import {
    IonCol,
    IonContent,
    IonIcon,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonLabel,
    IonInput,
    IonImg,
    IonGrid,
    IonSelect,
    IonItem,
    IonSelectOption,
    IonText,
    IonLoading,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonVirtualScroll,
    useIonAlert,
    IonTextarea,
    IonSearchbar
} from "@ionic/react";
import React, { useEffect, useState, useRef, useMemo, useSyncExternalStore } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";
import { useForm } from "react-hook-form";
import UserService from "../../Services/user.service";
import { addCircleOutline, addCircleSharp, addOutline, arrowBack, close, colorPalette, compassOutline } from "ionicons/icons";
import DataTable from "react-data-table-component"

import "./Deitymanage.css";
import { useHistory } from "react-router";
import Authservice from "../../Services/user.service";
import { Capacitor } from "@capacitor/core";
import Lightbox from "react-image-lightbox";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";

const MaterialsVen: React.FC = () => {
    const [presentAlert] = useIonAlert();
    const [comment, setComment] = useState("")
    const [viewcomment, setViewcomment] = useState("")
    const [inactivecomment, setInactivecomment] = useState("")
    const [activeflag, setActiveflag] = useState("")

    const formatter = new Intl.NumberFormat('en-IN')
    const [formErrors, setFormErrors]: any = useState({});


    const {
        handleSubmit,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
    });

    const [usertoken] = useState(localStorage.getItem("token"));
    const history = useHistory();
    const userdata: any = useState(localStorage.getItem("UserData"));
    const uFlag: any = useState(localStorage.getItem("uFlag"));
    const [materialList, setMaterialList] = useState([]);
    const [name, setName] = useState("");
    const [brand, setBrand] = useState("");
    const [price, setPrice] = useState("");
    const [pUnit, setPUnit]: any = useState("");
    const [psize, setPsize]: any = useState("");
    const [quantity, setQuantity]: any = useState(0);
    const [stock, setStock] = useState("");
    const [image, setImage] = useState("");
    const [matImage, setMatImage] = useState("");
    const [images, setImages] = useState("");
    const [isedit, setIsedit] = useState(false);
    const [index, setIndex] = useState(0)
    const [req, setReq] = useState(false)
    const [mid, setMid] = useState("")
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [showload, setShowload] = useState(false)
    const [MaterialorderList, setMaterialorderList] = useState([])
    const [isHovered, setIsHovered] = useState(false);
    const [productname, setProductname] = useState();
    const [brandname, setBrandname] = useState();
    const [oprice, setoPrice] = useState();
    const [oquantity, setoQuantity] = useState();
    const [amtpriest, setAmtpriest] = useState();
    const [amttemple, setAmttemple] = useState();
    const [date, setDate] = useState();
    const [number, setNumber] = useState();
    const [opsize, setoPsize] = useState();
    const [opunit, setoPunit] = useState();
    const [oname, setoName] = useState()
    const [modalopen, setModalopen] = useState(false);
    const [modalopen1, setModalopen1] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [alertmsg, setAlertMsg]: any = useState("")
    const [alertModal, setAlertModal]: any = useState(false)
    const alertRef = useRef(null);

    if (materialList != undefined && materialList.length > 0) {
        materialList.forEach((item: any, index: any) => { item.serial = index + 1; });
    }


    const columns: any = [
        {
            name: "S.No",
            selector: (item: any, i: any) => item.serial,
            sortable: true,
            width: "90px",
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.serial}</div>
                </div>
            )
        },
        {
            name: "Image",
            selector: (item: any) => item.image,
            sortable: false,
            style: {
                textAlign: 'right'
            },
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >
                        <img style={{ maxWidth: "70px", maxHeight: "70px" }} alt="img" src={item.image + "?v=" + new Date()}></img>
                    </div>
                </div>
            )
        },
        {
            name: "Product Name",
            selector: (item: any) => item.productName,
            sortable: true,
            cell: (item: any) => (
                <div className="textDes threelineCls left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.productName}</div>
                </div>
            )
        },
        {
            name: "Brand Name",
            selector: (item: any) => item.brandName,
            sortable: true,
            cell: (item: any) => (
                <div className="textDes threelineCls left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.brandName}</div>
                </div>
            )
        },
        {
            name: "In Stock",
            selector: (item: any) => parseInt(item.materialStock, 10),
            sortable: true,
            cell: (item: any) => (
                <div className="textDes threelineCls center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.materialStock}</div>
                </div>
            )
        },
        {
            name: "Price(₹)",
            selector: (item: any) => parseInt(item.price, 10),
            sortable: true,
            cell: (item: any) => (
                <div className="textDes threelineCls center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{formatter.format(item.price)}</div>
                </div>
            )
        },
        {
            name: "Package Size",
            selector: (item: any) => parseInt(item.packageSize, 10),
            sortable: true,
            //width: "50%",
            cell: (item: any) => (
                <div className="textDes threelineCls center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.packageSize}</div>
                </div>
            )
        },
        {
            name: "Status",
            sortable: false,
            selector: (item: any) => item.activeFlag,
            cell: (item: any) => (
                <div className="center_col_tab">
                    {item.activeFlag === "true" ? (
                        <IonButton color="danger" className="datatable-activate-btn" onClick={() =>
                            presentAlert({
                                header: 'Alert',
                                message: ' If you deactivate, the customers will not be able to view the selected material to place orders. Do you want to deactivate?',
                                buttons: [
                                    {
                                        text: 'No',
                                        role: 'cancel',
                                        handler: () => {
                                            // setHandlerMessage('Alert canceled');
                                        },
                                    },
                                    {
                                        text: 'Yes',
                                        role: 'confirm',
                                        handler: (alertData) => {
                                            handleStatus(false, item.id, alertData.comment)
                                        },
                                    },
                                ],
                                inputs: [
                                    {
                                        name: 'comment',
                                        type: 'text',
                                        value: comment,
                                        placeholder: 'Enter your comment'
                                    }
                                ]
                            })

                        }><span className="button_padding">Deactivate</span></IonButton>

                    ) : (
                        <IonButton color="success" className="datatable-activate-btn" onClick={() => presentAlert({
                            header: 'Alert',
                            message: 'If you activate, the customers will be able to view the selected material to place orders. Do you want to activate?',
                            buttons: [
                                {
                                    text: 'No',
                                    role: 'cancel',
                                    handler: () => {
                                        // setHandlerMessage('Alert canceled');
                                    },
                                },
                                {
                                    text: 'Yes',
                                    role: 'confirm',
                                    handler: (alertData) => {
                                        handleStatus(true, item.id, alertData.comment)
                                    },
                                },
                            ],
                            inputs: [
                                {
                                    name: 'comment',
                                    type: 'text',
                                    value: comment,
                                    placeholder: 'Enter your comment'
                                }
                            ]
                        })} ><span className="button_padding">Activate</span></IonButton>

                    )}
                </div>
            )
        },
        // {
        //     name: "View",
        //     sortable: false,
        //     cell: (item: any) => (
        //         <>
        //             <IonButton onClick={() => viewdetails(item)} style={{ textTransform: "none" }}>Update</IonButton>
        //         </>
        //     )
        // },
    ]

    useEffect(() => {
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_VENDOR") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            showPoojaMaterial();
        }

    }, []);
    const handleStatus = (status: any, id: any, comment: any) => {
        setShowLoading(true);
        Authservice.statusVenMat(usertoken, status, id, comment).then((response) => {
            console.log(response)
            window.location.reload()
            setShowLoading(false);
        })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false);
            });

    }
    const showPoojaMaterial = () => {
        setShowLoading(true);
        UserService.materialList(usertoken, "").then((response) => {
            console.log(response);
            var data = response.data;
            const array: any = [...data].reverse();
            setMaterialList(array);
            setShowLoading(false);
        })
            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }

    const viewdetails = (item: any) => {
        setReq(true)
        console.log(item);
        setActiveflag(item.activeFlag)
        setImages("")
        setMid(item.id)
        if (item.productName === null) {
            setName("");
        } else {
            setName(item.productName);
        }
        if (item.brandName === null) {
            setBrand("");
        } else {
            setBrand(item.brandName);
        }
        if (item.activeComment === null) {
            setViewcomment("");
        } else {
            setViewcomment(item.activeComment);
            console.log(item.activeComment)
        }
        if (item.inactiveComment === null) {
            setInactivecomment("");
        } else {
            setInactivecomment(item.inactiveComment);
        }
        if (item.price === null) {
            setPrice("");
        } else {
            setPrice(item.price);
        }
        if (item.materialStock === null) {
            setStock("");
        } else {
            setStock(item.materialStock);
        }
        if (item.quantity === null) {
            setQuantity(0);
        } else {
            setQuantity(0)
        }
        if (item.packageSize === null) {
            setPsize("");
        } else {
            setPsize(item.packageSize);
        }
        if (item.packageUnit === null) {
            setPUnit("");
        } else {
            setPUnit(item.packageUnit);
        }
        console.log(quantity)
        setIsedit(true)
        setImage(item.image + "?v=" + new Date());
        setMatImage(item.image + "?v=" + new Date());
        // setIndex(1)
        setFormErrors({})
        setModalopen(true)
    };
    const [fileName, setFileName] = useState("");
    const openmodal = () => {
        setReq(false)
        setIsedit(false)
        // setIndex(1)
        setModalopen1(true)
        setImage("");
        setName("");
        setBrand("");
        setPrice("");
        setPUnit("");
        setPsize("");
        setStock("");
        setFileName("")
        setImages("")
        setFormErrors({})
    };

    const imageinputref: any = useRef(null)
    const imageinputref1: any = useRef(null)
    const handleButtonClick = (e: any) => {
        if (e == 1) {
            if (imageinputref.current) {
                imageinputref.current.click();
            }
        } else {
            if (imageinputref1.current) {
                imageinputref1.current.click();
            }
        }
    };
    const handleimage = (e: any) => {
        var file: any = e.target.files[0];
        const splited: any = file.name.split(".")
        if (splited[splited.length - 1] === "jpg" || splited[splited.length - 1] === "png"
            || splited[splited.length - 1] === "jpeg") {
            console.log(images)
            const objectUrl = URL.createObjectURL(file);
            setImages(file);
            setFileName(file.name)
            setImage(objectUrl);
        } else {
            showAlert(file.name + " is Unsupported File Format, Supported File Formats Are JPG, PNG,JPEG")
            // setAlertModal(true)
            if (imageinputref.current) {
                imageinputref.current.value = null
            }
            if (imageinputref1.current) {
                imageinputref1.current.value = null
            }
            return false;
        }
        console.log(e.target.files);

    };


    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };
    const showAlert = (msg: any) => {
        presentAlert({
            header: 'Atmagram',
            cssClass: 'custom-alert',
            message: msg,
            buttons: [
                {
                    text: 'ok',
                    role: 'cancel',
                    handler: () => {


                        //window.location.reload();
                    },
                }
            ]
        })
    }

    const onSubmit = () => {
        setShowLoading(true);
        console.log(images)
        UserService.addMaterial(usertoken, isedit, brand, price, name, images, pUnit, psize, stock, mid, quantity, "").then((response) => {
            console.log(quantity)
            if (response.data.responseText.startsWith("SUCCESS")) {
                if (isedit) {
                    setShowLoading(false);
                    setModalopen(false)
                    showAlert("Updated Successfully")

                    //window.location.reload();
                }
                else {
                    setShowLoading(false);
                    setModalopen1(false)
                    showAlert("Added Successfully")

                    //window.location.reload()
                }
                setIndex(0)
                showPoojaMaterial();
            } else if (response.data.responseText === "ERROR:Product Name Already Exist") {
                setShowLoading(false);
                showAlert("Product Name Already Exist")

                console.log(response)
            }
            else if (response.data.responseText === "Add address in your profile and create material!") {
                setShowLoading(false);
                showAlert("Add address in your profile and create material!")

                history.push("./profile")
            }
            else {
                setShowLoading(false);
                showAlert("Not Updated")

            }
        })
            .catch((error) => {
                showAlert("Something went wrong, please try again later.");

                setShowLoading(false);
            });
    };

    var tableData: any = [];
    if (materialList != undefined && materialList.length > 0) {
        tableData = materialList.filter((item: any) => {
            const serializedItem = JSON.stringify(item).toLowerCase();
            const searchTerm = filterText.toLowerCase();

            const regex = new RegExp(`\\b${searchTerm}\\b`);

            return regex.test(serializedItem);
        });
    }


    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <IonRow className="table_btn_container">
                <div>
                    <IonButton onClick={openmodal} className="admin_add_btn">
                        <IonIcon src={addOutline} />
                        <span className="button_padding">Add Materials</span>
                    </IonButton>
                </div>
                <div>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>

        );
    }, [filterText, resetPaginationToggle]);

    const preventdata = (e: any) => {
        e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
        e.target.addEventListener('keydown', function (u: any) {
            if (u.which === 38 || u.which === 40) {
                u.preventDefault();
            }
        })
    }

    const [showModal, setShowModal] = useState(false)
    const [addshowModal, setAddshowModal] = useState(false)
    const imagenew = () => {
        setShowModal(true)
    }

    const addimagenew = () => {
        setAddshowModal(true)
    }

    const validation = (e: any) => {
        if (e == 1) {
            if (name.length === 0) {
                setFormErrors({ ...formErrors, name: "Please Enter The Name" })
            }
        }
        if (e == 2) {
            if (brand.length === 0) {
                setFormErrors({ ...formErrors, brand: "Please Enter The Brand" })
            }
        }
        if (e == 3) {
            if (price.length === 0) {
                setFormErrors({ ...formErrors, price: "Please Enter The Price" })
            }
        }
        if (e == 4) {
            if (stock.length === 0) {
                setFormErrors({ ...formErrors, stock: "Please Enter The Stock" })
            }
        }
        if (e == 5) {
            if (psize.length === 0) {
                setFormErrors({ ...formErrors, size: "Please Enter The Size" })
            }
        }
        if (e == 6) {
            if (pUnit.length === 0) {
                setFormErrors({ ...formErrors, unit: "Please Enter The Unit" })
            }
        }
    }
    const namechange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, name: "" })
        }
        setName(e.detail.value!)
    }
    const brandChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, brand: "" })
        }
        setBrand(e.detail.value!)
    }
    const priceChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, price: "" })
        }
        setPrice(e.detail.value!)
    }
    const stockChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, stock: "" })
        }
        setStock(e.detail.value!)
    }
    const sizeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, size: "" })
        }
        setPsize(e.detail.value!)
    }
    const unitChange = (e: any) => {
        if (e.target.value !== "") {
            setFormErrors({ ...formErrors, unit: "" })
        }
        setPUnit(e.target.value!)
    }
    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Material Management"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <Modal show={modalopen1} className="admin_modal">
                    <Modal.Body>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Add New Material</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen1(false)} />
                            </div>
                        </div>
                        <IonGrid >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Name <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Name"
                                                type="text"
                                                value={name}
                                                onBlur={() => validation(1)}
                                                onIonChange={(e) => namechange(e)}
                                                readonly={req}
                                            ></IonInput>
                                        </div>
                                        {formErrors.name !== "" && <span className="alert_input_bottom ">{formErrors.name}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Brand <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Brand"
                                                type="text"
                                                value={brand}
                                                onBlur={() => validation(2)}
                                                onIonChange={(e) => brandChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.brand !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.brand}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Price <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Price"
                                                type="number"
                                                value={price}
                                                min={0}
                                                onBlur={() => validation(3)}
                                                onFocus={(e) => preventdata(e)}
                                                onIonChange={(e) => priceChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.price !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.price}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">In Stock <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                type="number"
                                                placeholder="In Stock"
                                                value={stock}
                                                onBlur={() => validation(4)}
                                                onFocus={(e) => preventdata(e)} min={0}
                                                onIonChange={(e) => stockChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.stock !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.stock}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Package Size <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Package size"
                                                type="number"
                                                min={0}
                                                value={psize}
                                                onBlur={() => validation(5)}
                                                onFocus={(e) => preventdata(e)}
                                                onIonChange={(e) => sizeChange(e)}
                                            />
                                        </div>
                                        {formErrors.size !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.size}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Package Unit <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <select
                                                placeholder="Package unit"
                                                value={pUnit}
                                                onBlur={() => validation(6)}
                                                onChange={(e) => unitChange(e)}
                                                className="select_package_admin"
                                            >
                                                <option disabled selected value="">Package Unit</option>
                                                <option>Kilogram</option>
                                                <option>Litre</option>
                                                <option>Gram</option>
                                                <option>Pound</option>
                                                <option>Millilitre</option>
                                                <option>Meter</option>
                                                <option>Piece</option>
                                            </select>
                                        </div>
                                        {formErrors.unit !== "" && <span className="alert_input_bottom " >{formErrors.unit}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeMd="6" sizeXs="12">
                                        <IonRow className="image_div">
                                            <IonCol size="6" className="center_col image_content">
                                                {images != "" ? <span className="image_content_spn">{fileName}</span> :
                                                    <span className="image_content_spn">No file Chosen <span style={{ color: "red" }}>*</span></span>}
                                            </IonCol>
                                            <IonCol size="6" className="image_content">
                                                <input
                                                    className={images != "" ? "" : "image_chose"}
                                                    type="file"
                                                    ref={imageinputref1}
                                                    onChange={(e) => handleimage(e)}
                                                    style={{ display: 'none' }}
                                                />
                                                <IonButton
                                                    onClick={() => handleButtonClick(2)}
                                                    className="admin_img_btn image_content"
                                                ><span className="button_padding">Choose File</span>
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            </form>
                            {image.length === 0 ? (
                                <div></div>
                            ) : (
                                <IonRow>
                                    <IonCol className="Col_1">
                                        <div onClick={() => addimagenew()}>
                                            <IonImg className="image_design" src={image}></IonImg>
                                        </div>
                                    </IonCol>
                                </IonRow>
                            )}
                            {addshowModal && (
                                <Lightbox
                                    mainSrc={image}
                                    onCloseRequest={() => setAddshowModal(false)}
                                    onImageLoad={() => {
                                        window.dispatchEvent(new Event('resize'));
                                    }}
                                />
                            )}
                            <IonRow style={{ textAlign: "center" }}>
                                <IonCol size="12">
                                    <IonRow className="admin-submit-cancel">
                                        <IonCol size="6" style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <div style={{ width: "fit-content" }} className={name.length === 0 ||
                                                price.length === 0 ||
                                                brand.length === 0 ||
                                                pUnit === "" ||
                                                psize === "" ||
                                                stock.length === 0 ||
                                                image.length === 0 ? "disableCursor" : ""}>
                                                <IonButton
                                                    disabled={
                                                        name.length === 0 ||
                                                        price.length === 0 ||
                                                        brand.length === 0 ||
                                                        pUnit === "" ||
                                                        psize === "" ||
                                                        stock.length === 0 ||
                                                        image.length === 0
                                                    }
                                                    onClick={onSubmit}
                                                    type="submit"
                                                    className="admin_add_btn"
                                                >
                                                    <span className="button_padding">Submit</span>
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                        <IonCol style={{ textAlign: "start" }} size="6">
                                            <IonButton
                                                className="admin_cencel_btn" fill="outline"
                                                onClick={() => setModalopen1(false)}
                                            >
                                                <span className="button_padding">Cancel</span>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Modal.Body>
                </Modal>
                <Modal show={alertModal} centered style={{ width: "40%", left: "30%" }}>
                    <Modal.Body ref={alertRef}>
                        <div style={{ textAlign: "center" }}><div>
                            <IonText> {alertmsg}</IonText>
                        </div><br></br>
                            <div>
                                <IonButton className="admin_add_btn">
                                    <span onClick={() => { setAlertModal(false) }} className="button_padding">ok</span>

                                </IonButton>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={modalopen} className="admin_modal">
                    <Modal.Body>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Update Material</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
                            </div>
                        </div>
                        <IonGrid style={{ textAlign: "left" }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Name <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Name"
                                                type="text"
                                                value={name}
                                                onBlur={() => validation(1)}
                                                onIonChange={(e) => namechange(e)}
                                                readonly={req}
                                            ></IonInput>
                                        </div>
                                        {formErrors.name !== "" && <span className="alert_input_bottom ">{formErrors.name}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Brand <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Brand"
                                                type="text"
                                                value={brand}
                                                onBlur={() => validation(2)}
                                                onIonChange={(e) => brandChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.brand !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.brand}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Price <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Price"
                                                type="number"
                                                value={price}
                                                min={0}
                                                onBlur={() => validation(3)}
                                                onFocus={(e) => preventdata(e)}
                                                onIonChange={(e) => priceChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.price !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.price}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">In Stock <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                type="number"
                                                placeholder="In Stock"
                                                value={stock}
                                                onBlur={() => validation(4)}
                                                onFocus={(e) => preventdata(e)} min={0}
                                                onIonChange={(e) => stockChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.stock !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.stock}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Package Size <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Package size"
                                                type="number"
                                                min={0}
                                                value={psize}
                                                onBlur={() => validation(5)}
                                                onFocus={(e) => preventdata(e)}
                                                onIonChange={(e) => sizeChange(e)}
                                            />
                                        </div>
                                        {formErrors.size !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.size}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Package Unit <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <select
                                                placeholder="Package unit"
                                                value={pUnit}
                                                onBlur={() => validation(6)}
                                                onChange={(e) => unitChange(e)}
                                                className="select_package_admin"
                                            >
                                                <option disabled selected value="">Package Unit</option>
                                                <option>Kilogram</option>
                                                <option>Litre</option>
                                                <option>Gram</option>
                                                <option>Pound</option>
                                                <option>Millilitre</option>
                                                <option>Meter</option>
                                                <option>Piece</option>
                                            </select>
                                        </div>
                                        {formErrors.unit !== "" && <span className="alert_input_bottom " >{formErrors.unit}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow style={{ alignItems: "flex-end" }}>
                                    {activeflag === "true" ? <>
                                    </> : <>
                                        {inactivecomment === "" ? <></> :
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonLabel className="label_align">Comment<span style={{ color: "red" }}>*</span></IonLabel>
                                                <div>
                                                    <IonTextarea
                                                        style={{ overflowY: "auto", height: "40px" }}
                                                        value={inactivecomment}
                                                        readonly
                                                    />
                                                </div>
                                            </IonCol>
                                        }
                                    </>}
                                    <IonCol sizeMd="6" sizeXs="12">
                                        <IonRow className="image_div" style={{ margin: activeflag === "false" ? "0px" : "10px 0px" }}>
                                            <IonCol size="6" className="image_content">
                                                <input
                                                    className={images != "" ? "" : "image_chose"}
                                                    type="file"
                                                    ref={imageinputref}
                                                    onChange={(e) => handleimage(e,)}
                                                    style={{ display: 'none' }}
                                                />
                                                <IonButton
                                                    onClick={() => handleButtonClick(1)}
                                                    className="admin_img_btn image_content"
                                                ><span className="button_padding">Choose File</span>
                                                </IonButton>
                                            </IonCol>
                                            <IonCol size="6" className="center_col image_content">
                                                {images != "" ? <span className="image_content_spn">{fileName}</span> :
                                                    <span className="image_content_spn">Select a File <span style={{ color: "red" }}>*</span></span>}
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            </form>
                            {image.length === 0 ? (
                                <div></div>
                            ) : (
                                <IonRow>
                                    <IonCol className="Col_1">
                                        <div className='image-container'>
                                            <div onClick={() => imagenew()}>
                                                <IonImg className="image_design_deity" src={image}></IonImg>
                                            </div>
                                        </div>
                                    </IonCol>
                                </IonRow>
                            )}
                            {showModal && (
                                <Lightbox
                                    mainSrc={image}
                                    onCloseRequest={() => setShowModal(false)}
                                    onImageLoad={() => {
                                        window.dispatchEvent(new Event('resize'));
                                    }}
                                />
                            )}
                            <IonRow style={{ textAlign: "center" }}>
                                <IonCol size="12">
                                    <IonRow>
                                        <IonCol style={{ display: "flex", justifyContent: "flex-end" }} size="6">
                                            <div style={{ width: "fit-content" }} className={name.length === 0 ||
                                                price.length === 0 ||
                                                brand.length === 0 ||
                                                pUnit === "" ||
                                                psize === "" ||
                                                stock.length === 0 ||
                                                image.length === 0 ? "disableCursor" : ""}>
                                                <IonButton
                                                    className="admin_add_btn"
                                                    disabled={
                                                        name.length === 0 ||
                                                        price.length === 0 ||
                                                        brand.length === 0 ||
                                                        pUnit === "" ||
                                                        psize === "" ||
                                                        stock.length === 0 ||
                                                        image.length === 0
                                                    }
                                                    onClick={onSubmit}
                                                    type="submit"
                                                >
                                                    <span className="button_padding">Submit</span>
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                        <IonCol style={{ textAlign: "start" }} size="6">
                                            <IonButton
                                                className="admin_cencel_btn" fill="outline"
                                                onClick={() => setModalopen(false)}
                                            >
                                                <span className="button_padding">Cancel</span>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Modal.Body>
                </Modal>
                <div>
                    <div className="container_middle_table page-wrapper admin_background_color">
                        {index == 0 ? <div>
                            <DataTable
                                columns={columns}
                                data={tableData}
                                striped
                                noDataComponent="Sorry ! No result found"
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                style={{ width: "100%" }}
                                onRowClicked={(item: any) => viewdetails(item)}
                                customStyles={customTableStyle}
                            />
                        </div> : index == 1 ? <div>
                        </div> : index == 2 ? <div>
                        </div> : index == 3 ? <div></div> : <div></div>}

                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
export default MaterialsVen;
