import {
  IonCol,
  IonContent,
  IonIcon,
  IonItemDivider,
  IonPage,
  IonRow,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonImg,
  IonTextarea,
  IonText,
  IonList,
  IonGrid,
  IonLoading,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonCard,
  IonSearchbar,
} from "@ionic/react";
import React, { useEffect, useState, useMemo, useSyncExternalStore, useRef, createRef } from "react";
import Head from "../Head";
import Foot from "../Foot";
import Module from "module";
import axios from "axios";
import { useForm } from "react-hook-form";
import UserService from "../../Services/user.service";
import { arrowBack, calendarOutline, close, closeCircle, closeCircleOutline, documentAttachOutline, receipt, receiptOutline, timeOutline } from "ionicons/icons";
import DataTable from "react-data-table-component"
import "./Deitymanage.css";
import { useHistory } from "react-router";
import TempleAdmDet from "./TempDetails";
import Addtemp from "./addtemple";
import { Modal } from "react-bootstrap";
import Authservice from "../../Services/user.service";
import moment from "moment";
import { Capacitor } from "@capacitor/core"
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import Lightbox from "react-image-lightbox";
import customTableStyle from "../../components/CustomTableStyle";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import { temple } from "../../theme/imagePath";
import Select from 'react-select';


const Order: React.FC = () => {
  const customStyles = {
    control: (provided: any) => ({
        ...provided,
        border: "1px solid black",
        boxShadow: 'none',
        fontSize: '15px',
        // height: '44px',
        width: '100%',
        outline: 'none',
        textAlign: "left"
    }),
};
  const [usertoken] = useState(localStorage.getItem("token"));
  const userdata: any = useState(localStorage.getItem("UserData"));
  const uFlag: any = useState(localStorage.getItem("uFlag"));
  const [modalopen, setModalopen] = useState(false);
  const [name, setName] = useState("");
  const [fromAndTo, setFromAndTo] = useState(true);
  const [listOfD, setListOfD] = useState(true);
  const [description, setDescription] = useState("");
  const [price, setPrice]: any = useState("");
  const [pdamt, setPdamt] = useState("")
  const [time, setTimes] = useState("");
  const [donamt, setDonamt]: any = useState("");
  const [dasamt, setDasamt]: any = useState("");
  const [frD, setFrD] = useState("");
  const [toD, setToD] = useState("");
  const [isedit, setIsedit] = useState(false);
  const [id, Setid] = useState("");
  const [data, setData]: any = useState([]);
  const [noM, setNoM] = useState("");
  const [loD, setLoD] = useState("");
  const [deliveryAdd, setDeliveryAdd] = useState("");
  const [tname, setTname] = useState("");
  const [deityname, setDeityname] = useState("");
  const [uname, setUname] = useState("");
  const [star, setStar] = useState("");
  const [ship, setShip]: any = useState([])
  const [shipmodal, setShipmodal] = useState(false);
  const [shipDate, setShipDate] = useState("");
  const [shipTime, setShipTime] = useState("");
  const [filterText, setFilterText] = React.useState("");
  const [orderdate, setOrderdate] = useState("");
  const [Odate, setOdate]: any = useState();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [totalpaidamount, setTotalpaidamount]: any = useState("");
  const [shipNewlist, setShipNewlist]: any = useState([])
  if (data != undefined && data.length > 0) {
    data.forEach((item: any, index: any) => { item.serial = index + 1; });
    data.forEach((item: any, index: any) => {
      var date = item.poojaOfferingOrders.orderDate.split("T");
      const od = moment(date[0]).format("DD-MM-YYYY");
      item.localOrderDate = od;
    });
  }

  const [showLoading, setShowLoading] = useState(false);
  var dateSplit1 = orderdate.split("T")
  const Date1 = dateSplit1[0]
  const [offerDoneCheck, setOfferDone] = useState(false);

  const formatter = new Intl.NumberFormat('en-IN')
  const [isOpendel, setIsOpendel] = useState(false);
  const [receiptdata, setReceiptdata] = useState("")
  const [receiptimage, setReceiptimage] = useState("")
  const [wholedata, setWholedata] = useState("")
  const [awbisopen, setAwbisopen] = useState(false)
  const [awbNumber, setAwnNumber] = useState("");
  const [showshipdate, setShowshipdate] = useState("")
  const [showshiptime, setShowshiptime] = useState("")
  const [initisopen, setInitisopen] = useState(false)
  const [initofferId, setInitofferid] = useState("")
  const [initstatusid, setInitstatusid] = useState("")
  const [initshipdate, setInitshipdate] = useState("")
  const [initawb, setInitAwb] = useState("")
  const [initshiptime, setInitshiptime] = useState("")
  const [orderDate, setOrderDate] = useState("")
  const [ordernumber, setOrdernumber] = useState("")
  const [mindate, setMindate] = useState("");
  const [orderedBy, setOrderedBy] = useState("")
  const [orderByStar, setOrderedByStar] = useState("")
  const [templeName, setTempleName] = useState("")
  const [offeringName, setOfferingname] = useState("")
  const [orderNo, setOrderNo] = useState("")
  const [statusList, setStatusList]: any = useState([])
  const [orderStatus, setOrdStatus]: any = useState("OPEN")
  const [shipStatus,setShipStatus]: any=useState("")
  const orderStatusList:any[] = [
    {value:2, label:"All"},
   
{value:1, label:"Complete Orders"},
{value:0, label:"Incomplete Orders"},]
const [filterStatus, setFilterStatus]: any = useState({value:0, label:"Incomplete Orders"})
const [filterArr , setFilterArr]:any = useState([])
const [shipStatusError, setShipStatusError]:any = useState("")
const loadData:any = useRef([])
var load = false;

  const toggleDrawer = (item: any, i: any) => {
    console.log(item, "ujisjdbjk")
    if (prasadhamflag === false) {
      setIsOpendel(true);
    }
    else if (item.awbNumber !== null) {
      setAwbisopen(true)
      setAwnNumber(item.awbNumber)
      var date = moment(item.shipmentDate).format("DD-MM-YYYY")
      setShowshipdate(date)
      setShowshiptime(item.shipmentTime)
    }
    else {
      setInitisopen(true)
    }
    setInitstatusid(item.id)
    setShipStatus(item.status)
    setInitofferid(item.poojaOfferingOrders.id)
    setOrdernumber(item.poojaOfferingOrders.orderNumber)
    setReceiptdata(item.receipt)
    setWholedata(item)
    setShiperror({})
    var orderdate: any = item.orderedDate.split("T")
    var format = moment(orderdate[0]).format("DD-MM-YYYY")
    setOrderDate(format)
    setMindate(orderdate[0])
    setReceiptimage(item.imageName)
    setOrderedBy(item.poojaOfferingOrders.userCartDetails.firstName)
    setOrderedByStar(item.poojaOfferingOrders.userCartDetails.star)
  };

  const history = useHistory()

  useEffect(() => {
    if (userdata[0] === null) {
      history.push("/login")
    }
    else if (userdata[0] !== "ROLE_TEMPADMIN") {
      history.push("/home")
    }
    else if (uFlag[0] === "false") {
      history.push("/home")
    }
    // return()=>{
    else {
      if(!load){
        load = true;
        showorder();
      getstatusList();

      }
      
    }
    console.log("effect")
    //}
  }, []);
  console.log()
  const [ood, setOod] = useState("");

  const getstatusList = () =>{
    
    Authservice.poojaorderStatusList(usertoken).then((response) => {
        console.log(response);
        setStatusList(response.data)
        
       
       
    })
        .catch((error) => {
            alert(error)
            
        });
}
const changeStatus = (status:any) => {
  setOrdStatus(status)

   }
   const groupedItems = ship.reduce((acc:any, item:any) => {
    const { status } = item;
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(item);
    return acc;
  }, {} as { [key: string]: any[] });
  const showorder = () => {
    setShowLoading(true);
    Authservice.OrderTemp(usertoken, "").then((response) => {
      
      setData(response.data);
      loadData.current = response.data;
     
     
      shipNewlist.length = 0;


      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].poojaOfferingOrdersStatusList.length > 0) {
          //setShip((e:any)=>([...e, ...response.data[i].poojaOfferingOrdersStatusList]))
          const newData: any = response.data[i].poojaOfferingOrdersStatusList.map((e: any) => {
            return { ...e, shipDate: "", shipTime: "", offeringFlag: e.offeringFlag, disableFlag: e.offeringFlag, imageName: "" }
          })
          shipNewlist.push(newData)

        }

      }
      
      setShowLoading(false);
      handleOrderChange({value:0, label:"Incomplete Orders"})
    })
      .catch((error) => {
        alert("Something went wrong, please try again later.");
        setShowLoading(false);
      });
  }
  const columns: any = [
    // {
    //   name: "S.No",
    //   selector: (item: any, i: any) => item.serial,
    //   sortable: true,
    //   width: "90px",
    //   cell: (item: any) => (
    //     <div className="center_col_tab" onClick={() => viewdetails(item)}>
    //       <div style={{ cursor: "pointer" }}>{item.serial}</div>
    //     </div>
    //   )
    // },
    {
      name: "Order Number",
      sortable: true,
      // width: "13%",
      selector: (item: any) => item.poojaOfferingOrders.orderNumber,
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => viewdetails(item)}>
          <div style={{ cursor: "pointer" }} >{item.poojaOfferingOrders.orderNumber}</div>
        </div>
      ),
    },
    {
      name: "Order Date",
      sortable: true,
      selector: (item: any) => new Date(item.localOrderDate.split('-').reverse().join('-')),
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => viewdetails(item)}>
          <div style={{ cursor: "pointer" }} >{item.localOrderDate}</div>
        </div>
      )
      //   {
      //     var date = item.poojaOfferingOrders.orderDate.split("T");
      //     const od = moment(date[0]).format("DD-MM-YYYY");
      //     return od;

      //  }
    },
    
    {
      name: "Temple Name",
      sortable: true,
      selector: (item: any, i: any) => item.poojaOfferingOrders.poojaOfferings.temple.name,
      cell: (item: any) => (
        <div className="left_col" onClick={() => viewdetails(item)}>
          <IonText style={{ cursor: "pointer" }}>{item.poojaOfferingOrders.poojaOfferings.temple.name}</IonText>
        </div>
      )
    },
    {
      name: "Offering Name",
      selector: (item: any) => item.poojaOfferingOrders.poojaOfferings.name,
      sortable: true,
      // width: "16%",
      cell: (item: any) => (
        <div className="left_col" onClick={() => viewdetails(item)}>
          <div style={{ cursor: "pointer" }} >{item.poojaOfferingOrders.poojaOfferings.name}</div>
        </div>
      )
    },
    
    {
      name: "Price(₹)",
      sortable: true,
      selector: (item: any) => formatter.format(item.poojaOfferingOrders.poojaOfferings.price),
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => viewdetails(item)}>
          <div style={{ cursor: "pointer" }} >{formatter.format(item.poojaOfferingOrders.poojaOfferings.price)}</div>
        </div>
      )
    },
    {
      name: "Manage Orders",
      sortable: false,
      // width: "14%",
      cell: (item: any, i: any) => (
        <div className="center_col_tab">
          <IonButton className="manage_order_btn" style={{ textTransform: "none" }} onClick={() => Ship(item, i)}>
            <span>
              Manage Orders
            </span>
          </IonButton>
        </div>
      )
    },
    // {
    //   name: "View",
    //   sortable: false,
    //   fixed: 'right',
    //   // width: "10%",
    //   selector: (item: any) => (
    //     <div style={{ textAlign: "center" }}>
    //       <IonButton onClick={() => viewdetails(item)} style={{ textTransform: "none" }}>View</IonButton>
    //     </div>
    //   )
    // }
  ]

  const [prasadhamflag, setPrasadhamflag] = useState(false)

  const Ship = (item: any, i: any) => {
    console.log(item, "item")
    setInitisopen(false)
    setAwbisopen(false)
    setIsOpendel(false)
    setAndroidreceiptmodalcheck(false)
    setInitshipdate("")
    setInitAwb("")
    setInitshiptime("")
    setTimeref("")
    setFormErrors({})
    setShipErrors({})
    setPrasadhamflag(item.poojaOfferingOrders.userCartDetails.prasadhamDelFlag)
    if (Capacitor.getPlatform() === "web") {
      setWeb(true)
      setAndroid(false)
    }
    else {
      setWeb(false)
      setAndroid(true)
    }
    console.log(ship, "ship")
    var obj = shipNewlist[i]
    console.log(item.poojaOfferingOrdersStatusList, "kmk")
    setShip(item.poojaOfferingOrdersStatusList);
    setOfferingname(item.poojaOfferingOrders.poojaOfferings.name)
    setOrderNo(item.poojaOfferingOrders.orderNumber);
    setTempleName(item.poojaOfferingOrders.poojaOfferings.temple.name)
    setShipmodal(true);
  }
  const Modalclose = () => {
    setModalopen(false)
  }
  const [statusmodal, setStatusmodal] = useState(false);
  const [statusId, setStatusId] = useState("");

  const changeOfferDoneproceed = (i: any, item: any) => {

    // let id = ship[i].id;
    console.log(usertoken, statusId, item.offeringFlag)
    UserService.changeOfferDone(usertoken, statusId, item.offeringFlag).then((response) => {
      console.log(response.data);
      if (response.data == "PoojaOfferingDone") {
        alert("OfferingStatus Changed successfully")

      } else {
        alert("OfferingStatus Changed failed, Try again")

      }

      window.location.reload();
    })
      .catch((error) => {
        alert(error)
      });
  }
  const statusproceed = (i: any) => {
    console.log(i)
    if (ship[i].status == "SCHEDULED") {
      alert("Select Status")
    } else {
      var data =
      {
        "status": ship[i].status,
        "id": ship[i].id
      }
      UserService.updateOrderStatusForOffering(usertoken, data).then((response) => {
        console.log(response.data);
        alert("Status Changed successfully")
        window.location.reload();
      })
        .catch((error) => {
          alert(error)
        });
    }
  }
  const ref: any = useRef([])
  const dateRef: any = useRef([])
  const [timeref, setTimeref] = useState("")

  const gettime = (e: any) => {
    console.log(initshipdate, "date")
    setTimeref(e.detail.value)
    if (e.detail.value !== "") {
      console.log(e.detail.value, "eeeee")
      var ii = e.detail.value.split(":");
      console.log(isNaN(ii))
      var t: any;
      var a: any;
      const date = new Date();
      const dates = new Date(initshipdate + " " + e.detail.value)
      console.log(dates, "datesssssss")

      // if (dates < date) {
      //   alert("Select Proper Time")
      //   setInitshiptime("")
      //   setInitshipdate("")
      //   return
      // }
      // else {
      if (parseInt(ii[0]) > 12) {
        t = parseInt(ii[0]) - 12;
        a = "PM";
      } else if (parseInt(ii[0]) < 12) {
        t = ii[0]
        a = 'AM';
      }
      else if (parseInt(ii[0]) == 12) {
        t = ii[0]
        a = 'PM';
      }
      // }
      console.log(t, "tttt")
      console.log(ii[1], "iiiiii")
      console.log(a, "aaaaaa")
      var str = t + ":" + ii[1].toString() + " " + a;
      console.log(str, "strrrr")
      setInitshiptime(str)
    }

  }

  const [onumber, setOnumber] = useState("");
  const [prasadhamdel, setPrasadamdel] = useState()
  const [web, setWeb] = useState(false)
  const [android, setAndroid] = useState(false)
  const viewdetails = (item: any) => {
    if (item.poojaOfferingOrders.userCartDetails.fromDate === "" || item.poojaOfferingOrders.userCartDetails.fromDate === null) {
      setFromAndTo(false)
    }
    else {
      var fromdate = item.poojaOfferingOrders.userCartDetails.fromDate.split("T");
      const fd = moment(fromdate[0]).format("DD-MM-YYYY");
      var todate = item.poojaOfferingOrders.userCartDetails.toDate.split("T");
      const td = moment(todate[0]).format("DD-MM-YYYY");
      setFrD(fd)
      setToD(td)
      setFromAndTo(true)
    }
    if (item.poojaOfferingOrders.userCartDetails.listOfDates === null || item.poojaOfferingOrders.userCartDetails.listOfDates === "") {
      setListOfD(false)
    }
    else {
      var lod = item.poojaOfferingOrders.userCartDetails.listOfDates.split("||").join().split("T12:00:00,")
      var lod2 = lod.splice(-1);
      const ldm2: any = [];
      for (let i = 0; i < lod.length; i++) {
        var ldm = moment(lod[i]).format("DD-MM-YYYY");
        ldm2.push(ldm)
      }
      setLoD(ldm2.join(", "))
      setListOfD(true)
    }
    setName(item.poojaOfferingOrders.poojaOfferings.name);
    setPrasadamdel(item.poojaOfferingOrders.userCartDetails.prasadhamDelFlag)
    setOnumber(item.poojaOfferingOrders.orderNumber);
    setDescription(item.poojaOfferingOrders.poojaOfferings.description);
    setPrice(item.poojaOfferingOrders.poojaOfferings.price);
    setTimes(item.poojaOfferingOrders.time);
    setTotalpaidamount(item.poojaOfferingOrders.totalPaidAmount);
    setDasamt(item.poojaOfferingOrders.userCartDetails.dakshinaAmountForPriest);
    setDonamt(item.poojaOfferingOrders.userCartDetails.dakshinaAmountToTemple);
    setNoM(item.poojaOfferingOrders.userCartDetails.noOfMonths);
    setDeliveryAdd(item.poojaOfferingOrders.userCartDetails.deliveryAddress);
    setTname(item.poojaOfferingOrders.poojaOfferings.temple.name);
    setDeityname(item.poojaOfferingOrders.poojaOfferings.deity.deityName);
    setUname(item.poojaOfferingOrders.userCartDetails.firstName);
    setStar(item.poojaOfferingOrders.userCartDetails.star);
    setModalopen(true);
  };

  const handleIconClick = (event: any) => {
    console.log('Icon clicked!');
    event.stopPropagation();  // Stop the event from propagating to the parent div
  };

  const DateInputRef: any = useRef(null);
  const timeInputRef: any = useRef(null);

  const datelimit = () => {
    const date = new Date(mindate)
    const current = new Date()
    if (date < current) {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    }
    else {
      const today = new Date(mindate);
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    }
  };

  var tableData: any = [];
  if (data != undefined && data.length > 0) {
    tableData = data.filter(
      (item: any) => JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1

    );
  }


  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <IonRow>
        <IonCol className="ion-text-left" size="12" style={{ paddingRight: '0px' }}>
          <IonSearchbar style={{ paddingRight: '0px' }}
            value={filterText}
            onIonChange={(e: any) => setFilterText(e.detail.value)}
            placeholder="Search"
            className="table_search"
          />
        </IonCol>
      </IonRow>
    );
  }, [filterText, resetPaginationToggle]);
  const dateChange = (event: any) => {
    //ship[i].shipDate = event.target.value
    var val = event.target.value;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate());
    if (val == "") {
      setShiperror((e: any) => {
        return { ...e, shipDate: "Please choose the Shipment Date" }
      })
    }
    else if (tomorrow.toISOString().split('T')[0] > new Date(val).toISOString().split('T')[0]) {
      setShiperror((e: any) => {
        return { ...e, shipDate: "Please select future date" }
      })
    }
    else {
      setShiperror((e: any) => {
        return { ...e, shipDate: "" }
      })
    }
    if (val === "") {
      setInitshipdate("")
    }
    else {
      const date = moment(val).format("YYYY-MM-DD")
      setInitshipdate(date)
    }
  };
  const handleStatus = (e: any, i: any) => {
    ship[i].status = e.target.value
    console.log(ship)
  };
  const [shipErrors, setShipErrors]: any = useState({})
  const [shiperror, setShiperror]: any = useState({})

  const validation = (num: any) => {
    setShipErrors({})
    if(num === "6"){
      if(initawb.trim() == ""){
        setShiperror((e: any) => {
          return { ...e, awbAlert: "Invalid AWB number" }
        })
       
      }else{
        setShiperror((e: any) => {
          return { ...e, awbAlert: "" }
        })
      }
    }
    if (num === "1") {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate());
      if (initshipdate == "") {
        setShiperror((e: any) => {
          return { ...e, shipDate: "Please choose the Shipment Date" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(initshipdate).toISOString().split('T')[0]) {
        setShiperror((e: any) => {
          return { ...e, shipDate: "Please select future date" }
        })
      }
      else {
        setShiperror((e: any) => {
          return { ...e, shipDate: "" }
        })
      }
    }
    else if (num === "2") {
      if (initshiptime == "") {
        setShiperror((e: any) => {
          return { ...e, shipTime: "Please choose the Shipment Time" }
        })
      }
      else {
        setShiperror((e: any) => {
          return { ...e, shipTime: "" }
        })
      }
    }
  }
  const initShip = () => {
    setShiperror({})
    console.log(initofferId, initshipdate, initshiptime, initstatusid)
    const shiperror: any = {}
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate())
    if (initshipdate == "") {
      shiperror.shipDate = "Please choose the Shipment Date"
    }
    else if (tomorrow.toISOString().split('T')[0] > new Date(initshipdate).toISOString().split('T')[0]) {
      shiperror.shipDate = "Please select future date"
    }
    if (initshiptime == "") {
      shiperror.shipTime = "Please choose the Shipment Time"
    }
    if(initawb.trim() == ""){
      shiperror.awbAlert = "Invalid AWB number"
    }
    setShipErrors(shiperror)
    if (Object.keys(shiperror).length === 0) {
      // var shId = ship[i].id;
      // var ordId = ship[i].poojaOfferingOrders.id;
      // var shTime = ship[i].shipTime;
      // var shDate = ship[i].shipDate;
      // console.log()
      // const date1 = new Date();
      // const time: any = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
      // const formattedDate = moment(date1.toLocaleDateString()).format("YYYY-MM-DD");

      // const time1Parts = time.split(/:| /);
      // const time2Parts = initshiptime.split(/:| /);

      // const date2: any = new Date();
      // date2.setHours(parseInt(time1Parts[0], 10) % 12 + (time1Parts[2] === "PM" ? 12 : 0));
      // date2.setMinutes(parseInt(time1Parts[1], 10));
      // date2.setSeconds(0);

      // const date3: any = new Date();
      // date3.setHours(parseInt(time2Parts[0], 10) % 12 + (time2Parts[2] === "PM" ? 12 : 0));
      // date3.setMinutes(parseInt(time2Parts[1], 10));
      // date3.setSeconds(0);

      // const differenceInMilliseconds = Math.abs(date3 - date2);
      // const hours = Math.floor(differenceInMilliseconds / 3600000);
      // const minutes = Math.floor((differenceInMilliseconds % 3600000) / 60000);

      // console.log(minutes,"kkkkk")

      // if (initshipdate === formattedDate && (hours === 0 && minutes <= 30)) {
      //   alert("Minimum Time Difference Is 30 Minutes");
      //   setInitshiptime("")
      //   setTimeref("");
      // }
      // else {
      Authservice.initShipForOffering(usertoken, initofferId, initshipdate, initshiptime, initstatusid,initawb).then((response) => {
        console.log(response.data);
        if (response.data.errorMessage == null) {
          alert("Shipment Initiated Successfully");
          window.location.reload();
        } else {
          alert(response.data.errorMessage);
          window.location.reload();
        }

      })
        .catch((error) => {
          alert("Problem in Initiating Shipment");
        });
      setShipmodal(false)
    }
    // }
  }
  const useOutsideAlerter = (refer: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (refer.current && !refer.current.contains(event.target)) {
          setModalopen(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [refer]);
  }
  const list: any = useRef(null);

  useOutsideAlerter(list);

  const check = (e: any, item: any) => {
    console.log(item)
    // if (e.detail.checked) {
    //   setStatusId(item.id)
    //   setOfferDone(true)
    // }
    // else {
    //   setOfferDone(false)
    // }
    const offdone = ship.map((items: any) => {
      if (item.id === items.id) {
        console.log({ ...items, offeringFlag: !items.offeringFlag }, "kbb")
        return { ...items, offeringFlag: !items.offeringFlag }
      }
      return items
    })
    setShip(offdone)
    console.log(item.id, "idd")
    setStatusId(item.id)
  }

  const [files, setFiles]: any = useState("")
  const [file, setFile] = useState("")
  const [fileview, setFileview] = useState("")
  const [receiptmodal, setReceiptmodal] = useState(false)
  const [receiptmodalcheck, setReceiptmodalcheck] = useState(false)
  const [androidreceiptmodalcheck, setAndroidreceiptmodalcheck] = useState(false)
  const [imagesend, setImagesend] = useState("")
  const receiptimageref: any = useRef(null)
  const receiptimageref1: any = useRef(null)
  const receiptimageref2: any = useRef(null)

  const handleButtonClick = (e: any) => {
    if (e == 0) {
      if (receiptimageref.current) {
        receiptimageref.current.click();
      }
    }
    else if (e == 1) {
      if (receiptimageref1.current) {
        receiptimageref1.current.click();
      }
    }
    else {
      if (receiptimageref2.current) {
        receiptimageref2.current.click();
      }
    }
  };

  const handleimage = (e: any, i: any) => {
    setReceiptmodalcheck(false)
    setShipmodal(true)
    var file: any = e.target.files[0];
    const splited: any = file.name.split(".")
    if (splited[splited.length - 1] === "jpg" || splited[splited.length - 1] === "jpeg"
      || splited[splited.length - 1] === "png") {
      const objectUrl = URL.createObjectURL(file);
      console.log(objectUrl, "qwertyui")
      setReceiptimage(file.name)
      const imagenameset = ship.map((item: any, index: any) => {
        if (i === index) {
          return { ...item, imageName: file.name }
        }
        return item
      })
      setShip(imagenameset)
      setFiles(file);
      setFile(objectUrl);
      // ship[i].receipt = objectUrl
      console.log(files)
    } else {
      alert(file.name + " is Unsupported File Format, Supported File Formats are JPG,JPEG and PNG")
      if (receiptimageref.current) {
        receiptimageref.current.value = null
      }
      if (receiptimageref1.current) {
        receiptimageref1.current.value = null
      }
      if (receiptimageref2.current) {
        receiptimageref2.current.value = null
      }
      return false;
    }
  };
  const statusProceed = () => 
  {
   
    if (shipStatus == "SCHEDULED") {
      setShipStatusError("Choose order status")
       
    }
    else {
      setShipStatusError("")
        var data =
        {
            "status": shipStatus,
            "id": initstatusid
        }
        Authservice.updateOrderStatusForOffering(usertoken, data).then((response) => {
            console.log(response.data);
            alert("Status Changed Successfully")
            window.location.reload();
            setShipmodal(false)
        })
            .catch((error) => {
              alert(error)
                
            });
        
    }
  }
  const receiptimagecheck = (e: any, item: any) => {
    console.log(item, "sdfsdzf")
    if (item.receipt === null) {
      handleimage(e, item.id)
    }
    else {
      setImagesend(e)
      setReceiptmodalcheck(true)
    }
  }

  const androidreceiptimagecheck = (item: any) => {
    console.log(item, "sdfsdzf")
    if (item.receipt === null) {
      takePicture()
    }
    else {
      setAndroidreceiptmodalcheck(true)
    }
  }

  const [imageuri, setImageuri]: any = useState("")
  const [imagename, setImagename] = useState("")
  const takePicture = async () => {
    setAndroidreceiptmodalcheck(false)
    setShipmodal(true)
    const image: any = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64
    });
    console.log(Camera, "gud")
    console.log(image, 'ghc')
    setImageuri(image)
  }

  const checkmodalclose = () => {
    setAndroidreceiptmodalcheck(false)
    setShipmodal(true)
  }

  const [formErrors, setFormErrors]: any = useState({});

  const fileupload = (item: any) => {
    const imageerrors: any = {}

    if (android === true) {
      if (imageuri.length <= 0) {
        imageerrors.androidimage = "Choose a Receipt"
      }
      if (Object.keys(imageerrors).length === 0) {
        const rawData = atob(imageuri.base64String);
        const bytes = new Array(rawData.length);
        for (var x = 0; x < rawData.length; x++) {
          bytes[x] = rawData.charCodeAt(x);
        }
        const arr = new Uint8Array(bytes);
        console.log(arr, "sdgvd")
        const blob = new File([arr], "receipt", { type: imageuri.format });
        console.log(blob, "image")
        Authservice.Reciept(usertoken, item.id, blob).then((response) => {
          console.log(response)
          if (response.data === "Receipt uploaded") {
            alert("Receipt Uploaded Successfully")
          }
          else {
            alert("Receipt Upload Failed")
          }
          window.location.reload()
        })
        console.log("qwerty")
      }
    }
    else {
      console.log("1111")
      if (files.length <= 0) {
        console.log("2222")
        imageerrors.fileimage = "Choose a Receipt"
        console.log(imageerrors, "yhfy")
      }
      if (Object.keys(imageerrors).length === 0) {
        console.log(item.id, "iddd")
        console.log(usertoken, 'token', item.id, 'id', files, "image")
        Authservice.Reciept(usertoken, item.id, files).then((response) => {
          console.log(response.data)
          if (response.data === "Receipt uploaded") {
            alert("Receipt Uploaded Successfully")
          }
          else {
            alert("Receipt Upload Failed")
          }
          window.location.reload()
        })
      }
    }
    setFormErrors(imageerrors)
  }

  const receiptview = (item: any) => {
    console.log(item)
    setFileview(item.receipt)
    setReceiptmodal(true)
  }

  const receiptmodalclose = () => {
    setReceiptmodal(false)
    setShipmodal(true)
  }

  const drawermodal = () => {
    setInitisopen(false)
    setAwbisopen(false)
    setIsOpendel(false)
    setInitshipdate("")
    setInitAwb("")
    setInitshiptime("")
    setTimeref("")
    setFormErrors({})
    setShipErrors({})
  }

  const mainmodalclose = () => {
    setShipmodal(false)
    setInitisopen(false)
    setAwbisopen(false)
    setIsOpendel(false)
    setInitshipdate("")
    setInitAwb("")
    setInitshiptime("")
    setTimeref("")
    setFormErrors({})
    setShipErrors({})
  }
  const handleOrderChange = (select:any) => {
    setShowLoading(true)
    setFilterArr([])
    setData([])
  
   
    setFilterStatus(select)
    if(select.value == 2){
      
        setData(loadData.current);
        setShowLoading(false)
return
    }
   
    
    if (loadData.current != undefined && loadData.current.length > 0) {

        for(var i=0;i<loadData.current.length;i++){
            var compFlag= 1;
            var IncomFlag = 0;
            var subOrder= loadData.current[i].poojaOfferingOrdersStatusList;
            for(var j=0;j<subOrder.length;j++){
               
                    if(subOrder[j].status != "COMPLETED"){
                        compFlag = 0;
                       
                    }
                

            }
            if(select.value == 0 && compFlag == 0){
                filterArr.push(loadData.current[i]);
            }

            if(select.value == 1 && compFlag == 1){
                filterArr.push(loadData.current[i]);
            }



        }
        
        tableData = filterArr;
        setData(filterArr)
        setShowLoading(false)
        
    }else{
      setShowLoading(false)
    }
    
   }
  return (
    <IonPage>
      <AdminHead User={"user"} Role={"Role"} Name={"Offering Order Management"} />
      <Sidebar />
      <IonContent>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() => setShowLoading(false)}

        // duration={5000}
        />
        {/* <Head User="deity" Role={userdata[0]} /> */}

        <Modal show={statusmodal}>
          <Modal.Body>
            <div className="modal-header">
              <h5 className="modal-title">Status Detail</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setStatusmodal(false)}>
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div>
              <IonRow>
                <IonCol size="4">
                  <IonText>Change status</IonText>
                </IonCol>
                <IonCol size="4">
                  <select onChange={(e: any) => handleStatus(e, statusId)} placeholder="Select Status" style={{ backgroundColor: "white" }}>
                    <option value="SCHEDULED" selected>Select Status</option>

                    <option value="SHIPPED">SHIPPED</option>
                    <option value="COMPLETED">COMPLETED</option>
                  </select>
                </IonCol>
                <IonCol size="4" style={{ textAlign: "center" }}>
                  <IonButton onClick={() => { statusproceed(statusId) }}>Update</IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="4">
                  <IonText>Offering Status</IonText>
                </IonCol>
                <IonCol size="4">
                  {/* <IonCheckbox checked={offerDoneCheck} onIonChange={(e: any) => check(e,item)}></IonCheckbox> */}
                  <IonText style={{ paddingLeft: "1%", paddingBottom: '10px' }}>Pooja Offering Done</IonText>
                </IonCol>
                <IonCol size="4" style={{ textAlign: "center" }}>
                  {/* <IonButton onClick={() => { changeOfferDoneproceed(statusId) }}>Update</IonButton> */}
                </IonCol>

              </IonRow>
            </div>
          </Modal.Body>
        </Modal>
        <div>
          <Modal show={modalopen} className="admin_modal">
            <Modal.Body ref={list}>
              <div className="donation-modal-header admin-user-modal-header">
                <h5 className="donation-modal-title">Order Details</h5>
                <div>
                  <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
                </div>
              </div>
              <div>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Order Number</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{onumber}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Offering Name</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{name}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Description</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{description}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Temple Name</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{tname}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Deity</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{deityname}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Name</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{uname}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Star</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{star}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Price</IonText>
                  </IonCol>

                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>₹ {formatter.format(price)}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Total Amount</IonText>
                  </IonCol>

                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>₹ {formatter.format(totalpaidamount)}</IonText>
                  </IonCol>
                </IonRow>
                {prasadhamdel == false ? <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Delivery Address</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>NA</IonText>
                  </IonCol>
                </IonRow> : <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Delivery Address</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{deliveryAdd}</IonText>
                  </IonCol>
                </IonRow>}
                {dasamt == null ? <></> : <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Dakshina Amount</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>Rs.{formatter.format(dasamt)}</IonText>
                  </IonCol>
                </IonRow>}
                {donamt == null ? <></> : <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Donation Amount</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>Rs.{formatter.format(donamt)}</IonText>
                  </IonCol>
                </IonRow>}
                {fromAndTo && <div> <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>From Date</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{frD}</IonText>
                  </IonCol>
                </IonRow>
                  <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                    <IonCol size="5">
                      <IonText style={{ fontWeight: "bold" }}>To Date</IonText>
                    </IonCol>
                    <IonCol size="7">
                      <IonText style={{ paddingRight: "2%" }}>:</IonText>
                      <IonText>{toD}</IonText>
                    </IonCol>
                  </IonRow></div>}
                {noM == null ? <></> : <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>No Of Months</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{noM}</IonText>
                  </IonCol>
                </IonRow>}
                {listOfD && <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Offering Date(s)</IonText>
                  </IonCol>
                  <IonCol size="7">
                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                    <IonText>{loD}</IonText>
                  </IonCol>
                </IonRow>}
                {/* <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                  <IonCol size="5">
                    <IonText style={{ fontWeight: "bold" }}>Reciept Upload</IonText>
                  </IonCol>
                  <IonCol size="1">:</IonCol>
                  {web && <IonCol size="6">
                    <input onChange={handleimage} type="file" />
                  </IonCol>}
                  {android && <IonCol size="6">
                    <IonRow>
                      <IonIcon onClick={() => takePicture()} src={documentAttachOutline} className="point" size="large"></IonIcon>
                    </IonRow>
                  </IonCol>}
                </IonRow>
                {android && <IonRow>
                  <IonCol size="4" />
                  <IonCol size="8">
                    <IonText>{imagename}</IonText>
                  </IonCol>
                </IonRow>} */}
                <IonRow>
                  <IonCol style={{ textAlign: 'center' }}>
                    <IonButton
                      className="admin_add_btn"
                      onClick={Modalclose}
                    >
                      <span className="button_padding">Close</span>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={androidreceiptmodalcheck} className="gramerce-modal" centered >
            <Modal.Body>
              <IonRow>
                <IonCol style={{ textAlign: "center" }}>
                  <h5 className="mt-2">Do You Want To Upload Again</h5>
                </IonCol>
              </IonRow>
              <IonRow className="mt-12">
                <IonCol size="6" style={{ textAlign: "end" }}>
                  <IonButton onClick={() => takePicture()}>Yes</IonButton>
                </IonCol>
                <IonCol size="6">
                  <IonButton onClick={(e) => checkmodalclose()}>No</IonButton>
                </IonCol>
              </IonRow>
            </Modal.Body>
          </Modal>
          {receiptmodal && (
            <Lightbox
              mainSrc={fileview + "?v=" + new Date()}
              onCloseRequest={() => setReceiptmodal(false)}
              onImageLoad={() => {
                window.dispatchEvent(new Event('resize'));
              }}
            />
          )}
          <div className="container_middle_table page-wrapper admin_background_color">
            <IonRow>
              <Modal show={shipmodal} className="admin_modal">
                <Modal.Body>
                  <div className="donation-modal-header admin-user-modal-header">
                    <h5 className="donation-modal-title">Shipping Details</h5>
                    <div>
                      <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => mainmodalclose()} />
                    </div>
                  </div>
                  <IonRow>
                    <IonCol size="3">
                      <IonText style={{ fontWeight: "bold" }}>
                        Temple Name
                      </IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size="6" style={{ textAlign: "left" }}>
                      <IonText>
                        {templeName}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="3">
                      <IonText style={{ fontWeight: "bold" }}>
                        Offering Name
                      </IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size="6" style={{ textAlign: "left" }}>
                      <IonText>
                        {offeringName}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="3">
                      <IonText style={{ fontWeight: "bold" }}>
                      Order No
                      </IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText style={{ fontWeight: "bold" }}>
                        :
                      </IonText>
                    </IonCol>
                    <IonCol size="6" style={{ textAlign: "left" }}>
                      <IonText>
                        {orderNo}
                      </IonText>
                    </IonCol>
                  </IonRow>

                  <div>
                        
                        <IonList className="horizontal-scroll-list">
                            
                            {statusList.map((item:any, i:any) => (
                                <div key={item} className={(orderStatus == item) ? "chooseOrderTab center_col":"horizontal-scroll-item center_col"}
                                onClick={()=>{changeStatus(item)}}>
                
                {item} 
                {(groupedItems != undefined&& groupedItems[item]!= undefined)?<>
                                     ( {groupedItems[item].length } )
                                </> :<>
                                 ( 0 )</>}</div>
                           
                            ))}
                       
                
                        
                        
                      
                      </IonList>
                     
                     
                                        </div>
                                        {!groupedItems.hasOwnProperty(orderStatus) ? <div style={{width:"100%", height:"200px"}} className="center_col">
                <IonLabel style={{fontSize:"20px"}}>
                    Order not available
                </IonLabel>
             
              </div>: 
                  <div style={{ marginTop: "20px" }} className="manageorderscroll">
                    <table style={{ width: "100%" }}>
                      <tr>
                        <th>
                          S.No
                        </th>
                       
                        <th>
                          Offering Date
                        </th>
                        {/* <th>
                          Order Status
                        </th> */}
                        <th>
                          Action
                        </th>
                      </tr>
                      {Object.entries(groupedItems).map(([status, items1]) => (
            <>
              {(status== orderStatus) && <>
             
                {(items1 as any).map((item:any, i:any) => {
                  var dateSplit = item.orderedDate.split("T")
                  const format = moment(dateSplit[0]).format("DD-MM-YYYY")
                        return (
                          <>
                            <Modal show={receiptmodalcheck} className="gramerce-modal" centered >
                              <Modal.Body>
                                <IonRow>
                                  <IonCol style={{ textAlign: "center" }}>
                                    <h5 className="mt-2">Do You Want To Upload Again</h5>
                                  </IonCol>
                                </IonRow>
                                <IonRow className="mt-12">
                                  <IonCol size="6" style={{ textAlign: "end" }}>
                                    <IonButton onClick={() => {
                                      setReceiptmodalcheck(false)
                                      setTimeout(() => {
                                        handleimage(imagesend, i)
                                      }, 500);
                                    }}>Yes</IonButton>
                                  </IonCol>
                                  <IonCol size="6">
                                    <IonButton onClick={(e) => setReceiptmodalcheck(false)}>No</IonButton>
                                  </IonCol>
                                </IonRow>
                              </Modal.Body>
                            </Modal>
                            <tr className="material-shipment-row">
                              <td>
                                <IonText>{i + 1}</IonText>
                              </td>
                              {/* <td >
                                <IonText>{item.poojaOfferingOrders.orderNumber}</IonText>
                              </td> */}
                              <td>
                                <IonText>{format}</IonText>
                              </td>
                              {/* <td>
                                <IonText>{item.status}</IonText>
                              </td> */}
                              <td>
                                <IonButton className="admin_add_btn" onClick={() => toggleDrawer(item, i)}>
                                  <span className="button_padding">
                                    Manage
                                  </span>
                                </IonButton>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                      </>}
                     
                    </>
                      ))}
                    </table>
                    <div className={`drawer ${initisopen ? 'open' : ''}`}>
                      <div className="drawer-toggle">
                        <div style={{ width: "90%" }}>
                          <IonText>Atmagram</IonText>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <IonIcon className="donation-modal-close-icon close_icon" onClick={() => drawermodal()} src={close} />
                        </div>
                      </div>
                      <IonRow>
                        <IonCol size="5.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            Offering Date
                          </IonText>
                        </IonCol>
                        <IonCol size="0.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size="6" style={{ textAlign: "left" }}>
                          <IonText>
                            {orderDate}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="5.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            Order Number
                          </IonText>
                        </IonCol>
                        <IonCol size="0.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size="6" style={{ textAlign: "left" }}>
                          <IonText>
                            {ordernumber}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      {orderedBy === "" ? <></> :
                        <IonRow>
                          <IonCol size="5.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              Name
                            </IonText>
                          </IonCol>
                          <IonCol size="0.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size="6" style={{ textAlign: "left" }}>
                            <IonText>
                              {orderedBy}
                            </IonText>
                          </IonCol>
                        </IonRow>
                      }
                      {orderByStar === "" ? <></> :
                        <IonRow>
                          <IonCol size="5.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              Star
                            </IonText>
                          </IonCol>
                          <IonCol size="0.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size="6" style={{ textAlign: "left" }}>
                            <IonText>
                              {orderByStar}
                            </IonText>
                          </IonCol>
                        </IonRow>
                      }
                      <div style={{ textAlign: "center", height: "10%", display: "flex", paddingLeft: "10px", alignItems: "end", paddingTop: "10px", paddingBottom: "10px" }}>
                        <IonText style={{ fontWeight: "bolder" }}>
                          Initiate Shipment
                        </IonText>
                      </div>
                      <IonRow>
                        <IonCol style={{ display: "flex", alignItems: "center" }} size-lg="5" size-md="5" size-sm="4" size-xs="4">
                          <IonText>
                            Shipment Date :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" className="offdefaulticons">
                          <IonInput onBlur={() => validation("1")}
                            onKeyDown={(e: any) => {
                              if (e.key !== 'Tab' && e.key !== ' ') {
                                e.preventDefault();
                              }
                              else {
                                if (DateInputRef.current) {
                                  DateInputRef.current.setFocus();
                                }
                              }
                            }}
                            ref={DateInputRef}
                            color="black"
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "7px 0px" }}
                            value={initshipdate} type="date" onIonChange={(e: any) => dateChange(e)}
                            min={datelimit()} className="time_off status-ship" />
                          <div style={{ display: "flex", marginTop: "2%" }}>
                            {shiperror.shipDate && <span className="alert_input_bottom ">{shiperror.shipDate}</span>}
                            {shipErrors.shipDate && <span className="alert_input_bottom ">{shipErrors.shipDate}</span>}
                          </div>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" style={{ paddingLeft: "10px", paddingRight: "10px" }} className="officons">
                          <div style={{ position: "absolute", width: "100%", justifyContent: "flex-end" }}>
                            <IonInput onBlur={() => validation("1")} onKeyDown={(e: any) => e.preventDefault()} value={initshipdate} type="date" onIonChange={(e: any) => dateChange(e)} min={datelimit()} className="time_off status-ship" />
                          </div>
                          <div className="date_icon_style" style={{ position: "relative", textAlign: "right", paddingTop: "3%" }}>
                            <IonIcon onClick={handleIconClick} className="calaendariconorders date_icon_style" src={calendarOutline} />
                          </div>
                          <div style={{ display: "flex", marginTop: "7%" }}>
                            {shiperror.shipDate && <span className="alert_input_bottom ">{shiperror.shipDate}</span>}
                            {shipErrors.shipDate && <span className="alert_input_bottom ">{shipErrors.shipDate}</span>}
                          </div>
                        </IonCol>
                        <IonCol size-lg="1" size-md="1" size-sm="2" size-xs="2" />
                      </IonRow>
                      <IonRow>
                        <IonCol style={{ display: "flex", alignItems: "center" }} size-lg="5" size-md="5" size-sm='4' size-xs="4">
                          <IonText>
                            Shipment Time :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" className="offdefaulticons">
                          <IonInput onBlur={() => validation("2")}
                            onKeyDown={(e: any) => {
                              if (e.key !== 'Tab' && e.key !== ' ') {
                                e.preventDefault();
                              }
                              else {
                                if (timeInputRef.current) {
                                  timeInputRef.current.setFocus();
                                }
                              }
                            }}
                            ref={timeInputRef}
                            type="time" style={{}} value={timeref}
                            onIonChange={(e: any) => gettime(e)} className="time_off status-ship" />
                          <div style={{ display: "flex", marginTop: "2%" }}>
                            {shiperror.shipTime && <span className="alert_input_bottom">{shiperror.shipTime}</span>}
                            {shipErrors.shipTime && <span className="alert_input_bottom ">{shipErrors.shipTime}</span>}
                          </div>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" className="officons">
                          <div style={{ position: "absolute", width: "100%", display: "flex", alignItems: "center" }}>
                            <IonInput onBlur={() => validation("2")} onKeyDown={(e: any) => e.preventDefault()} value={timeref} type="time" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onIonChange={(e: any) => gettime(e)} className="time_off status-ship" />
                          </div>
                          <div className="date_icon_style" style={{ position: "relative", textAlign: "right", paddingTop: "3%", paddingRight: "5px" }}>
                            <IonIcon onClick={handleIconClick} className="calaendariconorders date_icon_style" src={timeOutline} />
                          </div>
                          <div style={{ display: "flex", marginTop: "7%" }}>
                            {shiperror.shipTime && <span className="alert_input_bottom ">{shiperror.shipTime}</span>}
                            {shipErrors.shipTime && <span className="alert_input_bottom ">{shipErrors.shipTime}</span>}
                          </div>
                        </IonCol>
                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" />
                      </IonRow>
                      <IonRow>
                <IonCol style={{ display: "flex", alignItems: "center" }} size-lg="5" size-md="5" size-sm='5' size-xs="5">
                    <IonText>
                      AWB number :
                    </IonText>
                  </IonCol>
                  <IonCol size-lg="6" size-md="6" size-sm="6" size-xs="6" >
                  
              <IonInput type="text" placeholder="AWB number" style={{textAlign:"left"}}
                                                              onBlur={() => validation("6")}
                                                              
                                                              onIonChange={(e: any) =>{
                                                                  validation("6")
                                                                  setInitAwb(e.detail.value.trim()) 
                                                              } }></IonInput>
                                                                <div style={{ display: "flex", marginTop: "3%" }}>
                                                              {shiperror.awbAlert && <span className="alert_input_bottom">{shiperror.awbAlert}</span>}
                                                              {shipErrors.awbAlert && <span className="alert_input_bottom ">{shipErrors.awbAlert}</span>}

                                                              </div>
                    </IonCol>

                </IonRow>
                      <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                        <IonButton className="admin_add_btn" onClick={() => initShip()}>
                          <span className="button_padding">
                            Initiate Shipment
                          </span>
                        </IonButton>
                      </div>

                      <div style={{ textAlign: "center", display: "flex", paddingLeft: "10px", paddingTop: "20px", paddingBottom: "10px" }}>
                        <IonText style={{ fontWeight: "bolder" }}>
                          Receipt Upload
                        </IonText>
                      </div>
                      <div style={{ marginTop: "2%" }}>
                        {/* Drawer content goes here */}
                        {web &&
                          <>
                            <IonRow>
                              <IonCol size-lg="4" size-md="4" size-xs="2" size-sm="2" />
                              <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                                <input className="receipt" style={{ display: "none" }} ref={receiptimageref} onChange={(e: any) => receiptimagecheck(e, wholedata)} type="file" />
                                <IonButton
                                  onClick={() => handleButtonClick(0)}
                                  className="receipt_img_btn image_content"
                                ><span className="button_padding">Choose File</span>
                                </IonButton>
                                <div style={{ display: "flex", marginTop: "2%" }}>
                                  {formErrors.fileimage && <span className="alert_input_bottom ">{formErrors.fileimage}</span>}
                                </div>
                              </IonCol>
                              <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                                {receiptdata === null ?
                                  <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    <IonText>{receiptimage}</IonText>
                                  </div> :
                                  <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                                    <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                                  </div>
                                }
                              </IonCol>
                            </IonRow>
                            <IonRow>
                            </IonRow>
                          </>
                        }
                        {android &&
                          <>
                            <IonRow style={{ margintop: '2%' }}>
                              <IonCol size="3">
                              </IonCol>
                              <IonCol size="4" style={{ display: "flex", alignItems: "center" }}>
                                <IonButton size="small" onClick={() => androidreceiptimagecheck(wholedata)}>
                                  <IonText style={{ fontSize: "10px" }}>
                                    Choose file
                                  </IonText>
                                </IonButton>
                              </IonCol>
                              <IonCol size="5" style={{ display: "flex", alignItems: "center" }}>
                                {receiptdata === null ?
                                  <>{imageuri === "" ? <IonText>Choose Receipt</IonText> : <IonText>Receipt Choosen</IonText>}</> :
                                  <div style={{ width: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                                  </div>
                                }
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol size="3" />
                              <IonCol size="9">
                                {formErrors.androidimage && <span className="alert_input_bottom ">{formErrors.androidimage}</span>}
                              </IonCol>
                            </IonRow>
                          </>
                        }
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                        <IonButton className="admin_add_btn" onClick={() => fileupload(wholedata)}>
                          <span className="button_padding">
                            Upload
                          </span>
                        </IonButton>
                      </div>
                    </div>
                    <div className={`drawer ${awbisopen ? 'open' : ''}`}>
                      <div className="drawer-toggle">
                        <div style={{ width: "90%" }}>
                          <IonText>Atmagram</IonText>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <IonIcon className="donation-modal-close-icon close_icon" onClick={() => drawermodal()} src={close} />
                        </div>
                      </div>
                      <IonRow>
                        <IonCol size="5.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            Offering Date
                          </IonText>
                        </IonCol>
                        <IonCol size="0.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size="6" style={{ textAlign: "left" }}>
                          <IonText>
                            {orderDate}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="5.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            Order Number
                          </IonText>
                        </IonCol>
                        <IonCol size="0.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size="6" style={{ textAlign: "left" }}>
                          <IonText>
                            {ordernumber}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="5.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            AWB number
                          </IonText>
                        </IonCol>
                        <IonCol size="0.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size="6" style={{ textAlign: "left" }}>
                          <IonText>
                            {awbNumber}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      {orderedBy === "" ? <></> :
                        <IonRow>
                          <IonCol size="5.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              Name
                            </IonText>
                          </IonCol>
                          <IonCol size="0.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size="6" style={{ textAlign: "left" }}>
                            <IonText>
                              {orderedBy}
                            </IonText>
                          </IonCol>
                        </IonRow>
                      }
                      {orderByStar === "" ? <></> :
                        <IonRow>
                          <IonCol size="5.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              Star
                            </IonText>
                          </IonCol>
                          <IonCol size="0.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size="6" style={{ textAlign: "left" }}>
                            <IonText>
                              {orderByStar}
                            </IonText>
                          </IonCol>
                        </IonRow>
                      }
                      <div style={{ textAlign: "center", height: "10%", display: "flex", paddingLeft: "10px", alignItems: "end", paddingTop: "10px", paddingBottom: "10px" }}>
                        <IonText style={{ fontWeight: "bolder" }}>
                          Initiate Shipment
                        </IonText>
                      </div>
                      <IonRow>
                        <IonCol size="6" style={{ textAlign: "right" }}>
                          <IonText>
                            Shipment Date :
                          </IonText>
                        </IonCol>
                        <IonCol size="6">
                          <IonText>
                            {showshipdate}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="6" style={{ textAlign: "right" }}>
                          <IonText>
                            Shipment Time :
                          </IonText>
                        </IonCol>
                        <IonCol size="6">
                          <IonText>
                            {showshiptime}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <div style={{ textAlign: "center", display: "flex", paddingLeft: "10px", paddingTop: "20px", paddingBottom: "10px" }}>
                        <IonText style={{ fontWeight: "bolder" }}>
                          Receipt Upload
                        </IonText>
                      </div>
                      <div style={{ marginTop: "2%" }}>
                        {/* Drawer content goes here */}
                        {web &&
                          <>
                            <IonRow>
                              <IonCol size-lg="4" size-md="4" size-xs="2" size-sm="2" />
                              <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                                <input className="receipt" style={{ display: "none" }} ref={receiptimageref1} onChange={(e: any) => receiptimagecheck(e, wholedata)} type="file" />
                                <IonButton
                                  onClick={() => handleButtonClick(1)}
                                  className="receipt_img_btn image_content"
                                ><span className="button_padding">Choose File</span>
                                </IonButton>
                                <div style={{ display: "flex", marginTop: "2%" }}>
                                  {formErrors.fileimage && <span className="alert_input_bottom ">{formErrors.fileimage}</span>}
                                </div>
                              </IonCol>
                              <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                                {receiptdata === null ?
                                  <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    <IonText>{receiptimage}</IonText>
                                  </div> :
                                  <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                                    <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                                  </div>
                                }
                              </IonCol>
                            </IonRow>
                          </>
                        }
                        {android &&
                          <>
                            <IonRow style={{ margintop: '2%' }}>
                              <IonCol size="3">
                              </IonCol>
                              <IonCol size="4" style={{ display: "flex", alignItems: "center" }}>
                                <IonButton size="small" onClick={() => androidreceiptimagecheck(wholedata)}>
                                  <IonText style={{ fontSize: "10px" }}>
                                    Choose file
                                  </IonText>
                                </IonButton>
                              </IonCol>
                              <IonCol size="5" style={{ display: "flex", alignItems: "center" }}>
                                {receiptdata === null ?
                                  <>{imageuri === "" ? <IonText>Choose Receipt</IonText> : <IonText>Receipt Choosen</IonText>}</> :
                                  <div style={{ width: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                                  </div>
                                }
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol size="3" />
                              <IonCol size="9">
                                {formErrors.androidimage && <span className="alert_input_bottom ">{formErrors.androidimage}</span>}
                              </IonCol>
                            </IonRow>
                          </>
                        }
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                        <IonButton className="admin_add_btn" onClick={() => fileupload(wholedata)}>
                          <span className="button_padding">
                            Upload
                          </span>
                        </IonButton>
                      </div>
                     
                <div style={{ textAlign: "center", display: "flex", paddingLeft: "10px", paddingTop: "20px", paddingBottom: "10px" }}>
                  <IonText style={{ fontWeight: "bolder" }}>
                    Order Status
                  </IonText>
                </div>
                <div className="center_col">
                 
                  <select style={{ fontSize: "13px" }} 
                  className="select-status-admin" value={shipStatus} onChange={(e: any) => {setShipStatus(e.target.value)
                    
                  }} placeholder="Select Status">
                                                                    
                                                                    
             <option value="SCHEDULED" selected disabled>Select Status</option>
                                                                    <option value="SHIPPED">SHIPPED</option>
                                                                    <option value="COMPLETED">COMPLETED</option>
                                                                </select>
                                                                </div><div className="center_col">
                                                                <IonButton className="datatable-activate-btn" onClick={() => {statusProceed() }}><span className="button_padding">Update</span></IonButton>
                 
                
                </div>
                
                {shipStatusError!= "" && <IonText className="center_col" style={{fontSize:"10px",color:"red"}}>{shipStatusError}</IonText>}
                    </div>
                    <div className={`drawer ${isOpendel ? 'open' : ''}`}>
                      <div className="drawer-toggle">
                        <div style={{ width: "90%" }}>
                          <IonText>Atmagram</IonText>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <IonIcon className="donation-modal-close-icon close_icon" onClick={() => drawermodal()} src={close} />
                        </div>
                      </div>
                      <IonRow>
                        <IonCol size="5.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            Offering Date
                          </IonText>
                        </IonCol>
                        <IonCol size="0.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size="6" style={{ textAlign: "left" }}>
                          <IonText>
                            {orderDate}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="5.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            Order Number
                          </IonText>
                        </IonCol>
                        <IonCol size="0.5">
                          <IonText style={{ fontWeight: "bold" }}>
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size="6" style={{ textAlign: "left" }}>
                          <IonText>
                            {ordernumber}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      {orderedBy === "" ? <></> :
                        <IonRow>
                          <IonCol size="5.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              Name
                            </IonText>
                          </IonCol>
                          <IonCol size="0.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size="6" style={{ textAlign: "left" }}>
                            <IonText>
                              {orderedBy}
                            </IonText>
                          </IonCol>
                        </IonRow>
                      }
                      {orderByStar === "" ? <></> :
                        <IonRow>
                          <IonCol size="5.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              Star
                            </IonText>
                          </IonCol>
                          <IonCol size="0.5">
                            <IonText style={{ fontWeight: "bold" }}>
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size="6" style={{ textAlign: "left" }}>
                            <IonText>
                              {orderByStar}
                            </IonText>
                          </IonCol>
                        </IonRow>
                      }
                      <div style={{ textAlign: "center", height: "15%", display: "flex", paddingLeft: "10px", alignItems: "end", paddingTop: "10px", paddingBottom: "10px" }}>
                        <IonText style={{ fontWeight: "bolder" }}>
                          Receipt Upload
                        </IonText>
                      </div>
                      <div style={{ marginTop: "2%" }}>
                        {/* Drawer content goes here */}
                        {web &&
                          <>
                            <IonRow>
                              <IonCol size-lg="4" size-md="4" size-xs="2" size-sm="2" />
                              <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                                <input style={{ display: "none" }} ref={receiptimageref2} onChange={(e: any) => receiptimagecheck(e, wholedata)} type="file" />
                                <IonButton
                                  onClick={() => handleButtonClick(2)}
                                  className="receipt_img_btn image_content"
                                ><span className="button_padding">Choose File</span>
                                </IonButton>
                                <div style={{ display: "flex", marginTop: "2%" }}>
                                  {formErrors.fileimage && <span className="alert_input_bottom ">{formErrors.fileimage}</span>}
                                </div>
                              </IonCol>
                              <IonCol size-lg="4" size-md="4" size-xs="5" size-sm="5">
                                {receiptdata === null ?
                                  <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    <IonText>{receiptimage}</IonText>
                                  </div> :
                                  <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                                    <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                                  </div>
                                }
                              </IonCol>
                            </IonRow>
                          </>
                        }
                        {android &&
                          <>
                            <IonRow style={{ margintop: '2%' }}>
                              <IonCol size="3">
                              </IonCol>
                              <IonCol size="4" style={{ display: "flex", alignItems: "center" }}>
                                <IonButton size="small" onClick={() => androidreceiptimagecheck(wholedata)}>
                                  <IonText style={{ fontSize: "10px" }}>
                                    Choose file
                                  </IonText>
                                </IonButton>
                              </IonCol>
                              <IonCol size="5" style={{ display: "flex", alignItems: "center" }}>
                                {receiptdata === null ?
                                  <>{imageuri === "" ? <IonText>Choose Receipt</IonText> : <IonText>Receipt Choosen</IonText>}</> :
                                  <div style={{ width: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    <IonText style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={() => receiptview(wholedata)}>Receipt</IonText>
                                  </div>
                                }
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol size="3" />
                              <IonCol size="9">
                                {formErrors.androidimage && <span className="alert_input_bottom ">{formErrors.androidimage}</span>}
                              </IonCol>
                            </IonRow>
                          </>
                        }
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                        <IonButton className="admin_add_btn" onClick={() => fileupload(wholedata)}>
                          <span className="button_padding">
                            Upload
                          </span>
                        </IonButton>
                      </div>
                    </div>

                  </div>}
                </Modal.Body>
              </Modal>
            </IonRow>
            <IonRow className="overflow">
            </IonRow>
            <div>
              {data.length>0&&
                        <IonRow style={{ zIndex: "999", position: "relative" }} className="select-admin-container">
                <IonCol size="4">
                <IonRow style={{ textAlign: "left" }}>
               <IonLabel className="admin_search_label">
                 Order Status 
               </IonLabel>
             </IonRow> 
   
    <Select
        options={orderStatusList}
        value={filterStatus}
        
        onChange={handleOrderChange}
        placeholder="Select Order Status"
        isClearable
        
        styles={customStyles}
        className="custom-select"
    />
</IonCol>
                </IonRow>}
                        </div>
            <DataTable
              columns={columns}
              data={tableData}
              striped
              noDataComponent="Sorry ! No result found"
              pagination
              customStyles={customTableStyle}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
              subHeader
              subHeaderComponent={subHeaderComponent}
              style={{ width: "100%" }}
              onRowClicked={(item: any) => viewdetails(item)}
            />


          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default Order;
