import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonItem,
  IonToolbar,
  IonButton,
  IonItemDivider,
  IonRow,
  IonCol,
  IonIcon,
  IonList,
  IonPopover,
  IonModal,
  IonText,
  IonImg,
  IonMenu,
  IonHeader,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonListHeader,
  IonLabel,
  IonFab,
  IonFabButton,
  IonFabList,
  useIonAlert,
} from "@ionic/react";
import { Link, NavLink as BaseNavLink, NavLink, useHistory, useLocation } from "react-router-dom";
import "./Userpage.css";
import "./Home.css";
import "./Login.css";

import { menuController } from "@ionic/core/components";

import { arrowDown, arrowForward, menu, menuOutline, person, personCircle, personCircleOutline, personOutline } from "ionicons/icons";
import { Modal } from "react-bootstrap";
import { Capacitor } from "@capacitor/core";
import { BudhaImg, logo } from "../theme/imagePath";
import { AppContext } from "../components/AppContext";
import { Plugins } from '@capacitor/core';
import Authservice from "../Services/user.service";
import UserService from "../Services/auth.service";


interface Props {
  User: string;
  Role: string;
  Name: string;
}
const HeadFoot: React.FC<Props> = (props) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [log, setLog] = useState(false);
  const history = useHistory();
  const { Storage } = Plugins;
  // var userdata = localStorage.getItem("UserData")
  // var uFlag = localStorage.getItem("uFlag")
  //var token = localStorage.getItem("token")
  const { token } = useContext(AppContext)!;
  const { uFlag } = useContext(AppContext)!;
  const { userdata } = useContext(AppContext)!;
  const { setUserData } = useContext(AppContext)!;
  const { setUFlag } = useContext(AppContext)!;
  const { setToken } = useContext(AppContext)!;
  const popover = useRef<HTMLIonPopoverElement>(null);
  const popover1 = useRef<HTMLIonPopoverElement>(null);
  const popover2 = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverOpen1, setPopoverOpen1] = useState(false);
  const [popoverOpen2, setPopoverOpen2] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const [Alert] = useIonAlert();


  function openPopover(e: any) {
    popover.current!.event = e;
    setPopoverOpen(true);
  }

  const openPopover1 = (e: any) => {
    popover1.current!.event = e;
    setPopoverOpen1(true);
  };

  const openPopover2 = (e: any) => {
    popover2.current!.event = e;
    setPopoverOpen2(true);
  };
  const local: any = useLocation()
  var assign: any;
  const logout = async () => {
    UserService.logout(token).then(async (response) => {
      // Cart();
      if (response.data == "Logout successful") {
        try {
          await Storage.clear();
          console.log('Cache cleared successfully.');
        } catch (error) {
          console.error('Error clearing cache:', error);
        }

        setModalopen(false);
        localStorage.clear()
        setUserData(null);
        setUFlag(null)
        setToken(null)
        window.location.hash = "/login";
        if (Capacitor.getPlatform() == "ios") {
          window.location.href = "/login"
        }
        window.location.reload();
      } else {
        setModalopen(false);
        Alert("something went wrong. Please try again")
      }

    })
      .catch((error) => {
        Alert("something went wrong. Please try again")


      });


  };

  const check = () => {
    console.log(token, "token")
    if (token == null || uFlag == "false") {
      history.push('/login')
    }
  }

  const pathname = props.Name;
  const [commanName, setCommanName] = useState("");
  const [pageName, setPageName] = useState("");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Function to update the screen width when the window is resized
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    if (pathname) {
      const [newCommanName, newPageName] = pathname.split(",");
      setCommanName(newCommanName);
      setPageName(newPageName);
    } else {
      setCommanName("");
      setPageName("");
    }

    window.addEventListener('resize', updateScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, [pathname]);

  // useEffect(() => {
  //   check()
  //   var assign: any;
  //   console.log(userdata);
  //   if (assign !== null && assign !== undefined) {
  //     clearInterval(assign);
  //     assign = setInterval(() => {
  //       console.log("counter for 3 seconds");
  //       clearInterval(assign);
  //       localStorage.clear();
  //       window.location.hash = "login";
  //     }, 60000000);
  //   } else {
  //     assign = setInterval(() => {
  //       console.log("counter for 3 seconds");
  //       clearInterval(assign);
  //       localStorage.clear();
  //       window.location.hash = "login";
  //     }, 60000000);
  //   }
  //   console.log("___________", local.pathname)
  // }, []);

  const tempMgmt = () => {
    if (local.pathname === "/tempadmin") {
      window.location.reload()

    } else {

      history.push("/tempadmin")
    }
  }
  const matMgmt = () => {
    if (local.pathname === "/vendormaterials") {
      window.location.reload()

    } else {

      history.push("/vendormaterials")
    }
  }

  const [isPoojaVisible, setIsPoojaVisible] = useState(false);
  const [isGraVisible, setIsGraisible] = useState(false);
  const [isProjVisible, setIsProjisible] = useState(false);

  const isHome = local.pathname === '/home' || local.pathname === '/';
  const mobile = screenWidth <= 1000;
  const displayHeadBackgroundImg = isHome && screenWidth <= 1000;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleUserKeyPress = (event: any) => {
    if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
      setIsPopoverOpen(!isPopoverOpen);
    }
  }

  return (
    <>
      <div className={displayHeadBackgroundImg ? 'safe_area' : 'head_container safe_area'}
        id="main-content">
        <div className={(local.pathname === '/home' || local.pathname === '/' || local.pathname === "/login" || local.pathname === "/signup") && !mobile ? '' : 'head_background_img'}>
          <div className='head_sub_container'>
            <div style={{ justifyContent: "start" }} className="logoImgCls">
              <Link to="/" style={{ textDecoration: "none" }}>
                <IonImg src={logo} className={Capacitor.getPlatform() === "ios" ? "logoimgsize_for_ios" : "logoimgsize"}></IonImg>
              </Link>
            </div>
            <div className="home_head_sub_container2">
              <div className="head_content_container">
                <NavLink to="/home" className="text_decor">
                  <span className={local.pathname === '/home' ? "font_sign_up_active" : "font_sign_up"}>Home</span>
                </NavLink>
                <NavLink to="/temples" className="text_decor">
                  <span className={local.pathname === '/temples' ? "font_sign_up_active" : "font_sign_up"}>Temples</span>
                </NavLink>
                <NavLink to="/offerings" className="text_decor">
                  <span className={local.pathname === '/offerings' ? "font_sign_up_active" : "font_sign_up"}>Pooja Offerings</span>
                </NavLink>
                <NavLink to="/materials" className="text_decor">
                  <span className={local.pathname === '/materials' ? "font_sign_up_active" : "font_sign_up"}>Pooja Materials</span>
                </NavLink>
                <NavLink to="/grammerce" className="text_decor">
                  <span className={local.pathname === '/grammerce' ? "font_sign_up_active" : "font_sign_up"}>Grammerce</span>
                </NavLink>
                <NavLink to="/donations" className="text_decor">
                  <span className={local.pathname === '/donations' ? "font_sign_up_active" : "font_sign_up"}>Donations</span>
                </NavLink>
                <NavLink to="/project" className="text_decor">
                  <span className={local.pathname === '/project' ? "font_sign_up_active" : "font_sign_up"}>Projects</span>
                </NavLink>
              </div>
              <div className="show_slide">
                <IonButtons slot="right">
                  <IonMenuButton className="head_menu_button" />
                </IonButtons>
              </div>
              <div>
                {token === null ?
                  <div style={{ width: "max-content", display: "flex" }}>
                    <NavLink to="/signup" className="text_decor">
                      <span className={local.pathname === '/signup' ? "font_sign_up_active" : "font_sign_up"}>Sign Up</span>
                    </NavLink>
                    <div className="text_decor">
                      <span className="font_sign_up">/</span>
                    </div>
                    <NavLink to="/login" className="text_decor">
                      <span className={local.pathname === '/login' ? "font_sign_up_active" : "font_sign_up"}>Login</span>
                    </NavLink>
                  </div> : <>
                    <div>
                      <IonIcon
                        className="menu_css"
                        src={personCircleOutline}
                        aria-label="Profile"
                        tabIndex={0}
                        id={props.User}
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        onKeyDown={handleUserKeyPress}
                      ></IonIcon>
                      <IonPopover
                        isOpen={isPopoverOpen}
                        trigger={props.User}
                        event="click"
                        id={'profilePop'}
                        dismissOnSelect={true}
                        show-backdrop={false}
                      >
                        <IonContent className="popover_head">
                          <IonList className="popMenuListCls">
                            <Link to="/profile" className="text_decor">
                              <IonItem
                                className="border_menu"
                                button={true}
                                detail={false}
                              >
                                My Profile
                              </IonItem>
                            </Link>
                            {userdata === "ROLE_USER" && uFlag === 'true' ? (
                              <div>
                                <Link to="/myorders" className="text_decor">
                                  <IonItem
                                    className="border_menu"
                                    button={true}
                                    detail={false}
                                  >
                                    My Offerings
                                  </IonItem>
                                </Link>
                                <Link to="/mymaterial" className="text_decor">
                                  <IonItem
                                    className="border_menu"
                                    button={true}
                                    detail={false}
                                  >
                                    My Materials
                                  </IonItem>
                                </Link>
                                <Link to="/mygrammerce" className="text_decor">
                                  <IonItem
                                    className="border_menu"
                                    button={true}
                                    detail={false}
                                  >
                                    My Orders
                                  </IonItem>
                                </Link>
                                <Link to="/mycart" className="text_decor">
                                  <IonItem
                                    className="border_menu"
                                    button={true}
                                    detail={false}
                                  >
                                    My Cart
                                  </IonItem>
                                </Link>
                                <Link to="/mydonation" className="text_decor">
                                  <IonItem
                                    className="border_menu"
                                    button={true}
                                    detail={false}
                                  >
                                    My Donation
                                  </IonItem>
                                </Link>
                                <Link to="/myprojectdonation" className="text_decor">
                                  <IonItem
                                    className="border_menu"
                                    button={true}
                                    detail={false}
                                  >
                                    My Project Donation
                                  </IonItem>
                                </Link>
                              </div>
                            ) : (<div></div>)}
                            <Link to="/change_password" className="text_decor">
                              <IonItem
                                className="border_menu"
                                button={true}
                                detail={false}
                              >
                                Change Password
                              </IonItem>
                            </Link>
                            <IonItem
                              className="border_menu"
                              button={true}
                              detail={false}
                              onClick={(e) => setModalopen(true)}
                              id="logouts-modal"
                            >
                              Sign Out
                            </IonItem>
                          </IonList>
                        </IonContent>
                      </IonPopover>
                      <Modal show={modalopen} className="modal_dialog_width1 logout-modal">
                        <Modal.Body>
                          <IonRow>
                            <IonCol style={{ textAlign: "center" }}>
                              <h5 className="mt-2">Do you want to sign out ?</h5>
                            </IonCol>
                          </IonRow>
                          <IonRow className="mt-12">
                            <IonCol size="6" style={{ textAlign: "end" }}>
                              <IonButton onClick={logout}><span className="button_padding">Yes</span></IonButton>
                            </IonCol>
                            <IonCol size="6">
                              <IonButton onClick={(e) => setModalopen(false)}><span className="button_padding">No</span></IonButton>
                            </IonCol>
                          </IonRow>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </>}
              </div>
            </div>
          </div>

          {mobile ? <>
            <div className="head_text_container">
              <IonText className="head_middle_text">{pageName}</IonText>
            </div>
          </> : <>
            {local.pathname != "/home" && local.pathname != "/" && local.pathname != "" && local.pathname != "/signup" && local.pathname != "/login" ?
              <div className="head_text_container">
                <IonText className="head_middle_text">{pageName}</IonText>
              </div>
              : <></>}</>}

          {/* {local.pathname != "/home" && local.pathname != "/" && local.pathname != "" ?
            <div className="head_bubble_container">
              <div className="head_bubble">
                {commanName && (
                  <Link to="/home" style={{ textDecoration: "none" }}>
                    <IonText className="head_bubble_inside_comman_text">{commanName}</IonText>
                  </Link>
                )}
                {commanName && pageName && (
                  <IonText className="head_bubble_inside_comman_text"> / </IonText>
                )}
                {pageName && (
                  <IonText className="head_bubble_inside_page_text">{pageName}</IonText>
                )}
              </div>
            </div>
            : <></>} */}
        </div>
      </div>
      {userdata != null && userdata != '' && userdata != undefined && mobile ? <IonMenu content-id="main-content" id="menu_id">
        <IonHeader>
          <IonToolbar className="Side_Menu_Head">
            <IonTitle>Atmagram</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            <div>
              {userdata === "ROLE_USER" ? (
                // <IonMenuToggle auto-hide="true">
                <IonList>


                  <Link to="/home" className='link_cls'>
                    <IonItem>
                      <span>
                        Home
                      </span>
                    </IonItem>
                  </Link>


                  {uFlag === 'true' &&


                    <Link to="/temples" className='link_cls'>
                      <IonItem>
                        <span>
                          Temples
                        </span>
                      </IonItem>
                    </Link>
                  }
                  {uFlag === 'true' &&

                    <Link to="/offerings" className='link_cls'>
                      <IonItem>
                        <span>
                          Pooja Offerings
                        </span></IonItem>
                    </Link>

                  }
                  {uFlag === 'true' &&

                    <Link to="/materials" className='link_cls'>
                      <IonItem>
                        <span>
                          Pooja Materials
                        </span>
                      </IonItem>
                    </Link>

                  }
                  {uFlag === 'true' &&

                    <Link to="/grammerce" className='link_cls'>
                      <IonItem>
                        <span>
                          Grammerce
                        </span>
                      </IonItem>
                    </Link>

                  }
                  {uFlag === 'true' &&

                    <Link to="/donations" className='link_cls'>
                      <IonItem>
                        <span>
                          Donations
                        </span>
                      </IonItem>
                    </Link>

                  }
                  {uFlag === 'true' &&

                    <Link to="/project" className='link_cls'>
                      <IonItem>
                        <span>
                          Projects
                        </span>
                      </IonItem>
                    </Link>

                  }
                </IonList>
                // </IonMenuToggle>

              ) : (
                <div>
                  {userdata === "ROLE_ADMIN" && uFlag === 'true' ? (
                    <div>
                      {/* <IonMenuToggle auto-hide="true"> */}
                      <IonList>
                        <Link to='admin_page' className='link_cls'>
                          <IonItem>
                            <span>
                              User Management
                            </span>
                          </IonItem>
                        </Link>

                        <Link to="/deity" className='link_cls'>
                          <IonItem>
                            <span>
                              Deity Management
                            </span>
                          </IonItem>
                        </Link>

                        <Link to="/orderAdmin" className='link_cls'>
                          <IonItem>
                            <span>
                              Offering Order Management
                            </span>
                          </IonItem>
                        </Link>

                        <Link to="/admtemple" className='link_cls'>
                          <IonItem>
                            <span>
                              Temple Management
                            </span>
                          </IonItem>
                        </Link>

                        <Link to="/donationmanage" className='link_cls'>
                          <IonItem>
                            <span>
                              Donation Management
                            </span>
                          </IonItem>
                        </Link>
                      </IonList>
                      {/* </IonMenuToggle> */}
                      <IonItem
                        button
                        onClick={() => {
                          setIsPoojaVisible(true);
                          if (isPoojaVisible === true) {
                            setIsPoojaVisible(false);
                          }
                        }}>

                        <IonLabel>Pooja Material</IonLabel>
                        <IonIcon
                          slot="end"
                          icon={isPoojaVisible ? arrowDown : arrowForward}
                        ></IonIcon>
                      </IonItem>
                      {/* <IonMenuToggle> */}
                      <IonList>

                        <Link to="/materialadm" className='link_cls' hidden={!isPoojaVisible}>
                          <IonItem
                            button
                          >
                            <span >
                              Material Management
                            </span>
                          </IonItem>
                        </Link>

                        <Link to="/admmaterialorders" className='link_cls' hidden={!isPoojaVisible}>
                          <IonItem
                            button>
                            <span>
                              Material Order Management
                            </span>
                          </IonItem>
                        </Link>
                      </IonList>
                      {/* </IonMenuToggle> */}

                      <IonItem
                        button
                        onClick={() => {
                          setIsGraisible(true);
                          if (isGraVisible === true) {
                            setIsGraisible(false);
                          }
                        }}>
                        <IonLabel>Grammerce</IonLabel>
                        <IonIcon
                          slot="end"
                          icon={isGraVisible ? arrowDown : arrowForward}
                        ></IonIcon>
                      </IonItem>
                      {/* <IonMenuToggle> */}
                      <IonList>

                        <Link to="/grammerceadm" className='link_cls' hidden={!isGraVisible}>
                          <IonItem
                            button>
                            <span >
                              Grammerce Management
                            </span>
                          </IonItem>
                        </Link>

                        <Link to="/grammerceorderadm" className='link_cls' hidden={!isGraVisible}>
                          <IonItem
                            button>
                            <span>
                              Grammerce Order Management
                            </span>
                          </IonItem>
                        </Link>

                      </IonList>
                      {/* </IonMenuToggle> */}

                      <IonItem
                        button
                        onClick={() => {
                          setIsProjisible(true);
                          if (isProjVisible === true) {
                            setIsProjisible(false);
                          }
                        }}>

                        <IonLabel>Project</IonLabel>
                        <IonIcon
                          slot="end"
                          icon={isProjVisible ? arrowDown : arrowForward}
                        ></IonIcon>
                      </IonItem>
                      {/* <IonMenuToggle> */}
                      <IonList>

                        <Link to="/projectmanage" className='link_cls' hidden={!isProjVisible}>
                          <IonItem
                            button>
                            <span >
                              Project Management
                            </span>
                          </IonItem>
                        </Link>

                        <Link to="/donationadm" className='link_cls' hidden={!isProjVisible}>
                          <IonItem
                            button>
                            <span>
                              Project Donation
                            </span>
                          </IonItem>
                        </Link>
                      </IonList>
                      <IonList>
                        <Link to="/reports" className='link_cls'>
                          <IonItem>
                            <span>
                              Report
                            </span>
                          </IonItem>
                        </Link>
                      </IonList>
                      {/* </IonMenuToggle> */}

                      <div className="row_headers">

                        {/* <div className="gap_between">
                <IonText className={local.pathname === "/materialadm" ? "active-tab" : local.pathname === "/admmaterialorders" ? "active-tab" : "inactive-tab"} onClick={openPopover}>Pooja Material</IonText>
                <IonPopover ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
                  <IonContent class="ion-padding">
                    <div className="gap_between" onClickCapture={() => setPopoverOpen(false)}>
                      <Link to="/materialadm" style={{ textDecoration: "none" }}>
                        <span className={local.pathname == "/materialadm" ? "active-tab" : "inactive-tab"}>
                          Material Management
                        </span>
                      </Link>
                    </div>
                    <div className="gap_between" onClickCapture={() => setPopoverOpen(false)}>
                      <Link to="/admmaterialorders" style={{ textDecoration: "none" }}>
                        <span className={local.pathname == "/admmaterialorders" ? "active-tab" : "inactive-tab"}>
                          Material Order Management
                        </span>
                      </Link>
                    </div>
                  </IonContent>
                </IonPopover>
              </div>
              <div className="gap_between">
                <IonText className={local.pathname === "/grammerceadm" ? "active-tab" : local.pathname === "/grammerceorderadm" ? "active-tab" : "inactive-tab"} onClick={openPopover1}>Grammerce</IonText>
                <IonPopover ref={popover1} isOpen={popoverOpen1} onDidDismiss={() => setPopoverOpen1(false)}>
                  <IonContent className="ion-padding">
                    <div className="gap_between" style={{ width: "max-content" }} onClickCapture={() => setPopoverOpen1(false)}>
                      <Link to="/grammerceadm" style={{ textDecoration: "none" }}>
                        <span className={local.pathname == "/grammerceadm" ? "active-tab" : "inactive-tab"}>
                          Grammerce Management
                        </span>
                      </Link>
                    </div>
                    <div className="gap_between" style={{ width: "max-content" }} onClickCapture={() => setPopoverOpen1(false)}>
                      <Link to="/grammerceorderadm" style={{ textDecoration: "none" }}>
                        <span className={local.pathname === "/grammerceorderadm" ? "active-tab" : "inactive-tab"}>
                          Grammerce Order Management
                        </span>
                      </Link>
                    </div>
                  </IonContent>
                </IonPopover>
              </div> */}

                        {/* <div className="gap_between">
                <IonText className={local.pathname === "/projectmanage" ? "active-tab" : local.pathname === "/donationadm" ? "active-tab" : "inactive-tab"} onClick={openPopover2}>Project</IonText>
                <IonPopover ref={popover2} isOpen={popoverOpen2} onDidDismiss={() => setPopoverOpen2(false)}>
                  <IonContent class="ion-padding">
                    <div className="gap_between" onClickCapture={() => setPopoverOpen2(false)}>
                      <Link to="/projectmanage" style={{ textDecoration: "none" }}>
                        <span className={local.pathname === "/projectmanage" ? "active-tab" : "inactive-tab"}>
                          Project Management
                        </span>
                      </Link>
                    </div>
                    <div className="gap_between" onClickCapture={() => setPopoverOpen2(false)}>
                      <Link to="/donationadm" style={{ textDecoration: "none" }}>
                        <span className={local.pathname === "/donationadm" ? "active-tab" : "inactive-tab"}>
                          Project Donation
                        </span>
                      </Link>
                    </div>
                  </IonContent>
                </IonPopover>
              </div> */}
                      </div>
                    </div>

                  ) : userdata === "ROLE_TEMPADMIN" && uFlag === 'true' ? (
                    // <IonMenuToggle auto-hide="false">
                    <IonList>
                      <Link to='tempadmin' className='link_cls'>
                        <IonItem>
                          <span>
                            Temple Management
                          </span> </IonItem>
                      </Link>

                      <Link to="/order" className='link_cls'>
                        <IonItem>
                          <span>
                            Order Management
                          </span></IonItem>
                      </Link>

                      <Link to="/reports" className='link_cls'>
                        <IonItem>
                          <span>
                            Report
                          </span>
                        </IonItem>
                      </Link>
                    </IonList>
                    // </IonMenuToggle>



                  ) : userdata === "ROLE_VENDOR" && uFlag === 'true' ? (
                    // <IonMenuToggle auto-hide="false">
                    <IonList>

                      <Link to='/vendormaterials' className='link_cls'>
                        <IonItem>
                          <span>
                            Material Management
                          </span> </IonItem>
                      </Link>

                      <Link to="/vendormateriaorders" className='link_cls'>
                        <IonItem>
                          <span>
                            Material Order Management
                          </span> </IonItem>
                      </Link>

                      <Link to="/reports" className='link_cls'>
                        <IonItem>
                          <span>
                            Report
                          </span>
                        </IonItem>
                      </Link>
                    </IonList>
                    // </IonMenuToggle>


                  ) : userdata === "ROLE_PROJECTADMIN" && uFlag === 'true' ? (
                    // <IonMenuToggle auto-hide="false">
                    <IonList>

                      <Link to="/projectadmin" className='link_cls'>
                        <IonItem>
                          <span>
                            Project Management
                          </span> </IonItem>
                      </Link>

                      <Link to="/projectdonate" className='link_cls'>
                        <IonItem>
                          <span>
                            Project Donation
                          </span> </IonItem>
                      </Link>

                      <Link to="/reports" className='link_cls'>
                        <IonItem>
                          <span>
                            Report
                          </span>
                        </IonItem>
                      </Link>
                    </IonList>
                    // </IonMenuToggle>


                  ) : <div>{Capacitor.getPlatform() === "web" ?
                    // <IonMenuToggle auto-hide="false">
                    <IonList lines="none">

                      <Link to="/" className='link_cls'>
                        <IonItem>
                          <span>
                            Home
                          </span></IonItem>
                      </Link>

                      <Link to="/temples" className='link_cls'>
                        <IonItem>
                          <span>
                            Temples
                          </span></IonItem>
                      </Link>

                      <Link to="/offerings" className='link_cls'>
                        <IonItem>
                          <span >
                            Pooja Offerings
                          </span></IonItem>
                      </Link>

                      <Link to="/materials" className='link_cls'>
                        <IonItem>
                          <span>
                            Pooja Materials
                          </span></IonItem>
                      </Link>

                      <Link to="/grammerce" className='link_cls'>
                        <IonItem>
                          <span >
                            Grammerce
                          </span></IonItem>
                      </Link>

                      <Link to="/donations" className='link_cls'>
                        <IonItem>
                          <span>
                            Donations
                          </span></IonItem>
                      </Link>



                      <Link to="/project" className='link_cls'>
                        <IonItem>
                          <span>
                            Projects
                          </span></IonItem>
                      </Link>

                    </IonList>

                    // </IonMenuToggle> 
                    : <></>}</div>}
                </div>
              )}


            </div>


          </IonList>
        </IonContent>
      </IonMenu> : <></>}
    </>
  );
};
export default HeadFoot;