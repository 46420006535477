import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonButton,
    IonLabel,
    IonInput,
    IonImg,
    IonText,
    IonItemDivider,
    IonLoading,
    IonSelect,
    IonSelectOption,
    useIonAlert,
    IonIcon,
    IonGrid,
    IonTextarea,
    IonItem,
    IonSearchbar
} from "@ionic/react";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";
import Authservice from "../../Services/user.service";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import { Capacitor } from "@capacitor/core";
import { addCircleSharp, addOutline, arrowBack, close } from "ionicons/icons";
import { useForm } from "react-hook-form";
import Select, { components } from 'react-select';
import Lightbox from "react-image-lightbox";
import customTableStyle from "../../components/CustomTableStyle";
import Sidebar from "./sidebar";
import AdminHead from "./adminHead";
import "./materialmanage.css";

const Materialmanage: React.FC = () => {
    const [presentAlert] = useIonAlert();
    const [comment, setComment] = useState("")
    const [usertoken] = useState(localStorage.getItem("token"));
    const [materialid, setMaterialid] = useState("");
    const userdata: any = useState(localStorage.getItem("UserData"));
    const uFlag: any = useState(localStorage.getItem("uFlag"));
    const [filterText, setFilterText] = React.useState("");
    const [material, setMaterial]: any = useState([]);
    const [materialorder, setMaterialorder] = useState([]);
    const [viewmaterial, setViewmaterial]: any = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [modalopen, setModalopen] = useState(false);
    const [modalopen1, setModalopen1] = useState(false);
    const [index, setIndex] = useState(0);
    const [productname, setProductname] = useState();
    const [brandname, setBrandname] = useState();
    const [price, setPrice]: any = useState("");
    const [quantity, setQuantity] = useState(0);
    const [amtpriest, setAmtpriest] = useState();
    const [amttemple, setAmttemple] = useState();
    const [date, setDate] = useState();
    const [number, setNumber] = useState();
    const [psize, setPsize] = useState("");
    const [punit, setPunit] = useState();
    const [isHovered, setIsHovered] = useState(false);
    const [name, setName] = useState("");
    const [vendorname, setVendorname] = useState("");
    const history = useHistory();
    const [fileName, setFileName] = useState("");

    if (material != undefined && material.length > 0) {
        material.forEach((item: any, index: any) => { item.serial = index + 1; });
    }
    if (materialorder != undefined && materialorder.length > 0) {
        materialorder.forEach((item: any, index: any) => { item.serial = index + 1; });
    }

    const [showLoading, setShowLoading] = useState(false);
    const [venAdm, setVenAdm] = useState("");
    const [venAdmList, setVenAdmList]: any = useState([]);
    const [req, setReq] = useState(false)
    const [isedit, setIsedit] = useState(false);
    const [brand, setBrand] = useState("");

    const [pUnit, setPUnit]: any = useState("");

    const formatter = new Intl.NumberFormat('en-IN')

    const [stock, setStock] = useState("");
    const [image, setImage] = useState("");
    const [matImage, setMatImage] = useState("");
    const [activeflag, setActiveflag]: any = useState("")
    const [mid, setMid] = useState("")
    const [viewcomment, setViewcomment] = useState("")
    const [inactivecomment, setInactivecomment] = useState("")
    const [images, setImages]: any = useState("");
    const dataFetchedRef = useRef(false);
    const [isSearch, setIsSearch] = useState(false)
    const [alertmsg, setAlertMsg]: any = useState("")
    const [alertModal, setAlertModal]: any = useState(false)
    const alertRef = useRef(null);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_ADMIN") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            // showVenAdmList();
            //Materialadm();
            getStates();
        }
    }, [])

    const getStates = () => {
        setShowLoading(true)
        Authservice.getAllstates()
            .then((response) => {
                console.log(response)
                setStates(response.data)
                setShowLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setShowLoading(false)
            })
    }

    const viewdetails = (item: any) => {
        setReq(true)
        setImages("")
        console.log(item);
        setActiveflag(item.activeFlag)
        setMid(item.id)
        if (item.productName === null) {
            setName("");
        } else {
            setName(item.productName);
        }
        if (item.brandName === null) {
            setBrand("");
        } else {
            setBrand(item.brandName);
        }
        if (item.activeComment === null) {
            setViewcomment("");
        } else {
            setViewcomment(item.activeComment);
            console.log(item.activeComment)
        }
        if (item.inactiveComment === null) {
            setInactivecomment("");
        } else {
            setInactivecomment(item.inactiveComment);
        }
        if (item.price === null) {
            setPrice("");
        } else {
            setPrice(item.price);
        }
        if (item.materialStock === null) {
            setStock("");
        } else {
            setStock(item.materialStock);
        }
        if (item.quantity === null) {
            setQuantity(0);
        } else {
            setQuantity(0)
        }
        if (item.packageSize === null) {
            setPsize("");
        } else {
            setPsize(item.packageSize);
        }
        if (item.packageUnit === null) {
            setPUnit("");
        } else {
            setPUnit(item.packageUnit);
        }
        console.log(quantity)
        setIsedit(true)
        setImage(item.image + "?v=" + new Date());
        setMatImage(item.image + "?v=" + new Date())
        setFormErrors({})
        setModalopen(true)
    };
    const showVenAdmList = () => {
        setShowLoading(true);
        Authservice.vendorAdmList(usertoken)
            .then((response) => {
                console.log(response.data);
                var list: any = response.data;
                for (let i = 0; i < list.length; i++) {
                    venAdmList.push(list[i]);
                }
                setShowLoading(false);
            })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false);
            });
    }
    const [reversematerials, setReversematerials] = useState([])
    const Materialadm = (e: any) => {
        setShowLoading(true);
        setMaterial([]);
        Authservice.materialList(usertoken, e).then((response) => {
            console.log(response);
            var data = response.data;
            const array: any = [...data].reverse();
            setMaterial(array)
            // for (let i = 0; i < response.data.length; i++) {
            //     setMaterial((material: any) => [...material, response.data[i]])
            // }


            setMaterialid(response.data.id);
            setShowLoading(false);
            setIsSearch(true)
        }).catch((error) => {
            alert("Something went wrong, please try again later.");
            setShowLoading(false);
        });
    }
    const handleStatus = (status: any, id: any, comment: any) => {
        setShowLoading(true);
        Authservice.statusVenMat(usertoken, status, id, comment).then((response) => {
            console.log(response)
            Materialadm(venAdm)
            // window.location.reload()
            setShowLoading(false);
        })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false);
            });

    }
    const openmodal = () => {
        setReq(false)
        setIsedit(false)
        setFormErrors({})
        setModalopen1(true)
        setImage("");
        setImages("")
        setName("");
        setBrand("");
        setPrice("");
        setPUnit("");
        setPsize("");
        setStock("");
        setFileName("")
    };

    const columns: any = [
        {
            name: "S.No",
            selector: (item: any, i: any) => item.serial,
            width: "90px",
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.serial}</div>
                </div>
            )
        },
        {
            name: "Material Image",
            selector: (item: any) => item.image,
            sortable: false,

            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <img style={{ maxWidth: "60px", maxHeight: "70px" }} alt="img" src={item.image + "?v=" + new Date()}></img>
                </div>
            )
        },
        {
            name: "Brand Name",
            selector: (item: any) => item.brandName,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.brandName}</div>
                </div>
            )
        },
        {
            name: "Product Name",
            selector: (item: any) => item.productName,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.productName}</div>
                </div>
            )
        },

        {
            name: "Price (₹)",
            selector: (item: any) => parseInt(item.price, 10),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{formatter.format(parseInt(item.price, 10))}</div>
                </div>
            )
        },
        {
            name: "In Stock",
            selector: (item: any) => parseInt(item.materialStock, 10),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{parseInt(item.materialStock, 10)}</div>
                </div>
            )
        },
        ,
        {
            name: "Status",
            selector: (item: any) => item.activeFlag,
            sortable: false,
            cell: (item: any) => (
                <div className="center_col_tab">
                    {item.activeFlag === "true" ? (
                        <IonButton color="danger" className="datatable-activate-btn" onClick={() =>
                            presentAlert({
                                header: 'Alert',
                                message: ' If you deactivate, the customers will not be able to view the selected material to place orders. Do you want to deactivate?',
                                buttons: [
                                    {
                                        text: 'No',
                                        role: 'cancel',
                                        handler: () => {
                                            // setHandlerMessage('Alert canceled');
                                        },
                                    },
                                    {
                                        text: 'Yes',
                                        role: 'confirm',
                                        handler: (alertData) => {
                                            handleStatus(false, item.id, alertData.comment)
                                        },
                                    },
                                ],
                                inputs: [
                                    {
                                        name: 'comment',
                                        type: 'text',
                                        value: comment,
                                        placeholder: 'Enter your comment'
                                    }
                                ]
                            })

                        }><span className="button_padding">Deactivate</span></IonButton>

                    ) : (
                        <IonButton color="success" className="datatable-activate-btn" onClick={() => presentAlert({
                            header: 'Alert',
                            message: 'If you activate, the customers will be able to view the selected material to place orders. Do you want to activate?',
                            buttons: [
                                {
                                    text: 'No',
                                    role: 'cancel',
                                    handler: () => {
                                        // setHandlerMessage('Alert canceled');
                                    },
                                },
                                {
                                    text: 'Yes',
                                    role: 'confirm',
                                    handler: (alertData) => {
                                        handleStatus(true, item.id, alertData.comment)
                                    },
                                },
                            ],
                            inputs: [
                                {
                                    name: 'comment',
                                    type: 'text',
                                    value: comment,
                                    placeholder: 'Enter your comment'
                                }
                            ]
                        })}><span className="button_padding">Activate</span></IonButton>

                    )}
                </div>
            )
        },
    ]


    const imageinputref: any = useRef(null)
    const imageinputref1: any = useRef(null)

    const handleimage = (e: any) => {

        var file: any = e.target.files[0];
        const splited: any = file.name.split(".")
        if (splited[splited.length - 1] === "jpg" || splited[splited.length - 1] === "png"
            || splited[splited.length - 1] === "jpeg") {
            console.log(images)
            const objectUrl = URL.createObjectURL(file);
            setImages(file);
            setImage(objectUrl);
            setFileName(file.name)

        } else {
            showAlert(file.name + " is Unsupported File Format, Supported File Formats Are JPG, PNG,JPEG")
            // setAlertModal(true)
            if (imageinputref.current) {
                imageinputref.current.value = null
            }
            if (imageinputref1.current) {
                imageinputref1.current.value = null
            }
            return false;
        }
        console.log(e.target.files);

    };
    const cancel = () => {
        Materialadm(venAdm);
        setModalopen(false)
        setModalopen1(false)
    }
    var tableData: any = [];
    if (material != undefined && material.length > 0) {
        tableData = material.filter((item: any) => {
            const serializedItem = JSON.stringify(item).toLowerCase();
            const searchTerm = filterText.toLowerCase();

            const regex = new RegExp(`\\b${searchTerm}\\b`);

            return regex.test(serializedItem);
        });

    }
    const handleButtonClick = (e: any) => {
        if (e == 1) {
            if (imageinputref.current) {
                imageinputref.current.click();
            }
        } else {
            if (imageinputref1.current) {
                imageinputref1.current.click();
            }
        }
    };


    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };
    const onSubmit = () => {
        console.log(images)
        setShowLoading(true);
        Authservice.addMaterial(usertoken, isedit, brand, price, name, images, pUnit, psize, stock, mid, quantity, venAdm).then((response) => {
            console.log(quantity)
            setShowLoading(false);
            if (response.data.responseText.startsWith("SUCCESS")) {
                if (isedit) {
                    setModalopen(false)
                    showAlert("Updated Successfully")

                    //window.location.reload();
                }
                else {
                    setModalopen1(false)
                    showAlert("Added Successfully")

                    // window.location.reload();
                }
                setIndex(0)
                Materialadm(venAdm);
            } else if (response.data.responseText === "ERROR:Product Name Already Exist") {
                showAlert("Product Name Already Exist")

                console.log(response)
            }
            else {
                showAlert(response.data.responseText)

            }
        })
            .catch((error) => {
                showAlert("Something went wrong, please try again later.");

                setShowLoading(false);
            });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        }
        return (
            <IonRow className="table_btn_container">
                <div>
                    <IonButton onClick={openmodal} className="admin_add_btn">
                        <IonIcon src={addOutline} />
                        <span className="button_padding">Add Materials</span>
                    </IonButton>
                </div>
                <div>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle]);

    const {
        handleSubmit,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
    });

    const preventdata = (e: any) => {
        e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
        e.target.addEventListener('keydown', function (u: any) {
            if (u.which === 38 || u.which === 40) {
                u.preventDefault();
            }
        })
        // e.target.addEventListener("keydown", function (d: any) { d.preventDefault() })
    }

    const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(null);

    const handleChange = (selectedOption: any) => {
        if (selectedOption !== null) {
            setFormErrors({ ...formErrors, vendorAdmin: "" })
        }
        else {
            setVenAdm("")
            setFormErrors({ ...formErrors, vendorAdmin: "Select a Vendor admin" })
        }
        setSelectedOption(selectedOption);
        setVenAdm(selectedOption.value);
        setMaterial([]);
        Materialadm(selectedOption.value);
        console.log(selectedOption, "kmkkk")
    };

    const vendorListOptions = venAdmList
        .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
        .map((tempAdm: any, index: any) => ({
            value: tempAdm.id,
            label: tempAdm.firstName
        }));

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const [states, setStates] = useState([]);

    const [stateOption, setStateOption] = useState<{ value: string; label: string } | null>(null);

    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [formErrors, setFormErrors]: any = useState({});
    var stateLiistOptions: any = [];
    if (states != undefined && states.length > 0) {
        stateLiistOptions = states.map((e: any, index: any) => ({
            value: e,
            label: e
        }));
    }


    const handleStateChange = (e: any) => {
        setVenAdmList([])
        setMaterial([])
        setSelectedOption(null)
        if (e !== null) {
            setFormErrors({ ...formErrors, state: "" })
            setState(e.value);
        }
        else {
            setState("");
            setFormErrors({ ...formErrors, state: "Select a State" })
        }
        setStateOption(e);
    }

    const handleSearch = () => {
        const errors: any = {};
        if (state.length === 0) {
            errors.state = 'Please Select a State';
        }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setSelectedOption(null)
            setMaterial([])
            setShowLoading(true)
            setVenAdmList([])
            Authservice.getVendorList(usertoken, state, city)
                .then((response) => {
                    console.log(response, "jnjj")
                    if (response.data.length != 0) {
                        var list: any = response.data;
                        setVenAdmList(list)
                    }
                    else {
                        presentAlert("No Results Found !")
                    }

                    setShowLoading(false);
                })
                .catch((error) => {
                    console.log(error)
                    setShowLoading(false);
                })
        }
    }

    const [showModal, setShowModal] = useState(false)
    const [addshowModal, setAddshowModal] = useState(false)
    const imagenew = () => {
        setShowModal(true)
    }
    const showAlert = (msg: any) => {
        presentAlert({
            header: 'Atmagram',
            cssClass: 'custom-alert',
            message: msg,
            buttons: [
                {
                    text: 'ok',
                    role: 'cancel',
                    handler: () => {


                        //window.location.reload();
                    },
                }
            ]
        })
    }

    const addimagenew = () => {
        setAddshowModal(true)
    }

    const validation = (e: any) => {
        if (e == 1) {
            if (name.length === 0) {
                setFormErrors({ ...formErrors, name: "Please Enter The Name" })
            }
        }
        if (e == 2) {
            if (brand.length === 0) {
                setFormErrors({ ...formErrors, brand: "Please Enter The Brand" })
            }
        }
        if (e == 3) {
            if (price.length === 0) {
                setFormErrors({ ...formErrors, price: "Please Enter The Price" })
            }
        }
        if (e == 4) {
            if (stock.length === 0) {
                setFormErrors({ ...formErrors, stock: "Please Enter The Stock" })
            }
        }
        if (e == 5) {
            if (psize.length === 0) {
                setFormErrors({ ...formErrors, size: "Please Enter The Size" })
            }
        }
        if (e == 6) {
            if (pUnit.length === 0) {
                setFormErrors({ ...formErrors, unit: "Please Enter The Unit" })
            }
        }
        if (e == 7) {
            if (state.length === 0) {
                setFormErrors({ ...formErrors, state: "Please Select The State" })
            }
        }
        if (e == 8) {
            if (venAdm.length === 0) {
                setFormErrors({ ...formErrors, vendorAdmin: "Please Select a Vendor admin" })
            }
        }
    }

    const namechange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, name: "" })
        }
        setName(e.detail.value!)
    }
    const brandChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, brand: "" })
        }
        setBrand(e.detail.value!)
    }
    const priceChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, price: "" })
        }
        setPrice(e.detail.value!)
    }
    const stockChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, stock: "" })
        }
        setStock(e.detail.value!)
    }
    const sizeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, size: "" })
        }
        setPsize(e.detail.value!)
    }
    const unitChange = (e: any) => {
        if (e.target.value !== "") {
            setFormErrors({ ...formErrors, unit: "" })
        }
        setPUnit(e.target.value!)
    }

    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Material Management"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <Modal show={alertModal} centered >
                    <Modal.Body ref={alertRef}>
                        <div style={{ textAlign: "center" }}><div>
                            <IonText> {alertmsg}</IonText>
                        </div><br></br>
                            <div>
                                <IonButton className="admin_add_btn">
                                    <span onClick={() => { setAlertModal(false) }} className="button_padding">ok</span>
                                </IonButton>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={modalopen} className="admin_modal">
                    <Modal.Body>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Update Material</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
                            </div>
                        </div>
                        <IonGrid>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Name <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Name"
                                                type="text"
                                                value={name}
                                                onBlur={() => validation(1)}
                                                onIonChange={(e) => namechange(e)}
                                                readonly={req}
                                            ></IonInput>
                                        </div>
                                        {formErrors.name !== "" && <span className="alert_input_bottom ">{formErrors.name}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Brand <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Brand"
                                                type="text"
                                                value={brand}
                                                onBlur={() => validation(2)}
                                                onIonChange={(e) => brandChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.brand !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.brand}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Price <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Price"
                                                type="number"
                                                value={price}
                                                min={0}
                                                onBlur={() => validation(3)}
                                                onFocus={(e) => preventdata(e)}
                                                onIonChange={(e) => priceChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.price !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.price}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">In Stock <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                type="number"
                                                placeholder="In Stock"
                                                value={stock}
                                                onBlur={() => validation(4)}
                                                onFocus={(e) => preventdata(e)} min={0}
                                                onIonChange={(e) => stockChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.stock !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.stock}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Package Size <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Package size"
                                                type="number"
                                                min={0}
                                                value={psize}
                                                onBlur={() => validation(5)}
                                                onFocus={(e) => preventdata(e)}
                                                onIonChange={(e) => sizeChange(e)}
                                            />
                                        </div>
                                        {formErrors.size !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.size}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Package Unit <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <select
                                                placeholder="Package unit"
                                                value={pUnit}
                                                onBlur={() => validation(6)}
                                                onChange={(e) => unitChange(e)}
                                                className="select_package_admin"
                                            >
                                                <option disabled selected value="">Package Unit</option>
                                                <option>Kilogram</option>
                                                <option>Litre</option>
                                                <option>Gram</option>
                                                <option>Pound</option>
                                                <option>Millilitre</option>
                                                <option>Meter</option>
                                                <option>Piece</option>
                                            </select>
                                        </div>
                                        {formErrors.unit !== "" && <span className="alert_input_bottom " >{formErrors.unit}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow style={{ alignItems: "flex-end" }}>
                                    {activeflag === "true" ? <>
                                    </> : <>
                                        {inactivecomment === "" ? <></> :
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonLabel className="label_align">Comment<span style={{ color: "red" }}>*</span></IonLabel>
                                                <div>
                                                    <IonTextarea
                                                        style={{ overflowY: "auto", height: "40px" }}
                                                        value={inactivecomment}
                                                        readonly
                                                    />
                                                </div>
                                            </IonCol>
                                        }
                                    </>}
                                    <IonCol sizeMd="6" sizeXs="12">
                                        <IonRow className="image_div" style={{ margin: activeflag === "false" ? "0px" : "10px 0px" }}>
                                            <IonCol size="6" className="center_col image_content">
                                                {images != "" ? <span className="image_content_spn">{fileName}</span> :
                                                    <span className="image_content_spn">Select a File <span style={{ color: "red" }}>*</span></span>}
                                            </IonCol>
                                            <IonCol size="6" className="image_content">
                                                <input
                                                    className={images != "" ? "" : "image_chose"}
                                                    type="file"
                                                    ref={imageinputref}
                                                    onChange={(e) => handleimage(e,)}
                                                    style={{ display: 'none' }}
                                                />
                                                <IonButton
                                                    onClick={() => handleButtonClick(1)}
                                                    className="admin_img_btn image_content"
                                                ><span className="button_padding">Choose File</span>
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            </form>
                            {image.length === 0 ? (
                                <div></div>
                            ) : (
                                <IonRow>
                                    <IonCol className="Col_1">
                                        <div onClick={() => imagenew()}>
                                            <IonImg className="image_design_deity" src={image}></IonImg>
                                        </div>
                                    </IonCol>
                                </IonRow>
                            )}
                            {showModal && (
                                <Lightbox
                                    mainSrc={image}
                                    onCloseRequest={() => setShowModal(false)}
                                    onImageLoad={() => {
                                        window.dispatchEvent(new Event('resize'));
                                    }}
                                />
                            )}
                            <IonRow style={{ textAlign: "center" }}>
                                <IonCol size="12">
                                    <IonRow>
                                        <IonCol style={{ display: "flex", justifyContent: "flex-end" }} size="6">
                                            <div style={{ width: "fit-content" }} className={name.length === 0 ||
                                                price.length === 0 ||
                                                brand.length === 0 ||
                                                pUnit === "" ||
                                                psize === "" ||
                                                stock.length === 0 ||
                                                image.length === 0 ? "disableCursor" : ""}>
                                                <IonButton
                                                    className="admin_add_btn"
                                                    disabled={
                                                        name.length === 0 ||
                                                        price.length === 0 ||
                                                        brand.length === 0 ||
                                                        pUnit === "" ||
                                                        psize === "" ||
                                                        stock.length === 0 ||
                                                        image.length === 0
                                                    }
                                                    onClick={onSubmit}
                                                    type="submit"
                                                >
                                                    <span className="button_padding">Submit</span>
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                        <IonCol style={{ textAlign: "start" }} size="6">
                                            <IonButton
                                                className="admin_cencel_btn" fill="outline"
                                                onClick={cancel}
                                            >
                                                <span className="button_padding">Cancel</span>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Modal.Body>
                </Modal>
                <Modal show={modalopen1} className="admin_modal">
                    <Modal.Body>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Add New Material</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen1(false)} />
                            </div>
                        </div>
                        <IonGrid >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Name <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Name"
                                                type="text"
                                                value={name}
                                                onBlur={() => validation(1)}
                                                onIonChange={(e) => namechange(e)}
                                                readonly={req}
                                            ></IonInput>
                                        </div>
                                        {formErrors.name !== "" && <span className="alert_input_bottom ">{formErrors.name}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Brand <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Brand"
                                                type="text"
                                                value={brand}
                                                onBlur={() => validation(2)}
                                                onIonChange={(e) => brandChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.brand !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.brand}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Price <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Price"
                                                type="number"
                                                value={price}
                                                min={0}
                                                onBlur={() => validation(3)}
                                                onFocus={(e) => preventdata(e)}
                                                onIonChange={(e) => priceChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.price !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.price}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">In Stock <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                type="number"
                                                placeholder="In Stock"
                                                value={stock}
                                                onBlur={() => validation(4)}
                                                onFocus={(e) => preventdata(e)} min={0}
                                                onIonChange={(e) => stockChange(e)}
                                                required
                                            ></IonInput>
                                        </div>
                                        {formErrors.stock !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.stock}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Package Size <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput
                                                placeholder="Package size"
                                                type="number"
                                                min={0}
                                                value={psize}
                                                onBlur={() => validation(5)}
                                                onFocus={(e) => preventdata(e)}
                                                onIonChange={(e) => sizeChange(e)}
                                            />
                                        </div>
                                        {formErrors.size !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.size}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="label_align">Package Unit <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <select
                                                placeholder="Package unit"
                                                value={pUnit}
                                                onBlur={() => validation(6)}
                                                onChange={(e) => unitChange(e)}
                                                className="select_package_admin"
                                            >
                                                <option disabled selected value="">Package Unit</option>
                                                <option>Kilogram</option>
                                                <option>Litre</option>
                                                <option>Gram</option>
                                                <option>Pound</option>
                                                <option>Millilitre</option>
                                                <option>Meter</option>
                                                <option>Piece</option>
                                            </select>
                                        </div>
                                        {formErrors.unit !== "" && <span className="alert_input_bottom " >{formErrors.unit}</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeMd="6" sizeXs="12">
                                        <IonRow className="image_div">
                                            <IonCol size="6" className="center_col image_content">
                                                {images != "" ? <span className="image_content_spn">{fileName}</span> :
                                                    <span className="image_content_spn">No file Chosen <span style={{ color: "red" }}>*</span></span>}
                                            </IonCol>
                                            <IonCol size="6" className="image_content">
                                                <input
                                                    className={images != "" ? "" : "image_chose"}
                                                    type="file"
                                                    ref={imageinputref1}
                                                    onChange={(e) => handleimage(e)}
                                                    style={{ display: 'none' }}
                                                />
                                                <IonButton
                                                    onClick={() => handleButtonClick(2)}
                                                    className="admin_img_btn image_content"
                                                ><span className="button_padding">Choose File</span>
                                                </IonButton>
                                            </IonCol>

                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            </form>
                            {image.length === 0 ? (
                                <div></div>
                            ) : (
                                <IonRow>
                                    <IonCol className="Col_1">
                                        <div onClick={() => addimagenew()}>
                                            <IonImg className="image_design" src={image}></IonImg>
                                        </div>
                                    </IonCol>
                                </IonRow>
                            )}
                            {addshowModal && (
                                <Lightbox
                                    mainSrc={image}
                                    onCloseRequest={() => setAddshowModal(false)}
                                    onImageLoad={() => {
                                        window.dispatchEvent(new Event('resize'));
                                    }}
                                />
                            )}
                            <IonRow className="admin-submit-cancel">
                                <IonCol size="12">
                                    <IonRow style={{ alignItems: "center" }}>
                                        <IonCol style={{ display: "flex", justifyContent: "flex-end" }} size="6">
                                            <div style={{ width: "fit-content" }} className={name.length === 0 ||
                                                price.length === 0 ||
                                                brand.length === 0 ||
                                                pUnit === "" ||
                                                psize === "" ||
                                                stock.length === 0 ||
                                                image.length === 0 ? "disableCursor" : ""}>
                                                <IonButton
                                                    disabled={
                                                        name.length === 0 ||
                                                        price.length === 0 ||
                                                        brand.length === 0 ||
                                                        pUnit === "" ||
                                                        psize === "" ||
                                                        stock.length === 0 ||
                                                        image.length === 0
                                                    }
                                                    onClick={onSubmit}
                                                    type="submit"
                                                    className="admin_add_btn"
                                                >
                                                    <span className="button_padding">Submit</span>
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                        <IonCol style={{ textAlign: "start" }} size="6">
                                            <IonButton
                                                className="admin_cencel_btn" fill="outline"
                                                onClick={cancel}
                                            >
                                                <span className="button_padding">Cancel</span>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Modal.Body>
                </Modal>
                {index == 0 ? <div className="container_middle_table page-wrapper admin_background_color">
                    <div className="project-management-search-container">
                        <IonRow>
                            <IonCol size-xs="12" size-lg="3" size-sm="4.5">
                                <IonLabel className="admin_search_label">
                                    State <span style={{ color: "red" }}>*</span>
                                </IonLabel>
                                <Select
                                    options={stateLiistOptions}
                                    value={stateOption}
                                    onBlur={() => validation(7)}
                                    onChange={handleStateChange}
                                    placeholder="Enter the State"
                                    isClearable
                                    styles={customStyles}
                                    className="custom-select"
                                />
                                <div style={{ height: "10px" }}>
                                    {formErrors.state && <span className="alert_input_bottom ">{formErrors.state}</span>}
                                </div>
                            </IonCol>
                            <IonCol size-xs="12" size-lg="3" size-sm="4.5" style={{ zIndex: "999", position: "relative" }}>
                                <IonLabel className="admin_search_label">
                                    City
                                </IonLabel>
                                <IonInput className="admin_search_input" value={city} placeholder="Enter city name" onIonChange={(e: any) => setCity(e.detail.value)} />
                            </IonCol>
                            <IonCol size-xs="12" size-lg="6" size-sm="3" class="search-colum-project-admin">
                                <IonLabel className="admin_search_label">
                                </IonLabel>
                                <IonButton
                                    className="admin_add_btn"
                                    type="button"
                                    onClick={handleSearch}
                                >
                                    <span className="button_padding"> Search</span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        {venAdmList.length !== 0 ?
                            <IonRow style={{ zIndex: "999", position: "relative" }} className="select-admin-container">
                                <IonCol size-xs="12" size-lg="3" size-md="3">
                                    <IonLabel className="admin_search_label">
                                        Vendor
                                    </IonLabel>
                                    <Select
                                        options={vendorListOptions}
                                        value={selectedOption}
                                        onChange={handleChange}
                                        onBlur={() => validation(8)}
                                        placeholder="Select a Vendor"
                                        isClearable
                                        styles={customStyles}
                                        className="custom-select"
                                    />
                                    {formErrors.vendorAdmin && <span className="alert_input_bottom ">{formErrors.vendorAdmin}</span>}
                                </IonCol>
                            </IonRow>
                            : <></>}
                    </div>
                    {selectedOption != null ?
                        <div >
                            <DataTable
                                columns={columns}
                                data={tableData}
                                striped
                                noDataComponent="Sorry ! No result found"
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                                subHeader
                                subHeaderComponent={isSearch === true && selectedOption != null ? subHeaderComponent : ""}
                                onRowClicked={(item: any) => viewdetails(item)}
                                customStyles={customTableStyle}
                            />
                        </div> : <></>}
                </div> :
                    index == 1 ? <div className="container_middle_table">
                    </div> : <></>}
            </IonContent>
        </IonPage >
    )
}

export default Materialmanage;


