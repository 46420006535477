import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonButton,
    IonLabel,
    IonInput,
    IonImg,
    IonText,
    IonItemDivider,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonLoading,
    IonCard,
    IonSearchbar,
    IonIcon,
    IonTabBar,
    IonGrid
} from "@ionic/react";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";
import "./Deitymanage.css";
import UserService from "../../Services/user.service";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Capacitor } from "@capacitor/core";
import { calendarOutline, close, timeOutline } from "ionicons/icons";
import Authservice from "../../Services/user.service";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";


const Admgrammereceorder: React.FC = () => {

    const formatter = new Intl.NumberFormat('en-IN')
    const [AWBnumber, setAWBnumber] :any = useState("");
  

    const data: any = [
        {
            name: "S.No",
            selector: (item: any) => item.serial,
            sortable: true,
            width: "90px",
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.serial}</div>
                </div>
            )
        },
        {
            name: "Product Name",
            selector: (item: any) => item.grammerceDto.productName,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{item.grammerceDto.productName}</div>
                </div>
            )
        },
        {
            name: "Price (₹)",
            selector: (item: any) => parseInt(item.totalPrice, 10),
            // selector: (item: any) => {
            //     var totalprice = item.totalPrice.split(".");
            //     const totprice = totalprice[0];
            //     return totprice;
            // },
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{formatter.format(item.totalPrice)}</div>
                </div>
            )
        },
        {
            name: "Quantity",
            sortable: true,
            selector: (item: any) => parseInt(item.quantity, 10),
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{item.quantity}</div>
                </div>
            )
        },
        {
            name: "Order Date",
            selector: (item: any) => new Date(item.localOrderDate.split('-').reverse().join('-')),
            sortable: true,
            cell: (item: any) => (
                <div onClick={() => viewdetails(item)} className="center_col_tab">
                    <div style={{ cursor: "pointer" }} >{item.localOrderDate}</div>
                </div>
            )
        },
        {
            name: "Order Number",
            sortable: true,
            selector: (item: any) => item.orderNumber,
            cell: (item: any) => (
                <div onClick={() => viewdetails(item)} className="center_col_tab">
                    <div style={{ cursor: "pointer" }} >{item.orderNumber}</div>
                </div>
            )
        },
        {
            name: "Manage Orders",
            sortable: false,
            cell: (item: any) => (
                <div className="center_col_tab">
                    <IonButton onClick={() => shipment(item)} className="admin_add_btn">
                        <span style={{ fontSize: "15px" }}>
                            {item.status}
                        </span>
                    </IonButton>
                </div>
            )
        },
        // {
        //     name: "View",
        //     sortable: false,
        //     cell: (item: any) => (
        //         <>
        //             <IonButton onClick={() => viewdetails(item)} style={{ textTransform: "none" }}>View</IonButton>
        //         </>
        //     )
        // }
    ]

    const userdata: any = useState(localStorage.getItem("UserData"));
    const uFlag: any = useState(localStorage.getItem("uFlag"));
    const [usertoken] = useState(localStorage.getItem("token"));
    const history = useHistory();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = React.useState("");
    const [productname, setProductname] = useState();
    const [brandname, setBrandname] = useState();
    const [oprice, setoPrice] = useState();
    const [oquantity, setoQuantity] = useState();
    const [orderstatus, setOrderstatus]: any = useState();
    const [date, setDate]: any = useState();
    const [number, setNumber] = useState();
    const [giftFlag, setGiftFlag] = useState(false);
    const [giftby, setGiftBy] = useState();
    const [giftNote, setGiftnote] = useState();
    const [oname, setoName] = useState()
    const [address, setAddress] = useState("")
    const [contactNum, setContactNum] = useState("")
    const [emailId, setemailId] = useState("")
    const [totalPrice, setTotalPrice]: any = useState("")
    const [modalopen1, setModalopen1] = useState(false);
    const [modalopen, setModalopen] = useState(false);
    const [modalopen2, setModalopen2] = useState(false);
    const [shipDate, setShipDate] = useState("");
    const [shipTime, setShipTime]: any = useState("");
    const [ostatus, setOstatus] = useState("");
    const [grammerceorder, setGrammerceOrder] = useState([]);
    const [shipId, setShipId] = useState("");
    const [ship, setShip]: any = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [totalpaidamount, setTotalpaidamount]: any = useState("")
    const ref: any = useRef()

    if (grammerceorder != undefined && grammerceorder.length > 0) {
        grammerceorder.forEach((item: any, index: any) => { item.serial = index + 1; });
        grammerceorder.forEach((item: any, index: any) => {
            var date = item.orderDate.split("T");
            const od = moment(date[0]).format("DD-MM-YYYY");
            item.localOrderDate = od;
        });
    }

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)


    useEffect(() => {
        setScreenWidth(window.innerWidth)
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_ADMIN") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            setShowLoading(true);
            UserService.grammerceorders(usertoken).then((response) => {
                console.log(response.data);
                setGrammerceOrder(response.data);
                setShowLoading(false);
            })
                .catch((error) => {
                    //alert(error)
                    alert("Something went wrong, please try again later.");
                    setShowLoading(false);
                });

        }

    }, []);
    const [pdamt, setPdamt] = useState("")
    const viewdetails = (item: any) => {
        var totalprice = item.totalPrice.split(".");
        const totprice = totalprice[0];
        var date = item.orderDate.split("T");
        const od = moment(date[0]).format("DD-MM-YYYY");
        setModalopen1(true);
        setProductname(item.grammerceDto.productName);
        setBrandname(item.grammerceDto.brandName);
        setoPrice(item.grammerceDto.price);
        setoQuantity(item.quantity);
        if (item.status === "SCHEDULED") {
            setOrderstatus("Scheduled Pickup")
        }
        else {
            setOrderstatus(item.status);
        }
        setTotalpaidamount(item.totalPaidAmount)
        setDate(od);
        setNumber(item.orderNumber);
        setGiftFlag(item.giftFlag);
        setGiftBy(item.giftedBy);
        setGiftnote(item.giftNote);
        setoName(item.name);
        setemailId(item.emailId)
        setAddress(item.deliveryAddress)
        setContactNum(item.contactNumber)
        setTotalPrice(totprice)
        setModalopen1(true);
    };
    const [shipmentdate, setShipmentdate] = useState("")
    const shipment = (item: any) => {
        setModalopen(true);
        var obj = item.id;
        setShipId(obj)
        setShip(item)
        if (item.shipmentDate === null) {
            setShipmentdate("N/A")
        }
        else {
            var shipdate = moment(item.shipmentDate).format("DD-MM-YYYY")
            setShipmentdate(shipdate)
        }
    }
    var tableData1: any = [];
    if (grammerceorder != undefined && grammerceorder.length > 0) {
        tableData1 = grammerceorder.filter(
            (item: any) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        );

    }

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };

    const subHeaderComponent1 = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        }
        return (
            <IonRow>
                <IonCol className="ion-text-left" size="12" style={{ paddingRight: '0px' }}>
                    <IonSearchbar style={{ paddingRight: '0px' }}
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </IonCol>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle]);
    const Modalclose1 = () => {
        setModalopen1(false)
    }

    const [shiperror, setShiperror]: any = useState({})
    const [shipErrors, setShipErrors]: any = useState({})

    const validation = (num: any) => {
        console.log(shiperror, "erroorrrr")
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setShipErrors({})
        if (num === "1") {
            if (shipDate === "") {
                setShiperror((e: any) => {
                    return { ...e, shipDate: "Please choose the Shipment Date" }
                })
            }
            else if (tomorrow.toISOString().split('T')[0] > new Date(shipDate).toISOString().split('T')[0]) {
                setShiperror((e: any) => {
                    return { ...e, shipDate: "Please select future date" }
                })
            }
            else {
                setShiperror((e: any) => {
                    return { ...e, shipDate: "" }
                })
            }
        }
        else if (num === "2") {
            if (shipTime === "") {
                setShiperror((e: any) => {
                    return { ...e, shipTime: "Please choose the Shipment Time" }
                })
            }
            else {
                setShiperror((e: any) => {
                    return { ...e, shipTime: "" }
                })
            }
        }
        else if(num === "3"){
        
          if(AWBnumber.trim() === ""){
            setShiperror((e: any) => {
                return { ...e, awb: "Invalid AWB number" }
            })
          }else{
            setShiperror((e: any) => {
                return { ...e, awb: "" }
            })
          }

        }
    }

    const replacement = () => {
        setShiperror({})
        const shiperror: any = {}
        if (shipDate == "") {
            shiperror.shipDate = "Please choose the Shipment Date"
        }
        if (shipTime == "") {
            shiperror.shipTime = "Please choose the Shipment Time"
        }
        setShipErrors(shiperror)
        if (Object.keys(shiperror).length === 0) {
            if (shipDate.length === 0) {
                alert("Select Date")
            }
            else if (shipTime.length === 0) {
                alert("Select Time")
            }
            else {
                UserService.grammercereplace(usertoken, shipId, shipDate, shipTime, "").then((response) => {
                    console.log(response.data);
                    if (response.data.packageStatus == 'OPEN' && response.data.trackingId != null) {
                        alert("Replacement Initiated Successfully");
                        window.location.reload();
                    } else {
                        alert(response.data.errorMessage);
                    }
                })
                    .catch((error) => {
                        alert("Problem in Initiating Shipment");
                    });
                setModalopen(false);
            }
        }
    }

    const loadShipment = () => {
        setShiperror({})
        const shiperror: any = {}
        if (shipDate == "") {
            shiperror.shipDate = "Please choose the Shipment Date"
        }
        if (shipTime == "") {
            shiperror.shipTime = "Please choose the Shipment Time"
        }
        if(AWBnumber.trim() === "") {
            shiperror.awb = "Invalid AWB number"
        }
        setShipErrors(shiperror)
        if (Object.keys(shiperror).length === 0) {
            if (shipDate.length === 0) {
                alert("Select Date")
            }
            else if (shipTime.length === 0) {
                alert("Select Time")
            }
            else {
                // const date1 = new Date();
                // const time: any = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                // const formattedDate = moment(date1.toLocaleDateString()).format("YYYY-MM-DD");

                // const time1Parts = time.split(/:| /);
                // const time2Parts = shipTime.split(/:| /);

                // const date2: any = new Date();
                // date2.setHours(parseInt(time1Parts[0], 10) % 12 + (time1Parts[2] === "PM" ? 12 : 0));
                // date2.setMinutes(parseInt(time1Parts[1], 10));
                // date2.setSeconds(0);

                // const date3: any = new Date();
                // date3.setHours(parseInt(time2Parts[0], 10) % 12 + (time2Parts[2] === "PM" ? 12 : 0));
                // date3.setMinutes(parseInt(time2Parts[1], 10));
                // date3.setSeconds(0);

                // const differenceInMilliseconds = Math.abs(date3 - date2);
                // const hours = Math.floor(differenceInMilliseconds / 3600000);
                // const minutes = Math.floor((differenceInMilliseconds % 3600000) / 60000);

                // if (shipDate === formattedDate && (hours === 0 && minutes <= 30)) {
                //     alert("Minimum Time Difference Is 30 Minutes");
                //     setShipTime("");
                // }
                // else {
                UserService.initShipForGrammerce(usertoken, shipId, shipDate, shipTime,AWBnumber).then((response) => {
                    console.log(response.data);
                    if (response.data.errorMessage == null) {
                        alert("Shipment Initiated Successfully");
                        window.location.reload();
                    } else {
                        alert(response.data.errorMessage);
                    }
                })
                    .catch((error) => {
                        alert("Problem in Initiating Shipment");
                    });
                setModalopen(false);
            }
        }
        // }
    }
    const [statuserror, setStatuserror]: any = useState({})

    const initiaterefund = () => {
        console.log(ship, "shipppppppppp")
        setModalopen(false)
        setShowLoading(true)
        Authservice.initRefundgrammerce(usertoken, ship.id)
            .then((response) => {
                console.log(response)
                if (response.data.refundId !== "" || response.data.refundId !== null) {
                    alert("Refund Initated Succesfully")
                    window.location.reload()
                    setShowLoading(false)
                }
                else {
                    alert("Something went wrong")
                    setShowLoading(false)
                }
            })
            .catch((error) => {
                console.log(error)
                alert(error)
            })
    }

    const changeStatus = () => {
        const error: any = {}
        if (ostatus === "") {
            error.statusAlert = "Please select the status"
        }
        setStatuserror(error)
        if (Object.keys(error).length === 0) {
            console.log(ostatus);
            var data =
            {
                "status": ostatus,
                "id": shipId
            }
            UserService.updateOrderStatusForGrammerce(usertoken, data).then((response) => {
                console.log(response.data);
                alert(response.data)
                window.location.reload();
            })
                .catch((error) => {
                    // alert(error)
                    alert("Something went wrong, please try again later.");
                });

            //window.location.reload()
        }
    }

    const handleIconClick = (event: any) => {
        console.log('Icon clicked!');
        event.stopPropagation();  // Stop the event from propagating to the parent div
    };

    const datelimit = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    const setTime = (e: any) => {

        var ii = e.detail.value.split(":");
        var t: any;
        var a: any;
        //  const date = new Date();
        //  const dates = new Date(shipDate + " " + e.detail.value)
        //  console.log(dates, date)
        //  if (dates < date) {
        //     alert("Select Proper Time")
        //     ref.current.value = null
        // }
        // else {
        if (ii[0] > 12) {
            t = ii[0] - 12;
            a = "PM";

        } else if (ii[0] < 12) {
            t = ii[0]
            a = 'AM';
        }
        else if (ii[0] == 12) {
            t = ii[0]
            a = 'PM';
        }
        var str = t + ":" + ii[1] + " " + a;
        setShipTime(str)
        console.log(str)
        //}
    }
    const setdatechange = (e: any) => {
        var val = e.detail.value;
        const shiperror: any = {}
        if (val === "") {
            setShiperror((e: any) => {
                return { ...e, shipDate: "Please choose the Shipment Date" }
            })
        }
        else {
            setShiperror((e: any) => {
                return { ...e, shipDate: "" }
            })
        }
        setShipErrors(shiperror)
        const date = moment(val).format("YYYY-MM-DD")
        setShipDate(date)
        ref.current.value = null
    }
    const status = () => {
        setModalopen(false)
        setModalopen2(true);
    }


    const modalclose = () => {
        setModalopen2(false);
        setModalopen(true)
    }

    const useOutsideAlerter = (refer: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (refer.current && !refer.current.contains(event.target)) {
                    setModalopen1(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer]);
    }
    const list: any = useRef(null);

    useOutsideAlerter(list);

    const closemodal = () => {
        setModalopen(false)
        setShipDate("")
        setShiperror({})
        setShipTime("")
        setAWBnumber("")
    }

    const dateInputRef: any = useRef(null)

    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Grammerce Order"} />
            <Sidebar />
            <IonContent>

                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                // onDidDismiss={() => setShowLoading(false)}
                // duration={5000}
                />
                {/* <Head User="deity" Role={userdata[0]} /> */}
                <Modal show={modalopen1} centered className="modal_dialog_width1 centered-modal-matmanagement">
                    <Modal.Body ref={list}>
                        <div className="modal-header">
                            <h5 className="modal-title">Order Details</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen1(false)} />
                            </div>

                        </div>
                        <div style={{ padding: "0px 20px" }} >
                            <IonRow className="row_bot" style={{ marginTop: "4%" }}>
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Order Number
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{number}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Order Date
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{date}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row_bot" >
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Product Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{productname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Brand Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{brandname}</IonText>
                                </IonCol>
                            </IonRow>
                            {/* <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Price
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="2"><IonText>:</IonText></IonCol>
                                <IonCol size="5">
                                    <IonText

                                    >Rs. {oprice}</IonText>
                                </IonCol>
                            </IonRow> */}
                            <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Quantity
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{oquantity}</IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Order Status
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{orderstatus}</IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{oname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Email
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{emailId}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Contact Number
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{contactNum}</IonText>
                                </IonCol>
                            </IonRow>


                            <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Delivery Address
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{address}</IonText>
                                </IonCol>
                            </IonRow>
                            {giftFlag && <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Gifted By
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{giftby}</IonText>
                                </IonCol>
                            </IonRow>}
                            {giftFlag && <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Gift Note
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >{giftNote}</IonText>
                                </IonCol>
                            </IonRow>}
                            <IonRow className="row_bot">
                                <IonCol size="5">
                                    <IonLabel style={{ fontWeight: 'bold' }} className="label_css" >
                                        Total Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="7">
                                    <IonText style={{ paddingRight: "2%" }}>:</IonText>
                                    <IonText

                                    >₹ {formatter.format(totalpaidamount)}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="row_bot">
                                <IonCol size="11" size-lg="7" style={{ textAlign: 'center' }}>
                                    <IonButton
                                        className="admin_add_btn"
                                        onClick={Modalclose1}
                                    >
                                        <span className="button_padding">Close
                                        </span>
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={modalopen} centered className="modal_dialog_width1 centered-modal-matmanagement">
                    <Modal.Body>
                        <div className="modal-header">
                            <h5 className="modal-title">Shipping Details</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => closemodal()} />
                            </div>

                        </div>
                        <div style={{ padding: "0px 20px" }}>
                            {ship.status === "CANCELLED" ?

                                <IonGrid style={{ textAlign: "center" }}>
                                    <IonRow>
                                        <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                            <IonText className="adm_label_color">Order No</IonText>
                                        </IonCol>
                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                            :

                                        </IonCol>
                                        <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                            <IonText>{ship.orderNumber}</IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                            <IonText className="adm_label_color">Order Status</IonText>
                                        </IonCol>
                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                            :

                                        </IonCol>
                                        <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                            <IonText>
                                                {ship.status}
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                            <IonText className="adm_label_color">Refund Amount</IonText>
                                        </IonCol>
                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                            :

                                        </IonCol>
                                        <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                            <IonText>
                                                ₹ {formatter.format(ship.refundAmt)}
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                            <IonText className="adm_label_color">Cancellation Fee</IonText>
                                        </IonCol>
                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                            :

                                        </IonCol>
                                        <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                            <IonText>₹ {formatter.format(ship.cancellationFee)}</IonText>
                                        </IonCol>
                                    </IonRow>
                                    {ship.cancelReason !== "" ? <IonRow>
                                        <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                            <IonText className="adm_label_color">Reason</IonText>
                                        </IonCol>
                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                            :

                                        </IonCol>
                                        <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                            <IonText>{ship.cancelReason}</IonText>
                                        </IonCol>
                                    </IonRow> : <></>}
                                </IonGrid>
                                : ship.status === "RETURNED" ? <>

                                    <IonGrid>
                                        <IonRow>
                                            <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                <IonText className="adm_label_color">Order No</IonText>
                                            </IonCol>
                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                <IonText >:</IonText>
                                            </IonCol>

                                            <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                <IonText>{ship.orderNumber}</IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                <IonText className="adm_label_color">
                                                    Order Status :
                                                </IonText>
                                            </IonCol>
                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                <IonText >:</IonText>
                                            </IonCol>
                                            <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                <IonText>
                                                    {ship.status}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        {ship.refundFlag === false ?
                                            <IonRow className="row_bot">
                                                <IonCol size="12" style={{ textAlign: 'center' }}>
                                                    <IonButton
                                                        className="admin_add_btn"
                                                        onClick={() => initiaterefund()}
                                                    >
                                                        <span className="button_padding">Initiate Refund</span>
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow> : <>
                                                <IonRow>
                                                    <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                        <IonText className="adm_label_color">Refund Amount</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText >:</IonText>
                                                    </IonCol>
                                                    <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                        <IonText>
                                                            ₹ {formatter.format(ship.refundAmt)}
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                        <IonText className="adm_label_color">Cancellation Fee</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText >:</IonText>
                                                    </IonCol>
                                                    <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                        <IonText >₹ {formatter.format(ship.cancellationFee)}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                {ship.returnReason !== "" ? <IonRow className="row_bot">
                                                    <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                        <IonText className="adm_label_color">Reason</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText >:</IonText>
                                                    </IonCol>
                                                    <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                        <IonText>{ship.returnReason}</IonText>
                                                    </IonCol>
                                                </IonRow> : <></>}
                                            </>}
                                    </IonGrid>

                                </> : ship.status === "RETURN_INIT" || ship.status === "RETURN_SHIPPED" || ship.status === "REPLACE_INIT" || ship.status === "REPLACE_SHIPPED" ?
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                <IonText className="adm_label_color">Order No </IonText>
                                            </IonCol>
                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                <IonText >:</IonText>
                                            </IonCol>
                                            <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                <IonText>{ship.orderNumber}</IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                <IonText className="adm_label_color">Order Status</IonText>
                                            </IonCol>
                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                <IonText >:</IonText>
                                            </IonCol>
                                            <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                <IonText>
                                                    {ship.status}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                <IonText className="adm_label_color">Pickup Date</IonText>
                                            </IonCol>
                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                <IonText >:</IonText>
                                            </IonCol>
                                            <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                <IonText>
                                                    {shipmentdate}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                <IonText className="adm_label_color">Pickup Time</IonText>
                                            </IonCol>
                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                <IonText >:</IonText>
                                            </IonCol>
                                            <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                <IonText>{ship.shipmentTime}</IonText>
                                            </IonCol>
                                        </IonRow>
                                        {(ship.status === "RETURN_INIT" || ship.status === "RETURN_SHIPPED" || ship.status === "REPLACE_INIT" || ship.status === "REPLACE_SHIPPED"||
                                         ship.status === "REPLACE_ADMIN_INIT" || ship.status === "REPLACE_ADMIN_SHIPPED") &&
                                         <>
                                                        <IonRow>
                                                            <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                                <IonText className="adm_label_color">
                                                                    Choose Order Status
                                                                </IonText>
                                                            </IonCol>
                                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                                <IonText className="adm_label_color">:</IonText>
                                                            </IonCol>
                                                            <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                                <select className="status_gram" onChange={(e: any) => setOstatus(e.target.value)}>
                                                                    <option value="" disabled selected>Select Status</option>
                                                                   
                                                                    {(ship.status === "RETURN_INIT" || ship.status === "RETURN_SHIPPED") && <>
                                                                    <option value="RETURN_SHIPPED">RETURN_SHIPPED</option>
                                                                    <option value="RETURNED">RETURNED</option></>}
                                                                    {(ship.status === "REPLACE_INIT" || ship.status === "REPLACE_SHIPPED") && <>
                                                                    <option value="REPLACE_SHIPPED">REPLACE_SHIPPED</option>
                                                                    <option value="REPLACE_RETURNED">REPLACE_RETURNED</option></>}
                                                                    {(ship.status === "REPLACE_ADMIN_INIT" || ship.status === "REPLACE_ADMIN_SHIPPED") && <>
                                                                    <option value="REPLACE_ADMIN_SHIPPED">REPLACE_ADMIN_SHIPPED</option>
                                                                    <option value="REPLACED">REPLACED</option></>}
                                                                </select>
                                                                <div style={{ display: "flex", marginTop: "2%" }}>
                                                                    {statuserror.statusAlert && <span className="alert_input_bottom ">{statuserror.statusAlert}</span>}
                                                                </div>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow className="row_bot">
                                                            <IonCol size="12" style={{ textAlign: 'center' }}>
                                                                <IonButton
                                                                    className="admin_add_btn"
                                                                    onClick={() => changeStatus()}
                                                                >
                                                                    <span className="button_padding"> Change Status</span>
                                                                </IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                    </>
                                         }
                                        {ship.status === "RETURN_INIT" || ship.status === "RETURN_SHIPPED" ?
                                            <>
                                             
                                                {ship.returnReason !== "" ? <IonRow>
                                                    <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                        <IonText className="adm_label_color">Reason</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText >:</IonText>
                                                    </IonCol>
                                                    <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                        <IonText>{ship.returnReason}</IonText>
                                                    </IonCol>
                                                </IonRow> : <></>}
                                            </> : <></>}
                                        {ship.status === "REPLACE_INIT" || ship.status === "REPLACE_SHIPPED" ? <>
                                            {ship.replaceReason !== "" ? <IonRow>
                                                <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                    <IonText className="adm_label_color">Reason</IonText>
                                                </IonCol>
                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                    <IonText >:</IonText>
                                                </IonCol>
                                                <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                    <IonText>{ship.replaceReason}</IonText>
                                                </IonCol>
                                            </IonRow> : <></>}
                                        </> : <></>}
                                    </IonGrid>
                                    :
                                    <>
                                        {ship.awbNumber != "" && ship.status === "COMPLETED" || ship.status === "REPLACE_ADMIN_INIT" || ship.status === "REPLACE_ADMIN_SHIPPED" || ship.status === "REPLACED" || ship.status === "SCHEDULED" || ship.status === "SHIPPED" ?
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                        <IonText className="adm_label_color">AWB Number</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="adm_label_color">:</IonText>
                                                    </IonCol>
                                                    <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                        <IonText>{ship.awbNumber}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                        <IonText className="adm_label_color">Order No</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="adm_label_color">:</IonText>
                                                    </IonCol>
                                                    <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                        <IonText>{ship.orderNumber}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                        <IonText className="adm_label_color">Shipment Date</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="adm_label_color">:</IonText>
                                                    </IonCol>
                                                    <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                        <IonText>{shipmentdate}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                        <IonText className="adm_label_color">Shipment Time</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="adm_label_color">:</IonText>
                                                    </IonCol>
                                                    <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                        {ship.shipmentTime === null ? <IonText>N/A</IonText> : <IonText>{ship.shipmentTime}</IonText>}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                        <IonText className="adm_label_color">
                                                            Status
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="adm_label_color">:</IonText>
                                                    </IonCol>
                                                    <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                        {ship.status === "SCHEDULED" ? <IonText>
                                                            SCHEDULED PICKUP
                                                        </IonText> : <IonText>
                                                            {ship.status}
                                                        </IonText>}
                                                    </IonCol>
                                                </IonRow>
                                                {(ship.status === "SCHEDULED" || ship.status === "SHIPPED" ||ship.status === "RETURN_INIT" 
                                                || ship.status === "RETURN_SHIPPED" || ship.status === "REPLACE_INIT" || ship.status === "REPLACE_SHIPPED"
                                                || ship.status === "REPLACE_ADMIN_INIT" || ship.status === "REPLACE_ADMIN_SHIPPED" )?
                                                    <>
                                                        <IonRow>
                                                            <IonCol className="admalign_text" size-lg="5" size-md="5" size-sm="5" size-xs="11">
                                                                <IonText className="adm_label_color">
                                                                    Choose Order Status
                                                                </IonText>
                                                            </IonCol>
                                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                                                                <IonText className="adm_label_color">:</IonText>
                                                            </IonCol>
                                                            <IonCol className="admalign_text" size-lg="6" size-md="6" size-sm="6" size-xs="12">
                                                                <select className="status_gram" onChange={(e: any) => setOstatus(e.target.value)}>
                                                                    <option value="" disabled selected>Select Status</option>
                                                                    {(ship.status === "SCHEDULED" || ship.status === "SHIPPED") && <>
                                                                    <option value="SHIPPED">SHIPPED</option>
                                                                    <option value="COMPLETED">COMPLETED</option></>}
                                                                    {(ship.status === "RETURN_INIT" || ship.status === "RETURN_SHIPPED") && <>
                                                                    <option value="RETURN_SHIPPED">RETURN_SHIPPED</option>
                                                                    <option value="RETURNED">RETURNED</option></>}
                                                                </select>
                                                                <div style={{ display: "flex", marginTop: "2%" }}>
                                                                    {statuserror.statusAlert && <span className="alert_input_bottom ">{statuserror.statusAlert}</span>}
                                                                </div>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow className="row_bot">
                                                            <IonCol size="12" style={{ textAlign: 'center' }}>
                                                                <IonButton
                                                                    className="admin_add_btn"
                                                                    onClick={() => changeStatus()}
                                                                >
                                                                    <span className="button_padding"> Change Status</span>
                                                                </IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                    </> : <></>}
                                            </IonGrid> : ship.status === "OPEN" || ship.status === "REPLACE_RETURNED" ?

                                                <IonGrid className="adm_action_div_adjust">
                                                    <IonRow>
                                                        <IonCol size-lg="5" size-md="12" size-sm="12" size-xs="12" style={{ display: "flex", alignItems: "center" }}>
                                                            <IonText style={{ fontWeight: 'bold' }}>
                                                                Order No
                                                            </IonText>
                                                        </IonCol>
                                                        {screenWidth <= 991 ? <></> :
                                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" style={{ display: "flex", alignItems: "center" }}><IonText>:</IonText></IonCol>
                                                        }
                                                        <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12">
                                                            <IonText>{ship.orderNumber}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="row_bot " style={{ marginTop: "4%" }}>
                                                        <IonCol size-lg="5" size-md="12" size-sm="12" size-xs="12" style={{ display: "flex", alignItems: "center" }}>
                                                            <IonText style={{ fontWeight: 'bold' }} >
                                                                Schedule Date
                                                            </IonText>
                                                        </IonCol>
                                                        {screenWidth <= 991 ? <></> :
                                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" style={{ display: "flex", alignItems: "center" }}><IonText>:</IonText></IonCol>
                                                        }
                                                        <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12" className="grammercedefaulticon">
                                                            <IonInput type="date" min={datelimit()} className="time_gram"
                                                                onBlur={() => validation("1")}
                                                                onKeyDown={(e) => {
                                                                    if (e.key !== 'Tab' && e.key !== ' ') {
                                                                        e.preventDefault();
                                                                    }
                                                                    else {
                                                                        if (dateInputRef.current) {
                                                                            dateInputRef.current.setFocus();
                                                                        }
                                                                    }
                                                                }}
                                                                ref={dateInputRef}
                                                                onIonChange={(e: any) => setdatechange(e)} />
                                                            <div style={{ display: "flex", marginTop: "2%" }}>
                                                                {shiperror.shipDate && <span className="alert_input_bottom ">{shiperror.shipDate}</span>}
                                                                {shipErrors.shipDate && <span className="alert_input_bottom ">{shipErrors.shipDate}</span>}
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12" className="grammerceordericon">
                                                            <div style={{ position: "absolute", width: "100%" }}>
                                                                <IonInput type="date" min={datelimit()} className="time_gram"
                                                                    onBlur={() => validation("1")}
                                                                    onKeyDown={(e: any) => e.preventDefault()}
                                                                    onIonChange={(e: any) => setdatechange(e)} />                                            </div>
                                                            <div className="date_icon_style" style={{ position: "relative", textAlign: "right", paddingTop: "5%", paddingRight: "5px" }}>
                                                                <IonIcon onClick={handleIconClick} className="calaendariconorders date_icon_style" src={calendarOutline} />
                                                            </div>
                                                            <div style={{ display: "flex", marginTop: "3%" }}>
                                                                {shiperror.shipDate && <span className="alert_input_bottom ">{shiperror.shipDate}</span>}
                                                                {shipErrors.shipDate && <span className="alert_input_bottom ">{shipErrors.shipDate}</span>}
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="row_bot " style={{ marginTop: "2%" }}>
                                                        <IonCol size-lg="5" size-md="12" size-sm="12" size-xs="12" style={{ display: "flex", alignItems: "center" }}>
                                                            <IonText style={{ fontWeight: 'bold' }}>
                                                                Schedule Time
                                                            </IonText>
                                                        </IonCol>
                                                        {screenWidth <= 991 ? <></> :
                                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" style={{ display: "flex", alignItems: "center" }}><IonText>:</IonText></IonCol>
                                                        }
                                                        <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12" className="grammercedefaulticon">
                                                            <IonInput type="time" ref={ref} className="time_gram" onBlur={() => validation("2")}
                                                                onKeyDown={(e) => {
                                                                    if (e.key !== 'Tab' && e.key !== ' ') {
                                                                        e.preventDefault();
                                                                    }
                                                                    else {
                                                                        if (ref.current) {
                                                                            ref.current.setFocus();
                                                                        }
                                                                    }
                                                                }}
                                                                onIonChange={(e: any) => setTime(e)}></IonInput>
                                                            <div style={{ display: "flex", marginTop: "2%" }}>
                                                                {shiperror.shipTime && <span className="alert_input_bottom">{shiperror.shipTime}</span>}
                                                                {shipErrors.shipTime && <span className="alert_input_bottom">{shipErrors.shipTime}</span>}
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12" className="grammerceordericon">
                                                            <div style={{ position: "absolute", width: "100%", display: "flex", alignItems: "center" }}>
                                                                <IonInput type="time" ref={ref} onKeyDown={(e: any) => e.preventDefault()} onBlur={() => validation("2")} onIonChange={(e: any) => setTime(e)}></IonInput>
                                                            </div>
                                                            <div className="date_icon_style" style={{ position: "relative", textAlign: "right", paddingTop: "5%", paddingRight: "5px" }}>
                                                                <IonIcon onClick={handleIconClick} className="calaendariconorders date_icon_style" src={timeOutline} />
                                                            </div>
                                                            <div style={{ display: "flex", marginTop: "3%" }}>
                                                                {shiperror.shipTime && <span className="alert_input_bottom">{shiperror.shipTime}</span>}
                                                                {shipErrors.shipTime && <span className="alert_input_bottom">{shipErrors.shipTime}</span>}
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="row_bot " style={{ marginTop: "2%" }}>
                                                        <IonCol size-lg="5" size-md="12" size-sm="12" size-xs="12" style={{ display: "flex", alignItems: "center" }}>
                                                            <IonText style={{ fontWeight: 'bold' }}>
                                                                AWB Number
                                                            </IonText>
                                                        </IonCol>
                                                        {screenWidth <= 991 ? <></> :
                                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" style={{ display: "flex", alignItems: "center" }}><IonText>:</IonText></IonCol>
                                                        }
                                                         <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12" className="grammercedefaulticon">
                                                         <IonInput type="text" value={AWBnumber}  onBlur={() => validation("3")}
                                                                
                                                                onIonChange={(e: any) =>{
                                                                    validation("3")
                                                                    setAWBnumber(e.detail.value) 
                                                                } }></IonInput>
                                                                  <div style={{ display: "flex", marginTop: "3%" }}>
                                                                {shiperror.awb && <span className="alert_input_bottom">{shiperror.awb}</span>}
                                                                {shipErrors.awb && <span className="alert_input_bottom">{shipErrors.awb}</span>}
                                                            </div>
                                                         </IonCol>
                                                        
                                                        </IonRow>
                                                    <IonRow className="row_bot " style={{ marginTop: "2%" }}>
                                                        <IonCol size-lg="5" size-md="12" size-sm="12" size-xs="12" style={{ display: "flex", alignItems: "center" }}>
                                                            <IonText style={{ fontWeight: 'bold' }}>
                                                                Status
                                                            </IonText>
                                                        </IonCol>
                                                        {screenWidth <= 991 ? <></> :
                                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" style={{ display: "flex", alignItems: "center" }}><IonText>:</IonText></IonCol>
                                                        }
                                                        <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12" >
                                                            {ship.status === "SCHEDULED" ? <IonText>{ship.status}</IonText> : <IonText>{ship.status}</IonText>}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="row_bot">
                                                        <IonCol size="12" style={{ textAlign: 'center' }}>
                                                            {ship.status === "REPLACE_RETURNED" ? <IonButton
                                                                className="admin_add_btn"
                                                                onClick={() => replacement()}
                                                            ><span className="button_padding">Initiate Replacement</span>

                                                            </IonButton> : <IonButton
                                                                className="admin_add_btn"
                                                                onClick={() => loadShipment()}
                                                            ><span className="button_padding">
                                                                    Initiate Shipment</span>
                                                            </IonButton>}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                                : <></>
                                        }
                                    </>}
                        </div>
                    </Modal.Body>

                </Modal>
                <div className="container_middle_table page-wrapper admin_background_color">

                    <div className="data_table">
                        <DataTable
                            columns={data}
                            data={tableData1}
                            striped
                            noDataComponent="Sorry ! No result found"
                            pagination
                            paginationPerPage={10}
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                            subHeader
                            subHeaderComponent={subHeaderComponent1}
                            customStyles={customTableStyle}
                            onRowClicked={(item: any) => viewdetails(item)}
                        /></div></div>


            </IonContent>
        </IonPage>


    )
}
export default Admgrammereceorder;
