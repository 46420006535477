import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonItem,
  IonPage,
  IonButton,
  IonItemDivider,
  IonRow,
  IonCol,
  IonInput,
  IonAlert,
  IonLabel,
  useIonRouter,
  IonLoading,
  useIonAlert,
} from "@ionic/react";

import "./Login.css";
import axios from "axios";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import Head from "./Head";
import UserService from "../Services/user.service";
import Foot from "./Foot";
import { Capacitor } from "@capacitor/core";
import { Plugins } from '@capacitor/core';
import HeadFoot from "./Head";
import AdminHead from "./admin/adminHead";
import Sidebar from "./admin/sidebar";

const ChangePassword: React.FC = () => {
  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const history = useHistory();
  const router = useIonRouter();
  const { Storage } = Plugins;
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [oldpassword, setOldpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [msgerror, setMsgerror] = useState("");
  const [userid] = useState(localStorage.getItem("UserId"));
  const [usertoken] = useState(localStorage.getItem("token"));
  const userdata: any = useState(localStorage.getItem("UserData"));
  const [showLoading, setShowLoading] = useState(false);
  const [Alert] = useIonAlert()
  const [formErrors, setFormErrors] = useState({
    newpassword: '',
    oldpassword: '',
    confirmpassword: '',
  });


  useEffect(() => {
    console.log(userid);
    if (userid === null || usertoken === null) {
      history.push("/login");
    } else {
      console.log("dsasa");
      setConfirmpassword("");
      setOldpassword("");
      setNewpassword("");
    }
  }, []);

  const Validation = (label: any) => {
    if (label === "newpassword") {
      if (newpassword === "") {
        setFormErrors((prev: any) => {
          return { ...prev, newpassword: "Please Enter the new password" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, newpassword: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      if (confirmpassword === "") {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Please Enter the Confirm password" }
        })
      }
      else if (newpassword != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Please Check the Confirm Password" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }

    if (label === "oldpassword") {
      if (oldpassword === "") {
        setFormErrors((prev: any) => {
          return { ...prev, oldpassword: "Please Enter the old password" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, oldpassword: "" }
        })
      }
    }
  }

  const ChangeValidation = (label: any, e: any) => {
    if (label === "newpassword") {
      const newpassword = e.target.value;
      if (newpassword === "") {
        setFormErrors((prev: any) => {
          return { ...prev, newpassword: "Please Enter the new password" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, newpassword: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      const confirmpassword = e.target.value;

      if (confirmpassword === "") {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Please Enter the Confirm password" }
        })
      }
      else if (newpassword != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Please Check the Confirm Password" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }

    if (label === "oldpassword") {
      const oldpassword = e.target.value;

      if (oldpassword === "") {
        setFormErrors((prev: any) => {
          return { ...prev, oldpassword: "Please Enter the old password" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, oldpassword: "" }
        })
      }
    }
  }

  const onSubmit = () => {
    const errors: any = {};
    if (newpassword === "") {
      errors.newpassword = "Please Enter the new password"
      // setIserror(true);
    }
    if (confirmpassword === "") {
      errors.confirmpassword = "Please Enter the Confirm password"
      // setIserror(true);
    }
    else if (newpassword != confirmpassword) {
      errors.confirmpassword = "Please Check the Confirm Password"
      // setIserror(true);
    }
    if (oldpassword === "") {
      errors.oldpassword = "Please Enter the old password"
      // setIserror(true);
    }
    // else if (oldpassword === newpassword) {
    //   errors.newpassword = "New Password should not be your Old Password"
    //   // setIserror(true);
    // }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setShowLoading(true)
      UserService.ChangePassword(
        newpassword,
        oldpassword,
        confirmpassword,
        usertoken
      )
        .then((response) => {
          if (response.data.startsWith("SUCCESS")) {
            setShowLoading(false)
            setConfirmpassword("");
            setOldpassword("");
            setNewpassword("");
            setMsgerror("Success");
            setMessage(response.data.split("SUCCESS:")[1]);
            Alert("Password Changed Successfully");
            setIserror(true);
            try {
              Storage.clear();
              console.log('Cache cleared successfully.');
            } catch (error) {
              console.error('Error clearing cache:', error);
            }

            localStorage.clear();
            window.location.hash = "/login";
            window.location.reload();
          } else {
            setShowLoading(false)
            setMsgerror("Error");
            if (response.data === "ERROR:Old Password is wrong") {
              setMessage("Incorrect Old Password");
            }
            else {
              setMessage('')
            }
            console.log(response.data)
            setIserror(true);
          }
        })
        .catch((error) => {
          Alert("Something went wrong, please try again later.");
          setShowLoading(false)
        });
    }
  };

  return (
    <IonPage>
      {userdata[0] != "ROLE_USER" ?
        <AdminHead User={"User"} Role={"User"} Name={"Change Password"} />
        :
        <></>}
      {userdata[0] != "ROLE_USER" ?
        <Sidebar /> : <></>
      }
      <IonContent>
        {userdata[0] === "ROLE_USER" ?
          <HeadFoot User={"login"} Role={'Role'} Name={"Home,Change Password"} />
          : <></>
        }
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() => setShowLoading(false)}

        // duration={1000}
        />


        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={` page_content_padding container_bg ${userdata[0] != "ROLE_USER" ? 'page-wrapper' : ""}`}>
            <div style={{ height: "fit-content" }}>
              <IonRow>
                <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                  <IonLabel className="label_align">
                    Old Password <span style={{ color: "red" }}>*</span>
                  </IonLabel>
                  <IonInput className="select_user"
                    type="password"
                    clearOnEdit={false}
                    onIonChange={(e) => { setOldpassword(e.detail.value!); ChangeValidation("oldpassword", e) }}
                    onBlur={() => Validation("oldpassword")}
                  />
                  {formErrors.oldpassword && <span className="alert_input_bottom ">{formErrors.oldpassword}</span>}
                </IonCol>
              </IonRow>
              <IonRow className="row_padding">
                <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                  <IonLabel className="label_align">
                    New Password <span style={{ color: "red" }}>*</span>
                  </IonLabel>
                  <IonInput className="select_user"
                    type="password"
                    clearOnEdit={false}
                    onBlur={() => Validation("newpassword")}
                    onIonChange={(e) => { setNewpassword(e.detail.value!); ChangeValidation("newpassword", e) }}
                  // required
                  />
                  {formErrors.newpassword && <span className="alert_input_bottom ">{formErrors.newpassword}</span>}
                </IonCol>
              </IonRow>
              <IonRow className="row_padding">
                <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                  <IonLabel className="label_align">
                    Confirm New Password <span style={{ color: "red" }}>*</span>
                  </IonLabel>
                  <IonInput className="select_user"
                    type="password"
                    clearOnEdit={false}
                    onBlur={() => Validation("confirmpassword")}
                    onIonChange={(e) => { setConfirmpassword(e.detail.value!); ChangeValidation("confirmpassword", e) }}
                  />
                  {formErrors.confirmpassword && <span className="alert_input_bottom ">{formErrors.confirmpassword}</span>}
                </IonCol>
              </IonRow>
              <IonRow className="btn_row_padding">
                <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                  <IonButton
                    type="submit"
                    disabled={
                      confirmpassword.length === 0 ||
                      newpassword.length === 0 ||
                      oldpassword.length === 0
                    }
                  >
                    <span className="button_padding">
                      Submit
                    </span>
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </div>
        </form>


        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={msgerror}
          message={message}
          buttons={["Dismiss"]}
        />

        {userdata[0] != "ROLE_USER" ? <></> :
          <Foot />}
      </IonContent>
    </IonPage>
  );
};
export default ChangePassword;
