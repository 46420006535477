import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import Foot from "./Foot";
import "./Home.css";
import HeadFoot from "./Head";

const RefundPolicy: React.FC = () => {

  const userdata: any = useState(localStorage.getItem("UserData"));
  const [token, settoken]: any = useState(localStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Refund Policy"} />
        <IonRow className="page_content_padding">
          <IonCol className="">
            <div style={{ margin: "2% 4%" }}>
              <div>
                <p style={{ textAlign: "justify" }}> Once we receive your return or the seller notifies us of receipt of return, as the case may be, a refund is issued to the original payment method (in case of pre-paid transactions) or to your bank account (in case of Pay on Delivery orders). </p>
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Refund Timelines :</label>
                <p style={{ textAlign: "justify" }}>The refund time period for different modes of payments is provided below.</p>
              </div>
              <div style={{ justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <table style={{ width: "70%" }}>
                  <thead style={{ display: "flex" }}>
                    <th style={{ width: "30%", textAlign: "left" }}>
                      Available refund method
                    </th>
                    <th style={{ width: "70%" }}>
                      Refund Time-frame
                    </th>
                  </thead>
                  <thead style={{ display: "flex" }}>
                    <th style={{ width: "30%" }}>
                    </th>
                    <th style={{ width: "70%", display: "flex" }}>
                      <td style={{ width: "50" }}>
                        Orders (After the return is received by Atmagram)
                      </td>
                      <td style={{ width: "50" }}>
                        Fulfilled orders (After seller notifies Atmagram of receipt of return)
                      </td>
                    </th>
                  </thead>
                  <tbody>
                    <tr style={{ display: "flex" }}>
                      <th style={{ width: "30%", textAlign: "left" }}>
                        Prepaid Orders
                      </th>
                      <th style={{ width: "70%" }}>
                      </th>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "30%", textAlign: "left" }}>
                        Credit Card/ Debit Card
                      </td>
                      <tr style={{ width: "70%", display: "flex" }}>
                        <td style={{ width: "78%" }}>
                          2-4 Business Days
                        </td>
                        <td style={{ width: "100%" }}>
                          3-5 Business Days
                        </td>
                      </tr>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "30%", textAlign: "left" }}>
                        Net Banking Account (Credited to Bank Account)
                      </td>
                      <tr style={{ width: "70%", display: "flex" }}>
                        <td style={{ width: "108%" }}>
                        </td>
                      </tr>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "30%", textAlign: "justify" }}>
                        UPI Linked Bank Account
                      </td>
                      <tr style={{ width: "70%", display: "flex" }}>
                        <td style={{ width: "108%" }}>
                          2-4 business days
                        </td>
                      </tr>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <th style={{ width: "30%", textAlign: "left" }}>
                        Pay on Delivery Orders
                      </th>
                      <th style={{ width: "70%" }}>
                      </th>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "30%", textAlign: "left" }}>
                        NEFT to Bank Account
                      </td>
                      <tr style={{ width: "70%", display: "flex" }}>
                        <td style={{ width: "78%" }}>
                          2-4 Business Days
                        </td>
                        <td style={{ width: "100%" }}>
                          3-5 Business Days
                        </td>
                      </tr>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "30%", textAlign: "left" }}>
                        Paper Cheque
                      </td>
                      <tr style={{ width: "70%", display: "flex" }}>
                        <td style={{ width: "108%" }}>
                          2-4 Business Days
                        </td>
                      </tr>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ paddingTop: "15px" }}>
                <p style={{ textAlign: "justify" }}>
                  If the standard time-frame as mentioned in the above table has expired and you have still not received the refund, please contact your credit or debit card issuer or your bank for more information. Refunds will not be processed in cash. Refunds can be processed via paper cheque only in exceptional cases.
                </p>
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>For Seller-Fulfilled orders :</label>
                <p style={{ textAlign: "justify" }}>
                  1. For damaged/ defective items, the seller will issue a refund if the item cannot be repaired or replaced.
                </p>
                <p style={{ textAlign: "justify" }}>
                  2. In any case where a refund is required, the seller(s) need to authorise that refund. Atmagram can assist in facilitating refunds for you only when the seller notifies us of the receipt of the item. Once the seller notifies us of the receipt of the return item, the above refund time period will apply for processing refunds.              </p>
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Refund for Pay on Delivery Orders :</label>
                <p style={{ textAlign: "justify" }}>
                  For Pay on Delivery orders, refunds will be processed either to your bank account (via National Electronic Funds Transfer (NEFT)).
                </p>
                <p style={{ textAlign: "justify" }}>
                  If you wish to receive the Pay on Delivery order’s refund to your bank account, you can update the details of the bank account in Your Account section or from the Returns Centre when you are returning an item.
                </p>
                <p style={{ textAlign: "justify" }}>
                  <label style={{ fontWeight: "bold" }}>Note :</label>
                  Refunds cannot be processed to third-party accounts. The name on your Atmagram account should match with the name of the bank account holder.
                </p>
                <p style={{ textAlign: "justify" }}>
                  In case you plan to drop your cheque in a clearance box, please note the following :
                </p>
                <p style={{ textAlign: "justify" }}>
                  - If you are dropping the cheque in a clearance box in any of the above cities, use the box marked Local Cheques.
                </p>
                <p style={{ textAlign: "justify" }}>
                  - If you are dropping the cheque in a clearance box in any other city, use the box marked Outstation Cheques.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Failing to follow the above instructions might result in the cheque not being processed and a penalty being levied by the bank.
                </p>
                <p style={{ textAlign: "justify" }}>
                  <label style={{ fontWeight: "bold" }}>Note :</label>
                  Once a cheque is issued, Atmagram will send you an e-mail with the tracking details of the refund cheque within 4 business days from the date of refund.
                </p>
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Shipping Cost Refunds</label>
                <p style={{ textAlign: "justify" }}>
                  For eligible items, return shipping costs of upto Rs. 100 will be refunded. The cost of gift-wrapping will also be refunded, if any. All such refunds will be issued through cheques.
                </p>
                <p style={{ textAlign: "justify" }}>
                  <label style={{ fontWeight: "bold" }}>Note :</label>
                  If you incur return shipping charges over Rs.100 for returning large and heavy items, you can contact us for an additional refund. For refund of such additional charges, proof of payment, like a courier receipt needs to be submitted.
                </p>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <Foot />
      </IonContent>
    </IonPage >
  );
};

export default RefundPolicy;