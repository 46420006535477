import {
    IonRow,
    IonCol,
    IonText,
    IonButton,
    IonPage,
    IonContent,
    IonLoading,
    IonImg,
    IonIcon,
    IonCard,
    IonGrid,
    IonItemDivider
} from "@ionic/react";
import "./Bookdetails.css"
import "./Myorder.css"
import Authservice from "../Services/user.service";
import React, { useEffect, useRef, useState, useSyncExternalStore } from "react";
import Lightbox from "react-image-lightbox";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import Foot from "./Foot";
import moment from "moment";
import { checkmarkCircleSharp, close, closeCircleOutline } from "ionicons/icons";
import { set } from "react-hook-form";
import { useHistory } from "react-router";
import HeadFoot from "./Head";
import { tick } from "../theme/imagePath";

const Myorder: React.FC = () => {
    const [token, settoken]: any = useState(localStorage.getItem("token"));
    const [myOrder, setMyOrder] = useState([]);
    const [islogin, Islogin] = useState(false);
    const userdata: any = useState(localStorage.getItem("UserData"));
    const uFlag: any = useState(localStorage.getItem("uFlag"));
    const [showLoading, setShowLoading] = useState(false);
    const [modalopen, setModalopen] = useState(false);
    const [showreceipt, setShowreceipt] = useState(false)
    const [status, setStatus]: any = useState([]);
    const history = useHistory();
    const [prasadhamflag, setPrasadhamflag]: any = useState([]);

    const formatter = new Intl.NumberFormat('en-IN')

    useEffect(() => {
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_USER") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            setShowLoading(true);
            Authservice.MyOrder(token).then((respone: any) => {
                console.log(respone)
                if (respone.data !== undefined) {
                    setMyOrder(respone.data);
                } else {
                    setMyOrder([]);
                }
                setShowLoading(false);
            }).catch((error) => {
                console.log(error)
                setShowLoading(false);
            });
        }
    }, [])
    const [checkdelflag, setCheckdelflag] = useState(false)
    const [orderNumber, setOrdernumber] = useState("")
    const ViewStatus = (item: any) => {
        console.log(item, "qqq")
        setCheckdelflag(item.poojaOfferingOrders.userCartDetails.prasadhamDelFlag)
        setStatus([])
        var status1 = []
        for (let i = 0; i < item.poojaOfferingOrdersStatusList.length; i++) {
            status1.push(item.poojaOfferingOrdersStatusList[i])
        }
        setOrdernumber(item.poojaOfferingOrders.orderNumber)
        setStatus(status1)
        console.log(status1, "dijvwbi");
        setModalopen(true);
    }

    const useOutsideAlerter = (refer: any, receipt: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (refer.current && !refer.current.contains(event.target)) {
                    setModalopen(false)
                }
                if (receipt.current && !receipt.current.contains(event.target)) {
                    setShowreceipt(false)
                    setModalopen(true)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer, receipt]);
    }
    const statusmodal: any = useRef(null);
    const receiptmodal: any = useRef(null)

    useOutsideAlerter(statusmodal, receiptmodal);

    const [receipt, setReceipt]: any = useState("")

    const viewreceipt = (item: any) => {
        console.log(item, "itemmmmm")
        setReceipt(item.receipt)
        setShowreceipt(true)
    }

    const closemodal = () => {
        setShowreceipt(false)
        setModalopen(true)
    }

    return (
        <IonPage>
            <IonContent>
                <HeadFoot User={"login"} Role={'Role'} Name='Home,My Offerings' />
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                // onDidDismiss={() => setShowLoading(false)}

                // duration={5000}
                />
                {showreceipt && (
                    <Lightbox
                        mainSrc={receipt + "?v=" + new Date()}
                        onCloseRequest={() => setShowreceipt(false)}
                        onImageLoad={() => {
                            window.dispatchEvent(new Event('resize'));
                        }}
                    />
                )}
                {/* <UserHead User="materials" Role={userdata[0]} /> */}
                <div className="backgroundorders page_content_padding">
                    {showLoading === false && myOrder.length === 0 ? <div style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonText className="no_data" >No Data Available</IonText>
                    </div> : <div><IonGrid>
                        {myOrder.map((item: any, i: any) => {
                            var dateSplit = item.poojaOfferingOrders.orderDate.split("T")
                            const odate = moment(dateSplit[0]).format("DD-MM-YYYY");
                            var fdmdate;
                            var tdmdate;
                            let ldmdate: any;
                            var fromdate = item.poojaOfferingOrders.userCartDetails.fromDate;
                            var todate = item.poojaOfferingOrders.userCartDetails.toDate;
                            var lddate = item.poojaOfferingOrders.userCartDetails.listOfDates;
                            if (fromdate === null || fromdate === "" || todate === null || todate === "") {
                                fromdate = "";
                                todate = "";
                            } else {
                                var fd = fromdate.split("T");
                                var td = todate.split("T");
                                const fdm = moment(fd[0]).format("DD-MM-YYYY");
                                const tdm = moment(td[0]).format("DD-MM-YYYY");
                                fdmdate = fdm;
                                tdmdate = tdm;
                                // console.log(fdm,"fromDate");
                                // console.log(tdm,"toDate");
                            }
                            if (lddate === null || lddate === "") {
                                lddate = "";
                            }
                            else {
                                const ld = lddate.split("||");
                                const ld3 = ld.splice(-1);
                                const ld2 = ld.toString();
                                const ld4 = ld2.split('T12:00:00,').join().split("T12:00:00").join();
                                const ld5 = ld4.split(",");
                                const ld6 = ld5.splice(-1);
                                const ldm2: any = [];
                                for (let i = 0; i < ld5.length; i++) {
                                    var ldm = moment(ld5[i]).format("DD-MM-YYYY")
                                    // var ldm2 
                                    ldm2.push(ldm);
                                    // console.log(ldm)
                                }
                                ldmdate = ldm2.join(", ");
                            }
                            // console.log(ldmdate);
                            return (
                                <IonCard className="ordercard">
                                    <IonRow className="order_header">
                                        <IonCol>
                                            
                                            <IonText className="order_id">{i + 1}. {item.poojaOfferingOrders.orderNumber}</IonText>
                                        </IonCol>
                                        <IonCol style={{ display: "flex", justifyContent: "flex-end", paddingRight: "20px" }}>

                                            <IonText className="order_id">₹ {formatter.format(item.poojaOfferingOrders.totalPaidAmount)}</IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="order_row">
                                        <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12" >
                                            {/* <IonRow style={{paddingTop:'5px'}} >
                                           
                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                                <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                </IonIcon>
                                                            </IonCol>
                                            <IonCol size-lg="4" size-md="3" size-sm="2.9" size-xs="2.9" >
                                                <IonText className="order_detail">Order Number</IonText>
                                            </IonCol>
                                            <IonCol size-lg="0.5" size-md="1" size-sm="0.9" size-xs="0.9" >
                                                <IonText className="order_detail">:</IonText>
                                            </IonCol>
                                            <IonCol size-lg="6.5" size-md="3" size-sm="2.9" size-xs="2.9" >
                                                <IonText className="order_detail">{item.poojaOfferingOrders.orderNumber}</IonText>
                                            </IonCol>
                                           
                                        </IonRow> */}

                                            <IonRow>

                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                    </IonIcon>
                                                </IonCol>
                                                <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                    <IonText className="order_detail">Offering Name </IonText>
                                                </IonCol>
                                                <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                    <IonText className="order_detail">:</IonText>
                                                </IonCol>
                                                <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                    <IonText className="order_detail">{item.poojaOfferingOrders.poojaOfferings.name}</IonText>
                                                </IonCol>

                                            </IonRow>
                                            <IonRow>
                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                    </IonIcon>
                                                </IonCol>
                                                <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                    <IonText className="order_detail">Temple Name </IonText>
                                                </IonCol>
                                                <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                    <IonText className="order_detail">:</IonText>
                                                </IonCol>
                                                <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                    <IonText className="order_detail">{item.poojaOfferingOrders.userCartDetails.poojaOfferings.temple.name}</IonText>
                                                </IonCol>

                                            </IonRow>
                                            <IonRow>
                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                    </IonIcon>
                                                </IonCol>
                                                <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                    <IonText className="order_detail">Deity Name </IonText>
                                                </IonCol>
                                                <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                    <IonText className="order_detail">:</IonText>
                                                </IonCol>
                                                <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                    <IonText className="order_detail">{item.poojaOfferingOrders.userCartDetails.poojaOfferings.deity.deityName}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                    </IonIcon>
                                                </IonCol>
                                                <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                    <IonText className="order_detail">Order Date</IonText>
                                                </IonCol>
                                                <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                    <IonText className="order_detail">:</IonText>
                                                </IonCol>
                                                <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                    <IonText className="order_detail">{odate}</IonText>
                                                </IonCol>

                                            </IonRow>
                                            {/* <IonRow>
                                        <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                                <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                </IonIcon>
                                                            </IonCol>
                                            <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                <IonText className="order_detail">Total Price</IonText>
                                            </IonCol>
                                            <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                <IonText className="order_detail">:</IonText>
                                            </IonCol>
                                            <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                <IonText className="order_detail">₹{formatter.format(item.poojaOfferingOrders.totalPaidAmount)}</IonText>
                                            </IonCol>
                                        </IonRow> */}

                                        </IonCol>
                                        <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12">
                                            {item.poojaOfferingOrders.userCartDetails.firstName && <IonRow style={{ paddingTop: '5px' }}>
                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                    </IonIcon>
                                                </IonCol>
                                                <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                    <IonText className="order_detail">Name</IonText>
                                                </IonCol>
                                                <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                    <IonText className="order_detail">:</IonText>
                                                </IonCol>
                                                <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                    <IonText className="order_detail">{item.poojaOfferingOrders.userCartDetails.firstName}</IonText>
                                                </IonCol>
                                            </IonRow>}

                                            {item.poojaOfferingOrders.userCartDetails.star && <IonRow>
                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                    </IonIcon>
                                                </IonCol>
                                                <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                    <IonText className="order_detail">Star</IonText>
                                                </IonCol>
                                                <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                    <IonText className="order_detail">:</IonText>
                                                </IonCol>
                                                <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                    <IonText className="order_detail">{item.poojaOfferingOrders.userCartDetails.star}</IonText>
                                                </IonCol>
                                            </IonRow>}

                                            {item.poojaOfferingOrders.userCartDetails.deliveryAddress === "" ||
                                                item.poojaOfferingOrders.userCartDetails.deliveryAddress === "," ? <></> :
                                                <IonRow>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                        <IonText className="order_detail">Delivery Address</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                        <IonText className="order_detail">{item.poojaOfferingOrders.userCartDetails.deliveryAddress}</IonText>
                                                    </IonCol>

                                                </IonRow>}



                                            {item.poojaOfferingOrders.userCartDetails.dakshinaAmountForPriest === null ? <></> :
                                                <IonRow>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                        <IonText className="order_detail">Dakshina Amount</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                        <IonText className="order_detail">₹ {formatter.format(item.poojaOfferingOrders.userCartDetails.dakshinaAmountForPriest)}</IonText>
                                                    </IonCol>

                                                </IonRow>}
                                            {item.poojaOfferingOrders.userCartDetails.dakshinaAmountToTemple === null ? <></> : <IonRow>
                                                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                    </IonIcon>
                                                </IonCol>
                                                <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                    <IonText className="order_detail">Donation Amount</IonText>
                                                </IonCol>
                                                <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                    <IonText className="order_detail">:</IonText>
                                                </IonCol>
                                                <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                    <IonText className="order_detail">₹ {formatter.format(item.poojaOfferingOrders.userCartDetails.dakshinaAmountToTemple)}</IonText>
                                                </IonCol>

                                            </IonRow>}

                                            {item.poojaOfferingOrders.userCartDetails.noOfMonths === null ? <></> :
                                                <IonRow>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                        <IonText className="order_detail">No. of Months</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                        <IonText className="order_detail">{item.poojaOfferingOrders.userCartDetails.noOfMonths}</IonText>
                                                    </IonCol>

                                                </IonRow>}
                                            {item.poojaOfferingOrders.userCartDetails.fromDate === null ? <></> :
                                                <IonRow>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                        <IonText className="order_detail">From Date</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                        <IonText className="order_detail">{fdmdate}</IonText>
                                                    </IonCol>

                                                </IonRow>}
                                            {item.poojaOfferingOrders.userCartDetails.toDate === null ? <></> :
                                                <IonRow>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                        <IonText className="order_detail">To Date</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                        <IonText className="order_detail">{tdmdate}</IonText>
                                                    </IonCol>

                                                </IonRow>}
                                            {item.poojaOfferingOrders.userCartDetails.listOfDates === null ? <></> :
                                                <IonRow>
                                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size-lg="4" size-md="3" size-sm="3" size-xs="3">
                                                        <IonText className="order_detail">List Of Dates</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="0.5" size-md="1" size-sm="1" size-xs="1">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="6.5" size-md="7" size-sm="7" size-xs="7">
                                                        <IonText className="order_detail">{ldmdate}</IonText>
                                                    </IonCol>

                                                </IonRow>}

                                        </IonCol>

                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeLg="4" sizeMd="8" sizeXl="8" sizeSm="8" >
                                            <div className="center_col">\
                                                <IonButton size="small" onClick={() => ViewStatus(item)}>
                                                    <span className="button_padding">View Order Status

                                                    </span>
                                                </IonButton></div>

                                        </IonCol>
                                    </IonRow>



                                </IonCard>
                            )
                        })} </IonGrid>
                    </div>}



                </div>
                <Modal show={modalopen} className='offering-modal' centered>
                    <ModalHeader className="donation-modal-header">

                        <h5 className="donation-modal-title">Order Status - {orderNumber}</h5>
                        <div>
                            <IonIcon src={close} className="close_icon" onClick={() => setModalopen(false)} />
                        </div>


                    </ModalHeader>
                    <Modal.Body>

                        <div className="custom-modal-deity scroll_tab" >
                            <IonRow className="web_hide_off_order_status">
                                <IonCol size="3" style={{ textAlign: "center" }}>
                                    <IonText className="order_detail_popup" style={{ fontWeight: "bold" }}>Offering Date</IonText>
                                </IonCol>
                                <IonCol size="3" style={{ textAlign: "center" }}>
                                    <IonText className="order_detail_popup" style={{ fontWeight: "bold" }}>Order Status</IonText>
                                </IonCol>
                                <IonCol size="3" style={{ textAlign: "center" }}>
                                    <IonText className="order_detail_popup" style={{ fontWeight: "bold" }}>AWB Number</IonText>
                                </IonCol>
                                <IonCol size="3" style={{ textAlign: "center" }}>
                                    <IonText className="order_detail_popup" style={{ fontWeight: "bold" }}>Receipt</IonText>
                                </IonCol>
                            </IonRow>
                            {status.map((item: any, index: any) => {
                                var dateSplit = item.orderedDate.split("T")
                                const odate = moment(dateSplit[0]).format("DD-MM-YYYY");
                                const Sta = item.status
                                const awb = item.awbNumber
                                var receipt;
                                if (item.receipt === null) {
                                    receipt = ""
                                }
                                else {
                                    const splited: any = item.receipt.split("/")
                                    receipt = splited[splited.length - 1]
                                }
                                return (
                                    <>
                                        <IonRow className="web_hide_off_order_status">
                                            <IonCol size="3" style={{ textAlign: "center", marginTop: "2%" }}>
                                                <IonText className="order_detail_popup">{odate}</IonText>
                                            </IonCol>
                                            <IonCol size="3" style={{ textAlign: "center", marginTop: "2%" }}>
                                                <IonText className="order_detail_popup">{Sta}</IonText>
                                            </IonCol>
                                            <>
                                                {awb === "" || awb === null ?
                                                    <IonCol size="3" style={{ textAlign: "center", marginTop: "2%" }}>
                                                        <IonText className="order_detail_popup">Not Yet Initiated</IonText>
                                                    </IonCol> :
                                                    <IonCol size="3" style={{ textAlign: "center", marginTop: "2%" }}>
                                                        <IonText className="order_detail_popup">{awb}</IonText>
                                                    </IonCol>}
                                            </>
                                            <IonCol size="3" style={{
                                                textAlign: "center", marginTop: "2%",
                                                width: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                            }}>
                                                {receipt === "" ? <IonText className="order_detail_popup">Not Available</IonText> :
                                                    <IonText onClick={() => viewreceipt(item)} style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                                        className="order_detail_popup">{odate}_RT{index + 1}</IonText>}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="order_status_offering_mobile">
                                            <IonCol size="12">
                                                <IonRow>
                                                    <IonCol className="order_status_col_padding" size-lg="1" size-md="1" size-xs="1.5" size-sm="1">
                                                        <IonText className="order_detail_popup">{index + 1}.</IonText>
                                                    </IonCol>
                                                    <IonCol className="order_status_col_padding" size="5" size-md="3" size-xs="4" size-sm="4">
                                                        <IonText className="order_detail_popup"> Order Date</IonText>
                                                    </IonCol>
                                                    <IonCol className="order_status_col_padding" size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                                                        <IonText className="order_detail_popup">:</IonText>
                                                    </IonCol>
                                                    <IonCol className="order_status_col_padding" size="5.5" size-md="7.5" size-xs="6" size-sm="6.5">
                                                        <IonText className="order_detail_popup">{odate}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="order_status_col_padding" size-lg="1" size-md="1" size-xs="1.5" size-sm="1" />
                                                    <IonCol className="order_status_col_padding" size="5" size-md="3" size-xs="4" size-sm="4">
                                                        <IonText className="order_detail_popup">Order Status</IonText>
                                                    </IonCol>
                                                    <IonCol className="order_status_col_padding" size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                                                        <IonText className="order_detail_popup">:</IonText>
                                                    </IonCol>
                                                    <IonCol className="order_status_col_padding" size="5.5" size-md="7.5" size-xs="6" size-sm="6.5">
                                                        <IonText className="order_detail_popup">{Sta}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="order_status_col_padding" size-lg="1" size-md="1" size-xs="1.5" size-sm="1" />
                                                    <IonCol className="order_status_col_padding" size="5" size-md="3" size-xs="4" size-sm="4">
                                                        <IonText className="order_detail_popup">AWB Number</IonText>
                                                    </IonCol>
                                                    <IonCol className="order_status_col_padding" size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                                                        <IonText className="order_detail_popup">:</IonText>
                                                    </IonCol>
                                                    {awb === "" || awb === null ?
                                                        <IonCol className="order_status_col_padding" size="5.5" size-md="7.5" size-xs="6" size-sm="6.5">
                                                            <IonText className="order_detail_popup">Not Yet Initiated</IonText>
                                                        </IonCol> :
                                                        <IonCol className="order_status_col_padding" size="5.5" size-md="7.5" size-xs="6" size-sm="6.5">
                                                            <IonText className="order_detail_popup">{awb}</IonText>
                                                        </IonCol>
                                                    }
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="order_status_col_padding" size-lg="1" size-md="1" size-xs="1.5" size-sm="1" />
                                                    <IonCol className="order_status_col_padding" size="5" size-md="3" size-xs="4" size-sm="4">
                                                        <IonText className="order_detail_popup">Receipt</IonText>
                                                    </IonCol>
                                                    <IonCol className="order_status_col_padding" size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                                                        <IonText className="order_detail_popup">:</IonText>
                                                    </IonCol>
                                                    <IonCol className="order_status_col_padding" size="5.5" size-md="7.5" size-xs="6" size-sm="6.5"
                                                        style={{
                                                            width: "100px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                                        }}>
                                                        {receipt === "" ? <IonText className="order_detail_popup">Not Available</IonText> :
                                                            <IonText onClick={() => viewreceipt(item)} style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                                                className="order_detail_popup">{odate}_RT{index + 1}</IonText>}
                                                    </IonCol>
                                                </IonRow>
                                                <IonItemDivider className="divider_order_details" />
                                            </IonCol>
                                        </IonRow>
                                    </>
                                )
                            }
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
                {/* <Modal show={modalopen} className="custom-modal-wrapper"  >
                    <Modal.Body ref={showreceipt === true ? null : statusmodal}>
                        <div className="order_status_header_container" >
                            <h5 className="order_status_text">Order Status - {orderNumber}</h5>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModalopen(false)}>
                                <span aria-hidden="true">x</span>
                            </button>
                        </div>

                    </Modal.Body>
                </Modal> */}

                <Foot />
            </IonContent>
        </IonPage>
    )
}


export default Myorder;
