import {
    IonRow,
    IonCol,
    IonText,
    IonGrid,
    IonPage,
    IonContent,
    IonItemDivider,
    IonLoading,
    IonCard,
    IonImg,
    IonIcon,
    IonButton,
    useIonAlert
} from "@ionic/react";
import "./Bookdetails.css"
import React, { useEffect, useState } from "react";
import UserHead from "./UserHead";
import Foot from "./Foot";
import Authservice from "../Services/user.service";
import moment from "moment";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router";
import HeadFoot from "./Head";
import { tick } from "../theme/imagePath";
import { checkmarkCircleSharp, downloadOutline } from "ionicons/icons";
import { saveAs } from 'file-saver';

const Myprojectdonation: React.FC = () => {
    const userdata: any = useState(localStorage.getItem("UserData"));
    const [data, setData] = useState([]);
    const [token, settoken]: any = useState(localStorage.getItem("token"));
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory()
    const uFlag: any = useState(localStorage.getItem("uFlag"))
    const formatter = new Intl.NumberFormat('en-IN')
    const [Alert] =  useIonAlert()

    useEffect(() => {
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_USER") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            showdonation();
        }
    }, [])
    const downInvoice = (payId:any,no:any) => {
        setShowLoading(true);
        Authservice.projdonationInvoice(token,payId).then((response) => {
            console.log(response);
    // Decode the base64 string
    const decodedData = atob(response.data);
    const filename = "donation_"+no+".pdf";
    
    // Convert the decoded data to a Uint8Array
    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
    }
   
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    saveAs(blob, filename);
           setShowLoading(false);
        }) .catch((error) => {
            setShowLoading(false);
            Alert("Something went wrong, please try again later.")
           
        });
    }

    const showdonation = () => {
        setShowLoading(true);
        Authservice.ProjectDonationOrderUser(token)
            .then((response) => {
                console.log(response.data);
                if (response.data) {
                    setData(response.data);
                } else {
                    setData([])
                }
                setShowLoading(false);
            })
            .catch((error) => {
                setData([])
                setShowLoading(false);
            });
    }

    return (
        <IonPage>
            <IonContent>
                <HeadFoot User={"login"} Role={'Role'} Name='Home,My Project Donation' />
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                // onDidDismiss={() => setShowLoading(false)}

                // duration={5000}
                />
                {/* <UserHead  User="projectdonations" Role={userdata[0]} /> */}
                <div className="backgroundorders page_content_padding">

                    {showLoading === false && data.length == 0 ? <div style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonText className="no_data">No Data Available</IonText>
                    </div> : <div>
                        <IonGrid>
                            {data.map((item: any, i: any) => {
                                var donationdate;
                                var dd = item.donationDate;
                                if (dd === null || dd === "") {
                                    dd = "";
                                }
                                else {
                                    var dd1 = dd.split("T");
                                    const ddate = moment(dd1[0]).format("DD-MM-YYYY");
                                    donationdate = ddate;
                                }
                                return (
                                    <IonCard className="ordercard">
                                        <IonRow className="order_header">
                                            <IonCol>
                                           
                                            <IonText className="order_id">{i + 1}. {item.donationNumber}</IonText>
                                            </IonCol>
                                            <IonCol style={{ display: "flex", justifyContent: "flex-end", paddingRight: "20px" }}>

                                                <IonText className="order_id">₹ {formatter.format(item.contributionAmount)}</IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="order_pad">
                                            <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12" >
                                                {/* <IonRow  style={{paddingTop:'5px'}}>
                                                    
                                                    <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                                <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                </IonIcon>
                                                            </IonCol>
                                                    <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                        <IonText className="order_detail">Donation Number</IonText>
                                                    </IonCol>
                                                    <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                        <IonText className="order_detail">{item.donationNumber}</IonText>
                                                    </IonCol>
                                                </IonRow> */}
                                                <IonRow>
                                                    <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                        <IonText className="order_detail">Project Title</IonText>
                                                    </IonCol>
                                                    <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                        <IonText className="order_detail">{item.projectModel.projectTitle}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                        <IonText className="order_detail">PAN Number</IonText>
                                                    </IonCol>
                                                    <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                        <IonText className="order_detail">{item.panCardNumber}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                {item.donorName === "" || item.donorName === null ? <></> :
                                                    <IonRow>
                                                        <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                            </IonIcon>
                                                        </IonCol>
                                                        <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                            <IonText className="order_detail"> Donor Name </IonText>
                                                        </IonCol>
                                                        <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                            <IonText className="order_detail">{item.donorName}</IonText>
                                                        </IonCol>
                                                    </IonRow>}
                                                    <IonRow>
                                                        <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                            </IonIcon>
                                                        </IonCol>
                                                        <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                            <IonText className="order_detail">Mail Id</IonText>
                                                        </IonCol>
                                                        <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                            <IonText className="order_detail">{item.emailId}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                {item.remarks === "" || item.remarks === null ? <></> :
                                                    <IonRow>
                                                        <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                            </IonIcon>
                                                        </IonCol>
                                                        <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                            <IonText className="order_detail">Remarks</IonText>
                                                        </IonCol>
                                                        <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                            <IonText className="order_detail">{item.remarks}</IonText>
                                                        </IonCol>
                                                    </IonRow>}
                                            </IonCol>
                                            <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12" >
                                            <IonRow>
                                                    <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                        <IonText className="order_detail">Name as per bank</IonText>
                                                    </IonCol>
                                                    <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                        <IonText className="order_detail">{item.nameAsPerBank}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                        <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                        </IonIcon>
                                                    </IonCol>
                                                    <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                        <IonText className="order_detail">Donation Date</IonText>
                                                    </IonCol>
                                                    <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                        <IonText className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                        <IonText className="order_detail">{donationdate}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                {/* <IonRow>
                                                <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                                <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                </IonIcon>
                                                            </IonCol>
                                                    <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                        <IonText  className="order_detail">Contribution Amount</IonText>
                                                    </IonCol>
                                                    <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                        <IonText  className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                        <IonText  className="order_detail">₹ {formatter.format(item.contributionAmount)}</IonText>
                                                    </IonCol>
                                                </IonRow> */}
                                                {item.address === "" || item.address === null ? <></> :
                                                    <IonRow>
                                                        <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                            </IonIcon>
                                                        </IonCol>
                                                        <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                            <IonText className="order_detail">Address</IonText>
                                                        </IonCol>
                                                        <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                            <IonText className="order_detail">{item.address}</IonText>
                                                        </IonCol>
                                                    </IonRow>}
                                                {item.contactNumber === "" || item.contactNumber === null ? <></> :
                                                    <IonRow>
                                                        <IonCol size="1" sizeMd="1" sizeLg="1" sizeXl="1" sizeSm="1" className='tick_container' >
                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                            </IonIcon>
                                                        </IonCol>
                                                        <IonCol size="5" sizeMd="5" sizeLg="5" sizeXl="5" sizeSm="5">
                                                            <IonText className="order_detail">Contact Number</IonText>
                                                        </IonCol>
                                                        <IonCol size="0.5" sizeMd="0.5" sizeLg="0.5" sizeXl="0.5" sizeSm="0.5">
                                                            <IonText className="order_detail">:</IonText>
                                                        </IonCol>
                                                        <IonCol size="5.5" sizeMd="5.5" sizeLg="5.5" sizeXl="5.5" sizeSm="5.5">
                                                            <IonText className="order_detail">{Authservice.formatPhone(item.contactNumber)}</IonText>
                                                        </IonCol>
                                                    </IonRow>}
                                            </IonCol>
                                        </IonRow>
                                       
<IonRow>
                                            <IonCol size="12">
                                        <div style={{ display: "flex", justifyContent: "flex-end",margin:"10px",alignItems:"center" }}>
                                            <IonButton size="small" className="order_invoice_button" onClick={()=>downInvoice(item.id,item.donationNumber)}>
                                                <IonIcon src={downloadOutline} style={{ fontSize: "20px"}} />
                                                <span className="order_button_padding" style={{ marginLeft: "10px" }}>
                                                    Invoice
                                                </span>
                                            </IonButton>
                                        </div></IonCol>
                                        </IonRow>
                                    </IonCard>

                                )
                            }
                            )}
                        </IonGrid>
                    </div>}
                </div>

                <Foot />
            </IonContent>
        </IonPage>
    )
}
export default Myprojectdonation;
