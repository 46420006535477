import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
  IonGrid,
  IonImg,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";
import "./Home.css";
import "./Userpage.css"
import HeadFoot from "./Head";
import { aboutusimg1, aboutusimg2 } from "../theme/imagePath";

const AboutUs: React.FC = () => {

  const userdata: any = useState(localStorage.getItem("UserData"));
  const [token, settoken]: any = useState(localStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"login"} Role="Role" Name="Home,About us" />
        <IonGrid className="page_content_padding whole_container_bg">
          <IonRow className="container_first_half">
            <IonCol size="12">
             
              <IonRow>
                <IonText className="sub_head_text">
                Platform
                </IonText>
              </IonRow>
              
              <IonRow>
                <p className="vision_content1">
                Atmagram is a divine and organic space for you to be part of a culture that is eternal and blissful. It is a place where the oldest culture of the world is celebrated in abundance. It is so graceful that you have multiple ways to connect to divinity, purity and materials made of amazing dedication and commitment. You are connecting with the soul of villages whispering vibrant Vedas. Just listen to the music; the music of beautiful life set in the serenity of consciousness and oneness. Your spiritual journey starts here.
                </p>
              </IonRow>
              <IonRow>
                <IonText className="sub_head_text">
                Who We Are
                </IonText>
              </IonRow>
              <IonRow>
                <p className="vision_content1">
                We help transform Indian villages by connecting them with the international community across the globe. Our mission is to provide a space for those who seek to make a difference in the world and seamlessly connect the seekers with opportunities.   </p>
              </IonRow>
              <IonRow>
                <IonText className="sub_head_text">
                What We Do
                </IonText>
              </IonRow>
              <IonRow>
                <p className="vision_content1">
                We connect with people; artisans, craftsmen, weavers, traditional musicians, farmers, tribal community and the like through atmagram.com and create a market for them to be able to sell their skill and output. A host of products with goodness of high spiritual and ethical value are offered for the consumption for the global good and in the process enrich the celestial community of the bottom of the economic pyramid and sustain them in their original characteristic economically, spiritually and culturally.  </p>
              </IonRow>
            </IonCol>
            {/* <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12" className="img_col_align">
              <IonImg className="about_img" src={aboutusimg1} />
            </IonCol> */}
          </IonRow>
          {/* <div className="container_second_half">
            <IonRow className="content_padding">
              <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                <IonRow>
                  <IonText className="head_text1">
                    Our Apporach
                  </IonText>
                </IonRow>
                <IonRow>
                  <IonText className="sub_head_text">
                    Our Core Vision And Mission.
                  </IonText>
                </IonRow>
                <IonRow style={{ marginTop: "10px" }}>
                  <p className="vision_content1">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorpe
                  </p>
                </IonRow>
                <IonRow style={{ marginTop: "10px" }}>
                  <p className="vision_content1">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorpe
                  </p>
                </IonRow>
              </IonCol>
              <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12" className="img_col_align">
                <IonImg className="about_img" src={aboutusimg2} />
              </IonCol>
            </IonRow>
          </div> */}
        </IonGrid>
        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default AboutUs;