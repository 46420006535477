import React from "react";
import {
  IonToolbar,
  IonRow,
  IonCol,
  IonIcon,
  useIonRouter,
  IonItemDivider,
  IonImg,
  IonText,
} from "@ionic/react";
import "./Userpage.css";
import "./Home.css";
import "./Login.css"


import {
  logoFacebook,
  call,
  logoYoutube,
  logoTwitter,
  logoInstagram,
  mailOutline,
  callOutline,
  logoWhatsapp,
} from "ionicons/icons";
import { Link } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { logo } from "../theme/imagePath";
const Foot: React.FC = () => {


  const router = useIonRouter();
  const recipient = 'srisankarapeetam@gmail.com';
  const subject = 'Atmagram';

  const shareViaWhatsapp = () => {
    const phoneNumber = '+918281641474'; // Replace with the phone number you want to share with
    const message = encodeURIComponent('Hi Atmagram');

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };
  const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    subject
  )}`;
  const phone = "+918281641474";

  return (<div>
    {Capacitor.getPlatform() === 'web' && <div>
      <IonToolbar className="high_align" color="primary">
        <IonRow style={{ marginTop: "20px" }}>
          <IonCol size-lg="5" size-md="5" size-xs="12" size-sm="12">
            <div className="container1">
              <IonRow className="logo_container">
                <Link to="/">
                  <IonImg src={logo} className="footer_logo_size" />
                </Link>
              </IonRow>
              <div className="media_alignment_container">
                <IonRow className="followus_container">
                  <IonText className="media_text">
                    Follow Us
                  </IonText>
                </IonRow>
                <IonRow className="divider_container">
                  <IonItemDivider className="followus_divider" />
                </IonRow>
                <IonRow className="media_logo_container">
                <a className="font_icon" href="https://www.facebook.com/profile.php?id=61556576180451&mibextid=ZbWKwL" target="_blank">
                    <IonIcon src={logoFacebook} />
                  </a>
                 
                  <a className="font_icon" href="https://www.instagram.com/srisankarapeetam?igsh=dTMwaWJlamNybjE5" target="_blank">
                    <IonIcon src={logoInstagram} />
                  </a>
                  <IonIcon onClick={shareViaWhatsapp} src={logoWhatsapp} className="font_icon" tabIndex={0} />
                  <a className="font_icon" href="https://youtube.com/@srisankarapeetam3817?si=CUV17hFUfGHGnqS5" target="_blank">
                    <IonIcon src={logoYoutube} /></a>
                 
                  <a href={mailtoLink} target="_blank" className="font_icon" rel="noopener noreferrer" >
                    <IonIcon src={mailOutline}   /></a>
                  <a href={`tel:${phone}`} >
                    <IonIcon src={callOutline} className="font_icon" tabIndex={0} /></a>
                </IonRow>
              </div>
            </div>
          </IonCol>
          {/* <IonCol size="0.5" size-md="0.5" size-xs="0" size-sm="0" /> */}
          <IonCol size="7" size-md="7" size-xs="12" size-sm="12">
            <IonRow>
              <IonCol size="4">
                <IonRow>
                  <Link to="/AboutUs" className="footer_text">
                    About Us
                  </Link>
                </IonRow>
                <IonRow>
                  <Link to="/ContactUs" className="footer_text">
                    Contact
                  </Link>
                </IonRow>
                <IonRow>
                  <Link to="/ConditionsofSale" className="footer_text">
                    Conditions of Sale
                  </Link>
                </IonRow>
                {/* <IonRow>
                  <Link to="/JoinUs" className="footer_text">
                    Join us
                  </Link>
                </IonRow> */}
              </IonCol>
              <IonCol size="4">
                <IonRow>
                  <Link to="/PrivacyPolicy" className="footer_text">
                    Privacy Notice
                  </Link>
                </IonRow>
                <IonRow>
                  <Link to="/PurchaseProtection" className="footer_text">
                    Purchase Protection
                  </Link>
                </IonRow>
                <IonRow>
                  <Link to="/CancellationPolicy" className="footer_text">
                    Cancellation Policy
                  </Link>
                </IonRow>
                {/* <IonRow>
                  <Link to="/Downloads" className="footer_text">
                    Downloads
                  </Link>
                </IonRow>
                <IonRow>
                  <Link to="/Enquiry" className="footer_text">
                    Enquiry
                  </Link>
                </IonRow> */}
              </IonCol>
              <IonCol size="4">
                <IonRow>
                  <Link to="/ReturnPolicy" className="footer_text">
                    Return Policy
                  </Link>
                </IonRow>
                <IonRow>
                  <Link to="/ReplacementPolicy" className="footer_text">
                    Replacement Policy
                  </Link>
                </IonRow>
                <IonRow>
                  <Link to="/RefundPolicy" className="footer_text">
                    Refund Policy
                  </Link>
                </IonRow>
              </IonCol>
              <IonCol size="4">
                <IonRow>
                  <Link to="/terms&conditions" className="footer_text">
                    Terms & Conditions
                  </Link>
                </IonRow>
               
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonItemDivider style={{ minHeight: "0px", background: "#454545", marginTop: "5px" }} />
        <IonRow style={{ padding: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>
          <div>
            <IonText className="copy_font">
              Copyrights @ 2023
            </IonText>
            <IonText className="copy_font_hignlight "> Atmagram</IonText>
            <IonText className="copy_font">
              . All Rights Reserved.
            </IonText>
          </div>
        </IonRow>
      </IonToolbar>
    </div>}

  </div>
  )
}
export default Foot
