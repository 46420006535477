import React, { useEffect, useRef, useState } from "react";
import {
    IonButton,
    IonRow,
    IonCol,
    IonGrid,
    IonLabel,
    IonInput,
    IonImg,
    IonText,
    IonTextarea,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonModal,
    useIonAlert,
    IonLoading,
    IonButtons,
    IonMenuButton,
    IonPopover,
    IonList,
    IonContent,
    IonItem,
    IonPage,
    IonAlert

} from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import "../TempleSearch.css";
import Select, { components } from 'react-select';
import { Modal } from "react-bootstrap";
import UserService from "../../Services/user.service";
import { addCircle, addCircleOutline, addOutline, arrowBack, closeCircle, contractOutline, ellipsisVertical, ellipsisVerticalCircleOutline, imageOutline, menuOutline, pin, removeCircle, removeCircleOutline, removeOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import Admtemple from "./Admtemple";
import Foot from "../Foot";
import Authservice from "../../Services/user.service";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";

const Addtemp: React.FC = () => {

    const facilities = [
        {
            type: "text",
            id: 1,
            value: ""
        }
    ];
    const facilities1 = [
        {
            type: "text",
            id: 2,
            value: ""
        }
    ];
    const inputOfferArr = [
        {
            offeringName: "",
            significance: "",
        }
    ];
    const inputOfferArr1 = [
        {
            type: "text",
            id: 0,
            value: ""
        }
    ];

    const inputArr = [
        {
            name: "",
            description: "",
        }
    ];

    const inputArr1 = [
        {
            type: "text",
            id: 1,
            description: "",
            value: ""
        }
    ];


    const inputOfferArrtext = [
        {

            videoLink: "",
            videoName: "",
            type: "1"
        }
    ];
    const inputOfferArrtext1 = [
        {

            videoLink: "",
            videoName: "",
            type: "2"
        }
    ];
    const inputpoojarow = [
        {
            name: "", description: "", price: "", offeringTime: '', deity: { id: "" }
        }
    ]
    var facilitiesDto = {};
    var offeringsAndSignificanceDto = {};

    var festivalsDto = {};
    var templesDto = {};

    const min = 0
    const max = 100000

    const [index1, setIndex1] = useState(0)
    const [Offerings, setOfferings] = useState(inputOfferArr);
    const [significance, setSignificance] = useState(inputOfferArr1);
    const [offerings, setofferings] = useState("");
    const [state, setState]: any = useState("");
    const [country, setCountry] = useState("India");
    const [district, setDistrict] = useState("");
    const [village, setVillage] = useState("");
    const [temple, setTemple] = useState("");
    const history = useHistory();
    //right
    const [aerial, setAerial]: any = useState("");
    const [history1, setHistory] = useState("");
    const [shopIn, setShopIn] = useState(facilities);
    const [AccIn, setAccIn] = useState(facilities1);
    const [parkingname, setParkingame]: any = useState("");
    const [security, setSecurity] = useState("");
    const [restrooms, setRestrooms] = useState("");
    const [cloak, setCloak] = useState("");
    const [provisions, setProvisions] = useState("");
    const [shops, setShops] = useState([]);
    const [Accessibility, setAccessibility] = useState([]);
    const [cab, setCab] = useState("");
    const [auditorium, setAuditorium] = useState("");
    const [festname, setFastname] = useState(inputArr);
    const [name, setName] = useState("");
    const [sign, setSign] = useState("");
    const [mrgDharsan, setMrgDharsan] = useState("");
    const [eveDharsan, seteveDharsan]: any = useState("");
    const [timereq, setTimereq]: any = useState([]);
    const [closetime, setCloseTime]: any = useState("");
    const [timingrow, setTimingrow]: any = useState([]);
    const [poojarow, setPoojarow]: any = useState(inputpoojarow);
    const [dressM, setDressM]: any = useState("");
    const [dressW, setDressW]: any = useState("");
    const [fee, setFee]: any = useState("");
    const [offerSIn, setofferSIn] = useState(inputOfferArrtext);
    const [offerSInn, setofferSInn] = useState(inputOfferArrtext1);
    const [usertoken] = useState(localStorage.getItem("token"));
    const [image, setImage]: any = useState([]);
    const [images, setImages]: any = useState([]);
    const [deity, setDeity]: any = useState([]);
    const [videolink, setVideolink]: any = useState([]);
    const [lat, setLat]: any = useState("");
    const [long, setLong]: any = useState("");
    const modal = useRef<HTMLIonModalElement>(null);
    const [log, setLog] = useState(false);
    const [alert] = useIonAlert();
    const [shipAddress, setShipAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [modalopen, setModalopen] = useState(false);
    const mapRef: any = useRef(null);

    const inputRef = useRef<any>(null);
    const inputRef1 = useRef<any>(null);

    const shopInputRef = useRef<any>(null);
    const accessibilityInputRef = useRef<any>(null);
    const poojaListInputRef = useRef<any>(null);
    const videoInputRef = useRef<any>(null);
    const liveVideoInputRef = useRef<any>(null);
    const videoLinkRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/i;

    const googleMapsLinkRegex = /^(https?:\/\/)?(www\.)?(google\.com\/(?:maps\/)?)(.*)/i;
    const googleMapsLinkRegex1 = /^(https?:\/\/)?(www\.)?(google\.co\.in\/(?:maps\/)?)(.*)/i;

    const [formErrors, setFormErrors]: any = useState({
    });
    const [car, setCar] = useState("")
    const [bus, setBus] = useState("")
    const [train, setTrain] = useState("")
    const [flight, setFlight] = useState("")

    const [stateOption, setStateOption] = useState<{ value: string; label: string } | null>(null);
    const [districtOption, setDistrictOption] = useState<{ value: string; label: string } | null>(null);

    const containerStyle = {

        height: '400px',
        width: '100%'
    };
    const [zoomLevel, setZoomLevel]: any = useState(15);

    const [stateList, setStateList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [returnPage, setRtnPage] = useState("");
    const actionFlag = useRef(false);
    const [templeProps, setTempleProps]: any = useState({})
    const location = useLocation()
    const [getState]: any = useState(location.state)
    const [tempId, setTempId] = useState("")
    const [adminBack, setAdminBack]: any = useState()
    const [idBack, setIdBack] = useState("")
    const [nameBack, setNameBack] = useState("")
    const [propState, setPropState] = useState("")
    const [propCity, setPropCity] = useState("")
    const [propDistrict, setPropDistrict] = useState("")
    const [tempName, setTempName] = useState("")
    const [screenwidth, setScreenwidth] = useState(window.innerWidth)

    useEffect(() => {
        getStates()
        if (getState !== undefined) {
            setTempId(getState.tempId.current)
            setAdminBack(getState.admin);
            setIdBack(getState.id)
            setNameBack(getState.name.current);
            setPropState(getState.state.current);
            setPropDistrict(getState.district.current);
            setScreenwidth(window.innerWidth);
            if (!getState.city) {
                setPropCity("")
            }
            else {
                setPropCity(getState.city.current)
            }
            if (!getState.templeName) {
                setTempName("")
            }
            else {
                setTempName(getState.templeName.current)
            }
        }
        else {
            history.push("/admtemple")
        }
        const unlisten = history.block((location, action) => {
            if (!actionFlag.current) {
                setRtnPage(location.pathname)
                console.log(location.pathname)
                actionFlag.current = true;

                setShowAlert(true);
                return false;
            }
        });

        return () => {
            unlisten();
        };
    }, [history])
    const handleLeave = () => {

        setShowAlert(false);
        actionFlag.current = true;


        history.push(returnPage); // Example: Navigate to the new page
    };
    const getStates = () => {
        setShowLoading(true)
        Authservice.getAllstates()
            .then((response) => {
                console.log(response)
                setStateList(response.data)
                setShowLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setShowLoading(false)
            })
        UserService.ShowdeitTemp(usertoken).then((response: any) => {
            console.log(response);
            console.log(response.data)
            setDeity(response.data)
            setShowLoading(false);
            console.log("Deity=>", deity)
        })
            .catch((error: any) => {
                console.log(error);
                setShowLoading(false);
            });
    }

    const imageinputref: any = useRef(null)

    const handleButtonClick = () => {
        if (imageinputref.current) {
            imageinputref.current.click();
        }
    };

    const onchangeImage = (e: any) => {
        const supportedFormats = ["jpg", "jpeg", "png"];
        var file: any = e.target.files;
        const unsupportedFiles: any = [];
        if (image.length < 10) {
            for (let i = 0; i < Math.min(file.length, (10 - image.length)); i++) {
                const splited: any = file[i].name.split(".")
                console.log(splited[1])
                const fileExtension = splited[splited.length - 1].toLowerCase();
                if (supportedFormats.includes(fileExtension)) {
                    const objectUrl = URL.createObjectURL(file[i]);
                    setImage((e: any) => [
                        ...e, objectUrl
                    ]);
                    setImages((e: any) => [
                        ...e, file[i]
                    ])
                } else {
                    unsupportedFiles.push(file[i].name);
                }
            }
        } else {
            alert("You have reached the maximum limit of 10 images.")
        }
        if (unsupportedFiles.length > 0) {
            const unsupportedFilesMessage = "Unsupported File Formats:\n" + unsupportedFiles.join(", ");
            alert(unsupportedFilesMessage + ". Supported formats are JPG, PNG, and JPEG.");
        }
    }


    const addofferInput1 = (e: any) => {
        if (e === 0) {
            console.log(offerSIn, "kmkk")
            var valSet = isFoundvideo('');
            if (!valSet) {
                setofferSIn([...offerSIn, { type: "1", videoLink: "", videoName: "" }])
                setTimeout(() => {
                    videoInputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }

        } else {
            var valSet = isFoundliveVideo('');
            if (!valSet) {
                setofferSInn([...offerSInn, { type: "2", videoLink: "", videoName: "" }])
                setTimeout(() => {
                    liveVideoInputRef.current?.setFocus();
                }, 100)
            } else {
                alert("Enter details before adding a new row")
            }

        }
    };
    const isFoundvideo = (val: any) => {
        return offerSIn.some((item: any) => (val === item.videoLink || val === item.videoName || val === item.type));
    }
    const isFoundliveVideo = (val: any) => {
        return offerSInn.some((item: any) => (val === item.videoLink || val === item.videoName || val === item.type));
    }


    var mrng = "Morning Darshan:"
    var Timings = mrng.concat(mrgDharsan, "and", "Evening Darshan:", eveDharsan, "and", "Time Required:", timereq, "and", fee, "and", "Dress Code:", dressM, "and", "Dress Code:", dressW, "and", "Closing Time:", closetime)
    const addrow = (id: any) => {
        if (id === 0) {
            var valSet = isFoundPoojaList('');
            if (!valSet) {
                setPoojarow((e: any) => {
                    return [
                        ...e, {
                            name: "", description: "", price: "", offeringTime: '', deity: { id: "" }
                        }
                    ]
                })
                setTimeout(() => {
                    poojaListInputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }
        }
    }

    const isFoundPoojaList = (val: any) => {
        return poojarow.some((item: any) => (val === item.name || val === item.description || val === item.price || val === item.offeringTime || val === item.deity.id));
    }

    const handleChangerowPname = (index: any, e: any) => {
        console.log(poojarow)
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].name = e.target.value


    }
    const restrictE = (e: any) => {
        if (e.key == "e") {
            e.preventDefault()
        }
    }
    const handleChangerowPprice = (index: any, e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].price = e.target.value

    }
    const handleChangerowDesc = (index: any, e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].description = e.target.value

    }
    const handleChangerowGod = (index: any, e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].deity.id = e.target.value

    }
    const handleChangerowPtiming = (index: any, e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].offeringTime = e.target.value


    }

    const addInputFest = (e: any) => {

        if (e === 0) {
            console.log(festname);
            var valSet = isFoundFest('');
            if (!valSet) {
                setFastname([...festname, { name: "", description: "" }])
                setTimeout(() => {
                    inputRef1.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }

        }
    };

    const isFoundFest = (val: any) => {
        return festname.some((item: any) => (val === item.name || val === item.description));
    }

    const handleChangeName = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        festname[i].name = e.detail.value;

    }

    const handleChangeSign = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(festname)
        console.log(e.detail.value)
        festname[i].description = e.detail.value;


    }

    const addInputOfferings = (e: any) => {
        if (e === 1) {
            console.log(Offerings);

            var valSet = isFound('');
            if (!valSet) {
                setOfferings([...Offerings, { significance: "", offeringName: "" }]);
                setTimeout(() => {
                    inputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }

        }
    };

    const isFound = (val: any) => {
        return Offerings.some((item: any) => (val === item.offeringName || val === item.significance));
    }

    const offerhandleChangeSIn = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(Offerings)
        Offerings[i].significance = e.detail.value;

    }

    const offerhandleChange = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(Offerings)
        Offerings[i].offeringName = e.detail.value;

    }

    const videoLink = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(offerSIn)
        offerSIn[i].videoLink = e.detail.value;

    }
    const videoName = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(offerSIn)
        offerSIn[i].videoName = e.detail.value;

    }
    const Livevideolink = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(offerSInn)
        offerSInn[i].videoLink = e.detail.value;

    }
    const Livevideoname = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(offerSInn)
        offerSInn[i].videoName = e.detail.value;

    }

    const long1 = (e: any) => {
        var long = parseFloat(e.detail.value)
        setLong(long);

    }
    const lat1 = (e: any) => {
        var lat = parseFloat(e.detail.value)
        setLat(lat);
    }

    const validation = (e: any) => {
        var shop;
        for (let i = 0; i < shopIn.length; i++) {
            if (shopIn[i].value == "") {
                shop = ""
            }
        }
        var poojaname;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].name == "") {
                poojaname = ""
                if(poojarow[i].price != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].description != ""){
                    setFormErrors({ ...formErrors, poojaname: "Please enter name for pooja list" })
                    alert("Please enter name for pooja list")
                    return;
                }
            }
        }
        var poojaprice;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].price == "") {
                poojaprice = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].description != ""){
                    setFormErrors({ ...formErrors, poojaprice: "Please enter price for pooja list" })
                    alert("Please enter price for pooja list")
                    return;
                }
            }
        }
        var poojades;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].description == "") {
                poojades = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, poojades: "Please enter description for pooja list" })
                    alert("Please enter description for pooja list")
                    return;
                }
            }
        }
        var poojatime;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].offeringTime == "") {
                poojatime = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].description != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, poojatime: "Please enter time for pooja list" })
                    alert("Please enter time for pooja list")
                    return;
                }
            }
        }
        var poojadeity;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].deity.id == "") {
                if(poojarow[i].name != "" || poojarow[i].offeringTime != "" || poojarow[i].description != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, poojadeity: "Please enter the pooja deity" })
                    alert("Please choose deity for pooja list")
                    return;
                }
                poojadeity = ""
            }
        }
        if (e == 1) {
            if (history1 == "") {
                setFormErrors({ ...formErrors, abtAndHis: "Please enter the about and history" })
            }
        } else if (e == 2) {
            if (temple == "") {
                setFormErrors({ ...formErrors, templeName: "Please enter the temple name" })
            }
        } else if (e == 3) {
            if (village == "") {
                setFormErrors({ ...formErrors, village: "Please enter the village" })
            }
        } else if (e == 4) {
            if (country == "") {
                setFormErrors({ ...formErrors, country: "Please enter the country" })
            }
        }
        else if (e == 5) {
            console.log(state)
            if (state == "") {
                setFormErrors({ ...formErrors, state: "Please select a state" })
            }
        }
        else if (e == 6) {
            if (district == "") {
                setFormErrors({ ...formErrors, district: "Please select a district" })
            }
        }
        else if (e == 7) {
            if (shipAddress == "") {
                setFormErrors({ ...formErrors, shipAddress: "Please enter the shipping address" })
            }
        }
        else if (e == 8) {
            if (pincode == "") {
                setFormErrors({ ...formErrors, pincode: "Please enter the pincode" })
            }
        }
        else if (e == 9) {
            if (parkingname.length == 0) {
                setFormErrors({ ...formErrors, parkingLot: "Please enter the parking lot" })
            }
        }
        else if (e == 10) {
            if (security == "") {
                setFormErrors({ ...formErrors, security: "Please enter the security" })
            }
        }
        else if (e == 11) {
            if (restrooms == "") {
                setFormErrors({ ...formErrors, restRooms: "Please select the restroom field" })
            }
        }
        else if (e == 12) {
            if (cloak == "") {
                setFormErrors({ ...formErrors, cloak: "Please enter the cloak counter" })
            }
        }
        else if (e == 13) {
            if (provisions == "") {
                setFormErrors({ ...formErrors, poojapro: "Please select the pooja provisions" })
            }
        }
        else if (e == 14) {
            if (cab == "") {
                setFormErrors({ ...formErrors, cab: 'Please select Cab / Auto availability' })
            }
        }
        else if (e == 15) {
            if (auditorium == "") {
                setFormErrors({ ...formErrors, auditorium: 'Please enter the auditorium' })
            }
        }
        else if (e == 16) {
            if (shop == "") {
                setFormErrors({ ...formErrors, shops: 'Please enter the shops' })
            }
        }
        else if (e == 17) {
            if (car == "") {
                setFormErrors({ ...formErrors, car: 'Please enter the car field' })
            }
        }
        else if (e == 18) {
            if (bus == "") {
                setFormErrors({ ...formErrors, bus: 'Please enter the bus field' })
            }
        }
        else if (e == 19) {
            if (train == "") {
                setFormErrors({ ...formErrors, train: 'Please enter the train field' })
            }
        }
        else if (e == 20) {
            if (flight == "") {
                setFormErrors({ ...formErrors, flight: 'Please enter the flight field' })
            }
        }
        // else if (e == 21) {
        //     if (poojaname == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja name' })
        //     }
        // }
        // else if (e == 22) {
        //     if (poojaprice == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja price' })
        //     }
        // }
        // else if (e == 23) {
        //     if (poojades == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja description' })
        //     }
        // }
        // else if (e == 24) {
        //     if (poojatime == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja timings' })
        //     }
        // }
        // else if (e == 25) {
        //     if (poojadeity == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja deity' })
        //     }
        // }
        else if (e == 26) {
            if (mrgDharsan == "") {
                setFormErrors({ ...formErrors, mrng: 'Please enter the morning darshan' })
            }
        }
        else if (e == 27) {
            if (eveDharsan == "") {
                setFormErrors({ ...formErrors, evng: 'Please enter the evening darshan' })
            }
        }
        else if (e == 28) {
            if (timereq == "") {
                setFormErrors({ ...formErrors, time: 'Please enter the darshan time' })
            }
        }
        else if (e == 29) {
            if (closetime == "") {
                setFormErrors({ ...formErrors, ctime: 'Please enter the darshan closing time' })
            }
        }
        else if (e == 30) {
            if ((fee == "") || (fee == undefined) || (fee == undefined)) {
                setFormErrors({ ...formErrors, fee: 'Please Select the fee details' })
            }
        }
        else if (e == 31) {
            if (dressM == "") {
                setFormErrors({ ...formErrors, dressM: 'Please enter the dress code for men' })
            }
        }
        else if (e == 32) {
            if (dressW == "") {
                setFormErrors({ ...formErrors, dressW: 'Please enter the dress code for women' })
            }
        }
        else if (e == 33) {
            if (aerial == "") {
                setFormErrors({ ...formErrors, aerial: 'Please Enter the temple address map link' })
            }
            else if ((!googleMapsLinkRegex.test(aerial)) && (!googleMapsLinkRegex1.test(aerial))) {
                setFormErrors({ ...formErrors, aerial: 'Please Enter a valid map link starts with  www.google.com/maps  or www.google.co.in/maps' })
            }
        }
    }

    const historyChange = (e: any) => {
        if (e.detail.value != "") {
            setFormErrors({ ...formErrors, abtAndHis: "" })
        }
        setHistory(e.detail.value!)
    }
    const templeChange = (e: any) => {
        if (e.detail.value != "") {
            setFormErrors({ ...formErrors, templeName: "" })
        }
        setTemple(e.detail.value!)
    }
    const villageChange = (e: any) => {
        if (e.detail.value != "") {
            setFormErrors({ ...formErrors, village: "" })
        }
        setVillage(e.detail.value!)
    }
    const countryChange = (e: any) => {
        if (e.detail.value != "") {
            setFormErrors({ ...formErrors, country: "" })
        }
        setCountry(e.detail.value!)
    }
    const shipAddressChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, shipAddress: "" })

        }
        setShipAddress(e.detail.value!)
    }
    const pincodeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, pincode: "" })
        }
        setPincode(e.detail.value!)
    }
    const parkingChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, parkingLot: "" })
        }
        const value = Math.max(min, Math.min(max, Number(e.target.value)))
        setParkingame(value)
    }
    const securityChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, security: "" })
        }
        setSecurity(e.detail.value!)
    }
    const restroomChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, restRooms: "" })
        }
        setRestrooms(e.detail.value!)
    }
    const cloakChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, cloak: "" })
        }
        setCloak(e.detail.value!)
    }
    const provisionChange = (e: any) => {
        console.log(e.detail.value)
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, poojapro: "" })
        }
        setProvisions(e.detail.value!)
    }
    const cabChange = (e: any) => {
        console.log(e.detail.value)
        if (e.detail.value !== undefined || e.detail.value !== null) {
            setFormErrors({ ...formErrors, cab: "" })
        }
        setCab(e.detail.value!)
    }
    const audChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, auditorium: "" })
        }
        setAuditorium(e.detail.value!)
    }
    const carChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, car: "" })
        }
        setCar(e.detail.value!)
    }
    const busChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, bus: "" })
        }
        setBus(e.detail.value!)
    }
    const trainChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, train: "" })
        }
        setTrain(e.detail.value!)
    }
    const flightChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, flight: "" })
        }
        setFlight(e.detail.value!)
    }
    const mrngChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, mrng: "" })
        }
        setMrgDharsan(e.detail.value!)
    }
    const evngChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, evng: "" })
        }
        seteveDharsan(e.detail.value!)
    }
    const timeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, time: "" })
        }
        setTimereq(e.detail.value!)
    }
    const ctimeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, ctime: "" })
        }
        setCloseTime(e.detail.value!)
    }
    const feeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, fee: "" })
        }
        setFee(e.detail.value!)
    }
    const mChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, dressM: "" })
        }
        setDressM(e.detail.value!)
    }
    const wChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, dressW: "" })
        }
        setDressW(e.detail.value!)
    }
    const onsubmit = () => {
        const errors: any = {};
        setVideolink([])
        console.log(offerSIn, "iiii")
        for (let i = 0; i < offerSIn.length; i++) {
            videolink.push(offerSIn[i]);
            if (offerSIn[i].videoLink != "" && offerSIn[i].videoLink != null && offerSIn[i].videoLink != undefined) {
                if (!videoLinkRegex.test(offerSIn[i].videoLink)) {
                    errors.videoLink = 'Please enter valid video link'
                }
            }

        }
        for (let i = 0; i < offerSInn.length; i++) {
            videolink.push(offerSInn[i])
            if (offerSInn[i].videoLink != "" && offerSInn[i].videoLink != null && offerSInn[i].videoLink != undefined) {
                if (!videoLinkRegex.test(offerSInn[i].videoLink)) {
                    errors.videoLinkLive = 'Please enter valid live video link'
                }
            }
        }


        var shop;
        for (let i = 0; i < shopIn.length; i++) {
            if (shopIn[i].value == "") {
                shop = ""
            }
        }
        var acc;
        for (let i = 0; i < AccIn.length; i++) {
            if (AccIn[i].value == "") {
                acc = ""
            }
        }
        var poojaname;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].name == "") {
                poojaname = ""
                if(poojarow[i].price != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].description != ""){
                    setFormErrors({ ...formErrors, poojaname: "Please enter name for pooja list" })
                    alert("Please enter name for pooja list")
                    return;
                }
            }
        }
        var poojaprice;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].price == "") {
                poojaprice = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].description != ""){
                    setFormErrors({ ...formErrors, poojaprice: "Please enter price for pooja list" })
                    alert("Please enter price for pooja list")
                    return;
                }
            }
        }
        var poojades;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].description == "") {
                poojades = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, poojades: "Please enter description for pooja list" })
                    alert("Please enter description for pooja list")
                    return;
                }
            }
        }
        var poojatime;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].offeringTime == "") {
                poojatime = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].description != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, poojatime: "Please enter time for pooja list" })
                    alert("Please enter time for pooja list")
                    return;
                }
            }
        }
        var poojadeity;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].deity.id == "") {
                poojadeity = ""
                if(poojarow[i].name != "" || poojarow[i].offeringTime != "" || poojarow[i].description != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, poojadeity: "Please enter the pooja deity" })
                    alert("Please choose deity for pooja list")
                    return;
                }
            }
        }

        facilitiesDto = {
            "parkingLot": parkingname,
            "security": security,
            "restRooms": restrooms,
            "cloakCounters": cloak,
            "poojaProvisions": provisions,
            "cabAvailablity": cab,
            "shops": shops,
            "auditorium": auditorium,
            "car": car,
            "bus": bus,
            "train": train,
            "flight": flight,
            "name": temple
        }
        templesDto = {
            name: temple,
            scheduleAndTimings: Timings,
            aerialView: aerial,
            villageorTown: village,
            district: district,
            state: state,
            country: country,
            aboutAndHistory: history1,
            tempLat: lat,
            tempLong: long,
            shippingAddress: shipAddress + "," + pincode
        }
        console.log(videolink)
        var poojaList = [];
        const allEmpty = poojarow.every((obj: any) => {
            const isEmpty = Object.values(obj).every(value => {
                if (typeof value === 'object' && value !== null) {
                    return Object.values(value).every(innerValue => innerValue === '');
                }
                return value === '' || value === null || value === undefined;
            });

            return isEmpty;
        });
        const atLeastOneNotEmpty = poojarow.some((obj: any) => {
            const atLeastOneNotEmpty = Object.values(obj).some(value => {
                if (typeof value === 'object' && value !== null) {
                    return Object.values(value).every(innerValue => innerValue !== '');
                }
                return value !== '' || value !== null || value !== undefined;
            });

            return atLeastOneNotEmpty;
        });
        const allNotEmpty = poojarow.every((obj: any) => Object.values(obj).every(value => value !== ''));
        if (history1 == "") {
            errors.abtAndHis = "Please enter the about and history"
        }
        if (temple == "") {
            errors.templeName = "Please enter the temple name"
        }
        if (village == "") {
            errors.village = "Please enter the village"
        }
        if (country == "") {
            errors.country = "Please enter the country"
        }
        if (state == "") {
            errors.state = "Please select a state"
        }
        if (district == "") {
            errors.district = "Please select a district"
        }
        if (shipAddress == "") {
            errors.shipAddress = "Please enter the shipping address"
        }
        if (pincode == "") {
            errors.pincode = "Please enter the pincode"
        }
        if (pincode.length !== 6) {
            errors.pincode = "Please enter valid pincode"
        }
        if (allEmpty) {
            poojaList = [];
        }
        else if (allNotEmpty) {
            poojaList = poojarow;
        }
        else if (atLeastOneNotEmpty) {
            if (poojaname == "") {
                errors.pooja = 'Please enter the pooja name'
            }
            else if (poojaprice == "") {
                errors.pooja = 'Please enter the pooja price'
            }
            else if (poojades == "") {
                errors.pooja = 'Please enter the pooja description'
            }
            else if (poojatime == "") {
                errors.pooja = 'Please enter the pooja timing'
            }
            else if (poojadeity == "") {
                errors.pooja = 'Please select the pooja deity'
            }
        }
        console.log(allEmpty, "all empty", atLeastOneNotEmpty, "any one empty", allNotEmpty, "all not empty", poojaList, poojarow)
        if (aerial === "") {
            errors.aerial = 'Please Enter the temple address map link'
        }
        else if ((!googleMapsLinkRegex.test(aerial)) && (!googleMapsLinkRegex1.test(aerial))) {
            setFormErrors({ ...formErrors, aerial: 'Please Enter a valid map link starts with  www.google.com/maps  or www.google.co.in/maps' })
        }
        if (mrgDharsan == "") {
            errors.mrng = 'Please enter the morning darshan'
        }
        if (eveDharsan == "") {
            errors.evng = 'Please enter the evening darshan'
        }
        if (timereq == "") {
            errors.time = 'Please enter the darshan time'
        }
        if (closetime == "") {
            errors.ctime = 'Please enter the darshan closing time'
        }
        if ((fee == "") || (fee == undefined) || (fee == null)) {
            errors.fee = 'Please Select the fee details'
        }
        if (dressM == "") {
            errors.dressM = 'Please enter the dress code for men'
        }
        if (dressW == "") {
            errors.dressW = 'Please enter the dress code for women'
        }

        setFormErrors(errors)
        if (Object.keys(errors).length === 0) {
            actionFlag.current = true;
            UserService.createTemple(usertoken, facilitiesDto, Offerings, festname, templesDto, images, poojaList, videolink, lat, long, getState.tempId.current).then((response) => {
                console.log(facilitiesDto, "shopss", Offerings, "off", festname,)
                alert({
                    header: "Atmagram",
                    message: response.data,
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "ok",
                            handler: () => {
                                window.location.reload()
                            }
                        }
                    ]
                })
                setShowLoading(false);
                console.log(facilitiesDto, Offerings, festname, templesDto, images, poojarow, videolink, lat, long)

            })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            alert("Please enter all mandatory fields")
        }
    }

    const onCancel = () => {
        actionFlag.current = true;
        if (tempId != "" && tempId != undefined) {
            // setIndex1(1)
            history.push({
                pathname: "/admtemple",
                state: { tempId: tempId, admin: adminBack, id: idBack, name: nameBack, state: propState, city: propCity, district: propDistrict, templeName: tempName }
            })
            setModalopen(false)
        }
        else {
            //window.location.reload()
            history.push("/tempadmin")
        }
    }

    const handlechange = (e: any) => {
        if (e.target.value !== "") {
            setAerial(e.target.value)
            console.log(e.target.value)
            console.log(templesDto)

            console.log(templesDto)
            const regex = /@(\d+(\.\d+)?),(\d+(\.\d+)?),/;
            const match = e.target.value.match(regex);
            var latitude = match ? parseFloat(match[1]) : 0;
            var longitude = match ? parseFloat(match[3]) : 0;
            console.log(latitude, longitude, "latlng")
            const urlParams = new URLSearchParams(e.target.value.split("?")[1]);
            const zoomLevel = urlParams.get("z");
            setZoomLevel(zoomLevel);

            setLat(latitude);
            setLong(longitude);
            setFormErrors({ ...formErrors, aerial: "" })
            // setHI(e.target.value)
        }
        else {
            setAerial("")
        }
    }

    const addInput = (e: any) => {
        // console.log(shopIn);
        // console.log(AccIn);

        if (e === 1) {
            console.log(shopIn);
            var valSet = isFoundshop('');
            if (!valSet) {
                setShopIn([...shopIn, { type: "text", id: 1, value: "" }])
                setTimeout(() => {
                    shopInputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }
        }
        else {
            console.log(AccIn)
            var valSet = isFoundAcc('');
            if (!valSet) {
                setAccIn([...AccIn, { type: "text", id: 1, value: "" }])
                setTimeout(() => {
                    accessibilityInputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }

        }
    };

    const isFoundshop = (val: any) => {
        return shopIn.some((item: any) => val === item.value);
    }
    const isFoundAcc = (val: any) => {
        return AccIn.some((item: any) => (val === item.value));
    }
    const mapOptions = {
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
        minZoom: 4
    };

    const handleChangeShop = (e: any, i: any) => {
        e.preventDefault();
        shopIn[i].value = e.target.value;
        var shops: any = []
        for (let i = 0; i < shopIn.length; i++) {
            console.log(shopIn[i].value)
            shops.push(shopIn[i].value)
        }
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, shops: "" })
        }
        setShops(shops)
    };
    const removeInputFields = (index: any) => {
        const rows = [...poojarow];
        if (rows.length === 1) {
            const rowsInput = { "name": "", "description": "", "price": "", 'offeringTime': '', "deity": { "id": "" } }
            setPoojarow([rowsInput]);
        }
        else if (rows.length >= 1) {
            rows.splice(index, 1);
            setPoojarow(rows);
        }
    }
    const removeInputFields1 = (index: any, item: any) => {
        console.log(item, "lmm")
        if (item.value != "" || index != 0) {
            alert({
                header: 'Atmagram',
                message: 'Do you want to Delete this Field ?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                    },
                    {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                            const rows = [...shops];
                            const row = [...shopIn]
                            if (rows.length >= 1) {
                                rows.splice(index, 1);
                            }
                            if (row.length >= 1) {
                                row.splice(index, 1);
                            }
                            setShops(rows);
                            setShopIn(row)
                        },
                    },
                ]
            })
        }
        else {
            alert({
                header: 'Atmagram',
                message: 'No Values to Delete !',
                buttons: ["Ok"]
            })
        }
    }
    const removeInputFields2 = (index: any) => {
        const rows = [...AccIn];
        const row = [...Accessibility]
        if (rows.length >= 1) {
            rows.splice(index, 1);
        }
        if (row.length >= 1) {
            row.splice(index, 1);
        }
        setAccIn(rows);
        setAccessibility(row)
    }
    const removeInputFields3 = (index: any, item: any) => {
        if (item.offeringName != "" && item.significance != "" || index != 0) {
            alert({
                header: 'Atmagram',
                message: 'Do you want to Delete this Field ?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                    },
                    {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                            const rows = [...Offerings];
                            if (rows.length >= 1) {
                                rows.splice(index, 1);
                            }
                            setOfferings(rows);
                        },
                    },
                ]
            })
        }
        else {
            alert({
                header: 'Atmagram',
                message: 'No Values to Delete !',
                buttons: ["Ok"]
            })
        }
    }
    const removeInputFields4 = (index: any, item: any) => {
        if (item.mame != "" && item.description != "" || index != 0) {
            alert({
                header: 'Atmagram',
                message: 'Do you want to Delete this Field ?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                    },
                    {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                            const rows = [...festname];
                            if (rows.length >= 1) {
                                rows.splice(index, 1);
                            }
                            setFastname(rows);
                        },
                    },
                ]
            })
        }
        else {
            alert({
                header: 'Atmagram',
                message: 'No Values to Delete !',
                buttons: ["Ok"]
            })
        }
    }
    const removeInputFields5 = (index: any, item: any) => {
        if (item.videoLink != "" && item.videoName != "" || index != 0) {
            alert({
                header: 'Atmagram',
                message: 'Do you want to Delete this Field ?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                    },
                    {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                            console.log(offerSIn, "hnh")
                            console.log(videolink, "mkkk")
                            const rows = [...offerSIn];
                            const row = [...videolink]
                            if (rows.length >= 1) {
                                rows.splice(index, 1);
                            }
                            if (row.length >= 1) {
                                row.splice(index, 1);
                            }
                            setofferSIn(rows);
                            setVideolink(row)
                        },
                    },
                ]
            })
        }
        else {
            alert({
                header: 'Atmagram',
                message: 'No Values to Delete !',
                buttons: ["Ok"]
            })
        }
    }
    const removeInputFields6 = (index: any, item: any) => {
        if (item.videoLink != "" && item.videoName != "" || index != 0) {
            alert({
                header: 'Atmagram',
                message: 'Do you want to Delete this Field ?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                    },
                    {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                            const rows = [...offerSInn];
                            const row = [...videolink]
                            if (rows.length >= 1) {
                                rows.splice(index, 1);
                            }
                            if (row.length >= 1) {
                                row.splice(index, 1);
                            }
                            setofferSInn(rows);
                            setVideolink(row)
                        },
                    },
                ]
            })
        }
        else {
            alert({
                header: 'Atmagram',
                message: 'No Values to Delete !',
                buttons: ["Ok"]
            })
        }
    }
    const handleChangeAccess = (e: any, i: any) => {
        e.preventDefault();
        AccIn[i].value = e.target.value;
        var access: any = []
        for (let i = 0; i < AccIn.length; i++) {
            console.log(AccIn[i].value)
            access.push(AccIn[i].value)

        }
        setAccessibility(access)

    };


    const [index, setIndex]: any = useState(0);
    // const content = [screens[index]];
    const [btn, setBtn] = useState(true);
    const getBtnId = (e: any) => {
        console.log(e.target.id)
        setIndex(e.target.id)
    };


    const DeleteImage = (item: any, i: any) => {
        alert({
            header: 'Atmagram',
            message: 'Do you want to Delete this Image ?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                },
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: () => {
                        let filteredArr = image.filter((el: any) => el !== item);
                        var index = image.indexOf(item)
                        if (index > -1) {
                            var img = images.splice(index, 1);
                            console.log(img, "km")
                            setImages(images);
                        }
                        setImage(filteredArr);
                        console.log(images)
                    },
                },
            ]
        })
        // console.log(image.indexOf(item))
        // var indexx = image.indexOf(item)
        // if (indexx > -1) {
        // var img = image.splice(index, 1);
        // console.log(img, "km")
        // }
        // console.log(item,"km")
        // image.slice(item)
    }

    const AddBack = () => {
        actionFlag.current = true;
        if (tempId != "") {
            setIndex1(1)
        }
        else {
            setModalopen(true)
            // window.location.reload()
        }
    }

    const handleStateChange = (e: any) => {
        setStateOption(e)
        setDistrict("")
        setDistrictList([])
        if (e == null) {
            setState("")
        } else {
            setState(e.value)
            setFormErrors({ ...formErrors, state: "" })
        }
        getDistricts(e.value)
    }

    const getDistricts = (e: any) => {
        setShowLoading(true);
        setDistrictOption(null)
        setDistrict("")
        setDistrictList([])
        Authservice.getAllDistricts([e])
            .then((response) => {
                console.log(response)
                setDistrictList(response.data)
                setShowLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setShowLoading(false)
            })
    }

    const stateLiistOptions = stateList.map((e: any, index: any) => ({
        value: e,
        label: e
    }));

    const districtListOptions = districtList.map((e: any, index: any) => ({
        value: e,
        label: e
    }));

    const handleDistrictChange = (e: any) => {
        setDistrictOption(e)
        if (e === null) {
            setDistrict("")
        } else {
            setDistrict(e.value)
            setFormErrors({ ...formErrors, district: "" })
        }
    }

    const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const preventdata = (e: any) => {

        e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })

        e.target.addEventListener('keydown', function (u: any) {

            if (u.which === 38 || u.which === 40) {

                u.preventDefault();

            }

        })

    }
    return (
        <IonPage>
            <IonAlert
                isOpen={showAlert}
                backdropDismiss={false}
                onDidDismiss={() => setShowAlert(false)}
                header="Confirmation"

                message="Are you sure, want to quit? entered details will not be saved."
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',

                        handler: () => {
                            actionFlag.current = true;
                            setShowAlert(false); // Update the flag to indicate that the action was clicked
                        },
                    },
                    {
                        text: 'Yes',
                        handler: handleLeave,
                    },
                ]}
            />
            <AdminHead User={"user"} Role={"Role"} Name={"Add Temple"} />
            <Sidebar />
            <IonContent>
                {index1 === 0 ?
                    <div className="container_middles_layout page-wrapper admin_background_color temple_padding">
                        <div className="menu_pop temple_menu_align">
                            <IonIcon
                                className="menu_pop"
                                src={ellipsisVerticalCircleOutline}
                                size="large"
                                style={{ cursor: "pointer", textAlign: "right" }}
                                color="primary"
                                id="menu_content"
                            ></IonIcon>
                            <IonPopover
                                trigger="menu_content"
                                dismissOnSelect={true}
                                show-backdrop={false}
                            >
                                <IonContent style={{ background: "white" }} scrollEvents={true}>
                                    <IonList className='templeTabBtnMainCls'>
                                        <div className={index == 0 ? "selected-btn" : "menu-btn"} id="0" onClick={getBtnId}>
                                            <IonLabel id="0" onClick={getBtnId} >
                                                About
                                            </IonLabel>
                                        </div>
                                        <div className={index == 1 ? "selected-btn" : "menu-btn"} id="1" onClick={getBtnId}>
                                            <IonLabel id="1" onClick={getBtnId}>
                                                Facilities
                                            </IonLabel>
                                        </div>
                                        <div className={index == 2 ? "selected-btn" : "menu-btn"} id="2" onClick={getBtnId}>
                                            <IonLabel id="2" onClick={getBtnId}>
                                                Offerings & Significance
                                            </IonLabel>
                                        </div>
                                        <div className={index == 3 ? "selected-btn" : "menu-btn"} id="3" onClick={getBtnId}>
                                            <IonLabel id="3" onClick={getBtnId}>
                                                Festivals
                                            </IonLabel>
                                        </div>
                                        <div className={index == 4 ? "selected-btn" : "menu-btn"} id="4" onClick={getBtnId}>
                                            <IonLabel id="4" onClick={getBtnId}>
                                                Pooja List
                                            </IonLabel>
                                        </div>
                                        <div className={index == 6 ? "selected-btn" : "menu-btn"} id="6" onClick={getBtnId} >
                                            <IonLabel id="6" onClick={getBtnId}>
                                                Schedule & Timings
                                            </IonLabel>
                                        </div>
                                        <div className={index == 7 ? "selected-btn" : "menu-btn"} id="7" onClick={getBtnId} style={{ borderBottom: "none" }}>
                                            <IonLabel id="7" onClick={getBtnId}>
                                                Gallery
                                            </IonLabel>
                                        </div>
                                    </IonList>
                                </IonContent>
                            </IonPopover>
                        </div>
                        <IonRow className="temple_menu_container">
                            <div>
                                <IonText tabIndex={-1} id="0" onClick={getBtnId} className={index == 0 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    About & History
                                </IonText>
                            </div>
                            <div>
                                <IonText id="1" onClick={getBtnId} className={index == 1 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Facilities
                                </IonText>
                            </div>
                            <div>
                                <IonText id="2" onClick={getBtnId} className={index == 2 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Offerings & Significance
                                </IonText>
                            </div>
                            <div>
                                <IonText id="3" onClick={getBtnId} className={index == 3 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Festivals
                                </IonText>
                            </div>
                            <div>
                                <IonText id="4" onClick={getBtnId} className={index == 4 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Pooja list
                                </IonText>
                            </div>
                            <div>
                                <IonText id="5" onClick={getBtnId} className={index == 5 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Aerial View
                                </IonText>
                            </div>
                            <div>
                                <IonText id="6" onClick={getBtnId} className={index == 6 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Schedule & Timings
                                </IonText>
                            </div>
                            <div>
                                <IonText id="7" onClick={getBtnId} className={index == 7 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Gallery
                                </IonText>
                            </div>
                        </IonRow><div style={{ minHeight: "100% !important" }}>
                            <IonGrid className="menu_temple_details_container">
                                {index == 0 ?
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonRow>
                                                <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            About & history <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonTextarea placeholder="Enter About & history" className="temple_textarea_input" autoGrow value={history1} onBlur={() => validation(1)}
                                                        onIonChange={(e) => historyChange(e)} />
                                                    {formErrors.abtAndHis && <span className="alert_input_bottom ">{formErrors.abtAndHis}</span>}
                                                </IonCol>
                                                <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                    <IonRow style={{ marginTop: "4%" }}>
                                                        <IonLabel className="temple_details_label">
                                                            Country <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonSelect className="temple_inputs" value={country} onBlur={() => validation(4)} onIonChange={(e) => countryChange(e)}>
                                                        <IonSelectOption value={'India'}>India</IonSelectOption>
                                                    </IonSelect>
                                                    {formErrors.country && <span className="alert_input_bottom ">{formErrors.country}</span>}
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            State <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <Select
                                                        options={stateLiistOptions}
                                                        value={stateOption}
                                                        onChange={(e: any) => handleStateChange(e)}
                                                        placeholder="Select a State"
                                                        isClearable
                                                        onBlur={() => validation(5)}
                                                        styles={customStyles}
                                                        className="custom-select state_dropdown"
                                                    />
                                                    {formErrors.state && <span className="alert_input_bottom ">{formErrors.state}</span>}
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            District <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <Select
                                                        options={districtListOptions}
                                                        value={districtOption}
                                                        onChange={(e: any) => handleDistrictChange(e)}
                                                        placeholder="Select a District"
                                                        isClearable
                                                        onBlur={() => validation(6)}
                                                        styles={customStyles}
                                                        className="custom-select"
                                                    />
                                                    {formErrors.district && <span className="alert_input_bottom ">{formErrors.district}</span>}
                                                </IonCol>
                                                <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            Shipping Address <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonTextarea placeholder="Enter Shipping Address" className="temple_textarea_input" autoGrow value={shipAddress} onBlur={() => validation(7)}
                                                        onIonChange={(e) => shipAddressChange(e)} />
                                                    {formErrors.shipAddress && <span className="alert_input_bottom ">{formErrors.shipAddress}</span>}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            Temple Name <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonInput placeholder="Enter Temple Name" className="temple_inputs" value={temple}
                                                        onBlur={() => validation(2)} onIonChange={(e) => templeChange(e)} />
                                                    {formErrors.templeName && <span className="alert_input_bottom ">{formErrors.templeName}</span>}
                                                </IonCol>
                                                <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            Village Or Town <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonInput placeholder="Enter Village Name" className="temple_inputs" onBlur={() => validation(3)} value={village}
                                                        onIonChange={(e) => villageChange(e)} />
                                                    {formErrors.village && <span className="alert_input_bottom ">{formErrors.village}</span>}
                                                </IonCol>
                                                <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            Pincode <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonInput placeholder="Enter Pincode" className="temple_inputs" type="number" onKeyDown={(evt) => {
                                                        if (evt.key === 'e' || evt.key === 'E' || evt.key === '.') {
                                                            evt.preventDefault();
                                                        }
                                                    }}
                                                        onBlur={() => validation(8)} value={pincode} onIonChange={(e) => pincodeChange(e)} />
                                                    {formErrors.pincode && <span className="alert_input_bottom ">{formErrors.pincode}</span>}
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow> : index == 1 ?
                                        <IonRow>
                                            <IonCol size="12">
                                                <IonRow>
                                                    <IonLabel className="temple_facilites_head_text">
                                                        Accessibility
                                                    </IonLabel>
                                                </IonRow>
                                                <IonRow style={{ marginTop: "1%" }}>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Car
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect placeholder="Select Car" interface="popover" className="temple_inputs" value={car} onIonChange={(e) => carChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Bus
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect placeholder="Select Bus" interface="popover" className="temple_inputs" value={bus} onIonChange={(e) => busChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Train
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect placeholder="Select Train" interface="popover" className="temple_inputs" value={train} onIonChange={(e) => trainChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Flight
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect placeholder="Select Flight" interface="popover" className="temple_inputs" value={flight} onIonChange={(e) => flightChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow style={{ marginTop: "3%" }}>
                                                    <IonLabel className="temple_facilites_head_text">
                                                        Facilities
                                                    </IonLabel>
                                                </IonRow>
                                                <IonRow style={{ marginTop: "1%" }}>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Parking Lot
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonInput min={0} onFocus={preventdata} placeholder="Enter Parking lot" className="temple_inputs" type="number" value={parkingname} onIonChange={(e) => parkingChange(e)} />
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Security
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" placeholder="Select Security" value={security} interface="popover" onIonChange={(e) => securityChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Rest Rooms
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" interface="popover" placeholder="Select Rest Rooms" value={restrooms} onIonChange={(e) => restroomChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Auditorium
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonInput placeholder="Enter Auditorium" className="temple_inputs" value={auditorium} onIonChange={(e) => audChange(e)} />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow style={{ marginTop: "1%" }}>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Cloak Counters
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" interface="popover" placeholder="Select Cloak Counters" value={cloak} onIonChange={(e) => cloakChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Pooja Provisions
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" interface="popover" placeholder="Select Pooja Provisions" value={provisions} onIonChange={(e) => provisionChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Cab / Auto Availability
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" interface="popover" placeholder="Select Cab / Auto Availability" value={cab} onIonChange={(e) => cabChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow style={{ paddingLeft: "5px" }}>
                                                    <IonCol style={{ display: "flex" }} size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonLabel className="temple_details_label">
                                                            Shops
                                                        </IonLabel>
                                                        <div style={{ display: 'flex', alignItems: "center", paddingLeft: "3px" }}>
                                                            <IonIcon src={addCircleOutline} className="shop_add_icon" onClick={() => addInput(1)} />
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    {shopIn.map((item: any, i: any) => {
                                                        return (
                                                            <IonCol style={{ display: "flex" }} size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                <IonInput
                                                                    onIonChange={(e) => handleChangeShop(e, i)}
                                                                    value={item.value}
                                                                    id={i}
                                                                    type={item.type}
                                                                    ref={(ref) => shopInputRef.current = ref} autofocus={true}
                                                                    className="temple_inputs"
                                                                />
                                                                <div style={{ display: 'flex', alignItems: "center", paddingLeft: "3px" }}>
                                                                    <IonIcon src={removeCircleOutline} onClick={() => removeInputFields1(i, item)} className="shop_add_icon" />
                                                                </div>
                                                                {/* {shopIn.length <= 1 ? <></>
                                                                    : <IonIcon size="small" src={removeCircle} onClick={() => removeInputFields1(i)} style={{ cursor: "pointer", color: "#576F9F", padding: "10px 5px" }} />} */}
                                                            </IonCol>
                                                        );
                                                    })}
                                                </IonRow>
                                            </IonCol>
                                        </IonRow> : index == 2 ?
                                            <IonRow>
                                                <IonCol size="12">
                                                    <IonRow>
                                                        <IonCol size-lg="4" size-md="4" size-sm="12" size-xs="12">
                                                            <IonLabel className="temple_details_label">
                                                                Add Offering Name
                                                            </IonLabel>
                                                        </IonCol>
                                                        <IonCol size-lg="8" size-md="8" size-sm="12" size-xs="12">
                                                            <IonRow>
                                                                <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11">
                                                                    <IonLabel className="temple_details_label">
                                                                        Add Offering Significance
                                                                    </IonLabel>
                                                                </IonCol>
                                                                <IonCol size="1">
                                                                    <IonIcon
                                                                        src={addCircle}
                                                                        className="off_add_icon"
                                                                        onClick={() => addInputOfferings(1)}
                                                                    />
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                    </IonRow>
                                                    {Offerings.map((item: any, i: any) => {
                                                        return (
                                                            <div>
                                                                <IonRow>
                                                                    <IonCol size-lg="4" size-md="4" size-sm="11" size-xs="11">
                                                                        <IonTextarea
                                                                            onIonChange={(e) => offerhandleChange(e, i)}
                                                                            value={item.offeringName}
                                                                            className="temple_offerings_textarea_input"
                                                                            autoGrow
                                                                            placeholder="Add Offering Name"
                                                                            ref={(ref) => inputRef.current = ref} autofocus={true}
                                                                        />
                                                                    </IonCol>
                                                                    <IonCol size-lg="8" size-md="8" size-sm="12" size-xs="12" style={{ padding: "0px" }}>
                                                                        <IonRow>
                                                                            <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11">
                                                                                <IonTextarea
                                                                                    onIonChange={(e) => offerhandleChangeSIn(e, i)}
                                                                                    autoGrow
                                                                                    placeholder="Add Offering Significance"
                                                                                    value={item.significance}
                                                                                    className="temple_offerings_textarea_input"
                                                                                />
                                                                            </IonCol>
                                                                            <IonCol size="1">
                                                                                <IonIcon src={removeCircle} onClick={() => removeInputFields3(i, item)} className="off_add_icon" />
                                                                            </IonCol>
                                                                        </IonRow>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </div>

                                                        );
                                                    })}
                                                </IonCol>
                                            </IonRow> : index == 3 ?
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <IonRow>
                                                            <IonCol size-lg="4" size-md="4" size-sm="12" size-xs="12">
                                                                <IonLabel className="temple_details_label">
                                                                    Add Festival Name
                                                                </IonLabel>
                                                            </IonCol>
                                                            <IonCol size-lg="8" size-md="8" size-sm="12" size-xs="12">
                                                                <IonRow>
                                                                    <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11">
                                                                        <IonLabel className="temple_details_label">
                                                                            Add Festival Significance
                                                                        </IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="1">
                                                                        <IonIcon
                                                                            src={addCircle}
                                                                            className="off_add_icon"
                                                                            onClick={() => addInputFest(0)}
                                                                        />
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonCol>
                                                        </IonRow>
                                                        {festname.map((item: any, i: any) => {
                                                            return (
                                                                <div>
                                                                    <IonRow>
                                                                        <IonCol size-lg="4" size-md="4" size-sm="11" size-xs="11">
                                                                            <IonTextarea
                                                                                onIonChange={(e) => handleChangeName(e, i)}
                                                                                value={item.name}
                                                                                className="temple_offerings_textarea_input"
                                                                                autoGrow
                                                                                placeholder="Add Festival Name"
                                                                                ref={(ref) => inputRef1.current = ref} autofocus={true}
                                                                            />
                                                                        </IonCol>
                                                                        <IonCol size-lg="8" size-md="8" size-sm="12" size-xs="12" style={{ padding: "0px" }}>
                                                                            <IonRow>
                                                                                <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11">
                                                                                    <IonTextarea
                                                                                        onIonChange={(e) => handleChangeSign(e, i)}
                                                                                        value={item.description}
                                                                                        placeholder="Add Festival Significance"
                                                                                        className="temple_offerings_textarea_input"
                                                                                        autoGrow
                                                                                    />
                                                                                </IonCol>
                                                                                <IonCol size="1">
                                                                                    <IonIcon src={removeCircle} onClick={() => removeInputFields4(i, item)} className="off_add_icon" />
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </div>
                                                            );
                                                        })}
                                                    </IonCol>
                                                </IonRow>
                                                : index == 4 ?
                                                    <div style={{ overflowY: "auto", height: "420px" }}>
                                                        {screenwidth <= 1000 ? <></> : <IonRow style={{ backgroundColor: "transparent" }}>
                                                            <IonCol size-xl="2.4">
                                                                <IonText className="temple_poojalist_head">Pooja Name</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="2">
                                                                <IonText className="temple_poojalist_head_highlight">Pooja Price</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="2.4">
                                                                <IonText className="temple_poojalist_head">Pooja Description</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="2.4">
                                                                <IonText className="temple_poojalist_head_highlight">Pooja Timings</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="2.4">
                                                                <IonText className="temple_poojalist_head">Deity</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="0.4">
                                                                <IonIcon
                                                                    src={addCircle}
                                                                    className="pooja_list_add_icon" onClick={() => addrow(0)}
                                                                />
                                                            </IonCol>
                                                        </IonRow>}
                                                        {poojarow.map((data: any, index: any) => {
                                                            return (
                                                                <IonRow className="poojalist_input_container" key={index}>
                                                                    <IonCol size-xl="2.4" size-lg="2.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonInput type="text" placeholder="Pooja Name" value={data.name} onBlur={() => validation(21)} onIonChange={(evnt) => (handleChangerowPname(index, evnt))} name="poojaName" className="temple_poojalist_inputs"
                                                                            ref={(ref) => poojaListInputRef.current = ref} autofocus={true} />
                                                                    </IonCol>
                                                                    <IonCol size-xl="2" size-lg="2" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonInput type="number" onFocus={preventdata} placeholder="Price" value={data.price} min={0}
                                                                            onBlur={() => validation(22)} onKeyDown={(e: any) => restrictE(e)}
                                                                            onIonChange={(evnt) => (handleChangerowPprice(index, evnt))} name="poojaPrice" className="temple_poojalist_inputs" />
                                                                    </IonCol>
                                                                    <IonCol size-xl="2.4" size-lg="2.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonInput type="text" placeholder="Description" value={data.description} onBlur={() => validation(23)}
                                                                            onIonChange={(evnt) => (handleChangerowDesc(index, evnt))} name="poojaPrice" className="temple_poojalist_inputs" />
                                                                    </IonCol>
                                                                    <IonCol size-xl="2.4" size-lg="2.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonInput type="text" placeholder="Timings" value={data.offeringTime} onBlur={() => validation(24)}
                                                                            onIonChange={(evnt) => (handleChangerowPtiming(index, evnt))} name="poojaTiming" className="temple_poojalist_inputs" />
                                                                    </IonCol>
                                                                    <IonCol size-xl="2.4" size-lg="2.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonSelect interface="popover" placeholder="Select Deity" value={data.deity.id} name="poojaPrice" className="temple_poojalist_dropDown"
                                                                            onBlur={() => validation(25)} onIonChange={(e) => (handleChangerowGod(index, e))}>
                                                                            {deity.map((state: any) => (
                                                                                <IonSelectOption key={state.id} value={state.id}>
                                                                                    {state.deityName}
                                                                                </IonSelectOption>
                                                                            ))}
                                                                        </IonSelect>
                                                                    </IonCol>
                                                                    <IonCol style={{ textAlign: "right" }} size-xl="0.4" size-lg="0.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonIcon src={removeCircle} onClick={() => removeInputFields(index)} className="pooja_list_add_icon" />
                                                                    </IonCol>
                                                                </IonRow>
                                                            )
                                                        })}
                                                        <div style={{ display: "flex", padding: "10px", alignItems: "center" }}>
                                                            <div style={{ justifyContent: "flex-start", width: "90%" }}>
                                                                {formErrors.pooja && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.pooja}</span>}
                                                            </div>
                                                            {/* <div style={{ justifyContent: "end" }}>
                                                                <IonButton className="admin_add_btn" onClick={() => addrow(0)}>
                                                                    <span className="button_padding">
                                                                        Add
                                                                    </span>
                                                                </IonButton>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    : index == 5 ?
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <IonRow>
                                                                    <IonLabel className="temple_details_label">
                                                                        Please Enter Temple Address Map Link here <span style={{ color: 'red' }}>*</span>
                                                                    </IonLabel>
                                                                </IonRow>
                                                                <IonRow>
                                                                    <IonCol size="12">
                                                                        <IonTextarea onIonChange={(e) => handlechange(e)} placeholder="Please enter temple address map link here"
                                                                            value={aerial} onBlur={() => validation(33)} autoGrow className="temple_map_textarea_input" />
                                                                        {formErrors.aerial && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.aerial}</span>}
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonCol>
                                                            {lat && long ?

                                                                <IonRow style={{ width: "100%" }}>
                                                                    <IonCol className="col1" size-lg="12" size-md="12" size-xs="12" size-sm="12">

                                                                        {window.google == undefined ? <LoadScript
                                                                            googleMapsApiKey={UserService.mapKey()}
                                                                        >
                                                                            <GoogleMap
                                                                                ref={mapRef}
                                                                                mapContainerStyle={containerStyle}
                                                                                options={mapOptions}

                                                                                center={{ lat: lat, lng: long }}
                                                                                zoom={17}
                                                                            >
                                                                                <MarkerF position={{ lat: lat, lng: long }} />
                                                                            </GoogleMap>
                                                                        </LoadScript> : <GoogleMap
                                                                            ref={mapRef}
                                                                            mapContainerStyle={containerStyle}
                                                                            options={mapOptions}
                                                                            center={{ lat: lat, lng: long }}
                                                                            zoom={17}
                                                                        >
                                                                            <MarkerF position={{ lat: lat, lng: long }} />
                                                                        </GoogleMap>}
                                                                    </IonCol>
                                                                    {/* <IonCol className="col2" size-lg="6" size-md="12" size-xs="12" size-sm="12">
                                                                </IonCol> */}
                                                                </IonRow>
                                                                : <></>}
                                                        </IonRow>
                                                        : index == 6 ?
                                                            <IonRow>
                                                                <IonCol size="12">
                                                                    <IonRow>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Morning Darshan <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonInput placeholder="Enter Morning Darshan" onBlur={() => validation(26)} value={mrgDharsan} className="temple_inputs" onIonChange={(e) => mrngChange(e)} />
                                                                            {formErrors.mrng && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.mrng}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Evening Darshan <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonInput placeholder="Enter Evening Darshan" value={eveDharsan} className="temple_inputs" onBlur={() => validation(27)} onIonChange={(e) => evngChange(e)} />
                                                                            {formErrors.evng && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.evng}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Time Required <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonInput placeholder="Enter Time Required" onBlur={() => validation(28)} value={timereq} className="temple_inputs" onIonChange={(e) => timeChange(e)} />
                                                                            {formErrors.time && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.time}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Temple Closing Time <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonInput placeholder="Enter Temple Closing Time" onBlur={() => validation(29)} value={closetime} onIonChange={(e) => ctimeChange(e)} className="temple_inputs" />
                                                                            {formErrors.ctime && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.ctime}</span>}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow style={{ marginTop: "1%" }}>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Fee Details <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonSelect placeholder="Select Fee Details" interface="popover" onBlur={() => validation(30)} onIonChange={(e) => feeChange(e)} value={fee} className="temple_inputs" >
                                                                                <IonSelectOption value="entry fee">Entry Fee</IonSelectOption>
                                                                                <IonSelectOption value="No entry fee">No Entry Fee</IonSelectOption>
                                                                            </IonSelect>
                                                                            {formErrors.fee && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.fee}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="4.5" size-md="4.5" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Dress Code (Men) <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonTextarea autoGrow placeholder="Enter Men Dress Code" className="temple_textarea_dress" onBlur={() => validation(31)} value={dressM} onIonChange={(e) => mChange(e)} />
                                                                            {formErrors.dressM && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.dressM}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="4.5" size-md="4.5" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Dress Code (Women) <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonTextarea autoGrow placeholder="Enter Women Dress Code" className="temple_textarea_dress" onBlur={() => validation(32)} value={dressW} onIonChange={(e) => wChange(e)} />
                                                                            {formErrors.dressW && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.dressW}</span>}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </IonCol>
                                                            </IonRow>
                                                            : index == 7 ?
                                                                <div>
                                                                    <IonRow>
                                                                        <input style={{ display: "none" }} ref={imageinputref} type="file" name="photo" onChange={(e) => onchangeImage(e)} multiple={true} />
                                                                        <IonCol size-lg="2" size-md="4" size-sm="4" size-xs="6">
                                                                            <IonButton
                                                                                onClick={handleButtonClick}
                                                                                className="admin_img_btn image_content"
                                                                            ><span>
                                                                                    Choose File
                                                                                </span>

                                                                            </IonButton>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow>
                                                                        <IonCol size="12">
                                                                            <IonRow style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                                                                                <IonText style={{ color: "red", fontSize: "10px" }}>* The first image will be displayed as Temple Image</IonText>
                                                                            </IonRow>
                                                                            <IonRow className="ion-justify-content-center">
                                                                                {image.map((item: any, i: any) => {
                                                                                    return (
                                                                                        <IonCol sizeLg="4" size="4">
                                                                                            <div style={{ display: "flex", justifyContent: "right" }}>
                                                                                                <IonIcon src={closeCircle} tabIndex={0} size="small" style={{ cursor: "pointer", color: '#576F9F' }} onClick={() => DeleteImage(item, i)} />
                                                                                            </div>
                                                                                            <IonImg src={item} tabIndex={1} className="img" />
                                                                                        </IonCol>
                                                                                    )
                                                                                })}
                                                                            </IonRow>
                                                                            <IonRow style={{ marginTop: '1%' }}>
                                                                                <IonCol size="11" size-xs="10">
                                                                                    <IonLabel className="temple_facilites_head_text">
                                                                                        Video Link
                                                                                    </IonLabel>
                                                                                </IonCol>
                                                                                <IonCol size="1" size-xs="2" className="temple_video_add_icon">
                                                                                    <IonIcon
                                                                                        src={addCircle}
                                                                                        className="video_add_icon"
                                                                                        onClick={() => addofferInput1(0)}
                                                                                        tabIndex={2}
                                                                                        onKeyDown={(event) => {
                                                                                            if (event.key === "Enter" || event.key === " ") {
                                                                                                addofferInput1(0)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </IonCol>
                                                                            </IonRow>

                                                                            {offerSIn.map((item: any, i: any) => {
                                                                                var flag = true;

                                                                                return (
                                                                                    <div>
                                                                                        <IonRow>
                                                                                            <IonCol size="11" size-xs="10">
                                                                                                <IonRow>
                                                                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                                                        <IonLabel className="temple_details_label">Video Name</IonLabel>
                                                                                                        <IonInput
                                                                                                            value={item.videoName}
                                                                                                            id={i}
                                                                                                            onIonChange={(e) => videoName(e, i)}
                                                                                                            className="temple_inputs"
                                                                                                            ref={(ref) => videoInputRef.current = ref} autofocus={true}
                                                                                                        />
                                                                                                    </IonCol>
                                                                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                                                        <IonLabel className="temple_details_label">Video Link</IonLabel>
                                                                                                        <IonInput
                                                                                                            value={item.videoLink}
                                                                                                            id={i}
                                                                                                            onIonChange={(e) => videoLink(e, i)}
                                                                                                            className="temple_inputs"
                                                                                                        />
                                                                                                    </IonCol>
                                                                                                </IonRow>
                                                                                            </IonCol>
                                                                                            <IonCol size="1" size-xs="2" className="temple_video_add_icon">
                                                                                                <IonIcon src={removeCircle}
                                                                                                    tabIndex={2}
                                                                                                    onKeyDown={(event) => {
                                                                                                        if (event.key === "Enter" || event.key === " ") {
                                                                                                            removeInputFields5(i, item)
                                                                                                        }
                                                                                                    }}
                                                                                                    onClick={() => removeInputFields5(i, item)}
                                                                                                    className="video_add_icon" />
                                                                                            </IonCol>
                                                                                        </IonRow>
                                                                                        {formErrors.videoLink && <span className="alert_input_bottom ">{formErrors.videoLink}</span>}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                            <IonRow style={{ marginTop: '1%' }}>
                                                                                <IonCol size="11" size-xs="10">
                                                                                    <IonLabel className="temple_facilites_head_text">
                                                                                        Live Video Link
                                                                                    </IonLabel>
                                                                                </IonCol>
                                                                                <IonCol>
                                                                                    <IonIcon
                                                                                        src={addCircle}
                                                                                        tabIndex={2}
                                                                                        className="video_add_icon" onClick={() => addofferInput1(1)}
                                                                                        onKeyDown={(event) => {
                                                                                            if (event.key === "Enter" || event.key === " ") {
                                                                                                addofferInput1(1)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            {offerSInn.map((item: any, i: any) => {
                                                                                return (
                                                                                    <div>
                                                                                        <IonRow>
                                                                                            <IonCol size="11" size-xs="10">
                                                                                                <IonRow>
                                                                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                                                        <IonLabel className="temple_details_label">Video Name</IonLabel>
                                                                                                        <IonInput
                                                                                                            value={item.videoName}
                                                                                                            id={i}
                                                                                                            onIonChange={(e) => Livevideoname(e, i)}
                                                                                                            className="temple_inputs"
                                                                                                            ref={(ref) => liveVideoInputRef.current = ref} autofocus={true}
                                                                                                        />
                                                                                                    </IonCol>
                                                                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                                                        <IonLabel className="temple_details_label">Video Link</IonLabel>
                                                                                                        <IonInput
                                                                                                            value={item.videoLink}
                                                                                                            id={i}
                                                                                                            onIonChange={(e) => Livevideolink(e, i)}
                                                                                                            className="temple_inputs"
                                                                                                        />
                                                                                                    </IonCol>
                                                                                                </IonRow>
                                                                                            </IonCol>
                                                                                            <IonCol size="1" size-xs="2" className="temple_video_add_icon">
                                                                                                <IonIcon src={removeCircle}
                                                                                                    onClick={() => removeInputFields6(i, item)}
                                                                                                    tabIndex={2}
                                                                                                    onKeyDown={(event) => {
                                                                                                        if (event.key === "Enter" || event.key === " ") {
                                                                                                            removeInputFields6(i, item)
                                                                                                        }
                                                                                                    }}
                                                                                                    className="video_add_icon" />
                                                                                            </IonCol>
                                                                                        </IonRow>
                                                                                        {formErrors.videoLinkLive && <span className="alert_input_bottom ">{formErrors.videoLinkLive}</span>}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </div>
                                                                : <></>}
                                <IonRow style={{ justifyContent: "flex-end", marginTop: "5%" }}>
                                    <IonButton onClick={onsubmit} className="admin_add_btn">
                                        <span className="button_padding">
                                            Submit
                                        </span>
                                    </IonButton>
                                    <IonButton onClick={() => setModalopen(true)} className="admin_cencel_btn" fill="outline">
                                        <span className="button_padding">
                                            Cancel
                                        </span>
                                    </IonButton>
                                </IonRow>
                            </IonGrid></div>
                    </div> :
                    <div>
                        {/* <Admtemple id={idBack} edit={editBack} admin={adminBack} tempId={tempId} name={nameBack} state={sestate} city={city} district={sedistrict} templeName={tempname} /> */}
                    </div>}
                <Modal show={modalopen} className="logout-modal modal-dialog modal-dialog-centered modal-sm">
                    <Modal.Body>
                        <IonRow>
                            <IonCol style={{ textAlign: "center" }}>
                                <p className="mt-2">Are you sure, want to quit? entered details will not be saved.</p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="mt-12">
                            <IonCol size-lg="6" size-md="6" size="6" style={{ textAlign: "end" }}>
                                <IonButton className="admin_add_btn" onClick={() => onCancel()}>
                                    <span className="button_padding">
                                        Yes
                                    </span>
                                </IonButton>
                            </IonCol>
                            <IonCol size-lg="6" size-md="6" size="6">
                                <IonButton className="admin_add_btn" onClick={(e) => setModalopen(false)}>
                                    <span className="button_padding">
                                        No
                                    </span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </Modal.Body>
                </Modal>
            </IonContent>
        </IonPage>
    )
}
export default Addtemp;
