import {
  IonContent,
  IonSelect,
  IonItem,
  IonSelectOption,
  IonPage,
  IonButton,
  IonItemDivider,
  IonRow,
  IonCol,
  IonInput,
  IonAlert,
  IonLabel,
  useIonRouter,
  IonLoading,
  IonImg,
  useIonAlert,
  IonIcon,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";
import { Capacitor } from "@capacitor/core"
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';


import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Login.css";
import { useHistory } from "react-router";
import validator from 'validator'
import { AppContext } from "../components/AppContext";
import { logo } from "../theme/imagePath";
import { Link } from "react-router-dom";
import HeadFoot from "./Head";
import { home } from "ionicons/icons";

interface State {
  id: number;
  name: string;
}
var load: any = false;
const states: State[] = [
  {
    id: 1,
    name: "Andhra Pradesh",
  },
  {
    id: 2,
    name: "Arunachal Pradesh",
  },
  {
    id: 3,
    name: "Assam",
  },
  {
    id: 4,
    name: "Bihar",
  },
  {
    id: 5,
    name: "Chhattisgarh",
  },
  {
    id: 6,
    name: "Goa",
  },
  {
    id: 7,
    name: "Gujarat",
  },
  {
    id: 8,
    name: "Haryana",
  },
  {
    id: 9,
    name: "Himachal Pradesh",
  },
  {
    id: 10,
    name: "Jharkhand",
  },
  {
    id: 11,
    name: "Karnataka",
  },

  {
    id: 12,
    name: "Kerala",
  },

  {
    id: 13,
    name: "Madhya Pradesh",
  },
  {
    id: 14,
    name: "Maharashtra",
  },

  {
    id: 14,
    name: "Manipur",
  },

  {
    id: 15,
    name: "Meghalaya",
  },

  {
    id: 16,
    name: "Mizoram",
  },

  {
    id: 17,
    name: "Nagaland",
  },

  {
    id: 18,
    name: "Odisha",
  },
  {
    id: 19,
    name: "Punjab",
  },
  {
    id: 20,
    name: "Rajasthan",
  },
  {
    id: 21,
    name: "Sikkim",
  },
  {
    id: 22,
    name: "Tamil Nadu",
  },
  {
    id: 23,
    name: "Telengana",
  },
  {
    id: 24,
    name: "Tripura",
  },
  {
    id: 23,
    name: "Uttarkhand",
  },
  {
    id: 24,
    name: "Uttar Pradesh",
  },
  {
    id: 25,
    name: "West Bengal",
  },
  {
    id: 26,
    name: "Andaman and Nicobar Islands",
  },
  {
    id: 27,
    name: "Chandigarh",
  },
  {
    id: 28,
    name: "The Government of NCT of Delhi",
  },
  {
    id: 29,
    name: "Dadra and Nagar Haveli and Daman & Diu",
  },
  {
    id: 30,
    name: "Ladakh",
  },
  {
    id: 31,
    name: "Jammu & Kashmir",
  },
  {
    id: 32,
    name: "Lakshadweep",
  },

  {
    id: 33,
    name: "Puducherry",
  },
];



const Signup: React.FC = () => {
  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [iserror, setIserror] = useState(false);
  const [issuccess, setIssuccess] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser]: any = useState("");
  const [state, setState] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [product, setproduct] = useState("");
  const [TName, setTName]: any = useState("");
  const [TAdd, setTAdd]: any = useState("");
  const [vendor, setvendor]: any = useState("");
  const [pincode, setPincode]: any = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const widthSize: any = window.innerWidth;
  const [Alert] = useIonAlert()


  const { userdata } = useContext(AppContext)!;

  useEffect(() => {

    if (userdata != null && userdata != undefined && userdata != '') {
      //history.goForward();
      history.replace("/")
    }



  }, []);
  const mailpat = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
  const namecheck = /^[a-zA-Z\s]*$/

  const [formErrors, setFormErrors]: any = useState({
    user: '',
    firstname: '',
    phone: '',
    email: '',
    password: '',
    confirmpassword: '',
    vendor: '',
    product: '',
    TName: '',
    TAdd: '',
  });


  const Validation = (label: any) => {
    if (label === "user") {
      if (user === "") {
        setFormErrors((prev: any) => {
          return { ...prev, user: "Choose a Role" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, user: "" }
        })
      }
    }
    if (label === "firstname") {
      if (firstname == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "" }
        })
      }
    }

    if (label === "phone") {
      if (phone === undefined || phone === "") {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }
    if (label === "email") {
      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: 'Email is required' }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }
    if (label === "password") {
      if (confirmpassword !== "") {
        if (password === confirmpassword) {
          setFormErrors((prev: any) => {
            return { ...prev, confirmpassword: "" }
          })
        }
      }
      else if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }
    if (label === "confirmpassword") {
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }

    if (label === "VName") {
      if (user === "3" && vendor === "") {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "Enter Vendor Name" }
        })
      }
      else if (user === "3" && namecheck.test(vendor) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "Alphabets only Allowed in Vendor Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "" }
        })
      }
    }
    if (label === "product") {
      if (user === "3" && product === "") {
        setFormErrors((prev: any) => {
          return { ...prev, product: "Enter Product Type" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, product: "" }
        })
      }
    }
    if (label === "TName") {
      if (user === "4" && TName === "") {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "Enter Temple Name" }
        })
      }
      else if (user === "4" && namecheck.test(TName) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "Alphabets only Allowed in Temple Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "" }
        })
      }
    }
    if (label === "TAdd") {
      if (user === "4" && TAdd === "") {
        setFormErrors((prev: any) => {
          return { ...prev, TAdd: "Enter Temple Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, TAdd: "" }
        })
      }
    }
  }

  const ChangeValidation = (label: any, e: any) => {
    if (label === "user") {
      const user = e.target.value;
      if (user === "") {
        setFormErrors((prev: any) => {
          return { ...prev, user: "Choose a Role" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, user: "" }
        })
      }
    }
    if (label === "firstname") {
      const firstname = e.target.value;
      if (firstname == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "" }
        })
      }
    }

    if (label === "phone") {
      const phone = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (phone === undefined || phone === "") {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Mobile Number is required" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }
    if (label === "email") {
      const email = e.target.value;

      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: 'Email is required' }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }
    if (label === "password") {
      const password = e.target.value;
      if (confirmpassword !== "") {
        if (password === confirmpassword) {
          setFormErrors((prev: any) => {
            return { ...prev, confirmpassword: "" }
          })
        }
      }
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }
    if (label === "confirmpassword") {
      const confirmpassword = e.target.value;

      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }

    if (label === "VName") {
      const vendor = e.target.value;

      if (user === "3" && vendor === "") {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "Enter Vendor Name" }
        })
      }
      else if (user === "3" && namecheck.test(vendor) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "Alphabets only Allowed in Vendor Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, vendor: "" }
        })
      }
    }
    if (label === "product") {
      const product = e.target.value;

      if (user === "3" && product === "") {
        setFormErrors((prev: any) => {
          return { ...prev, product: "Enter Product Type" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, product: "" }
        })
      }
    }
    if (label === "TName") {
      const TName = e.target.value;

      if (user === "4" && TName === "") {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "Enter Temple Name" }
        })
      }
      else if (user === "4" && namecheck.test(TName) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "Alphabets only Allowed in Temple Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, TName: "" }
        })
      }
    }
    if (label === "TAdd") {
      const TAdd = e.target.value;

      if (user === "4" && TAdd === "") {
        setFormErrors((prev: any) => {
          return { ...prev, TAdd: "Enter Temple Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, TAdd: "" }
        })
      }
    }
  }

  const submit = () => {
    const errors: any = {};

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    if (user === "") {
      errors.user = "Choose a Role"
    }
    if (firstname == "") {
      errors.firstname = "Enter First Name"
    }
    else if (namecheck.test(firstname) == false) {
      errors.firstname = 'Alphabets only Allowed in First Name'
    }
    else if (firstname.charAt(0) == " ") {
      errors.firstname = 'Empty Space at First Name'
    }
    if (phone === undefined || phone === "") {
      errors.phone = "Mobile Number is required"
    } else if (!isValidPhoneNumber) {
      errors.phone = "Enter valid Mobile Number"
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (email.trim() === '') {
      errors.email = 'Email is required';
    }
    else if (validator.isEmail(email) === false) {
      errors.email = "Enter Valid Email Address";
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }
    if (password.trim() === '') {
      errors.password = 'Password is required';
    }
    if (password.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (password != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    if (user === "3" && vendor === "") {
      errors.vendor = "Enter Vendor Name"
    }
    else if (user === "3" && namecheck.test(vendor) === false) {
      errors.vendor = "Alphabets only Allowed in Vendor Name"
    }
    if (user === "3" && product === "") {
      errors.product = "Enter Product Type";
    }
    if (user === "4" && TName === "") {
      errors.TName = "Enter Temple Name";
    }
    else if (user === "4" && namecheck.test(TName) === false) {
      errors.TName = "Alphabets only Allowed in Temple Name"
    }
    if (user === "4" && TAdd === "") {
      errors.TAdd = "Enter Temple Address"
    }
    setFormErrors({});
    if (Object.keys(errors).length === 0) {

      setShowLoading(true);
      console.log(
        email,
        phone,
        password,
        user,
        firstname,
        product,
        TName,
        TAdd,
        vendor,
        user
      )
      Authservice.Register(
        // aadhar,
        // address,
        // pincode,
        // area,
        // city,
        // state,
        // country,
        email,
        firstname,
        // lastname,
        phone,
        password,
        user,
        product,
        TName,
        TAdd,
        vendor,
        user
      )
        .then((response) => {
          console.log("res", response);
          setShowLoading(false);
          if (response.responseText.startsWith("SUCCESS")) {
            window.location.hash = "/login"
            Alert({
              header: "Atmagram",
              message: "Register Successfully",
              backdropDismiss: false,
              buttons: ['OK']
            })
            setConfirmpassword("");
            setFirstname("");
            setEmail("");
            setPassword("");
            setPhone("");
            setUser("");
            setTAdd("");
            setTName("");
            setproduct("");
            setvendor("");
          } else if (response.responseText == "ERROR:Email Id already exist") {
            setMessage("Email Id Already Exists, Try Any Other Email Id");
            setIserror(true);
          } else if (response.responseText == "ERROR:MobileNumber already exist") {
            setMessage("Mobile Number Already Exists, Try Any Other Mobile Number");
            setIserror(true);
          } else {
            setMessage("User Not Register Successfully")
            setIserror(true);
          }
        })
        .catch((error) => {
          setMessage("Something went wrong");
          setIserror(true);
          setShowLoading(false);
        });
    }
  };

  const phoneInputRef = useRef(null)
  const moveCursorToEnd = (value: any) => {
    const input: any = phoneInputRef.current;
    if (widthSize <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumber(false);
      console.log(error)
    }
    setPhone(value)
  };

  return (
    <IonPage>
      <IonContent>
        <div className="head_login">
          <HeadFoot User={"login"} Role={'Role'} Name='Home,Sign Up' /></div>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() =>{
        //   setShowLoading(false)
        //   if(!iserror){
        //     alert(message)
        //     window.location.hash = "/login"


        //   }

        // } }

        // duration={5000}
        />
        {/* <UserHead Role={userdata[0]} User="home" /> */}

        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={"Atmagram"}
          message={message}
          buttons={["Ok"]}
        />
        <IonAlert
          isOpen={issuccess}
          onDidDismiss={() => setIssuccess(false)}
          cssClass="my-custom-class"
          header={"Atmagram"}
          message={message}
          buttons={["Ok"]}
        />

        <IonRow className="page_container">
          <IonCol className="img_container" sizeLg="7.5" sizeMd="0" sizeXl="7.5" sizeSm="0" size="7.5" sizeXs="0" >
            <div className="tool_img" >
              <div className="logo_login">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <IonImg className="logo_class" src={logo} ></IonImg>
                </Link></div>
            </div>
          </IonCol>
          <IonCol sizeLg="4.5" sizeMd="12" sizeXl="4.5" sizeSm="12" size="4.5" sizeXs="12">

            <IonRow ><IonCol>
              <Link to="/" className="home_icon_div">
                <IonIcon src={home} className="home_icon">

                </IonIcon>
              </Link>

            </IonCol>


            </IonRow>
            <IonRow><IonCol>
              <div className="signup_container">

                <form onSubmit={handleSubmit(submit)}>
                  <div>
                    <span className="login_head">Sign Up</span>
                    <IonRow >
                      <IonCol size="12" size-lg="7" style={{ marginTop: "50px" }}>

                        <IonLabel className="label_align">
                          Choose a Role <span style={{ color: "red" }}>*</span>
                        </IonLabel>

                        <IonSelect
                          placeholder="Choose a Role"
                          value={user} className="select_user"

                          onIonChange={(e) => { setUser(e.detail.value!); ChangeValidation("user", e) }}
                          onBlur={() => Validation("user")}
                        >
                          {/* {Capacitor.getPlatform() === "web" ?  */}
                          <IonSelectOption value="2">User</IonSelectOption>
                          {/* : <></> */}
                          <IonSelectOption value="3">Vendor</IonSelectOption>
                          <IonSelectOption value="4">
                            Temple Admin
                          </IonSelectOption>
                        </IonSelect>


                        {formErrors.user && <span className="alert_input_bottom ">{formErrors.user}</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="12" size-lg="7" >


                        <IonLabel className="label_align">
                          First Name
                          <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="text" className="select_user"
                          value={firstname}
                          // required
                          onBlur={() => Validation("firstname")}
                          onIonChange={(e) => { setFirstname(e.detail.value!); ChangeValidation("firstname", e) }}
                        ></IonInput>


                        {formErrors.firstname && <span className="alert_input_bottom ">{formErrors.firstname}</span>}
                      </IonCol>
                    </IonRow>


                    <IonRow >
                      <IonCol size="12" size-lg="7" >
                        <IonLabel className="label_align">
                          Mobile Number <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <div className="select_user">
                          <PhoneInput
                            international
                            defaultCountry="IN"
                            value={phone}

                            ref={phoneInputRef}
                            onBlur={() => Validation("phone")}
                            // flags=false
                            onChange={(value: any) => { moveCursorToEnd(value); ChangeValidation("phone", value) }}
                          /></div>
                        <div style={{}} className={Capacitor.getPlatform() === "ios" ? "space-for-iOS" : ""}>
                          {formErrors.phone && <span className="alert_input_bottom ">{formErrors.phone}</span>}
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="12" size-lg="7" >


                        <IonLabel className="label_align" >
                          E-mail <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          // type="email"
                          value={email} className="select_user"
                          // required
                          onBlur={() => Validation("email")}
                          onIonChange={(e) => { setEmail(e.detail.value!); ChangeValidation("email", e) }}
                        ></IonInput>


                        {formErrors.email && <span className="alert_input_bottom ">{formErrors.email}</span>}
                      </IonCol>

                    </IonRow>
                    <IonRow >
                      <IonCol size="12" size-lg="7" >


                        <IonLabel className="label_align" >
                          Password <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="password" className="select_user"
                          value={password}
                          clearOnEdit={false}
                          onBlur={() => Validation("password")}
                          onIonChange={(e) => { setPassword(e.detail.value!); ChangeValidation("password", e) }}
                        ></IonInput>


                        {formErrors.password && <span className="alert_input_bottom ">{formErrors.password}</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="12" size-lg="7"  >


                        <IonLabel className="label_align">
                          Confirm Password <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="password" className="select_user"
                          clearOnEdit={false}
                          value={confirmpassword}
                          // required
                          onBlur={() => Validation("confirmpassword")}
                          onIonChange={(e) => { setConfirmpassword(e.detail.value!); ChangeValidation("confirmpassword", e) }}
                        ></IonInput>


                        {formErrors.confirmpassword && <span className="alert_input_bottom ">{formErrors.confirmpassword}</span>}
                      </IonCol>
                    </IonRow>
                    {user == 3 ?
                      <div>
                        <IonRow>
                          <IonCol size="12" size-lg="7" >
                            <div>

                              <IonLabel className="label_align">
                                Vendor Name <span style={{ color: "red" }}>*</span>
                              </IonLabel>
                              <IonInput
                                value={vendor} className="select_user"
                                // required
                                onBlur={() => Validation("VName")}
                                onIonChange={(e) => { setvendor(e.detail.value!); ChangeValidation("VName", e) }}
                              ></IonInput>

                            </div>
                            {formErrors.vendor && <span className="alert_input_bottom ">{formErrors.vendor}</span>}
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12" size-lg="7">
                            <div>

                              <IonLabel className="label_align" >
                                Product Type <span style={{ color: "red" }}>*</span>
                              </IonLabel>
                              <IonInput
                                value={product} className="select_user"
                                // required 
                                onBlur={() => Validation("product")}
                                onIonChange={(e) => { setproduct(e.detail.value!); ChangeValidation("product", e) }}
                              ></IonInput>

                            </div>
                            {formErrors.product && <span className="alert_input_bottom ">{formErrors.product}</span>}
                          </IonCol>
                        </IonRow></div> :
                      user == 4 ?
                        <div>
                          <IonRow>
                            <IonCol size="12" size-lg="7">
                              <div>

                                <IonLabel className="label_align">
                                  Temple Name <span style={{ color: "red" }}>*</span>
                                </IonLabel>
                                <IonInput
                                  value={TName} className="select_user"
                                  // required
                                  onBlur={() => Validation("TName")}
                                  onIonChange={(e) => { setTName(e.detail.value!); ChangeValidation("TName", e) }}
                                ></IonInput>

                              </div>
                              {formErrors.TName && <span className="alert_input_bottom ">{formErrors.TName}</span>}
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size="12" size-lg="7">
                              <div>

                                <IonLabel className="label_align" >
                                  Temple Address <span style={{ color: "red" }}>*</span>
                                </IonLabel>
                                <IonInput
                                  value={TAdd} className="select_user"
                                  // required
                                  onBlur={() => Validation("TAdd")}
                                  onIonChange={(e) => { setTAdd(e.detail.value!); ChangeValidation("TAdd", e) }}
                                ></IonInput>

                              </div>
                              {formErrors.TAdd && <span className="alert_input_bottom ">{formErrors.TAdd}</span>}
                            </IonCol>
                          </IonRow></div> : <div></div>}
                    <IonRow>

                      <IonCol size="12" size-lg="12" className="pass_container" >
                        <div >
                          <Link to="/login" className="forgot_pass_css">Already have an account?</Link>
                        </div>
                      </IonCol>
                    </IonRow>
                    <div className={["signup_btn_container", firstname.length === 0 ||
                      phone === undefined || phone === "" || !isValidPhoneNumber ||
                      email.length === 0 ||
                      password.length === 0 ||
                      confirmpassword.length === 0 ||
                      user == "" ? "disableCursor" : ""].join(" ")} style={{ width: "fit-content" }}>
                      <IonButton
                        type="submit"
                        disabled={
                          firstname.length === 0 ||
                          phone === undefined || phone === "" || !isValidPhoneNumber ||
                          email.length === 0 ||
                          password.length === 0 ||
                          confirmpassword.length === 0 ||
                          user == ""
                        }
                      >
                        <span className="button_padding"> Sign up</span>
                      </IonButton>
                    </div>
                  </div>
                </form>
              </div></IonCol></IonRow>
          </IonCol>
        </IonRow>
        {/* <Foot /> */}
      </IonContent>
    </IonPage>
  );
};

export default Signup;