import {
    IonCol,
    IonContent,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonText,
    IonLabel,
    IonInput,
    IonLoading,
    IonSearchbar,
    IonIcon,
} from "@ionic/react";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";
import { useForm } from "react-hook-form";
import UserService from "../../Services/user.service";
import DataTable from "react-data-table-component"

import "./Deitymanage.css";
import { useHistory } from "react-router";
import moment from "moment";
import { Capacitor } from "@capacitor/core";
import Authservice from "../../Services/user.service";
import { close } from "ionicons/icons";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";

const Projectdonation: React.FC = () => {

    const [usertoken] = useState(localStorage.getItem("token"));
    const history = useHistory();
    const userdata: any = useState(localStorage.getItem("UserData"));
    const uFlag: any = useState(localStorage.getItem("uFlag"));
    const [donationlist, setDonationlist] = useState([]);
    const [modalopen, setModalopen] = useState(false);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [name, setName] = useState();
    const [number, setNumber] = useState();
    const [amt, setAmt]: any = useState();
    const [date, setDate]: any = useState();
    const [mail, setMail] = useState();
    const [phone, setPhone] = useState("");
    const [addr, setAddr] = useState();
    const [remarks, setRemarks] = useState("")
    const [title, setTitle] = useState();
    const [purpose, setPurpose] = useState();
    const [taramt, setTaramt]: any = useState();
    if (donationlist != undefined && donationlist.length > 0) {
        donationlist.forEach((item: any, index: any) => { item.serial = index + 1; });
        donationlist.forEach((item: any, index: any) => {
            var date = item.donationDate.split("T");
            const od = moment(date[0]).format("DD-MM-YYYY");
            item.localOrderDate = od;

        });
    }


    const formatter = new Intl.NumberFormat('en-IN')

    const columns: any = [
        {
            name: "S.No",
            selector: (item: any, i: any) => item.serial,
            width: "90px",
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }}>{item.serial}</div>
                </div>
            )
        },
        {
            name: "Donation Number",
            sortable: true,
            // width: "200px",
            selector: (item: any) => item.donationNumber,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{item.donationNumber}</div>
                </div>
            )
        },
        {
            name: "Donor Date",
            selector: (item: any) => new Date(item.localOrderDate.split('-').reverse().join('-')),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{item.localOrderDate}</div>
                </div>
            )
        },

        {
            name: "Donor Name",
            selector: (item: any) => item.donorName,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{item.donorName}</div>
                </div>
            )
        },
        {
            name: "Contributed Amount (₹)",
            selector: (item: any) => parseInt(item.contributionAmount, 10),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{formatter.format(item.contributionAmount)}</div>
                </div>
            )
        },
        {
            name: "Purpose",
            selector: (item: any) => item.projectModel.purpose,
            sortable: true,
            // width: "250px"
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} className="two-line-text" >{item.projectModel.purpose}</div>
                </div>
            )
        },
        {
            name: "Project",
            selector: (item: any) => item.projectModel.projectTitle,
            sortable: true,
            // width: "250px",
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails(item)}>
                    <div style={{ cursor: "pointer" }} >{item.projectModel.projectTitle}</div>
                </div>
            )
        },
        // {
        //     name: "View",
        //     sortable: false,

        //     cell: (item: any) => (
        //         <>
        //             <IonButton onClick={() => viewdetails(item)} style={{ textTransform: "none" }}>View</IonButton>
        //         </>
        //     )
        // }
    ]

    useEffect(() => {
        console.log(uFlag, "uifuivi")
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_PROJECTADMIN") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            showalldonation();
        }

    }, []);
    const showalldonation = () => {
        setShowLoading(true);
        UserService.Projectdonation(usertoken, "").then((response) => {
            console.log(response.data);
            setDonationlist(response.data);
            setShowLoading(false);
        })

            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }

    const viewdetails = (item: any) => {
        var date = item.donationDate.split("T");
        const od = moment(date[0]).format("DD-MM-YYYY");
        setModalopen(true);
        setName(item.donorName);
        setNumber(item.donationNumber);
        setAmt(item.contributionAmount);
        setAddr(item.address)
        setDate(od);
        setMail(item.emailId);
        setPhone(item.contactNumber);
        setTitle(item.projectModel.projectTitle);
        setTaramt(item.projectModel.targetAmount);
        setPurpose(item.projectModel.purpose);
        setRemarks(item.remarks)
    };


    const Modalclose = () => {
        showalldonation();
        setModalopen(false)
    }

    var tableData: any = [];
    if (donationlist != undefined && donationlist.length > 0) {
        tableData = donationlist.filter(
            (item: any) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        );
    }


    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <IonRow className="table_btn_container">
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle]);

    const useOutsideAlerter = (refer: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (refer.current && !refer.current.contains(event.target)) {
                    setModalopen(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer]);
    }

    const projectdonationmodal = useRef(null);

    useOutsideAlerter(projectdonationmodal);


    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Project Donations"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <Modal show={modalopen} className="admin_modal">
                    <Modal.Body ref={projectdonationmodal}>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Donation Details</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
                            </div>
                        </div>
                        <div className="create-modal-project-container donation-admin-container">
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Donation Number
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{number}</IonText>
                                </IonCol>
                            </IonRow>
                            {name === "" || name === null ? <></> :
                                <IonRow>
                                    <IonCol size="5">
                                        <IonLabel className="label_align" >
                                            Donor Name
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonText>:</IonText>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonText
                                        >{name}</IonText>
                                    </IonCol>
                                </IonRow>}
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Contributed Amount(₹)
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {formatter.format(amt)}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Donation Date
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{date}</IonText>
                                </IonCol>
                            </IonRow>
                            {mail === "" || mail === null ? <></> :
                                <IonRow>
                                    <IonCol size="5">
                                        <IonLabel className="label_align" >
                                            Mail Id
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonText>:</IonText>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonText
                                        >{mail}</IonText>
                                    </IonCol>
                                </IonRow>}
                            {addr === "" || addr === null ? <></> :
                                <IonRow>
                                    <IonCol size="5">
                                        <IonLabel className="label_align" >
                                            Address
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonText>:</IonText>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonText
                                        >{addr}</IonText>
                                    </IonCol>
                                </IonRow>}
                            {phone === "" || phone === null ? <></> :
                                <IonRow>
                                    <IonCol size="5">
                                        <IonLabel className="label_align" >
                                            Contact Number
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="1">
                                        <IonText>:</IonText>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonText
                                        >{Authservice.formatPhone(phone)}</IonText>
                                    </IonCol>
                                </IonRow>}
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Project Title
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{title}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Purpose
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{purpose}</IonText>
                                </IonCol>
                            </IonRow>
                            {remarks === "" || remarks === null ? <></> : <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Remarks
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{remarks}</IonText>
                                </IonCol>
                            </IonRow>}
                            <IonRow>
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Target Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {formatter.format(taramt)}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12" style={{ display:"flex",justifyContent: "center" }}>
                                    <IonButton
                                        className="admin_add_btn"
                                        onClick={Modalclose}
                                    >
                                        <span className="button_padding">Close</span>
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    </Modal.Body>
                </Modal>
                <div>
                    <div className="container_middle_table page-wrapper admin_background_color">
                        <DataTable
                            columns={columns}
                            data={tableData}
                            striped
                            noDataComponent="Sorry ! No result found"
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                            subHeader
                            subHeaderComponent={subHeaderComponent}
                            style={{ width: "100%" }}
                            onRowClicked={(item: any) => viewdetails(item)}
                            customStyles={customTableStyle}
                        />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
export default Projectdonation;


