import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import { useHistory } from "react-router";
import Head from "./Head";
import { Capacitor } from "@capacitor/core";
import HeadFoot from "./Head";

const Terms: React.FC = () => {

  const userdata: any = useState(localStorage.getItem("UserData"));
  const [token, settoken]: any = useState(localStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Conditions of Sale"} />
        <IonRow className="page_content_padding">
          <IonCol>
            <div style={{ margin: "2% 4%" }}>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                Conditions of Sale (between Sellers and the Customer)
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                Please read these conditions carefully before placing an order for any products with the Sellers ("We" or "Our" or "Us", wherever applicable) on the Atmagram.com (the website).
                These conditions signify your agreement to be bound by these conditions.
              </p>
              <p style={{ textAlign: "justify" }}>
                In addition, when you use any current or future Atmagram.com, you will also be subject to the terms, guidelines and conditions applicable to that service ("Terms"). If these Conditions of Sale are inconsistent with such Terms, the Terms will control.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                1. Conditions Relating to the Sale of Products to You
              </p>
              <p style={{ textAlign: "justify" }}>
                This section deals with conditions relating to the sale of products on the website by us to you.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                2. Our Contract
              </p>
              <p style={{ textAlign: "justify" }}>
                Your order is an offer to us to buy the product(s) in your order. When you place an order to purchase a product from us, you will receive an e-mail confirming receipt of your order and containing the details of your order (the "Order Confirmation E-mail"). The Order Confirmation E-mail is acknowledgement that we have received your order, and does not confirm acceptance of your offer to buy the product(s) ordered. We only accept your offer, and conclude the contract of sale for a product ordered by you, when the product is dispatched to you and an e-mail confirmation is sent to you that the product has been dispatched to you (the "Dispatch Confirmation E-mail").
              </p>
              <p style={{ textAlign: "justify" }}>
                Your contract is with us (the Sellers) and you confirm that the product(s) ordered by you are purchased for your internal / personal purpose and not for re-sale or business purpose. You authorize us to declare and provide declaration to any governmental authority on your behalf stating the aforesaid purpose of the products ordered by you on the website.
              </p>
              <p style={{ textAlign: "justify" }}>
                You can cancel your order for a product at no cost any time before we send the Dispatch Confirmation E-mail relating to that product.
              </p>
              <p style={{ textAlign: "justify" }}>
                Please note that we sell products only in quantities which correspond to the typical needs of an average household. This applies both to the number of products ordered within a single order and the placing of several orders for the same product where the individual orders comprise a quantity typical for a normal household.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                3. Returns
              </p>
              <p style={{ textAlign: "justify" }}>
                Most items purchased from sellers listed on Atmagram.com are returnable within the return window, except those that are explicitly identified as not returnable. The return is processed only if:
              </p>
              <ul>
                <li style={{ textAlign: "justify" }}>
                  the order is made under Grammerce;
                </li>
                <li style={{ textAlign: "justify" }}>
                  it is determined that the product was not damaged while in your possession
                </li>
                <li style={{ textAlign: "justify" }}>
                  the product is not different from what was shipped to you;
                </li>
                <li style={{ textAlign: "justify" }}>
                  the product is returned in original condition (with brand’s/manufacturer's box, MRP tag intact, user manual, warranty card and accessories)
                </li>
              </ul>
              <p style={{ textAlign: "justify" }}>
                You can review return policy for products listed on Atmagram.com by clicking here. For the products that are returned by the customer, the refund is issued to the original payment method (in case of pre-paid transactions) or to the bank account, the details for making such refund and the timelines are detailed in the refund policy available here.
              </p>
              <p style={{ textAlign: "justify" }}>
                Please review our Returns Policy, which applies to products sold by us.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                4. Pricing and availability
              </p>
              <p style={{ textAlign: "justify" }}>
                We list availability information for products sold by us on the website, including on each product information page. Beyond what we say on that page or otherwise on the website, we cannot be more specific about availability. Please note that dispatch estimates are just that. They are not guaranteed dispatch times and should not be relied upon as such. As we process your order, you will be informed by e-mail if any products you order turn out to be unavailable.
              </p>
              <p style={{ textAlign: "justify" }}>
                All prices are inclusive of VAT/CST, service tax, Goods and Services Tax ("GST"), duties and cesses as applicable - unless stated otherwise.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                5. Taxes
              </p>
              <p style={{ textAlign: "justify" }}>
                You shall be responsible for payment of all fees/costs/charges associated with the purchase of products from us and you agree to bear any and all applicable taxes including but not limited to VAT/CST, service tax, GST, duties and cesses etc.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                6. Children
              </p>
              <p style={{ textAlign: "justify" }}>
                Use of Atmagram.com is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may purchase only with the involvement of a parent or guardian.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                7. Communications
              </p>
              <p style={{ textAlign: "justify" }}>
                When you visit Atmagram.com, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the website or by any other mode of communication. For contractual purposes, you consent to receive communications including SMS, e-mails or phone calls from us with respect to your order.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                8. Losses
              </p>
              <p style={{ textAlign: "justify" }}>
                We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when a contract for the sale of goods by us to you was formed.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                9. Alteration or Amendments to the Conditions
              </p>
              <p style={{ textAlign: "justify" }}>
                We reserve the right to make changes to our policies, and these Conditions of Sale at any time. You will be subject to the policies and Conditions of Sale in force at the time you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                10. Events beyond our reasonable control
              </p>
              <p style={{ textAlign: "justify" }}>
                We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                11. Waiver
              </p>
              <p style={{ textAlign: "justify" }}>
                If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                12. Governing Law and Jurisdiction
              </p>
              <p style={{ textAlign: "justify" }}>
                These conditions are governed by and construed in accordance with the laws of India, and the United Nations Convention on Contracts for the International Sale of Goods.
              </p>
            </div>
          </IonCol>
        </IonRow>
        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default Terms;