import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import { useHistory } from "react-router";
import Head from "./Head";
import { Capacitor } from "@capacitor/core";

const Ser: React.FC = () => {

  const userdata: any = useState(localStorage.getItem("UserData"));
  const [token, settoken]: any = useState(localStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
      {/* {userdata[0] === "ROLE_USER" ? <UserHead User="services" Role={userdata[0]} />: userdata[0] === null ?<UserHead User="services" Role={userdata[0]} />:<Head User="services" Role={userdata[0]} />}       */}
        <div className="container_middles_layout">
        <h5 style={{ justifyContent: 'center', textAlign: 'center', marginTop: '1%', fontWeight: 'bold' }}>The service to the temples
        </h5>
        <p style={{ textAlign: "justify" }}>The present need is not in waiting for lots of money to do the services to the abodes. It rather requires inclination to serve. This service could happen with millions in currency or could be without a penny. It depends on what one could and one would like to give. One who is wealthy
          materially and mentally could do the renovations and maintenance of the abodes verily on his/her
          own to that Supreme Lord who gave the most admired wealths to kubera in a moment.
          Those who could come together pooling in the drops making an ocean could very well serve the Lord of all.
          One who could not afford even a penny could offer the work with the body in the service of the Lord and abode.
          One who is not physically fit to do that could at least sit in His presence, chant and inspire others to do
          the service.
        </p>
        <p style={{ textAlign: "justify" }}>The life of thirun^Avukkarachar perumAn 
        should be a great inspiration for us. Whichever abode of the Lord who Danced blissfully in his heart,
         he went, he did whatever required and possible for the environment of the temples using the instrument 
         uzavAram he always held in his hand. This does not require any cost other than the not so big cost of the
          instrument itself, but the service offered is great! Even today these services which involves physical effort 
          and not much of cost are called uzavArap paNi and is done by the devotees in many abodes. Various services could
           be offered without incurring much cost including,
        </p>
        <p style={{textAlign:"left",fontWeight:'bold'}}> *   Removing the accumulated pests and other plants that hinder the way to the abodes</p>
        <p style={{textAlign:"left",fontWeight:'bold'}}> *   Forming gardens for worship</p>
        <p style={{textAlign:"left",fontWeight:'bold'}}> *  Cleaning the temple premises</p>
        <p style={{textAlign:"left",fontWeight:'bold'}}> *  Illuminating the temples</p>
        <p style={{textAlign:"left",fontWeight:'bold'}}> *  Organizing the thirumuRai/vEda classes</p>
        <p style={{textAlign:"left",fontWeight:'bold'}}> *  Organizing discourses on shaivam</p>
        <p style={{textAlign:"justify"}}>  All it requires is motivation and devotion. We could do whatever little we can in order to ensure that they stand gloriously in the time to come delivering the divine grace to the generations that would thank us for this service.</p>
         </div>
          
           <Foot />
      </IonContent>
    </IonPage>
  );
};

export default Ser;