import {
  IonContent,
  IonItem,
  IonPage,
  IonButton,
  IonItemDivider,
  IonRow,
  IonInput,
  IonAlert,
  IonLabel,
  useIonRouter,
  IonCol
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import axios from "axios";
import { useForm } from "react-hook-form";
import Foot from "./Foot";
import UserHead from "./UserHead";
import Authservice from "../Services/auth.service";
import HeadFoot from "./Head";
import validator from "validator";

const VerifyLink: React.FC = (props) => {
  const {
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const [email, setEmail] = useState("");
  const history = useHistory();
  const params: any = useParams()
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [message, setMessage] = useState("");
  const [iserror, setIserror] = useState(false);
  const [issuccess, setIssuccess] = useState(false);
  const userdata: any = useState(localStorage.getItem("UserData"));
  const token: any = params.token;

  const [headName, setHeadName] = useState('');
  const [formErrors, setFormErrors]: any = useState({})


  useEffect(() => {
    setHeadName("Home,Reset Password");
    // Authservice.IsvaildToken(token).then((response)=>{
    //     if (response.data.startsWith("SUCCESS")) {
    //         setMessage(response.data.split('SUCCESS:')[1]);
    //         setIssuccess(true);

    //       } else {
    //         setMessage(response.data.split('ERROR:')[1]);
    //         setIserror(true);
    //             history.push("/login")
    //       }
    // })
    // .catch((error)=>{
    //     setMessage(error);
    //     setIserror(true);
    // });

  }, [])

  const onsubmit = () => {
    const errors: any = {};
    if (email.trim() === '') {
      errors.email = 'Email is required';
    }
    else if (validator.isEmail(email) === false) {
      errors.email = "Enter Valid Email Address";
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }
    if (password.trim() === '') {
      errors.password = 'Password is required';
    }
    if (password.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (password != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    setFormErrors({});
    if (Object.keys(errors).length === 0) {
      Authservice.ChangePasswordByTkn(password, email, token, confirmpassword).then((response) => {
        if (response.data.startsWith("SUCCESS")) {
          setMessage(response.data.split('SUCCESS:')[1]);
          setIssuccess(true);
          history.push("/login");
        } else {
          setMessage(response.data.split('ERROR:')[1]);
          setIserror(true);
        }
      })
        .catch((error) => {
          setMessage(error);
          setIserror(true);
        });
    }

  }

  const Validation = (label: any) => {
    if (label === "email") {
      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: 'Email is required' }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }
    if (label === "password") {
      if (confirmpassword !== "") {
        if (password === confirmpassword) {
          setFormErrors((prev: any) => {
            return { ...prev, confirmpassword: "" }
          })
        }
      }
      else if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }
    if (label === "confirmpassword") {
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
  }

  const ChangeValidation = (label: any, e: any) => {
    if (label === "email") {
      const email = e.target.value;

      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: 'Email is required' }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }
    if (label === "password") {
      const password = e.target.value;
      if (confirmpassword !== "") {
        if (password === confirmpassword) {
          setFormErrors((prev: any) => {
            return { ...prev, confirmpassword: "" }
          })
        }
      }
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }
    if (label === "confirmpassword") {
      const confirmpassword = e.target.value;

      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }

  }

  return (
    <IonPage>
      <IonContent>
        {/* <UserHead Role={userdata[0]} User="verify"/> */}
        <HeadFoot User={"login"} Role="Role" Name={headName} />
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={"Error!"}
          message={message}
          buttons={["Dismiss"]}
        />
        <IonAlert
          isOpen={issuccess}
          onDidDismiss={() => setIssuccess(false)}
          cssClass="my-custom-class"
          header={"Success!"}
          message={message}
          buttons={["Dismiss"]}
        />
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="page_content_padding container_bg">
            <div style={{ height: "fit-content" }}>
              <IonRow>
                <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                  <IonLabel className="label_align">
                    Email <span style={{ color: "red" }}>*</span>
                  </IonLabel>
                  <IonInput className="select_user"
                    type="email"
                    onIonChange={(e) => { setEmail(e.detail.value!); ChangeValidation("email", e) }}
                    required
                    onBlur={() => Validation("email")}
                  />
                  {formErrors.email && <span className="alert_input_bottom ">{formErrors.email}</span>}
                </IonCol>
              </IonRow>
              <IonRow className="row_padding">
                <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                  <IonLabel className="label_align">
                    Password <span style={{ color: "red" }}>*</span>
                  </IonLabel>
                  <IonInput className="select_user"
                    type="password"
                    clearOnEdit={false}
                    onIonChange={(e) => { setPassword(e.detail.value!); ChangeValidation("password", e) }}
                    required
                    onBlur={() => Validation("password")}
                  />
                  {formErrors.password && <span className="alert_input_bottom ">{formErrors.password}</span>}
                </IonCol>
              </IonRow>
              <IonRow className="row_padding">
                <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                  <IonLabel className="label_align">
                    Confirm Password <span style={{ color: "red" }}>*</span>
                  </IonLabel>
                  <IonInput className="select_user"
                    type="password"
                    clearOnEdit={false}
                    onIonChange={(e) => { setConfirmpassword(e.detail.value!); ChangeValidation("confirmpassword", e) }}
                    required
                    onBlur={() => Validation("confirmpassword")}
                  />
                  {formErrors.confirmpassword && <span className="alert_input_bottom ">{formErrors.confirmpassword}</span>}
                </IonCol>
              </IonRow>
              <IonRow className="btn_row_padding">
                <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                  <IonButton
                    type="submit"
                    disabled={
                      confirmpassword.length === 0 ||
                      password.length === 0 ||
                      email.length === 0
                    }
                  >
                    <span className="button_padding">
                      Submit
                    </span>
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </div>
        </form>
        {/* <div className="container_middle">
          <h4>Reset Password</h4>
          <form onSubmit={handleSubmit(onsubmit)}>
          <div className="top_profile">
            <IonRow className="row_bot pad_text">
                <IonCol size="11" size-lg="7">
              <IonItem className="select_user border border rounded">
                <IonLabel position="floating" className="label_css">Email</IonLabel>
                <IonInput
                className="input_css"
                  type="email"
                  onIonChange={(e) => setEmail(e.detail.value!)}
                  required
                ></IonInput>
              </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="row_bot pad_text">
            <IonCol size="11" size-lg="7">
              <IonItem className="select_user border border rounded">
                <IonLabel position="floating" className="label_css">Password</IonLabel>
                <IonInput
                className="input_css"
                  type="password"
                  onIonChange={(e) => setPassword(e.detail.value!)}
                  required
                ></IonInput>
              </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="row_bot pad_text">
            <IonCol size="11" size-lg="7">
              <IonItem className="select_user border border rounded">
                <IonLabel position="floating" className="label_css">Confirm Password</IonLabel>
                <IonInput
                className="input_css"
                  type="password"
                  onIonChange={(e) => setConfirmpassword(e.detail.value!)}
                  required
                ></IonInput>
              </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="row_bot pad_text">
            <IonCol size="11" size-lg="7" style={{textAlign:'left'}}>
              <IonButton
                className="but_pass"
                type="submit"
                disabled={
                  confirmpassword.length === 0 ||
                  password.length === 0 ||
                  email.length === 0
                }
              >
                Submit
              </IonButton>
              </IonCol>
            </IonRow>
          </div>
          </form>
        </div> */}
        <Foot />
      </IonContent>
    </IonPage>
  );
};
export default VerifyLink;
