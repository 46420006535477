import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonList, IonMenu, IonMenuButton, IonPopover, IonRow, IonSplitPane, IonText, IonTitle, IonToolbar, useIonAlert } from "@ionic/react";
import { chevronForwardOutline, menu, personCircleOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import { Modal } from "react-bootstrap";
import { Plugins } from '@capacitor/core';
import { logo } from "../../theme/imagePath";
import SessionTimeout from "../../Services/SessionTimeOut";
import UserService from "../../Services/auth.service";

interface Props {
      User: string;
      Role: string;
      Name: string;
}

const AdminHead: React.FC<Props> = (props) => {

      const { token } = useContext(AppContext)!;
      const { uFlag } = useContext(AppContext)!;
      const { userdata } = useContext(AppContext)!;
      const { setUserData } = useContext(AppContext)!;
  const { setUFlag } = useContext(AppContext)!;
  const { setToken } = useContext(AppContext)!;
  const [Alert] = useIonAlert();

      const pageNmae: any = useState(props.Name);

      const [modalopen, setModalopen] = useState(false);
      const { Storage } = Plugins;


      const handlesidebar = () => {
            document.body.classList.toggle('mini-sidebar');
      }


      const logout = async () => {
            UserService.logout(token).then(async (response) => {
                  // Cart();
                  if (response.data == "Logout successful") {
                        try {
                              await Storage.clear();
                              console.log('Cache cleared successfully.');
                        } catch (error) {
                              console.error('Error clearing cache:', error);
                        }
            
                        setModalopen(false);
                        setUserData(null);
                setUFlag(null)
                setToken(null)
                        localStorage.clear()
                        window.location.hash = "/login";
                        window.location.reload();
                  }else{
                    setModalopen(false);
                    Alert("something went wrong. Please try again")
                  }
            
                })
                  .catch((error) => {
                    Alert("something went wrong. Please try again")
                    
            
                  });
           
      };

      const local: any = useLocation();

      const [isSideMenu, setSideMenu] = useState("");

      const toggleSidebar = (value: any) => {
            setSideMenu(value);
      }


      const [screenWidth, setScreenWidth] = useState(window.innerWidth);

      // Function to update the screen width when the window is resized
      const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
      };

      useEffect(() => {
            window.addEventListener('resize', updateScreenWidth);

            // Clean up the event listener when the component unmounts
            return () => {
                  window.removeEventListener('resize', updateScreenWidth);
            };
      }, []);

      const mobile = screenWidth <= 1000;

      const [isPopoverOpen, setIsPopoverOpen] = useState(false);

      const handleUserKeyPress = (event: any) => {
            if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
                  setIsPopoverOpen(!isPopoverOpen);
            }
      }

      return (
            <div className="safe_area"><SessionTimeout />
                  <div id="sub-content">
                        <div className="admin_head_sub_container">
                              <div>
                                    <IonIcon tabIndex={0} src={menu} className="admin_head_menu_button" id="toggle_btn" onClick={handlesidebar} onKeyDown={(event: any) => {
                                          if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
                                                handlesidebar()
                                          }
                                    }} />
                                    <IonButtons slot="right">
                                          <IonMenuButton id="mobile_btn" className="mobile_btn admin_head_menu_button" />
                                    </IonButtons>
                              </div>
                              <IonIcon
                                    className="admin_menu_css"
                                    src={personCircleOutline}
                                    aria-label="Profile"
                                    id={props.User}
                                    tabIndex={0}
                                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                    onKeyDown={handleUserKeyPress}
                              ></IonIcon>
                              <IonPopover
                                    isOpen={isPopoverOpen}
                                    trigger={props.User}
                                    event="click"
                                    id={'profilePop'}
                                    dismissOnSelect={true}
                                    show-backdrop={false}
                              >
                                    <IonContent className="popover_head">
                                          <IonList className="popMenuListCls">
                                                <Link to="/profile" className="text_decor">
                                                      <IonItem
                                                            className="border_menu"
                                                            button={true}
                                                            detail={false}
                                                      >
                                                            My Profile
                                                      </IonItem>
                                                </Link>
                                                <Link to="/change_password" className="text_decor">
                                                      <IonItem
                                                            className="border_menu"
                                                            button={true}
                                                            detail={false}
                                                      >
                                                            Change Password
                                                      </IonItem>
                                                </Link>
                                                <IonItem
                                                      className="border_menu"
                                                      button={true}
                                                      detail={false}
                                                      onClick={(e) => setModalopen(true)}
                                                      id="logouts-modal"
                                                >
                                                      Sign Out
                                                </IonItem>
                                          </IonList>
                                    </IonContent>
                              </IonPopover>
                              <Modal show={modalopen} className="modal_dialog_width1" centered>
                                    <Modal.Body>
                                          <IonRow>
                                                <IonCol style={{ textAlign: "center" }}>
                                                      <h5 className="mt-2">Do you want to sign out ?</h5>
                                                </IonCol>
                                          </IonRow>
                                          <IonRow className="mt-12">
                                                <IonCol size="6" style={{ textAlign: "end" }}>
                                                      <IonButton onClick={logout}>Yes</IonButton>
                                                </IonCol>
                                                <IonCol size="6">
                                                      <IonButton onClick={(e) => setModalopen(false)}>No</IonButton>
                                                </IonCol>
                                          </IonRow>
                                    </Modal.Body>
                              </Modal>
                        </div>
                        <div className="admin_head_sub_container2">
                              <IonText className="admin_head_menu_name">{pageNmae}</IonText>
                        </div>
                  </div>
                  {mobile ?
                        <IonMenu contentId="sub-content">
                              <IonContent className="">
                                    <div className="mobile_sidebar safe_area">
                                          <div className="sidebar-inner slimscroll">

                                                <div className="sidebar_logo">

                                                </div>
                                                <div>
                                                      <i className="menu-title">Menu</i>
                                                </div>
                                                <div id="sidebar-menu" className="sidebar-menu">
                                                      {userdata === "ROLE_ADMIN" ?
                                                            <ul>
                                                                  <li className={local.pathname === "/admin_page" ? "active" : ""}>
                                                                        <Link to="/admin_page" > <span>User Management</span></Link>
                                                                  </li>
                                                                  <li className={local.pathname === "/deity" ? "active" : ""}>
                                                                        <Link to="/deity" > <span>Deity Management</span></Link>
                                                                  </li>
                                                                  <li className={local.pathname === "/admtemple" ? "active" : ""}>
                                                                        <Link to="/admtemple" > <span>Temple Management</span></Link>
                                                                  </li>
                                                                  <li className={local.pathname === "/orderAdmin" ? "active" : ""}>
                                                                        <Link to="/orderAdmin" > <span>Offering Order Management</span></Link>
                                                                  </li>
                                                                  <li className="submenu">
                                                                        <a className={isSideMenu == "materials" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "materials" ? "" : "materials")}><span> Pooja Materials</span> <IonIcon src={chevronForwardOutline} className="menu-arrow" /></a>
                                                                        {isSideMenu === "materials" ?
                                                                              <ul >
                                                                                    <li><Link className={local.pathname === "/materialadm" ? "active" : ""} to="/materialadm">Material Management</Link></li>
                                                                                    <li><Link className={local.pathname === "/admmaterialorders" ? "active" : ""}
                                                                                          to="/admmaterialorders">Material Order Management</Link></li>
                                                                              </ul>
                                                                              : ""
                                                                        }
                                                                  </li>
                                                                  <li className="submenu">
                                                                        <a className={isSideMenu == "grammerce" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "grammerce" ? "" : "grammerce")}><span> Grammerce</span> <IonIcon src={chevronForwardOutline} className="menu-arrow" /></a>
                                                                        {isSideMenu === "grammerce" ?
                                                                              <ul >
                                                                                    <li><Link className={local.pathname === "/grammerceadm" ? "active" : ""} to="/grammerceadm">Product Management</Link></li>
                                                                                    <li><Link className={local.pathname === "/grammerceorderadm" ? "active" : ""}
                                                                                          to="/grammerceorderadm"> Order Management</Link></li>
                                                                              </ul>
                                                                              : ""
                                                                        }
                                                                  </li>
                                                                  <li className={local.pathname === "/donationmanage" ? "active" : ""}>
                                                                        <Link to="/donationmanage" > <span>Donation Management</span></Link>
                                                                  </li>
                                                                  <li className="submenu">
                                                                        <a className={((local.pathname === "/projectmanage") || (local.pathname === "/donationadm")) ? "active" : ""} onClick={() => toggleSidebar(isSideMenu == "projects" ? "" : "projects")}><span> Projects</span> <IonIcon src={chevronForwardOutline} className="menu-arrow" /></a>
                                                                        {isSideMenu === "projects" ?
                                                                              <ul >
                                                                                    <li><Link className={local.pathname === "/projectmanage" ? "active" : ""} to="/projectmanage">Project Management</Link></li>
                                                                                    <li><Link className={local.pathname === "/donationadm" ? "active" : ""}
                                                                                          to="/donationadm"> Project Donation</Link></li>
                                                                              </ul>
                                                                              : ""
                                                                        }
                                                                  </li>
                                                                  <li className={local.pathname === "/reports" ? "active" : ""}>
                                                <Link to="/reports" > <span>Report</span></Link>
                                          </li>
                                                            </ul>
                                                            : userdata === "ROLE_TEMPADMIN" ?
                                                                  <ul>
                                                                        <li className={local.pathname === "/tempadmin" ? "active" : ""}>
                                                                              <Link to="/tempadmin" > <span>Temple Management</span></Link>
                                                                        </li>
                                                                        <li className={local.pathname === "/order" ? "active" : ""}>
                                                                              <Link to="/order" > <span>Order Management</span></Link>
                                                                        </li>
                                                                        <li className={local.pathname === "/reports" ? "active" : ""}>
                                                <Link to="/reports" > <span>Report</span></Link>
                                          </li>
                                                                  </ul>
                                                                  : userdata === "ROLE_VENDOR" ?
                                                                        <ul>
                                                                              <li className={local.pathname === "/vendormaterials" ? "active" : ""}>
                                                                                    <Link to="/vendormaterials" > <span>Material Management</span></Link>
                                                                              </li>
                                                                              <li className={local.pathname === "/vendormateriaorders" ? "active" : ""}>
                                                                                    <Link to="/vendormateriaorders" > <span>Material Order Management</span></Link>
                                                                              </li>
                                                                              <li className={local.pathname === "/reports" ? "active" : ""}>
                                                <Link to="/reports" > <span>Report</span></Link>
                                          </li>
                                                                        </ul>
                                                                        : userdata === "ROLE_PROJECTADMIN" ?
                                                                              <ul>
                                                                                    <li className={local.pathname === "/projectadmin" ? "active" : ""}>
                                                                                          <Link to="/projectadmin" > <span>Project Management</span></Link>
                                                                                    </li>
                                                                                    <li className={local.pathname === "/projectdonate" ? "active" : ""}>
                                                                                          <Link to="/projectdonate" > <span>Project Donation</span></Link>
                                                                                    </li>
                                                                                    <li className={local.pathname === "/reports" ? "active" : ""}>
                                                <Link to="/reports" > <span>Report</span></Link>
                                          </li>
                                                                              </ul>
                                                                              : <></>
                                                      }
                                                </div>
                                          </div>
                                    </div>
                              </IonContent>
                        </IonMenu> : <></>}
            </div>
      )
}

export default AdminHead;