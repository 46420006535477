import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  IonContent,
  IonItem,
  IonPage,
  IonButton,
  IonItemDivider,
  IonRow,
  IonCol,
  IonInput,
  IonAlert,
  IonLabel,
  IonModal,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonText,
  useIonAlert,
  IonSearchbar
} from "@ionic/react";
import { addCircleSharp, addOutline, arrowBack, close, reorderFour } from "ionicons/icons";
import "./Userpage.css";
import "./Home.css";
import Head from "./Head";
import Foot from "./Foot";
import UserService from "../Services/auth.service";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component"
import { Modal } from "react-bootstrap";
import Authservice from "../Services/user.service";
import { Capacitor } from "@capacitor/core";
import validator from "validator";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { count, error } from "console";
import Sidebar from "./admin/sidebar";
import AdminHead from "./admin/adminHead";
import customTableStyle from "../components/CustomTableStyle";

interface State {
  id: number;
  name: string;
}

const Adminpage: React.FC = () => {
  const [allusers, setAllusers] = useState([]);
  const [index, setIndex] = useState(0);
  const [user, setUser]: any = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [password, setPassword] = useState("");
  const [state1, setState1]: any = useState("");
  const [TName, setTName]: any = useState("");
  const [TAdd, setTAdd]: any = useState("");
  const [aadhar1, setAadhar1] = useState("");
  const [addr, setAddr] = useState("");
  const [area1, setArea1] = useState("")
  const [firstname1, setFirstname1] = useState("");
  const [lastname1, setLastname1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [city1, setCity1] = useState("");
  const [country1, setCountry1] = useState("");
  const [email1, setEmail1] = useState("");
  const [comment, setComment] = useState("");
  const [issuccess, setIssuccess] = useState(false);
  const [pincode1, setPincode1] = useState("");
  const mailpat = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
  const namecheck = /^[a-zA-Z\s]*$/
  const phoneInputRef = useRef(null)
  const [isValidPhoneNumberSign, setIsValidPhoneNumberSign] = useState(false);
  const [formErrors, setFormErrors]: any = useState({});


  const moveCursorToEnd = (value: any) => {
    const input: any = phoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumberSign(phoneNumberObject ? phoneNumberObject.isValid() : false);
      if (value === '' || value === undefined) {
        setFormErrors({ ...formErrors, phone1: "Mobile Number is required" })
      }
      else if (!(phoneNumberObject ? phoneNumberObject.isValid() : false)) {
        setFormErrors({ ...formErrors, phone1: "Please Enter Valid Mobile Number" })
      }
      else {
        setFormErrors({ ...formErrors, phone1: "" })
      }
    } catch (error) {
      setIsValidPhoneNumberSign(false);
      console.log(error)
    }
    setPhone1(value)
  };

  const validation = (num: any) => {
    if (num === 1) {
      if (user === "") {
        setFormErrors({ ...formErrors, user: "Choose a Role" })
      }
      else {
        setFormErrors({ ...formErrors, user: "" })
      }
    }
    else if (num === 2) {
      if (firstname1 === "") {
        setFormErrors({ ...formErrors, firstName1: "Enter First Name" })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors({ ...formErrors, firstName1: 'Alphabets only Allowed in First Name' })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors({ ...formErrors, firstName1: 'Empty Space at First Name' })
      }
      else {
        setFormErrors({ ...formErrors, firstName1: "" })
      }
    }
    else if (num === 3) {
      if (phone1 === '' || phone1 === undefined) {
        setFormErrors({ ...formErrors, phone1: "Mobile Number is required" })
      }
      else if (!isValidPhoneNumberSign) {
        setFormErrors({ ...formErrors, phone1: "Please Enter Valid Mobile Number" })
      }
      else {
        setFormErrors({ ...formErrors, phone1: "" })
      }
    }
    else if (num === 4) {
      if (email1.trim() === '') {
        setFormErrors({ ...formErrors, email1: "Email is required" })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors({ ...formErrors, email1: "Enter Valid Email Address" })
      }
      else {
        setFormErrors({ ...formErrors, email1: "" })
      }
    }
    if (num === 5) {
      if (password.trim() === '') {
        setFormErrors({ ...formErrors, password1: "Password is required" })
      }
      else {
        setFormErrors({ ...formErrors, password1: "" })
      }
    }
    if (num === 6) {
      if (confirmpassword.trim() === '') {
        setFormErrors({ ...formErrors, confirmpassword: "Confirm Password is required" })
      }
    }
    if (num === 7) {
      if (user === "4" && TName === "") {
        setFormErrors({ ...formErrors, templeName: "Enter Temple Name" })
      }
      else if (user === "4" && namecheck.test(TName) === false) {
        setFormErrors({ ...formErrors, templeName: "Alphabets only Allowed in Temple Name" })
      }
    }
    if (num === 8) {
      if (user === "4" && TAdd === "") {
        setFormErrors({ ...formErrors, address: "Enter Temple Address" })
      }
    }
  }

  const onchangevalidation = (e: any, num: any) => {
    if (num === 1) {
      var cuser = e.target.value;
      if (cuser !== "") {
        setFormErrors({ ...formErrors, user: "" })
      }
      setUser(e.target.value)
    }
    if (num === 2) {
      var name = e.detail.value!;
      if (namecheck.test(name) == false) {
        setFormErrors({ ...formErrors, firstName1: 'Alphabets only Allowed in First Name' })
      }
      else if (name.charAt(0) == " ") {
        setFormErrors({ ...formErrors, firstName1: 'Empty Space at First Name' })
      }
      else {
        setFormErrors({ ...formErrors, firstName1: "" })
      }
      setFirstname1(e.detail.value!)
    }
    if (num === 3) {
      var mail = e.detail.value!;
      if (mail.trim() === '') {
        setFormErrors({ ...formErrors, email1: "Email is required" })
      }
      else if (validator.isEmail(mail) === false) {
        setFormErrors({ ...formErrors, email1: "Enter Valid Email Address" })
      }
      else {
        setFormErrors({ ...formErrors, email1: "" })
      }
      setEmail1(e.detail.value!)
    }
    if (num === 4) {
      var pass = e.detail.value!;
      if (confirmpassword !== "") {
        if (pass === confirmpassword) {
          setFormErrors({ ...formErrors, confirmpassword: "" })
        }
      }
      else if (pass.trim() === '') {
        setFormErrors({ ...formErrors, password1: "Password is required" })
      }
      else {
        setFormErrors({ ...formErrors, password1: "" })
      }
      setPassword(e.detail.value!)
    }
    if (num === 5) {
      var cpass = e.detail.value!;
      if (cpass.trim() === '') {
        setFormErrors({ ...formErrors, confirmpassword: "Confirm Password is required" })
      }
      else if (password != cpass) {
        setFormErrors({ ...formErrors, confirmpassword: "Password and confirm password are not same" })
      }
      else {
        setFormErrors({ ...formErrors, confirmpassword: "" })
      }
      setConfirmpassword(e.detail.value!)
    }
    if (num === 6) {
      var temName = e.detail.value!;
      if (user === "4" && temName === "") {
        setFormErrors({ ...formErrors, templeName: "Enter Temple Name" })
      }
      else if (user === "4" && temName.charAt(0) == " ") {
        setFormErrors({ ...formErrors, templeName: 'Empty Space at First Name' })
      }
      else if (user === "4" && namecheck.test(temName) === false) {
        setFormErrors({ ...formErrors, templeName: "Alphabets only Allowed in Temple Name" })
      }
      else {
        setFormErrors({ ...formErrors, templeName: "" })
      }
      setTName(e.detail.value!)
    }
    if (num === 7) {
      var add = e.detail.value!;
      if (user === "4" && add === "") {
        setFormErrors({ ...formErrors, address: "Enter Temple Address" })
      }
      else {
        setFormErrors({ ...formErrors, address: "" })
      }
      setTAdd(e.detail.value!)
    }
  }

  const submit = () => {
    const errors: any = {};
    if (user === "") {
      errors.user = "Choose a Role"
    }
    if (firstname1 == "") {
      errors.firstname1 = "Enter First Name"
    }
    else if (namecheck.test(firstname1) == false) {
      errors.firstname1 = 'Alphabets only Allowed in First Name'
    }
    else if (firstname1.charAt(0) == " ") {
      errors.firstname1 = 'Empty Space at First Name'
    }
    if (phone1 === '' || phone1 === undefined) {
      errors.phone1 = "Mobile Number is required"
    } else if (!isValidPhoneNumberSign) {
      errors.phone1 = "Please Enter Valid Mobile Number"
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (email1.trim() === '') {
      errors.email1 = 'Email is required';
    }
    else if (validator.isEmail(email1) === false) {
      errors.email1 = "Enter Valid Email Address";
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }
    if (password.trim() === '') {
      errors.password1 = 'Password is required';
    }
    if (confirmpassword.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (password != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    if (user === "4" && TName === "") {
      errors.templeName = "Enter Temple Name"
    }
    else if (user === "4" && namecheck.test(TName) === false) {
      errors.templeName = "Alphabets only Allowed in Temple Name"
    }
    if (user === "4" && TAdd === "") {
      errors.address = "Enter Temple Address"
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      console.log(
        // aadhar1,
        // addr,
        // pincode1,
        // area1,
        // city1,
        // state1,
        // country1,
        email1,
        firstname1,
        // lastname1,
        phone1,
        password,
        user,
        TName,
        TAdd,
        user
      )
      UserService.Registeradm(
        // aadhar1,
        // addr,
        // pincode1,
        // area1,
        // city1,
        // state1,
        // country1,
        email1,
        firstname1,
        // lastname1,
        phone1,
        password,
        user,
        TName,
        TAdd,
        user
      )
        .then((response) => {
          console.log("res", response);
          setShowLoading(false);
          if (response.responseText.startsWith("SUCCESS")) {
            if (user == "4") {
              setAlertMsg("Temple Admin Created Successfully");
              setAlertModal(true)
              setIndex(0);
              window.location.reload();
            } else {
              setAlertMsg("Project Admin Created Successfully");
              setAlertModal(true)
              setIndex(0);
              window.location.reload();
            }
            setIssuccess(true);
            setCreateUserModal(false)
            // setAadhar1("");
            // setAddr("");
            // setArea1("");
            // setCity1("");
            setConfirmpassword("");
            // setCountry1("");
            setEmail1("");
            setFirstname1("");
            // setLastname1("");
            setPassword("");
            setPhone1("");
            setUser("");
            // setPincode1("");
            window.location.reload();
          } else if (response.responseText == "ERROR:Email Id already exist") {
            setAlertMsg("Email Id Exits, Try Any Other Email Id");
            setAlertModal(true)
            setIserror(true);
          } else if (response.responseText == "ERROR:MobileNumber already exist") {
            setAlertMsg("Mobile Number Exits, Try Any Other Mobile Number");
            setAlertModal(true)
            setIserror(true);
          } else {
            setAlertMsg("User Not Register")
            setAlertModal(true)
            setIserror(true);
          }
        })
        .catch((error) => {
          console.log(error)
          setShowLoading(false);
          setAlertMsg("Something went wrong");
          setAlertModal(true)
          setIserror(true);
        });
    }

  };
  const [presentAlert] = useIonAlert();
  const columns: any = [
    {
      name: "S.No",
      selector: (item: any, i: any) => item.serial,
      sortable: true,
      width: "90px",
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => Onviewdetails(item)}>
          <div style={{ cursor: "pointer" }}>{item.serial}</div>
        </div>
      )
    },
    {
      name: "Name",
      selector: (item: any) => item.firstName,
      sortable: true,
      cell: (item: any) => (
        <div onClick={() => Onviewdetails(item)} className="left_col">
          <div style={{ cursor: "pointer" }} >{item.firstName}</div>
        </div>
      )
    },
    {
      name: "Email",
      selector: (item: any) => item.email,
      sortable: true,
      cell: (item: any) => (
        <div onClick={() => Onviewdetails(item)} className="left_col">
          <div style={{ cursor: "pointer" }} >{item.email}</div>
        </div>
      )
    },
    {
      name: "Role",
      selector: (item: any) => item.preRole,
      sortable: true,
      cell: (item: any) => {
        return (
          <div className="center_col_tab" onClick={() => Onviewdetails(item)}>
            <div style={{ cursor: "pointer" }} >{item.preRole}</div>
          </div>
        )
      }
    },
    {
      name: "Status",
      sortable: false,
      selector: (item: any) => item.uflag,
      width: "150px",
      cell: (item: any) => (
        <div className="center_col_tab">
          {item.uflag === "true" ? (
            <IonButton color="danger" className="datatable-activate-btn" onClick={() =>
              presentAlert({
                header: 'Atmagram',
                message: 'Do you want to Deactivate this User',
                buttons: [
                  {
                    text: 'No',
                    role: 'cancel',
                  },
                  {
                    text: 'Yes',
                    role: 'confirm',


                    handler: (alertData) => {
                      handleStatus(false, item.id, alertData.comment)
                    },
                  },
                ],
                inputs: [
                  {
                    name: 'comment',
                    type: 'text',
                    value: comment,
                    placeholder: 'Enter your comment'
                  }
                ]

              })

            }><span className="button_padding">Deactivate</span></IonButton>
          ) : (
            <IonButton color="success" className="datatable-activate-btn" onClick={() => presentAlert({
              header: 'Atmagram',
              message: 'Do you want to activate this User',
              buttons: [
                {
                  text: 'No',
                  role: 'cancel',
                },
                {
                  text: 'Yes',
                  role: 'confirm',
                  handler: (alertData) => {
                    handleStatus(true, item.id, alertData.comment)
                  },
                },
              ]
              ,
              inputs: [
                {
                  name: 'comment',
                  type: 'text',
                  value: comment,
                  placeholder: 'Enter your comment'
                }
              ]
            })}><span className="button_padding">Activate</span></IonButton>
          )}
        </div>
      )
    },
    // {
    //   name: "View",
    //   sortable: false,
    //   width: "100px",
    //   cell: (item: any) => (
    //     <>
    //       <IonButton onClick={() => Onviewdetails(item)} style={{ textTransform: "none" }}>View</IonButton>
    //     </>
    //   )
    // },
  ]
  const history = useHistory();
  const modal = useRef<HTMLIonModalElement>(null);
  const [iserror, setIserror] = useState(false);
  const [modals, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [usertoken]: any = useState(localStorage.getItem("token"));
  const [uFlag]: any = useState(localStorage.getItem("uFlag"))
  const userdata: any = useState(localStorage.getItem("UserData"));
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [aadhar, setAadharNumber] = useState("");
  const [activeComment, setActiveComment] = useState("");
  const [inactiveComment, setInactiveComment]: any = useState();
  const [showLoading, setShowLoading] = useState(false);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);

  const [alertmsg, setAlertMsg]: any = useState("")
  const [alertModal, setAlertModal]: any = useState(false)
  const alertRef = useRef(null);


  if (allusers != undefined && allusers.length > 0) {
    allusers.forEach((item: any, index: any) => { item.serial = index + 1; });
    allusers.forEach((item: any, index: any) => {
      if (item.role === "ROLE_PROJECTADMIN") {
        item.preRole = "Project Admin"
      }
      else if (item.role === "ROLE_USER") {
        item.preRole = "User"
      }
      else if (item.role === "ROLE_TEMPADMIN") {
        item.preRole = "Temple Admin"
      }
      else if (item.role === "ROLE_VENDOR") {
        item.preRole = "Vendor"
      }
      else {
        item.preRole = "Admin"
      }
    });
  }



  useEffect(() => {
    console.log(userdata, "jvjh")
    if (userdata[0] === null) {
      history.push("/login")
    }
    else if (userdata[0] !== "ROLE_ADMIN") {
      history.push("/home")
    }
    else if (uFlag[0] === "false") {
      history.push("/home")
    }
    else {
      showallusers();
    }

  }, []);

  const showallusers = () => {
    setShowLoading(true);

    Authservice.Showallusers(usertoken).then((response) => {
      console.log(response.data);
      setAllusers(response.data);
      setShowLoading(false);
    })
      .catch((error) => {
        presentAlert("Something went wrong, please try again later.");
        console.log(error + "net")
        setShowLoading(false);

      });
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };

  const states: State[] = [
    {
      id: 1,
      name: "Andhra Pradesh",
    },
    {
      id: 2,
      name: "Arunachal Pradesh",
    },
    {
      id: 3,
      name: "Assam",
    },
    {
      id: 4,
      name: "Bihar",
    },
    {
      id: 5,
      name: "Chhattisgarh",
    },
    {
      id: 6,
      name: "Goa",
    },
    {
      id: 7,
      name: "Gujarat",
    },
    {
      id: 8,
      name: "Haryana",
    },
    {
      id: 9,
      name: "Himachal Pradesh",
    },
    {
      id: 10,
      name: "Jharkhand",
    },
    {
      id: 11,
      name: "Karnataka",
    },

    {
      id: 12,
      name: "Kerala",
    },

    {
      id: 13,
      name: "Madhya Pradesh",
    },
    {
      id: 14,
      name: "Maharashtra",
    },

    {
      id: 14,
      name: "Manipur",
    },

    {
      id: 15,
      name: "Meghalaya",
    },

    {
      id: 16,
      name: "Mizoram",
    },

    {
      id: 17,
      name: "Nagaland",
    },

    {
      id: 18,
      name: "Odisha",
    },
    {
      id: 19,
      name: "Punjab",
    },
    {
      id: 20,
      name: "Rajasthan",
    },
    {
      id: 21,
      name: "Sikkim",
    },
    {
      id: 22,
      name: "Tamil Nadu",
    },
    {
      id: 23,
      name: "Telengana",
    },
    {
      id: 24,
      name: "Tripura",
    },
    {
      id: 23,
      name: "Uttarkhand",
    },
    {
      id: 24,
      name: "Uttar Pradesh",
    },
    {
      id: 25,
      name: "West Bengal",
    },
    {
      id: 26,
      name: "Andaman and Nicobar Islands",
    },
    {
      id: 27,
      name: "Chandigarh",
    },
    {
      id: 28,
      name: "The Government of NCT of Delhi",
    },
    {
      id: 29,
      name: "Dadra and Nagar Haveli and Daman & Diu",
    },
    {
      id: 30,
      name: "Ladakh",
    },
    {
      id: 31,
      name: "Jammu & Kashmir",
    },
    {
      id: 32,
      name: "Lakshadweep",
    },

    {
      id: 33,
      name: "Puducherry",
    },
  ];

  const [flag, setFlag] = useState("")

  const Onviewdetails = (item: any) => {
    var number: any
    if (item.mobileNumber !== null) {
      if (!item.mobileNumber.startsWith("+")) {
        number = Authservice.formatPhone("+91" + item.mobileNumber)
      } else {
        number = Authservice.formatPhone(item.mobileNumber)
      }
    }

    console.log(number, item, "jnjn");
    setFlag(item.uflag)
    setFirstname(item.firstName)
    setLastname(item.lastName)
    setRole(item.preRole)
    setPhone(number)
    setArea(item.area)
    setCity(item.city)
    setState(item.state)
    setCountry(item.country)
    setEmail(item.email)
    setAddress(item.address)
    setAadharNumber(item.aadharNumber)
    setActiveComment(item.activeComment);
    setInactiveComment(item.inactiveComment);
    setModal(true)

  };
  const addmodal = () => {
    setCreateUserModal(true)
    setFormErrors({})
  }

  const handleStatus = (status: any, id: any, comm: any) => {
    setShowLoading(true)
    Authservice.statusAdmUser(usertoken, status, id, comm).then((response) => {
      console.log(response)
      // alert("User Activated")
      window.location.reload()
      setShowLoading(true)
    })
      .catch((error) => {
        presentAlert("Something went wrong, please try again later.");
        setShowLoading(true)
      });

  }
  var tableData: any = [];
  if (allusers != undefined && allusers.length > 0) {
    tableData = allusers.filter((item: any) => {
      const serializedItem = JSON.stringify(item).toLowerCase();
      const searchTerm = filterText.toLowerCase();

      var regex: any;

      if (/\+/.test(searchTerm)) {
        const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        regex = new RegExp(escapedSearchTerm);
      } else {
        regex = new RegExp(`\\b${searchTerm}\\b`);
      }

      return regex.test(serializedItem);
    });
  }


  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <IonRow className="table_btn_container">
        <div>
          <IonButton onClick={addmodal} className="admin_add_btn">
            <IonIcon src={addOutline} />
            <span className="button_padding">Add User</span>
          </IonButton>
        </div>
        <div>
          <IonSearchbar
            value={filterText}
            onIonChange={(e: any) => setFilterText(e.detail.value)}
            placeholder="Search"
            className="table_search"
          />
        </div>
      </IonRow>
    );
  }, [filterText, resetPaginationToggle]);
  const conditionalRowStyles: any[] = [
    {
      when: (row: any, rowIndex: any) => rowIndex % 2 === 0,
      style: (row: any, rowIndex: any) => ({
        background: rowIndex % 2 === 0 ? '#f2f2f2' : 'inherit',
      }),
    },
  ];


  const useOutsideAlerter = (refer: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (refer.current && !refer.current.contains(event.target)) {
          setModal(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [refer]);
  }
  const usermodal = useRef(null);
  useOutsideAlerter(usermodal);




  const CloseCreateModal = () => {
    setCreateUserModal(false)
    setFormErrors({})
    setUser("")
    setFirstname1("")
    setPhone1("")
    setEmail1("")
    setPassword("")
    setConfirmpassword("")
    setTName("")
    setTAdd("")
  }

  return (
    <IonPage>
      <AdminHead User={"user"} Role={"Role"} Name={"User Management"} />
      <Sidebar />
      <IonContent>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        />
        <IonRow style={{ position: "absolute" }}>
          <IonCol>
            <IonAlert
              isOpen={iserror}
              onDidDismiss={() => setIserror(false)}
              cssClass="my-custom-class"
              header={"Error!"}
              message={message}
              buttons={["Dismiss"]}
            />
          </IonCol>
        </IonRow>
        <Modal show={alertModal} centered style={{ width: "40%", left: "30%" }}>
          <Modal.Body ref={alertRef}>
            <div style={{ textAlign: "center" }}><div>
              <IonText> {alertmsg}</IonText>
            </div><br></br>
              <div>
                <IonButton className="admin_add_btn">
                  <span onClick={() => { setAlertModal(false) }} className="button_padding">ok</span>

                </IonButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={modals} className="admin_modal">
          <Modal.Body ref={usermodal}>
            <div className="donation-modal-header admin-user-modal-header">
              <h5 className="donation-modal-title">User Information</h5>
              <div>
                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModal(false)} />
              </div>
            </div>
            <div className="admin_signup_modal">
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    First Name
                  </IonLabel>
                  <IonInput
                    value={firstname}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Last Name
                  </IonLabel>
                  <IonInput
                    value={lastname}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Role
                  </IonLabel>
                  <IonInput
                    value={role}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Mobile Number
                  </IonLabel>
                  <IonInput
                    value={phone}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Area
                  </IonLabel>
                  <IonInput
                    value={area}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    City
                  </IonLabel>
                  <IonInput
                    value={city}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    State
                  </IonLabel>
                  <IonInput
                    value={state}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Country
                  </IonLabel>
                  <IonInput
                    value={country}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Email
                  </IonLabel>
                  <IonInput
                    value={email}
                    readonly
                  ></IonInput>
                </IonCol>
                <IonCol size-xs="12" size-lg="6" size-md="6">
                  <IonLabel className="label_align">
                    Address
                  </IonLabel>
                  <IonInput
                    value={address}
                    readonly
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                {aadhar === null ? <div></div> :
                  <IonCol size-xs="12" size-lg="6" size-md="6">
                    <IonLabel className="label_align">
                      Aadhaar Number
                    </IonLabel>
                    <IonInput
                      value={aadhar}
                      readonly
                    ></IonInput>
                  </IonCol>
                }
                {flag === "true" ? <>
                </> : <>
                  {inactiveComment === null ? <div></div> :
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <div>
                        <IonLabel className="label_align">
                          Comment
                        </IonLabel>
                        <IonInput
                          value={inactiveComment}
                          readonly
                        ></IonInput>
                      </div>
                    </IonCol>
                  }
                </>
                }
              </IonRow>
              <IonRow style={{ marginTop: '10px' }}>
                <IonCol size="12" style={{ textAlign: "center" }}>
                  <IonButton onClick={(e) => setModal(false)} className="admin_add_btn"><span className="button_padding">Close</span></IonButton>
                </IonCol>
              </IonRow>
            </div>
          </Modal.Body>
        </Modal>
        <div >
          {index == 0 ? <div className="container_middle_table page-wrapper admin_background_color">
            <DataTable
              columns={columns}
              data={tableData}
              defaultSortFieldId="name"
              striped
              noDataComponent="Sorry ! No result found"
              pagination
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
              subHeader
              subHeaderComponent={subHeaderComponent}
              onRowClicked={(item: any) => Onviewdetails(item)}
              customStyles={customTableStyle}
            />
          </div> : index == 1 ? <></>
            : <></>}
          <Modal show={createUserModal} className="admin_modal">
            <Modal.Body>
              <div className="donation-modal-header admin-user-modal-header">
                <h5 className="donation-modal-title">Create User</h5>
                <div>
                  <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => CloseCreateModal()} />
                </div>
              </div>
              <div>
                <div className="admin_signup_modal">
                  <IonRow>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <div>
                        <IonLabel className="label_align">
                          Choose a Role
                          <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <select
                          value={user}
                          onBlur={() => validation(1)}
                          style={{ borderWidth: "0px", width: "100%", height: "100%", outline: "0px" }}
                          onChange={(e: any) => onchangevalidation(e, 1)}
                          className="select_user_admin"
                        >
                          <option disabled selected value="">Choose a Role</option>
                          <option value="4">Temple Admin</option>
                          <option value="5">
                            Project Admin
                          </option>
                        </select>
                      </div>
                      {formErrors.user && <span className="alert_input_bottom ">{formErrors.user}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <div>
                        <IonLabel className="label_align">
                          First Name
                          <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="text"
                          required
                          value={firstname1}
                          onBlur={() => validation(2)}
                          onIonChange={(e) => onchangevalidation(e, 2)}
                        ></IonInput>
                      </div>
                      {formErrors.firstName1 && <span className="alert_input_bottom ">{formErrors.firstName1}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <div >
                        <IonLabel className="label_align">
                          Mobile Number <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          onBlur={() => validation(3)}
                          value={phone1}
                          className="signUpNum"
                          ref={phoneInputRef}
                          onChange={(value: any) => moveCursorToEnd(value)}
                        />
                      </div>
                      {formErrors.phone1 && <span className="alert_input_bottom ">{formErrors.phone1}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Email <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="email"
                        value={email1}
                        required
                        onBlur={() => validation(4)}
                        onIonChange={(e) => onchangevalidation(e, 3)}
                      ></IonInput>
                      {formErrors.email1 && <span className="alert_input_bottom ">{formErrors.email1}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Password <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        value={password}
                        required
                        clearOnEdit={false}
                        onBlur={() => validation(5)}
                        onIonChange={(e) => onchangevalidation(e, 4)}
                      ></IonInput>
                      {formErrors.password1 && <span className="alert_input_bottom">{formErrors.password1}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        clearOnEdit={false}
                        value={confirmpassword}
                        required
                        onBlur={() => validation(6)}
                        onIonChange={(e) => onchangevalidation(e, 5)}
                      ></IonInput>
                      {formErrors.confirmpassword && <span className="alert_input_bottom ">{formErrors.confirmpassword}</span>}
                    </IonCol>
                  </IonRow>
                  {user == 4 ? <IonRow>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Temple Name <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        value={TName}
                        required
                        onBlur={() => validation(7)}
                        onIonChange={(e) => onchangevalidation(e, 6)}
                      ></IonInput>
                      {formErrors.templeName && <span className="alert_input_bottom ">{formErrors.templeName}</span>}
                    </IonCol>
                    <IonCol size-xs="12" size-lg="6" size-md="6">
                      <IonLabel className="label_align">
                        Temple Address <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        value={TAdd}
                        required
                        onBlur={() => validation(8)}
                        onIonChange={(e) => onchangevalidation(e, 7)}
                      ></IonInput>
                      {formErrors.address && <span className="alert_input_bottom ">{formErrors.address}</span>}
                    </IonCol>
                  </IonRow> : <></>}
                  <IonRow>
                    <IonCol size="12" size-lg="12" style={{ display: "flex", justifyContent: "center" }}>
                      <div className={email1.length === 0 ||
                        password.length === 0 ||
                        confirmpassword.length === 0 ||
                        user == "" || user === "4" && TName === "" || user === "4" && TAdd === "" ? "disableCursor" : ""}>
                        <IonButton
                          className="admin_add_btn"
                          type="submit"
                          onClick={submit}
                          disabled={
                            email1.length === 0 ||
                            password.length === 0 ||
                            confirmpassword.length === 0 ||
                            user == "" || user === "4" && TName === "" || user === "4" && TAdd === ""
                          }
                        >
                          <span className="button_padding">Create User</span>
                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        {/* <Foot /> */}
      </IonContent>
    </IonPage>
  );
};
export default Adminpage;
