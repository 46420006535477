import React from "react";
import axios from "axios";
import { Alert } from "react-bootstrap";

//const API_URL = "https://groupnpay.com/vedagram/";
const API_URL = "https://www.atmagram.com/";
//const API_URL = "http://uat.atmagram.com/";
//const API_URL = "http://4.224.33.212:8080/vedagram/";

// const[alert]=useIonAlert();
//const API_URL = "https://demo.infocareerindia.com/vedagram/";

const Login = (email: any, password: any) => {
  const loginData = {
    email: email,
    password: password,
  };

  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("user/loginValidate", loginData)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {

      alert("Something went wrong, please try again later.");
      return error;

    });
};
const logout = (token: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return api
    .get("user/logout")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {

      alert("Something went wrong, please try again later.");
      return error;

    });
};

const Register = (
  // aadhar: any,
  // address: any,
  // pincode:any,
  // area: any,
  // city: any,
  // state: any,
  // country: any,
  email: any,
  firstname: any,
  // lastname: any,
  phone: any,
  password: any,
  users: any,
  product: any,
  TName: any,
  TAdd: any,
  vendor: any,
  user: any
) => {
  console.log("userrrrr", user)
  var loginData = {}
  if (user == "3") {
    loginData = {
      // aadharNumber: aadhar,
      // address: address +','+ pincode,
      // area: area,
      // city: city,
      // state: state,
      // country: country,
      email: email,
      firstName: firstname,
      // lastName: lastname,
      mobileNumber: phone,
      password: password,
      roleId: users,
      productType: product,
      vendorName: vendor
    }
  }
  else if (user == "4") {
    loginData = {
      // aadharNumber: aadhar,
      // address: address + ',' + pincode,
      // area: area,
      // city: city,
      // state: state,
      // country: country,
      email: email,
      firstName: firstname,
      // lastName: lastname,
      mobileNumber: phone,
      password: password,
      roleId: users,
      templeName: TName,
      templeAddress: TAdd
    }
  }
  else {
    loginData = {
      // aadharNumber: aadhar,
      // address: address + ',' + pincode,
      // area: area,
      // city: city,
      // state: state,
      // country: country,
      email: email,
      firstName: firstname,
      // lastName: lastname,
      mobileNumber: phone,
      password: password,
      roleId: users,
    }
  }

  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("user/register", loginData)
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const Getallgods = (islogin: any, token: any) => {
  var api: any = "";
  if (islogin) {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    api = axios.create({
      baseURL: API_URL,
    });
  }

  return api
    .get("u/showAllDeity")
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
};

const Forgotpassword = (email: any) => {
  const logindata = {
    email: email,
  };

  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/user/forgotpassword", logindata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
};

const ChangePasswordByTkn = (password: any, email: any, token: any, confirmpassword: any) => {
  const logindata = {
    password: password,
    email: email,
    confirmPassword: confirmpassword,
    resetPwdToken: token
  };

  const api = axios.create({
    baseURL: API_URL,

  });
  return api
    .post("/user/changePasswordByTkn", logindata)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
};

const IsvaildToken = (token: any) => {


  const api = axios.create({
    baseURL: API_URL,
  });

  return api
    .get("user/" + token + "/pwdtkn")
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}
const SearchTemple = (usertoken: any, country: any, state: any, district: any, town: any, deityId: any, tempName: any) => {
  var body: any;
  if (tempName != "" && tempName != null && tempName != undefined) {
    body = {

      deityId: deityId,
      templeName: tempName
    };
  } else {
    body = {
      country: country,
      state: state,
      district: district,
      villageOrTown: town,
      deityId: deityId
    };
  }



  console.log(usertoken)

  if (usertoken === null) {
    const api = axios.create({
      baseURL: API_URL,
    });
    return api
      .post("/u/searchTemple", body)
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
  } else {
    const api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });
    return api
      .post("/u/searchTemple", body)
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
  }


}
const Templedetails = (id: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/tempadm/viewTemple/?templeId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
};
const ShowAllOrder = (usertoken: any) => {
  console.log("kjg", usertoken)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showAllOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Registeradm = (
  // aadhar: any,
  // address: any,
  // pincode:any,
  // area: any,
  // city: any,
  // state: any,
  // country: any,
  email: any,
  firstname: any,
  // lastname: any,
  phone: any,
  password: any,
  users: any,
  TName: any,
  TAdd: any,
  user: any
) => {
  console.log("userrrrr", user)
  var loginData = {}
  if (user == "4") {
    loginData = {
      // aadharNumber: aadhar,
      // address: address +','+pincode,
      // area: area,
      // city: city,
      // state: state,
      // country: country,
      email: email,
      firstName: firstname,
      // lastName: lastname,
      mobileNumber: phone,
      password: password,
      roleId: users,
      templeName: TName,
      templeAddress: TAdd
    }
  }
  else {
    loginData = {
      // aadharNumber: aadhar,
      // address: address,
      // area: area,
      // city: city,
      // state: state,
      // country: country,
      email: email,
      firstName: firstname,
      // lastName: lastname,
      mobileNumber: phone,
      password: password,
      roleId: users,
    }
  }

  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("user/register", loginData)
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const Registermaterials = (
  // aadhar: any,
  // address: any,
  // pincode:any,
  // area: any,
  // city: any,
  // state: any,
  // country: any,
  email: any,
  firstname: any,
  // lastname: any,
  phone: any,
  password: any,
  users: any,
  user: any
) => {
  console.log("userrrrr", user)
  var loginData = {}
  if (user == "2") {
    loginData = {
      // aadharNumber: aadhar,
      // address: address+','+pincode,
      // area: area,
      // city: city,
      // state: state,
      // country: country,
      email: email,
      firstName: firstname,
      // lastName: lastname,
      mobileNumber: phone,
      password: password,
      roleId: users,
    }
  }

  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("user/register", loginData)
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
const validMob = (mobile: any) => {
  mobile = encodeURIComponent(mobile)


  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/user/verifyMobileNumber?mobileNumber=" + mobile)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
const mobileLogin = (mobile: any) => {
  mobile = encodeURIComponent(mobile)


  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/user/loginViaMoblieNumber?mobileNumber=" + mobile)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const UserService = {
  Login,
  Register,
  Getallgods,
  Forgotpassword,
  ChangePasswordByTkn,
  IsvaildToken,
  SearchTemple,
  Templedetails,
  ShowAllOrder,
  Registeradm,
  Registermaterials,
  validMob,
  mobileLogin,
  logout

};
export default UserService;