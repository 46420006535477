import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import { useHistory } from "react-router";
import Head from "./Head";
import { Capacitor } from "@capacitor/core";
import HeadFoot from "./Head";

const TourPackages: React.FC = () => {

  const userdata: any = useState(localStorage.getItem("UserData"));
  const [token, settoken]: any = useState(localStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Cancellation Policy"} />
        <IonRow className="page_content_padding">
          <IonCol className="">
            <div style={{ margin: '2% 4%' }}>
              <div className="ion-text-center">
                <h6 style={{ fontSize:"20px" }}>You can cancel items or orders by visiting the My Orders section in Your Account.</h6>
              </div>
             <br/>
              <div className="">
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>To cancel orders that are not shipped yet:</h6>
              </div>
              <div className="">
                <p className="policy_text">1. Go to <a href="#/mygrammerce" style={{textDecoration:"none"}}>My Orders</a></p>
                <p className="policy_text">2. Select the item you want to cancel and click Cancel items</p>
                <p className="policy_text">3. Provide reasons for cancellation (optional) and proceed</p>
              </div>
              <div style={{paddingTop:"25px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>To cancel orders that are already shipped:</h6>
              </div>
              <div className="">
                <p className="policy_text">1. Go to <a href="#/mygrammerce" style={{textDecoration:"none"}}>My Orders</a></p>
                <p className="policy_text">2. Select the Request cancellation option and proceed further</p>
                <p className="policy_text">3. The item(s) will be returned to us for a refund (if the payment is already made)
In case you're still contacted for delivery, please refuse to accept it.</p>
              </div>
              <div style={{paddingTop:"25px"}}>
                <h6 style={{ fontWeight: 'bold', textAlign: "left" }}>Note:</h6>
              </div>
              <div className="">
                <p className="policy_text">1. If your order is combined with other active orders from your account and is shipped as a single shipment (common tracking number), then cancelling one order/item would result in cancellation of all the other orders/items combined in the shipment.</p>
                <p className="policy_text">2. International customers cannot cancel an export order once it is shipped. However, they may refuse delivery of the product(s) and contact us to get a refund.</p>
              </div>
              </div>
              
          </IonCol>
        </IonRow>

        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default TourPackages;