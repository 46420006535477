import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { Plugins } from '@capacitor/core';
import { IonAlert } from '@ionic/react';
import UserService from '../Services/auth.service';
import Authservice from '../Services/user.service';

interface AppContextProps {
  token: any;
  setToken: React.Dispatch<React.SetStateAction<any>>;
  uFlag: any;
  setUFlag: React.Dispatch<React.SetStateAction<any>>;
  userdata: any;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  shareurl: any;
  amountLimit: any;
}

interface AppProviderProps {
  children: ReactNode;
}
const { Network } = Plugins;

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [uFlag, setUFlag] = useState(localStorage.getItem("uFlag"));
  const [userdata, setUserData] = useState(localStorage.getItem("UserData"));
  const [showAlert, setShowAlert] = useState(false);
  var url = Authservice.getApiUrl();
  const shareurl = url + "#/projId"
  const amountLimit = 500000;

  // Function to handle closing the alert
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    // const checkNetworkStatus = async () => {
    //   const status = await Network.getStatus();
    //   console.log('Initial network status:', status.connected ? 'online' : 'offline');
    //   if (status.connected) {
    //     setShowAlert(false)
    //     console.log('Network is connected');
    //   } else {
    //     //alert('Network is disconnected');
    //     if(!showAlert){
    //       setShowAlert(true)
    //     }

    //   }
    // };
  
    // checkNetworkStatus();


    // const networkStatusChangeListener = Network.addListener(
    //   'networkStatusChange',
    //   (status: any) => {
    //     if (status.connected) {
    //       setShowAlert(false)
    //       console.log('Network is connected');  
    //     } else {
    //       //alert('Network is disconnected');
    //       if(!showAlert){
    //         setShowAlert(true)
    //       }

    //     }

    //     console.log('Connection type:', status.connectionType);
    //   }
    // );

    // return () => {
    //   networkStatusChangeListener.remove();
    // };

    
  }, []);


  return (
    <AppContext.Provider value={{ token, setToken, uFlag, setUFlag, userdata, setUserData, shareurl, amountLimit }}>
      <div>
        {/* Render the IonAlert component */}
        <IonAlert
          isOpen={showAlert}
          //onDidDismiss={handleCloseAlert}
          header="Alert"
          message="Network is disconnected, Please connect with internet otherwise you can't proceed further."
          backdropDismiss={false}
        //buttons={['OK']}
        />
      </div>
      {children}
    </AppContext.Provider>
  );
};
