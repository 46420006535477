import React, { useEffect, useState, useRef, useMemo } from "react";
import {
    IonContent,
    IonItem,
    IonPage,
    IonButton,
    IonItemDivider,
    IonRow,
    IonCol,
    IonInput,
    IonAlert,
    IonLabel,
    IonModal,
    IonText,
    IonLoading,
    useIonAlert,
    IonGrid,
    IonTextarea,
    IonIcon,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    IonImg,
} from "@ionic/react";
import axios from "axios";
import "../Userpage.css";
import "../Home.css";
import Head from "../Head";
import Foot from "../Foot";
import UserService from "../../Services/user.service";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component"
import { Modal } from "react-bootstrap";
import Authservice from "../../Services/user.service";
import { Capacitor } from "@capacitor/core";
import { addCircleSharp, heart, informationCircle, timeOutline, arrowBack, arrowForward, calendarOutline, addOutline, informationCircleSharp, close, arrowBackCircleOutline, arrowForwardCircleOutline, } from "ionicons/icons";
import Slider from "react-slick";
import moment from "moment";
import Select, { components } from 'react-select';
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import { clock1, heart2 } from "../../theme/imagePath";


const ProjMan: React.FC = () => {
    const [projAdm, setProjAdm] = useState();
    const [projAdmList, setProjAdmList]: any = useState([]);
    const dataFetchedRef = useRef(false);
    const [presentAlert] = useIonAlert();
    const [comment, setComment] = useState("")
    const [proLoad, setProLoad]: any = useState(false);
    const [endTrue, setEndTrue] = useState(false)
    const today: any = new Date()

    const NextArrow = (props: any) => {
        const { className, onClick } = props;
        return (
            <div className="slick_nxt" onClick={onClick}  >
                <IonIcon icon={arrowForwardCircleOutline} />
            </div>
        );
    };

    const PrevArrow = (props: any) => {
        const { className, onClick } = props;
        return (
            <div className="slick_prev" onClick={onClick}>
                <IonIcon icon={arrowBackCircleOutline} />
            </div>
        );
    };
    const [usertoken] = useState(localStorage.getItem("token"));
    const userdata: any = useState(localStorage.getItem("UserData"));
    const uFlag: any = useState(localStorage.getItem("uFlag"));
    const [index, setIndex] = useState(0);
    const [modalopen, setModalopen] = useState(false);
    const [purposemodal, setPurposemodal] = useState(false);
    const [projecttitle, setProjecttitle] = useState("");
    const [purpose, setpurpose] = useState("");
    const [targetamt, setTargetamt] = useState("");
    const [startdate, setStartdate] = useState("");
    const [modstartdate, setModStartdate] = useState("");
    const [modenddate, setModenddate] = useState("");
    const [enddate, setEnddate]: any = useState("");
    const [minamt, setMinamt] = useState("");
    const [maxamt, setmaxamt] = useState("");
    const [show, setShow]: any = useState([]);
    const [pur, setPur] = useState("")
    const [showLoading, setShowLoading] = useState(false);
    const [updateBtn, setUpdateBtn] = useState(false)
    const [id, setId] = useState("")
    const [formErrors, setFormErrors]: any = useState({});
    const formatter = new Intl.NumberFormat('en-IN')
    const [alertmsg, setAlertMsg]: any = useState("")
    const [alertModal, setAlertModal]: any = useState(false)
    const alertRef = useRef(null);

    var Settings = {}
    if (show != undefined && show.length >= 2) {
        Settings = {
            dots: false,
            horizontal: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        }
    }
    else if (show != undefined && show.length < 2) {
        Settings = {
            dots: false,
            horizontal: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        }
    }
    const handleStatus = (flag: any, id: any, comment: any) => {
        Authservice.statusAdmproj(usertoken, flag, id, comment).then((response) => {
            console.log(response)
            //window.location.reload()
            chooseVenAdms(projAdm)
        })
            .catch((error) => {
                console.log("Something went wrong, please try again later.");
            });
    }
    const Clear = () => {
        setUpdateBtn(false)
        setProjecttitle("");
        setpurpose("");
        setTargetamt("");
        setStartdate("");
        setEnddate("");
        setModenddate("");
        setModStartdate("");
        setmaxamt("");
        setMinamt("");
        setFormErrors({})
        setModalopen(true);
    }
    const editProject = (item: any) => {
        setUpdateBtn(true)
        setProjecttitle(item.projectTitle);
        setpurpose(item.purpose);
        setTargetamt(item.targetAmount);
        var end = item.endDate.split("-")
        setModenddate(end[2] + "-" + end[1] + "-" + end[0])
        var start = item.startDate.split("-")
        setEndTrue(new Date(end[2] + "-" + end[1] + "-" + end[0]) < today)
        setModStartdate(start[2] + "-" + start[1] + "-" + start[0])
        setStartdate(item.startDate);
        setEnddate(item.endDate);
        setmaxamt(item.maxAmountDonate);
        setMinamt(item.minAmountDonate);
        setId(item.id)
        setFormErrors({})
        setModalopen(true);
    }
    const Add = () => {
        Authservice.Addproject(usertoken, projecttitle, purpose, targetamt, startdate, enddate, minamt, maxamt, projAdm).then((response) => {

            setModalopen(false);
            setAlertMsg("Project created successfully");
            setAlertModal(true)
            //window.location.reload();
            chooseVenAdms(projAdm)
        })
    }
    const chooseVenAdms = (e: any) => {
        setProjAdm(e);
        setShowLoading(true);
        setShow([]); setProLoad(false)
        Authservice.Showallprojectadm(usertoken, e).then((response) => {
            console.log(response, "vfhb")
            if (response.data && response.data.length > 0)
                setShow(response.data);
            setProLoad(true)
            setShowLoading(false);
        })

    }
    const Update = () => {
        Authservice.Editproject(usertoken, id, projecttitle, purpose, targetamt, startdate, enddate, minamt, maxamt, projAdm).then((response) => {
            console.log(response.data, "jhvf");
            setModalopen(false);
            setAlertMsg("Project Updated Successfully!");
            setAlertModal(true)
            // window.location.reload();
            chooseVenAdms(projAdm)
        })
    }

    const handleIconClick = (event: any) => {
        console.log('Icon clicked!');
        event.stopPropagation();  // Stop the event from propagating to the parent div
    };

    const datelimit = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    const datelimit1 = () => {
        const today = new Date(modstartdate);
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const history = useHistory()

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_ADMIN") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        // showProjAdmList();
        else {
            getStates()

        }
    }, [])


    const getStates = () => {
        setShowLoading(true)
        Authservice.getAllstates()
            .then((response) => {
                console.log(response)
                setStates(response.data)
                setShowLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setShowLoading(false)
            })
    }

    const showProjAdmList = () => {
        setShowLoading(true);
        setProjAdmList([]);
        Authservice.projAdmList(usertoken)
            .then((response) => {
                console.log(response.data);
                var list: any = response.data;
                for (let i = 0; i < list.length; i++) {
                    setProjAdmList((projAdmList: any) => [...projAdmList, list[i]])
                    //projAdmList.push(list[i]);
                }
                setShowLoading(false);
            })
            .catch((error) => {
                presentAlert("Something went wrong, please try again later.");
                setShowLoading(false);
            });
    }
    const purmodal = (item: any) => {
        setPur(item.purpose)
        setPurposemodal(true)
    }
    const format = (e: any) => {
        console.log(typeof e.detail.value)
        setModStartdate(e.detail.value!)
        var string = e.detail.value.split('T');
        var str = string[0];
        setStartdate(moment(str).format("DD-MM-YYYY"))
        console.log(str)
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, startDate: "" })
        }
    }
    const eformat = (e: any) => {
        setModenddate(e.detail.value!)
        var string = e.detail.value.split('T');
        var str = string[0];
        setEnddate(moment(str).format("DD-MM-YYYY"))
        setEndTrue(false)
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, endDate: "" })
        }
    }

    const useOutsideAlerter = (purposeref: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (purposeref.current && !purposeref.current.contains(event.target)) {
                    setPurposemodal(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [purposeref]);
    }

    const purposemodal1 = useRef(null);


    useOutsideAlerter(purposemodal1);

    const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(null);

    const handleChange = (selectedOption: any) => {
        setSelectedOption(selectedOption);
        setProjAdm(selectedOption.value);
        // setProjAdm(e);
        setShowLoading(true);
        setShow([]);
        setProLoad(false)
        Authservice.Showallprojectadm(usertoken, selectedOption.value).then((response) => {
            console.log(response, "vfhb")
            setShow(response.data);
            setProLoad(true)
            setShowLoading(false);
        })

        // showmaterialoreder(selectedOption.value);    
        console.log(selectedOption, "kmkkk")
    };

    const projectAdmListOptions = projAdmList
        .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
        .map((tempAdm: any, index: any) => ({
            value: tempAdm.id,
            label: tempAdm.firstName
        }));

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const [states, setStates] = useState([]);

    const [stateOption, setStateOption]: any = useState();

    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [formerror, setformerror]: any = useState({});
    var stateLiistOptions: any = [];
    if (states != undefined && states.length > 0) {
        stateLiistOptions = states.map((e: any, index: any) => ({
            value: e,
            label: e
        }));
    }
    const preventdata = (e: any) => {
        e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
        e.target.addEventListener('keydown', function (u: any) {
            if (u.which === 38 || u.which === 40) {
                u.preventDefault();
            }
        })
    }
    const validation = (e: any) => {
        if (e == 1) {
            if (projecttitle.length === 0) {
                setFormErrors({ ...formErrors, projectTitle: "Please enter title of the project" })
            }
        }
        if (e == 2) {
            if (purpose.length === 0) {
                setFormErrors({ ...formErrors, purpose: "Please enter the purpose" })
            }
        }
        if (e == 3) {
            if (targetamt.length === 0 || parseInt(targetamt) <= 0) {
                setFormErrors({ ...formErrors, tarAmt: "Please enter the target amount" })
            }
        }
        if (e == 4) {
            if (minamt.length === 0 || parseInt(minamt) <= 0) {
                setFormErrors({ ...formErrors, minAmt: "Please enter the minimum amount" })
            }
        }
        if (e == 5) {
            if (maxamt.length === 0 || parseInt(maxamt) <= 0) {
                setFormErrors({ ...formErrors, maxAmt: "Please enter the maximum amount" })
            }
        }
        if (e == 6) {
            if (modstartdate.length === 0) {
                setFormErrors({ ...formErrors, startDate: "Please enter the start date" })
            }
        }
        if (e == 7) {
            if (modenddate.length === 0) {
                setFormErrors({ ...formErrors, endDate: "Please enter the end date" })
            }
        }
        if (e == 8) {
            console.log(state)
            if (state.length === 0) {
                setformerror({ ...formerror, state: "Please select the state" })
            }
        }
        // if (e == 5) {
        //     if (startdate.length) {
        //         errors.maxAmt = 'Please enter the maximum amount';
        //     }
        // }
        // setFormErrors(errors)
    }

    const tarAmtChange = (e: any) => {
        if (e.detail.value !== "") {
            if (minamt.length > 0 && e.detail.value < parseInt(minamt)) {
                setFormErrors({ ...formErrors, tarAmt: "The target amount must be greater than the minimum amount" })
            } else if (maxamt.length > 0 && e.detail.value < parseInt(maxamt)) {
                setFormErrors({ ...formErrors, tarAmt: "The target amount must be greater than the maximum amount" })
            } else {
                setFormErrors({ ...formErrors, tarAmt: "" })
            }
        } else {
            setFormErrors({ ...formErrors, tarAmt: "Please enter the target amount" })
        }
        setTargetamt(e.detail.value!)
    }
    const minAmtChange = (e: any) => {
        if (e.detail.value !== "") {
            if (minamt.length > 0 && e.detail.value > parseInt(targetamt)) {
                setFormErrors({ ...formErrors, minAmt: "The minimum amount must be less than the target amount" })
            } else if (maxamt.length > 0 && e.detail.value > parseInt(maxamt)) {
                setFormErrors({ ...formErrors, minAmt: "The minimum amount must be less than the maximum amount" })
            } else {
                setFormErrors({ ...formErrors, minAmt: "" })
            }
        } else {
            setFormErrors({ ...formErrors, minAmt: "Please enter the minimum amount" })
        }
        setMinamt(e.detail.value!)
    }
    const maxamtChangee = (e: any) => {
        if (e.detail.value !== "") {
            if (maxamt.length > 0 && e.detail.value > parseInt(targetamt)) {
                setFormErrors({ ...formErrors, maxAmt: "The maximum amount must be less than the target amount" })
            } else if (minamt.length > 0 && e.detail.value < parseInt(minamt)) {
                setFormErrors({ ...formErrors, maxAmt: "The maximum amount must be greater than the minimum amount" })
            } else {
                setFormErrors({ ...formErrors, maxAmt: "" })
            }
        } else {
            setFormErrors({ ...formErrors, maxAmt: "Please enter the minimum amount" })
        }
        setmaxamt(e.detail.value!)
    }
    const projecttitleChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, projectTitle: "" })
        }
        setProjecttitle(e.detail.value!)
    }
    const purposeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, purpose: "" })
        }
        setpurpose(e.detail.value!)
    }
    const handleStateChange = (e: any) => {
        setStateOption(e);
        if (e === null) {
            setState("");
        } else {
            setState(e.value);
            setformerror({ ...formerror, state: "" })
        }
    }

    const handleSearch = () => {
        const errors: any = {};
        if (state.length === 0) {
            errors.state = 'Please Select a State';
        }
        setformerror(errors);

        if (Object.keys(errors).length === 0) {
            // setNameView(true)
            setSelectedOption(null)
            setShowLoading(true)
            setProjAdmList([])
            setShow([])
            Authservice.getProjectAdminList(usertoken, state, city)
                .then((response) => {
                    console.log(response, "jnjj")
                    if (response.data.length != 0) {
                        var list: any = response.data;
                        setProjAdmList(list)
                        // for (let i = 0; i < list.length; i++) {
                        //     venAdmList.push(list[i]);
                        // }
                    }
                    else {
                        presentAlert("No Results Found !")
                    }
                    setShowLoading(false);
                })
                .catch((error) => {
                    console.log(error)
                    setShowLoading(false);
                })
        }
    }

    const dateInputRef: any = useRef(null)
    const endDateInputRef: any = useRef(null)

    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Project Management"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <Modal show={alertModal} centered style={{ width: "40%", left: "30%" }}>
                    <Modal.Body ref={alertRef}>
                        <div style={{ textAlign: "center" }}><div>
                            <IonText> {alertmsg}</IonText>
                        </div><br></br>
                            <div>
                                <IonButton className="admin_add_btn">
                                    <span onClick={() => { setAlertModal(false) }} className="button_padding">ok</span>

                                </IonButton>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={modalopen} className="admin_modal">
                    <Modal.Body >
                        <div className="donation-modal-header admin-user-modal-header">
                            {updateBtn == true ? <h5 className="donation-modal-title">Edit Project</h5> : <h5 className="donation-modal-title">Create New Project</h5>}
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
                            </div>
                        </div>
                        <div className="create-modal-project-container">
                            <IonGrid>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeSm="6" style={{ padding: "0" }}>
                                        <IonRow>
                                            <IonCol sizeXs="12">
                                                <IonLabel className="label_align">Project Title <span style={{ color: "red" }}>*</span></IonLabel>
                                                <div>
                                                    {updateBtn == true ? <IonInput onIonChange={(e) => setProjecttitle(e.detail.value!)} readonly value={projecttitle} /> :
                                                        <IonInput onBlur={() => validation(1)} placeholder="Enter a title" onIonChange={(e) => projecttitleChange(e)} value={projecttitle} />}
                                                    {formErrors.projectTitle !== "" && <span className="alert_input_bottom ">{formErrors.projectTitle}</span>}
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol sizeXs="12">
                                                <IonLabel className="label_align">Target Amount <span style={{ color: "red" }}>*</span></IonLabel>
                                                <div>
                                                    <IonInput type="number" onFocus={preventdata} placeholder="Enter target amount" onIonChange={(e) => tarAmtChange(e)} value={targetamt} onBlur={() => validation(3)} min={0} />
                                                    {formErrors.tarAmt !== "" && <span className="alert_input_bottom ">{formErrors.tarAmt}</span>}
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeSm="6" style={{ padding: "0" }}>
                                        <IonRow>
                                            <IonCol sizeXs="12" >
                                                <IonLabel className="label_align">Purpose <span style={{ color: "red" }}>*</span></IonLabel>
                                                <div>
                                                    <IonTextarea placeholder="Enter Purpose" autoGrow onBlur={() => validation(2)} onIonChange={(e) => purposeChange(e)} value={purpose} />
                                                    {formErrors.purpose !== "" && <span className="alert_input_bottom " >{formErrors.purpose}</span>}
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeSm="6">
                                        <IonLabel className="label_align">Minimum Amount <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput placeholder="Enter minimum amount" value={minamt} type="number" onFocus={preventdata} onIonChange={(e) => minAmtChange(e)} min={0} onBlur={() => validation(4)} />
                                            {formErrors.minAmt !== "" && <span className="alert_input_bottom " >{formErrors.minAmt}</span>}
                                        </div>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeSm="6">
                                        <IonLabel className="label_align">Maximum Amount <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div>
                                            <IonInput placeholder="Enter maximum amount" value={maxamt} onFocus={preventdata} type="number" min={0} onBlur={() => validation(5)} onIonChange={(e) => maxamtChangee(e)} />
                                            {formErrors.maxAmt !== "" && <span className="alert_input_bottom ">{formErrors.maxAmt}</span>}
                                        </div>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeSm="6">
                                        <IonLabel className="label_align">Start Date <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div className='inputdate-container'>
                                            <IonInput
                                                value={modstartdate} type="date" onBlur={() => validation(6)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== 'Tab' && e.key !== ' ') {
                                                        e.preventDefault();
                                                    }
                                                    else {
                                                        if (dateInputRef.current) {
                                                            dateInputRef.current.setFocus();
                                                        }
                                                    }
                                                }}
                                                ref={dateInputRef}
                                                min={datelimit()} onIonChange={(e) => format(e)} className='listofdatemat'
                                            />
                                            <IonIcon className='custom-calender-icon date_icon_style' onClick={handleIconClick} src={calendarOutline} />
                                        </div>
                                        {formErrors.startDate !== "" && <span className="alert_input_bottom " >{formErrors.startDate}</span>}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeSm="6">
                                        <IonLabel className="label_align">End Date <span style={{ color: "red" }}>*</span></IonLabel>
                                        <div className='inputdate-container'>
                                            <IonInput
                                                value={modenddate} onBlur={() => validation(7)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== 'Tab' && e.key !== ' ') {
                                                        e.preventDefault();
                                                    }
                                                    else {
                                                        if (endDateInputRef.current) {
                                                            endDateInputRef.current.setFocus();
                                                        }
                                                    }
                                                }}
                                                ref={endDateInputRef}
                                                min={datelimit1()} onIonChange={(e) => eformat(e)} type="date" className='listofdatemat'
                                            />
                                            <IonIcon className='custom-calender-icon date_icon_style' onClick={handleIconClick} src={calendarOutline} />
                                        </div>
                                        {formErrors.endDate !== "" && <span className="alert_input_bottom " >{formErrors.endDate}</span>}
                                    </IonCol>
                                </IonRow>
                                <div className="create-project-element">
                                    {updateBtn === true ? <div className={projecttitle.length === 0 ||
                                        purpose.length === 0 || purpose.charAt(0) == " " ||
                                        targetamt.length === 0 || parseInt(targetamt, 10) <= 0 ||
                                        minamt.length === 0 || parseInt(minamt, 10) <= 0 ||
                                        maxamt.length === 0 || parseInt(maxamt, 10) <= 0 || parseInt(minamt, 10) > parseInt(maxamt, 10) ||
                                        parseInt(minamt, 10) > parseInt(targetamt, 10) ||
                                        parseInt(maxamt, 10) > parseInt(targetamt, 10) ||
                                        startdate === "" ||
                                        enddate === "" || endTrue ? "disableCursor" : ""}><IonButton className="admin_add_btn"
                                            disabled={
                                                projecttitle.length === 0 ||
                                                purpose.length === 0 || purpose.charAt(0) == " " ||
                                                targetamt.length === 0 || parseInt(targetamt, 10) <= 0 ||
                                                minamt.length === 0 || parseInt(minamt, 10) <= 0 ||
                                                maxamt.length === 0 || parseInt(maxamt, 10) <= 0 || parseInt(minamt, 10) > parseInt(maxamt, 10) ||
                                                parseInt(minamt, 10) > parseInt(targetamt, 10) ||
                                                parseInt(maxamt, 10) > parseInt(targetamt, 10) ||
                                                startdate === "" ||
                                                enddate === "" || endTrue
                                            }
                                            onClick={(() => Update())}><span className="button_padding">Update Project</span></IonButton></div> : <div className={projecttitle.length === 0 ||
                                                purpose.length === 0 || purpose.charAt(0) == " " ||
                                                targetamt.length === 0 || parseInt(targetamt, 10) <= 0 ||
                                                minamt.length === 0 || parseInt(minamt, 10) <= 0 ||
                                                maxamt.length === 0 || parseInt(maxamt, 10) <= 0 || parseInt(minamt, 10) > parseInt(maxamt, 10) ||
                                                parseInt(minamt, 10) > parseInt(targetamt, 10) ||
                                                parseInt(maxamt, 10) > parseInt(targetamt, 10) ||
                                                startdate === "" ||
                                                enddate === "" ? "disableCursor" : ""}> <IonButton
                                                    className="admin_add_btn"
                                                    disabled={
                                                        projecttitle.length === 0 ||
                                                        purpose.length === 0 || purpose.charAt(0) == " " ||
                                                        targetamt.length === 0 || parseInt(targetamt, 10) <= 0 ||
                                                        minamt.length === 0 || parseInt(minamt, 10) <= 0 ||
                                                        maxamt.length === 0 || parseInt(maxamt, 10) <= 0 || parseInt(minamt, 10) > parseInt(maxamt, 10) ||
                                                        parseInt(minamt, 10) > parseInt(targetamt, 10) ||
                                                        parseInt(maxamt, 10) > parseInt(targetamt, 10) ||
                                                        startdate === "" ||
                                                        enddate === ""
                                                    }
                                                    onClick={(() => Add())}><span className="button_padding">Create Project</span></IonButton>
                                    </div>}
                                </div>
                            </IonGrid>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={purposemodal} className="custom-modal-wrapper">
                    <Modal.Body ref={purposemodal1} className="custom-modal">
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Project Purpose</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setPurposemodal(false)} />
                            </div>
                        </div>
                        <div className="adminProject-purpose">
                            <p>{pur}</p>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="container_middle_table page-wrapper admin_background_color project-admin-container">
                    <div className="project-management-search-container">
                        <IonRow>
                            <IonCol size-xs="12" size-lg="3" size-sm="4.5">
                                <IonLabel className="admin_search_label">
                                    State <span style={{ color: "red" }}>*</span>
                                </IonLabel>
                                <Select
                                    options={stateLiistOptions}
                                    value={stateOption}
                                    onChange={handleStateChange}
                                    onBlur={() => validation(8)}
                                    placeholder="Select the State"
                                    isClearable
                                    styles={customStyles}
                                    className="custom-select"
                                />
                                <div style={{ height: "10px" }}>
                                    {formerror.state && <span className="alert_input_bottom ">{formerror.state}</span>}
                                </div>
                            </IonCol>
                            <IonCol size-xs="12" size-lg="3" size-sm="4.5" style={{ zIndex: "999", position: "relative" }}>
                                <IonLabel className="admin_search_label">
                                    City
                                </IonLabel>
                                <IonInput className="admin_search_input" value={city} placeholder="Enter city name" onIonChange={(e: any) => setCity(e.detail.value)} />
                            </IonCol>
                            <IonCol size-xs="12" size-lg="6" size-sm="3" class="search-colum-project-admin">
                                <IonLabel className="admin_search_label">
                                </IonLabel>
                                <IonButton
                                    className="admin_add_btn"
                                    type="button"
                                    onClick={handleSearch}
                                >
                                    <span className="button_padding">Search</span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        {projAdmList.length != 0 ?
                            <IonRow style={{ zIndex: "999", position: "relative" }} className="select-admin-container">
                                <IonCol size-xs="12" size-lg="3" size-sm="4.5">
                                    <IonLabel className="admin_search_label">
                                        Project Admin
                                    </IonLabel>
                                    <Select
                                        options={projectAdmListOptions}
                                        value={selectedOption}
                                        onChange={handleChange}
                                        placeholder="Select a Project Admin"
                                        isClearable
                                        styles={customStyles}
                                        className="custom-select"
                                    />
                                </IonCol>
                            </IonRow>
                            : <></>}
                    </div>
                    {selectedOption != null ?
                        <div style={{ textAlign: "left", paddingTop: "3%" }}>
                            <IonButton onClick={() => Clear()} className="admin_add_btn">
                                <IonIcon src={addOutline} className="add_deity" style={{ cursor: "pointer" }}></IonIcon>
                                <span className="button_padding">Create New Project</span>
                            </IonButton>
                        </div>
                        : <></>}
                    <div >
                        {show.length == 0 && proLoad || selectedOption === null ? <></> : <div>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size='12'>
                                        <div>
                                            <div className='slider-element-admin'>
                                                <Slider {...Settings}>
                                                    {
                                                        show.map((item: any, i: any) => {
                                                            var status: any = ""
                                                            var status1: any = ""
                                                            if (item.projectStatus === "false") {
                                                                status1 = "ELAPSED"
                                                            } else {
                                                                status = item.projectStatus.split("_")
                                                                status1 = status[1]
                                                            }
                                                            return (
                                                                <div className='admin_project_cards'>
                                                                    <div className='card-header'>
                                                                        <p className='card-title'>{item.projectTitle}</p>
                                                                        <IonIcon src={informationCircleSharp} className='info_icon' onClick={() => purmodal(item)} />
                                                                    </div>
                                                                    <div className='card-content'>
                                                                        <p>{item.purpose}</p>
                                                                    </div>
                                                                    <div className="status-project-card">
                                                                        <IonText>Status  : </IonText>
                                                                        <IonText>{status1}</IonText>
                                                                    </div>
                                                                    <div>
                                                                        <IonProgressBar type="determinate" value={0.2} className="card-progressBar"></IonProgressBar>
                                                                        <IonRow className="collected-editrow">
                                                                            <IonCol size="7">
                                                                                {item.totalAmountCollected === null ?
                                                                                    <p className='project-rupees'>Yet To Be Donated</p>
                                                                                    :
                                                                                    <p className='project-rupees'>Rs.{formatter.format(item.totalAmountCollected)} Collected</p>
                                                                                }
                                                                            </IonCol>
                                                                            <IonCol size="5" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                                <IonButton onClick={() => editProject(item)} className="admin_add_btn"><span className="button_padding">Edit</span></IonButton>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                    </div>
                                                                    <div>
                                                                        <IonRow>
                                                                            <IonCol sizeLg='6' sizeMd='6' sizeSm='6' sizeXs='12'>
                                                                                <IonRow>
                                                                                    <IonCol size='6' className='card-icons-parent'>
                                                                                        <IonImg src={clock1} className='card-icons' />
                                                                                        {item.noOfDaysLeft < 0 ? <p className='days-likes'>Elapsed</p> : <p className='days-likes'>{item.noOfDaysLeft} days Left</p>}
                                                                                    </IonCol>
                                                                                    <IonCol size='6' className='card-icons-parent'>
                                                                                        <IonImg src={heart2} className='card-icons' />
                                                                                        <p className='days-likes'>{item.totalSupporters} supporters</p>
                                                                                    </IonCol>
                                                                                </IonRow>
                                                                            </IonCol>
                                                                            <IonCol sizeLg='6' sizeMd='6' sizeSm='6' sizeXs='12' className='donate-share'>
                                                                                {item.projectStatus === "STATUS_PENDING" ?
                                                                                    <div className={item.noOfDaysLeft < 0 ? "disableCursor" : ""}>
                                                                                        <IonButton color="success" disabled={item.noOfDaysLeft < 0} className="admin_add_btn" onClick={() =>
                                                                                            presentAlert({
                                                                                                header: 'Atmagram',
                                                                                                message: 'Do you want to activate this Project',
                                                                                                buttons: [
                                                                                                    {
                                                                                                        text: 'No',
                                                                                                        role: 'cancel',
                                                                                                    },
                                                                                                    {
                                                                                                        text: 'Yes',
                                                                                                        role: 'confirm',
                                                                                                        handler: (alertData) => {
                                                                                                            handleStatus("STATUS_ACTIVE", item.id, alertData.comment)
                                                                                                        },
                                                                                                    },
                                                                                                ],
                                                                                                inputs: [
                                                                                                    {
                                                                                                        name: 'comment',
                                                                                                        type: 'text',
                                                                                                        value: comment,
                                                                                                        placeholder: 'Enter your comment'
                                                                                                    }
                                                                                                ]
                                                                                            })

                                                                                        }><span className="button_padding">Activate</span></IonButton></div>
                                                                                    :
                                                                                    <div className={item.noOfDaysLeft < 0 ? "disableCursor" : ""}>
                                                                                        <IonButton className="admin_add_btn" color="danger" disabled={item.noOfDaysLeft < 0} onClick={() => presentAlert({
                                                                                            header: 'Atmagram',
                                                                                            message: 'Do you want to deactivate this Project',
                                                                                            buttons: [
                                                                                                {
                                                                                                    text: 'No',
                                                                                                    role: 'cancel',
                                                                                                },
                                                                                                {
                                                                                                    text: 'Yes',
                                                                                                    role: 'confirm',
                                                                                                    handler: (alertData) => {
                                                                                                        handleStatus("STATUS_PENDING", item.id, alertData.comment)
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                            inputs: [
                                                                                                {
                                                                                                    name: 'comment',
                                                                                                    type: 'text',
                                                                                                    value: comment,
                                                                                                    placeholder: 'Enter your comment'
                                                                                                }
                                                                                            ]
                                                                                        })}><span className="button_padding">Deactivate</span></IonButton>
                                                                                    </div>
                                                                                }
                                                                            </IonCol>
                                                                        </IonRow>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                </Slider>

                                            </div>
                                        </div>
                                    </IonCol>

                                </IonRow>

                            </IonGrid>
                        </div>}
                    </div>
                </div>
                {/* <Foot /> */}
            </IonContent >
        </IonPage >
    )
};
export default ProjMan;
