import {
  IonContent,
  IonItemDivider,
  IonRow,
  IonCol,
  IonImg,
  IonPage,
  IonIcon,
  IonText,
  IonButton,
  IonCheckbox,
  IonGrid,
  IonInput,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  useIonAlert,
  IonLoading,
  IonItem,
  IonAlert,
  IonHeader,
  IonCard,
  IonBadge
} from "@ionic/react";
import React, { useEffect, useState, useRef, useContext } from "react";
import { addCircleOutline, addOutline, arrowBack, arrowForward, calendarOutline, cart, checkmarkCircleSharp, close, informationCircle, informationCircleSharp, pin, removeCircleOutline, removeOutline } from "ionicons/icons";
import { useHistory, useLocation } from "react-router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { Modal, ModalHeader } from "react-bootstrap";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/js/bootstrap.min.js";
import UserHead from "./UserHead";
import Foot from "./Foot";
import "jquery/dist/jquery.min.js";
import UserService from "../Services/auth.service";
import Authservice from "../Services/user.service";
import "./sam-temp.css";
import { useForm } from "react-hook-form";
import { Capacitor } from "@capacitor/core";
import validator from 'validator'
import CustomSpin from "../components/customSpin";
import useRazorpay from "react-razorpay";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { AppContext } from "../components/AppContext";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Select, { components } from 'react-select';
import { tick, infoicon, closeicon } from "../theme/imagePath";
import "./TempleSearch.css"
import HeadFoot from "./Head";
import { Link } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../Services/firebase-config";
import { totalmem } from "os";
import { v4 as uuidv4 } from 'uuid';



interface State {
  id: number;
  name: string;
}
const Poojaofferings: React.FC = () => {

  const [formErrors, setFormErrors]: any = useState({});
  const [counErrors, setcountErrors]: any = useState({});


  const [userdata, setUserdata]: any = useState(localStorage.getItem("UserData"));
  const [errorText, setErrorText] = useState("")
  const [deitylist, setDeitylist] = useState([]);
  const history = useHistory();
  const [image, setImage] = useState("");
  const [deityname, setDeityname] = useState("");
  const [deityId, setDeityId] = useState("")
  const [description, setDescription] = useState("");
  const [isShow, setIsshow] = useState(false);
  const [index1, setIndex1]: any = useState(0);
  const [id, setId] = useState("");
  const [islogin, Islogin] = useState(false);
  const [offerKey, setOfferKey] = useState(0)

  const [showLoading, setShowLoading] = useState(false);
  const [dprice, setDprice]: any = useState("");
  const location = useLocation();
  const [itemDivider, setItemDivider] = useState(true);
  const [userAddress, setUserAddress]: any = useState(localStorage.getItem("Address"));
  const [usertoken, setUsertoken] = useState(localStorage.getItem("token"));
  const [cartparams]: any = useState(location.state)
  const formatter = new Intl.NumberFormat('en-IN')
  const [cartId, setCartId] = useState("")
  const buttonClickedRef = useRef(index1);

  const [actionFlag, setActionFlag] = useState(false);
  const actionClickedRef = useRef(actionFlag);
  const { setUFlag } = useContext(AppContext)!;
  const { setToken } = useContext(AppContext)!;
  const { setUserData } = useContext(AppContext)!;
  const [showAlert, setShowAlert] = useState(false);
  const [returnPage, setRtnPage] = useState("");
  const [Alert] = useIonAlert();
  const [stateOption, setStateOption]: any = useState([]);
  const [districtOption, setDistrictOption]: any = useState([]);
  const [countryOption, setCountryOption]: any = useState({ value: "India", label: "India" });
  const [states, setStates]: any = useState([])
  const [districts, setDistricts]: any = useState([])
  const [districtMenu, setDistrictMenu]: any = useState(false)
  const [stateMenu, setStateMenu]: any = useState(false)
  const [countries, setCountries] = useState(["India"])
  const [payFlag, setPayFlag] = useState(0)
  const [cartCount, setCartCount] = useState(0)
  const stateLiistOptions = states.map((e: any, index: any) => ({
    value: e,
    label: e
  }));
  const districtLiistOptions = districts.map((e: any, index: any) => ({
    value: e,
    label: e
  }));
  const countryLiistOptions = countries.map((e: any, index: any) => ({
    value: e,
    label: e
  }));

  const uFlag: any = useState(localStorage.getItem("uFlag"))

  const [headname, setHeadname] = useState("")
  const [hideContent, setHideContent] = useState(false)
  useEffect(() => {
    loadCart();
    if (userdata === null) {
      Islogin(false)
      console.log("1")
    }
    else {
      if (userdata !== "ROLE_USER") {
        history.push("/home")
      }
      else if (uFlag[0] === "false") {
        history.push("/home")
      }
      Islogin(true)
      console.log("2")
    }
    console.log(cartparams, "fs")
    if (cartparams === undefined || cartparams === null) {
      setIndex1(0)
      setHeadname("Home,Select Deity")
      getallgods();
      console.log("3")
    }
    else {
      console.log("4")
      console.log(cartparams)
      setTname(cartparams.item.poojaOfferings.temple.name)
      setOffername(cartparams.item.poojaOfferings.name)
      setUsername(cartparams.item.firstName)
      setStar(cartparams.item.star)
      setOfferId(cartparams.item.poojaOfferings.id)
      setDeityname(cartparams.item.poojaOfferings.deity.deityName)
      setCartId(cartparams.item.id)
      setDprice(cartparams.item.poojaOfferings.price)
      if (cartparams.item.prasadhamDelFlag === true) {
        setRadiodel("true")
        var pin = cartparams.item.deliveryAddress.split(',');
        var arrLlen = pin.length - 1;
        var address: any = "";
        for (var a = 0; a < arrLlen; a++) {
          if (a == 0) {
            address = address + pin[a];

          } else {
            address = address + "," + pin[a];
          }
          setDelivery(address)
          setPin(pin[arrLlen])
          setDeladd(true)
        }
      }
      else {
        setRadiodel("false")
      }
      if (cartparams.item.payDakshinaToPriestFlag === true) {
        setDak(true)
        setDakamt1(true)
        setPaydakshinapreist(true)
        if (cartparams.item.dakshinaAmountForPriest !== null) {
          setDakshiamt(cartparams.item.dakshinaAmountForPriest)
        }
        else {
          setDakshiamt("")
        }
      }
      else {
        setDak(false)
        setDakamt1(false)
        setPaydakshinapreist(false)
      }
      if (cartparams.item.payDakshinaToTempleFlag === true) {
        setDon(true)
        setDonamt1(true)
        setPaydakshinatemple(true)
        if (cartparams.item.dakshinaAmountToTemple !== null) {
          setDonationamt(cartparams.item.dakshinaAmountToTemple)
        }
        else {
          setDonationamt("")
        }
      }
      else {
        setDon(false)
        setDonamt1(false)
        setPaydakshinatemple(false)
      }
      setHeadname("Home,Booking Details")
      setIndex1(4)
    }



  }, []);
  const loadCart = () => {
    setShowLoading(true);
    var guestTkn: any = localStorage.getItem("guestTkn");

    Authservice.Addcart(usertoken, guestTkn).then((response: any) => {
      if (response.data) {
        setCartCount(response.data.length)
        if (usertoken != null && usertoken != undefined && usertoken != "") {
          localStorage.removeItem("guestTkn")

        }
      }

      setShowLoading(false);



    }).catch((error) => {

      setShowLoading(false);
    });
  }

  const getallgods = () => {
    setShowLoading(true);
    UserService.Getallgods(islogin, usertoken).then((response: any) => {
      if (response.data !== undefined || response.data.length !== 0) {
        setDeitylist(response.data);
      } else {
        setDeitylist([]);
        setErrorText("Sorry, No data available")
      }
      setShowLoading(false)

    })
      .catch((error: any) => {
        console.log(error);
        setErrorText("Sorry, No data available")
        setShowLoading(false);
      });


  }
  const handleLeave = () => {
    // Code to execute when the user confirms leaving

    console.log('Leaving MyPage', returnPage);
    // Additional actions can be performed here

    setShowAlert(false);

    // Update the flag to indicate that the action was clicked
    history.push(returnPage); // Example: Navigate to the new page
  };
  useEffect(() => {
    const unlisten = history.block((location, action) => {

      if (!actionClickedRef.current && (buttonClickedRef.current === 4 || buttonClickedRef.current === 5)) {
        setRtnPage(location.pathname)
        setActionFlag(true)
        // return "Are you sure want to Leave? If you leave, your order details will be lost.";
        setShowAlert(true);
        return false;

      }
      // Code to execute when the page is about to leave
      console.log('Leaving MyPage');
      // Additional actions can be performed here

    });

    return () => {
      unlisten();
    };

  }, [history, actionClickedRef.current, buttonClickedRef.current]);

  useEffect(() => {
    buttonClickedRef.current = index1;
    actionClickedRef.current = actionFlag;
  }, [index1, actionFlag]);
  const [maindeityname, setMaindeityname] = useState("")
  const [maindeityid, setMaindeityid] = useState("")
  const Details = (item: any) => {
    if (Capacitor.getPlatform() !== "web" && gods.length === 0) {
      setHideContent(true)
    }
    else {
      setHideContent(false)
    }
    setGods([])
    setId(item.id);
    setMaindeityid(item.id)
    setMaindeityname(item.deityName);
    setImage(item.image)
    getAllState()
    setIndex1(2)
    setHeadname("Home,Select Area")
  }

  const backtodeity = () => {
    setHeadname("Home,Select Deity")
    setIndex1(0)
  }

  const templebacktodeity = () => {
    if (Capacitor.getPlatform() !== "web") {
      setHideContent(true)
    }
    else {
      setHideContent(false)
    }
    setIndex1(0)
    setShowgod(false)
    setHeadname("Home,Select Deity")
  }

  const Viewinfo = (item: any) => {
    setImage(item.image);
    setMaindeityname(item.deityName);
    setDescription(item.deityDescription);
    setIndex1(1)
    setHeadname("Home,Biography")
  };

  const [state, setState]: any = useState();
  const [country, setCountry] = useState("India");
  const [district, setDistrict]: any = useState()
  const [town, setTown]: any = useState()
  const [gods, setGods] = useState([])
  const ref: any = useRef(null);
  const [scrl, setScrl]: any = useState();
  const [offerings, setOfferings]: any = useState([])
  const [offerName, setOffername] = useState("")
  const [offerId, setOfferId] = useState("")
  const [tname, setTname] = useState("")
  const [temName, setTemName]: any = useState("");
  const [showgod, setShowgod] = useState(false);
  const widthSize = window.innerWidth
  const [slidesToShow, setSlidesToShow]: any = useState(widthSize > 900 ? 3 : widthSize > 600 ? 2 : widthSize < 600 ? 1 : 0);
  const SearchF = (value: any) => {
    if (value == "Enter") {
      Submit()
    }
  }
  const submitSign = (value: any) => {
    if (value == "Enter") {
      Searchsign()
    }
  }

  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const targetRef: any = useRef(null);

  const Submit = () => {
    const errors: any = {};
    if (country.length == 0) {
      errors.country = "Please select the country"
    }
    setcountErrors(errors);
    if (Object.keys(errors).length == 0) {
      setShowLoading(true)
      UserService.SearchTemple(usertoken, country, state, district, town, id, temName)
        .then((response: any) => {
          console.log("Res=>", response)
          setGods(response.data)
          setShowgod(true);
          if (Capacitor.getPlatform() !== "web") {
            setHideContent(false)
            setTimeout(() => {
              if (targetRef.current) {
                targetRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }, 500)
          }
          setShowLoading(false)
        })
        .catch((error) => {
          console.log(error);
          setShowLoading(false)
        });
    }
  };

  const Clear = () => {
    console.log("Skjsk");
    setState("");
    setDistrict("");
    setTown("");
    setTemName("");
    setShowgod(false);
    setStateOption(null)
    setDistrictOption(null)
    if (Capacitor.getPlatform() !== "web") {
      setHideContent(true)
    }
  }

  const [Templedeity, setTempledeity]: any = useState([])
  const [deityArr, setDeityArr]: any = useState([])
  const [deityArr2, setDeityArr2]: any = useState([])
  const Razorpay = useRazorpay();

  const [deityOffer, setDeityOffer]: any = useState([])

  const NextArrow = (props: any) => {
    console.log(props, "nextt")
    const { className, onClick } = props;
    const currentSlide = props.currentSlide
    const slideCount = props.slideCount
    return (
      <div className="slick_nxt" onClick={onClick} style={{ display: currentSlide === slideCount - slidesToShow ? 'none' : 'block' }} >
        <IonIcon icon={arrowForward} />
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    console.log(props, "preee")
    const { className, onClick } = props;
    const currentSlide = props.currentSlide
    return (
      <div className="slick_prev" onClick={onClick} style={{ display: currentSlide === 0 ? 'none' : 'block' }}>
        <IonIcon icon={arrowBack} />
      </div>
    );
  };
  const breakpoints = [
    {
      breakpoint: 400, settings: {
        slidesToShow: 1, slidesToScroll: 1, dots: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />, infinite: false,
      }
    },
    { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, dots: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />, infinite: false, } },
    { breakpoint: 900, settings: { slidesToShow: 2, slidesToScroll: 2, dots: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />, infinite: false, } },
    { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3, dots: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />, infinite: false, } },
  ];

  const initialSettings = {
    dots: false,
    horizontal: true,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: breakpoints,
  };

  const [Settings, setSettings]: any = useState(initialSettings);

  const show = (tid: any, tname: any) => {
    setShowLoading(true)
    setTempledeity([])
    var DeityArr: any = []
    var DeityArr2: any = []
    console.log(gods)
    setId(id)
    setTname(tname)
    setItemDivider(false)
    setDeityOffer([])
    Authservice.Poojaofferings(id, tid).then((response: any) => {
      console.log(response.data)
      for (let i = 0; i < response.data.length; i++) {
        if (maindeityid === response.data[i].deityDto.id) {
          DeityArr.push(response.data[i].deityDto)
          setDeityId(response.data[i].deityDto.id)
          setDeityname(response.data[i].deityDto.deityName)
        } else {
          DeityArr2.push(response.data[i].deityDto)
        }
      }
      setTempledeity([
        ...DeityArr, ...DeityArr2
      ])
      for (let i = 0; i < response.data.length; i++) {
        if (maindeityid === response.data[i].deityDto.id) {
          console.log(response.data[i].poojaOfferings)
          setDeityOffer(response.data[i].poojaOfferings)
          setOfferKey(offerKey + 1)
        }
      }
      setOfferings(response.data)
      // for (let i = 0; i < response.data.length; i++) {
      //   setTname(response.data[i].temple.name)
      // }
    })

    setTabId(0);
    setIndex1(3);
    setHeadname("Home,Offerings List")
    setShowLoading(false)
    console.log("id", id)

    const updateSettings = () => {
      const windowWidth = window.innerWidth;
      const breakpoint = breakpoints.find((bp) => windowWidth < bp.breakpoint);
      const updatedSettings = breakpoint ? breakpoint.settings : initialSettings;
      setSettings(updatedSettings);
    };

    updateSettings();

    window.addEventListener('resize', updateSettings);
    return () => {
      window.removeEventListener('resize', updateSettings);
    };
  }

  const backtotemple = () => {
    setDeityId("")
    setOfferings([])
    setDeityOffer([])
    setIndex1(2)
    setHeadname("Home,Select Area")
  }
  const [android, setAndroid] = useState(false)
  const shows = (item: any) => {
    setItemDivider(true)
    var aa = Capacitor.getPlatform()
    console.log(aa, "aaaa")
    if (Capacitor.getPlatform() === "android") {
      setAndroid(true)
    }
    else {
      setAndroid(false)
    }
    setIndex1(4);
    setHeadname("Home,Booking Details")
    setId(item.id);
    setOfferId(item.id)
    console.log("id", offerId);
    setOffername(item.name);
    setDprice(item.price);
  }
  const scroll = (scrollOffset: any) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const inputArr = [
    {

      value: ""
    }
  ];


  const [userid] = useState(localStorage.getItem("UserId"));
  const [radio, setRadio] = useState("")
  const [month, setMonth]: any = useState(0)
  const [fdate, setFdate] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [tdate, setTdate] = useState("")
  const [username, setUsername]: any = useState("");
  const [star, setStar] = useState("");
  const [radiodel, setRadiodel] = useState("false");
  const [delivery, setDelivery] = useState("");
  const [dates, setDates]: any = useState(inputArr)
  const [dakshinaamt, setDakshiamt]: any = useState("")
  const [paydakshinapreist, setPaydakshinapreist]: any = useState(false)
  const [paydakshinatemple, setPaydakshinatemple]: any = useState(false)
  const [donationamt, setDonationamt]: any = useState("")
  const [cartList, setcart]: any = useState([])
  const [email, setEmail]: any = useState(localStorage.getItem("Email"));
  const [pincode, setPin]: any = useState("");
  const [phone, setPhone]: any = useState(localStorage.getItem("Phone"));
  const [listdates, setListdates]: any = useState([]);
  const [confirmdates, setConfirmdates]: any = useState([]);
  const [tabId, setTabId]: any = useState("")
  const [sortdates, setSortDates]: any = useState([])
  const [deladd, setDeladd] = useState(false);
  const [orderList, setOrderList]: any = useState([]);
  const [payData, setPayData]: any = useState([])
  const [onedate, setOnedate] = useState("");
  const [paymentdata, setPaymentdata]: any = useState([]);
  const [add, setAdd]: any = useState(localStorage.getItem("Address"));
  const [che, setChe] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const [modalopen1, setModalopen1] = useState(false);
  const [myOrder, setMyOrder] = useState([]);
  const [dak, setDak]: any = useState(false);
  const [don, setDon] = useState(false);
  const [delCharge, setDelCharge]: any = useState("");
  const [delChargePerDay, setDelChargePerDay]: any = useState("");

  const [signupmodal, setSignupmodal] = useState(false)
  const [user, setUser]: any = useState("2");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [password1, setPassword1]: any = useState("");
  const [state1, setState1]: any = useState("");
  const [TName, setTName]: any = useState("");
  const [TAdd, setTAdd]: any = useState("");
  const [aadhar1, setAadhar1]: any = useState("");
  const [addr, setAddr] = useState("");
  const [area1, setArea1] = useState("")
  const [firstname1, setFirstname1] = useState("");
  const [lastname1, setLastname1] = useState("");
  const [phone1, setPhone1]: any = useState("");
  const [city1, setCity1] = useState("");
  const [country1, setCountry1] = useState("India");
  const [email1, setEmail1] = useState("");
  const [issuccess, setIssuccess] = useState(false);
  const [pincode1, setPincode1]: any = useState("");
  const checkboxRef = useRef(null);
  const [isValidPhoneNumberSign, setIsValidPhoneNumberSign] = useState(false);
  const sliderRef: any = useRef()


  var details: any = {}
  if (radio === "1") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    details = {
      "id": cartId,
      "firstName": username,
      "fromDate": from,
      "toDate": to,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId },
      //  "listOfDates":dates,
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt

    }
  } else if (radio === "2") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    details = {
      "id": cartId,
      "firstName": username,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId },
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      "noOfMonths": month,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt
    }
  } else if (radio === "3") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    // const datesList = listdates.map((e: any) => new Date(e))
    //   .sort((a: any, b: any) => a - b)
    // const listofdates = datesList.map((e: any) => moment(e).format("YYYY-MM-DDThh:mm:ss"))
    details = {
      "id": cartId,
      "firstName": username,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId },
      "listOfDates": listdates,
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt
    }
  }

  if (paydakshinatemple) {
    details["dakshinaAmountToTemple"] = donationamt;
  }
  if (paydakshinapreist) {
    details["dakshinaAmountForPriest"] = dakshinaamt;
  }

  const addInput = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const val = tomorrow.toISOString().split('T')[0]
    var valSet = isFound('');
    var past = pastDate(val)
    const errors: any = {}

    if (!valSet && !past) {
      setDates((e: any) => {
        return [
          ...e,
          {
            value: ""
          }
        ];
      });
      return;

    } else if (past) {
      errors.dates = "Please select future date"
    } else {
      errors.dates = "Enter Date before adding a new Date"
    }
    setFormErrors(errors)

  };
  const getAllState = () => {
    setStateOption(null)
    setDistrictOption(null)
    setShowLoading(true);
    Authservice.getAllstates().then((response) => {
      console.log(response.data);
      setStates(response.data)
      setShowLoading(false);
    })
      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }

  const getAllDistricts = (item: any) => {
    const arr = [item.value]
    setDistrictOption(null)
    Authservice.getAllDistricts(arr).then((response) => {
      console.log(response);
      setShowLoading(true)
      setDistricts(response.data)
      setShowLoading(false)
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }
  const isFound = (val: any) => {
    return dates.some((item: any) => val === item.value);
  }
  const pastDate = (val: any) => {
    return dates.some((item: any) => val > new Date(item.value).toISOString().split('T')[0]);
  }
  const removeInputFields = (index: any) => {
    const rows = [...dates];
    const lists = [...listdates]
    const confirm = [...confirmdates]
    const error: any = {}

    if (rows.length >= 1) {
      rows.splice(index, 1);
    }
    if (lists.length >= 1) {
      lists.splice(index, 1);
    }
    if (confirm.length >= 1) {
      confirm.splice(index, 1);
    }
    error.dates = ""
    setFormErrors(error)
    setDates(rows);
    setListdates(lists)
    setConfirmdates(confirm)
  }
  const sliderback = () => {
    setItemDivider(false)
    setOffername("")
    setIndex1(3)
    setHeadname("Home,Offerings List")
  }
  const handleChange = (e: any, i: any) => {
    e.preventDefault();
    dates[i].value = e.target.value
  };
  const dates_changes = (e: any, i: any) => {
    // console.log(date,"lojo")
    //   Alert({
    //     header: "Atmagram",
    //     message: "Please select a future date",
    //     backdropDismiss: false,
    //     buttons: [
    //       {
    //         text: "ok",
    //       }
    //     ]
    //   })
    // }
    // else {
    dates[i].value = e.target.value
    var ii = e.detail.value;
    var str = moment(ii).format('YYYY-MM-DDThh:mm:ss')
    listdates[i] = str;
    var confirm = moment(ii).format('DD-MM-YYYY')
    confirmdates[i] = confirm
    listdates.map((e: any) => moment(e).format('YYYY-MM-DDThh:mm:ss'))
      .sort((a: any, b: any) => a - b)
    // }
  }

  const handleIconClick = (event: any) => {
    console.log('Icon clicked!');
    event.stopPropagation();  // Stop the event from propagating to the parent div
  };

  const fromDateInputRef: any = useRef(null);
  const toDateInputRef: any = useRef(null)
  const listDateInputRef: any = useRef(null)

  const datelimit = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    // return yyyy + "-" + mm + "-" + dd;
    const today1 = new Date();
    const tomorrow = new Date(today1);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return moment(tomorrow.toISOString().split('T')[0]).format("YYYY-MM-DD");
  };
  const todatelimit = () => {
    const today = new Date(fdate);
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    // return yyyy + "-" + mm + "-" + dd;
    const today1 = new Date();
    if (fdate !== "") {
      const tomorrow = new Date(fdate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().split('T')[0];
    }
  };
  const namepat = /^[a-zA-Z\s]*$/
  const starpat = /^[a-zA-Z\s]*$/
  const twoDigitsOnly = /^\d{0,2}$/;
  const [ffdate, setffdate]: any = useState("");
  const [ttdate, setttdate] = useState("")
  const { amountLimit }: any = useContext(AppContext)!;

  const Validation = (label: any) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (label === "address") {
      if (delivery == "" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, address: "Please Enter delivery address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, address: "" }
        })
      }
    }

    if (label === "pincode") {
      if (pincode.length === "" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter valid pincode" }
        })
      }
      else if (pincode.charAt(0) === "0" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Pincode is invalid" }
        })
      }
      else if (pincode.length != 6 && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter valid pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }

    if (label === "radio") {
      if (radio == "") {
        setFormErrors((prev: any) => {
          return { ...prev, radio: "Please choose atleast any one of the fields" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, radio: "" }
        })
      }
    }

    if (label === "fromDate") {
      if (fdate === "" && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "Please Select From Date" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(fdate).toISOString().split('T')[0] && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "" }
        })
      }
    }

    if (label === "toDate") {
      if (tdate === "" && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "Please Select To Date" }
        })
      }
      else if (fdate === tdate && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "From date and To date are same" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(tdate).toISOString().split('T')[0] && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "" }
        })
      }
    }

    if (label === "monthly") {
      const check = month as string;
      if (month == 0 && radio === "2") {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "Please Enter the Month" }
        })
      }
      else if (radio === "2" && twoDigitsOnly.test(check) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "Please Enter valid Month" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "" }
        })
      }
    }

    if (label === "dates") {
      var flag;
      for (let i = 0; i < dates.length; i++) {
        if (dates[i].value == "") {
          flag = true
        }
      }
      var flag1;
      for (let ii = 0; ii < dates.length; ii++) {
        for (let j = ii + 1; j < dates.length; j++) {
          if (dates[ii].value == dates[j].value) {
            flag1 = true
          }
        }
      }
      var flag2;
      for (let i = 0; i < dates.length; i++) {
        if (tomorrow.toISOString().split('T')[0] > new Date(dates[i].value).toISOString().split('T')[0]) {
          flag2 = true
        }
      }
      if (flag && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please Enter the Date" }
        })
      }
      else if (flag1 && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please Check the Date you Entered" }
        })
      }
      else if (flag2 && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "" }
        })
      }
    }

    if (label === "priestAmount") {
      if (dakshinaamt == "" && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Please Enter the dakshina amount to priest" }
        })
      }
      else if (dakshinaamt <= 0 && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Please Enter valid dakshina amount" }
        })
      }
      else if (dakshinaamt > amountLimit && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "" }
        })
      }
    }

    if (label === "donationamt") {
      if (donationamt == "" && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Please Enter the donation amount to temple" }
        })
      }
      else if (donationamt <= 0 && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Please Enter valid donation amount" }
        })
      }
      else if (donationamt > amountLimit && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "" }
        })
      }
    }

    if (label === "login email") {
      if (logemail.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Email is required" }
        })
      }
      else if (validator.isEmail(logemail) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "" }
        })
      }
    }

    if (label === "login password") {
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }


    if (label === "firstname") {
      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "mobile") {
      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumberSign) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }

    if (label === "loginphone") {
      if (loginphone === '' || loginphone === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, loginPhone: "Mobile Number is required" }
        })
      } else if (!isValidLoginNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, loginPhone: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, loginPhone: "" }
        })
      }
    }

    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (label === "email") {
      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }

    if (label === "password") {
      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
  }

  const ChangeValidation = (label: any, e: any) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (label === "address") {
      const delivery = e.target.value;
      if (delivery == "" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, address: "Please Enter delivery address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, address: "" }
        })
      }
    }

    if (label === "pincode") {
      const pincode = e.target.value;

      if (pincode.length === "" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter valid pincode" }
        })
      }
      else if (pincode.charAt(0) === "0" && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Pincode is invalid" }
        })
      }
      else if (pincode.length != 6 && deladd == true) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please Enter valid pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }

    if (label === "radio") {
      const radio = e.target.value;

      if (radio == "") {
        setFormErrors((prev: any) => {
          return { ...prev, radio: "Please choose atleast any one of the fields" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, radio: "" }
        })
      }
    }

    if (label === "fromDate") {
      const fdate = e.target.value;

      if (fdate === "" && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "Please Select From Date" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(fdate).toISOString().split('T')[0] && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, fromDate: "" }
        })
      }
    }

    if (label === "toDate") {
      const tdate = e.target.value;
      if (tdate === "" && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "Please Select To Date" }
        })
      }
      else if (fdate === tdate && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "From date and To date are same" }
        })
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(tdate).toISOString().split('T')[0] && radio === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, toDate: "" }
        })
      }
    }

    if (label === "monthly") {
      const month = e.target.value;
      const check = e.target.value as string;
      if (month == 0 && radio === "2") {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "Please Enter the Month" }
        })
      }
      else if (radio === "2" && twoDigitsOnly.test(check) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "Please Enter valid Month" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, monthly: "" }
        })
      }
    }

    if (label === "dates") {
      var flag;
      for (let i = 0; i < dates.length; i++) {
        if (dates[i].value == "") {
          flag = true
        }
      }
      var flag1;
      for (let ii = 0; ii < dates.length; ii++) {
        for (let j = ii + 1; j < dates.length; j++) {
          if (dates[ii].value == dates[j].value) {
            flag1 = true
          }
        }
      }
      var flag2;
      for (let i = 0; i < dates.length; i++) {
        if (tomorrow.toISOString().split('T')[0] > new Date(dates[i].value).toISOString().split('T')[0]) {
          flag2 = true
        }
      }


      if (flag && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please Enter the Date" }
        })
      }
      else if (flag1 && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please Check the Date you Entered" }
        })
      }
      else if (flag2 && radio === "3") {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, dates: "" }
        })
      }
    }

    if (label === "priestAmount") {
      const dakshinaamt = e.target.value;

      if (dakshinaamt == "" && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Please Enter the dakshina amount to priest" }
        })
      }
      else if (dakshinaamt <= 0 && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Please Enter valid dakshina amount" }
        })
      }
      else if (dakshinaamt > amountLimit && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, priestAmount: "" }
        })
      }
    }

    if (label === "donationamt") {

      const donationamt = e.target.value;

      if (donationamt == "" && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Please Enter the donation amount to temple" }
        })
      }
      else if (donationamt <= 0 && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Please Enter valid donation amount" }
        })
      }
      else if (donationamt > amountLimit && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, donationamt: "" }
        })
      }
    }

    if (label === "login email") {
      const logemail = e.target.value;

      if (logemail.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Email is required" }
        })
      }
      else if (validator.isEmail(logemail) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "" }
        })
      }
    }

    if (label === "login password") {
      const password = e.target.value;

      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }

    if (label === "phone") {
      if (phone === undefined || phone === "") {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }


    if (label === "firstname") {
      const firstname1 = e.target.value;

      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "mobile") {

      const phone1 = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (label === "email") {

      const email1 = e.target.value;

      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }

    if (label === "password") {
      const password1 = e.target.value;

      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      const confirmpassword = e.target.value;

      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
  }

  const show1 = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var flag;
    var flag2;
    for (let i = 0; i < dates.length; i++) {
      if (dates[i].value == "") {
        flag = true
      }
      else if (tomorrow.toISOString().split('T')[0] > new Date(dates[i].value).toISOString().split('T')[0]) {
        flag2 = true
      }
    }
    var flag1;
    for (let ii = 0; ii < dates.length; ii++) {
      for (let j = ii + 1; j < dates.length; j++) {
        if (dates[ii].value == dates[j].value) {
          flag1 = true
        }
      }
    }

    // if (username == "") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Enter name',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (namepat.test(username) == false) {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Alphabets only Allowed in Name',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (username.charAt(0) == " ") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Empty Space at Name',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (star == "") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Enter Star',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (starpat.test(star) == false) {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Alphabets only Allowed in Star',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (star.charAt(0) == " ") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Empty Space at Star',
    //     buttons: ['OK'],
    //   })
    // }
    const errors: any = {};
    const check = month as string;
    const dontNumber = parseInt(donationamt, 10)
    const dakNumber = parseInt(dakshinaamt, 10)
    var amountCheck = dontNumber + dakNumber
    if (radiodel == "") {
      Alert({
        header: 'Alert',
        message: 'Choose pradasam delivery',
        buttons: ['OK'],
      })
    }
    if (delivery == "" && deladd == true) {
      errors.address = "Please Enter delivery address"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter delivery address',
      //   buttons: ['OK'],
      // })
    }
    if (pincode.length === "" && deladd == true) {
      errors.pincode = "Please Enter valid pincode"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter valid pincode',
      //   buttons: ['OK'],
      // })
    }
    else if (pincode.charAt(0) === "0" && deladd == true) {
      errors.pincode = "Pincode is invalid"
      // Alert({
      //   header: 'Alert',
      //   message: 'pincode is invalid',
      //   buttons: ['OK'],
      // })
    }
    else if (pincode.length != 6 && deladd == true) {
      errors.pincode = "Please Enter valid pincode"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter valid pincode',
      //   buttons: ['OK'],
      // })
    }
    if (radio == "") {
      errors.radio = "Please choose atleast any one of the fields"
      // Alert({
      //   header: 'Alert',
      //   message: 'Choose Dates',
      //   buttons: ['OK'],
      // })
    }
    if (fdate === "" && radio === "1") {
      errors.fromDate = "Please Select From Date"

      // Alert({
      //   header: 'Alert',
      //   message: 'Select from Date',
      //   buttons: ['OK'],
      // })
    } else if (radio === "1") {
      if (tomorrow.toISOString().split('T')[0] > new Date(fdate).toISOString().split('T')[0]) {
        errors.fromDate = "Please select future date"
      }
    }
    else if (tdate === "" && radio === "1") {
      errors.toDate = "Please Select To Date"
      // Alert({
      //   header: 'Alert',
      //   message: 'Select to Date',
      //   buttons: ['OK'],
      // })
    }
    else if (radio === "1") {
      if (tomorrow.toISOString().split('T')[0] > new Date(tdate).toISOString().split('T')[0]) {
        errors.toDate = "Please select future date"
      }
    }
    else if (fdate === tdate && radio === "1") {
      errors.toDate = "From date and To date are same"
      // Alert({
      //   header: 'Alert',
      //   message: 'From date and To date are same',
      //   buttons: ['OK'],
      // })
    }
    else if (month == 0 && radio === "2") {
      errors.monthly = "Please Enter the Month"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the Month',
      //   buttons: ['OK'],
      // })
    }
    else if (radio === "2" && twoDigitsOnly.test(check) === false) {
      errors.monthly = "Please Enter valid Month"
    }
    else if (flag && radio === "3") {
      errors.dates = "Please Enter the Date"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the Dates',
      //   buttons: ['OK'],
      // })
    }
    else if (flag1 && radio === "3") {
      errors.dates = "Please Check the Date you Entered"
      // Alert({
      //   header: 'Alert',
      //   message: 'Same Dates',
      //   buttons: ['OK'],
      // })
    } else if (flag2 && radio === "3") {
      errors.dates = "Please select future date"
    }
    if (dakshinaamt == "" && dakamt1 === true) {
      errors.priestAmount = "Please Enter the dakshina amount to priest"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the dakshina amount to priest',
      //   buttons: ['OK'],
      // })
    }
    else if (dakshinaamt <= 0 && dakamt1 === true) {
      errors.priestAmount = "Please Enter valid dakshina amount"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter valid dakshina amount',
      //   buttons: ['OK'],
      // })
    }
    if (donationamt == "" && donamt1 === true) {
      errors.donationamt = "Please Enter the donation amount to temple"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the donation amount to temple',
      //   buttons: ['OK'],
      // })
    }
    else if (donationamt <= 0 && donamt1 === true) {
      errors.donationamt = "Please Enter valid donation amount"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter valid donation amount',
      //   buttons: ['OK'],
      // })
    }
    if (amountCheck > amountLimit) {
      Alert({
        header: "Atmagram",
        message: "Maximum Transaction limit is 500000",
        backdropDismiss: false,
        buttons: [
          {
            text: "ok",
          }
        ]
      })
    }
    else {
      if (Object.keys(errors).length === 0) {
        console.log(dates)
        const sortedDates = dates
          .map((e: any) => new Date(e.value))
          .sort((a: any, b: any) => a - b);
        setSortDates(sortedDates)
        var ff = moment(fdate).format('DD-MM-YYYY')
        setffdate(ff);
        var tt = moment(tdate).format('DD-MM-YYYY')
        setttdate(tt);
        // setIndex1(4);
        setId(id)
        console.log("id", id)
        // calDelCharge(usertoken);
        pinvalid()
      }

    }
    setFormErrors(errors);
  }
  const [totalpaidamt, setTotalpaidamt] = useState("")

  const pinvalid = () => {
    if (deladd) {
      Authservice.pinValid(pincode).then((response: any) => {
        console.log(response.data, "gcf");
        if (response.data.startsWith("SUCCESS")) {
          // if (usertoken == null) {
          //   setLoginmodal(true)
          // } else {
          calDelCharge(usertoken);
          //}
        } else {
          Alert("Delivery not available for this Pincode")
        }
      })
        .catch((error: any) => {
          console.log(error);
          setShowLoading(false);
        });
    } else {
      // if (usertoken == null) {
      //   setLoginmodal(true)
      // } else {
      calDelCharge(usertoken);
      setIndex1(5);
      setHeadname("Home,Booking Details")
      setActionFlag(false)
      //}
    }
  }

  const calDelCharge = (token: any) => {
    setShowLoading(true);
    Authservice.calDelChargeForOffer(token, details).then((response: any) => {
      console.log(response.data, "gcf");
      var data = response.data;
      if (data.deliveryError === null || data.deliveryError === "") {
        setDelCharge(data.pdDelCharge)
        setTotalpaidamt(data.totalPaidAmount)
        setDelChargePerDay(data.delChargePerDay)
        setShowLoading(false);
        setIndex1(5);
        setHeadname("Home,Booking Details")
        setActionFlag(false)
      }
      else {
        Alert("Delivery service not available to this pincode. sorry for the inconvenience.")
        setShowLoading(false);
      }
    })
      .catch((error: any) => {
        console.log(error);
        setShowLoading(false);
      });
  }
  const [dakamt1, setDakamt1] = useState(false)
  const inputRef = useRef<any>(null);
  const dakamt = (e: any) => {
    if (e.detail.checked) {
      setDak(true)
      setDakamt1(true)
      setPaydakshinapreist(true)
      // inputRef.current.setFocus();
      setTimeout(() => {
        inputRef.current?.setFocus();
      }, 500);
    }
    else {
      setDak(false)
      setDakamt1(false)
      setPaydakshinapreist(false)
      setDakshiamt("");
      setFormErrors((prev: any) => {
        return { ...prev, priestAmount: "" }
      })
    }
  }
  const [donamt1, setDonamt1] = useState(false)
  const inputRef1 = useRef<any>(null);
  const donamt = (e: any) => {
    if (e.detail.checked) {
      setDon(true)
      setDonamt1(true)
      setPaydakshinatemple(true)
      setTimeout(() => {
        inputRef1.current?.setFocus();
      }, 500);
    }
    else {
      setDon(false)
      setDonamt1(false)
      setPaydakshinatemple(false)
      setDonationamt("");
      setFormErrors((prev: any) => {
        return { ...prev, donationamt: "" }
      })
    }
  }
  const Increment = () => {
    setMonth(parseInt(month, 10) + 1);
  }
  const handlechangeMonth = (e: any) => {
    var round = 0
    round = Math.round(e.target.value)
    setMonth(round)
  }
  const Decrement = () => {
    if (month > 0) {
      setMonth(month - 1);
    }
  }
  const Orders = () => {
    setIndex(2)
    Authservice.MyOrder(usertoken).then((respone: any) => {
      console.log(respone)
      setMyOrder(respone.data);
    })
  }
  // const Cart = () => {
  //   setIndex(3)
  //   Authservice.Addcart(usertoken).then((response: any) => {
  //     setcart(response.data)
  //     console.log(response.data)
  //     setPaymentData(response.data);
  //   })
  // }
  const savebook = (e: any) => {

    var guestTkn: any = "";
    var token: any = localStorage.getItem("guestTkn");

    if (e == null) {

      if (token == null || token == undefined || token == "") {
        token = uuidv4();
        localStorage.setItem("guestTkn", token);
      }

      guestTkn = token;
    }
    setActionFlag(true);
    console.log(details)
    Authservice.AddToCartInit(details, e, guestTkn).then((response) => {
      if (response.data === "Success") {
        if (e != null) {
          history.push("/mycart")
        } else {
          Alert("Item added to cart")
          history.push("/offerings")
        }

      }

    })
      .catch((error) => {
        Alert(error)

      });
  }

  var paymentdetails: any = {}
  if (radio === "1") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    paymentdetails = {
      "firstName": username,
      "fromDate": from,
      "toDate": to,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "pdDelCharge": delCharge,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId, "price": dprice },
      "totalPaidAmount": totalpaidamt,
      //  "listOfDates":dates,
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt

    }
  } else if (radio === "2") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    paymentdetails = {
      "firstName": username,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "pdDelCharge": delCharge,
      "prasadhamDelFlag": radiodel,
      "poojaOfferings": { "id": offerId, "price": dprice },
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      "noOfMonths": month,
      "totalPaidAmount": totalpaidamt,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt
    }
  } else if (radio === "3") {
    if (radiodel === "false") {
      var delAdd = "N/A"
    }
    else {
      var delAdd = delivery + "," + pincode;
    }
    const datesList = listdates.map((e: any) => new Date(e))
      .sort((a: any, b: any) => a - b)
    const listofdates = datesList.map((e: any) => moment(e).format("YYYY-MM-DDThh:mm:ss"))
    paymentdetails = {
      "firstName": username,
      "star": star,
      "mailId": email,
      "mobileNumber": phone,
      "deliveryAddress": delAdd,
      "prasadhamDelFlag": radiodel,
      "pdDelCharge": delCharge,
      "poojaOfferings": { "id": offerId, "price": dprice },
      "listOfDates": listofdates,
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      "totalPaidAmount": totalpaidamt,
      // "dakshinaAmountForPriest": dakshinaamt,
      // "dakshinaAmountToTemple": donationamt
    }
  }

  if (paydakshinatemple) {
    paymentdetails["dakshinaAmountToTemple"] = donationamt;
  }
  if (paydakshinapreist) {
    paymentdetails["dakshinaAmountForPriest"] = dakshinaamt;
  }

  const proceedtopayment = (e: any) => {
    setActionFlag(true)
    console.log(paymentdetails, "sdfsdf")
    setShowLoading(true);
    Authservice.Payinitoffering(paymentdetails, e).then((response) => {
      console.log(response.data, "dataa")
      if (response.data.errors === null) {

        var options: any = {
          key: response.data.pgOption.key,
          key_secret: response.data.secretKey,
          amount: response.data.pgOption.amount,
          currency: response.data.pgOption.currency,
          name: response.data.pgOption.name,
          order_id: response.data.pgOption.order_id,
          description: response.data.pgOption.description,
          modal: {
            escape: false, ondismiss: function () {
              Alert("Payment Failed")
            }
          },
          handler: function (res: any) {
            // alert(res.razorpay_payment_id+"/"+res.razorpay_order_id+res.razorpay_signature);
            var resData: any = {
              "amount": response.data.pgOption.amount,
              "orderId": res.razorpay_order_id,
              "cartPayRefId": response.data.cartPayRefId,
              "signature": res.razorpay_signature,
              "paymentId": res.razorpay_payment_id
            }
            console.log("resData", resData);
            Authservice.Payresoffering(resData, e)
              .then((response) => {
                console.log("payresponse" + response);
                Alert({
                  header: "Atmagram",
                  message: "Order placed successfully, Please visit My Offerings",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                      handler: () => {
                        window.location.reload()
                      }
                    }
                  ]
                })
              })
              .catch((error) => {
                Alert({
                  header: "Atmagram",
                  message: "Something went wrong, please try again later.",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                    }
                  ]
                })
              });

          },
          prefill: {
            name: response.data.pgOption.name,
            email: response.data.email,
            contact: response.data.contact

          },

          theme: {
            color: response.data.theme
          }
        };
        var pay: any = new Razorpay(options)
        pay.on("payment.failed", function (data: any) {
          Alert({
            header: "Atmagram",
            message: data.error.reason,
            backdropDismiss: false,
            buttons: [
              {
                text: "ok",
              }
            ]
          })

        });
        pay.on("payment.cancelled", function (data: any) {
          Alert(data.error.reason);
          // Additional handling or redirection logic can be added here if needed.
        });
        pay.open()
        setShowLoading(false);
      }
      else {
        setShowLoading(false);
        Alert({
          header: "Atmagram",
          message: response.data.errors[0],
          backdropDismiss: false,
          buttons: ["Ok"]
        })
      }
    })
      .catch((error) => {
        Alert(error)
        setShowLoading(true);

      });
  }
  const getBtnId = (e: any) => {
    console.log(e.target.value, "deityselect")
    if (offerings.length > 0) {
      setDeityOffer([])
      setDeityId(e.target.value)
      for (let i = 0; i < offerings.length; i++) {
        console.log(e.target.value === offerings[i].deityDto.id, "checkkkk")
        console.log(offerings[i].deityDto.id, "offfff")
        if (e.target.value === offerings[i].deityDto.id) {
          console.log(offerings[i].deityDto, "dtoooo")
          setDeityname(offerings[i].deityDto.deityName)
          setDeityOffer(offerings[i].poojaOfferings)
        }
      }
    }
  };

  const [offtiming, setOfftiming] = useState("")
  const [offdescription, setOffdecription] = useState("")
  const [infomodal, setInfomodal] = useState(false)

  const informationview = (item: any) => {
    console.log(item, "itemmm")
    var time: any;
    if (item.offeringTime != null) {
      if (item.offeringTime != null) {
        if (item.offeringTime.includes("||")) {
          time = (item.offeringTime).split("||").join(",")
        }
        else if (item.offeringTime.includes("|")) {
          time = (item.offeringTime).split("|").join(",");
        }
        else {
          time = item.offeringTime
        }
      }
    }
    setOffername(item.name)
    setOfftiming(time)
    setOffdecription(item.description)
    setInfomodal(true)
  }

  const fromdate = (e: any) => {
    setFdate(e.detail.value)
    setTdate("")
    setTo("")
    var ii = e.detail.value;
    var str = moment(ii).format('YYYY-MM-DDThh:mm:ss')
    setFrom(str)
  }
  const delCart = (e: any) => {
    Authservice.removeCart(usertoken, e.id).then((response) => {
      // Cart();
      if (response.data.resposeSuccess) {
        Alert('Item removed from cart')
        // Cart();
      }

    })
      .catch((error) => {
        Alert(error)

      });

  }
  const todate = (e: any) => {
    setTdate(e.detail.value)
    var ii = e.detail.value;
    var str = moment(ii).utcOffset('+05:30').format('YYYY-MM-DDThh:mm:ss')
    setTo(str)
  }
  const setPaymentData = (list: any) => {
    setOrderList([]);
    for (let order of list) {
      var data: any = {
        "id": order.id,
        "userModel": {
          "id": order.userModel.id
        },
        "poojaOfferings": {

          "id": order.poojaOfferings.id,
          "price": order.poojaOfferings.price
        },
        "firstName": order.firstName,
        "mailId": order.mailId,
        "mobileNumber": order.mobileNumber,
        "deliveryAddress": order.deliveryAddress,
        "prasadhamDelFlag": order.prasadhamDelFlag,
        "fromDate": order.fromDate,
        "toDate": order.toDate,
        "payDakshinaToPriestFlag": order.payDakshinaToPriestFlag,
        "payDakshinaToTempleFlag": order.payDakshinaToTempleFlag,
        // "dakshinaAmountForPriest": order.dakshinaAmountForPriest,
        // "dakshinaAmountToTemple": order.dakshinaAmountToTemple,
        "delFlag": order.delFlag,
        "noOfMonths": order.noOfMonths
      }
      console.log(data)
      if (order.payDakshinaToTempleFlag) {
        data["dakshinaAmountToTemple"] = order.dakshinaAmountToTemple;
      }
      if (order.payDakshinaToPriestFlag) {
        data["dakshinaAmountForPriest"] = order.dakshinaAmountForPriest;
      }

      // setOrderList([...orderList,data])
      //     var finalData=  {
      //         "userCartDetailDtoLst":data
      // }

      // setPayData(finalData);
      //  setPayData(finalData) //
      // setPayData([...payData,data])

      // console.log("p;ay",paymentData);
      payData.push(data)


    }


  }
  const proceedPayment = () => {

    console.log(payData)
    var obj = { "userCartDetailDtoLst": payData }

    console.log(paymentdata)

    Authservice.payInit(usertoken, obj).then((response: any) => {


      console.log(response.data)
      window.location.reload()
    })

  }


  const [index, setIndex] = useState(0);
  const preventdata = (e: any) => {
    e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
    e.target.addEventListener('keydown', function (u: any) {
      if (u.which === 38 || u.which === 40) {
        u.preventDefault();
      }
    })
  }
  const sameas = (e: any) => {
    const checkboxElement: any = checkboxRef.current;

    if (e.detail.checked) {
      console.log(e, "eeeee")
      setChe(true)
      console.log(userAddress, "useraddress")
      if (userAddress != null && userAddress != "") {
        var pin = add.split(',');
        var arrLlen = pin.length - 1;
        var address: any = "";
        for (var a = 0; a < arrLlen; a++) {
          if (a == 0) {
            address = address + pin[a];

          } else {
            address = address + "," + pin[a];
          }

        }
        if (checkboxElement) {
          checkboxElement.setAttribute('aria-checked', String(true));
          checkboxElement.setAttribute('checked', String(true));
        }
        setDelivery(address)
        setPin(pin[arrLlen])
        console.log(che, "ssvsfsvf")
      } else {
        setChe(false)
        if (checkboxElement) {
          checkboxElement.setAttribute('aria-checked', String(!checkboxElement.checked));
          checkboxElement.setAttribute('checked', String(!checkboxElement.checked));
        }
        console.log(che, "111")
        Alert("Enter Address Details at My Profile")

      }

    } else {
      if (checkboxElement) {
        checkboxElement.setAttribute('aria-checked', String(false));
        checkboxElement.setAttribute('checked', String(false));
      }
      setChe(false)
      setDelivery("")
      setPin('')
    }

  }
  const openmodal = () => {
    setModalopen(true)
  }
  const openmodal1 = () => {
    setModalopen1(true)
  }
  const [delcrgField, setDelcrgField] = useState(false)
  const Radio = (e: any) => {
    setRadiodel(e.detail.value)
    if (e.detail.value == "false") {
      setDeladd(false);
      setDelivery("")
      setPin('')
      setChe(false)
      setDelcrgField(false)
    }
    else {
      setDeladd(true);
      setDelcrgField(true)

    }
  }
  const numberOnlyValidation = (event: any) => {
    const pattern = /[0-9,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  const [des, setDes] = useState("")
  const [time, setTime] = useState("")
  const [offeringName, setOffeingrName] = useState("");
  const [desmodal, setdesmodal] = useState(false)
  const View = (item: any) => {
    var time: any;
    if (item.offeringTime != null) {
      if (item.offeringTime.includes("||")) {
        time = (item.offeringTime).split("||").join(",")
      }
      else if (item.offeringTime.includes("|")) {
        time = (item.offeringTime).split("|").join(",");
      }
      else {
        time = item.offeringTime
      }
    }
    setOffeingrName(item.name);
    setDes(item.description);
    setTime(time)
    setdesmodal(true);
  }

  const useOutsideAlerter = (refer: any, offref: any, tempdonref: any, donref: any, logref: any, signref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (refer.current && !refer.current.contains(event.target)) {
          setIsshow(false)
        }
        if (offref.current && !offref.current.contains(event.target)) {
          setdesmodal(false)
        }
        if (tempdonref.current && !tempdonref.current.contains(event.target)) {
          setModalopen1(false)
        }
        if (donref.current && !donref.current.contains(event.target)) {
          setModalopen(false)
        }
        if (logref.current && !logref.current.contains(event.target)) {
          setLoginmodal(true)
        }
        if (signref.current && !signref.current.contains(event.target)) {
          setSignupmodal(true)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [refer]);
  }
  const deitymodal: any = useRef(null);
  const offeringmodal = useRef(null)
  const templedonationmodal = useRef(null);
  const donationmodal = useRef(null)
  const loginmodalref = useRef(null);
  const signupmodalref = useRef(null)

  useOutsideAlerter(deitymodal, offeringmodal, templedonationmodal, donationmodal, loginmodalref, signupmodalref);
  const [logemail, setLogemail] = useState("")
  const [password, setPassword] = useState("");
  const [loginphone, setLoginPhone] = useState("")
  const [loginmodal, setLoginmodal] = useState(false)
  const [loginIndex, setLoginIndex] = useState(0);
  const [showOtp, setShowOtp]: any = useState(false);
  const [otp, setOtp] = useState("");
  const [confirmationResult, setconfirmationResult]: any = useState();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [loginalertmodal, setLoginalertmodal] = useState(false)
  const [loginalertdata, setLoginalertdata] = useState("")

  const phoneLogin = () => {
    setLoginIndex(1)

  }
  const emailLogin = () => {
    setLoginIndex(0)
  }

  const loginalertmodalaction = (e: any, data: any) => {
    setLoginalertmodal(e)
    setLoginalertdata(data)
  }

  const alertmodalclose = () => {
    if (loginalertdata === "Register Successfully") {
      setLoginmodal(true)
      setLoginalertmodal(false)
    }
    else {
      setLoginalertmodal(false)
    }
    setLoginalertdata("")
  }

  const checkMobile = () => {
    const errors: any = {};
    if (loginphone === undefined || loginphone === "") {
      errors.loginPhone = "Mobile Number is required"
    } else if (!isValidLoginNumber) {
      errors.loginPhone = "Enter valid Mobile Number"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      UserService.validMob(loginphone).then((res) => {
        console.log(res, "otpres")
        if (res.data === true) {
          getOTP();
          setShowLoading(false);
        } else {
          setShowLoading(false);
          // alert("Please complete registration. Then only you can login with Atmagram")
          loginalertmodalaction(true, "Please complete registration. Then only you can login with Atmagram")
          // Alert({ header: 'Atmagram', message: "Please complete registration. Then only you can login with Atmagram",buttons: ['OK'], cssClass: 'custom-alert' })
        }

      }).catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
    }
  }

  const getOTP = () => {
    let verify = new RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        // alert("Response expired. Please solve reCAPTCHA again")
        loginalertmodalaction(true, "Response expired. Please solve reCAPTCHA again")
      }
    }, auth);

    signInWithPhoneNumber(auth, loginphone, verify)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log(confirmationResult);
        setShowOtp(true)
        setconfirmationResult(confirmationResult)
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        loginalertmodalaction(true, "Something went wrong, please try again later.")
        // alert("Something went wrong, please try again later.")
        // ...
      });
  }

  const verifyOTP = () => {
    // var credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
    // console.log(credential);
    const errors: any = {};
    if (otp === undefined || otp === "" || otp == null) {
      errors.otp = "Enter OTP"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      confirmationResult.confirm(otp).then((result: any) => {
        // User signed in successfully.
        const user = result.user;
        console.log(result);
        setShowLoading(true);
        UserService.mobileLogin(loginphone)
          .then((response) => {
            if (response.data.responseText.startsWith("SUCCESS")) {
              if (response.data.responseText.startsWith("SUCCESS")) {
                // if (Capacitor.getPlatform() === "web") {
                //   console.log(response, "ressss")
                if (response.data.userModel.role !== "ROLE_USER") {
                  // Alert("This Account is not a user")
                  loginalertmodalaction(true, "This Account is not a user")
                }
                else
                  if (response.data.userModel.uflag === "false") {
                    console.log(response.data.userModel.uflag, "uflag")
                    // Alert("Account Not Approved Yet")
                    loginalertmodalaction(true, "Account Not Approved Yet")
                  }
                  else {
                    localStorage.setItem("token", response.data.userToken);
                    localStorage.setItem("UserId", response.data.userModel.id);
                    localStorage.setItem("UserData", response.data.userModel.role);
                    localStorage.setItem("uFlag", response.data.userModel.uflag);
                    localStorage.setItem("Email", response.data.userModel.email);
                    localStorage.setItem("Phone", response.data.userModel.mobileNumber);
                    setUFlag(response.data.userModel.uflag)
                    setToken(response.data.userToken)
                    setUserData(response.data.userModel.role)
                    if (response.data.userModel.firstName !== null) {
                      localStorage.setItem("Name", response.data.userModel.firstName);
                    }
                    if (response.data.userModel.address !== null) {
                      localStorage.setItem("Address", response.data.userModel.address);
                    }
                    setUserdata(response.data.userModel.role)
                    setUserAddress(response.data.userModel.address);
                    setAdd(response.data.userModel.address)
                    setUsertoken(response.data.userToken)
                    setLoginmodal(false)
                    const sortedDates = dates
                      .map((e: any) => new Date(e.value))
                      .sort((a: any, b: any) => a - b);
                    setSortDates(sortedDates)
                    console.log(response.data.userToken)
                    var ff = moment(fdate).format('DD-MM-YYYY')
                    setffdate(ff);
                    var tt = moment(tdate).format('DD-MM-YYYY')
                    setttdate(tt);
                    setActionFlag(false)
                    setId(id)
                    console.log("id", id)
                    Alert({
                      header: "Atmagram",
                      message: "LoggedIn Successfully",
                      backdropDismiss: false,
                      buttons: [
                        {
                          text: "ok",
                          handler: () => {
                            if (payFlag == 1) {
                              savebook(response.data.userToken)
                            } else if (payFlag == 2) {
                              proceedtopayment(response.data.userToken)
                            }
                          }
                        }
                      ]
                    })
                    // bookpayment(response.data.userToken);
                  }
              }
              // }
              else {
                // Alert(response.data.responseText.split("ERROR:")[1]);
                loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
              }
            } else {
              // alert(response.data.responseText.split("ERROR:")[1]);
              loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
            }
            setShowLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setShowLoading(false);
          });

        // ...
      }).catch((err: any) => {
        // User couldn't sign in (bad verification code?)
        console.log(err.error);
        // alert("Not a valid verification code or Code expired, please try again")
        loginalertmodalaction(true, "Not a valid verification code or Code expired, please try again")
        // if(error.error.message=='SESSION_EXPIRED'){
        //   alert("OTP expired");
        // }else{

        // }
        // ...
      });
    }
  }


  const goBack = () => {
    setShowOtp(false)
  }

  const loginmodalclose = () => {
    setLoginmodal(false)
    setLoginIndex(0)
    // setLogemail("")
    // setPassword("")
    // setLoginPhone("")
    // setFormErrors({})
  }
  const signupmodalclose = () => {
    setSignupmodal(false);
    setLoginmodal(true);
    // setFormErrors({})
  }
  const Loginsubmit = () => {
    const errors: any = {};

    if (logemail.trim() === '') {
      errors.logemail = 'Email is required';
    }
    else if (validator.isEmail(logemail) === false) {
      errors.logemail = "Enter Valid Email Address";
    }

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      UserService.Login(logemail, password)
        .then((response) => {
          setShowLoading(false);

          if (response.data.responseText.startsWith("SUCCESS")) {
            // if (Capacitor.getPlatform() === "web") {
            //   console.log(response.data)
            if (response.data.userModel.role !== "ROLE_USER") {
              loginalertmodalaction(true, "This Account is not a user")
            }
            else
              if (response.data.userModel.uflag === "false") {
                console.log(response.data.userModel.uflag, "uflag")
                loginalertmodalaction(true, "Account Not Approved Yet")
              }
              else {
                localStorage.setItem("token", response.data.userToken);
                localStorage.setItem("UserId", response.data.userModel.id);
                localStorage.setItem("UserData", response.data.userModel.role);
                localStorage.setItem("uFlag", response.data.userModel.uflag);
                localStorage.setItem("Email", response.data.userModel.email);
                localStorage.setItem("Phone", response.data.userModel.mobileNumber);
                setUFlag(response.data.userModel.uflag)
                setToken(response.data.userToken)
                setUserData(response.data.userModel.role)
                if (response.data.userModel.firstName !== null) {
                  localStorage.setItem("Name", response.data.userModel.firstName);
                }
                if (response.data.userModel.address !== null) {
                  localStorage.setItem("Address", response.data.userModel.address);
                }

                setUserdata(response.data.userModel.role)
                setUserAddress(response.data.userModel.address);
                setAdd(response.data.userModel.address)
                setUsertoken(response.data.userToken)
                setLoginmodal(false)
                const sortedDates = dates
                  .map((e: any) => new Date(e.value))
                  .sort((a: any, b: any) => a - b);
                setSortDates(sortedDates)
                console.log(response.data.userToken)
                var ff = moment(fdate).format('DD-MM-YYYY')
                setffdate(ff);
                var tt = moment(tdate).format('DD-MM-YYYY')
                setttdate(tt);
                setIndex1(5);
                setActionFlag(false)
                setId(id)
                console.log("id", id)
                //calDelCharge(response.data.userToken);
                Alert({
                  header: "Atmagram",
                  message: "LoggedIn Successfully",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                      handler: () => {
                        if (payFlag == 1) {
                          savebook(response.data.userToken)
                        } else if (payFlag == 2) {
                          proceedtopayment(response.data.userToken)
                        }
                      }
                    }
                  ]
                })
              }
            // localStorage.setItem("Address", response.data.userModel.address);
            // localStorage.setItem("Name", response.data.userModel.firstName + response.data.userModel.lastName);
            console.log(response.data.userModel.uflag);
          }
          //  }
          else {
            loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
          }
        })
        .catch((error) => {
          setLoginmodal(true)
          console.log(error);
          setShowLoading(false);
        });
    }
  };
  const mailpat = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;


  const Searchlog = (value: any) => {
    if (value == "Enter") {
      // if (logemail.length === 0) {
      //   alert("Enter Email Address")
      // }
      // else if (validator.isEmail(logemail) === false) {
      //   alert("Enter Valid Email Address")
      // }
      // else if (password.length == 0) {
      //   alert("Enter your Password")
      // }
      // else {
      Loginsubmit()
      // }
    }
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "1px solid black",
      boxShadow: 'none',
      fontSize: '15px',
      // height: '44px',
      width: '90%',
      outline: 'none',
      textAlign: "left"
    }),
  };
  const handleStateChange = (e: any) => {
    console.log(e)
    if (e) {
      setStateOption(e);
      setState(e.value);
      getAllDistricts(e)
    } else {
      setStateOption(null)
      setState("")
      setDistricts([])
      setDistrictOption(null)
      setDistrict("")
    }
  }
  const handleCountryChange = (e: any) => {
    console.log(e)
    if (e) {
      setCountryOption(e);
      setCountry(e.value);
    } else {
      setCountryOption(null)
      setCountry("")
    }
  }
  const handleDistrictChange = (e: any) => {
    console.log(e)
    if (e) {
      setDistrictOption(e);
      setDistrict(e.value);
    } else {
      setDistrictOption(null)
      setDistrict("")
    }
  }

  const phoneInputRef = useRef(null)
  const loginphoneRef = useRef(null)
  const [isValidLoginNumber, setIsValidLoginNumber] = useState(false);

  const moveCursorToEnd = (value: any) => {
    const input: any = phoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumberSign(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumberSign(false);
      console.log(error)
    }
    setPhone1(value)
  };

  const loginmoveCursorToEnd = (value: any) => {
    const input: any = loginphoneRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidLoginNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
      if (value === '' || value === undefined) {
        setFormErrors({ ...formErrors, loginPhone: "Mobile Number is required" })
      }
      else if (!(phoneNumberObject ? phoneNumberObject.isValid() : false)) {
        setFormErrors({ ...formErrors, loginPhone: "Please Enter Valid Mobile Number" })
      }
      else {
        setFormErrors({ ...formErrors, loginPhone: "" })
      }
    } catch (error) {
      setIsValidLoginNumber(false);
      console.log(error)
    }
    setLoginPhone(value)
  };

  const [message, setMessage] = useState("");
  const [iserror, setIserror] = useState(false);

  const namecheck = /^[a-zA-Z\s]*$/
  const submit = () => {
    const errors: any = {};

    if (firstname1 == "") {
      errors.firstname1 = "Enter First Name"
    }
    else if (namecheck.test(firstname1) == false) {
      errors.firstname1 = 'Alphabets only Allowed in First Name'
    }
    else if (firstname1.charAt(0) == " ") {
      errors.firstname1 = 'Empty Space at First Name'
    }
    if (phone1 === '' || phone1 === undefined) {
      errors.phone1 = "Mobile Number is required"
    } else if (!isValidPhoneNumberSign) {
      errors.phone1 = "Please Enter Valid Mobile Number"
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (email1.trim() === '') {
      errors.email1 = 'Email is required';
    }
    else if (validator.isEmail(email1) === false) {
      errors.email1 = "Enter Valid Email Address";
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }
    if (password1.trim() === '') {
      errors.password1 = 'Password is required';
    }
    if (confirmpassword.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (password1 != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      UserService.Registermaterials(
        // aadhar1,
        // addr,
        // pincode1,
        // area1,
        // city1,
        // state1,
        // country1,
        email1,
        firstname1,
        // lastname1,
        phone1,
        password1,
        user,
        user
      )
        .then((response) => {
          console.log("res", response);
          setShowLoading(false);
          if (response.responseText.startsWith("SUCCESS")) {
            setIssuccess(true);
            // setAadhar1("");
            // setAddr("");
            // setArea1("");
            // setCity1("");
            setConfirmpassword("");
            // setCountry1("");
            setEmail1("");
            setFirstname1("");
            // setLastname1("");
            setPassword1("");
            setPhone1("");
            setUser("");
            // setPincode1("");
            loginalertmodalaction(true, "Register Successfully")
            setSignupmodal(false)
            console.log(response, "resss =>")
          } else if (response.responseText == "ERROR:Email Id already exist") {
            loginalertmodalaction(true, "Email Id  Exists, Try Any Other Email Id")
          } else if (response.responseText == "ERROR:MobileNumber already exist") {
            loginalertmodalaction(true, "Mobile Number  Exists, Try Any Other Mobile Number")
          } else {
            loginalertmodalaction(true, "User Not Register Successfully")
          }
        })
        .catch((error) => {
          console.log(error)
          setShowLoading(false);
          loginalertmodalaction(true, "Something went wrong")
        });
      console.log(aadhar1,
        // addr,
        // pincode1,
        // area1,
        // city1,
        // state1,
        // country1,
        email1,
        // firstname1,
        // lastname1,
        phone1,
        password1,
        user,
      )
    }
  }

  const Searchsign = () => {
    submit()
  }

  return (
    <IonPage>
      <IonAlert
        cssClass={"custom_alert"}
        isOpen={showAlert}
        backdropDismiss={false}
        onDidDismiss={() => setShowAlert(false)}
        header="Confirmation"

        message="Are you sure you want to leave? If you proceed, your order details will be lost. Please click Cancel to continue with your order."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',

            handler: () => {
              setActionFlag(false)
              setShowAlert(false); // Update the flag to indicate that the action was clicked
            },
          },
          {
            text: 'Leave',
            handler: handleLeave,
          },
        ]}

      />
      <Modal show={loginalertmodal} className="login_alert_modal" centered>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <h5 className="login_alert_title">Atmagram</h5>
          </div>
          <div style={{ textAlign: "center" }}>
            <IonText>{loginalertdata}</IonText>
          </div>
          <div style={{ textAlign: "right", paddingTop: "15px" }}>
            <IonText style={{ cursor: "pointer" }} onClick={() => alertmodalclose()}>OK</IonText>
          </div>
        </Modal.Body>
      </Modal>
      <IonContent>
        <HeadFoot User={"login"} Role="Role" Name={headname} />
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() => setShowLoading(false)}
        // duration={5000}
        />
        <IonRow style={{ position: "absolute" }}>
          <IonCol>
            <IonAlert
              isOpen={iserror}
              onDidDismiss={() => setIserror(false)}
              cssClass="my-custom-class"
              header={"Error!"}
              message={message}
              buttons={["Dismiss"]}
            />
          </IonCol>
        </IonRow>
        <Modal show={signupmodal} className='login-modal' centered>
          <ModalHeader className="profilenodal-Header">
            <h5 className="profileModal-title">Sign Up</h5>
            <div>
              <IonIcon src={close} className="close_icon" onClick={() => signupmodalclose()} />
            </div>
          </ModalHeader>
          <Modal.Body ref={signupmodalref}>

            <form onSubmit={handleSubmit(submit)}>
              <div>
                <IonRow>
                  <IonCol size="12" size-lg="7" className="email_container">
                    <div>
                      <IonLabel className="label_align">
                        First Name
                        <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="text" className="select_user"
                        value={firstname1}
                        // required
                        onBlur={() => Validation("firstname")}
                        onIonChange={(e) => { setFirstname1(e.detail.value!); ChangeValidation("firstname", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.firstname1 && <span className="alert_input_bottom ">{formErrors.firstname1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="12" size-lg="7" >
                    <div>
                      <IonLabel className="label_align">
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <div className="select_user">
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          value={phone1}
                          ref={phoneInputRef}
                          onBlur={() => Validation("mobile")}
                          onChange={(value: any) => { moveCursorToEnd(value); ChangeValidation("mobile", value) }}
                        /></div>
                    </div>
                    {formErrors.phone1 && <span className="alert_input_bottom ">{formErrors.phone1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-lg="7" >
                    <div>
                      <IonLabel className="label_align" >
                        E-mail <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="text"
                        value={email1}
                        className="select_user"
                        onBlur={() => Validation("email")}
                        onIonChange={(e) => { setEmail1(e.detail.value!); ChangeValidation("email", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.email1 && <span className="alert_input_bottom ">{formErrors.email1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="12" size-lg="7" className="pass_container">
                    <div>
                      <IonLabel className="label_align">
                        Password <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        value={password1}
                        clearOnEdit={false}
                        className="select_user"
                        onBlur={() => Validation("password")}
                        onIonChange={(e) => { setPassword1(e.detail.value!); ChangeValidation("password", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.password1 && <span className="alert_input_bottom ">{formErrors.password1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="12" size-lg="7" className="pass_container">
                    <div>
                      <IonLabel className="label_align">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        className="select_user"
                        clearOnEdit={false}
                        value={confirmpassword}
                        onBlur={() => Validation("confirmpassword")}
                        onIonChange={(e) => { setConfirmpassword(e.detail.value!); ChangeValidation("confirmpassword", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.confirmpassword && <span className="alert_input_bottom ">{formErrors.confirmpassword}</span>}
                  </IonCol>
                </IonRow>
                <div style={{ display: "flex", alignItems: "center" }} className="pass_container">
                  <div>
                    <Link onClick={(event: any) => { setLoginmodal(true); event.preventDefault(); setSignupmodal(false) }} className="forgot_pass_css" to={"#"}>Already have an account?</Link>
                  </div>
                </div>
                <div className={firstname1.length === 0 ||
                  email1.length === 0 ||
                  password1.length === 0 ||
                  confirmpassword.length === 0 ? "disableCursor signupbtn_container_width" : ""}>
                  <IonButton
                    type="submit"
                    disabled={
                      firstname1.length === 0 ||
                      email1.length === 0 ||
                      password1.length === 0 ||
                      confirmpassword.length === 0
                    }                  >
                    <span className="button_padding">Sign Up</span>
                  </IonButton>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={loginmodal} className='login-modal' centered>
          <ModalHeader className="profilenodal-Header">
            <h5 className="profileModal-title">Login</h5>
            <div>
              <IonIcon src={close} className="close_icon" onClick={() => loginmodalclose()} />
            </div>
          </ModalHeader>
          <Modal.Body ref={loginmodalref}>

            {loginIndex === 0 ?
              <form onSubmit={handleSubmit(Loginsubmit)} onKeyPress={e => Searchlog(e.key)}>
                <div>
                  <IonRow>
                    <IonCol size="11" size-lg="7" className="email_container">
                      <div>
                        <IonLabel className="label_align">
                          E-mail <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput className="select_user"
                          type="text"
                          placeholder="Enter mail-id"
                          value={logemail}
                          onBlur={() => Validation("login email")}
                          onIonChange={(e) => { setLogemail(e.detail.value!); ChangeValidation("login email", e) }}
                        ></IonInput>
                      </div>
                      {formErrors.logemail && <span className="alert_input_bottom ">{formErrors.logemail}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow >
                    <IonCol size="11" size-lg="7" className="pass_container">
                      <div>
                        <IonLabel className="label_align">
                          Password <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="password" className="select_user"
                          clearOnEdit={false}
                          placeholder="Enter password"
                          onBlur={() => Validation("login password")}
                          onIonChange={(e) => { setPassword(e.detail.value!); ChangeValidation("login password", e) }}
                        ></IonInput>
                      </div>
                      {formErrors.password && <span className="alert_input_bottom ">{formErrors.password}</span>}
                    </IonCol>
                  </IonRow>
                  <div style={{ display: "flex", alignItems: "center" }} className="pass_container">
                    <div>
                      <Link onClick={(event: any) => { setSignupmodal(true); event.preventDefault(); setLoginmodal(false) }} className="forgot_pass_css" to={"#"}>Don't have account?</Link>
                    </div>
                  </div>
                  <div className="login_modal_btn_container">
                    <div className={logemail.length === 0 || password.length === 0 ? "cursor_block" : ""}>
                      <IonButton
                        type="submit"
                        disabled={logemail.length === 0 || password.length === 0}
                      >
                        <span className="button_padding">Login</span>
                      </IonButton>
                    </div>
                    <div className="center or_text_container">
                      <p> or</p>
                    </div>
                    <IonButton onClick={phoneLogin}>
                      <span className="button_padding">Login With Phone</span>
                    </IonButton>
                  </div>
                </div>
              </form> : <div>

                {!showOtp ? <>
                  <IonRow>
                    <IonCol size="11" size-lg="7" className="email_container">
                      <IonLabel className="label_align">
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <PhoneInput
                        international
                        defaultCountry="IN"
                        value={loginphone}
                        className="select_user"
                        ref={loginphoneRef}
                        // flags=false
                        onBlur={(e) => Validation("loginphone")}
                        onChange={(value: any) => { loginmoveCursorToEnd(value); ChangeValidation("loginphone", value) }}
                      />
                      {formErrors.loginPhone && <span className="alert_input_bottom ">{formErrors.loginPhone}</span>}
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="11" size-lg="7" className="pass_container">
                      <div id="recaptcha-container"></div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" size-lg="12" className="textalign-center login_modal_btn_container">
                      <div className={loginphone === undefined || loginphone === undefined || !isValidLoginNumber ? "cursor_block" : ""}>
                        <IonButton
                          disabled={loginphone === undefined || loginphone === undefined || !isValidLoginNumber}
                          onClick={checkMobile}
                        >
                          <span className="button_padding">Get OTP</span>

                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                </> : <></>}

                {showOtp ?
                  <>
                    <IonRow>
                      <IonCol size="11" size-lg="7" className="email_container">

                        <IonLabel className="label_align">
                          Enter OTP <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput className="select_user"
                          onBlur={(e) => Validation("otp")}
                          onIonChange={(e) => { setOtp(e.detail.value!); ChangeValidation("otp", e) }}
                          required
                        ></IonInput>

                        {formErrors.otp && <span className="alert_input_bottom ">{formErrors.otp}</span>}
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size="11" size-lg="7" className="pass_container" >
                        <span className="go_back_style"
                          onClick={goBack}
                        >go back
                        </span>
                      </IonCol>
                    </IonRow>
                    <div className="login_modal_btn_container">
                      <IonButton
                        onClick={verifyOTP}
                      >
                        <span className="button_padding">Verify OTP</span>

                      </IonButton>
                    </div>
                  </> : <></>
                }
                <div className="login_modal_btn_container">
                  <div className="center or_text_container"><p>
                    or</p></div>
                  <IonButton onClick={emailLogin}>
                    <span className="button_padding">Login With Email</span></IonButton>
                </div>
              </div>}
          </Modal.Body>
        </Modal>
        <Modal show={desmodal} className="custom-modal-wrapper">
          <Modal.Body ref={offeringmodal} >
            <div className="modal-header" style={{ padding: "15px 0px" }}>
              <h5 className="modal-title">{offeringName}</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setdesmodal(false)}>
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="custom-modal-deity">
              <IonRow>
                <IonCol sizeLg="3" sizeMd="3" sizeSm="3" sizeXs="4">
                  <IonLabel style={{ fontWeight: "bold" }}>Time</IonLabel>
                </IonCol>
                <IonCol sizeLg="0.5" sizeMd="0.5" sizeSm="0.5" sizeXs="0.5">
                  <IonText style={{ paddingRight: "2%", textAlign: "right" }}>:</IonText>
                </IonCol>
                <IonCol sizeLg="8.5" sizeMd="8.5" sizeSm="8.5" sizeXs="7.5">
                  <IonText>{time}</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol sizeLg="3" sizeMd="3" sizeSm="3" sizeXs="4">
                  <IonLabel style={{ fontWeight: "bold" }}>Description</IonLabel>
                </IonCol>
                <IonCol sizeLg="0.5" sizeMd="0.5" sizeSm="0.5" sizeXs="0.5">
                  <IonText style={{ paddingRight: "2%", textAlign: "right" }}>:</IonText>
                </IonCol>
                <IonCol sizeLg="8.5" sizeMd="8.5" sizeSm="8.5" sizeXs="7.5">
                  <IonText>{des}</IonText>
                </IonCol>
              </IonRow>
            </div>
          </Modal.Body>
        </Modal>

        {index1 === 0 ? <>

          <div className="right_col cart_padding" style={{  background: "" }}>
            <div className='cart-badge-parent' onClick={() => {
              history.push("/mycart")

            }} >
              <IonIcon src={cart} className="grammerce_main_cart_btn_mobile" />
              <IonBadge className='cart-badge'>{cartCount}</IonBadge>
            </div>
          </div>

          <IonRow className='deity_container page_content_padding' style={{ paddingTop: "10px" }}>

            {deitylist.length > 0 ? <>
              {deitylist.map((item: any, i) => (
                <IonCol sizeLg='6' sizeXl='4' size-md='6' size-xs='12' size-sm='12'>
                  <IonCard className='deity_cards'>
                    <IonRow>
                      <IonCol style={{ display: "flex" }} size='5'>
                        <IonImg className="deity_god_image" src={item.image + "?v=" + new Date()} />
                      </IonCol>
                      <IonCol size='7'>
                        <IonRow style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <IonCol size='10'>
                            <IonText className='deity_name'>{item.deityName}</IonText>
                          </IonCol>
                          <IonCol size='2'>
                            <IonIcon onClick={() => Viewinfo(item)} className='info_icon' src={informationCircleSharp} />
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonText className='deity_description'>
                            {item.deityDescription}
                          </IonText>
                        </IonRow>
                        <IonRow style={{ marginTop: "5px" }}>
                          <IonButton onClick={(e) => Details(item)}>
                            <span className='button_padding'>
                              Select Area
                            </span>
                          </IonButton>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              ))
              }</> : <div className='no-materials'>
              <IonText>Sorry, No data available</IonText>
            </div>}
          </IonRow> </> : index1 === 1 ? <IonGrid className='about_deity_container page_content_padding'>

            <IonRow style={{ paddingTop: "20px" }}>
              <IonCol size="1" className="center_col">
                <div >
                  <IonIcon onClick={() => {
                    backtodeity()
                  }} src={arrowBack} className="point back" size="large" />
                </div>
              </IonCol>
              <IonCol size='11'>
                <IonText className='bio_deity'>
                  Biography
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size-lg="12" size-md="12" size-xs="12">
                <IonImg src={image} className='bio_right_img bio_deity_image' />
                <p className='bio_deity_description'>{description}</p>
              </IonCol>
            </IonRow>
          </IonGrid> : index1 === 2 ? <div className='page_content_padding'>
            <IonGrid>
              <IonRow>
                <IonCol className='sa' size='12' sizeLg='5.5'>
                  <form onSubmit={handleSubmit(Submit)} onKeyPress={e => SearchF(e.key)}>
                    <IonRow>
                      <IonCol className='center_col' size='1' onClick={() => {
                        templebacktodeity()
                      }}>
                        <div >
                          <IonIcon src={arrowBack} className="point back" size="large" />
                        </div></IonCol>
                      <IonCol className='center_col' size='10'>
                        <div >
                          <IonText className='select_text'>Select Area</IonText>
                        </div></IonCol>
                    </IonRow>
                    <IonRow className='justify-ion-text-center'>
                      {/* <IonCol size-xs="12" size-sm="6" size-lg="6" size-md="6">
                                                <IonLabel className='textsize'> Deity</IonLabel>
                                            </IonCol> */}
                      <IonCol className='inputcol' size-xs="12" size-sm="12" size-lg="12" size-md="12">
                        <div className='center_col'>
                          <IonText className='deity_text_search'>{maindeityname}</IonText>

                        </div>

                      </IonCol>
                    </IonRow>

                    <IonRow className='To'>
                      <IonCol size='12'>
                        <IonLabel className='search_label'> Country</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol size='12'>
                        <Select
                          className="custom-select-dark"
                          options={countryLiistOptions}
                          value={countryOption}
                          placeholder="Select a country"
                          isClearable
                          styles={customStyles}
                          onChange={handleCountryChange}
                        />
                        {counErrors.country && <span className="alert_input_bottom ">{counErrors.country}</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow className='To'>
                      <IonCol size="12">
                        <IonLabel className='search_label'>State</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol size="12">
                        <Select
                          className={stateMenu ? "custom-select" : "custom-select-dark"}
                          options={stateLiistOptions}
                          value={stateOption}
                          placeholder="Select a State"
                          isClearable
                          styles={customStyles}
                          onChange={handleStateChange}
                          menuIsOpen={stateMenu}
                          onMenuClose={() => setStateMenu(false)}
                          onMenuOpen={() => setStateMenu(true)}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow className='To' >
                      <IonCol size="12">
                        <IonLabel className='search_label'>District</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol size="12">
                        <Select
                          className={districtMenu ? "custom-select" : "custom-select-dark"}
                          options={districtLiistOptions}
                          value={districtOption}
                          placeholder="Select a District"
                          isClearable
                          styles={customStyles}
                          onChange={handleDistrictChange}
                          menuIsOpen={districtMenu}
                          onMenuClose={() => setDistrictMenu(false)}
                          onMenuOpen={() => setDistrictMenu(true)}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow className='To'>
                      <IonCol>
                        <IonLabel className='search_label'>Village / Town</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol>
                        <IonInput className='search_text' value={town} onIonChange={(e) => setTown(e.target.value)} />
                      </IonCol>
                    </IonRow>
                    <IonRow style={{ display: "flex", justifyContent: "center" }}>
                      <div className="center"><p>
                        or</p></div>
                    </IonRow>
                    <IonRow className='To'>
                      <IonCol>
                        <IonLabel className='search_label'>Enter Temple Name </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol >
                        <IonInput className='search_text' value={temName} onIonChange={(e) => setTemName(e.target.value)} />
                      </IonCol>
                    </IonRow>

                    <div className='center_col padding_search'>


                      <div >
                        <IonButton className={Capacitor.getPlatform() === "ios" ? 'clear_all border_change_clear' : 'clear_all'} onClick={Clear}>
                          <span className='button_padding'>Clear All</span>

                        </IonButton>
                      </div>



                      <div>
                        <IonButton className='search_btn' type="submit">
                          <span className='button_padding'>Search</span>

                        </IonButton>
                      </div>




                    </div>
                  </form>
                </IonCol>
                {hideContent === true ? <></> : <IonCol ref={targetRef} className='oo' size='12' sizeLg='6.5'>
                  <IonRow>
                    <div style={{ textAlign: "center", width: "100%", marginTop: "1%" }}>
                      <IonText className='deity_text_search'>Temple Name</IonText>
                    </div>
                  </IonRow>
                  {/* <div style={{ marginTop: "5%" }}>
                    <DataTable
                      style={{ width: "100%" }}
                      columns={data1}
                      data={gods}
                      pagination
                      paginationPerPage={5}
                      paginationRowsPerPageOptions={[5, 10]}
                      noDataComponent="Sorry ! No result found"
                      onRowClicked={(item: any) => show(item.id, item.name)}
                    />
                  </div> */}
                  <div className='temple_list_container'>
                    <div className='templenamescroll'>
                      {showgod ?
                        <>
                          {gods.length == 0 ?
                            <div className='no_result'>
                              <IonLabel className='before_search_text'>Not a serviceable area</IonLabel>
                            </div> : gods.length != 0 ?
                              <>
                              <div style={{color:"red",textAlign:"center",paddingBottom:"10px",fontSize:"14px"}}>
                              <span >* Please click on one of the temples displayed in the list to proceed</span>

                              </div>
                                {
                                  gods.map((item: any, i: any) => (
                                    <div className='temple_list' onClick={() => show(item.id, item.name)}>
                                      <IonRow >
                                        <IonCol size='2' >
                                          <div className='multi-color-dot'></div>

                                        </IonCol>
                                        <IonCol size='10' >
                                          <IonLabel
                                          >{item.name}
                                          </IonLabel>
                                        </IonCol>


                                      </IonRow></div>
                                  ))
                                }
                              </> : <></>
                          }
                        </> : <div className='temple_bg'>
                          <div className='temple_budha_bg'>
                          </div>
                          <div className='search_display'>
                            <IonLabel className='before_search_text'>Search Your Temple</IonLabel>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </IonCol>}
              </IonRow>
            </IonGrid>
          </div> : index1 === 3 ? <div className="page_content_padding off_main_container">
            <IonRow style={{}}>

            </IonRow>
            <IonGrid>
              <IonRow style={{ display: "flex", alignItems: "center" }}>
                <IonCol size-md='1' sizeLg="1" size-xs="1" size-sm="1">
                  <IonIcon onClick={() => backtotemple()} src={arrowBack} className="point back" size="large"></IonIcon>
                </IonCol>
                <IonCol size-lg="7" size-md="7" size-xs="11" size-sm="11">
                  <IonRow>
                    <IonText className="offering_temple_name">
                      {tname}
                    </IonText>
                  </IonRow>
                </IonCol>
                <IonCol style={{ display: "flex", justifyContent: "center" }} size-lg="3" size-md="3" size-xs="12" size-sm="12">
                  <IonImg src={image} className="off_temple_image" />
                </IonCol>
              </IonRow>
              {Templedeity.length === 0 ? <></> : <IonRow>
                <IonCol style={{ marginLeft: '10px' }}>
                  <IonSelect value={deityId} onIonChange={(e) => getBtnId(e)} placeholder="select Deity" interface="popover" className="off_deity_dropdown">
                    {Templedeity.map((item: any, i: any) => (
                      <IonSelectOption value={item.id}>
                        {item.deityName}
                      </IonSelectOption>
                    ))}
                  </IonSelect></IonCol>
              </IonRow>}
              {deityOffer.length === 0 ?
                <IonRow style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <IonText className="offering_name">
                    No offerings Available
                  </IonText>
                </IonRow> : <IonRow>
                  {deityOffer.map((item: any, index: any) => {
                    var time: any;
                    if (item.offeringTime != null) {
                      if (item.offeringTime != null) {
                        if (item.offeringTime.includes("||")) {
                          time = (item.offeringTime).split("||").join(",")
                        }
                        else if (item.offeringTime.includes("|")) {
                          time = (item.offeringTime).split("|").join(",");
                        }
                        else {
                          time = item.offeringTime
                        }
                      }
                    }
                    return (
                      <IonCol size-lg="6" size-md="12" size-xs="12" size-sm="12">
                        <IonCard className="off_card_container">
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <IonIcon onClick={() => informationview(item)} src={informationCircleSharp} className="info_icon" />
                          </div>
                          <IonRow>
                            <IonText className="offering_name">
                              {item.name}
                            </IonText>
                          </IonRow>
                          <IonRow>
                            <IonCol style={{ paddingTop: "10px" }} size-lg="1" size-md="1" size-xs="0.5" size-sm="0.5">
                              <IonIcon src={checkmarkCircleSharp} className="tick" />
                            </IonCol>
                            <IonCol size-lg="3.5" size-md="3.5" size-xs="4.5" size-sm="4.5" className="label_container">
                              <IonText className="card_label">
                                Timing
                              </IonText>
                            </IonCol>
                            <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                              <IonText className="card_label">
                                :
                              </IonText>
                            </IonCol>
                            <IonCol size-lg="7" size-md="7" size-xs="6.5" size-sm="6.5">
                              <IonText className="card_label">
                                {time}
                              </IonText>
                            </IonCol>
                          </IonRow>
                          {/* <IonRow className="row_container">
                            <IonCol style={{ paddingTop: "10px" }} size-lg="1" size-md="1" size-xs="0.5" size-sm="0.5">
                              <IonIcon src={checkmarkCircleSharp} className="tick" />
                            </IonCol>
                            <IonCol size-lg="3.5" size-md="3.5" size-xs="4.5" size-sm="4.5" className="label_container">
                              <IonText className="card_label">
                                Evening
                              </IonText>
                            </IonCol>
                            <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                              <IonText className="card_label">
                                :
                              </IonText>
                            </IonCol>
                            <IonCol size-lg="6.5" size-md="6.5" size-xs="6.5" size-sm="6.5">
                              {time[1] === '' || time[1] === null ? <IonText className="card_label">
                                N/A
                              </IonText> : <IonText className="card_label">
                                {time[1]}
                              </IonText>}
                            </IonCol>
                          </IonRow> */}
                          <IonRow className="row_container">
                            <IonCol style={{ paddingTop: "10px" }} size-lg="1" size-md="1" size-xs="0.5" size-sm="0.5">
                              <IonIcon src={checkmarkCircleSharp} className="tick" />
                            </IonCol>
                            <IonCol size-lg="3.5" size-md="3.5" size-xs="4.5" size-sm="4.5" className="label_container">
                              <IonText className="card_label">
                                Price
                              </IonText>
                            </IonCol>
                            <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                              <IonText className="card_label">
                                :
                              </IonText>
                            </IonCol>
                            <IonCol size-lg="7" size-md="6.5" size-xs="6.5" size-sm="6.5">
                              <IonText className="price_style">
                                ₹ {item.price}
                              </IonText>
                            </IonCol>
                          </IonRow>
                          <IonRow style={{ marginTop: "5%", display: "flex", justifyContent: "center" }}>
                            <IonButton onClick={() => shows(item)}>
                              <span className="button_padding">
                                Book Now
                              </span>
                            </IonButton>
                          </IonRow>
                        </IonCard>
                      </IonCol>
                    )
                  })}
                </IonRow>}
            </IonGrid>
          </div> : index1 === 4 ? <div className="page_content_padding book_details_main_container">

            <IonGrid className="book_details_sub_container">
              <IonRow style={{ display: "flex", alignItems: "center" }}>
                {cartparams === undefined || cartparams === null ?
                  <IonCol size-lg="1" size-md="1" size-sm="2" size-xs="2">
                    <IonIcon onClick={() => sliderback()} src={arrowBack} className="point back" size="large"></IonIcon>
                  </IonCol> : <></>}
                <IonCol size-lg="11" size-md="11" size-sm="10" size-xs="10">
                  <IonText className="book_details_head">
                    Booking Details
                  </IonText>
                </IonCol>
              </IonRow>

              <IonRow className="book_content_container">
                <IonRow className="book_poojaname_row">
                  <IonText className="book_poojaname_head">
                    Pooja Name :  {offerName}
                  </IonText>
                </IonRow>
                <IonGrid style={{ padding: "0px" }}>
                  <IonRow className="book_subcontent_container">
                    <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12">
                      <IonRow>
                        <IonCol size-lg="1" size-md="1.5" size-xs="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-sm="4.5" size-xs="11">
                          <IonText className="book_details_label_infoget">
                            Temple Name
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                          <IonText className="book_details_label_infoget off_colon_display">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="11">
                          <IonText className="book_details_text">
                            {tname}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow className="label_row_container">
                        <IonCol size-lg="1" size-md="1.5" size-xs="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-sm="4.5" size-xs="11">
                          <IonText className="book_details_label_infoget">
                            Name
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                          <IonText className="book_details_label_infoget off_colon_display">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="11">
                          <IonInput className="off_input" value={username} onIonChange={(e: any) => setUsername(e.detail.value)} />
                        </IonCol>
                      </IonRow>
                      <IonRow className="label_row_container">
                        <IonCol size-lg="1" size-md="1.5" size-xs="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-sm="4.5" size-xs="11">
                          <IonText className="book_details_label_infoget">
                            Star
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                          <IonText className="book_details_label_infoget off_colon_display">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="11">
                          <IonInput className="off_input" value={star} onIonChange={(e: any) => setStar(e.detail.value)} />
                        </IonCol>
                      </IonRow>
                      <IonRow className="label_row_container">
                        <IonCol size-lg="1" size-md="1.5" size-xs="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-sm="4.5" size-xs="11">
                          <IonText className="book_details_label_infoget">
                            Prashadam Delivery
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                          <IonText className="book_details_label_infoget off_colon_display">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="11">
                          <IonRadioGroup className={Capacitor.getPlatform() === "ios" ? "ios_radio_btn" : ""} value={radiodel} onIonChange={(e: any) => Radio(e)}>
                            <IonCol>
                              <IonRadio value="true" className="custom-tabIndex" />
                            </IonCol>
                            <IonCol>
                              <IonLabel className="book_details_label_infoget">Yes</IonLabel>
                            </IonCol>
                            <IonCol>
                              <IonRadio value="false" className="custom-tabIndex" />
                            </IonCol>
                            <IonCol>
                              <IonLabel className="book_details_label_infoget">No</IonLabel>
                            </IonCol>
                          </IonRadioGroup>
                        </IonCol>
                      </IonRow>
                      {deladd && <div>
                        <IonRow className="label_row_container">
                          <IonCol size-lg="1" size-md="1.5" size-xs="1">
                            <IonIcon src={checkmarkCircleSharp} className="tick" />
                          </IonCol>
                          <IonCol size-lg="4.5" size-md="4.5" size-sm="4.5" size-xs="11">
                            <IonText className="book_details_label_infoget">
                              Delivery Address <span style={{ color: "red" }}>*</span>
                            </IonText>
                          </IonCol>
                          <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1" >
                            <IonText className="book_details_label_infoget off_colon_display">
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="11">
                            <IonTextarea className="off_textarea" value={delivery} onBlur={() => Validation("address")} onIonChange={(e: any) => { setDelivery(e.detail.value); ChangeValidation("address", e) }} autoGrow />
                            {formErrors.address && <span className="alert_input_bottom ">{formErrors.address}</span>}
                          </IonCol>
                        </IonRow>
                        <IonRow className="label_row_container">
                          <IonCol size-lg="1" size-md="1.5" size-xs="1">
                            <IonIcon src={checkmarkCircleSharp} className="tick" />
                          </IonCol>
                          <IonCol size-lg="4.5" size-md="4.5" size-sm="4.5" size-xs="11">
                            <IonText className="book_details_label_infoget">
                              Pincode <span style={{ color: "red" }}>*</span>
                            </IonText>
                          </IonCol>
                          <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="1">
                            <IonText className="book_details_label_infoget off_colon_display">
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="11">
                            <IonInput className="off_input" value={pincode} type="number" onFocus={preventdata} onBlur={() => Validation("pincode")} onIonChange={(e: any) => { setPin(e.detail.value); ChangeValidation("pincode", e) }} />
                            {formErrors.pincode && <span className="alert_input_bottom ">{formErrors.pincode}</span>}
                          </IonCol>
                        </IonRow>
                        {usertoken === null ? <></> : <IonRow className="label_row_container">
                          <IonCol size-lg="1" size-md="1.5" size-xs="1.5" size-sm="1.5">
                            {/* <IonIcon src={checkmarkCircleSharp} className="tick" /> */}
                          </IonCol>
                          <IonCol size="0.5">
                            <IonCheckbox ref={checkboxRef} onIonChange={(e: any) => sameas(e)} checked={che} className="custom-tabIndex" />
                          </IonCol>
                          <IonCol size="0.5">
                          </IonCol>
                          <IonCol size="5.5">
                            <IonText className="book_details_label_infoget">
                              Same As profile
                            </IonText>
                          </IonCol>
                        </IonRow>}
                      </div>}
                    </IonCol>
                    <IonCol size-lg="6" size-md="12" size-sm="12" size-xs="12">
                      <IonRow className="padding_pay" style={{ display: "flex", alignItems: "center" }}>
                        <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="11" size-md="11" size-xs="11" size-sm="11">
                          <IonRadioGroup className={Capacitor.getPlatform() === "ios" ? "ios_radio_btn" : ""} value={radio} onBlur={() => Validation("radio")} onIonChange={(e: any) => { setRadio(e.detail.value); ChangeValidation("radio", e) }}>
                            <IonRadio value="1" style={{ margin: "0% 2% 0% 0%" }} className="custom-tabIndex" />
                            <IonLabel className="book_details_label_infoget" >Daily <span style={{ color: "red" }}>*</span></IonLabel>
                            {/* <IonRadio value="2" style={{ margin: "0% 2%" }} className="custom-tabIndex" />
                            <IonLabel className="book_details_label_infoget" >Monthly <span style={{ color: "red" }}>*</span></IonLabel> */}
                            <IonRadio value="3" style={{ margin: "0% 2%" }} className="custom-tabIndex" />
                            <IonLabel className="book_details_label_infoget">Select Date(s) <span style={{ color: "red" }}>*</span></IonLabel>
                          </IonRadioGroup>
                          {formErrors.radio && <span className="alert_input_bottom">{formErrors.radio}</span>}
                        </IonCol>
                      </IonRow>
                      {radio === "1" ? <div>
                        <IonRow className="off_date_container">
                          <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                            <IonRow className={formErrors.fromDate ? "fromdate_row_container_height" : "fromdate_row_container"}>
                              <IonCol size-lg="5" size-md="5.5" size-sm="4" size-xs="4">
                                <IonLabel className="book_details_label_date">From Date <span style={{ color: "red" }}>*</span></IonLabel>
                              </IonCol>
                              <IonCol size-lg="7" size-md="6.5" size-sm="4.5" size-xs="5.5" className="defaultcalendar date_font">
                                <IonInput min={datelimit()} onBlur={() => Validation("fromDate")} ref={fromDateInputRef}
                                  onKeyDown={(e: any) => {
                                    if (e.key !== 'Tab' && e.key !== ' ') {
                                      e.preventDefault();
                                    }
                                    else {
                                      if (fromDateInputRef.current) {
                                        fromDateInputRef.current.setFocus();
                                      }
                                    }
                                  }}
                                  type="date" value={fdate} onIonChange={(e: any) => { fromdate(e); ChangeValidation("fromDate", e) }} className="web_calendar" />
                                {formErrors.fromDate && <span className="alert_input_bottom ">{formErrors.fromDate}</span>}
                              </IonCol>
                              <IonCol size-lg="6.5" size-md="6.5" size-sm="4.5" size-xs="6.5" className="iconcalendar">
                                <div style={{ position: "absolute", width: "100%" }}>
                                  <IonInput min={datelimit()} ref={fromDateInputRef} onBlur={() => Validation("fromDate")} color="dark" placeholder="dd-mm-yyyy"
                                    onKeyUp={(e: any) => {
                                      if (e.key !== 'Tab' && e.key !== ' ') {
                                        e.preventDefault();
                                      }
                                      else {
                                        if (fromDateInputRef.current) {
                                          fromDateInputRef.current.setFocus();
                                        }
                                      }
                                    }}
                                    type="date" value={fdate} onIonChange={(e: any) => { fromdate(e); ChangeValidation("fromDate", e) }} className="androidcalendar" />
                                  {formErrors.fromDate && <span className="alert_input_bottom ">{formErrors.fromDate}</span>}
                                </div>
                                <div style={{ position: "relative" }} className={formErrors.fromDate ? "calendarIconAlert date_icon_style" : "calendarIcon date_icon_style"}>
                                  <IonIcon style={{ color: "black" }} onClick={handleIconClick} className="date_icon_style" src={calendarOutline} />
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonCol>
                          <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                            <IonRow className={formErrors.fromDate ? "todate_row_container_height" : "fromdate_row_container"}>
                              <IonCol size-lg="5" size-md="5.5" size-sm="4" size-xs="4">
                                <IonLabel className="book_details_label_date">To Date <span style={{ color: "red" }}>*</span></IonLabel>
                              </IonCol>
                              <IonCol size-lg="7" size-md="6.5" size-sm="4.5" size-xs="5.5" className="defaultcalendar date_font">
                                <IonInput disabled={fdate === "" ? true : false} min={todatelimit()} onBlur={() => Validation("toDate")} ref={toDateInputRef}
                                  onKeyDown={(e: any) => {
                                    if (e.key !== 'Tab' && e.key !== ' ') {
                                      e.preventDefault();
                                    }
                                    else {
                                      if (toDateInputRef.current) {
                                        toDateInputRef.current.setFocus();
                                      }
                                    }
                                  }}
                                  type="date" value={tdate} onIonChange={(e: any) => { todate(e); ChangeValidation("toDate", e) }} className="web_calendar" />
                                {formErrors.toDate && <span className="alert_input_bottom ">{formErrors.toDate}</span>}
                              </IonCol>
                              <IonCol size-lg="6.5" size-md="6.5" size-sm="4.5" size-xs="6.5" className="iconcalendar">
                                <div style={{ position: "absolute", width: "100%" }}>
                                  <IonInput disabled={fdate === "" ? true : false} min={todatelimit()} onBlur={() => Validation("toDate")} color="dark" placeholder="dd-mm-yyyy"
                                    onKeyDown={(e: any) => {
                                      if (e.key !== 'Tab' && e.key !== ' ') {
                                        e.preventDefault();
                                      }
                                      else {
                                        if (toDateInputRef.current) {
                                          toDateInputRef.current.setFocus();
                                        }
                                      }
                                    }}
                                    type="date" value={tdate} onIonChange={(e: any) => { todate(e); ChangeValidation("toDate", e) }} className="androidcalendar" />
                                  {formErrors.toDate && <span className="alert_input_bottom ">{formErrors.toDate}</span>}
                                </div>
                                <div style={{ position: "relative" }} className={formErrors.toDate ? "calendarIconAlertto date_icon_style" : "calendarIcon date_icon_style"}>
                                  {fdate === "" ? <></> : <IonIcon onClick={handleIconClick} style={{ color: "black" }} className="date_icon_style" src={calendarOutline} />}
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </div> 
                      // :
                      //  radio === "2" ? <div>
                      //   <IonRow className="off_month_container">
                      //     {/* <div> */}
                      //     <IonCol size-lg="4" size-md="4" size-sm="5" size-xs="5" className="off_month_label_container">
                      //       <IonLabel className="book_details_label_date">No of Months <span style={{ color: "red" }}>*</span></IonLabel>
                      //     </IonCol>
                      //     <IonCol style={{ display: "flex", alignItems: "center" }} size-lg="8" size-md="8" size-sm="7" size-xs="7">
                      //       <IonIcon src={removeOutline} onClick={() => Decrement()} className={month === 0 ? "disableCursor" : "month_decrement_btn"} />
                      //       <IonInput type="number" debounce={500} onKeyPress={(event) => numberOnlyValidation(event)} onFocus={preventdata} onBlur={() => Validation("monthly")} onIonChange={(e: any) => { handlechangeMonth(e); ChangeValidation("monthly", e) }} min={0} value={month} className="off_input" style={{ maxWidth: "80px", height: "30px", textAlign: "center" }} />
                      //       <IonIcon src={addOutline} onClick={() => Increment()} style={{ cursor: "pointer", color: '#484848' }} />
                      //     </IonCol>
                      //     {/* </div> */}
                      //   </IonRow>
                      //   <div style={{ display: "flex", justifyContent: "center" }}>
                      //     {formErrors.monthly && <span className="alert_input_bottom ">{formErrors.monthly}</span>}
                      //   </div>
                      // </div> 
                      : radio === "3" ? <div>
                        <IonRow className="off_sdate_container">
                          <IonCol className="sdate_label" size-lg="3" size-md="3" size-sm="3" size-xs="3.5">
                            <IonLabel className="book_details_label_date">
                              Date(s)
                              <span style={{ color: "red" }}>*</span>
                            </IonLabel>
                          </IonCol>
                          <IonCol size-lg="7" size-md="5" size-sm="7" size-xs="8.5" style={{ padding: "0%" }}>
                            {dates.map((item: any, i: any) => {
                              return (
                                <IonRow style={{ marginTop: "2%" }}>
                                  <IonCol size-lg="7" size-md="7" size-sm="8" size-xs="10" className="listdatedefault">
                                    <IonInput
                                      onIonChange={(e: any) => { dates_changes(e, i); ChangeValidation("dates", e) }}
                                      onKeyDown={(e: any) => {
                                        if (e.key !== 'Tab' && e.key !== ' ') {
                                          e.preventDefault();
                                        }
                                        else {
                                          if (listDateInputRef.current) {
                                            listDateInputRef.current.setFocus();
                                          }
                                        }
                                      }}
                                      ref={listDateInputRef}
                                      value={item.value}
                                      id={i}
                                      min={datelimit()}
                                      onBlur={() => Validation("dates")}
                                      type="date"
                                      style={{ height: "35px", maxWidth: "300px", borderRadius: "8px" }}
                                    />
                                  </IonCol>
                                  <IonCol size-lg="7" size-md="7" size-sm="8" size-xs="10" className="iconcalendar">
                                    <div style={{ position: "absolute", width: "100%", display: "flex", alignItems: "center" }}>
                                      <IonInput
                                        onIonChange={(e: any) => { dates_changes(e, i); ChangeValidation("dates", e) }}
                                        onKeyDown={(e: any) => e.preventDefault()}
                                        style={{
                                          textAlign: "center", paddingBottom: "5px",
                                          height: "35px", maxWidth: "300px", borderRadius: "8px"
                                        }}
                                        value={item.value}
                                        id={i}
                                        onBlur={() => Validation("dates")}
                                        min={datelimit()}
                                        type="date"
                                      />
                                    </div>
                                    <div className="date_icon_style" style={{ position: "relative", paddingRight: "4px", paddingTop: "2px" }}>
                                      <IonIcon style={{ color: "black" }} onClick={handleIconClick} className="date_icon_style" src={calendarOutline} />
                                    </div>
                                  </IonCol>
                                  <IonCol size-lg='5' size-md="5" size-sm="4" size-xs="2" style={{ margin: "auto 0" }}>
                                    {i == 0 ? <IonIcon tabIndex={1} src={addCircleOutline} size="medium"
                                      onKeyDown={(e: any) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                          addInput();
                                        }
                                      }} onClick={() => addInput()}
                                      style={{ cursor: "pointer", paddingLeft: "5%", color: '#484848' }} />
                                      :
                                      <IonIcon tabIndex={1} src={removeCircleOutline}
                                        onClick={() => removeInputFields(i)}
                                        onKeyDown={(e: any) => {
                                          if (e.key === 'Enter' || e.key === ' ') {
                                            removeInputFields(i);
                                          }
                                        }}
                                        style={{ cursor: "pointer", paddingLeft: "5%", color: '#484848' }} />}
                                  </IonCol>
                                </IonRow>
                              )
                            })}
                            {formErrors.dates && <span className="alert_input_bottom ">{formErrors.dates}</span>}
                          </IonCol>
                        </IonRow>
                      </div> : <div></div>}
                      <IonRow className="padding_pay">
                        <IonCol style={{ display: "flex ", alignItems: "center" }} size-lg="1" size-md="1" size-sm="1" size-xs="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="10.5" style={{ display: "flex", alignItems: "center", userSelect: "none" }}>
                          <IonCheckbox checked={dak} value={paydakshinapreist} className="custom-tabIndex" onIonChange={(e) => dakamt(e)} />
                          <IonLabel style={{ marginLeft: "15px", marginRight: "10px" }} className="book_details_label_infoget">Pay Dakshina directly to the priest</IonLabel>
                          <IonIcon className="info_icon" style={{ marginLeft: "5px" }} onClick={openmodal} src={informationCircleSharp} />
                        </IonCol>
                      </IonRow>
                      {dakamt1 && <IonRow>
                        <IonCol style={{ display: "flex ", alignItems: "center" }} size-lg="1" size-md="1" size-sm="1" size-xs="1" />
                        <IonCol size-lg="3" size-md="2" size-sm="4" size-xs="4" style={{ display: "flex", userSelect: "none" }}>
                          <IonLabel className="book_details_label_infoget">Amount (₹) <span style={{ color: "red" }}>*</span></IonLabel>
                        </IonCol>
                        <IonCol size-lg="5" size-md="4" size-sm="6" size-xs="6">
                          <IonInput value={dakshinaamt} onBlur={() => Validation("priestAmount")} min={0} ref={(ref) => inputRef.current = ref} onFocus={preventdata} autofocus={true} type="number" onKeyPress={(event) => numberOnlyValidation(event)} className="off_input" onIonChange={(e: any) => { setDakshiamt(e.detail.value); ChangeValidation("priestAmount", e) }} />
                          {dakamt1 && <div>
                            {formErrors.priestAmount && <span className="alert_input_bottom ">{formErrors.priestAmount}</span>}
                          </div>}
                        </IonCol>
                      </IonRow>}
                      <IonRow className="padding_pay">
                        <IonCol style={{ display: "flex ", alignItems: "center" }} size-lg="1" size-md="1" size-sm="1" size-xs="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11" style={{ display: "flex", alignItems: "center", userSelect: "none" }}>
                          <IonCheckbox checked={don} value={paydakshinatemple} className="custom-tabIndex" onIonChange={(e) => donamt(e)} />
                          <IonLabel style={{ marginLeft: "15px", marginRight: "10px" }} className="book_details_label_infoget">Pay donations to the temple</IonLabel>
                          <IonIcon className="info_icon" style={{ marginLeft: "5px" }} onClick={openmodal} src={informationCircleSharp} />
                        </IonCol>
                      </IonRow>
                      {donamt1 && <IonRow>
                        <IonCol style={{ display: "flex ", alignItems: "center" }} size-lg="1" size-md="1" size-sm="1" size-xs="1" />
                        <IonCol size-lg="3" size-md="2" size-sm="4" size-xs="4" style={{ display: "flex", userSelect: "none" }}>
                          <IonLabel className="book_details_label_infoget">Amount (₹)  <span style={{ color: "red" }}>*</span></IonLabel>
                        </IonCol>
                        <IonCol size-lg="5" size-md="4" size-sm="6" size-xs="6">
                          <IonInput onFocus={preventdata} type="number" onBlur={() => Validation("donationamt")} className="off_input" min={0} value={donationamt} ref={(ref) => inputRef1.current = ref} autofocus={true} onKeyPress={(event) => numberOnlyValidation(event)} onIonChange={(e: any) => { setDonationamt(e.detail.value); ChangeValidation("donationamt", e) }} />
                          {donamt1 && <div>
                            {formErrors.donationamt && <span className="alert_input_bottom ">{formErrors.donationamt}</span>}
                          </div>}
                        </IonCol>
                      </IonRow>}
                      <IonRow className="padding_pay" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "2%" }}>
                        <IonButton onClick={() => show1()}> <span className="button_padding">Proceed</span></IonButton>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonRow>
            </IonGrid>
          </div> : index1 === 5 ? <div className="page_content_padding book_details_main_container">
            <IonRow>

            </IonRow>
            <IonGrid className="confrom_book_details_sub_container">
              <IonRow style={{ display: "flex", alignItems: "center" }}>
                <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1">
                  <IonIcon onClick={() => {
                    setActionFlag(false)
                    setIndex1(4)
                  }
                  } src={arrowBack} className="point back"></IonIcon>
                </IonCol>
                <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11">
                  <IonText className="book_details_head">
                    Confirm Booking Details
                  </IonText> </IonCol>
              </IonRow>
              <IonRow className="book_content_container ">
                <IonRow className="book_poojaname_row">
                  <IonText className="book_poojaname_head">
                    Pooja Name :  {offerName}
                  </IonText>
                </IonRow>
              </IonRow>
              <IonGrid className="confrom_book_bg">
                <IonRow className="confrom_book_subcontent_container">
                  <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                    <IonRow>
                      <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                        <IonText className="book_details_label_info_check">
                          Temple Name
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                        <IonText className="book_details_label_info_check">
                          :
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                        <IonText className="book_details_label_info_check">
                          {tname}
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                        <IonText className="book_details_label_info_check">
                          Deity Name
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                        <IonText className="book_details_label_info_check">
                          :
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                        <IonText className="book_details_label_info_check">
                          {deityname}
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                        <IonText className="book_details_label_info_check">
                          Pooja Name
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                        <IonText className="book_details_label_info_check">
                          :
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                        <IonText className="book_details_label_info_check">
                          {offerName}
                        </IonText>
                      </IonCol>
                    </IonRow>
                    {username.length > 0 && <IonRow>
                      <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                        <IonLabel className="book_details_label_info_check">Name</IonLabel>
                      </IonCol>
                      <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                        <IonText className="book_details_label_info_check">
                          :
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                        <IonText className="book_details_label_info_check">{username}</IonText>
                      </IonCol>
                    </IonRow>}
                    {star.length > 0 && <IonRow>
                      <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                        <IonLabel className="book_details_label_info_check">Star</IonLabel>
                      </IonCol>
                      <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                        <IonText className="book_details_label_info_check">
                          :
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                        <IonText className="book_details_label_info_check">{star}</IonText>
                      </IonCol>
                    </IonRow>}
                    <IonRow>
                      <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                        <IonText className="book_details_label_info_check">Darshan Price</IonText>
                      </IonCol>
                      <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                        <IonText className="book_details_label_info_check">
                          :
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                        <IonText className="book_details_label_info_check">₹ {formatter.format(dprice)}</IonText>
                      </IonCol>
                    </IonRow>
                    {dakamt1 &&
                      <IonRow>
                        <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                          <IonLabel className="book_details_label_info_check">Dakshina Amount</IonLabel>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                          <IonText className="book_details_label_info_check">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                          <IonText className="book_details_label_info_check">₹ {formatter.format(dakshinaamt)}</IonText>
                        </IonCol>
                      </IonRow>}
                  </IonCol>
                  <IonCol size-lg="6" size-md="6" size-xs="12" size-sm="12">
                    {radio == "1" ? <div>
                      <IonRow>
                        <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                          <IonLabel className="book_details_label_info_check">From</IonLabel>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                          <IonText className="book_details_label_info_check">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                          <IonText className="book_details_label_info_check">{ffdate}</IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                          <IonLabel className="book_details_label_info_check">To</IonLabel>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                          <IonText className="book_details_label_info_check">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                          <IonText className="book_details_label_info_check">{ttdate}</IonText>
                        </IonCol>
                      </IonRow>
                    </div> : radio == "2" ? <div>
                      <IonRow>
                        <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                          <IonLabel className="book_details_label_info_check">No of Month(s)</IonLabel>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                          <IonText className="book_details_label_info_check">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                          <IonText className="book_details_label_info_check">{month}</IonText>
                        </IonCol>
                      </IonRow>
                    </div> : <div>

                      <IonRow>
                        <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                          <IonLabel className="book_details_label_info_check">Dates</IonLabel>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                          <IonText className="book_details_label_info_check">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                          {sortdates.map((item: any, i: any) => {
                            const dateFor = moment(item).format("DD-MM-YYYY")
                            var date;
                            if (i === sortdates.length - 1) {
                              date = dateFor.concat("")
                            }
                            else {
                              date = dateFor.concat(", ")
                            }
                            return (
                              <>
                                {
                                  item.value === "" ? <></> :
                                    <IonText className="book_details_label_info_check">{date}</IonText>
                                }
                              </>
                            )
                          })}
                        </IonCol>
                      </IonRow>
                    </div>}
                    {deladd &&
                      <IonRow>
                        <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                          <IonText className="book_details_label_info_check">Delivery Address</IonText>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                          <IonText className="book_details_label_info_check">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                          <IonText className="book_details_label_info_check">{delivery}</IonText>
                        </IonCol>
                      </IonRow>
                    }
                    {deladd &&
                      <IonRow>
                        <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                          <IonText className="book_details_label_info_check">Pincode</IonText>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                          <IonText className="book_details_label_info_check">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                          <IonText className="book_details_label_info_check">{pincode}</IonText>
                        </IonCol>
                      </IonRow>
                    }
                    {donamt1 &&
                      <IonRow>
                        <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                          <IonIcon src={checkmarkCircleSharp} className="tick" />
                        </IonCol>
                        <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3">
                          <IonLabel className="book_details_label_info_check">Donation Amount</IonLabel>
                        </IonCol>
                        <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                          <IonText className="book_details_label_info_check">
                            :
                          </IonText>
                        </IonCol>
                        <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                          <IonText className="book_details_label_info_check">₹ {formatter.format(donationamt)}</IonText>
                        </IonCol>
                      </IonRow>}
                    {delcrgField && <IonRow>
                      <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                        <IonIcon src={checkmarkCircleSharp} className="tick" />
                      </IonCol>
                      <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3" style={{ display: "flex", alignItems: "center" }}>
                        <IonLabel className="book_details_label_info_check">Delivery Charge</IonLabel>
                      </IonCol>
                      <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                        <IonText className="book_details_label_info_check">
                          :
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                        <IonText className="book_details_label_info_check">₹ {formatter.format(delCharge)}</IonText>
                      </IonCol>
                    </IonRow>}
                    {delcrgField && <div>
                      {radio === "3" ? <>
                        {confirmdates.length > 1 ? <IonRow>
                          <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                            <IonIcon src={checkmarkCircleSharp} className="tick" />
                          </IonCol>
                          <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3" style={{ display: "flex", alignItems: "center" }}>
                            <IonLabel className="book_details_label_info_check">Delivery Charge Per Day</IonLabel>
                          </IonCol>
                          <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                            <IonText className="book_details_label_info_check">
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                            <IonText className="book_details_label_info_check">₹ {formatter.format(delChargePerDay)}</IonText>
                          </IonCol>
                        </IonRow> : <></>}
                      </> : <>
                        <IonRow >
                          <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
                            <IonIcon src={checkmarkCircleSharp} className="tick" />
                          </IonCol>
                          <IonCol size-lg="4.5" size-md="4.5" size-xs="3" size-sm="3" style={{ display: "flex", alignItems: "center" }}>
                            <IonLabel className="book_details_label_info_check">Delivery Charge Per Day</IonLabel>
                          </IonCol>
                          <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
                            <IonText className="book_details_label_info_check">
                              :
                            </IonText>
                          </IonCol>
                          <IonCol size-lg="6" size-md="6" size-xs="7.5" size-sm="7.5">
                            <IonText className="book_details_label_info_check">₹ {formatter.format(delChargePerDay)}</IonText>
                          </IonCol>

                        </IonRow></>}
                    </div>}
                  </IonCol>
                </IonRow>
                <IonRow style={{ display: "flex", justifyContent: "center" }}>
                  <IonCol className="save_book_btn" size-lg="6" size-md="6" size-xs="12" size-sm="6">
                    <IonButton onClick={(e) => {
                      // if (usertoken == null) {
                      //   setPayFlag(1)
                      //   setLoginmodal(true)
                      // } else {
                      savebook(usertoken)

                      // }
                    }
                    }><span className="button_padding">Save & Book More</span></IonButton>

                  </IonCol>
                  <IonCol className="payment_btn" size-lg="6" size-md="6" size-xs="12" size-sm="6">
                    <IonButton onClick={() => {
                      if (usertoken == null) {
                        setPayFlag(2)
                        setLoginmodal(true)
                      } else {
                        proceedtopayment(usertoken)
                      }
                    }}><span className="button_padding">Proceed to Payment</span>
                    </IonButton>
                  </IonCol>
                </IonRow>

              </IonGrid>

            </IonGrid>
          </div> : <></>
        }

        <Foot />
      </IonContent >
      <Modal show={modalopen} className="donation-modal" centered >
        <Modal.Body ref={donationmodal}>
          <div className="donation-modal-header project-modal-header">
            <h5 className="donation-modal-title">Atmagram</h5>
            <div>
              <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
            </div>
          </div>
          <div className='donation-modal-content'>
            <IonText>The Amount will be paid to the priest who performs the pooja</IonText>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={modalopen1} className="donation-modal" centered >
        <Modal.Body ref={templedonationmodal}>
          <div className="donation-modal-header project-modal-header">
            <h5 className="donation-modal-title">Atmagram</h5>
            <div>
              <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen1(false)} />
            </div>
          </div>
          <div className='donation-modal-content'>
            <IonText>The Amount donated will be paid to the temple</IonText>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isShow} className="custom-modal-wrapper">
        <Modal.Body ref={deitymodal} >
          <div className="modal-header" style={{ padding: "15px 0px" }}>
            <h5 className="modal-title">Atmagram</h5>
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsshow(false)}>
              <span aria-hidden="true">x</span>
            </button>
          </div>
          <div className="custom-modal-deity">
            <IonRow style={{ display: "flex", alignItem: "center", justifyContent: "center" }}>
              <IonImg src={image} style={{ width: "250px", height: "300px" }}></IonImg>
            </IonRow>
            <IonRow style={{ justifyContent: "center" }}>
              <IonText style={{ fontWeight: "bold", fontSize: "20px" }}>{maindeityname}</IonText>
            </IonRow>
            <IonRow style={{ textAlign: "justify" }}>
              <IonText style={{ fontSize: "17px", padding: "30px" }}>{description}</IonText>
            </IonRow>

          </div>
        </Modal.Body>
      </Modal>

      <Modal show={infomodal} className="off_info_modal" centered>
        <Modal.Body>
          <IonRow className="off_info_modal_header">
            <IonCol size-lg="11" size-md="11" size-xs="11" size-sm="11">
              <IonText className="info_modal_offname">
                {offerName}
              </IonText>
            </IonCol>
            <IonCol size-lg="1" size-md="1" size-xs="1" size-sm="1">
              <IonIcon src={close} onClick={() => setInfomodal(false)} className="modal_close_icon close_icon" />
            </IonCol>
          </IonRow>
          <IonRow className="row_container">
            <IonCol size-lg="0.5" size-md="0.5" size-xs="1" size-sm="1">
              <IonIcon src={checkmarkCircleSharp} className="tick" />
            </IonCol>
            <IonCol size-lg="2.5" size-md="3.5" size-xs="3.5" size-sm="3.5" className="label_container_info">
              <IonText className="off_modal_label">
                Timing
              </IonText>
            </IonCol>
            <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
              <IonText className="off_modal_label">
                :
              </IonText>
            </IonCol>
            <IonCol size-lg="8.5" size-md="7.5" size-xs="7" size-sm="7">
              <IonText className="off_modal_label">
                {offtiming}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="row_container">
            <IonCol size-lg="0.5" size-md="0.5" size-xs="1" size-sm="1">
              <IonIcon src={checkmarkCircleSharp} className="tick" />
            </IonCol>
            <IonCol size-lg="2.5" size-md="3.5" size-xs="3.5" size-sm="3.5" className="label_container_info">
              <IonText className="off_modal_label">
                Description
              </IonText>
            </IonCol>
            <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" size-sm="0.5">
              <IonText className="off_modal_label">
                :
              </IonText>
            </IonCol>
            <IonCol size-lg="8.5" size-md="7.5" size-xs="7" size-sm="7">
              <IonText className="off_modal_label">
                {offdescription}
              </IonText>
            </IonCol>
          </IonRow>
        </Modal.Body>
      </Modal>

    </IonPage >
  );
};
export default Poojaofferings;
