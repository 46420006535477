import {
  IonContent,
  IonText,
  IonIcon,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
  IonGrid,
  IonCard,
  IonLabel,
  IonInput,
  IonTextarea,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";
import { calendarNumber, call, callOutline, location, locationOutline, logoFacebook, logoInstagram, logoTwitter, logoYoutube, mailOutline, phonePortraitOutline } from "ionicons/icons";
import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import "./ContactUs.css";
import { useHistory } from "react-router";
import Head from "./Head";
import { Capacitor } from "@capacitor/core";
import HeadFoot from "./Head";

const ContactUs: React.FC = () => {
  const recipient = 'contact@atmagram.com';
  const subject = 'Atmagram';
  

  const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    subject
  )}`;
const phone="+918281641474";
  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"login"} Role={'Role'} Name='Home,Contact' />
        <div className="page_content_padding">
          <IonGrid className="content_container">
            <IonRow className="center_col">
              <IonCol size="6" >
                <IonCard className="card_container">
                  <IonRow style={{ textAlign: "center" }}>
                    <IonText className="card_head_text">
                      Contact Information
                    </IonText>
                  </IonRow>
                  
                  <IonRow style={{ marginTop: "20px" }}>
                    <IonCol size="2">
                      <IonIcon src={callOutline} className="icon_contact" />
                    </IonCol>
                    <IonCol size="10"> <a href={`tel:${phone}`} style={{color:"#ffffff"}}>
                      <IonText className="phone_contact">
                     
                      +91 8281641474
                      </IonText></a>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginTop: "20px" }}>
                    <IonCol size="2">
                      <IonIcon src={mailOutline} className="icon_contact" />
                    </IonCol>
                    <IonCol size="10">
                      <IonText className="phone_contact">
                      contact@atmagram.com
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginTop: "20px" }}>
                    <IonCol size="2">
                      <IonIcon src={location} className="icon_contact" />
                    </IonCol>
                    <IonCol size="10">
                      <IonText className="address_contact">
                      Sri Sankara Peetam Charitable Trust,
Room No. 11/570, First Floor Thachappillil House, 
Kalady P.O., Ernakulam -
683574.  
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ display: "flex", justifuContent: "fle" }}>
                    <IonCol size="2" />
                    <IonCol size="2">
                      <IonIcon src={logoFacebook} className="logo_contact" />
                    </IonCol>
                    <IonCol size="2">
                    <a className="font_icon" href="https://instagram.com/srisankarapeetam?igshid=MzNlNGNkZWQ4Mg==" target="_blank">
                    <IonIcon src={logoInstagram} />
                  </a>
                    </IonCol>
                    <IonCol size="2">
                      <IonIcon src={logoTwitter} className="logo_contact" />
                    </IonCol>
                    <IonCol size="2">
                      <IonIcon src={logoYoutube} className="logo_contact" />
                    </IonCol>
                    <IonCol size="2">
                    <a href={mailtoLink} target="_blank" rel="noopener noreferrer">
                      <IonIcon src={mailOutline} className="logo_contact" /></a>
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
              {/* <IonCol style={{ paddingLeft: "30px" }} size="7">
                <IonRow>
                  <IonCol size="6" style={{ paddingRight: "40px" }}>
                    <IonLabel>
                      First Name
                    </IonLabel>
                    <IonRow style={{ marginTop: "10px" }}>
                      <IonInput className="contact_input" placeholder="Enter Firstname" />
                    </IonRow>
                  </IonCol>
                  <IonCol size="6" style={{ paddingLeft: "20px" }}>
                    <IonLabel>
                      Last Name
                    </IonLabel>
                    <IonRow style={{ marginTop: "10px" }}>
                      <IonInput className="contact_input" placeholder="Enter Lastname" />
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginTop: "40px" }}>
                  <IonCol size="6" style={{ paddingRight: "40px" }}>
                    <IonLabel>
                      E-Mail
                    </IonLabel>
                    <IonRow style={{ marginTop: "10px" }}>
                      <IonInput className="contact_input" placeholder="Enter your mail-id" />
                    </IonRow>
                  </IonCol>
                  <IonCol size="6" style={{ paddingLeft: "20px" }}>
                    <IonLabel>
                      Mobile Number
                    </IonLabel>
                    <IonRow style={{ marginTop: "10px" }}>
                      <IonInput className="contact_input" placeholder="Enter mobile number" />
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" style={{ marginTop: "40px" }}>
                    <IonLabel>
                      Message
                    </IonLabel>
                    <IonRow style={{ marginTop: "10px" }}>
                      <IonTextarea autoGrow className="message_contact" placeholder="Your Message" />
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow style={{ textAlign: "center" }}>
                  <IonCol size="12">
                    <IonButton>
                      <span className="button_padding">
                        Submit
                      </span>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol> */}
            </IonRow>
          </IonGrid>
        </div>
        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default ContactUs;