import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonGrid,
  IonButton,
  IonPage,
  IonContent,
  IonItemDivider,
  IonLoading,
  IonCard,
  IonImg,
  useIonAlert,
  IonLabel,
  IonInput
} from "@ionic/react";
import Authservice from "../Services/user.service";
import { checkmarkCircleSharp, close, createOutline, trashBin } from "ionicons/icons";
import UserHead from "./UserHead";
import Foot from "./Foot";
import { Modal, ModalHeader } from "react-bootstrap";
import "./Bookdetails.css";
import moment from "moment";
import useRazorpay from "react-razorpay";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import SessionTimeout from "../Services/SessionTimeOut";
import "./Myorder.css"
import HeadFoot from "./Head";
import { edit_icon, tick } from "../theme/imagePath";
import UserService from "../Services/auth.service";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../Services/firebase-config";
import validator from 'validator'
import { AppContext } from "../components/AppContext";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const Mycart: React.FC = () => {
  const [token, settoken]: any = useState(localStorage.getItem("token"));
  const [payData, setPayData]: any = useState([])
  const [delData, setDelData]: any = useState('')
  const [islogin, Islogin] = useState(false);
  const [cartList, setcart]: any = useState([]);
  const [paymentdata, setPaymentdata]: any = useState([]);

  const uFlag: any = useState(localStorage.getItem("uFlag"))
  const [tname, setTname]: any = useState(localStorage.getItem("tname"));
  const Razorpay = useRazorpay();
  const modal = useRef<HTMLIonModalElement>(null);
  const [log, setLog] = useState(false);
  const [cartData, setCartData] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  let [totalAmount, setTotalAmount]: any = useState(0)
  var amount = 0;
  const [Alert] = useIonAlert()
  const formatter = new Intl.NumberFormat('en-IN')
  const history: any = useHistory()
  const { setUFlag } = useContext(AppContext)!;
  const { setToken } = useContext(AppContext)!;
  const { setUserData } = useContext(AppContext)!;
  const [userdata, setUserdata]: any = useState(localStorage.getItem("UserData"));
  const [userAddress, setUserAddress]: any = useState(localStorage.getItem("Address"));
  const [usertoken, setUsertoken] = useState(localStorage.getItem("token"));
  const [add, setAdd]: any = useState(localStorage.getItem("Address"));
  const [userId, setUserId]: any = useState(localStorage.getItem("userId"));


  const [signupmodal, setSignupmodal] = useState(false)

  const [confirmpassword, setConfirmpassword] = useState("");
  const [password1, setPassword1]: any = useState("");
  const [firstname1, setFirstname1] = useState("");
  const [phone1, setPhone1]: any = useState("");
  const [email1, setEmail1] = useState("");
  const checkboxRef = useRef(null);
  const [isValidPhoneNumberSign, setIsValidPhoneNumberSign] = useState(false);
  const phoneInputRef = useRef(null)
  const loginphoneRef = useRef(null)
  const [formErrors, setFormErrors]: any = useState({});
  const [isValidLoginNumber, setIsValidLoginNumber] = useState(false);
  const namecheck = /^[a-zA-Z\s]*$/
  const [user, setUser]: any = useState("2");
  const [phone, setPhone]: any = useState(localStorage.getItem("Phone"));

  const loginmodalclose = () => {
    setLoginmodal(false)
    setLoginIndex(0)

  }
  const signupmodalclose = () => {
    setSignupmodal(false);
    setLoginmodal(true);
  }
  const useOutsideAlerter = (logref: any, signref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {

        if (logref.current && !logref.current.contains(event.target)) {
          setLoginmodal(true)
        }
        if (signref.current && !signref.current.contains(event.target)) {
          setSignupmodal(true)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [logref]);
  }
  const loginmodalref = useRef(null);
  const signupmodalref = useRef(null)

  useOutsideAlerter(loginmodalref, signupmodalref);
  const [logemail, setLogemail] = useState("")
  const [password, setPassword] = useState("");
  const [loginphone, setLoginPhone] = useState("")
  const [loginmodal, setLoginmodal] = useState(false)
  const [loginIndex, setLoginIndex] = useState(0);
  const [showOtp, setShowOtp]: any = useState(false);
  const [otp, setOtp] = useState("");
  const [confirmationResult, setconfirmationResult]: any = useState();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [loginalertmodal, setLoginalertmodal] = useState(false)
  const [loginalertdata, setLoginalertdata] = useState("")

  const phoneLogin = () => {
    setLoginIndex(1)

  }
  const emailLogin = () => {
    setLoginIndex(0)
  }

  const loginalertmodalaction = (e: any, data: any) => {
    setLoginalertmodal(e)
    setLoginalertdata(data)
  }

  const alertmodalclose = () => {
    if (loginalertdata === "Register Successfully") {
      setLoginmodal(true)
      setLoginalertmodal(false)
    }
    else {
      setLoginalertmodal(false)
    }
    setLoginalertdata("")
  }
  const Validation = (label: any) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);


    if (label === "login email") {
      if (logemail.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Email is required" }
        })
      }
      else if (validator.isEmail(logemail) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "" }
        })
      }
    }

    if (label === "login password") {
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }


    if (label === "firstname") {
      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "mobile") {
      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumberSign) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }

    if (label === "loginphone") {
      if (loginphone === '' || loginphone === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, loginPhone: "Mobile Number is required" }
        })
      } else if (!isValidLoginNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, loginPhone: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, loginPhone: "" }
        })
      }
    }

    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (label === "email") {
      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }

    if (label === "password") {
      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
  }
  const ChangeValidation = (label: any, e: any) => {


    if (label === "login email") {
      const logemail = e.target.value;

      if (logemail.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Email is required" }
        })
      }
      else if (validator.isEmail(logemail) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, logemail: "" }
        })
      }
    }

    if (label === "login password") {
      const password = e.target.value;

      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }

    if (label === "phone") {
      if (phone === undefined || phone === "") {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Mobile Number is required" }
        })
      } else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Enter valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }


    if (label === "firstname") {
      const firstname1 = e.target.value;

      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "mobile") {

      const phone1 = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (label === "email") {

      const email1 = e.target.value;

      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }

    if (label === "password") {
      const password1 = e.target.value;

      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      const confirmpassword = e.target.value;

      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
  }
  const checkMobile = () => {
    const errors: any = {};
    if (loginphone === undefined || loginphone === "") {
      errors.loginPhone = "Mobile Number is required"
    } else if (!isValidLoginNumber) {
      errors.loginPhone = "Enter valid Mobile Number"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      UserService.validMob(loginphone).then((res) => {
        console.log(res, "otpres")
        if (res.data === true) {
          getOTP();
          setShowLoading(false);
        } else {
          setShowLoading(false);
          // alert("Please complete registration. Then only you can login with Atmagram")
          loginalertmodalaction(true, "Please complete registration. Then only you can login with Atmagram")
          // Alert({ header: 'Atmagram', message: "Please complete registration. Then only you can login with Atmagram",buttons: ['OK'], cssClass: 'custom-alert' })
        }

      }).catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
    }
  }
  const moveCursorToEnd = (value: any) => {
    const input: any = phoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumberSign(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumberSign(false);
      console.log(error)
    }
    setPhone1(value)
  };
  const loginmoveCursorToEnd = (value: any) => {
    const input: any = loginphoneRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidLoginNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
      if (value === '' || value === undefined) {
        setFormErrors({ ...formErrors, loginPhone: "Mobile Number is required" })
      }
      else if (!(phoneNumberObject ? phoneNumberObject.isValid() : false)) {
        setFormErrors({ ...formErrors, loginPhone: "Please Enter Valid Mobile Number" })
      }
      else {
        setFormErrors({ ...formErrors, loginPhone: "" })
      }
    } catch (error) {
      setIsValidLoginNumber(false);
      console.log(error)
    }
    setLoginPhone(value)
  };
  const getOTP = () => {
    let verify = new RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        // alert("Response expired. Please solve reCAPTCHA again")
        loginalertmodalaction(true, "Response expired. Please solve reCAPTCHA again")
      }
    }, auth);

    signInWithPhoneNumber(auth, loginphone, verify)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log(confirmationResult);
        setShowOtp(true)
        setconfirmationResult(confirmationResult)
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        loginalertmodalaction(true, "Something went wrong, please try again later.")
        // alert("Something went wrong, please try again later.")
        // ...
      });
  }

  const verifyOTP = () => {
    // var credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
    // console.log(credential);
    const errors: any = {};
    if (otp === undefined || otp === "" || otp == null) {
      errors.otp = "Enter OTP"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      confirmationResult.confirm(otp).then((result: any) => {
        // User signed in successfully.
        const user = result.user;
        console.log(result);
        setShowLoading(true);
        UserService.mobileLogin(loginphone)
          .then((response) => {
            if (response.data.responseText.startsWith("SUCCESS")) {
              if (response.data.responseText.startsWith("SUCCESS")) {
                // if (Capacitor.getPlatform() === "web") {
                //   console.log(response, "ressss")
                if (response.data.userModel.role !== "ROLE_USER") {
                  // Alert("This Account is not a user")
                  loginalertmodalaction(true, "This Account is not a user")
                }
                else
                  if (response.data.userModel.uflag === "false") {
                    console.log(response.data.userModel.uflag, "uflag")
                    // Alert("Account Not Approved Yet")
                    loginalertmodalaction(true, "Account Not Approved Yet")
                  }
                  else {
                    localStorage.setItem("token", response.data.userToken);
                    localStorage.setItem("UserId", response.data.userModel.id);
                    setUserId(response.data.userModel.id)
                    localStorage.setItem("UserData", response.data.userModel.role);
                    localStorage.setItem("uFlag", response.data.userModel.uflag);
                    localStorage.setItem("Email", response.data.userModel.email);
                    localStorage.setItem("Phone", response.data.userModel.mobileNumber);
                    setUFlag(response.data.userModel.uflag)
                    setToken(response.data.userToken)
                    setUserData(response.data.userModel.role)
                    if (response.data.userModel.firstName !== null) {
                      localStorage.setItem("Name", response.data.userModel.firstName);
                    }
                    if (response.data.userModel.address !== null) {
                      localStorage.setItem("Address", response.data.userModel.address);
                    }
                    setUserdata(response.data.userModel.role)
                    setUserAddress(response.data.userModel.address);
                    setAdd(response.data.userModel.address)
                    setUsertoken(response.data.userToken)
                    setLoginmodal(false)


                    Alert({
                      header: "Atmagram",
                      message: "LoggedIn Successfully",
                      backdropDismiss: false,
                      buttons: [
                        {
                          text: "ok",
                          handler: () => {
                            history.push("/mycart")

                            // payment(response.data.userToken)
                          }
                        }
                      ]
                    })

                  }
              }
              // }
              else {
                // Alert(response.data.responseText.split("ERROR:")[1]);
                loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
              }
            } else {
              // alert(response.data.responseText.split("ERROR:")[1]);
              loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
            }
            setShowLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setShowLoading(false);
          });

        // ...
      }).catch((err: any) => {
        // User couldn't sign in (bad verification code?)
        console.log(err.error);
        // alert("Not a valid verification code or Code expired, please try again")
        loginalertmodalaction(true, "Not a valid verification code or Code expired, please try again")
        // if(error.error.message=='SESSION_EXPIRED'){
        //   alert("OTP expired");
        // }else{

        // }
        // ...
      });
    }
  }
  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const Searchlog = (value: any) => {
    if (value == "Enter") {

      Loginsubmit()

    }
  }
  const Loginsubmit = () => {
    const errors: any = {};

    if (logemail.trim() === '') {
      errors.logemail = 'Email is required';
    }
    else if (validator.isEmail(logemail) === false) {
      errors.logemail = "Enter Valid Email Address";
    }

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      UserService.Login(logemail, password)
        .then((response) => {
          setShowLoading(false);

          if (response.data.responseText.startsWith("SUCCESS")) {
            // if (Capacitor.getPlatform() === "web") {
            //   console.log(response.data)
            if (response.data.userModel.role !== "ROLE_USER") {
              loginalertmodalaction(true, "This Account is not a user")
            }
            else
              if (response.data.userModel.uflag === "false") {
                console.log(response.data.userModel.uflag, "uflag")
                loginalertmodalaction(true, "Account Not Approved Yet")
              }
              else {
                localStorage.setItem("token", response.data.userToken);
                localStorage.setItem("UserId", response.data.userModel.id);
                setUserId(response.data.userModel.id)
                localStorage.setItem("UserData", response.data.userModel.role);
                localStorage.setItem("uFlag", response.data.userModel.uflag);
                localStorage.setItem("Email", response.data.userModel.email);
                localStorage.setItem("Phone", response.data.userModel.mobileNumber);
                setUFlag(response.data.userModel.uflag)
                setToken(response.data.userToken)
                setUserData(response.data.userModel.role)
                if (response.data.userModel.firstName !== null) {
                  localStorage.setItem("Name", response.data.userModel.firstName);
                }
                if (response.data.userModel.address !== null) {
                  localStorage.setItem("Address", response.data.userModel.address);
                }

                setUserdata(response.data.userModel.role)
                setUserAddress(response.data.userModel.address);
                setAdd(response.data.userModel.address)
                setUsertoken(response.data.userToken)
                setLoginmodal(false)

                //calDelCharge(response.data.userToken);
                Alert({
                  header: "Atmagram",
                  message: "LoggedIn Successfully",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                      handler: () => {
                        history.push("/mycart")
                        // payment(response.data.userToken)
                      }
                    }
                  ]
                })
              }
            // localStorage.setItem("Address", response.data.userModel.address);
            // localStorage.setItem("Name", response.data.userModel.firstName + response.data.userModel.lastName);
            console.log(response.data.userModel.uflag);
          }
          //  }
          else {
            loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
          }
        })
        .catch((error) => {
          setLoginmodal(true)
          console.log(error);
          setShowLoading(false);
        });
    }
  };
  const submit = () => {
    const errors: any = {};

    if (firstname1 == "") {
      errors.firstname1 = "Enter First Name"
    }
    else if (namecheck.test(firstname1) == false) {
      errors.firstname1 = 'Alphabets only Allowed in First Name'
    }
    else if (firstname1.charAt(0) == " ") {
      errors.firstname1 = 'Empty Space at First Name'
    }
    if (phone1 === '' || phone1 === undefined) {
      errors.phone1 = "Mobile Number is required"
    } else if (!isValidPhoneNumberSign) {
      errors.phone1 = "Please Enter Valid Mobile Number"
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (email1.trim() === '') {
      errors.email1 = 'Email is required';
    }
    else if (validator.isEmail(email1) === false) {
      errors.email1 = "Enter Valid Email Address";
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }
    if (password1.trim() === '') {
      errors.password1 = 'Password is required';
    }
    if (confirmpassword.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (password1 != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      UserService.Registermaterials(
        // aadhar1,
        // addr,
        // pincode1,
        // area1,
        // city1,
        // state1,
        // country1,
        email1,
        firstname1,
        // lastname1,
        phone1,
        password1,
        user,
        user
      )
        .then((response) => {
          console.log("res", response);
          setShowLoading(false);
          if (response.responseText.startsWith("SUCCESS")) {

            setConfirmpassword("");
            // setCountry1("");
            setEmail1("");
            setFirstname1("");
            // setLastname1("");
            setPassword1("");
            setPhone1("");
            setUser("");
            // setPincode1("");
            loginalertmodalaction(true, "Register Successfully")
            setSignupmodal(false)
            console.log(response, "resss =>")
          } else if (response.responseText == "ERROR:Email Id already exist") {
            loginalertmodalaction(true, "Email Id  Exists, Try Any Other Email Id")
          } else if (response.responseText == "ERROR:MobileNumber already exist") {
            loginalertmodalaction(true, "Mobile Number  Exists, Try Any Other Mobile Number")
          } else {
            loginalertmodalaction(true, "User Not Register Successfully")
          }
        })
        .catch((error) => {
          console.log(error)
          setShowLoading(false);
          loginalertmodalaction(true, "Something went wrong")
        });

    }
  }

  const delCart = (e: any) => {
    setModalopen(false);
    Authservice.removeCart(token, delData.id).then((response: any) => {
      if (response.data.resposeSuccess) {
        Cart();
        Alert('Item removed from cart')
      }
    })
      .catch((error: any) => {
        Alert(error)

      });

  }
  const [errormodal, setErrormodal] = useState(false)
  const [errors, setErrors] = useState([])
  const proceedPayment = (tkn: any) => {
    if (cartList.length == 0) {
      setShowLoading(false);
      Alert('your cart is empty')
      return;
    }
    console.log(payData, "jksdbfdjkb")
    var obj = { "userCartDetailDtoLst": payData }
    console.log(paymentdata)
    Authservice.payInit(tkn, obj).then((response: any) => {
      console.log(response.data)
      // alert('Order placed successfully, Please visit MyOrder')
      // window.location.replace("/orders")
      if (response.data.errors !== null) {
        setErrormodal(true)
        setErrors(response.data.errors)
      }
      else if (response.data.initResMsg !== null) {
        Alert(response.data.initResMsg)
      }
      else {
        var options: any = {
          key: response.data.pgOption.key,
          key_secret: response.data.secretKey,
          amount: response.data.pgOption.amount,
          currency: response.data.pgOption.currency,
          name: response.data.pgOption.name,
          order_id: response.data.pgOption.order_id,
          description: response.data.pgOption.description,
          modal: {
            escape: false, ondismiss: function () {
              // code here 
              Alert("Payment Failed")
            }
          },
          handler: function (res: any) {
            // alert(res.razorpay_payment_id+"/"+res.razorpay_order_id+res.razorpay_signature);
            var resData: any = {

              "amount": response.data.pgOption.amount,
              "orderId": res.razorpay_order_id,
              "cartPayRefId": response.data.cartPayRefId,
              "signature": res.razorpay_signature,
              "paymentId": res.razorpay_payment_id

            }
            console.log("resData", resData);
            Authservice.payOfferingRes(token, resData)
              .then((response) => {
                console.log("payresponse" + response);
                Alert({
                  header: "Atmagram",
                  message: "Order placed successfully, Please visit My Offerings",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                      handler: () => {
                        window.location.reload()
                      }
                    }
                  ]
                })
              })
              .catch((error) => {
                Alert("Something went wrong, please try again later.");
              });

          },
          prefill: {
            name: response.data.pgOption.name,
            email: response.data.email,
            contact: response.data.contact

          },

          theme: {
            color: response.data.theme
          }
        };
        var pay: any = new Razorpay(options)
        pay.on("payment.failed", function (data: any) {
          Alert(data.error.reason);
        });
        setShowLoading(false);
        pay.open()
      }
    })

  }
  const setPaymentData = (list: any, tkn: any) => {
    //  setPayData([]);

    for (let order of list) {
      let user: any;
      if (order.userModel == null) {
        user = userAddress
      } else {
        user = order.userModel.id;
      }
      var data = {
        "id": order.id,
        "userModel": {
          "id": user
        },
        "poojaOfferings": {

          "id": order.poojaOfferings.id,
          "price": order.poojaOfferings.price
        },
        "firstName": order.firstName,
        "mailId": order.mailId,
        "mobileNumber": order.mobileNumber,
        "deliveryAddress": order.deliveryAddress,
        "prasadhamDelFlag": order.prasadhamDelFlag,
        "fromDate": order.fromDate,
        "toDate": order.toDate,
        "payDakshinaToPriestFlag": order.payDakshinaToPriestFlag,
        "payDakshinaToTempleFlag": order.payDakshinaToTempleFlag,
        "dakshinaAmountForPriest": order.dakshinaAmountForPriest,
        "dakshinaAmountToTemple": order.dakshinaAmountToTemple,
        "delFlag": order.delFlag,
        "noOfMonths": order.noOfMonths
      }
      console.log(data)
      payData.push(data)
    }
    setTimeout(() => {
      proceedPayment(tkn);
    }, 3000);







  }
  const payment = (tkn: any) => {
    setShowLoading(true);
    setPaymentData(cartData, tkn)
  }
  const goBack = () => {
    setShowOtp(false)
  }

  const Cart = () => {
    setShowLoading(true);
    var guestTkn: any = localStorage.getItem("guestTkn");

    Authservice.Addcart(token, guestTkn).then((response: any) => {
      setcart(response.data)
      setShowLoading(false);
      console.log(response.data, "hjdh")
      setCartData(response.data)
      var list = response.data;
      amount = 0;
      if (token != null && token != undefined && token != "") {
        localStorage.removeItem("guestTkn")
      }

      for (let obj of list) {
        if (obj.dakshinaAmountToTemple != null) {
          amount = amount + obj.dakshinaAmountToTemple;
        }
        // if (obj.pdDelCharge != null) {
        //     amount = amount + obj.pdDelCharge;
        // }
        if (obj.dakshinaAmountForPriest != null) {
          amount = amount + obj.dakshinaAmountForPriest;
        }
        amount = amount + obj.totalAmount;
        setTotalAmount(amount)
      }


    }).catch((error) => {
      setcart([])
      setShowLoading(false);
    });
  }

  useEffect(() => {
    // if (userdata[0] === null) {
    //     history.push("/login")
    // }
    // else if (userdata[0] !== "ROLE_USER") {
    //     history.push("/home")
    // }
    // else if (uFlag[0] === "false") {
    //     history.push("/home")
    // }
    // else {
    Cart();
    //}
  }, [])
  const callDelModal = (e: any) => {
    setModalopen(true);
    setDelData(e)
  }
  const edit = (item: any) => {
    console.log(item)
    history.push({
      pathname: "/offerings",
      state: { item }
    })
  }
  const cancelDelModal = () => {
    setModalopen(false);
    setDelData('');



  }


  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"login"} Role={'Role'} Name='Home,My Cart' />
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() => setShowLoading(false)}

        // duration={5000}
        />
        {/* <UserHead User="materials" Role={userdata[0]} /> */}
        <Modal show={errormodal} className="gramerce-modal" centered>
          <ModalHeader className="modal-header">

            <h5 className="modal-title">Atmagram</h5>
            <div>
              <IonIcon src={close} className="close_icon" onClick={() => setErrormodal(false)} />


            </div>
          </ModalHeader>
          <Modal.Body>

            <div style={{ overflowY: "auto" }}>
              {errors.map((item: any, i: any) => (
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <IonText style={{ fontWeight: "bold" }}>{i + 1}.</IonText>
                  <IonText style={{ paddingLeft: "5px" }}>{item}</IonText>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={loginalertmodal} className="login_alert_modal" centered>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <h5 className="login_alert_title">Atmagram</h5>
            </div>
            <div style={{ textAlign: "center" }}>
              <IonText>{loginalertdata}</IonText>
            </div>
            <div style={{ textAlign: "right", paddingTop: "15px" }}>
              <IonText style={{ cursor: "pointer" }} onClick={() => alertmodalclose()}>OK</IonText>
            </div>
          </Modal.Body>
        </Modal>
        <div className="backgroundorders page_content_padding">

          {showLoading === false && cartList.length == 0 ?
            <div style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center" }}>
              <IonText className="no_data">Your Cart is Empty</IonText>
            </div> : <div>
              <IonGrid>

                {cartList.slice(0).reverse().map((item: any, i: any, index: any) => {
                  // console.log(item);
                  // console.log(item.firstName)
                  // console.log(item.fromDate)
                  // console.log(item.toDate)
                  // console.log(item.listOfDates)
                  var fd: any = ""
                  if (item.fromDate === null) {
                    fd = ""
                  }
                  else {
                    var string = item.fromDate.split('T');
                    var str = string[0];
                    fd = moment(str).format("DD-MM-YYYY")
                    // console.log(fd)
                  }

                  var format: any = ""
                  if (item.toDate === null) {
                    format = ""
                  }
                  else {
                    var string1 = item.toDate.split('T');
                    var str1 = string1[0];
                    var format: any = moment(str1).format("DD-MM-YYYY")
                    // console.log(format)
                  }

                  var lddate: any = ""
                  let ldmdate: any;
                  if (item.listOfDates === null || item.listOfDates === "") {
                    lddate = "";
                  }
                  else {
                    var ld = item.listOfDates.split("||");
                    var ld2 = ld.splice(-1);
                    var ld3 = ld.toString();
                    const ld4 = ld3.split('T12:00:00,').join().split("T12:00:00").join();
                    const ld5 = ld4.split(",");
                    const ld6 = ld5.splice(-1);
                    lddate = ld5.join(", ")
                    const ldm2: any = [];
                    for (let i = 0; i < ld5.length; i++) {
                      var ldm = moment(ld5[i]).format("DD-MM-YYYY")
                      ldm2.push(ldm);
                    }
                    ldmdate = ldm2.join(", ");
                  }
                  // console.log(lddate,"ifud");

                  return (
                    <IonCard className="ordercard">
                      <IonRow className="order_header">
                        <IonCol>

                          <IonText className="order_id">{i + 1}. {item.poojaOfferings.name}</IonText>
                        </IonCol>
                        <IonCol style={{ display: "flex", justifyContent: "flex-end", paddingRight: "20px" }}>
                          <IonText className="order_id">₹ {formatter.format(item.totalAmount)}</IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol sizeLg="12" sizeMd="12" sizeXl="12" sizeSm="12">
                          <div key={i}>
                            <Modal show={modalopen} className="gramerce-modal" centered >
                              <Modal.Body>
                                <IonRow>
                                  <IonCol style={{ textAlign: "center" }}>
                                    <h5 className="mt-2">Are You Sure Want To Delete?</h5>
                                  </IonCol>
                                </IonRow>
                                <IonRow className="mt-12">
                                  <IonCol size="6" style={{ textAlign: "end" }}>
                                    <IonButton onClick={() => delCart(item)}><span className="button_padding">Yes</span></IonButton>
                                  </IonCol>
                                  <IonCol size="6">
                                    <IonButton onClick={(e) => cancelDelModal()}><span className="button_padding">No</span></IonButton>
                                  </IonCol>
                                </IonRow>
                              </Modal.Body>
                            </Modal>
                            <Modal show={signupmodal} className='login-modal' centered>
                              <ModalHeader className="profilenodal-Header">
                                <h5 className="profileModal-title">Sign Up</h5>
                                <div>
                                  <IonIcon src={close} className="close_icon" onClick={() => signupmodalclose()} />
                                </div>
                              </ModalHeader>
                              <Modal.Body ref={signupmodalref}>

                                <form onSubmit={handleSubmit(submit)}>
                                  <div>
                                    <IonRow>
                                      <IonCol size="12" size-lg="7" className="email_container">
                                        <div>
                                          <IonLabel className="label_align">
                                            First Name
                                            <span style={{ color: "red" }}>*</span>
                                          </IonLabel>
                                          <IonInput
                                            type="text" className="select_user"
                                            value={firstname1}
                                            // required
                                            onBlur={() => Validation("firstname")}
                                            onIonChange={(e) => { setFirstname1(e.detail.value!); ChangeValidation("firstname", e) }}
                                          ></IonInput>
                                        </div>
                                        {formErrors.firstname1 && <span className="alert_input_bottom ">{formErrors.firstname1}</span>}
                                      </IonCol>
                                    </IonRow>
                                    <IonRow >
                                      <IonCol size="12" size-lg="7" >
                                        <div>
                                          <IonLabel className="label_align">
                                            Mobile Number <span style={{ color: "red" }}>*</span>
                                          </IonLabel>
                                          <div className="select_user">
                                            <PhoneInput
                                              international
                                              defaultCountry="IN"
                                              value={phone1}
                                              ref={phoneInputRef}
                                              onBlur={() => Validation("mobile")}
                                              onChange={(value: any) => { moveCursorToEnd(value); ChangeValidation("mobile", value) }}
                                            /></div>
                                        </div>
                                        {formErrors.phone1 && <span className="alert_input_bottom ">{formErrors.phone1}</span>}
                                      </IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size="12" size-lg="7" >
                                        <div>
                                          <IonLabel className="label_align" >
                                            E-mail <span style={{ color: "red" }}>*</span>
                                          </IonLabel>
                                          <IonInput
                                            type="text"
                                            value={email1}
                                            className="select_user"
                                            onBlur={() => Validation("email")}
                                            onIonChange={(e) => { setEmail1(e.detail.value!); ChangeValidation("email", e) }}
                                          ></IonInput>
                                        </div>
                                        {formErrors.email1 && <span className="alert_input_bottom ">{formErrors.email1}</span>}
                                      </IonCol>
                                    </IonRow>
                                    <IonRow >
                                      <IonCol size="12" size-lg="7" className="pass_container">
                                        <div>
                                          <IonLabel className="label_align">
                                            Password <span style={{ color: "red" }}>*</span>
                                          </IonLabel>
                                          <IonInput
                                            type="password"
                                            value={password1}
                                            clearOnEdit={false}
                                            className="select_user"
                                            onBlur={() => Validation("password")}
                                            onIonChange={(e) => { setPassword1(e.detail.value!); ChangeValidation("password", e) }}
                                          ></IonInput>
                                        </div>
                                        {formErrors.password1 && <span className="alert_input_bottom ">{formErrors.password1}</span>}
                                      </IonCol>
                                    </IonRow>
                                    <IonRow >
                                      <IonCol size="12" size-lg="7" className="pass_container">
                                        <div>
                                          <IonLabel className="label_align">
                                            Confirm Password <span style={{ color: "red" }}>*</span>
                                          </IonLabel>
                                          <IonInput
                                            type="password"
                                            className="select_user"
                                            clearOnEdit={false}
                                            value={confirmpassword}
                                            onBlur={() => Validation("confirmpassword")}
                                            onIonChange={(e) => { setConfirmpassword(e.detail.value!); ChangeValidation("confirmpassword", e) }}
                                          ></IonInput>
                                        </div>
                                        {formErrors.confirmpassword && <span className="alert_input_bottom ">{formErrors.confirmpassword}</span>}
                                      </IonCol>
                                    </IonRow>
                                    <div style={{ display: "flex", alignItems: "center" }} className="pass_container">
                                      <div>
                                        <Link onClick={(event: any) => { setLoginmodal(true); event.preventDefault(); setSignupmodal(false) }} className="forgot_pass_css" to={"#"}>Already have an account?</Link>
                                      </div>
                                    </div>
                                    <div className={firstname1.length === 0 ||
                                      email1.length === 0 ||
                                      password1.length === 0 ||
                                      confirmpassword.length === 0 ? "disableCursor signupbtn_container_width" : ""}>
                                      <IonButton
                                        type="submit"
                                        disabled={
                                          firstname1.length === 0 ||
                                          email1.length === 0 ||
                                          password1.length === 0 ||
                                          confirmpassword.length === 0
                                        }                  >
                                        <span className="button_padding">Sign Up</span>
                                      </IonButton>
                                    </div>
                                  </div>
                                </form>
                              </Modal.Body>
                            </Modal>
                            <Modal show={loginmodal} className='login-modal' centered>
                              <ModalHeader className="profilenodal-Header">
                                <h5 className="profileModal-title">Login</h5>
                                <div>
                                  <IonIcon src={close} className="close_icon" onClick={() => loginmodalclose()} />
                                </div>
                              </ModalHeader>
                              <Modal.Body ref={loginmodalref}>

                                {loginIndex === 0 ?
                                  <form onSubmit={handleSubmit(Loginsubmit)} onKeyPress={e => Searchlog(e.key)}>
                                    <div>
                                      <IonRow>
                                        <IonCol size="11" size-lg="7" className="email_container">
                                          <div>
                                            <IonLabel className="label_align">
                                              E-mail <span style={{ color: "red" }}>*</span>
                                            </IonLabel>
                                            <IonInput className="select_user"
                                              type="text"
                                              placeholder="Enter mail-id"
                                              value={logemail}
                                              onBlur={() => Validation("login email")}
                                              onIonChange={(e) => { setLogemail(e.detail.value!); ChangeValidation("login email", e) }}
                                            ></IonInput>
                                          </div>
                                          {formErrors.logemail && <span className="alert_input_bottom ">{formErrors.logemail}</span>}
                                        </IonCol>
                                      </IonRow>
                                      <IonRow >
                                        <IonCol size="11" size-lg="7" className="pass_container">
                                          <div>
                                            <IonLabel className="label_align">
                                              Password <span style={{ color: "red" }}>*</span>
                                            </IonLabel>
                                            <IonInput
                                              type="password" className="select_user"
                                              clearOnEdit={false}
                                              placeholder="Enter password"
                                              onBlur={() => Validation("login password")}
                                              onIonChange={(e) => { setPassword(e.detail.value!); ChangeValidation("login password", e) }}
                                            ></IonInput>
                                          </div>
                                          {formErrors.password && <span className="alert_input_bottom ">{formErrors.password}</span>}
                                        </IonCol>
                                      </IonRow>
                                      <div style={{ display: "flex", alignItems: "center" }} className="pass_container">
                                        <div>
                                          <Link onClick={(event: any) => { setSignupmodal(true); event.preventDefault(); setLoginmodal(false) }} className="forgot_pass_css" to={"#"}>Don't have account?</Link>
                                        </div>
                                      </div>
                                      <div className="login_modal_btn_container">
                                        <div className={logemail.length === 0 || password.length === 0 ? "cursor_block" : ""}>
                                          <IonButton
                                            type="submit"
                                            disabled={logemail.length === 0 || password.length === 0}
                                          >
                                            <span className="button_padding">Login</span>
                                          </IonButton>
                                        </div>
                                        <div className="center or_text_container">
                                          <p> or</p>
                                        </div>
                                        <IonButton onClick={phoneLogin}>
                                          <span className="button_padding">Login With Phone</span>
                                        </IonButton>
                                      </div>
                                    </div>
                                  </form> : <div>

                                    {!showOtp ? <>
                                      <IonRow>
                                        <IonCol size="11" size-lg="7" className="email_container">
                                          <IonLabel className="label_align">
                                            Mobile Number <span style={{ color: "red" }}>*</span>
                                          </IonLabel>
                                          <PhoneInput
                                            international
                                            defaultCountry="IN"
                                            value={loginphone}
                                            className="select_user"
                                            ref={loginphoneRef}
                                            // flags=false
                                            onBlur={(e) => Validation("loginphone")}
                                            onChange={(value: any) => { loginmoveCursorToEnd(value); ChangeValidation("loginphone", value) }}
                                          />
                                          {formErrors.loginPhone && <span className="alert_input_bottom ">{formErrors.loginPhone}</span>}
                                        </IonCol>
                                      </IonRow>

                                      <IonRow>
                                        <IonCol size="11" size-lg="7" className="pass_container">
                                          <div id="recaptcha-container"></div>
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol size="12" size-lg="12" className="textalign-center login_modal_btn_container">
                                          <div className={loginphone === undefined || loginphone === undefined || !isValidLoginNumber ? "cursor_block" : ""}>
                                            <IonButton
                                              disabled={loginphone === undefined || loginphone === undefined || !isValidLoginNumber}
                                              onClick={checkMobile}
                                            >
                                              <span className="button_padding">Get OTP</span>

                                            </IonButton>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </> : <></>}

                                    {showOtp ?
                                      <>
                                        <IonRow>
                                          <IonCol size="11" size-lg="7" className="email_container">

                                            <IonLabel className="label_align">
                                              Enter OTP <span style={{ color: "red" }}>*</span>
                                            </IonLabel>
                                            <IonInput className="select_user"
                                              onBlur={(e) => Validation("otp")}
                                              onIonChange={(e) => { setOtp(e.detail.value!); ChangeValidation("otp", e) }}
                                              required
                                            ></IonInput>

                                            {formErrors.otp && <span className="alert_input_bottom ">{formErrors.otp}</span>}
                                          </IonCol>
                                        </IonRow>

                                        <IonRow>
                                          <IonCol size="11" size-lg="7" className="pass_container" >
                                            <span className="go_back_style"
                                              onClick={goBack}
                                            >go back
                                            </span>
                                          </IonCol>
                                        </IonRow>
                                        <div className="login_modal_btn_container">
                                          <IonButton
                                            onClick={verifyOTP}
                                          >
                                            <span className="button_padding">Verify OTP</span>

                                          </IonButton>
                                        </div>
                                      </> : <></>
                                    }
                                    <div className="login_modal_btn_container">
                                      <div className="center or_text_container"><p>
                                        or</p></div>
                                      <IonButton onClick={emailLogin}>
                                        <span className="button_padding">Login With Email</span></IonButton>
                                    </div>
                                  </div>}
                              </Modal.Body>
                            </Modal>
                            {/* <IonRow>
                                                            <IonCol size-lg="1" size-md="1" size-sm="2" size-xs="2" className="center_col" >
                                                                <div className="circular-background">
                                                                    <IonText className="ord_count">{i + 1} </IonText>
                                                                </div>

                                                            </IonCol>
                                                        </IonRow> */}
                            {/* <IonRow>
                                                            <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                                                <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                </IonIcon>
                                                            </IonCol>
                                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                                        <IonText  className="order_detail">Offering Name </IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5">
                                                        <IonText  className="order_detail">:</IonText>
                                                    </IonCol>
                                                    <IonCol size-lg="5.5" size-md="5.5" size-sm="5.5" size-xs="5.5">
                                                        <IonText  className="order_detail">{item.poojaOfferings.name}</IonText>
                                                    </IonCol>
                                                   

                                                        </IonRow> */}
                            <IonRow>
                              <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12">
                                <IonRow>
                                  <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                    </IonIcon>
                                  </IonCol>
                                  <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                    <IonText className="order_detail">Temple Name</IonText>
                                  </IonCol>
                                  <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                    <IonText className="order_detail">:</IonText>
                                  </IonCol>
                                  <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                    <IonText className="order_detail">{item.poojaOfferings.temple.name}</IonText>
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                    </IonIcon>

                                  </IonCol>
                                  <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                    <IonText className="order_detail">Deity Name</IonText>
                                  </IonCol>
                                  <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                    <IonText className="order_detail">:</IonText>
                                  </IonCol>
                                  <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                    <IonText className="order_detail">{item.poojaOfferings.deity.deityName}</IonText>
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                    </IonIcon>

                                  </IonCol>
                                  <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                    <IonText className="order_detail">Price</IonText>
                                  </IonCol>
                                  <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                    <IonText className="order_detail">:</IonText>
                                  </IonCol>
                                  <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                    <IonText className="order_detail">₹ {formatter.format(item.poojaOfferings.price)}</IonText>
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                    </IonIcon>

                                  </IonCol>
                                  <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                    <IonText className="order_detail">Total Price For All days</IonText>
                                  </IonCol>
                                  <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                    <IonText className="order_detail">:</IonText>
                                  </IonCol>
                                  <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                    <IonText className="order_detail">₹ {formatter.format(item.totalAmount)}</IonText>
                                  </IonCol>
                                </IonRow>
                                {item.fromDate == null ? <></> :
                                  <IonRow>
                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                      <IonIcon className='tick' src={checkmarkCircleSharp} >

                                      </IonIcon>

                                    </IonCol>
                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                      <IonText className="order_detail">From Date</IonText>
                                    </IonCol>
                                    <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                      <IonText className="order_detail">:</IonText>
                                    </IonCol>
                                    <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                      <IonText className="order_detail">{fd}</IonText>
                                    </IonCol>
                                  </IonRow>
                                }
                                {item.toDate == null ? <></> :
                                  <IonRow>
                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                      <IonIcon className='tick' src={checkmarkCircleSharp} >
                                      </IonIcon>
                                    </IonCol>
                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                      <IonText className="order_detail">To Date</IonText>
                                    </IonCol>
                                    <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                      <IonText className="order_detail">:</IonText>
                                    </IonCol>
                                    <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                      <IonText className="order_detail">{format}</IonText>
                                    </IonCol>
                                  </IonRow>
                                }
                                {item.listOfDates == null ? <></> :
                                  <IonRow>
                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                      <IonIcon className='tick' src={checkmarkCircleSharp} >
                                      </IonIcon>
                                    </IonCol>
                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                      <IonText className="order_detail">Date(s)</IonText>
                                    </IonCol>
                                    <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                      <IonText className="order_detail">:</IonText>
                                    </IonCol>
                                    <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                      <IonText className="order_detail">{ldmdate}</IonText>
                                    </IonCol>
                                  </IonRow>
                                }
                                {item.noOfMonths == null ? <></> :
                                  <IonRow>
                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                      <IonIcon className='tick' src={checkmarkCircleSharp} >

                                      </IonIcon>

                                    </IonCol>
                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                      <IonText className="order_detail">No. of months</IonText>
                                    </IonCol>
                                    <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                      <IonText className="order_detail">:</IonText>
                                    </IonCol>
                                    <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                      <IonText className="order_detail">{item.noOfMonths}</IonText>
                                    </IonCol>
                                  </IonRow>
                                }

                              </IonCol>
                              <IonCol sizeMd="12" sizeXl="6" sizeLg="6" sizeSm="12" sizeXs="12">
                                {item.firstName.length > 0 ? <IonRow>
                                  <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                    <IonIcon className='tick' src={checkmarkCircleSharp} >
                                    </IonIcon>
                                  </IonCol>
                                  <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                    <IonText className="order_detail">Name</IonText>
                                  </IonCol>
                                  <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                    <IonText className="order_detail">:</IonText>
                                  </IonCol>
                                  <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                    <IonText className="order_detail">{item.firstName}</IonText>
                                  </IonCol>
                                </IonRow> : <></>}
                                {item.star === "" ? <></> : <IonRow>
                                  <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                    <IonIcon className='tick' src={checkmarkCircleSharp} >
                                    </IonIcon>
                                  </IonCol>
                                  <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                    <IonText className="order_detail">Star</IonText>
                                  </IonCol>
                                  <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                    <IonText className="order_detail">:</IonText>
                                  </IonCol>
                                  <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                    <IonText className="order_detail">{item.star}</IonText>
                                  </IonCol>
                                </IonRow>}
                                {(item.userModel != null && item.userModel.mobileNumber !== null) && <IonRow>
                                  <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                    </IonIcon>

                                  </IonCol>
                                  <IonCol size-lg="5" size-md="5" size-xs="5" style={{ display: "flex", alignItems: "center" }}>
                                    <IonText className="order_detail">Mobile Number</IonText>
                                  </IonCol>
                                  <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5" style={{ display: "flex", alignItems: "center" }}>
                                    <IonText className="order_detail">:</IonText>
                                  </IonCol>
                                  <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5" style={{ display: "flex", alignItems: "center" }}>
                                    <IonText className="order_detail">{Authservice.formatPhone(item.userModel.mobileNumber)}</IonText>
                                  </IonCol>
                                </IonRow>}
                                {item.deliveryAddress == "" || item.deliveryAddress == "," ? <></> :
                                  <IonRow>
                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                      <IonIcon className='tick' src={checkmarkCircleSharp} >
                                      </IonIcon>
                                    </IonCol>
                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                      <IonText className="order_detail">Delivery Address</IonText>
                                    </IonCol>
                                    <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                      <IonText className="order_detail">:</IonText>
                                    </IonCol>
                                    <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                      <IonText className="order_detail">{item.deliveryAddress}</IonText>
                                    </IonCol>
                                  </IonRow>
                                }
                                {item.pdDelCharge == null ? <></> :
                                  <IonRow>
                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                      <IonIcon className='tick' src={checkmarkCircleSharp} >
                                      </IonIcon>
                                    </IonCol>
                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                      <IonText className="order_detail">Delivery Charge</IonText>
                                    </IonCol>
                                    <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                      <IonText className="order_detail">:</IonText>
                                    </IonCol>
                                    <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">

                                      <IonText className="order_detail">₹ {formatter.format(item.pdDelCharge)}</IonText>
                                    </IonCol>
                                  </IonRow>}
                                {item.dakshinaAmountForPriest == null ? <></> :
                                  <IonRow>
                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                      <IonIcon className='tick' src={checkmarkCircleSharp} >
                                      </IonIcon>
                                    </IonCol>
                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                      <IonText className="order_detail">Dakshina Amount</IonText>
                                    </IonCol>
                                    <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                      <IonText className="order_detail">:</IonText>
                                    </IonCol>
                                    <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                      <IonText className="order_detail">₹ {formatter.format(item.dakshinaAmountForPriest)}</IonText>
                                    </IonCol>
                                  </IonRow>
                                }
                                {item.dakshinaAmountToTemple == null ? <></> :
                                  <IonRow>
                                    <IonCol size-lg="1" size-md="1" size-sm="1" size-xs="1" className='tick_container' >
                                      <IonIcon className='tick' src={checkmarkCircleSharp} >
                                      </IonIcon>
                                    </IonCol>
                                    <IonCol size-lg="5" size-md="5" size-sm="5" size-xs="5">
                                      <IonText className="order_detail">Donation Amount</IonText>
                                    </IonCol>
                                    <IonCol size-lg="0.5" size-md="0.5" size-xs="0.5">
                                      <IonText className="order_detail">:</IonText>
                                    </IonCol>
                                    <IonCol size-lg="5.5" size-md="5.5" size-xs="5.5">
                                      <IonText className="order_detail">₹ {formatter.format(item.dakshinaAmountToTemple)}</IonText>
                                    </IonCol>
                                  </IonRow>
                                }
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol>
                                {item.elapseFlag == true ?
                                  <IonRow>
                                    <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5" className='tick_container' >

                                    </IonCol>
                                    <IonCol size-lg="10" size-md="10" size-xs="10">
                                      <IonText style={{ color: "red" }}>* Note : Your Offering Date(s) has Elapsed, Please edit your offering.</IonText>
                                    </IonCol>
                                  </IonRow> : item.poojaOfferings.delFlag === true ?
                                    <IonRow>
                                      <IonCol size-lg="0.5" size-md="0.5" size-sm="0.5" size-xs="0.5" className='tick_container' >

                                      </IonCol>
                                      <IonCol size-lg="10" size-md="10" size-xs="10">
                                        <IonText style={{ color: "red" }}>* Note : This Offering is not available now, Please remove from your cart.</IonText>
                                      </IonCol>
                                    </IonRow> : <></>
                                }
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol>
                                <div className="center_col">
                                  <IonButton tabIndex={1} onClick={() => callDelModal(item)} className="del_class"
                                    onKeyDown={(event) => {
                                      if (event.key === "Enter" || event.key === " ") {
                                        callDelModal(item)
                                      }
                                    }}
                                  >
                                    <span className="del_pad">Delete</span>
                                  </IonButton>
                                  {item.elapseFlag === true ?
                                    <div >
                                      <IonButton className="edit_box center_col" onClick={() => edit(item)} onKeyDown={(event) => {
                                        if (event.key === "Enter" || event.key === " ") {
                                          edit(item)
                                        }
                                      }}>
                                        <span className="del_pad">Edit </span>
                                      </IonButton>
                                    </div>
                                    : <></>}
                                </div>
                              </IonCol>
                            </IonRow>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  );
                })}
              </IonGrid>
            </div>}

          <div style={{ textAlign: "center" }}>
            {cartList.length == 0 ? <></> : <div>
              <IonRow>
                <IonCol size="6" style={{ textAlign: "right" }}>
                  <IonText className="order_detail">No Of Items </IonText>
                </IonCol>
                <IonCol size="6" style={{ textAlign: "left" }}>
                  <IonText className="order_detail">: {cartList.length}</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6" style={{ textAlign: "right" }}>
                  <IonText className="order_detail">Total Price (₹) </IonText>
                </IonCol>
                <IonCol size="6" style={{ textAlign: "left" }}>
                  <IonText className="order_detail">: {formatter.format(totalAmount)}</IonText>
                </IonCol>
              </IonRow>
              {/* <IonText  className="order_detail">No Of Items : {cartList.length}</IonText>
                            <br/>
                            <br></br>
                            <IonText  className="order_detail">Total Price (₹) : {formatter.format(totalAmount)}</IonText>
                            <br></br> */}
              <IonRow>
                <IonCol sizeLg="6" sizeXl="6" sizeMd="12" sizeXs="12" >
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <IonButton disabled={cartList.some((e: any) => e.elapseFlag === true) || cartList.some((e: any) => e.poojaOfferings.delFlag === true)}
                      onClick={(e) => {
                        if (usertoken == null) {

                          setLoginmodal(true)
                        } else {
                          payment(usertoken)
                        }
                      }

                      }>
                      <span className="button_padding">Proceed to Payment</span></IonButton>
                  </div>
                </IonCol>
                <IonCol sizeLg="6" sizeXl="6" sizeMd="12" sizeXs="12" >
                  <div className="cart_btn">
                    <IonButton
                      onClick={(e) => history.push("/offerings")}>
                      <span className="button_padding">Book More</span>
                    </IonButton></div>

                </IonCol>
              </IonRow>
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                <IonButton disabled={cartList.some((e: any) => e.elapseFlag === true)} style={{ margin: "5px" }}
                                    onClick={(e) => payment()
                                    }>
                                        <span className="button_padding">Proceed to Payment</span></IonButton>
                                <IonButton style={{ margin: "5px" }}
                                    onClick={(e) => history.push("/offerings")}>
                                        <span className="button_padding">Book More</span> 
                                        </IonButton>
                            </div> */}
            </div>}
          </div>

        </div>

        <Foot />
      </IonContent>
    </IonPage>
  )
}

function setOrderList(arg0: never[]) {
  throw new Error("Function not implemented.");
}


function setcart(data: any) {
  throw new Error("Function not implemented.");
}
export default Mycart;
