import {
  IonCol,
  IonContent,
  IonIcon,
  IonItemDivider,
  IonPage,
  IonRow,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonImg,
  IonModal,
  IonTextarea,
  IonLoading,
  IonSearchbar,
  useIonAlert,
  IonText
} from "@ionic/react";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";
import { useForm } from "react-hook-form";
import UserService from "../../Services/user.service";
import { addCircleOutline, addCircleSharp, addOutline, list, close } from "ionicons/icons";
import DataTable from "react-data-table-component"
import "./Deitymanage.css";
import { useHistory } from "react-router";
import { Capacitor } from "@capacitor/core";
import Authservice from "../../Services/user.service";
import Lightbox from "react-image-lightbox";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";

const Deity: React.FC = () => {
  const [comment, setComment] = useState("");
  const [presentAlert] = useIonAlert();

  const columns: any = [
    {
      name: "S.No",
      sortable: true,
      width: "90px",
      selector: (item: any) => item.serial,
      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => clickRow(item)}>
          {item.serial}
        </div>
      ),
    },
    {
      name: "Deity Image",
      sortable: false,
      width: "150px",

      cell: (item: any) => (
        <div className="center_col_tab" onClick={() => clickRow(item)}>
          <IonImg style={{ width: "70px", height: "70px", cursor: "pointer" }} alt="img" src={item.image + "?v=" + new Date()}></IonImg>
        </div>
      )
    },
    {
      name: "Deity Name",
      selector: (item: any) => item.deityName,
      sortable: true,
      width: "150px",
      style: {
        textAlign: 'left'
      },
      cell: (item: any) => (
        <div className="left_col" onClick={() => clickRow(item)}>
          <div style={{ cursor: "pointer" }} >{item.deityName}</div>
        </div>
      )
    },
    {
      name: "Description",
      selector: (item: any) => item.deityDescription,
      sortable: false,
      //width: "50%",
      cell: (item: any) => (
        <div onClick={() => clickRow(item)} style={{ textAlign: "justify" }}>
          <div className="textDes threelineCls" >{item.deityDescription}</div>
        </div>
      )
    },
  ]


  const {
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const [usertoken] = useState(localStorage.getItem("token"));
  const history = useHistory();
  const modal = useRef<HTMLIonModalElement>(null);
  const userdata: any = useState(localStorage.getItem("UserData"));
  const uFlag: any = useState(localStorage.getItem("uFlag"));
  const [deitylist, setDeitylist] = useState([]);
  const [modalopen, setModalopen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [activeComment, setActiveComment] = useState("");
  const [inActiveComment, setInActivecomment] = useState("");
  const [image, setImage] = useState("");
  const [deityImg, setDeityImg] = useState("");
  const [images, setImages]: any = useState("");
  const [isedit, setIsedit] = useState(false);
  const [deityid, setDeityid] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);


  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  if (deitylist != undefined && deitylist.length > 0) {
    deitylist.forEach((item: any, index: any) => { item.serial = index + 1; });
  }


  useEffect(() => {
    if (userdata[0] === null) {
      history.push("/login")
    }
    else if (userdata[0] !== "ROLE_ADMIN") {
      history.push("/admin_home")
    }
    else if (uFlag[0] === "false") {
      history.push("/admin_home")
    }
    else {
      showalldeity();
    }
  }, []);

  const showalldeity = () => {
    setShowLoading(true);
    UserService.Showdeity(usertoken).then((response) => {
      console.log(response.data);
      setDeitylist(response.data);
      setShowLoading(false);
    })
      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }

  const handleStatus = (status: any, id: any, comm: any) => {
    setShowLoading(true)
    Authservice.deityActIn(usertoken, id, status, comm).then((response) => {
      console.log(response)
      // alert("User Activated")
      window.location.reload()
      setShowLoading(false)
    })
      .catch((error) => {
        alert("Something went wrong, please try again later.");
        setShowLoading(false)
      });

  }

  const clickRow: any = (item: any) => {
    console.log(item);
    setImages("")
    if (item.deityName === null) {
      setName("");
    } else {
      setName(item.deityName);
    }
    if (item.deityDescription === null) {
      setDescription("");
    } else {
      setDescription(item.deityDescription);
    }
    setIsedit(true)
    setDeityid(item.id)
    setImage(item.image + "?v=" + new Date());
    setDeityImg(item.image + "?v=" + new Date());
    setModalopen(true);
  };
  const openmodal = () => {
    setIsedit(false)
    setDescription("");
    setImage("");
    setImages("")
    setName("");
    setFormerrors({})
    setActiveComment("");
    setInActivecomment("");
    setModalopen(true);
  };

  const imageref: any = useRef(null)
  const handleimage = (e: any) => {


    var file: any = e.target.files[0];
    const splited: any = file.name.split(".")
    if (splited[splited.length - 1] === "jpg" || splited[splited.length - 1] === "png" || splited[splited.length - 1] === "jpeg") {
      console.log(images)
      const objectUrl = URL.createObjectURL(file);
      setImages(file);
      setImage(objectUrl);
      setFileName(file.name);
    } else {
      loginalertmodalaction(true, file.name + " is Unsupported File Format, Supported File Formats Are JPG, PNG and JPEG")
      if (imageref.current) {
        imageref.current.value = null;
      }
      return false;
    }
  };
  const Modalclose = () => {
    showalldeity();
    setModalopen(false)
    setFileName("No file Chosen")
    setFormerrors({})
  }

  const [formerrors, setFormerrors]: any = useState({})
  const namecheck = /^[a-zA-Z\s]*$/

  const validation = (num: any) => {
    if (num === 1) {
      if (name === "") {
        setFormerrors({ ...formerrors, name: "Enter the Name" })
      }
      else if (name.charAt(0) === " ") {
        setFormerrors({ ...formerrors, name: "Empty space at Name" })
      }
      else if (namecheck.test(name) == false) {
        setFormerrors({ ...formerrors, name: 'Alphabets only Allowed in First Name' })
      }
      else {
        setFormerrors({ ...formerrors, name: "" })
      }
    }
    else if (num === 2) {
      if (description === "") {
        setFormerrors({ ...formerrors, description: "Enter the Description" })
      }
      else if (description.charAt(0) === " ") {
        setFormerrors({ ...formerrors, description: "Empty space at Description" })
      }
      else {
        setFormerrors({ ...formerrors, description: "" })
      }
    }
  }

  const onChangevalidation = (num: any, e: any) => {
    if (num === 1) {
      var namevalue = e.detail.value!;
      if (namevalue === "") {
        setFormerrors({ ...formerrors, name: "Enter the Name" })
      }
      else if (namevalue.charAt(0) === " ") {
        setFormerrors({ ...formerrors, name: "Empty space at Name" })
      }
      else if (namecheck.test(namevalue) == false) {
        setFormerrors({ ...formerrors, name: 'Alphabets only Allowed in First Name' })
      }
      else {
        setFormerrors({ ...formerrors, name: "" })
      }
      setName(e.detail.value!)
    }
    else if (num === 2) {
      var desvalue = e.detail.value!;
      if (desvalue === "") {
        setFormerrors({ ...formerrors, description: "Enter the Description" })
      }
      else if (desvalue.charAt(0) === " ") {
        setFormerrors({ ...formerrors, description: "Empty space at Description" })
      }
      else {
        setFormerrors({ ...formerrors, description: "" })
      }
      setDescription(e.detail.value!)
    }
  }


  const onSubmit = () => {
    console.log(images)
    var allowedExtensionsRegx = /(\.jpg|\.jpeg|\.png)$/i;
    var isAllowed: any = true

    if (images.length > 0) {
      var extension = images.name.substr(images.name.lastIndexOf("."));
      console.log(extension)
      isAllowed = allowedExtensionsRegx.test(extension);
      console.log(isAllowed)
    }
    //     if(images.length == 0){
    //       const blobObj = new File([image], 'deityImage',{type:"file"});
    //       console.log(blobObj)
    //       setImages(blobObj)
    // }
    var the_char = description.charAt(0);
    if (the_char === " ") {
      loginalertmodalaction(true, "Empty space at description");
      return;
    }
    else if (isAllowed == false) {
      loginalertmodalaction(true, "Only jpg, png, jpeg Files Are Supported")
      // alert("ok")
    }
    else {
      UserService.updateDeity(usertoken, isedit, description, deityid, name, images).then((response) => {
        if (response.data.responseText.startsWith("SUCCESS")) {
          if (isedit) {
            loginalertmodalaction(true, "Updated Successfully")
          } else {
            loginalertmodalaction(true, "Added Successfully")
          }
          setModalopen(false)
          showalldeity();
          window.location.reload();
        } else if (response.data.responseText === "ERROR:Deity Name Already Exist") {
          console.log(response)
          loginalertmodalaction(true, "Deity Name Already Exist")
        } else {
          loginalertmodalaction(true, "Not Updated")
        }
      })
        .catch((error) => {
          loginalertmodalaction(true, "Something went wrong, please try again later.");
        });
    }
  };
  var tableData: any = [];
  if (deitylist != undefined && deitylist.length > 0) {
    tableData = deitylist.filter(
      (item: any) =>
        JSON.stringify(item.deityName)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
    );
  }


  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <IonRow className="table_btn_container">
        <div>
          <IonButton onClick={openmodal} className="admin_add_btn">
            <IonIcon src={addOutline} />
            <span>Add Deity</span>
          </IonButton>
        </div>
        <div>
          <IonSearchbar
            value={filterText}
            onIonChange={(e: any) => setFilterText(e.detail.value)}
            placeholder="Search"
            className="table_search"
          />
        </div>
      </IonRow>
    );
  }, [filterText, resetPaginationToggle]);

  const [showModal, setShowModal] = useState(false)
  const imagenew = () => {
    setShowModal(true)
  }

  const handleButtonClick = () => {
    if (imageref.current) {
      imageref.current.click();
    }
  };

  const [fileName, setFileName] = useState("");
  const [loginalertmodal, setLoginalertmodal] = useState(false)
  const [loginalertdata, setLoginalertdata] = useState("")
  const loginalertmodalaction = (e: any, data: any) => {
    setLoginalertmodal(e)
    setLoginalertdata(data)
  }

  const alertmodalclose = () => {
    setLoginalertmodal(false)
    setLoginalertdata("")
  }

  return (
    <IonPage>
      <AdminHead User={"user"} Role={"Role"} Name={"Deity Management"} />
      <Sidebar />
      <IonContent>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        />
        <Modal show={loginalertmodal} className="login_alert_modal" centered>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <h5 className="login_alert_title">Atmagram</h5>
            </div>
            <div style={{ textAlign: "center" }}>
              <IonText>{loginalertdata}</IonText>
            </div>
            <div style={{ textAlign: "right", paddingTop: "15px" }}>
              <IonText style={{ cursor: "pointer" }} onClick={() => alertmodalclose()}>OK</IonText>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={modalopen} className="admin_modal" centered>
          <Modal.Body>
            <div className="donation-modal-header admin-user-modal-header">
              {isedit ? <h5 className="modal-title">Deity Profile</h5> : <h5 className="modal-title">Add Deity</h5>}
              <div>
                <IonIcon src={close} className="close_icon" onClick={() => { setModalopen(false); setFileName("No file Chosen"); setFormerrors({}) }} />
              </div>
            </div>
            <IonRow style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
              <IonCol size-lg="6" size-sm="12" size-xs="12" size-md="6">
                <div>
                  <IonText className="adm_label_text">Name <span style={{ color: "red" }}>*</span></IonText>
                  <IonInput placeholder="Enter deity name"
                    type="text"
                    readonly={isedit === true}
                    value={name}
                    onBlur={() => validation(1)}
                    onIonChange={(e) => onChangevalidation(1, e)}
                    required
                  />
                  {formerrors.name && <span className="alert_input_bottom ">{formerrors.name}</span>}
                </div>
                <div className="deity_image_div">
                  <IonText className="adm_label_text">Choose Image <span style={{ color: "red" }}>*</span></IonText>
                  <IonRow className="image_div">
                    <IonCol size="6" className="center_col image_content">
                      {images != "" ? <span className="image_content_spn">{fileName}</span> :
                        isedit ? <span className="image_content_spn">Select a File </span>
                          :
                          <span className="image_content_spn">No file Chosen </span>}
                    </IonCol>
                    <IonCol size="6" className="image_content">
                      <input
                        className={images != "" ? "" : "image_chose"}
                        type="file"
                        ref={imageref}
                        onChange={(e) => handleimage(e)}

                        style={{ display: 'none' }}
                      />
                      <IonButton
                        onClick={handleButtonClick}
                        className="admin_img_btn image_content"
                      ><span className="button_padding">
                          Choose File
                        </span>
                      </IonButton>
                    </IonCol>

                  </IonRow>
                </div>
              </IonCol>
              <IonCol size-lg="6" size-sm="12" size-xs="12" size-md="6">
                <IonText className="adm_label_text">Description <span style={{ color: "red" }}>*</span></IonText>
                <IonTextarea
                  className="adm_textarea"
                  autoGrow
                  onBlur={() => validation(2)}
                  value={description}
                  onIonChange={(e) => onChangevalidation(2, e)}
                  required
                />
                {formerrors.description && <span className="alert_input_bottom ">{formerrors.description}</span>}
              </IonCol>
            </IonRow>
            <>
              {image.length === 0 ? (
                <div></div>
              ) : (
                <div className="adm_image_contanier">
                  <IonImg className="image_design_deity" onClick={() => imagenew()} src={image}></IonImg>
                </div>
              )}
              {showModal && (
                <Lightbox
                  mainSrc={image}
                  onCloseRequest={() => setShowModal(false)}
                  onImageLoad={() => {
                    window.dispatchEvent(new Event('resize'));
                  }}
                />
              )}
            </>
            <IonRow className="row_bot">
              <IonCol className="adm_submit_btn_container">
                <div className={`${name.length === 0 || description.length === 0 || image.length === 0 ? 'disableCursor_div' : ''}`}>
                  <IonButton
                    className="admin_add_btn"
                    disabled={
                      name.length === 0 ||
                      description.length === 0 ||
                      image.length === 0
                    }
                    onClick={() => { onSubmit(); the_char(); }}
                    type="submit"
                  >
                    <span className="button_padding">
                      Submit
                    </span>
                  </IonButton>
                </div>
              </IonCol>
              <IonCol className="adm_cancel_btn_container">
                <IonButton
                  className="admin_cencel_btn"
                  fill="outline"
                  onClick={Modalclose}
                >
                  <span className="button_padding">
                    Cancel
                  </span>
                </IonButton>
              </IonCol>
            </IonRow>
          </Modal.Body>
        </Modal>

        <div className="container_middle_table page-wrapper admin_background_color">
          <div className="data_table">

            <DataTable
              columns={columns}
              data={tableData}
              striped
              noDataComponent="Sorry ! No result found"
              pagination
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
              onRowClicked={(item: any) => clickRow(item)}
              style={{ width: "100%" }}
              subHeader
              subHeaderComponent={subHeaderComponent}
              customStyles={customTableStyle}
            />
          </div>
        </div>
      </IonContent>
    </IonPage >
  );
};
export default Deity;

function the_char() {
  throw new Error("Function not implemented.");
}
