const customTableStyle = {
      table: {
            style: {
                  border: "0px"
            },
      },
      tableWrapper: {
            style: {
                  border: "0px"
            },
      },
      header: {
            style: {
            },
      },
      headRow: {
            style: {
                  borderWidth: "0px",
            },
      },
      headCells: {
            style: {
                  fontWeight: "bold",
                  fontSize: "15px",
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  border: "none",
                 
                  justifyContent: "center",
                  textalign:"center"
            },
      },
      rows: {
            style: {
                  borderWidth: "0.5px #E5E3E3",
                  
                  // '&:not(:last-of-type)': {
                  //       borderWidth:"0.5px #E5E3E3",
                  // },
            }
      },
      cells: {
            style: {
                  fontSize: "16px",
                  // justifyContent: "center",
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  wordBreak: 'break-word',
                  border: "none",
                  // background: "#ffffff"
            }
      },
      pagination: {
            style: {
                  background: "#ffffff",
                  // borderTopWidth:"0",
                  borderWidth: "0.5px #E5E3E3",

            },
            pageButtonsStyle: {
                  backgroundColor: "var(--ion-color-primary)",
                  borderRadius: '0%',
                  padding: "0px",
                  margin: "5px",
                  height: "25px",
                  width: "25px",
                  fill: "#ffffff",
                  cursor: 'pointer',
                  '&:disabled': {
                        cursor: 'not-allowed',
                        fill: "#ffffff",
                  },
                  '&:hover:not(:disabled)': {
                        backgroundColor: "var(--ion-color-primary)",
                  },
                  '&:focus': {
                        outline: 'none',
                        backgroundColor: "var(--ion-color-primary)",
                  },
            }
      }
};

export default customTableStyle;