import React, { useEffect, useState, useRef } from "react";
import {
    IonButton,
    IonRow,
    IonCol,
    IonGrid,
    IonLabel,
    IonInput,
    IonImg,
    IonTextarea,
    IonIcon,
    IonSelect,
    IonSelectOption,
    useIonAlert,
    IonLoading,
    IonSlides,
    IonSlide,
    IonItem,
    IonContent,
    IonList,
    IonPopover,
    IonText,
    IonPage
} from "@ionic/react";
import "../TempleSearch.css";
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { Button, Modal } from 'react-bootstrap'
import UserService from "../../Services/user.service";
import { addCircle, ellipsisVerticalCircleOutline, removeCircle, arrowBack, closeCircle, addCircleOutline, bus, car, removeCircleOutline, train, trashOutline, trash } from "ionicons/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useHistory, useLocation } from "react-router";
import Admtemple from "./Admtemple";
import Foot from "../Foot";
import Select, { components } from 'react-select';
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";

interface Props {
    id: string;
    edit: boolean;
    admin: any;
    tempId: any;
    name: any;
    state: any;
    city: any;
    district: any;
    templeName: any;
}
const TempleAdmDet: React.FC = () => {

    const inputOfferArrtext = [
        {
            id: 1,
            value: ""
        }
    ];
    const inputOfferArrtext1 = [
        {

            videoLink: "",
            videoName: "",
            type: 1
        }
    ];
    const inputOfferArrtext2 = [
        {

            videoLink: "",
            videoName: "",
            type: 2
        }
    ];
    const min = 0
    const max = 100000
    const [facilities, setFacilities]: any = useState({});
    const [templesdto, setTemplesdto]: any = useState({})
    const [aerial, setAerial]: any = useState("")
    const [morning, setMorning] = useState("");
    const [evening, setEvening] = useState("");
    const [timerequ, setTimerequ] = useState("");
    const [closetime, setCloseTime] = useState("");
    const [entry, setEntry] = useState("");
    const [dressme, setDressme] = useState("");
    const [dresswo, setDresswo] = useState("");
    const [shopIn, setShopIn]: any = useState([]);
    const [AccIn, setAccIn]: any = useState([]);
    const [Offerings, setOfferings]: any = useState([]);
    const [festival, setFestival]: any = useState([]);
    const [poojarow, setPoojarow]: any = useState([]);
    const [shops, setShops] = useState([]);
    const [video, setVideo]: any = useState("");
    const [offerSInn, setofferSInn] = useState(inputOfferArrtext);
    const [usertoken] = useState(localStorage.getItem("token"));
    const [image, setImage]: any = useState([]);
    const [remChsImg, setRemChsImg]: any = useState([])
    const [images, setImages]: any = useState([]);
    const [serverImage, setServerImage]: any = useState([]);
    const [deity, setDeity]: any = useState([]);
    const [videolink, setVideolink]: any = useState([])
    const [videolivelink, setVideoLivelink]: any = useState([])
    const [templeVideoLinksDto, setTempleVideoLinksDto]: any = useState([])
    const [lat, setLat]: any = useState();
    const [long, setLong]: any = useState();
    const [shipAddress, setShipAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [alert] = useIonAlert();
    const [showLoading, setShowLoading] = useState(false);
    const [poojarowlength, setPoojarowlength]: any = useState()
    const [flag, setFlag] = useState(false)
    const [largeImg, setLargeImg]: any = useState("")
    const [slides, setSlides]: any = useState([])
    const [indexOfImages, setIndexOfImages]: any = useState("");
    const [showModal, setShowModal] = useState(false);
    const [templeName, setTempleName] = useState("");
    const mySlides: any = useRef(null);
    const [modalopen, setModalopen] = useState(false);
    const [deleteImg, setDeleteImg]: any = useState([]);
   
    const [formErrors, setFormErrors]: any = useState({
    });
    const videoLinkRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/i;

    const googleMapsLinkRegex = /^(https?:\/\/)?(www\.)?(google\.com\/(?:maps\/)?)(.*)/i;
    const googleMapsLinkRegex1 = /^(https?:\/\/)?(www\.)?(google\.co\.in\/(?:maps\/)?)(.*)/i;

    const [zoomLevel, setZoomLevel]: any = useState(15);
    var loadState = false;

    const [index1, setIndex1] = useState(0);

    const inputRef = useRef<any>(null);
    const inputRef1 = useRef<any>(null);

    const shopInputRef = useRef<any>(null);
    const accessibilityInputRef = useRef<any>(null);
    const poojaListInputRef = useRef<any>(null);
    const videoInputRef = useRef<any>(null);
    const liveVideoInputRef = useRef<any>(null);

    const slideOpts = {
        initialSlide: 0,
        speed: 400
    };
    const handleSlideChange = async () => {
        const swiper = await mySlides.current.getSwiper();
        console.log("Slide Index", swiper.activeIndex);
    };
    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const mapOptions = {
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
        minZoom: 4
    };

    var center = {
        lat: lat,
        lng: long,
    };

    const [stateList, setStateList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [stateView, setStateView] = useState("");

    const [comment, setComment] = useState(false);
    // const [stateDrop, setStateDrop] = useState("");

    const [stateOption, setStateOption] = useState<{ value: string; label: string } | null>(null);
    const [districtOption, setDistrictOption] = useState<{ value: string; label: string } | null>(null);

    const [districtView, setDistrictView] = useState("");
    const distDrop = useRef(districtView)
    const stateDrop = useRef(stateView)
    const location = useLocation()
    const [getState]: any = useState(location.state)

    const [tempId, setTempId] = useState("")
    const [adminBack, setAdminBack]: any = useState()
    const [idBack, setIdBack] = useState("")
    const [nameBack, setNameBack] = useState("")
    const [propState, setPropState] = useState("")
    const [propCity, setPropCity] = useState("")
    const [propDistrict, setPropDistrict] = useState("")
    const [tempName, setTempName] = useState("")
    const [screenwidth, setScreenwidth] = useState(window.innerWidth)

    useEffect(() => {
        if (!loadState) {
            loadState = true;
            getStates()
        }
        console.log(getState, "propsss")
        setScreenwidth(window.innerWidth)
        if (getState !== undefined) {
            setTempId(getState.tempId.current)
            setAdminBack(getState.admin);
            setIdBack(getState.id)
            setNameBack(getState.name.current);
            setPropState(getState.state.current);
            setPropDistrict(getState.district.current);
            if (!getState.city.current) {
                setPropCity("")
            }
            else {
                setPropCity(getState.city.current)
            }
            if (!getState.templeName.current) {
                setTempName("")
            }
            else {
                setTempName(getState.templeName.current)
            }
        }
        else {
            history.push("/admtemple")
            //window.location.reload();
        }
        //Load()
    }, [])

    const getStates = () => {
        setShowLoading(true)
        UserService.getAllstates()
            .then((response: any) => {
                console.log(response)
                if (response.status === 200) {
                    setStateList(response.data)
                    //getDistricts(state)
                    Load()
                }
                else {
                    setStateList([])
                    Load()
                }
                setShowLoading(false)
            })
            .catch((error: any) => {
                console.log(error)
                setShowLoading(false)
            })
    }


    const Load = () => {
        setShowLoading(true);
        UserService.Edittemple(getState.id).then((response) => {
            console.log(response.templesDto.district, "kmk")
            console.log(response, "id")
            console.log(response.templesDto.tempLong, "long")
            const long = parseFloat(response.templesDto.tempLong)
            setLong(long);
            const lat = parseFloat(response.templesDto.tempLat)
            console.log(response.templesDto.tempLat, "lat")
            setLat(lat);
            setShowLoading(false);
            setStateView((response.templesDto.state).toUpperCase());
            const state = (response.templesDto.state).toUpperCase();
            setStateOption({ value: state, label: state })

            //setDistrictView((response.templesDto.district).toUpperCase());
            distDrop.current = (response.templesDto.district).toUpperCase();

            setDistrictOption({ value: distDrop.current, label: distDrop.current })

            LoadDistrict(state)
            if (response.templesDto.inactiveComment != null || response.templesDto.inactiveComment != "") {
                setComment(true)
            }
            else {
                setComment(false)
            }
            setFacilities(response.facilitiesDto)
            setTemplesdto(response.templesDto)
            console.log(response.templesDto.state, "state")
            setTempleName(response.templesDto.name)
            setAerial(response.templesDto.aerialView)
            setFestival(response.festivalsDto)
            if (response.poojaOfferingsDto.length === 0) {
                const rowsInput = { "name": "", "description": "", "price": "", 'offeringTime': '', "deity": { "id": "" } }
                setPoojarow([rowsInput])
            }
            else {
                setPoojarow(response.poojaOfferingsDto)
            }
            var lengthpooja = parseInt(response.poojaOfferingsDto.length, 10)
            setPoojarowlength(lengthpooja)
            setOfferings(response.offeringsAndSignificanceDto)
            setImage(response.templeImgsUrl)
            setServerImage(response.templeImgsUrl)
            var string = response.templesDto.scheduleAndTimings.split('and')
            setMorning(string[0].replace('Morning Darshan:', ''))
            setEvening(string[1].replace('Evening Darshan:', ''))
            setTimerequ(string[2].replace('Time Required:', ''))
            setEntry(string[3])
            setDressme(string[4].replace('Dress Code:', ''))
            console.log(string[4].replace('Dress Code:', ''))
            setDresswo(string[5].replace('Dress Code:', ''))
            if (string[6]) {
                setCloseTime(string[6].replace('Closing Time:', ''))
            } else {
                setCloseTime("")
            }
            console.log(response.templesDto.shippingAddress.split(","), "kjhhkj")

            var ship = response.templesDto.shippingAddress.split(",")

            var arrLlen = ship.length - 1;
            var address: any = "";
            for (var a = 0; a < arrLlen; a++) {
                if (a == 0) {
                    address = address + ship[a];

                } else {
                    address = address + "," + ship[a];
                }
            }
            console.log(address, "addrr")
            setShipAddress(address)
            setPincode(ship[arrLlen])

            if (response.facilitiesDto.shops.length === 0) {
                console.log(response.facilitiesDto.shops, "if")
                setShopIn([...shopIn, ""])
            }
            else {
                var arr: any = [];
                console.log(response.facilitiesDto.shops, "else")
                response.facilitiesDto.shops.map((item: any) => {
                    console.log(item.split('||'))
                    arr = item.split('||');
                });
                var array_filter = arr.filter((res: any) => res != "")
                setShopIn(array_filter);
            }
            console.log("video link")
            if (response.templeVideoLinksDto.filter((res: any) => res.type != 2).length === 0) {
                setVideolink(inputOfferArrtext1)
            } else {
                setVideolink(response.templeVideoLinksDto.filter((res: any) => res.type != 2 && res.type != null))
            }
            if (response.templeVideoLinksDto.filter((res: any) => res.type != 1).length === 0) {
                setVideoLivelink(inputOfferArrtext2)
            } else {
                setVideoLivelink(response.templeVideoLinksDto.filter((res: any) => res.type != 1 && res.type != null))
            }
            console.log(videolink, "vl")
            // if(videolivelink.length==0){
            //     setVideoLivelink([...videolivelink, { type: 2, videoLink: "", videoName: "" }])
            // }
            // if(videolink.length==0){
            //     setVideolink([...videolink, { type: 1, videoLink: "", videoName: "" }])
            // }
        })
            .catch((error) => {
                setShowLoading(false);
            });

        // LoadDistrict(districtView)
        setShowLoading(false)

        UserService.ShowdeitTemp(usertoken).then((response: any) => {
            console.log(response);
            console.log(response.data)
            setDeity(response.data)
            // console.log("Deity=>", deity)
        })
            .catch((error: any) => {
                console.log(error);
            });
    }

    const LoadDistrict = (e: any) => {
        UserService.getAllDistricts([e])
            .then((response: any) => {
                console.log(response)
                if (response.status === 200) {
                    setDistrictList(response.data)
                }
                else {
                    setDistrictList([])
                }
                setShowLoading(false)
            })
            .catch((error: any) => {
                console.log(error)
                setShowLoading(false)
            })
    }

    const imageinputref: any = useRef(null)

    const handleButtonClick = () => {
        if (imageinputref.current) {
            imageinputref.current.click();
        }
    };

    const onchangeImage = (e: any) => {
        const supportedFormats = ["jpg", "jpeg", "png"];
        var file: any = e.target.files;
        const unsupportedFiles: any = [];
        if (image.length < 10) {
            for (let i = 0; i < Math.min(file.length, (10 - image.length)); i++) {
                const splited: any = file[i].name.split(".")
                console.log(splited[1])
                const fileExtension = splited[splited.length - 1].toLowerCase();
                if (supportedFormats.includes(fileExtension)) {
                    const objectUrl = URL.createObjectURL(file[i]);
                    setImage((e: any) => [
                        ...e, objectUrl
                    ]);
                    setImages((e: any) => [
                        ...e, file[i]
                    ])
                } else {
                    unsupportedFiles.push(file[i].name);
                }
            }
        } else {
            alert("You have reached the maximum limit of 10 images.")
        }
        if (unsupportedFiles.length > 0) {
            const unsupportedFilesMessage = "Unsupported File Formats:\n" + unsupportedFiles.join(", ");
            alert(unsupportedFilesMessage + ". Supported formats are JPG, PNG, and JPEG.");
        }
    }

    // const removeInputFields6 = (ii: any, item: any) => {
    //     console.log(ii, videolivelink)
    //     const rows = [...videolivelink];
    //     if (rows.length >= 1) {
    //         rows.splice(ii, 1);
    //     }
    //     setVideoLivelink(rows);
    // }
    // const removeInputFields7 = (ii: any, item: any) => {
    //     console.log(ii, videolink)
    //     const rows = [...videolink];
    //     if (rows.length >= 1) {
    //         rows.splice(ii, 1);
    //     }
    //     setVideolink(rows);
    // }

    const addofferInput1 = (e: any) => {
        if (e === 0) {
            var valSet = isFoundvideo('');
            if (!valSet) {
                setVideolink([...videolink, { type: 1, videoLink: "", videoName: "" }])
                setTimeout(() => {
                    videoInputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }

        } else {
            var valSet = isFoundliveVideo('');
            if (!valSet) {
                setVideoLivelink([...videolivelink, { type: 2, videoLink: "", videoName: "" }])
                setTimeout(() => {
                    liveVideoInputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }
        }
    };

    const isFoundvideo = (val: any) => {
        return videolink.some((item: any) => (val === item.videoLink || val === item.videoName || val === item.type));
    }
    const isFoundliveVideo = (val: any) => {
        return videolivelink.some((item: any) => (val === item.videoLink || val === item.videoName || val === item.type));
    }

    const removeInputFields = (index: any) => {
        const rows = [...poojarow];
        if (rows.length >= 1) {
            rows.splice(index, 1);
        }
        setFormErrors({ ...formErrors, pooja: "" })
        setPoojarow(rows);
    }



    const addrow = (id: any) => {
        if (id === 0) {
            var valSet = isFoundPoojaList('');
            if (!valSet) {
                const rowsInput = { "name": "", "description": "", "price": "", 'offeringTime': '', "deity": { "id": "" } }
                setPoojarow([...poojarow, rowsInput])
                setTimeout(() => {
                    poojaListInputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }

        }
    }

    const isFoundPoojaList = (val: any) => {
        return poojarow.some((item: any) => (val === item.name || val === item.description || val === item.price || val === item.offeringTime || val === item.deity.id));
    }

    const handleChangerowPname = (index: any, e: any) => {
        if (e.target.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].name = e.target.value


    }
    const handleChangerowPtiming = (index: any, e: any) => {
        if (e.target.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].offeringTime = e.target.value


    }
    const handleChangerowPprice = (index: any, e: any) => {
        if (e.target.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].price = e.target.value

    }
    const restrictE = (e: any) => {
        if (e.key == "e") {
            e.preventDefault()
        }
    }
    const handleChangerowDesc = (index: any, e: any) => {
        if (e.target.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].description = e.target.value

    }
    const handleChangerowGod = (index: any, e: any) => {
        if (e.target.value !== "") {
            setFormErrors({ ...formErrors, pooja: "" })
        }
        poojarow[index].deity.id = e.target.value

    }


    const addInputFest = (e: any) => {
        if (e === 0) {
            console.log(festival);
            var valSet = isFoundFest('');
            if (!valSet) {
                setFestival([...festival, { description: '', name: '' }])
                setTimeout(() => {
                    inputRef1.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }
        }
    };

    const isFoundFest = (val: any) => {
        return festival.some((item: any) => (val === item.name || val === item.description));
    }

    const [feild, setFeild] = useState("")
    const [removeIndex, setRemoveIndex]: any = useState("")
    const [itemStore, setItemStore]: any = useState([])

    const [festivalId, setFestivalId]: any = useState([]);
    const [offeringId, setOfferingsId]: any = useState([]);
    const [videoLinkId, setVideoLinkId]: any = useState([]);
    const [liveVideoLinkId, setLivevideolinkid]: any = useState([])
    const [poojaListId, setPoojaListId]: any = useState([])

    const OpenRemovemodals = (index: any, fields: any, item: any) => {
        console.log(item, 'lm')
        if (fields === "festival") {
            if (item.mame != "" && item.description != "" || index != 0) {
                if (item.id != null || item.id != "") {
                    festivalId.push(item.id)
                }
                else {
                    setFestivalId([]);
                }
                setModalopen(!modalopen)
                setFeild(fields)
                setRemoveIndex(index)
            }
            else {
                alert({
                    header: 'Atmagram',
                    message: 'No Values to Delete !',
                    buttons: ["Ok"]
                })
            }
        }
        else if (fields === "offerings") {
            if (item.offeringName != "" && item.significance != "" || index != 0) {
                if (item.id != null || item.id != "") {
                    offeringId.push(item.id)
                }
                else {
                    setOfferingsId([]);
                }
                setModalopen(!modalopen)
                setFeild(fields)
                setRemoveIndex(index)
                setItemStore(item)
            }
            else {
                alert({
                    header: 'Atmagram',
                    message: 'No Values to Delete !',
                    buttons: ["Ok"]
                })
            }
        }
        else if (fields === "video") {
            if (item.videoLink != "" && item.videoName != "" || index != 0) {
                if (item.id != null || item.id != "") {
                    videoLinkId.push(item.id)
                }
                else {
                    setVideoLinkId([]);
                }
                setModalopen(!modalopen)
                setFeild(fields)
                setRemoveIndex(index)
            }
            else {
                alert({
                    header: 'Atmagram',
                    message: 'No Values to Delete !',
                    buttons: ["Ok"]
                })
            }
        }
        else if (fields === "live") {
            if (item.videoLink != "" && item.videoName != "" || index != 0) {
                if (item.id != null || item.id != "") {
                    liveVideoLinkId.push(item.id)
                }
                else {
                    setVideoLinkId([]);
                }
                setModalopen(!modalopen)
                setFeild(fields)
                setRemoveIndex(index)
            }
            else {
                alert({
                    header: 'Atmagram',
                    message: 'No Values to Delete !',
                    buttons: ["Ok"]
                })
            }
        }
        else if (fields === "shop") {
            if (item != "" || index != 0) {
                setModalopen(!modalopen)
                setFeild(fields)
                setRemoveIndex(index)
            }
            else {
                alert({
                    header: 'Atmagram',
                    message: 'No Values to Delete !',
                    buttons: ["Ok"]
                })
            }
        }
        else if (fields === "poojaList") {
            console.log(item, "list")
            if (item.name != "" && item.description != "" || index != 0) {
                if (item.id != null || item.id != "") {
                    poojaListId.push(item.id)
                }
                else {
                    setPoojaListId([]);
                }
                setModalopen(!modalopen)
                setFeild(fields)
                setRemoveIndex(index)
                setItemStore(item)
            }
            else {
                alert({
                    header: 'Atmagram',
                    message: 'No Values to Delete !',
                    buttons: ["Ok"]
                })
            }
        }
        else {
            setModalopen(!modalopen)
            setFeild(fields)
            setRemoveIndex(index)
        }

    }

    const festivalRemove = () => {
        const rows = [...festival];
        if (rows.length >= 1) {
            rows.splice(removeIndex, 1);
        }
        setFestival(rows);
    }

    const handleRemove = () => {
        console.log(feild)
        if (feild === "festival") {
            const rows = [...festival];
            if (rows.length >= 1) {
                rows.splice(removeIndex, 1);
            }
            setFestival(rows);
        } else if (feild === "shop") {
            const row = [...shopIn]
            if (row.length >= 1) {
                row.splice(removeIndex, 1);
            }
            setShopIn(row)
            setFacilities((prevState: any) => ({ ...prevState, shops: row }))
        }
        else if (feild === "Access") {
            const row = [...AccIn]
            if (row.length >= 1) {
                row.splice(removeIndex, 1);
            }
            setAccIn(row)
            setFacilities((prevState: any) => ({ ...prevState, accessibility: row }))
        }
        else if (feild === "offerings") {
            const rows = [...Offerings];
            if (rows.length >= 1) {
                rows.splice(removeIndex, 1);
            }
            setOfferings(rows);
        }
        else if (feild === "video") {
            const rows = [...videolink];
            if (rows.length >= 1) {
                rows.splice(removeIndex, 1);
            }
            setVideolink(rows);
        }
        else if (feild === "live") {
            console.log(index, videolink)
            const rows = [...videolivelink];
            if (rows.length >= 1) {
                rows.splice(removeIndex, 1);
            }
            setVideoLivelink(rows);
        }
        else if (feild === "poojaList") {
            const rows = [...poojarow];
            if (rows.length === 1) {
                const rowsInput = { "name": "", "description": "", "price": "", 'offeringTime': '', "deity": { "id": "" } }
                setPoojarow([rowsInput]);
                console.log(poojarow)
            }
            else if (rows.length > 1) {
                rows.splice(removeIndex, 1);
                setPoojarow(rows);
            }
        }
        setModalopen(!modalopen)
    }

    const handleRemoveCancel = () => {
        setModalopen(false);
        setFestivalId([]);
        setOfferingsId([]);
        setVideoLinkId([]);
    }

    // const removeInputFields4 = (index: any) => {
    //     const rows = [...festival];
    //     if (rows.length >= 1) {
    //         rows.splice(index, 1);
    //     }
    //     setFestival(rows);
    // }
    const handleChangeName = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        festival[i].name = e.detail.value;
    }

    const handleChangeSign = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(festival)
        console.log(e.detail.value)
        festival[i].description = e.detail.value;
    }
    const handlevdelink = (e: any, i: any, link: any) => {
        e.preventDefault();
        if (link === 1) {
            console.log(e.detail.value)
            videolink[i].videoLink = e.detail.value;
        } else {
            console.log(e.detail.value)
            videolivelink[i].videoLink = e.detail.value;
        }

    }
    const openMap = () => {
        console.log("lkn")
        window.open('https://www.google.com/maps/place/' + lat + ',' + long + '/@' + lat + ',' + long + ',')
    }
    const handlevdename = (e: any, i: any, link: any) => {
        e.preventDefault();
        if (link === 1) {
            console.log(e.detail.value)
            videolink[i].videoName = e.detail.value;
        } else {
            console.log(e.detail.value)
            videolivelink[i].videoName = e.detail.value;
        }
    }
    const addInputOfferings = (e: any) => {
        if (e === 1) {
            console.log(Offerings);
            var valSet = isFoundOffer('');
            if (!valSet) {
                setOfferings([...Offerings, { significance: "", offeringName: "" }])
                setTimeout(() => {
                    inputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }
        }
    };
    const preventdata = (e: any) => {
        e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
        e.target.addEventListener('keydown', function (u: any) {
            if (u.which === 38 || u.which === 40) {
                u.preventDefault();
            }
        })
    }

    const isFoundOffer = (val: any) => {
        return Offerings.some((item: any) => (val === item.offeringName || val === item.significance));
    }

    // const removeInputFields3 = (index: any) => {
    //     const rows = [...Offerings];
    //     if (rows.length >= 1) {
    //         rows.splice(index, 1);
    //     }
    //     setOfferings(rows);
    // }
    const offerhandleChangeSIn = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(Offerings)
        Offerings[i].significance = e.detail.value;

    }

    const offerhandleChange = (e: any, i: any) => {
        e.preventDefault();
        console.log(e.detail.value)
        console.log(Offerings)
        Offerings[i].offeringName = e.detail.value;

    }

    const handlechange = (e: any) => {
        setAerial(e.target.value)
        console.log(e.target.value)

        const regex = /@(\d+(\.\d+)?),(\d+(\.\d+)?),/;
        const match = e.target.value.match(regex);
        var latitude = match ? parseFloat(match[1]) : 0;
        var longitude = match ? parseFloat(match[3]) : 0;
        setLat(latitude);
        setLong(longitude);
        const urlParams = new URLSearchParams(e.target.value.split("?")[1]);
        const zoomLevel = urlParams.get("z");
        setZoomLevel(zoomLevel);
        // setHI(e.target.value)
        if (e.target.value !== "") {
            setFormErrors({ ...formErrors, aerial: '' })
        }
    }

    const park = (e: any) => {
        if (e.target.value !== "") {
            setFormErrors({ ...formErrors, parkingLot: "" })
        }
        const value = Math.max(min, Math.min(max, Number(e.target.value)))
        setFacilities((prevState: any) => ({ ...prevState, parkingLot: value }))
    }
    const validation = (e: any) => {
        var shop;
        for (let i = 0; i < shopIn.length; i++) {
            if (shopIn[i] == "") {
                shop = ""
            }
        }
        var poojaname;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].name == "") {
                poojaname = ""
                if(poojarow[i].price != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].description != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter name for pooja list" })
                    alert("Please enter name for pooja list")
                    return;
                }
            }
        }
        var poojaprice;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].price == "") {
                poojaprice = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].description != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter price for pooja list" })
                    alert("Please enter price for pooja list")
                    return;
                }
            }
        }
        var poojades;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].description == "") {
                poojades = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter description for pooja list" })
                    alert("Please enter description for pooja list")
                    return;
                }
            }
        }
        var poojatime;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].offeringTime == "") {
                poojatime = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].description != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter time for pooja list" })
                    alert("Please enter time for pooja list")
                    return;
                }
            }
        }
        var poojadeity;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].deity.id == "") {
                poojadeity = ""
                if(poojarow[i].name != "" || poojarow[i].offeringTime != "" || poojarow[i].description != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter the pooja deity" })
                    alert("Please choose deity for pooja list")
                    return;
                }
            }
        }
        if (e == 1) {
            if (templesdto.aboutAndHistory == "") {
                setFormErrors({ ...formErrors, abtAndHis: "Please enter the about and history" })
            }
        } else if (e == 2) {
            if (templesdto.name == "") {
                setFormErrors({ ...formErrors, templeName: "Please enter the temple name" })
            }
        } else if (e == 3) {
            if (templesdto.villageortown == "") {
                setFormErrors({ ...formErrors, village: "Please enter the village" })
            }
        } else if (e == 4) {
            if (templesdto.country == "") {
                setFormErrors({ ...formErrors, country: "Please enter the country" })
            }
        }
        else if (e == 5) {
            console.log(templesdto.state)
            if (templesdto.state == "") {
                setFormErrors({ ...formErrors, state: "Please select a state" })
            }
        }
        else if (e == 6) {
            if (templesdto.district == "") {
                setFormErrors({ ...formErrors, district: "Please select a district" })
            }
        }
        else if (e == 7) {
            if (shipAddress == "") {
                setFormErrors({ ...formErrors, shipAddress: "Please enter the shipping address" })
            }
        }
        else if (e == 8) {
            if (pincode == "") {
                setFormErrors({ ...formErrors, pincode: "Please enter the pincode" })
            }
        }
        else if (e == 9) {
            if (facilities.parkingLot.length == 0) {
                setFormErrors({ ...formErrors, parkingLot: "Please enter the parking lot" })
            }
        }
        else if (e == 10) {
            if (facilities.security == "") {
                setFormErrors({ ...formErrors, security: "Please enter the security" })
            }
        }
        else if (e == 11) {
            if (facilities.restrooms == "") {
                setFormErrors({ ...formErrors, restRooms: "Please select the restroom field" })
            }
        }
        else if (e == 12) {
            if (facilities.cloak == "") {
                setFormErrors({ ...formErrors, cloak: "Please enter the cloak counter" })
            }
        }
        else if (e == 13) {
            if (facilities.provisions == "") {
                setFormErrors({ ...formErrors, poojapro: "Please select the pooja provisions" })
            }
        }
        else if (e == 14) {
            if (facilities.cab == "") {
                setFormErrors({ ...formErrors, cab: 'Please select Cab / Auto availability' })
            }
        }
        else if (e == 15) {
            if (facilities.auditorium == "") {
                setFormErrors({ ...formErrors, auditorium: 'Please enter the auditorium' })
            }
        }
        else if (e == 16) {
            if (shop == "") {
                setFormErrors({ ...formErrors, shops: 'Please enter the shops' })
            }
        }
        else if (e == 17) {
            if (facilities.car == "") {
                setFormErrors({ ...formErrors, car: 'Please enter the car field' })
            }
        }
        else if (e == 18) {
            if (facilities.bus == "") {
                setFormErrors({ ...formErrors, bus: 'Please enter the bus field' })
            }
        }
        else if (e == 19) {
            if (facilities.train == "") {
                setFormErrors({ ...formErrors, train: 'Please enter the train field' })
            }
        }
        else if (e == 20) {
            if (facilities.flight == "") {
                setFormErrors({ ...formErrors, flight: 'Please enter the flight field' })
            }
        }
        // else if (e == 21) {
        //     if (poojaname == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja name' })
        //     }
        // }
        // else if (e == 22) {
        //     if (poojaprice == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja price' })
        //     }
        // }
        // else if (e == 23) {
        //     if (poojades == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja description' })
        //     }
        // }
        // else if (e == 24) {
        //     if (poojatime == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja timings' })
        //     }
        // }
        // else if (e == 25) {
        //     if (poojadeity == "") {
        //         setFormErrors({ ...formErrors, pooja: 'Please enter the pooja deity' })
        //     }
        // }
        else if (e == 26) {
            if (morning == "") {
                setFormErrors({ ...formErrors, mrng: 'Please enter the morning darshan' })
            }
        }
        else if (e == 27) {
            if (evening == "") {
                setFormErrors({ ...formErrors, evng: 'Please enter the evening darshan' })
            }
        }
        else if (e == 28) {
            if (timerequ == "") {
                setFormErrors({ ...formErrors, time: 'Please enter the darshan time' })
            }
        }
        else if (e == 29) {
            if (closetime == "") {
                setFormErrors({ ...formErrors, ctime: 'Please enter the darshan closing time' })
            }
        }
        else if (e == 30) {
            if (entry == "") {
                setFormErrors({ ...formErrors, fee: 'Please Select the fee details' })
            }
        }
        else if (e == 31) {
            if (dressme == "") {
                setFormErrors({ ...formErrors, dressM: 'Please enter the dress code for men' })
            }
        }
        else if (e == 32) {
            if (dresswo == "") {
                setFormErrors({ ...formErrors, dressW: 'Please enter the dress code for women' })
            }
        }
        else if (e == 33) {
            if (aerial == "") {
                setFormErrors({ ...formErrors, aerial: 'Please Enter the temple address map link' })
            }
            else if (!googleMapsLinkRegex.test(aerial) && !googleMapsLinkRegex1.test(aerial)) {
                setFormErrors({ ...formErrors, aerial: 'Please Enter a valid map link starts with  www.google.com/maps or www.google.co.in/maps' })
            }

        }
    }
    const historyChange = (e: any) => {
        if (e.detail.value != "") {
            setFormErrors({ ...formErrors, abtAndHis: "" })
        }
        setTemplesdto((prevState: any) => ({ ...prevState, aboutAndHistory: e.detail.value! }))
    }
    const templeChange = (e: any) => {
        if (e.detail.value != "") {
            setFormErrors({ ...formErrors, templeName: "" })
        }
        setTemplesdto((prevState: any) => ({ ...prevState, name: e.detail.value! }))
    }
    const villageChange = (e: any) => {
        if (e.detail.value != "") {
            setFormErrors({ ...formErrors, village: "" })
        }
        setTemplesdto((prevState: any) => ({ ...prevState, villageorTown: e.detail.value! }))
    }
    const countryChange = (e: any) => {
        if (e.detail.value != "") {
            setFormErrors({ ...formErrors, country: "" })
        }
        setTemplesdto((prevState: any) => ({ ...prevState, country: e.detail.value! }))
    }
    const shipAddressChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, shipAddress: "" })

        }
        setShipAddress(e.detail.value!)
    }
    const pincodeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, pincode: "" })
        }
        setPincode(e.detail.value!)
    }

    const securityChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, security: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, security: e.detail.value! }))
    }
    const restroomChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, restRooms: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, restRooms: e.detail.value! }))
    }
    const cloakChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, cloak: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, cloakCounters: e.detail.value! }))
    }
    const provisionChange = (e: any) => {
        console.log(e.detail.value)
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, poojapro: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, poojaProvisions: e.detail.value! }))
    }
    const cabChange = (e: any) => {
        console.log(e.detail.value)
        if (e.detail.value !== undefined || e.detail.value !== null) {
            setFormErrors({ ...formErrors, cab: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, cabAvailablity: e.detail.value! }))
    }
    const audChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, auditorium: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, auditorium: e.detail.value! }))
    }
    const carChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, car: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, car: e.detail.value! }))
    }
    const busChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, bus: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, bus: e.detail.value! }))
    }
    const trainChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, train: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, train: e.detail.value! }))
    }
    const flightChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, flight: "" })
        }
        setFacilities((prevState: any) => ({ ...prevState, flight: e.detail.value! }))
    }
    const mrngChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, mrng: "" })
        }
        setMorning(e.detail.value!)
    }
    const evngChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, evng: "" })
        }
        setEvening(e.detail.value!)
    }
    const timeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, time: "" })
        }
        setTimerequ(e.detail.value!)
    }
    const ctimeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, ctime: "" })
        }
        setCloseTime(e.detail.value!)
    }
    const feeChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, fee: "" })
        }
        setEntry(e.detail.value!)
    }
    const mChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, dressM: "" })
        }
        setDressme(e.detail.value!)
    }
    const wChange = (e: any) => {
        if (e.detail.value !== "") {
            setFormErrors({ ...formErrors, dressW: "" })
        }
        setDresswo(e.detail.value!)
    }

    const onsubmit = () => {
        const errors: any = {}
        var shop;
        for (let i = 0; i < shopIn.length; i++) {
            if (shopIn[i] == "") {
                shop = ""
            }
        }
        var acc;
        for (let i = 0; i < AccIn.length; i++) {
            if (AccIn[i] == "") {
                acc = ""
            }
        }
        var poojaname;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].name == "") {
                poojaname = ""
                if(poojarow[i].price != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].description != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter name for pooja list" })
                    alert("Please enter name for pooja list")
                    return;
                }
            }
        }
        var poojaprice;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].price == "") {
                poojaprice = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].description != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter price for pooja list" })
                    alert("Please enter price for pooja list")
                    return;
                }
            }
        }
        var poojades;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].description == "") {
                poojades = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].offeringTime != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter description for pooja list" })
                    alert("Please enter description for pooja list")
                    return;
                }
            }
        }
        var poojatime;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].offeringTime == "") {
                poojatime = ""
                if(poojarow[i].name != "" || poojarow[i].deity.id != "" || poojarow[i].description != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter time for pooja list" })
                    alert("Please enter time for pooja list")
                    return;
                }
            }
        }
        var poojadeity;
        for (let i = 0; i < poojarow.length; i++) {
            if (poojarow[i].deity.id == "") {
                poojadeity = ""
                if(poojarow[i].name != "" || poojarow[i].offeringTime != "" || poojarow[i].description != "" || poojarow[i].price != ""){
                    setFormErrors({ ...formErrors, pooja: "Please enter the pooja deity" })
                    alert("Please choose deity for pooja list")
                    return;
                }
            }
        }
        console.log(shipAddress, pincode, "shipAddress")
        var mrng = "Morning Darshan:"
        var Timings = mrng.concat(morning, "and", "Evening Darshan:", evening, "and", "Time Required:", timerequ, "and", entry, "and", "Dress Code:", dressme, "and", "Dress Code:", dresswo, "and", "Closing Time:", closetime)
        templesdto.scheduleAndTimings = Timings;
        templesdto.aerialView = aerial;
        templesdto.tempLat = lat;
        templesdto.tempLong = long;
        templesdto.shippingAddress = shipAddress + "," + pincode
        // console.log(facilities, "facilities")
        // console.log(Offerings, "offeringsAndSignificanceDto")
        // console.log(festival, "festivalsDto");
        console.log(templesdto, "templesDto");
        // console.log(poojarow, "poojarow");

        console.log(videolink);
        console.log(videolivelink);
        setTempleVideoLinksDto([])
        for (let i = 0; i < videolink.length; i++) {
            templeVideoLinksDto.push(videolink[i])
            if (videolink[i].videoLink != "" && videolink[i].videoLink != null && videolink[i].videoLink != undefined) {
                if (!videoLinkRegex.test(videolink[i].videoLink)) {
                    errors.videoLink = 'Please enter valid video link'
                }
            }
        }

        for (let i = 0; i < videolivelink.length; i++) {
            templeVideoLinksDto.push(videolivelink[i])
            if (videolivelink[i].videoLink != "" && videolivelink[i].videoLink != null && videolivelink[i].videoLink != undefined) {
                if (!videoLinkRegex.test(videolivelink[i].videoLink)) {
                    errors.videoLinkLive = 'Please enter valid live video link'
                }
            }
        }
        console.log(templeVideoLinksDto)
        console.log("askj", templesdto.aboutAndHistor)

        var poojaList = [];

        const allEmpty = poojarow.every((obj: any) => {
            const isEmpty = Object.values(obj).every(value => {
                if (typeof value === 'object' && value !== null) {
                    return Object.values(value).every(innerValue => innerValue === '');
                }
                return value === '' || value === null || value === undefined;
            });

            return isEmpty;
        });
        const atLeastOneNotEmpty = poojarow.some((obj: any) => {
            const atLeastOneNotEmpty = Object.values(obj).some(value => {
                if (typeof value === 'object' && value !== null) {
                    return Object.values(value).every(innerValue => innerValue !== '');
                }
                return value !== '' || value !== null || value !== undefined;
            });

            return atLeastOneNotEmpty;
        });
        const allNotEmpty = poojarow.every((obj: any) => Object.values(obj).every(value => value !== ''));

        if (templesdto.aboutAndHistory == "") {
            errors.abtAndHis = "Please enter the about and history"
        }
        if (templesdto.name == "") {
            errors.templeName = "Please enter the temple name"
        }
        if (templesdto.villageorTown == "") {
            errors.village = "Please enter the village"
        }
        if (templesdto.country == "") {
            errors.country = "Please enter the country"
        }
        if (templesdto.state == "") {
            errors.state = "Please select a state"
        }
        if (templesdto.district == "") {
            errors.district = "Please select a district"
        }
        if (shipAddress == "") {
            errors.shipAddress = "Please enter the shipping address"
        }
        if (pincode == "") {
            errors.pincode = "Please enter the pincode"
        }
        if (pincode.length !== 6) {
            errors.pincode = "Please enter valid pincode"
        }
        if (allEmpty) {
            poojaList = [];
        }
        else if (allNotEmpty) {
            poojaList = poojarow;
        }
        else if (atLeastOneNotEmpty) {
            if (poojaname == "") {
                errors.pooja = 'Please enter the pooja name'
            }
            else if (poojaprice == "") {
                errors.pooja = 'Please enter the pooja price'
            }
            else if (poojades == "") {
                errors.pooja = 'Please enter the pooja description'
            }
            else if (poojatime == "") {
                errors.pooja = 'Please enter the pooja timing'
            }
            else if (poojadeity == "") {
                errors.pooja = 'Please select the pooja deity'
            }
        }
        console.log(allEmpty, "all empty", atLeastOneNotEmpty, "any one empty", allNotEmpty, "all not empty", poojaList, poojarow)
        if (aerial === "") {
            errors.aerial = 'Please Enter the temple address map link'
        }
        else if ((!googleMapsLinkRegex.test(aerial)) && (!googleMapsLinkRegex1.test(aerial))) {
            setFormErrors({ ...formErrors, aerial: 'Please Enter a valid map link starts with  www.google.com/maps  or www.google.co.in/maps' })
        }
        if (morning == "") {
            errors.mrng = 'Please enter the morning darshan'
        }
        if (evening == "") {
            errors.evng = 'Please enter the evening darshan'
        }
        if (timerequ == "") {
            errors.time = 'Please enter the darshan time'
        }
        if (closetime == "") {
            errors.ctime = 'Please enter the darshan closing time'
        }
        if (entry == "") {
            errors.fee = 'Please Select the fee details'
        }
        if (dressme == "") {
            errors.dressM = 'Please enter the dress code for men'
        }
        if (dresswo == "") {
            errors.dressW = 'Please enter the dress code for women'
        }
        console.log("sdcsd", errors)
        setFormErrors(errors)

        console.log(poojaList)

        if (Object.keys(errors).length === 0) {
            UserService.updateTemple(usertoken, templesdto, facilities, Offerings, festival, images, poojaList, templeVideoLinksDto, deleteImg, festivalId, offeringId, videoLinkId, tempId, liveVideoLinkId, poojaListId)
                .then((response) => {
                    console.log(templeVideoLinksDto, poojaListId, "img", images)
                    if (response.data === true) {
                        alert({
                            header: "Atmagram",
                            message: "Temple Updated Successfully",
                            backdropDismiss: false,
                            buttons: [
                                {
                                    text: "ok",
                                    handler: () => {
                                        if (tempId != "" && tempId != undefined && tempId != null) {
                                            history.push({
                                                pathname: "/admtemple",
                                                state: { tempId: tempId, admin: 2, id: idBack, name: nameBack, state: propState, city: propCity, district: propDistrict, templeName: tempName }
                                            })
                                        }
                                        else {
                                            history.push("/tempadmin")
                                            //window.location.reload()
                                        }
                                    }
                                }
                            ]
                        })
                    } else {
                        alert("Temple Not Updated");
                    }
                })
                .catch((error) => {
                    alert(error)
                })
        } else {
            alert("Please enter all mandatory fields")
        }
    }

    const onCancel = () => {
        if (tempId != "" && tempId != undefined) {
            history.push({
                pathname: "admtemple",
                state: { tempId: tempId, admin: adminBack, id: idBack, name: nameBack, state: propState, city: propCity, district: propDistrict, templeName: tempName }
            })
        }
        else {
            //window.location.reload()
            history.push("/tempadmin")
        }
    }

    const addInput = (e: any) => {
        console.log(shopIn);
        if (e === 1) {
            var valSet = isFoundshop('');
            if (!valSet) {
                setShopIn([...shopIn, ""])
                setTimeout(() => {
                    shopInputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }
        }
        else {
            var valSet = isFoundAcc('');
            if (!valSet) {
                setAccIn([...AccIn, ""])
                setTimeout(() => {
                    accessibilityInputRef.current?.setFocus();
                }, 100)
                return;

            } else {
                alert("Enter details before adding a new row")
            }
        }
    };

    const isFoundshop = (val: any) => {
        return shopIn.some((item: any) => val === item);
    }
    const isFoundAcc = (val: any) => {
        return AccIn.some((item: any) => (val === item));
    }

    const handleChangeShop = (e: any, i: any) => {
        e.preventDefault();
        shopIn[i] = e.target.value;
        if (e.target.value) {
            setFormErrors({ ...formErrors, shops: "" })
        }
        var shops: any = []
        for (let i = 0; i < shopIn.length; i++) {
            console.log(shopIn[i])
            shops.push(shopIn[i])
        }
        console.log(shops, "jh")
        setFacilities((prevState: any) => ({ ...prevState, shops: shops }))
    };
    // const removeInputFields1 = (index: any) => {
    //     const row = [...shopIn]
    //     if (row.length >= 1) {
    //         row.splice(index, 1);
    //     }
    //     setShopIn(row)
    //     setFacilities((prevState: any) => ({ ...prevState, shops: row }))
    // }
    const handleChangeAccess = (e: any, i: any) => {
        e.preventDefault();
        AccIn[i] = e.target.value;
        var shops: any = []
        for (let i = 0; i < AccIn.length; i++) {
            console.log(AccIn[i])
            shops.push(AccIn[i])

        }
        console.log(shops, "jh")
        setFacilities((prevState: any) => ({ ...prevState, accessibility: shops }))

    };
    // const removeInputFields2 = (index: any) => {
    //     const row = [...AccIn]
    //     if (row.length >= 1) {
    //         row.splice(index, 1);
    //     }
    //     setAccIn(row)
    //     setFacilities((prevState: any) => ({ ...prevState, accessibility: row }))
    // }


    const [index, setIndex]: any = useState(0);
    // const content = [screens[index]];
    const [btn, setBtn] = useState(true);
    const getBtnId = (e: any) => {
        console.log(e.target.id)
        setIndex(e.target.id)
    };
    const openModalAndSetIndex = (index: any) => {
        setIndexOfImages(index);
        setShowModal(true);
    };
    const show1 = (e: any, i: any) => {
        setSlides([])
        setSlides([e])
        for (let i = 0; i < image.length; i++) {
            if (image[i] !== e) {
                setSlides((ee: any) => {
                    return [
                        ...ee, image[i]
                    ]
                })
            }
        }
        setLargeImg(i)
        setFlag(true)
    }
    const onBtnClicked: any = async (direction: string) => {
        const swiper = await mySlides.current.getSwiper();
        if (direction === "next") {
            swiper.slideNext();
        } else if (direction === "prev") {
            swiper.slidePrev();
        }
    };

    const history = useHistory();

    const back = () => {
        window.location.reload()
    }

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const DeleteImage = (item: any) => {
        console.log(item, remChsImg)
        const itemExistsInImages = serverImage.includes(item);
        console.log("lkkn", serverImage.includes(item))

        alert({
            header: 'Atmagram',
            message: 'Do you want to Delete this Image ?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                },
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: () => {
                        if (itemExistsInImages) {
                            let filteredArr = image.filter((el: any) => el !== item);
                            setImage(filteredArr);
                            deleteImg.push(item);
                        }
                        else {
                            let filteredArr = image.filter((el: any) => el != item);
                            setImage(filteredArr);
                            const ind = remChsImg.indexOf(item)
                            const arr = [...images]
                            arr.splice(ind, 1)
                            setImages(arr)
                            const arr1 = [...remChsImg]
                            arr1.splice(ind, 1)
                            setRemChsImg(arr1)
                        }

                        if (fileInputRef.current) {
                            (fileInputRef.current as HTMLInputElement).value = '';
                        }
                    },
                },
            ]
        })
        // console.log(image.indexOf(item))
        // var indexx = image.indexOf(item)
        // if (indexx > -1) {
        // var img = image.splice(index, 1);
        // console.log(img, "km")
        // }
        // console.log(item,"km")
        // image.slice(item)
    }

    const Back = () => {
        if (tempId != "") {
            setIndex1(1)
        }
        else {
            window.location.reload()
        }
    }

    const handleStateChange = (e: any) => {
        setStateOption(e)
        if (e === null) {
            console.log(e)
            setTemplesdto((prevState: any) => ({ ...prevState, state: "" }))
        } else {
            setTemplesdto((prevState: any) => ({ ...prevState, state: e.value! }))
            setFormErrors({ ...formErrors, state: "" })
        }
        setDistrictOption(null)
        setTemplesdto((prevState: any) => ({ ...prevState, district: "" }));
        setDistrictList([])
        setStateView(e.value)
        getDistricts(e.value)
    }

    const getDistricts = (e: any) => {
        setShowLoading(true);
        setTemplesdto((prevState: any) => ({ ...prevState, district: "" }));
        setDistrictList([])
        UserService.getAllDistricts([e])
            .then((response: any) => {
                console.log(response)
                if (response.status === 200) {
                    setDistrictList(response.data);
                    setDistrictView(distDrop.current)
                    //setStateDrop(e)

                }
                else {
                    setDistrictList([])
                }
                setShowLoading(false)
            })
            .catch((error: any) => {
                console.log(error)
                setShowLoading(false)
            })
    }

    const stateLiistOptions = stateList.map((e: any, index: any) => ({
        value: e,
        label: e
    }));

    const districtListOptions = districtList.map((e: any, index: any) => ({
        value: e,
        label: e
    }));

    const handleDistrictChange = (e: any) => {
        if (e === null) {
            setTemplesdto((prevState: any) => ({ ...prevState, district: "" }))
        } else {
            setTemplesdto((prevState: any) => ({ ...prevState, district: e.value! }))
            setFormErrors({ ...formErrors, district: "" })

        }
        setDistrictOption(e)
        setDistrictView(e.value)

    }

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };


    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Edit Temple"} />
            <Sidebar />
            <IonContent style={{ width: "100%" }}>
                {/* <div style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}>
                <IonText style={{ fontSize: "20px", fontWeight: "bold" }}>{templeName}</IonText>
            </div> */}
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                // onDidDismiss={() => setShowLoading(false)}
                // duration={5000}
                />
                {index1 === 0 ?
                    <div className="container_middles_layout page-wrapper admin_background_color temple_padding">
                        <div className="menu_pop temple_menu_align">
                            <IonIcon
                                className="menu_pop"
                                src={ellipsisVerticalCircleOutline}
                                size="large"
                                style={{ cursor: "pointer", textAlign: "right" }}
                                color="primary"
                                id="menu_content"
                            ></IonIcon>
                            <IonPopover
                                trigger="menu_content"
                                dismissOnSelect={true}
                                show-backdrop={false}
                            >
                                <IonContent style={{ background: "white" }} scrollEvents={true}>
                                    <IonList className='templeTabBtnMainCls'>
                                        <div className={index == 0 ? "selected-btn" : "menu-btn"} id="0" onClick={getBtnId}>
                                            <IonLabel id="0" onClick={getBtnId} >
                                                About
                                            </IonLabel>
                                        </div>
                                        <div className={index == 1 ? "selected-btn" : "menu-btn"} id="1" onClick={getBtnId}>
                                            <IonLabel id="1" onClick={getBtnId}>
                                                Facilities
                                            </IonLabel>
                                        </div>
                                        <div className={index == 2 ? "selected-btn" : "menu-btn"} id="2" onClick={getBtnId}>
                                            <IonLabel id="2" onClick={getBtnId}>
                                                Offerings & Significance
                                            </IonLabel>
                                        </div>
                                        <div className={index == 3 ? "selected-btn" : "menu-btn"} id="3" onClick={getBtnId}>
                                            <IonLabel id="3" onClick={getBtnId}>
                                                Festivals
                                            </IonLabel>
                                        </div>
                                        <div className={index == 4 ? "selected-btn" : "menu-btn"} id="4" onClick={getBtnId}>
                                            <IonLabel id="4" onClick={getBtnId}>
                                                Pooja List
                                            </IonLabel>
                                        </div>
                                        <div className={index == 6 ? "selected-btn" : "menu-btn"} id="6" onClick={getBtnId} >
                                            <IonLabel id="6" onClick={getBtnId}>
                                                Schedule & Timings
                                            </IonLabel>
                                        </div>
                                        <div className={index == 7 ? "selected-btn" : "menu-btn"} id="7" onClick={getBtnId} style={{ borderBottom: "none" }}>
                                            <IonLabel id="7" onClick={getBtnId}>
                                                Gallery
                                            </IonLabel>
                                        </div>
                                    </IonList>
                                </IonContent>
                            </IonPopover>
                        </div>
                        <IonRow className="temple_menu_container">
                            <div>
                                <IonText tabIndex={-1} id="0" onClick={getBtnId} className={index == 0 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    About & History
                                </IonText>
                            </div>
                            <div>
                                <IonText id="1" onClick={getBtnId} className={index == 1 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Facilities
                                </IonText>
                            </div>
                            <div>
                                <IonText id="2" onClick={getBtnId} className={index == 2 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Offerings & Significance
                                </IonText>
                            </div>
                            <div>
                                <IonText id="3" onClick={getBtnId} className={index == 3 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Festivals
                                </IonText>
                            </div>
                            <div>
                                <IonText id="4" onClick={getBtnId} className={index == 4 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Pooja list
                                </IonText>
                            </div>
                            <div>
                                <IonText id="5" onClick={getBtnId} className={index == 5 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Aerial View
                                </IonText>
                            </div>
                            <div>
                                <IonText id="6" onClick={getBtnId} className={index == 6 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Schedule & Timings
                                </IonText>
                            </div>
                            <div>
                                <IonText id="7" onClick={getBtnId} className={index == 7 ? "temple_menu_head_text_selected" : "temple_menu_head_text"}>
                                    Gallery
                                </IonText>
                            </div>
                        </IonRow><div style={{ minHeight: "100%" }}>
                            <IonGrid className="menu_temple_details_container">
                                {index == 0 ?
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonRow>
                                                <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            About & history <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonTextarea placeholder="Enter About & history" className="temple_textarea_input" autoGrow
                                                        onBlur={() => validation(1)} value={templesdto.aboutAndHistory} onIonChange={(e) => historyChange(e)} />
                                                    {formErrors.abtAndHis && <span className="alert_input_bottom ">{formErrors.abtAndHis}</span>}
                                                </IonCol>
                                                <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                    <IonRow style={{ marginTop: "4%" }}>
                                                        <IonLabel className="temple_details_label">
                                                            Country <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonSelect className="temple_inputs" value={templesdto.country} onBlur={() => validation(4)} onIonChange={(e) => countryChange(e)}>
                                                        <IonSelectOption value={'India'}>India</IonSelectOption>
                                                    </IonSelect>
                                                    {formErrors.country && <span className="alert_input_bottom ">{formErrors.country}</span>}
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            State <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <Select
                                                        options={stateLiistOptions}
                                                        value={stateOption}
                                                        onBlur={() => validation(5)}
                                                        onChange={(e: any) => handleStateChange(e)}
                                                        placeholder="Select a State"
                                                        isClearable
                                                        styles={customStyles}
                                                        className="custom-select state_dropdown"
                                                    />
                                                    {formErrors.state && <span className="alert_input_bottom ">{formErrors.state}</span>}
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            District <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <Select
                                                        options={districtListOptions}
                                                        value={districtOption}
                                                        onChange={(e: any) => handleDistrictChange(e)}
                                                        placeholder="Select a District"
                                                        isClearable
                                                        onBlur={() => validation(6)}
                                                        styles={customStyles}
                                                        className="custom-select"
                                                    />
                                                    {formErrors.district && <span className="alert_input_bottom ">{formErrors.district}</span>}
                                                </IonCol>
                                                <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            Shipping Address <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonTextarea placeholder="Enter Shipping Address" className="temple_textarea_input" autoGrow value={shipAddress} onBlur={() => validation(7)}
                                                        onIonChange={(e) => shipAddressChange(e)} />
                                                    {formErrors.shipAddress && <span className="alert_input_bottom ">{formErrors.shipAddress}</span>}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            Temple Name <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonInput placeholder="Enter Temple Name" className="temple_inputs"
                                                        onBlur={() => validation(2)} value={templesdto.name} onIonChange={(e) => templeChange(e)} />
                                                    {formErrors.templeName && <span className="alert_input_bottom ">{formErrors.templeName}</span>}
                                                </IonCol>
                                                <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            Village Or Town <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonInput placeholder="Enter Village Name" className="temple_inputs" value={templesdto.villageorTown} onBlur={() => validation(3)} onIonChange={(e) => villageChange(e)} />
                                                    {formErrors.village && <span className="alert_input_bottom ">{formErrors.village}</span>}
                                                </IonCol>
                                                <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                    <IonRow>
                                                        <IonLabel className="temple_details_label">
                                                            Pincode <span style={{ color: "red" }}>*</span>
                                                        </IonLabel>
                                                    </IonRow>
                                                    <IonInput placeholder="Enter Pincode" className="temple_inputs" type="number" onKeyDown={(evt) => {
                                                        if (evt.key === 'e' || evt.key === 'E' || evt.key === '.') {
                                                            evt.preventDefault();
                                                        }
                                                    }}
                                                        onBlur={() => validation(8)} value={pincode} onIonChange={(e) => pincodeChange(e)} />
                                                    {formErrors.pincode && <span className="alert_input_bottom ">{formErrors.pincode}</span>}
                                                </IonCol>
                                            </IonRow>
                                            {comment && <IonRow>
                                                {templesdto.inactiveComment === null || templesdto.inactiveComment === "" ? <></> :
                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Comment <span style={{ color: "red" }}>*</span>
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonTextarea className="temple_inputs" autoGrow={true} readonly style={{ border: "1px solid #F6BD64", marginTop: "2%", height: "100px", overflowY: "auto" }} value={templesdto.inactiveComment} />
                                                    </IonCol>}
                                            </IonRow>}
                                        </IonCol>
                                    </IonRow> : index == 1 ?
                                        <IonRow>
                                            <IonCol size="12">
                                                <IonRow>
                                                    <IonLabel className="temple_facilites_head_text">
                                                        Accessibility
                                                    </IonLabel>
                                                </IonRow>
                                                <IonRow style={{ marginTop: "1%" }}>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Car
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect placeholder="Select Car" interface="popover" className="temple_inputs" value={facilities.car} onIonChange={(e) => carChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Bus
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect placeholder="Select Bus" interface="popover" className="temple_inputs" value={facilities.bus} onIonChange={(e) => busChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Train
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect placeholder="Select Train" interface="popover" className="temple_inputs" value={facilities.train} onIonChange={(e) => trainChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Flight
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect placeholder="Select Flight" interface="popover" className="temple_inputs" value={facilities.flight} onIonChange={(e) => flightChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow style={{ marginTop: "3%" }}>
                                                    <IonLabel className="temple_facilites_head_text">
                                                        Facilities
                                                    </IonLabel>
                                                </IonRow>
                                                <IonRow style={{ marginTop: "1%" }}>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Parking Lot
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonInput min={0} onFocus={preventdata} placeholder="Enter Parking lot" className="temple_inputs" type="number"
                                                            value={facilities.parkingLot} onIonChange={(e) => park(e)} />
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Security
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" placeholder="Select Security" interface="popover"
                                                            value={facilities.security} onIonChange={(e) => securityChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Rest Rooms
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" placeholder="Select Rest Rooms" interface="popover"
                                                            value={facilities.restRooms} onIonChange={(e) => restroomChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Auditorium
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonInput placeholder="Enter Auditorium" className="temple_inputs" value={facilities.auditorium} onIonChange={(e) => audChange(e)} />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow style={{ marginTop: "1%" }}>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Cloak Counters
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" interface="popover" placeholder="Select Cloak Counters" value={facilities.cloakCounters} onIonChange={(e) => cloakChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Pooja Provisions
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" interface="popover" placeholder="Select Pooja Provisions" value={facilities.poojaProvisions} onIonChange={(e) => provisionChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Cab / Auto Availability
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect className="temple_inputs" interface="popover" placeholder="Select Cab / Auto Availability" value={facilities.cabAvailablity} onIonChange={(e) => cabChange(e)}>
                                                            <IonSelectOption value={'yes'}>Yes</IonSelectOption>
                                                            <IonSelectOption value={'no'}>No</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow style={{ paddingLeft: "5px" }}>
                                                    <IonCol style={{ display: "flex" }} size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                        <IonLabel className="temple_details_label">
                                                            Shops
                                                        </IonLabel>
                                                        <div style={{ display: 'flex', alignItems: "center", paddingLeft: "3px" }}>
                                                            <IonIcon src={addCircleOutline} className="shop_add_icon" onClick={() => addInput(1)} />
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    {shopIn.map((item: any, i: any) => {
                                                        return (
                                                            <IonCol style={{ display: "flex" }} size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                <IonInput
                                                                    onIonChange={(e) => handleChangeShop(e, i)}
                                                                    value={item}
                                                                    id={i}
                                                                    type={item.type}
                                                                    ref={(ref) => shopInputRef.current = ref} autofocus={true}
                                                                    className="temple_inputs"
                                                                />
                                                                <div style={{ display: 'flex', alignItems: "center", paddingLeft: "3px" }}>
                                                                    <IonIcon src={removeCircleOutline} onClick={() => OpenRemovemodals(i, "shop", item)} className="shop_add_icon" />
                                                                </div>
                                                                {/* {shopIn.length <= 1 ? <></>
                                                                    : <IonIcon size="small" src={removeCircle} onClick={() => removeInputFields1(i)} style={{ cursor: "pointer", color: "#576F9F", padding: "10px 5px" }} />} */}
                                                            </IonCol>
                                                        );
                                                    })}
                                                </IonRow>
                                            </IonCol>
                                        </IonRow> : index == 2 ?
                                            <IonRow>
                                                <IonCol size="12">
                                                    <IonRow>
                                                        <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                            <IonLabel className="temple_details_label">
                                                                Add Offering Name
                                                            </IonLabel>
                                                        </IonCol>
                                                        <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                            <IonRow>
                                                                <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11">
                                                                    <IonLabel className="temple_details_label">
                                                                        Add Offering Significance
                                                                    </IonLabel>
                                                                </IonCol>
                                                                <IonCol size="1">
                                                                    <IonIcon
                                                                        src={addCircle}
                                                                        className="off_add_icon"
                                                                        onClick={() => addInputOfferings(1)}
                                                                    />
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                    </IonRow>
                                                    {Offerings.map((item: any, i: any) => {
                                                        return (
                                                            <div>
                                                                <IonRow>
                                                                    <IonCol size-lg="6" size-md="6" size-sm="11" size-xs="11">
                                                                        <IonTextarea
                                                                            onIonChange={(e) => offerhandleChange(e, i)}
                                                                            value={item.offeringName}
                                                                            className="temple_offerings_textarea_input"
                                                                            autoGrow
                                                                            placeholder="Add Offering Name"
                                                                            ref={(ref) => inputRef.current = ref} autofocus={true}
                                                                        />
                                                                    </IonCol>
                                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12" style={{ padding: "0px" }}>
                                                                        <IonRow>
                                                                            <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11">
                                                                                <IonTextarea
                                                                                    onIonChange={(e) => offerhandleChangeSIn(e, i)}
                                                                                    autoGrow
                                                                                    placeholder="Add Offering Significance"
                                                                                    value={item.significance}
                                                                                    className="temple_offerings_textarea_input"
                                                                                />
                                                                            </IonCol>
                                                                            <IonCol size="1">
                                                                                <IonIcon src={removeCircle} onClick={() => OpenRemovemodals(i, "offerings", item)} className="off_add_icon" />
                                                                            </IonCol>
                                                                        </IonRow>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </div>

                                                        );
                                                    })}
                                                </IonCol>
                                            </IonRow> : index == 3 ?
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <IonRow>
                                                            <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                <IonLabel className="temple_details_label">
                                                                    Add Festival Name
                                                                </IonLabel>
                                                            </IonCol>
                                                            <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                <IonRow>
                                                                    <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11">
                                                                        <IonLabel className="temple_details_label">
                                                                            Add Festival Significance
                                                                        </IonLabel>
                                                                    </IonCol>
                                                                    <IonCol size="1">
                                                                        <IonIcon
                                                                            src={addCircle}
                                                                            className="off_add_icon"
                                                                            onClick={() => addInputFest(0)}
                                                                        />
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonCol>
                                                        </IonRow>
                                                        {festival.map((item: any, i: any) => {
                                                            return (
                                                                <div>
                                                                    <IonRow>
                                                                        <IonCol size-lg="6" size-md="6" size-sm="11" size-xs="11">
                                                                            <IonTextarea
                                                                                onIonChange={(e) => handleChangeName(e, i)}
                                                                                value={item.name}
                                                                                className="temple_offerings_textarea_input"
                                                                                autoGrow
                                                                                placeholder="Add Festival Name"
                                                                                ref={(ref) => inputRef1.current = ref} autofocus={true}
                                                                            />
                                                                        </IonCol>
                                                                        <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12" style={{ padding: "0px" }}>
                                                                            <IonRow>
                                                                                <IonCol size-lg="11" size-md="11" size-sm="11" size-xs="11">
                                                                                    <IonTextarea
                                                                                        onIonChange={(e) => handleChangeSign(e, i)}
                                                                                        value={item.description}
                                                                                        placeholder="Add Festival Significance"
                                                                                        className="temple_offerings_textarea_input"
                                                                                        autoGrow
                                                                                    />
                                                                                </IonCol>
                                                                                <IonCol size="1">
                                                                                    <IonIcon src={removeCircle} onClick={() => OpenRemovemodals(i, "festival", item)} className="off_add_icon" />
                                                                                </IonCol>
                                                                            </IonRow>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </div>
                                                            );
                                                        })}
                                                    </IonCol>
                                                </IonRow>
                                                : index == 4 ?
                                                    <div style={{ overflowY: "auto", height: "420px" }}>
                                                        {screenwidth <= 1000 ? <></> : <IonRow style={{ backgroundColor: "transparent" }}>
                                                            <IonCol size-xl="2.4">
                                                                <IonText className="temple_poojalist_head">Pooja Name</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="2">
                                                                <IonText className="temple_poojalist_head_highlight">Pooja Price</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="2.4">
                                                                <IonText className="temple_poojalist_head">Pooja Description</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="2.4">
                                                                <IonText className="temple_poojalist_head_highlight">Pooja Timings</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="2.4">
                                                                <IonText className="temple_poojalist_head">Deity</IonText>
                                                            </IonCol>
                                                            <IonCol size-xl="0.4">
                                                                <IonIcon
                                                                    src={addCircle}
                                                                    className="pooja_list_add_icon" onClick={() => addrow(0)}
                                                                />
                                                            </IonCol>
                                                        </IonRow>}
                                                        {poojarow != null && poojarow.length>0 ? 
                                                        <>
                                                        {poojarow.map((data: any, index: any) => {
                                                            return (
                                                                <IonRow className="poojalist_input_container" key={index}>
                                                                    {/* <IonCol></IonCol> */}
                                                                    <IonCol size-xl="2.4" size-lg="2.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonInput type="text" placeholder="Pooja Name" value={data.name} onBlur={() => validation(21)} onIonChange={(evnt) => (handleChangerowPname(index, evnt))} name="poojaName" className="temple_poojalist_inputs"
                                                                            ref={(ref) => poojaListInputRef.current = ref} autofocus={true} />
                                                                    </IonCol>
                                                                    <IonCol size-xl="2" size-lg="2" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonInput type="number" onFocus={preventdata} placeholder="Price" value={data.price} min={0}
                                                                            onBlur={() => validation(22)} onKeyDown={(e: any) => restrictE(e)}
                                                                            onIonChange={(evnt) => (handleChangerowPprice(index, evnt))} name="poojaPrice" className="temple_poojalist_inputs" />
                                                                    </IonCol>
                                                                    <IonCol size-xl="2.4" size-lg="2.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonInput type="text" placeholder="Description" value={data.description} onBlur={() => validation(23)}
                                                                            onIonChange={(evnt) => (handleChangerowDesc(index, evnt))} name="poojaPrice" className="temple_poojalist_inputs" />
                                                                    </IonCol>
                                                                    <IonCol size-xl="2.4" size-lg="2.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonInput type="text" placeholder="Timings" value={data.offeringTime} onBlur={() => validation(24)}
                                                                            onIonChange={(evnt) => (handleChangerowPtiming(index, evnt))} name="poojaTiming" className="temple_poojalist_inputs" />
                                                                    </IonCol>
                                                                    <IonCol size-xl="2.4" size-lg="2.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonSelect interface="popover" placeholder="Select Deity" value={data.deity.id} name="poojaPrice" className="temple_poojalist_dropDown"
                                                                            onBlur={() => validation(25)} onIonChange={(e) => (handleChangerowGod(index, e))}>
                                                                            {deity.map((state: any) => (
                                                                                <IonSelectOption key={state.id} value={state.id}>
                                                                                    {state.deityName}
                                                                                </IonSelectOption>
                                                                            ))}
                                                                        </IonSelect>
                                                                    </IonCol>
                                                                    <IonCol style={{ textAlign: "right" }} size-xl="0.4" size-lg="0.4" size-md="12" size-sm="12" size-xs="12">
                                                                        <IonIcon src={removeCircle}
                                                                            onClick={() => OpenRemovemodals(index, "poojaList", data)}
                                                                            className="pooja_list_add_icon" />
                                                                    </IonCol>
                                                                </IonRow>
                                                            )
                                                        })}
                                                        </>:<></>}
                                                        
                                                        <div className="" style={{ display: "flex", padding: "20px", alignItems: "center" }}>
                                                            <div style={{ justifyContent: "flex-start", width: "90%" }}>
                                                                {formErrors.pooja && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.pooja}</span>}
                                                            </div>
                                                            {/* <div style={{ justifyContent: "end" }}>
                                                                <IonButton className="admin_add_btn" onClick={() => addrow(0)}>
                                                                    <span className="button_padding">
                                                                        Add
                                                                    </span>
                                                                </IonButton>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    : index == 5 ?
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <IonRow>
                                                                    <IonLabel className="temple_details_label">
                                                                        Please Enter Temple Address Map Link here
                                                                    </IonLabel>
                                                                </IonRow>
                                                                <IonRow>
                                                                    <IonCol size="12">
                                                                        <IonTextarea onIonChange={(e) => handlechange(e)} placeholder="Please enter temple address map link here"
                                                                            value={aerial} onBlur={() => validation(33)} autoGrow className="temple_map_textarea_input" />
                                                                        {formErrors.aerial && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.aerial}</span>}
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonCol>
                                                            {lat && long ?

                                                                <IonRow style={{ width: "100%" }}>
                                                                    <IonCol className="col1" size-lg="12" size-md="12" size-xs="12" size-sm="12">

                                                                        {window.google == undefined ? <LoadScript
                                                                            googleMapsApiKey={UserService.mapKey()}
                                                                        >
                                                                            <GoogleMap
                                                                                mapContainerStyle={containerStyle}
                                                                                options={mapOptions}

                                                                                center={{
                                                                                    lat: lat, lng: long,
                                                                                }}
                                                                                zoom={17}
                                                                            >
                                                                                <MarkerF position={{ lat: lat, lng: long }} />
                                                                            </GoogleMap>
                                                                        </LoadScript> : <GoogleMap
                                                                            mapContainerStyle={containerStyle}
                                                                            center={{ lat: lat, lng: long }}
                                                                            options={mapOptions}
                                                                            zoom={17}
                                                                        >
                                                                            <MarkerF position={{ lat: lat, lng: long }} />
                                                                        </GoogleMap>}
                                                                    </IonCol>
                                                                    {/* <IonCol className="col2" size-lg="6" size-md="12" size-xs="12" size-sm="12">
                                                                </IonCol> */}
                                                                </IonRow>
                                                                : <></>}
                                                        </IonRow>
                                                        : index == 6 ?
                                                            <IonRow>
                                                                <IonCol size="12">
                                                                    <IonRow>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Morning Darshan <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonInput placeholder="Enter Morning Darshan" onBlur={() => validation(26)} value={morning} className="temple_inputs" onIonChange={(e) => mrngChange(e)} />
                                                                            {formErrors.mrng && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.mrng}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Evening Darshan <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonInput placeholder="Enter Evening Darshan" value={evening} className="temple_inputs" onBlur={() => validation(27)} onIonChange={(e) => evngChange(e)} />
                                                                            {formErrors.evng && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.evng}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Time Required <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonInput placeholder="Enter Time Required" onBlur={() => validation(28)} value={timerequ} className="temple_inputs" onIonChange={(e) => timeChange(e)} />
                                                                            {formErrors.time && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.time}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Temple Closing Time <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonInput placeholder="Enter Temple Closing Time" onBlur={() => validation(29)} value={closetime} onIonChange={(e) => ctimeChange(e)} className="temple_inputs" />
                                                                            {formErrors.ctime && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.ctime}</span>}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow style={{ marginTop: "1%" }}>
                                                                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Fee Details <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonSelect placeholder="Select Fee Details" interface="popover" onBlur={() => validation(30)} onIonChange={(e) => feeChange(e)} value={entry} className="temple_inputs" >
                                                                                <IonSelectOption value="entry fee">Entry Fee</IonSelectOption>
                                                                                <IonSelectOption value="No entry fee">No Entry Fee</IonSelectOption>
                                                                            </IonSelect>
                                                                            {formErrors.fee && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.fee}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="4.5" size-md="4.5" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Dress Code (Men) <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonTextarea autoGrow placeholder="Enter Men Dress Code" className="temple_textarea_dress" onBlur={() => validation(31)} value={dressme} onIonChange={(e) => mChange(e)} />
                                                                            {formErrors.dressM && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.dressM}</span>}
                                                                        </IonCol>
                                                                        <IonCol size-lg="4.5" size-md="4.5" size-sm="12" size-xs="12">
                                                                            <IonRow>
                                                                                <IonLabel className="temple_details_label">
                                                                                    Dress Code (Women) <span style={{ color: 'red' }}>*</span>
                                                                                </IonLabel>
                                                                            </IonRow>
                                                                            <IonTextarea autoGrow placeholder="Enter Women Dress Code" className="temple_textarea_dress" onBlur={() => validation(32)} value={dresswo} onIonChange={(e) => wChange(e)} />
                                                                            {formErrors.dressW && <span className="alert_input_bottom " style={{ width: "100%" }}>{formErrors.dressW}</span>}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </IonCol>
                                                            </IonRow>
                                                            : index == 7 ?
                                                                <div>
                                                                    <IonRow>
                                                                        <input style={{ display: "none" }} ref={imageinputref} type="file" name="photo" onChange={(e) => onchangeImage(e)} multiple={true} />
                                                                        <IonCol size="2">
                                                                            <IonButton
                                                                                onClick={handleButtonClick}
                                                                                className="admin_img_btn image_content"
                                                                            ><span>
                                                                                    Choose File
                                                                                </span>

                                                                            </IonButton>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow>
                                                                        <IonCol size="12">
                                                                            <IonRow style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                                                                                <IonText style={{ color: "red", fontSize: "10px" }}>* The first image will be displayed as Temple Image</IonText>
                                                                            </IonRow>
                                                                            <IonRow className="ion-justify-content-center">
                                                                                {image.map((item: any, i: any) => {
                                                                                    return (
                                                                                        <IonCol sizeLg="4" size="4">
                                                                                            <div style={{ display: "flex", justifyContent: "right" }}>
                                                                                                <IonIcon src={closeCircle} tabIndex={0} size="small" style={{ cursor: "pointer", color: '#576F9F' }}
                                                                                                    onClick={() => DeleteImage(item)}
                                                                                                    onKeyDown={(event) => {
                                                                                                        if (event.key === "Enter" || event.key === " ") {
                                                                                                            DeleteImage(item);
                                                                                                        }
                                                                                                    }} />
                                                                                            </div>
                                                                                            <IonImg src={item} tabIndex={1} className="img" onClick={() => openModalAndSetIndex(i)}
                                                                                                onKeyDown={(event) => {
                                                                                                    if (event.key === "Enter" || event.key === " ") {
                                                                                                        openModalAndSetIndex(i);
                                                                                                    }
                                                                                                }} />
                                                                                        </IonCol>
                                                                                    )
                                                                                })}
                                                                                {showModal && (
                                                                                    <Lightbox
                                                                                        mainSrc={image[indexOfImages]}
                                                                                        nextSrc={image[(indexOfImages + 1) % image.length]}
                                                                                        prevSrc={
                                                                                            image[
                                                                                            (indexOfImages + image.length - 1) % image.length
                                                                                            ]
                                                                                        }
                                                                                        onCloseRequest={() => setShowModal(false)}
                                                                                        onImageLoad={() => {
                                                                                            window.dispatchEvent(new Event('resize'));
                                                                                        }}
                                                                                        onMovePrevRequest={() =>
                                                                                            setIndexOfImages(
                                                                                                (indexOfImages + image.length - 1) % image.length
                                                                                            )
                                                                                        }
                                                                                        onMoveNextRequest={() =>
                                                                                            setIndexOfImages(
                                                                                                (indexOfImages + image.length + 1) % image.length
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </IonRow>
                                                                            <IonRow style={{ marginTop: '1%' }}>
                                                                                <IonCol size="11" size-xs="10">
                                                                                    <IonLabel className="temple_facilites_head_text">
                                                                                        Video Link
                                                                                    </IonLabel>
                                                                                </IonCol>
                                                                                <IonCol size="1" size-xs="2" style={{ display: "flex", alignItems: "center" }}>
                                                                                    <IonIcon
                                                                                        src={addCircle}
                                                                                        className="video_add_icon"
                                                                                        onClick={() => addofferInput1(0)}
                                                                                        tabIndex={2}
                                                                                        onKeyDown={(event) => {
                                                                                            if (event.key === "Enter" || event.key === " ") {
                                                                                                addofferInput1(0)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </IonCol>
                                                                            </IonRow>

                                                                            {videolink.map((item: any, i: any) => {
                                                                                var flag = true;

                                                                                return (
                                                                                    <div>
                                                                                        <IonRow>
                                                                                            <IonCol size="11" size-xs="10">
                                                                                                <IonRow>
                                                                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                                                        <IonLabel className="temple_details_label">Video Name</IonLabel>
                                                                                                        <IonInput
                                                                                                            value={item.videoName}
                                                                                                            id={i}
                                                                                                            onIonChange={(e) => handlevdename(e, i, 1)}
                                                                                                            className="temple_inputs"
                                                                                                            ref={(ref) => videoInputRef.current = ref} autofocus={true}
                                                                                                        />
                                                                                                    </IonCol>
                                                                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                                                        <IonLabel className="temple_details_label">Video Link</IonLabel>
                                                                                                        <IonInput
                                                                                                            value={item.videoLink}
                                                                                                            id={i}
                                                                                                            onIonChange={(e) => handlevdelink(e, i, 1)}
                                                                                                            className="temple_inputs"
                                                                                                        />
                                                                                                    </IonCol>
                                                                                                </IonRow>
                                                                                            </IonCol>
                                                                                            <IonCol size="1" size-xs="2" style={{ display: "flex", alignItems: "center" }}>
                                                                                                <IonIcon src={removeCircle}
                                                                                                    tabIndex={2}
                                                                                                    onKeyDown={(event) => {
                                                                                                        if (event.key === "Enter" || event.key === " ") {
                                                                                                            OpenRemovemodals(i, "video", item)
                                                                                                        }
                                                                                                    }}
                                                                                                    onClick={() => OpenRemovemodals(i, "video", item)}
                                                                                                    className="video_add_icon" />
                                                                                            </IonCol>
                                                                                        </IonRow>
                                                                                        {formErrors.videoLink && <span className="alert_input_bottom ">{formErrors.videoLink}</span>}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                            <IonRow style={{ marginTop: '1%' }}>
                                                                                <IonCol size="11" size-xs="10">
                                                                                    <IonLabel className="temple_facilites_head_text">
                                                                                        Live Video Link
                                                                                    </IonLabel>
                                                                                </IonCol>
                                                                                <IonCol size="1" size-xs="2" style={{ display: "flex", alignItems: "center" }}>
                                                                                    <IonIcon
                                                                                        src={addCircle}
                                                                                        tabIndex={2}
                                                                                        className="video_add_icon" onClick={() => addofferInput1(1)}
                                                                                        onKeyDown={(event) => {
                                                                                            if (event.key === "Enter" || event.key === " ") {
                                                                                                addofferInput1(1)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            {videolivelink.map((item: any, i: any) => {
                                                                                return (
                                                                                    <div>
                                                                                        <IonRow>
                                                                                            <IonCol size="11" size-xs="10">
                                                                                                <IonRow>
                                                                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                                                        <IonLabel className="temple_details_label">Video Name</IonLabel>
                                                                                                        <IonInput
                                                                                                            value={item.videoName}
                                                                                                            id={i}
                                                                                                            onIonChange={(e) => handlevdename(e, i, 2)}
                                                                                                            className="temple_inputs"
                                                                                                            ref={(ref) => liveVideoInputRef.current = ref} autofocus={true}
                                                                                                        />
                                                                                                    </IonCol>
                                                                                                    <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                                                        <IonLabel className="temple_details_label">Video Link</IonLabel>
                                                                                                        <IonInput
                                                                                                            value={item.videoLink}
                                                                                                            id={i}
                                                                                                            onIonChange={(e) => handlevdelink(e, i, 2)}
                                                                                                            className="temple_inputs"
                                                                                                        />
                                                                                                    </IonCol>
                                                                                                </IonRow>
                                                                                            </IonCol>
                                                                                            <IonCol size="1" size-xs="2" style={{ display: "flex", alignItems: "center" }}>
                                                                                                <IonIcon src={removeCircle}
                                                                                                    onClick={() => OpenRemovemodals(i, "live", item)}
                                                                                                    tabIndex={2}
                                                                                                    onKeyDown={(event) => {
                                                                                                        if (event.key === "Enter" || event.key === " ") {
                                                                                                            OpenRemovemodals(i, "live", item)
                                                                                                        }
                                                                                                    }}
                                                                                                    className="video_add_icon" />
                                                                                            </IonCol>
                                                                                        </IonRow>
                                                                                        {formErrors.videoLinkLive && <span className="alert_input_bottom ">{formErrors.videoLinkLive}</span>}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </div>
                                                                : <></>}
                                <IonRow style={{ justifyContent: "flex-end", marginTop: "5%" }}>
                                    <IonButton onClick={onsubmit} className="admin_add_btn">
                                        <span className="button_padding">
                                            Submit
                                        </span>
                                    </IonButton>
                                    <IonButton onClick={() => onCancel()} className="admin_cencel_btn" fill="outline">
                                        <span className="button_padding">
                                            Cancel
                                        </span>
                                    </IonButton>
                                </IonRow>
                            </IonGrid></div>
                    </div> :
                    <div>
                        {/* <Admtemple id={idBack} edit={editBack} admin={adminBack} tempId={tempId} name={nameBack} state={state} city={city} district={district} templeName={tempname} /> */}
                    </div>}

                <Modal show={modalopen} className="logout-modal modal-dialog modal-dialog-centered modal-sm">
                    <Modal.Body>
                        <IonRow>
                            <IonCol style={{ textAlign: "center" }}>
                                <p className="mt-2" style={{ fontSize: "16px" }}>Do you want to remove this field?</p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="mt-12">
                            <IonCol size="6" style={{ textAlign: "end" }}>
                                <IonButton className="admin_add_btn" onClick={handleRemove}>
                                    <span className="button_padding">
                                        Yes
                                    </span>
                                </IonButton>
                            </IonCol>
                            <IonCol size="6">
                                <IonButton className="admin_add_btn" onClick={() => handleRemoveCancel()}>
                                    <span className="button_padding">
                                        No
                                    </span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </Modal.Body>
                </Modal>
                {/* <Foot /> */}
            </IonContent>
        </IonPage >
    )
}

export default TempleAdmDet;
