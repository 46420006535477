import {
    IonText, useIonAlert, IonInput, IonRow, IonCol, IonLabel, IonButton,
    IonGrid, IonSelect, IonSelectOption, IonImg, IonIcon, IonPage,
    IonContent, IonItemDivider, IonLoading, IonSlides, IonSlide, IonPopover, IonList, IonCard, IonHeader
} from '@ionic/react'
import React, { useEffect, useRef, useState } from "react";
import { informationCircle, arrowBack, key, ellipsisVerticalCircleOutline, close, informationCircleSharp, checkmarkCircleSharp, shareSocialOutline, shareSocialSharp } from "ionicons/icons"
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import Authservice from "../Services/auth.service";
import UserHead from "./UserHead";
import Foot from "./Foot";
import "jquery/dist/jquery.min.js"
import { useHistory } from "react-router";
import "./sam-temp.css"
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { useForm } from "react-hook-form";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Capacitor } from '@capacitor/core';
import CustomSpin from '../components/customSpin';
import Select, { components } from 'react-select';
import UserService from '../Services/user.service';
import { closeicon, infoicon, shareIcon, tick } from '../theme/imagePath';
import HeadFoot from './Head';



interface Props {
    id1: string;
    deity: string;
    img: string;
}
const Temples: React.FC = () => {
    const userdata: any = useState(localStorage.getItem("UserData"));
    const uFlag: any = useState(localStorage.getItem("uFlag"));
    const token: any = useState(localStorage.getItem("token"));
    const [errorText, setErrorText] = useState("")
    const [deitylist, setDeitylist] = useState([]);
    const [image, setImage] = useState("");
    const [description, setDescription] = useState("");
    const [isShow, setIsshow] = useState(false);
    const [islogin, Islogin] = useState(false)
    // const [index, setIndex]: any = useState(0)
    const [showLoading, setShowLoading] = useState(false);
    // const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [states, setStates]: any = useState([]);
    const history = useHistory()
    // var randomColor = require('randomcolor');
    const [hideContent, setHideContent] = useState(false)
    const [index1, setIndex1] = useState(0);
    const [Alert] = useIonAlert();
    const [usertoken] = useState(localStorage.getItem("token"));
    const [state, setState]: any = useState();
    const [country, setCountry] = useState("India");
    const [district, setDistrict]: any = useState()
    const [town, setTown]: any = useState()
    const [temName, setTemName]: any = useState()
    const [deityId, setDeityId] = useState("");
    const [deityName, setDeityName] = useState("");
    const [deityImg, setDeityImg] = useState('');
    const [gods, setGods] = useState([])
    const [id, setId] = useState("");
    const [facilities, setFacilities]: any = useState([]);
    const [aerial, setAerial]: any = useState([]);
    const [Offerings, setOfferings]: any = useState([]);
    const [festival, setFestival]: any = useState([]);
    const [poojaoffer, setpoojaoffer]: any = useState([]);
    const [timings, setTimings]: any = useState([]);
    const [gallery, setGallery]: any = useState([]);
    const [shop, setShopIn]: any = useState([]);
    const [acces, setAccIn]: any = useState([]);
    const [lat, setLat]: any = useState();
    const [long, setLong]: any = useState();
    const [morning, setMorning] = useState("");
    const [evening, setEvening] = useState("");
    const [timerequ, setTimerequ] = useState("");
    const [closetime, setClosetime] = useState("");
    const [entry, setEntry] = useState("");
    const [dressme, setDressme] = useState("");
    const [dresswo, setDresswo] = useState("");
    const [videolink, setVideolink]: any = useState([])
    const [videolivelink, setVideoLivelink]: any = useState([])
    const [nodata, setNodata] = useState(false)
    const [nodataFest, setNodataFest] = useState(false)
    const [indexOfImages, setIndexOfImages]: any = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showgod, setShowgod] = useState(false);
    const openModalAndSetIndex = (index: any) => {
        setIndexOfImages(index);
        setShowModal(true);
    };
    const [stateOption, setStateOption]: any = useState([]);
    const [districtOption, setDistrictOption]: any = useState([]);
    const [districts, setDistricts]: any = useState([])
    const [districtMenu, setDistrictMenu]: any = useState(false)
    const [stateMenu, setStateMenu]: any = useState(false)
    const [countries, setCountries] = useState(["India"])
    const [countryOption, setCountryOption]: any = useState({ value: "India", label: "India" });
    const [formErrors, setFormErrors]: any = useState({})
    useEffect(() => {
        console.log(userdata[0])
        if (userdata[0] !== null && userdata[0] !== "ROLE_USER") {
            history.push("/home")
        }
        else if (userdata[0] !== null && uFlag[0] === "false") {
            history.push("/home")
        }
        else if (userdata[0]) {
            Islogin(true)
            getallgods();
        }
        else {
            Islogin(false)
            getallgods();
        }
    }, [])
    const getAllState = () => {
        setShowLoading(true);
        UserService.getAllstates().then((response) => {
            console.log(response.data);
            setStates(response.data)
            setShowLoading(false);
        })
            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }
    const Details = (item: any) => {
        if (Capacitor.getPlatform() !== "web" && gods.length === 0) {
            setHideContent(true)
        }
        else {
            setHideContent(false)
        }
        setGods([])
        setShowgod(false)
        setDeityId(item.id);
        setName(item.deityName);
        setDeityName(item.deityName)
        setImage(item.image)
        setDeityImg(item.image)
        getAllState()
        setIndex1(2);
    }
    const getallgods = () => {
        console.log(islogin)
        setShowLoading(true);
        Authservice.Getallgods(islogin, token[0]).then((response: any) => {
            if (response.data !== undefined || response.data.length !== 0) {
                setDeitylist(response.data);
            } else {
                setDeitylist([]);
                setErrorText("Sorry, No data available")
            }
            setShowLoading(false)

        })
            .catch((error: any) => {
                console.log(error);
                setErrorText("Sorry, No data available")
                setShowLoading(false);
            });
    }
    const views = () => {
        if (Capacitor.getPlatform() !== "web") {
            setHideContent(true)
        }
        else {
            setHideContent(false)
        }
        setIndex1(0)
    }
    const Viewinfo = (item: any) => {
        setImage(item.image)
        setName(item.deityName)
        setDescription(item.deityDescription)
        // setIsshow(true)
        setIndex1(1)
    }
    const useOutsideAlerter = (refer: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (refer.current && !refer.current.contains(event.target)) {
                    setIsshow(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer]);
    }
    const deitymodal = useRef(null);
    useOutsideAlerter(deitymodal);
    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // MultiColorDot.js


    const MultiColorDot = (color: any) => {
        const dotClass = `multi-color-dot`;
        return <div className={dotClass} style={{ backgroundColor: color }}></div>;
    };

    var randomColors: any = useState([]);

    const stateLiistOptions = states.map((e: any, index: any) => ({
        value: e,
        label: e
    }));
    const districtLiistOptions = districts.map((e: any, index: any) => ({
        value: e,
        label: e
    }));
    const countryLiistOptions = countries.map((e: any, index: any) => ({
        value: e,
        label: e
    }));

    const containerStyle = {
        width: '700px',
        height: '400px'
    };
    var center = {
        lat: lat,
        lng: long,
    };
    var mapOption = {
        gestureHandling: "cooperative",
        minZoom: 4

    };
    const targetRef: any = useRef(null);

    const Submit = () => {
        const errors: any = {};
        if (country.length == 0) {
            errors.country = "Please select the country"
        }
        setFormErrors(errors);
        if (Object.keys(errors).length == 0) {
            setShowLoading(true)
            Authservice.SearchTemple(usertoken, country, state, district, town, deityId, temName)
                .then((response: any) => {
                    console.log("Res=>", response)
                    setGods(response.data)
                    setShowgod(true);
                    if (Capacitor.getPlatform() !== "web") {
                        setHideContent(false);
                        setTimeout(() => {
                            if (targetRef.current) {
                                targetRef.current.scrollIntoView({ behavior: "smooth" });
                            }
                            console.log(showgod, "godddd")
                        }, 500)
                    }
                    setShowLoading(false)
                })
                .catch((error) => {
                    console.log(error);
                    setShowLoading(false)
                });
        }
    };
    const Clear = () => {
        setState("");
        setDistrict("");
        setTown("");
        setTemName("");
        setStateOption(null)
        setDistrictOption(null)
        setShowgod(false);
        if (Capacitor.getPlatform() !== "web") {
            setHideContent(true)
        }
    }
    const [videolinkshow, setVideolinkshow] = useState(false)
    const [livevideolinkshow, setLivevideolinkshow] = useState(false)
    const [car, setCar] = useState("")
    const [bus, setBus] = useState("")
    const [train, setTrain] = useState("")
    const [flight, setFlight] = useState("")
    const show = (id: any) => {
        setId(id)
        Authservice.Templedetails(id)
            .then((response: any) => {
                console.log("Ressss=>", response)
                setFacilities(response.data.facilitiesDto)
                if (response.data.facilitiesDto.car === "" || response.data.facilitiesDto.car === null) {
                    setCar("No")
                }
                else {
                    setCar(response.data.facilitiesDto.car)
                }
                if (response.data.facilitiesDto.bus === "" || response.data.facilitiesDto.bus === null) {
                    setBus("No")
                }
                else {
                    setBus(response.data.facilitiesDto.bus)
                }
                if (response.data.facilitiesDto.train === "" || response.data.facilitiesDto.train === null) {
                    setTrain("No")
                }
                else {
                    setTrain(response.data.facilitiesDto.train)
                }
                if (response.data.facilitiesDto.flight === "" || response.data.facilitiesDto.flight === null) {
                    setFlight("No")
                }
                else {
                    setFlight(response.data.facilitiesDto.flight)
                }
                setAerial(response.data.templesDto)
                setOfferings(response.data.offeringsAndSignificanceDto);
                if (response.data.offeringsAndSignificanceDto.every((e: any) => (e.offeringName == "" && e.significance == ""))) {
                    setNodata(true)
                }
                setFestival(response.data.festivalsDto)
                if (response.data.festivalsDto.every((e: any) => (e.name == "" && e.description == ""))) {
                    setNodataFest(true)
                }
                setpoojaoffer(response.data.poojaOfferingsDto);
                setTimings(response.data.templesDto);
                if (response.data.templeImgsUrl !== null) {
                    setGallery(response.data.templeImgsUrl);
                } else {
                    setGallery([]);
                }
                var long = parseFloat(response.data.templesDto.tempLong)
                setLong(long);
                var lat = parseFloat(response.data.templesDto.tempLat)
                setLat(lat);
                var arr: any = [];
                console.log(response.data.facilitiesDto.shops)
                response.data.facilitiesDto.shops.map((item: any) => {
                    console.log(item.split('||'))
                    arr = item.split('||');
                });
                var array_filter = arr.filter((res: any) => res != "")
                setShopIn(array_filter);
                // var arr_access: any = [];
                // response.data.facilitiesDto.accessibility.map((item: any) => {
                //     console.log(item.split('||'))
                //     arr_access = item.split('||');
                // });
                // var mapItem: any = "";
                // response.data.templesDto.aerialView.map((item: any) => {
                //     console.log(item.split(', '))
                //     mapItem = item.split(', ')
                // });
                // center = {
                //     lat: response.data.templesDto.tempLat,
                //     lng: response.data.templesDto.tempLong,
                // };
                // var array_filter_acs = arr_access.filter((res: any) => res != "")
                // setAccIn(array_filter_acs);
                const times = response.data.templesDto.scheduleAndTimings.split("and")
                setTimings(times);
                var string = response.data.templesDto.scheduleAndTimings.split('and')
                setMorning(string[0].replace('Morning Darshan:', ''))
                setEvening(string[1].replace('Evening Darshan:', ''))
                setTimerequ(string[2].replace('Time Required:', ''))
                setEntry(string[3])
                setDressme(string[4].replace('Dress Code:', ''))
                setDresswo(string[5].replace('Dress Code:', ''))
                setClosetime(string[6].replace('Closing Time:', ''))
                setVideolink(response.data.templeVideoLinksDto.filter((res: any) => res.type !== 2 && res.type !== null));
                setVideoLivelink(response.data.templeVideoLinksDto.filter((res: any) => res.type !== 1 && res.type !== null));
                if (response.data.templeVideoLinksDto.filter((res: any) => res.type !== 2 && res.type !== null).every((e: any) => (e.videoLink == "" && e.videoName == ""))) {
                    setVideolinkshow(true)
                }
                else {
                    setVideolinkshow(false)
                }
                if (response.data.templeVideoLinksDto.filter((res: any) => res.type !== 1 && res.type !== null).every((e: any) => (e.videoLink == "" && e.videoName == ""))) {
                    setLivevideolinkshow(true)
                }
                else {
                    setLivevideolinkshow(false)
                }
            })
            .catch((error) => {
                console.log(error);
            });
        console.log("id", id)
        setShowLoading(false);
        setIndex1(3);
    }
    const back1 = () => {
        setIndex1(2);
        setIndex(0);
        setNodata(false)
        setNodataFest(false)
    }
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '90%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const [index, setIndex]: any = useState(0);
    const getBtnId = (e: any) => {
        setIndex(e.target.id)
    };
    const { handleSubmit } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
    });
    const SearchF = (value: any) => {
        if (value == "Enter") {
            Submit()
        }
    }
    const openMap = () => {
        console.log("lkn")
        window.open('https://www.google.com/maps/place/' + lat + ',' + long + '/@' + lat + ',' + long + ',')
    }
    const getAllDistricts = (item: any) => {
        const arr = [item.value]
        UserService.getAllDistricts(arr).then((response) => {
            console.log(response);
            setShowLoading(true)
            setDistricts(response.data)
            setShowLoading(false)
        })

            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }
    const handleCountryChange = (e: any) => {
        console.log(e)
        if (e) {
            setCountryOption(e);
            setCountry(e.value);
        } else {
            setCountryOption(null)
            setCountry("")
        }
    }
    const handleStateChange = (e: any) => {
        console.log(e)
        if (e) {
            setStateOption(e);
            setState(e.value);
            getAllDistricts(e)
        } else {
            setStateOption(null)
            setState("")
            setDistricts([])
            setDistrictOption(null)
            setDistrict("")
        }
    }
    const handleDistrictChange = (e: any) => {
        console.log(e)
        if (e) {
            setDistrictOption(e);
            setDistrict(e.value);
        } else {
            setDistrictOption(null)
            setDistrict("")
        }
    }

    return (
        <IonPage>
            <IonContent>
                <HeadFoot User={"login"} Role={'Role'} Name='Home,Temples' />
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}

                // onDidDismiss={() => setShowLoading(false)}
                // duration={5000}
                />
                <Modal show={isShow} className="custom-modal-wrapper">
                    <Modal.Body className='modal_container'>
                        <div style={{ textAlign: "center" }}>
                            <IonText className='area_modal_head'>
                                Select Area
                            </IonText>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <IonIcon src={close} className='modal_close_icon close_icon' onClick={() => setIsshow(false)} />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <IonText className='area_modal_subHead'>
                                {name}
                            </IonText>
                        </div>
                        <IonGrid>
                            <IonRow>
                                <IonText className='area_modal_labels'>
                                    Country
                                </IonText>
                            </IonRow>
                        </IonGrid>
                    </Modal.Body>
                </Modal>
                <div>

                    {index1 === 0 ?
                        <IonRow className='deity_container page_content_padding'>
                            {deitylist.length > 0 ? <>
                                {deitylist.map((item: any, i) => (
                                    <IonCol sizeLg='6' sizeXl='4' size-md='6' size-xs='12' size-sm='12'>
                                        <IonCard className='deity_cards'>
                                            <IonRow>
                                                <IonCol style={{ display: "flex" }} size='5'>
                                                    <IonImg className="deity_god_image" src={item.image + "?v=" + new Date()} />
                                                </IonCol>
                                                <IonCol size='7'>
                                                    <IonRow style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <IonCol size='10'>
                                                            <IonText className='deity_name'>{item.deityName}</IonText>
                                                        </IonCol>
                                                        <IonCol size='2'>
                                                            <IonIcon onClick={() => Viewinfo(item)} className='info_icon' src={informationCircleSharp} />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonText className='deity_description'>
                                                            {item.deityDescription}
                                                        </IonText>
                                                    </IonRow>
                                                    <IonRow style={{ marginTop: "5px" }}>
                                                        <IonButton onClick={(e) => Details(item)}>
                                                            <span className='button_padding'>
                                                                Select Area
                                                            </span>
                                                        </IonButton>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </IonCard>
                                    </IonCol>
                                ))
                                }</> : <div className='no-materials'>
                                <IonText>Sorry, No data available</IonText>
                            </div>}
                        </IonRow> : index1 === 1 ? <IonGrid className='about_deity_container page_content_padding'>

                            <IonRow style={{ paddingTop: "20px" }}>
                                <IonCol size='1' className='center_col'>
                                    <div className='back'>
                                        <IonIcon onClick={() => {
                                            setIndex1(0)
                                        }} src={arrowBack} className="point back" size="large" />
                                    </div>
                                </IonCol>
                                <IonCol size='11'>
                                    <IonText className='bio_deity'>
                                        Biography
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size-lg="12" size-md="12" size-xs="12">
                                    <IonImg src={image} className='bio_right_img bio_deity_image' />
                                    <p className='bio_deity_description'>{description}</p>
                                </IonCol>
                            </IonRow>
                        </IonGrid> : index1 === 2 ?
                            <div>
                                <IonGrid className='page_content_padding'>
                                    <IonRow>
                                        <IonCol className='sa' size='12' sizeLg='5.5'>
                                            <form onSubmit={handleSubmit(Submit)} onKeyPress={e => SearchF(e.key)}>
                                                <IonRow>
                                                    <IonCol className='center_col' size='1'>
                                                        <div >
                                                            <IonIcon onClick={() => views()} className="point back" src={arrowBack} size="large" />
                                                        </div></IonCol>
                                                    <IonCol size='10' className='center_col'>
                                                        <div>
                                                            <IonText className='select_text'> Select Area</IonText>
                                                        </div></IonCol>
                                                </IonRow>
                                                <IonRow className='justify-ion-text-center'>
                                                    {/* <IonCol size-xs="12" size-sm="6" size-lg="6" size-md="6">
                                                    <IonLabel className='textsize'> Deity</IonLabel>
                                                </IonCol> */}
                                                    <IonCol className='inputcol' size-xs="12" size-sm="12" size-lg="12" size-md="12">
                                                        <div className='center_col'>
                                                            <IonText className='deity_text_search'>{deityName}</IonText>

                                                        </div>

                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='To'>
                                                    <IonCol size='12'>
                                                        <IonLabel className='search_label'> Country</IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='Too'>
                                                    <IonCol size='12'>
                                                        <Select
                                                            className={stateMenu ? "custom-select" : "custom-select-dark"}
                                                            options={countryLiistOptions}
                                                            value={countryOption}
                                                            placeholder="Select a country"
                                                            isClearable
                                                            styles={customStyles}
                                                            onChange={handleCountryChange}
                                                        />
                                                        {formErrors.country && <span className="alert_input_bottom ">{formErrors.country}</span>}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='To'>
                                                    <IonCol size="12" >
                                                        <IonLabel className='search_label'>State</IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='Too'>
                                                    <IonCol size='12'>
                                                        <Select
                                                            className={stateMenu ? "custom-select" : "custom-select-dark"}
                                                            options={stateLiistOptions}
                                                            value={stateOption}
                                                            placeholder="Select a State"
                                                            isClearable
                                                            styles={customStyles}
                                                            onChange={handleStateChange}
                                                            menuIsOpen={stateMenu}
                                                            onMenuClose={() => setStateMenu(false)}
                                                            onMenuOpen={() => setStateMenu(true)}
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='To'>
                                                    <IonCol size='12'>
                                                        <IonLabel className='search_label'>District</IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='Too'>
                                                    <IonCol>
                                                        <Select
                                                            className={districtMenu ? "custom-select" : "custom-select-dark"}
                                                            options={districtLiistOptions}
                                                            value={districtOption}
                                                            placeholder="Select a District"
                                                            isClearable
                                                            styles={customStyles}
                                                            onChange={handleDistrictChange}
                                                            menuIsOpen={districtMenu}
                                                            onMenuClose={() => setDistrictMenu(false)}
                                                            onMenuOpen={() => setDistrictMenu(true)}
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='To'>
                                                    <IonCol>
                                                        <IonLabel className='search_label'>Village / Town</IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='Too'>
                                                    <IonCol>
                                                        <IonInput className='search_text' value={town} onIonChange={(e) => setTown(e.target.value)} />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow style={{ display: "flex", justifyContent: "center" }}>
                                                    <div className="center"><p>
                                                        or</p></div>
                                                </IonRow>
                                                <IonRow className='To'>
                                                    <IonCol>
                                                        <IonLabel className='search_label'>Enter Temple Name </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='Too'>
                                                    <IonCol >
                                                        <IonInput className='search_text' value={temName} onIonChange={(e) => setTemName(e.target.value)} />
                                                    </IonCol>
                                                </IonRow>

                                                <div className='center_col padding_search'>
                                                    <div >
                                                        <IonButton className={Capacitor.getPlatform() === "ios" ? 'clear_all border_change_clear' : 'clear_all'} onClick={Clear}>
                                                            <span className='button_padding'> Clear All</span>

                                                        </IonButton>
                                                    </div>
                                                    <div>
                                                        <IonButton className='search_btn' type="submit">
                                                            <span className='button_padding'>Search</span>

                                                        </IonButton>
                                                    </div>
                                                </div>
                                            </form>
                                        </IonCol>
                                        {hideContent === true ? <></> :
                                            <IonCol ref={targetRef} className='oo' size='12' sizeLg='6.5'>
                                                <IonRow>
                                                    <div style={{ textAlign: "center", width: "100%", marginTop: "1%" }}>
                                                        <IonText className='deity_text_search'>Temple Name</IonText>
                                                    </div>
                                                </IonRow>
                                                <div className='temple_list_container'>
                                                    <div className='templenamescroll'>

                                                        {showgod ?
                                                            <>
                                                                {gods.length == 0 ?
                                                                    <div className='no_result'>
                                                                        <IonLabel className='before_search_text'>Not a serviceable area</IonLabel>
                                                                    </div> : gods.length != 0 ?
                                                                        <>
                                                                         <div style={{color:"red",textAlign:"center",paddingBottom:"10px",fontSize:"14px"}}>
                              <span >* Please click on one of the temples displayed in the list to proceed</span>

                              </div>
                                                                            {
                                                                                gods.map((item: any, i: any) => (
                                                                                    <div className='temple_list' onClick={() => show(item.id)}>
                                                                                        <IonRow >
                                                                                            <IonCol size='2' >
                                                                                                <div className='multi-color-dot'></div>

                                                                                            </IonCol>
                                                                                            <IonCol size='10' >
                                                                                                <IonLabel
                                                                                                >{item.name}
                                                                                                </IonLabel>
                                                                                            </IonCol>


                                                                                        </IonRow></div>
                                                                                ))
                                                                            }
                                                                        </> : <></>
                                                                }
                                                            </> :
                                                            <div className='temple_bg'>
                                                                <div className='temple_budha_bg'>
                                                                </div>
                                                                <div className='search_display'>
                                                                    <IonLabel className='before_search_text'>Search Your Temple</IonLabel>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </IonCol>}
                                    </IonRow>
                                </IonGrid>
                            </div> : index1 === 3 ?
                                <div className='temple_container'>
                                    <div>
                                        <IonGrid style={{ padding: '0px' }}>

                                            <IonRow>

                                                <IonCol size-lg="3.5" size-md="3.5" size-sm="12" size-xs="12" className='menu_show'>
                                                    <IonRow>
                                                        <IonCol size-lg="5" size-xs='4' className='temple_back' >
                                                            <IonIcon onClick={() => back1()} className='back' src={arrowBack} size="large"></IonIcon>
                                                        </IonCol>
                                                    </IonRow>
                                                    <div className='temple_menu' >

                                                        <div>
                                                            <div>
                                                                <IonImg className='img_class' src={deityImg + "?v=" + new Date()}>

                                                                </IonImg>
                                                                <IonLabel className='deity_style'>{deityName}</IonLabel>
                                                            </div>
                                                        </div>
                                                        <div className={index == 0 ? "selected-btn" : "menu-btn"} id="0" onClick={getBtnId}>
                                                            <IonLabel id="0" onClick={getBtnId} >
                                                                About
                                                            </IonLabel>
                                                        </div>
                                                        <div className={index == 1 ? "selected-btn" : "menu-btn"} id="1" onClick={getBtnId}>
                                                            <IonLabel id="1" onClick={getBtnId} >
                                                                Facilities
                                                            </IonLabel>
                                                        </div>
                                                        <div className={index == 2 ? "selected-btn" : "menu-btn"} id="2" onClick={getBtnId}>
                                                            <IonLabel id="2" onClick={getBtnId}>
                                                                Offerings & Significance
                                                            </IonLabel>
                                                        </div>
                                                        <div className={index == 3 ? "selected-btn" : "menu-btn"} id="3" onClick={getBtnId}>
                                                            <IonLabel id="3" onClick={getBtnId}>
                                                                Festivals
                                                            </IonLabel>
                                                        </div>
                                                        <div className={index == 4 ? "selected-btn" : "menu-btn"} id="4" onClick={getBtnId}>
                                                            <IonLabel id="4" onClick={getBtnId}>
                                                                Pooja List
                                                            </IonLabel>
                                                        </div>
                                                        <div className={index == 5 ? "selected-btn" : "menu-btn"} id="5" onClick={getBtnId}>
                                                            <IonLabel id="5" onClick={getBtnId}>
                                                            Aerial View
                                                            </IonLabel>
                                                        </div>
                                                        <div className={index == 6 ? "selected-btn" : "menu-btn"} id="6" onClick={getBtnId}>
                                                            <IonLabel id="6" onClick={getBtnId}>
                                                                Schedule & Timings
                                                            </IonLabel>
                                                        </div>
                                                        <div className={index == 7 ? "selected-btn" : "menu-btn"} id="7" onClick={getBtnId} style={{ borderBottom: "none" }}>
                                                            <IonLabel id="7" onClick={getBtnId}>
                                                                Gallery
                                                            </IonLabel>
                                                        </div></div>
                                                </IonCol>
                                                <IonCol size-lg="8.5" size-md="8.5" size-sm="12" size-xs="12" className="menu-col details-col">
                                                    <IonRow>
                                                        <IonCol size-lg="1" size-md="1" size-sm="2" size-xs="2" className="menu_back center_col">
                                                            <IonIcon onClick={() => back1()} className='back' src={arrowBack} size="large"></IonIcon>
                                                        </IonCol>

                                                        <IonCol size-md="12" size-sm="8" size-xs="8" size-lg="12" >
                                                            <div className="grid"> <IonText className='header_style'>{aerial.name}</IonText></div>

                                                        </IonCol>
                                                        <IonCol size-lg="1" size-md="1" size-sm="2" size-xs="2" className="menu_pop center_col">

                                                            <IonIcon
                                                                className="menu_pop"
                                                                src={ellipsisVerticalCircleOutline}
                                                                size="large"
                                                                style={{ cursor: "pointer" }}
                                                                id="menu_content"
                                                                color="primary"
                                                            ></IonIcon>
                                                            <IonPopover
                                                                trigger="menu_content"
                                                                dismissOnSelect={true}
                                                                show-backdrop={false}
                                                            >
                                                                <IonContent style={{ background: "white" }} scrollEvents={true}>
                                                                    <IonList className='templeTabBtnMainCls'>
                                                                        <div className={index == 0 ? "selected-btn" : "menu-btn"} id="0" onClick={getBtnId}>
                                                                            <IonLabel id="0" onClick={getBtnId} >
                                                                                About
                                                                            </IonLabel>
                                                                        </div>
                                                                        <div className={index == 1 ? "selected-btn" : "menu-btn"} id="1" onClick={getBtnId}>
                                                                            <IonLabel id="1" onClick={getBtnId}>
                                                                                Facilities
                                                                            </IonLabel>
                                                                        </div>
                                                                        <div className={index == 2 ? "selected-btn" : "menu-btn"} id="2" onClick={getBtnId}>
                                                                            <IonLabel id="2" onClick={getBtnId}>
                                                                                Offerings & Significance
                                                                            </IonLabel>
                                                                        </div>
                                                                        <div className={index == 3 ? "selected-btn" : "menu-btn"} id="3" onClick={getBtnId}>
                                                                            <IonLabel id="3" onClick={getBtnId}>
                                                                                Festivals
                                                                            </IonLabel>
                                                                        </div>
                                                                        <div className={index == 4 ? "selected-btn" : "menu-btn"} id="4" onClick={getBtnId}>
                                                                            <IonLabel id="4" onClick={getBtnId}>
                                                                                Pooja List
                                                                            </IonLabel>
                                                                        </div>
                                                                        <div className={index == 5 ? "selected-btn" : "menu-btn"} id="5" onClick={getBtnId}>
                                                                            <IonLabel id="5" onClick={getBtnId}>
                                                                            Aerial View
                                                                            </IonLabel>
                                                                        </div>
                                                                        
                                                                        <div className={index == 6 ? "selected-btn" : "menu-btn"} id="6" onClick={getBtnId} >
                                                                            <IonLabel id="6" onClick={getBtnId}>
                                                                                Schedule & Timings
                                                                            </IonLabel>
                                                                        </div>
                                                                        <div className={index == 7 ? "selected-btn" : "menu-btn"} id="7" onClick={getBtnId} style={{ borderBottom: "none" }}>
                                                                            <IonLabel id="7" onClick={getBtnId}>
                                                                                Gallery
                                                                            </IonLabel>
                                                                        </div>
                                                                    </IonList>
                                                                </IonContent>
                                                            </IonPopover>

                                                        </IonCol>


                                                    </IonRow>
                                                    {index == 0 ? <div>
                                                        <div className="grid">
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonCol size-lg="12" size-md="12" size-xs="12">
                                                                        {gallery.length > 0 ? <div>
                                                                            <img src={gallery[0]} className='rightImage templeImage' />
                                                                        </div> : <></>}
                                                                        <p className='history_style'>{aerial.aboutAndHistory}</p>

                                                                    </IonCol>
                                                                    {/* <IonCol size-lg="3" size-md="4" size-xs="5"> */}

                                                                    {/* </IonCol> */}
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                    </div> : index == 1 ? <div>
                                                        <div className='facility_container'>
                                                            <IonGrid className="grid">
                                                                <IonRow>
                                                                    <IonCol size-lg="12" size-md="12" size-xs="12">
                                                                        <IonGrid>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Parking Lot
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    {facilities.parkingLot === "" ?
                                                                                        <IonText className='facility_text'>
                                                                                            N/A
                                                                                        </IonText>
                                                                                        : <IonText className='facility_text'>
                                                                                            {facilities.parkingLot}
                                                                                        </IonText>}

                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Security
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    {facilities.security === "" ?
                                                                                        <IonText className='facility_text'>
                                                                                            N/A
                                                                                        </IonText>
                                                                                        :
                                                                                        <IonText className='facility_text'>
                                                                                            {facilities.security}
                                                                                        </IonText>}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Restrooms
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    {facilities.restRooms === "" ?
                                                                                        <IonText className='facility_text'>
                                                                                            N/A
                                                                                        </IonText>
                                                                                        :
                                                                                        <IonText className='facility_text'>
                                                                                            {facilities.restRooms}
                                                                                        </IonText>}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Cloak Counters
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    {facilities.cloakCounters === "" ?
                                                                                        <IonText className='facility_text'>
                                                                                            N/A
                                                                                        </IonText>
                                                                                        :
                                                                                        <IonText className='facility_text'>
                                                                                            {facilities.cloakCounters}
                                                                                        </IonText>}
                                                                                </IonCol>
                                                                            </IonRow>

                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Pooja Provisions
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    {facilities.poojaProvisions === "" ?
                                                                                        <IonText className='facility_text'>
                                                                                            N/A
                                                                                        </IonText>
                                                                                        :
                                                                                        <IonText className='facility_text'>
                                                                                            {facilities.poojaProvisions}
                                                                                        </IonText>}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Shop
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    {shop.length === 0 ?
                                                                                        <IonText className='facility_text'>
                                                                                            N/A
                                                                                        </IonText>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                shop.map((item: any, i: any) => {
                                                                                                    console.log(item)
                                                                                                    return (
                                                                                                        <>

                                                                                                            <IonText className='facility_text' id={i}>
                                                                                                                {item} {i != (shop.length - 1) && <>,</>}
                                                                                                            </IonText>
                                                                                                        </>
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </>}
                                                                                </IonCol>


                                                                            </IonRow>


                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Cab / Auto Availability
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    {facilities.cabAvailablity === "" ?
                                                                                        <IonText className='facility_text'>
                                                                                            N/A
                                                                                        </IonText>
                                                                                        :
                                                                                        <IonText className='facility_text'>
                                                                                            {facilities.cabAvailablity}
                                                                                        </IonText>}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Auditorium
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    {facilities.auditorium === "" ?
                                                                                        <IonText className='facility_text'>
                                                                                            N/A
                                                                                        </IonText>
                                                                                        :
                                                                                        <IonText className='facility_text'>
                                                                                            {facilities.auditorium}
                                                                                        </IonText>}
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Car
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    <IonText className='facility_text'>
                                                                                        {car}
                                                                                    </IonText>

                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Bus
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    <IonText className='facility_text'>
                                                                                        {bus}
                                                                                    </IonText>

                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Train
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    <IonText className='facility_text'>
                                                                                        {train}
                                                                                    </IonText>

                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className="remove-border">
                                                                                <IonCol size="2" className='tick_container' >
                                                                                    <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                                    </IonIcon>
                                                                                </IonCol>
                                                                                <IonCol size="5.5"  >
                                                                                    <IonText className='facility_text'>
                                                                                        Flight
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size='0.5'>
                                                                                    <IonText className='facility_text'>
                                                                                        :
                                                                                    </IonText>
                                                                                </IonCol>
                                                                                <IonCol size="4" >
                                                                                    <IonText className='facility_text'>
                                                                                        {flight}
                                                                                    </IonText>

                                                                                </IonCol>
                                                                            </IonRow>



                                                                        </IonGrid>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                    </div> : index == 2 ? <div>
                                                        <IonGrid className="grid">
                                                            {nodata == true ? <div style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center" }}>
                                                                <IonText style={{ display: "flex", alignItems: "center", color: "black" }}>No Data Available</IonText>

                                                            </div> :
                                                                <div>
                                                                    {Offerings.map((item: any) => (
                                                                        <div>
                                                                            {item.offeringName == "" || null && item.significance == "" || null ?
                                                                                <div>
                                                                                </div> : <div>
                                                                                    <IonRow>
                                                                                        <IonText className='offering_head'>{item.offeringName} :</IonText>
                                                                                    </IonRow>
                                                                                    <IonRow >
                                                                                        <p className='offering_style'>&emsp; &emsp; &emsp;{item.significance}</p>
                                                                                    </IonRow>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    ))}</div>}
                                                        </IonGrid>
                                                    </div> : index == 3 ? <div>
                                                        <IonGrid className="grid">
                                                            {nodataFest == true ? <div style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center" }}>
                                                                <IonText style={{ display: "flex", alignItems: "center", color: "black" }}>No Data Available</IonText>

                                                            </div> :
                                                                <div>
                                                                    {festival.map((item: any) => (
                                                                        <div>
                                                                            {item.name == "" || null && item.description == "" || null ? <div></div> :
                                                                                <div>
                                                                                    <IonRow>
                                                                                        <IonText className='offering_head'>{item.name} :</IonText>
                                                                                    </IonRow>
                                                                                    <IonRow>
                                                                                        <p className='offering_style'>&emsp; &emsp; &emsp; {item.description}</p>
                                                                                    </IonRow>
                                                                                </div>}
                                                                        </div>
                                                                    ))}</div>}

                                                        </IonGrid>
                                                    </div> : index == 4 ? <div>
                                                        <IonGrid className="grid">
                                                            <IonRow>
                                                                <IonText className='offering_head'>Pooja List</IonText>
                                                            </IonRow>
                                                            <IonRow className='pooja_tab'>
                                                                <IonCol className="table_column1">
                                                                    <IonGrid className='table_container'>

                                                                        <IonRow className="row_head">
                                                                            <IonCol size='1' className="head-border">
                                                                                S.no
                                                                            </IonCol>
                                                                            <IonCol size='3.5' className="head-border">
                                                                                Name of Vazhipadu
                                                                            </IonCol>
                                                                            <IonCol size='4' className="head-border">
                                                                                Description
                                                                            </IonCol>
                                                                            <IonCol size='1.5' className="head-border">
                                                                                Rate
                                                                            </IonCol>
                                                                            <IonCol size='2' className="head-border">
                                                                                Deity
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        {
                                                                            poojaoffer.map((item: any, i: any) => (
                                                                                <IonRow className="row_col">
                                                                                    <IonCol size='1' className="row_first col_border">{i + 1}</IonCol>
                                                                                    <IonCol size='3.5' className="col_border">{item.name}</IonCol>
                                                                                    <IonCol size='4' className="col_border">{item.description}</IonCol>
                                                                                    <IonCol size='1.5' className="col_border">&#8377; {item.price}</IonCol>
                                                                                    <IonCol size='2' className='col_last'>{item.deity.deityName}</IonCol>
                                                                                </IonRow>
                                                                            ))}


                                                                    </IonGrid>
                                                                </IonCol>
                                                            </IonRow>
                                                            <div className='menu_back'>
                                                                {
                                                                    poojaoffer.map((item: any, i: any) => (
                                                                        <IonCard className='pooja_card'>
                                                                            <IonRow>
                                                                                <IonCol className="pooja_col" size='5'>
                                                                                    {i + 1}. Vazhipadu
                                                                                </IonCol>
                                                                                <IonCol className="pooja_col" size='0.5' >:</IonCol>
                                                                                <IonCol className="pooja_col" size='6.5'>
                                                                                    {item.name}
                                                                                </IonCol>


                                                                            </IonRow>
                                                                            <IonRow>
                                                                                <IonCol className="pooja_col" size='5'>
                                                                                    Description
                                                                                </IonCol>
                                                                                <IonCol className="pooja_col" size='0.5' >:</IonCol>
                                                                                <IonCol className="pooja_col" size='6.5'>
                                                                                    {item.description}
                                                                                </IonCol>


                                                                            </IonRow>
                                                                            <IonRow>
                                                                                <IonCol size='5' className="pooja_col">
                                                                                    Rate
                                                                                </IonCol>
                                                                                <IonCol size='0.5' className="pooja_col">:</IonCol>
                                                                                <IonCol size='6.5' className="pooja_col">
                                                                                    &#8377; {item.price}
                                                                                </IonCol>


                                                                            </IonRow>
                                                                            <IonRow>
                                                                                <IonCol size='5' className="pooja_col">
                                                                                    Deity
                                                                                </IonCol>
                                                                                <IonCol size='0.5' className="pooja_col">:</IonCol>
                                                                                <IonCol size='6.5' className="pooja_col">
                                                                                    {item.deity.deityName}
                                                                                </IonCol>


                                                                            </IonRow>
                                                                        </IonCard>))}
                                                            </div>

                                                        </IonGrid>
                                                    </div> : index == 5 ? <div>
                                                        <div>
                                                            <IonGrid className="grid">
                                                                <IonRow>
                                                                    <IonCol size-lg="12" size-md="12" size-xs="12" size-sm="12">
                                                                        {/* <p>
                                                                {aerial.aerialView}
                                                            </p> */}
                                                                         <div className='parent'>
                                                            {window.google == undefined ? <LoadScript
                                                                googleMapsApiKey={UserService.mapKey()}
                                                            >
                                                                <GoogleMap
                                                                    mapContainerClassName="containerStyle"
                                                                    center={center}
                                                                    zoom={15} options={mapOption}
                                                                >
                                                                    <MarkerF position={center} />
                                                                </GoogleMap>
                                                            </LoadScript> : <GoogleMap
                                                                mapContainerClassName="containerStyle"
                                                                center={center}
                                                                zoom={15} options={mapOption}
                                                            >
                                                                <MarkerF position={center} />
                                                            </GoogleMap>}
                                                            <div className='child'>
                                                                <IonButton className='map_share' onClick={openMap}>Share
                                                                    {/* <IonImg className='share_icon' src={shareIcon}></IonImg> */}
                                                                    <IonIcon src={shareSocialSharp} className='share_icon' />
                                                                </IonButton>
                                                            </div>

                                                        </div>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </div>
                                                    </div> : index == 6 ? <div>
                                                        <div style={{ padding: "2%" }} className="grid">

                                                            <IonRow>
                                                                <IonText className='offering_head'>Schedule & Timings</IonText>
                                                            </IonRow>

                                                            <div className='schedule_container'>
                                                                <IonGrid>
                                                                    <IonRow className="remove-border">
                                                                        <IonCol size="1" className='tick_container' >
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                            </IonIcon>
                                                                        </IonCol>
                                                                        <IonCol size="4"  >
                                                                            <IonText className='facility_text'>
                                                                                Morning Darsan
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size='0.5'>
                                                                            <IonText className='facility_text'>
                                                                                :
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size="6.5" >
                                                                            <IonText className='facility_text'>
                                                                                {morning}
                                                                            </IonText>

                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow className="remove-border">
                                                                        <IonCol size="1" className='tick_container' >
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                            </IonIcon>
                                                                        </IonCol>
                                                                        <IonCol size="4"  >
                                                                            <IonText className='facility_text'>
                                                                                Evening Darsan
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size='0.5'>
                                                                            <IonText className='facility_text'>
                                                                                :
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size="6.5" >
                                                                            <IonText className='facility_text'>
                                                                                {evening}
                                                                            </IonText>

                                                                        </IonCol>
                                                                    </IonRow>

                                                                    <IonRow className="remove-border">
                                                                        <IonCol size="1" className='tick_container' >
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                            </IonIcon>
                                                                        </IonCol>
                                                                        <IonCol size="4"  >
                                                                            <IonText className='facility_text'>
                                                                                Time Required
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size='0.5'>
                                                                            <IonText className='facility_text'>
                                                                                :
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size="6.5" >
                                                                            <IonText className='facility_text'>
                                                                                {timerequ}
                                                                            </IonText>

                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow className="remove-border">
                                                                        <IonCol size="1" className='tick_container' >
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                            </IonIcon>
                                                                        </IonCol>
                                                                        <IonCol size="4"  >
                                                                            <IonText className='facility_text'>
                                                                                Closing Timings
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size='0.5'>
                                                                            <IonText className='facility_text'>
                                                                                :
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size="6.5" >
                                                                            <IonText className='facility_text'>
                                                                                {closetime}
                                                                            </IonText>

                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow className="remove-border">
                                                                        <IonCol size="1" className='tick_container' >
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                            </IonIcon>
                                                                        </IonCol>
                                                                        <IonCol size="4"  >
                                                                            <IonText className='facility_text'>
                                                                                Fees Details
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size='0.5'>
                                                                            <IonText className='facility_text'>
                                                                                :
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size="6.5" >
                                                                            <IonText className='facility_text'>
                                                                                {entry}
                                                                            </IonText>

                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow className="remove-border">
                                                                        <IonCol size="1" className='tick_container' >
                                                                            <IonIcon className='tick' src={checkmarkCircleSharp} >

                                                                            </IonIcon>
                                                                        </IonCol>
                                                                        <IonCol size="4"  >
                                                                            <IonText className='facility_text'>
                                                                                Dress Code
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size='0.5'>
                                                                            <IonText className='facility_text'>
                                                                                :
                                                                            </IonText>
                                                                        </IonCol>
                                                                        <IonCol size="6.5" >
                                                                            <IonText className='facility_text'>
                                                                                Men - {dressme}
                                                                            </IonText><br />
                                                                            <IonText className='facility_text'>
                                                                                Women - {dresswo}
                                                                            </IonText>

                                                                        </IonCol>
                                                                    </IonRow>



                                                                    <IonRow>
                                                                        <IonCol size-lg="3" size-md="3" size="5">
                                                                            <IonLabel style={{ fontSize: "14px", fontWeight: "bold" }}></IonLabel>
                                                                        </IonCol>
                                                                        <IonCol size-lg="9" size-md="9" size="7">
                                                                            <IonText></IonText>
                                                                            <IonText></IonText>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </IonGrid>
                                                            </div>
                                                        </div>
                                                    </div> : index == 7 ? <div>
                                                        <div className="grid">
                                                            <IonGrid>
                                                                <IonRow className="ion-justify-content-center">
                                                                    {gallery.map((item: any, i: any) => {
                                                                        return (
                                                                            <IonCol size-lg="4" size-md="4" size-xs="12">
                                                                                <IonCard className='img-card'>
                                                                                    <IonImg src={item} style={{ cursor: "pointer" }} onClick={() => openModalAndSetIndex(i)} className="img" tabIndex={1}
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === 'Enter') {
                                                                                                openModalAndSetIndex(i)
                                                                                            }
                                                                                        }} />
                                                                                </IonCard>
                                                                            </IonCol>
                                                                        )
                                                                    })}
                                                                    {showModal && (
                                                                        <Lightbox
                                                                            mainSrc={gallery[indexOfImages]}
                                                                            nextSrc={gallery.length > 1 ? gallery[(indexOfImages + 1) % gallery.length] : undefined}
                                                                            prevSrc={
                                                                                gallery.length > 1 ? gallery[
                                                                                    (indexOfImages + gallery.length - 1) % gallery.length
                                                                                ] : undefined
                                                                            }
                                                                            onCloseRequest={() => setShowModal(false)}
                                                                            onImageLoad={() => {
                                                                                window.dispatchEvent(new Event('resize'));
                                                                            }}
                                                                            onMovePrevRequest={() =>
                                                                                setIndexOfImages(
                                                                                    (indexOfImages + gallery.length - 1) % gallery.length
                                                                                )
                                                                            }
                                                                            onMoveNextRequest={() =>
                                                                                setIndexOfImages(
                                                                                    (indexOfImages + gallery.length + 1) % gallery.length
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </IonRow>

                                                                <IonRow style={{ paddingTop: "25px" }}>
                                                                    <IonText className='offering_head'>Video Link</IonText>
                                                                </IonRow>
                                                                <div>
                                                                    {videolinkshow === true ? <div style={{ marginTop: "2%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                        <IonText style={{ color: "black" }}>
                                                                            No Data Available
                                                                        </IonText>
                                                                    </div> : <div>
                                                                        {videolink.map((item: any) => {
                                                                            console.log(item)
                                                                            return (<>
                                                                                {item.videoName.length > 0 ? <>
                                                                                    <div className='video_link'>
                                                                                        <IonText >{item.videoName}</IonText>
                                                                                    </div>
                                                                                    <div className="live_link">

                                                                                        <a style={{ color: '#13ACFF' }} href={item.videoLink} target='_blank'>{item.videoLink}</a>

                                                                                    </div>

                                                                                </> : <>
                                                                                </>}
                                                                            </>
                                                                            )
                                                                        }
                                                                        )}</div>}
                                                                </div>
                                                                <div>
                                                                    <IonRow style={{ paddingTop: "40px" }}>
                                                                        <IonText className='offering_head'>Live Video Link</IonText>
                                                                    </IonRow>
                                                                    {livevideolinkshow === true ? <div style={{ marginTop: "2%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                        <IonText style={{ color: "black" }}>
                                                                            No Data Available
                                                                        </IonText></div> : <div>
                                                                        {videolivelink.map((item: any) => {
                                                                            console.log(item)
                                                                            return (<>
                                                                                {item.videoName.length > 0 ? <>
                                                                                    <div className='video_link'>

                                                                                        <IonText >{item.videoName}</IonText>
                                                                                    </div>
                                                                                    <div className='live_link'>
                                                                                        <a style={{ color: '#13ACFF' }} href={item.videoLink} target='_blank'>{item.videoLink}</a>
                                                                                    </div>
                                                                                </> : <>
                                                                                </>}
                                                                            </>
                                                                            )
                                                                        }
                                                                        )}</div>}
                                                                </div>
                                                            </IonGrid>
                                                        </div>
                                                    </div> : <div></div>}
                                                </IonCol>
                                            </IonRow>
                                            {index == 0 &&
                                                <IonRow>
                                                    <IonCol size-lg="12" size-md="12" size-xs="12" size-sm="12" style={{ padding: "0px" }}>
                                                        {/* <p>
                                                               {aerial.aerialView}
                                                           </p> */}

                                                        <div className='parent'>
                                                            {window.google == undefined ? <LoadScript
                                                                googleMapsApiKey={UserService.mapKey()}
                                                            >
                                                                <GoogleMap
                                                                    mapContainerClassName="containerStyle"
                                                                    center={center}
                                                                    zoom={15} options={mapOption}
                                                                >
                                                                    <MarkerF position={center} />
                                                                </GoogleMap>
                                                            </LoadScript> : <GoogleMap
                                                                mapContainerClassName="containerStyle"
                                                                center={center}
                                                                zoom={15} options={mapOption}
                                                            >
                                                                <MarkerF position={center} />
                                                            </GoogleMap>}
                                                            <div className='child'>
                                                                <IonButton className='map_share' onClick={openMap}>Share
                                                                    {/* <IonImg className='share_icon' src={shareIcon}></IonImg> */}
                                                                    <IonIcon src={shareSocialSharp} className='share_icon' />
                                                                </IonButton>
                                                            </div>

                                                        </div>


                                                    </IonCol>
                                                </IonRow>}
                                        </IonGrid>
                                    </div>
                                </div> :
                                <></>}</div>
                <Foot />
            </IonContent>
        </IonPage>
    )
}
export default Temples;

