import React, { useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonItemDivider,
  IonRow,
  IonInput,
  IonLabel,
  IonItem,
  IonCol,
  IonLoading,
  IonText
} from "@ionic/react";
import axios from "axios";
import { useForm } from "react-hook-form";
import UserHead from "./UserHead";
import Foot from "./Foot";
import Authservice from "../Services/auth.service";
import { useHistory } from "react-router";

const Forgot: React.FC = () => {
  const {
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const history = useHistory();
  const userdata: any = useState(localStorage.getItem("UserData"));
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage]: any = useState<string>('');
  const inputRef = useRef<HTMLIonInputElement>(null);
  const errorMessageRef = useRef<HTMLIonTextElement>(null);

  const Submit = () => {
    setShowLoading(true);
    Authservice.Forgotpassword(email).then((response) => {

      if (response.data.startsWith("SUCCESS")) {
        console.log(response.data)
        var res = response.data.split('SUCCESS:')[1];
        setMsg("Reset password link sent to your Email Id!")

        setShowLoading(false);

      } else {
        setShowLoading(false);
        setMsg("Account Not Found")
      }

    })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
      });



  };
  const handleBlur = () => {
    const inputValue: any = inputRef.current?.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(inputValue === ""){
      setErrorMessage('Please enter a email address');
      errorMessageRef.current?.setAttribute('aria-errormessage', 'email-error');
    }
    else if (!emailPattern.test(inputValue!)) {
      setErrorMessage('Please enter a valid email address');
      errorMessageRef.current?.setAttribute('aria-errormessage', 'email-error');
    } else {
      setErrorMessage('');
      errorMessageRef.current?.removeAttribute('aria-errormessage');
    }
  };
  return (
    <IonPage>
      <IonContent>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
          onDidDismiss={() => {
            setShowLoading(false)
            alert(msg)
            history.push("login")
          }
          }

          duration={5000}
        />
        {/* <UserHead User="forgot" Role={userdata[0]} /> */}
        <div className="container_middle">
          <h4>Forgot Password</h4>
          <form className="top" onSubmit={handleSubmit(Submit)}>
            <div>
              <IonRow className="row_bot">
                <IonCol size="11" size-lg="7">
                  <IonItem className="select_user border border rounded">
                    <IonLabel position="floating" className="label_align">Email</IonLabel>
                    <IonInput
                      type="email"
                      value={email}
                      required
                      onIonChange={(e) => setEmail(e.detail.value!)}
                      ref={inputRef}
                      onBlur={handleBlur}
                      aria-errormessage="email-error"
                    ></IonInput>
                  </IonItem>
                  {errorMessage && <span className="alert_input_bottom " ref={errorMessageRef} id="email-error" color="danger">{errorMessage}</span>}
                </IonCol>
              </IonRow>

              <IonRow className="row_bot">
                <IonCol size="11" size-lg="7" style={{ textAlign: 'left' }}>
                  <IonButton
                    className="but_pass"
                    type="submit"
                    disabled={email.length === 0}
                  >
                    Submit
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </form>
        </div>
        <IonItemDivider className="footer_login"></IonItemDivider>
        <Foot />
      </IonContent>
    </IonPage>
  );
};
export default Forgot;
