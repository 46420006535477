import {
    IonCol,
    IonContent,
    IonIcon,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonText,
    IonGrid,
    IonInput,
    IonImg,
    IonLoading,
    IonSlide,
    IonSlides,
    useIonAlert,
    IonPopover,
    IonList,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
} from "@ionic/react";
import React, { useMemo, useRef } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import Authservice from "../../Services/user.service";
import UserService from "../../Services/auth.service";
import Foot from "../Foot";
import Head from "../Head";
import { Modal } from 'react-bootstrap';
import { addCircleSharp, addOutline, arrowBack, ellipsisVerticalCircleOutline, filter } from "ionicons/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { Capacitor } from "@capacitor/core";
import TempleAdmDet from "./TempDetails";
import Addtemp from "./addtemple";
import Select, { components } from 'react-select';
import { stat } from "fs";
import { error } from "console";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";

const Admtemple: React.FC = () => {
    const [presentAlert] = useIonAlert();
    const [comment, setComment] = useState("");
    const [tempAdm, setTempAdm]: any = useState();
    const [name, setName]: any = useState()
    const [tempAdmList, setTempAdmList]: any = useState([]);
    const dataFetchedRef = useRef(false);
    const [headName, setHeadName] = useState("")

    const [isSearch, setIsSearch] = useState(false)

    const columns: any = [
        {
            name: "S.No",
            selector: (item: any, i: any) => item.serial,
            sortable: true,
            width: "90px",
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => onclick(item.templesDto.id)}>
                    <div style={{ cursor: "pointer" }}>{item.serial}</div>
                </div>
            )
        },
        {
            name: "Temple Name",
            selector: (item: any) => item.templesDto.name,
            sortable: true,
            cell: (item: any) => (
                <div  className="left_col" onClick={() => onclick(item.templesDto.id)}>
                    <div style={{ cursor: "pointer" }} >{item.templesDto.name}</div>
                </div>
            )
        },
        {
            name: "Location",
            selector: (item: any) => item.templesDto.villageorTown + "," + item.templesDto.district,
            sortable: true,
            cell: (item: any) => (
                <div  className="left_col" onClick={() => onclick(item.templesDto.id)}>
                    <div style={{ cursor: "pointer" }} >{item.templesDto.villageorTown + "," + item.templesDto.district}</div>
                </div>
            )
        },
        // ,
        // {
        //     name: "Active Comment",
        //     selector: (item: any) => item.templesDto.activeComment,
        //     sortable: true,
        //     cell: (item: any) => (
        //         <div onClick={() => onclick(item.templesDto.id)}>
        //             <div className="textDes threelineCls" style={{ cursor: "pointer" }} >{item.templesDto.activeComment}</div>
        //         </div>
        //     )
        // },
        {
            name: "Comment",
            selector: (item: any) => item.templesDto.inactiveComment,
            sortable: true,
            cell: (item: any) => (
                <div  className="left_col" onClick={() => onclick(item.templesDto.id)}>
                    <div className="textDes threelineCls" style={{ cursor: "pointer" }} >{item.templesDto.inactiveComment}</div>
                </div>
            )
        },
        {
            name: "Status",
            sortable: false,
            selector: (item: any) => item.facilitiesDto.temples.activeFlag,
            cell: (item: any) => (
                <div className="center_col_tab">
                    {item.facilitiesDto.temples.activeFlag === "true" ? (
                        <IonButton color="danger" style={{ textTransform: "none" }}
                            className="datatable-activate-btn"
                            onClick={() =>
                                presentAlert({
                                    header: 'Atmagram',
                                    message: 'Do you want to deactivate this temple',
                                    buttons: [
                                        {
                                            text: 'No',
                                            role: 'cancel',
                                            handler: () => {
                                                // setHandlerMessage('Alert canceled');
                                            },
                                        },
                                        {
                                            text: 'Yes',
                                            role: 'confirm',
                                            handler: (alertData) => {
                                                handleStatus(false, item.templesDto.id, alertData.comment)
                                            },
                                        },
                                    ],
                                    inputs: [
                                        {
                                            name: 'comment',
                                            type: 'text',
                                            value: comment,
                                            placeholder: 'Enter your comment'
                                        }
                                    ]
                                })

                            }>
                            <span className="button_padding">
                                Deactivate
                            </span>
                        </IonButton>
                    ) : (
                        <IonButton color="success"  className="datatable-activate-btn"
                         style={{ textTransform: "none" }} onClick={() => presentAlert({
                            header: 'Atmagram',
                            message: 'Do you want to activate this temple',
                            buttons: [
                                {
                                    text: 'No',
                                    role: 'cancel',
                                    handler: () => {
                                        // setHandlerMessage('Alert canceled');
                                    },
                                },
                                {
                                    text: 'Yes',
                                    role: 'confirm',
                                    handler: (alertData) => {
                                        handleStatus(true, item.templesDto.id, alertData.comment)
                                    },
                                },
                            ],
                            inputs: [
                                {
                                    name: 'comment',
                                    type: 'text',
                                    value: comment,
                                    placeholder: 'Enter your comment'
                                }
                            ]
                        })}>
                            <span className="button_padding">
                                Activate
                            </span>
                        </IonButton>
                    )}
                </div>
            )
        },
        // {
        //     name: "View",
        //     sortable: false,
        //     cell: (item: any) => (
        //         <>
        //             <IonButton onClick={
        //                 // () => show(item.id)
        //                 () => onclick(item.templesDto.id)
        //             } style={{ textTransform: "none" }}>View</IonButton>
        //         </>
        //     )
        // },
    ]

    const [usertoken] = useState(localStorage.getItem("token"));
    const history = useHistory();
    const userdata: any = useState(localStorage.getItem("UserData"));
    const uFlag: any = useState(localStorage.getItem("uFlag"));
    const [templelist, setTemplelist]: any = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [index1, setIndex1] = useState(0);
    const [isedit, setIsedit] = useState(false);

    const [id, Setid] = useState("");


    const handleStatus = (flag: any, id: any, comm: any) => {
        Authservice.statusAdmtemp(usertoken, flag, id, comm).then((response) => {
            console.log(response)
            // window.location.reload()
            ShowAllTemple(tempAdm)
        })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
            });

    }

    const tempId: any = useRef("")
    const idBack: any = useRef("")
    const adminBack: any = useRef("")
    const nameBack: any = useRef("")
    const propState: any = useRef("")
    const propCity: any = useRef("")
    const propDistrict: any = useRef("")
    const tempName: any = useRef("")
    const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(null);
    const [tempprops, setTempprops]: any = useState({})
    const location = useLocation()
    const [backProps]: any = useState(location.state)
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (userdata[0] === null) {
            history.push("/login")
        } else if (userdata[0] !== "ROLE_ADMIN") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {
            setHeadName("Temple Management")
            console.log(backProps, "getttt")
            if (backProps !== undefined) {
                tempId.current = backProps.tempId;
                adminBack.current = backProps.admin;
                idBack.current = backProps.id
                nameBack.current = backProps.name;
                propState.current = backProps.state;
                propDistrict.current = backProps.district;
                if (!backProps.city) {
                    propCity.current = "";
                }
                else {
                    propCity.current = backProps.city
                }
                if (!backProps.templeName) {
                    tempName.current = "";
                }
                else {
                    tempName.current = backProps.templeName;
                }
                setTempprops(backProps)
            }
            getStates()
            console.log(backProps, "getprops")
            // showTempleAdmList();
            // console.log(props, "propsss"
        }
    }, [location])

    const onclick = (id: any) => {
        console.log(tempprops, "sendddddddd")
        Setid(id)
        history.push({
            pathname: "/editTemple",
            state: { tempId: tempId, admin: 2, id: id, name: nameBack, state: propState, city: propCity, district: propDistrict, templeName: tempName }
        })
    }

    const addtemple = () => {
        // setIndex1(2);
        console.log(tempId, id, nameBack, propState, propDistrict)
        history.push({
            pathname: "/addTemple",
            state: { tempId: tempId, admin: 2, id: idBack, name: nameBack, state: propState, city: propCity, district: propDistrict, templeName: tempName }
        })
    }


    const getStates = () => {
        setShowLoading(true)
        Authservice.getAllstates()
            .then((response) => {
                // console.log(response)
                // if (response.status === 200) {
                setStates(response.data)
                // setShowLoading(false)
                // }
                if (backProps === undefined) {
                    setTempAdm("")
                    // setSelectedOption({ value: "", label: "" });
                    setIsSearch(false)
                    ShowAllTemple("");
                }
                else {
                    setTempAdm(backProps.tempId)
                    console.log(tempId)
                    setSelectedOption({ value: backProps.tempId, label: backProps.name });
                    ShowAllTemple(backProps.tempId);
                    setIsSearch(true)
                    // var admin = {
                    //     value: props.tempId,
                    //     label: props.name
                    // }
                    // handleChange(admin)
                }
                if (backProps.state !== "" && backProps.district !== "") {
                    setStateOption({ value: backProps.state, label: backProps.state });
                    setState(backProps.propState)
                    setCity(backProps.city)
                    setName(backProps.name)
                    setDistrict(backProps.district)
                    setDistrictOption({ value: backProps.district, label: backProps.district });
                    setTempleName(backProps.templeName)
                    var data = {
                        state: backProps.state,
                        city: backProps.city,
                        district: backProps.district,
                        temname: backProps.templeName
                    }
                    handleSearch(data)
                }
            })
            .catch((error) => {
                console.log(error)
                // setShowLoading(false)
            })
            .finally(() => {
                setShowLoading(false);
            });
    }

    const showTempleAdmList = () => {
        setShowLoading(true);
        Authservice.templeAdmList(usertoken)
            .then((response) => {
                console.log(response.data);
                const list = response.data;
                setTempAdmList(list);
                // var list: any = response.data;
                // for (let i = 0; i < list.length; i++) {
                //     tempAdmList.push(list[i]);
                // }
                setShowLoading(false);
            })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false);
            });
    }

    const ShowAllTemple = (id: any) => {
        console.log(id, "ewdsd")
        var idCheck;
        if (id != "") {
            idCheck = id
            setIsSearch(true)
        }
        else {
            idCheck = tempAdm
            setIsSearch(false)
        }
        setTemplelist([])

        setShowLoading(true);
        Authservice.Viewtemple(usertoken, idCheck)
            .then((response) => {
                console.log(response.data);


                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].facilitiesDto !== null) {
                        // templelist.push(response.data[i]);
                        setTemplelist((templelist: any) => [...templelist, response.data[i]])
                    }
                }
                setShowLoading(false);

            })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false);
            });
    }
    const chooseTempAdms = (e: any) => {
        console.log(e, "jnj")
        // if (tempAdm === null || tempAdm === "" || tempAdm === undefined) {
        // alert("Please Select Temple Admin")
        // }
        // else {
        setTempAdm(e);

        ShowAllTemple(e);
        // }

    }

    const [index, setIndex]: any = useState(0);
    const [Id, setId] = useState("");
    const [facilities, setFacilities]: any = useState([]);
    const [aerial, setAerial]: any = useState([]);
    const [Offerings, setOfferings]: any = useState([]);
    const [festival, setFestival]: any = useState([]);
    const [poojaoffer, setpoojaoffer]: any = useState([]);
    const [timings, setTimings]: any = useState([]);
    const [gallery, setGallery]: any = useState([]);
    const [shop, setShopIn]: any = useState([]);
    const [acces, setAccIn]: any = useState([]);
    const [lat, setLat]: any = useState();
    const [long, setLong]: any = useState();
    const [morning, setMorning] = useState("");
    const [evening, setEvening] = useState("");
    const [timerequ, setTimerequ] = useState("");
    const [entry, setEntry] = useState("");
    const [dressme, setDressme] = useState("");
    const [dresswo, setDresswo] = useState("");
    const [videolink, setVideolink]: any = useState([])
    const [videolivelink, setVideoLivelink]: any = useState([])
    const [flag, setFlag] = useState(false)
    const [largeImg, setLargeImg]: any = useState("")
    const [nodata, setNodata] = useState(false)
    const [nodataFest, setNodataFest] = useState(false)
    const [closetime, setCloseTime]: any = useState("");
    const [showModal, setShowModal] = useState(false);
    const [indexOfImages, setIndexOfImages]: any = useState("");

    const mySlides: any = useRef(null);

    const back1 = () => {
        setIndex1(0);
        setIndex(0);
        setNodata(false)
        setNodataFest(false)
    }

    const getBtnId = (e: any) => {
        setIndex(e.target.id)
    };

    const containerStyle = {
        width: '700px',
        height: '400px'
    };

    var center = {
        lat: lat,
        lng: long,
    };

    const slideOpts = {
        initialSlide: 0,
        speed: 400
    };
    const openModalAndSetIndex = (index: any) => {
        setIndexOfImages(index);
        setShowModal(true);
    };
    const handleSlideChange = async () => {
        const swiper = await mySlides.current.getSwiper();
        console.log("Slide Index", swiper.activeIndex);
    };

    const onBtnClicked: any = async (direction: string) => {
        const swiper = await mySlides.current.getSwiper();
        if (direction === "next") {
            swiper.slideNext();
        } else if (direction === "prev") {
            swiper.slidePrev();
        }
    };



    const show = (id: any) => {
        setIndex1(1)
        setId(id)
        UserService.Templedetails(id)
            .then((response: any) => {
                console.log("Ressss=>", response)
                var long = parseFloat(response.data.templesDto.tempLong)
                setLong(long);
                var lat = parseFloat(response.data.templesDto.tempLat)
                setLat(lat);
                console.log(long, "long", lat, "lat")
                if (response.data.facilitiesDto === null) {
                    setFacilities("")
                } else {
                    setFacilities(response.data.facilitiesDto)
                }
                setAerial(response.data.templesDto)
                setOfferings(response.data.offeringsAndSignificanceDto);
                if (response.data.offeringsAndSignificanceDto.every((e: any) => (e.offeringName == "" && e.significance == ""))) {
                    setNodata(true)
                }
                setFestival(response.data.festivalsDto)
                if (response.data.festivalsDto.every((e: any) => (e.name == "" && e.description == ""))) {
                    setNodataFest(true)
                }
                setpoojaoffer(response.data.poojaOfferingsDto);
                setTimings(response.data.templesDto);

                var arr: any = [];
                console.log(response.data.facilitiesDto.shops)
                response.data.facilitiesDto.shops.map((item: any) => {
                    console.log(item.split('||'))
                    arr = item.split('||');

                });
                var array_filter = arr.filter((res: any) => res != "")
                setShopIn(array_filter);

                var arr_access: any = [];
                response.data.facilitiesDto.accessibility.map((item: any) => {
                    console.log(item.split('||'))
                    arr_access = item.split('||');
                });

                var array_filter_acs = arr_access.filter((res: any) => res != "")
                setAccIn(array_filter_acs);
                if (response.data.templeImgsUrl === null) {
                    setGallery([]);
                } else {
                    console.log(response.data.templeImgsUrl)
                    setGallery(response.data.templeImgsUrl);
                }
                const times = response.data.templesDto.scheduleAndTimings.split("and")
                setTimings(times);
                setVideolink(response.data.templeVideoLinksDto.filter((res: any) => res.type !== 2 && res.type !== null));
                setVideoLivelink(response.data.templeVideoLinksDto.filter((res: any) => res.type !== 1 && res.type !== null));
                var string = response.data.templesDto.scheduleAndTimings.split('and')
                setMorning(string[0].replace('Morning Darshan:', ''))
                setEvening(string[1].replace('Evening Darshan:', ''))
                setTimerequ(string[2].replace('Time Required:', ''))
                setEntry(string[3])
                setDressme(string[4].replace('Dress Code:', ''))
                setDresswo(string[5].replace('Dress Code:', ''))
                setCloseTime(string[6].replace('Closing Time:', ''))
            })
            .catch((error) => {
                console.log(error);
            });
        console.log("id", id)
        setShowLoading(false);
        setIndex1(1);
    }

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    if (templelist != undefined && templelist.length > 0) {
        templelist.forEach((item: any, index: any) => { item.serial = index + 1; });

    }
    var tableData: any = [];
    if (templelist != undefined && templelist.length > 0) {
        tableData = templelist.filter(
            (item: any) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        );
    }



    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };



        return (
            <IonRow className="table_btn_container">
                <div>
                    <IonButton onClick={addtemple} className="admin_add_btn">
                        <IonIcon src={addOutline} />
                        <span className="button_padding">Add Temple</span>
                    </IonButton>
                </div>
                <div>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle]);

    const openMap = () => {
        window.open('https://www.google.com/maps/place/' + lat + ',' + long + '/@' + lat + ',' + long + ',')
    }



    const handleChange = (selectedOption: any) => {
        console.log(tempId, id, nameBack, propState, propDistrict)
        setSelectedOption(selectedOption);
        setTempAdm(selectedOption.value);
        setName(selectedOption.label);
        tempId.current = selectedOption.value;
        nameBack.current = selectedOption.label;
        ShowAllTemple(selectedOption.value);
        console.log(tempId.current, id, nameBack, propState, propDistrict)


        console.log(selectedOption, "kmkkk")
    };

    const tempAdmListOptions = tempAdmList
        .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
        .map((tempAdm: any, index: any) => ({
            value: tempAdm.id,
            label: tempAdm.firstName
        }));

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const [states, setStates] = useState([]);

    const [stateOption, setStateOption] = useState<{ value: string; label: string } | null>(null);
    const [stateMenu, setStateMenu] = useState(false)
    const [districtMenu, setDistrictMenu] = useState(false)
    const [districtOption, setDistrictOption] = useState<{ value: string; label: string } | null>(null);

    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [district, setDistrict] = useState("");
    const [templeName, setTempleName] = useState("");
    const [formErrors, setFormErrors]: any = useState({});
    const [nameView, setNameView] = useState(false);

    const [districtList, setDistrictList] = useState([]);
    var stateLiistOptions: any = [];
    if (states != undefined && states.length > 0) {
        stateLiistOptions = states.map((e: any, index: any) => ({
            value: e,
            label: e
        }));
    }



    const handleStateChange = (e: any) => {
        setStateOption(e);
        if (e == null) {
            setState("");
        } else {
            setState(e.value);
            setTempprops({
                ...tempprops, state: e.value
            })
            propState.current = e.value;
            setFormErrors({ ...formErrors, state: "" })

        }
        getDistricts(e.value);
    }

    const getDistricts = (e: any) => {
        setDistrictOption(null)
        setDistrictList([])
        setShowLoading(true);
        Authservice.getAllDistricts([e])
            .then((response) => {
                console.log(response)
                setDistrictList(response.data)
                setShowLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setShowLoading(false)
            })
    }
    var districtLiistOptions: any = [];
    if (districtList != undefined && districtList.length > 0) {
        districtLiistOptions = districtList.map((e: any, index: any) => ({
            value: e,
            label: e
        }));
    }



    const handleDistrictChange = (e: any) => {
        setDistrictOption(e)
        if (e == null) {
            setDistrict("")
        } else {
            setDistrict(e.value)
            setTempprops({
                ...tempprops, district: e.value
            })
            propDistrict.current = e.value
            setFormErrors({ ...formErrors, district: "" })

        }
    }


    const handleSearch = (item: any) => {
        var searchstate;
        var searchdistrict;
        var searchcity;
        var searchname;
        console.log(item, "searchitem")
        if (item === "") {
            searchstate = state
            searchdistrict = district
            searchcity = city
            searchname = templeName
            setTempAdmList([])
            setSelectedOption(null)
        }
        else {
            searchstate = item.state
            searchdistrict = item.district
            searchcity = item.city
            searchname = item.temname
        }
        const errors: any = {};
        if (searchstate.length === 0) {
            errors.state = 'Please Select a State';
        }

        if (searchdistrict.length === 0) {
            errors.district = 'Please Select a District';
        }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            console.log(usertoken, searchname, searchcity, searchstate, searchdistrict)
            setShowLoading(true)
            Authservice.tempadminsort(usertoken, searchname, searchcity, searchstate, searchdistrict)
                .then((response) => {
                    if (backProps === undefined) {
                        setSelectedOption(null)
                    }
                    setTemplelist([])
                    console.log(response)
                    if (response.data.length != 0) {
                        setTempAdmList(response.data);
                        setNameView(true)
                    }
                    else {
                        // setStateOption(null)
                        // setState("")
                        // setDistrictOption(null)
                        // setDistrict("")
                        // setCity("")
                        // setTempleName("")
                        // setDistrictList([])
                        presentAlert("No Results Found !")
                        setTempAdmList([]);
                    }
                    setShowLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                    setShowLoading(false)
                })
        }
    }

    const validation = (e: any) => {
        if (e == 1) {
            if (state == "") {
                setFormErrors({ ...formErrors, state: "Please select a state" })
            }
        }
        if (e == 2) {
            if (district == "") {
                setFormErrors({ ...formErrors, district: "Please select a district" })
            }
        }
    }

    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Temple Management"} />
            <Sidebar />
            <IonContent style={{ width: "100%" }}>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                // onDidDismiss={() => setShowLoading(false)}

                // duration={5000}
                />
                {/* <Head User="deity" Role={userdata[0]} /> */}
                {index1 == 0 ? <div className="container_middle_table page-wrapper admin_background_color">
                    <IonRow>
                        <IonCol size="3" size-md="3" size-sm="12" size-xs="12">
                            <IonRow style={{ textAlign: "left" }}>
                                <IonLabel className="admin_search_label">
                                    State <span style={{ color: "red" }}>*</span>
                                </IonLabel>
                            </IonRow>
                            <Select
                                options={stateLiistOptions}
                                value={stateOption}
                                onChange={handleStateChange}
                                placeholder="Enter the State"
                                isClearable={true}
                                onBlur={() => validation(1)}
                                styles={customStyles}
                                className="custom-select"
                            />
                            <div style={{ height: "10px" }}>
                                {formErrors.state && <span className="alert_input_bottom ">{formErrors.state}</span>}
                            </div>
                        </IonCol>
                        <IonCol size="3" size-md="3" size-sm="12" size-xs="12" style={{ textAlign: "left", position: "relative", zIndex: "999" }}>
                            <IonRow style={{ textAlign: "left" }}>
                                <IonLabel className="admin_search_label">
                                    District <span style={{ color: "red" }}>*</span>
                                </IonLabel>
                            </IonRow>
                            <Select
                                options={districtLiistOptions}
                                value={districtOption}
                                onChange={handleDistrictChange}
                                placeholder="Enter the District"
                                isClearable
                                onBlur={() => validation(2)}
                                styles={customStyles}
                                className="custom-select" />
                            {/* <IonInput value={district} placeholder="Enter District" onIonChange={(e: any) => setDistrict(e.detail.value)} /> */}
                            <div style={{ height: "10px" }}>
                                {formErrors.district && <span className="alert_input_bottom ">{formErrors.district}</span>}
                            </div>
                        </IonCol>
                        <IonCol size="3" size-md="3" size-sm="12" size-xs="12" style={{ textAlign: "left" }}>
                            <IonRow style={{ textAlign: "left" }}>
                                <IonLabel className="admin_search_label">
                                    City
                                </IonLabel>
                            </IonRow>
                            <IonInput value={city} placeholder="Enter City" className="admin_search_input"
                                onIonChange={(e: any) => {
                                    setCity(e.detail.value)
                                    setTempprops({
                                        ...tempprops, city: e.detail.value
                                    })
                                    propCity.current = e.detail.value
                                }} />
                        </IonCol>
                        <IonCol size="3" size-md="3" size-sm="12" size-xs="12" style={{ textAlign: "left" }}>
                            <IonRow style={{ textAlign: "left" }}>
                                <IonLabel className="admin_search_label">
                                    Temple Name
                                </IonLabel>
                            </IonRow>
                            <IonInput value={templeName} placeholder="Enter Temple Name" className="admin_search_input"
                                onIonChange={(e: any) => {
                                    setTempleName(e.detail.value);
                                    setTempprops({
                                        ...tempprops, templeName: e.detail.value
                                    })
                                    tempName.current = e.detail.value
                                }
                                } />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol size="12" size-md="12" size-sm="12" size-xs="12" style={{ padding: "0px", display: "flex", justifyContent: "center" }}>
                            <IonButton
                                className="admin_add_btn"
                                onClick={() => handleSearch("")}
                            >
                                <span className="button_padding">Search</span>
                            </IonButton>
                        </IonCol>
                    </IonRow>

                    <IonRow style={{ marginTop: "1%", marginBottom: "1%" }}>
                        <IonCol size-lg="3" size-md="3" size-sm="12" size-xs="12" style={{ display: 'table', margin: 'auto', position: "relative", zIndex: "998" }} >
                            {tempAdmList.length === 0 ? <></> : <Select
                                options={tempAdmListOptions}
                                value={selectedOption}
                                onChange={(e: any) => handleChange(e)}
                                placeholder="Select a Temple Admin"
                                isClearable
                                styles={customStyles}
                                className="custom-select"
                            />}
                        </IonCol>
                       
                    </IonRow>
                    {/* {selectedOption != null ?
                        <IonRow>
                            <IonCol className="ion-text-left" size-lg="9" size-md="9" size-xs="12">
                                {tempAdmList.length === 0 || templelist.length === 0 ? <></> : <div style={{ marginTop: "1%" }}>
                                    <IonButton onClick={addtemple}>
                                        <IonIcon src={addCircleSharp} className="add_deity" style={{ cursor: "pointer" }}></IonIcon>
                                        Add Temple
                                    </IonButton>
                                </div>}
                            </IonCol>
                            <IonCol size-lg="3" size-md="3" size-xs="12">
                                {isSearch === true || tempAdmList.length !== 0 || templelist.length > 0 ? <IonSearchbar style={{ paddingRight: '0px' }}
                                    value={filterText}
                                    onIonChange={(e: any) => setFilterText(e.detail.value)}
                                    placeholder="Search"
                                /> : <></>}
                            </IonCol>
                        </IonRow> : <></>} */}
                    {selectedOption != null ?
                        <DataTable
                            columns={columns}
                            data={tableData}
                            striped
                            noDataComponent="Sorry ! No result found"
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                            subHeader
                            subHeaderComponent={tempAdmList.length === 0 || templelist.length === 0 ? <></> : subHeaderComponent}
                            style={{ width: "100%" }}
                            onRowClicked={(item: any) => onclick(item.templesDto.id)}
                            customStyles={customTableStyle}
                        /> : <></>}
                </div> : index1 === 1 ? <></>
                    // <TempleAdmDet id={id} edit={isedit} admin={2} tempId={tempAdm} name={name} state={state} city={city} district={district} templeName={templeName} />
                    : index1 === 2 ? <></>
                        // <Addtemp id={id} edit={isedit} admin={2} tempId={tempAdm} name={name} state={state} city={city} district={district} templeName={templeName} />
                        : <></>
                }
            </IonContent>
        </IonPage >
    )

}

export default Admtemple;