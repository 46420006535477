import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonButton,
    IonLabel,
    IonInput,
    IonImg,
    IonText,
    IonItemDivider,
    IonLoading,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonCard,
    IonSearchbar,
    IonIcon,
    useIonAlert,
    IonList
} from "@ionic/react";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Modal } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot";
import UserService from "../../Services/user.service";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import Authservice from "../../Services/user.service";
import moment from "moment";
import { Capacitor } from "@capacitor/core";
import { calendarOutline, close, timeOutline } from "ionicons/icons";
import Select, { components } from 'react-select';
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";


const Admmaterialorder: React.FC = () => {
    const [shipNewlist, setShipNewlist]: any = useState([])
    const [orderStatus, setOrdStatus]: any = useState("OPEN")
    const [filterStatus, setFilterStatus]: any = useState({ value: 0, label: "Incomplete Orders" })
    const [filterArr, setFilterArr]: any = useState([])
    const loadData: any = useRef([])
    const [statusList, setStatusList]: any = useState([])
    var tableData1: any;

    const formatter = new Intl.NumberFormat('en-IN')

    const data: any = [
        // {
        //     name: "S.No",
        //     selector: (item: any, i: any) => item.serial,
        //     sortable: true,
        //     cell: (item: any) => (
        //         <div className="center_col_tab" onClick={() => viewdetails1(item)}>
        //             <div style={{ cursor: "pointer" }}>{item.serial}</div>
        //         </div>
        //     )
        // },
        {
            name: "Order Number",
            sortable: true,
            selector: (item: any) => item.poojaMaterialOrdDto.orderNumber,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails1(item)}>
                    <div style={{ cursor: "pointer" }}>{item.poojaMaterialOrdDto.orderNumber}</div>
                </div>
            )
        },
        {
            name: "Order Date",
            selector: (item: any) => new Date(item.localOrderDate.split('-').reverse().join('-')),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails1(item)}>
                    <div style={{ cursor: "pointer" }}>{item.localOrderDate}</div>
                </div>
            )
        },

        {
            name: "Product Name",
            selector: (item: any) => item.poojaMaterialOrdDto.poojaMaterialDto.productName,
            sortable: true,
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails1(item)}>
                    <div style={{ cursor: "pointer" }}>{item.poojaMaterialOrdDto.poojaMaterialDto.productName}</div>
                </div>
            )
        },
        {
            name: "Price(₹)",
            selector: (item: any) => parseInt(item.poojaMaterialOrdDto.totalAmount, 10),
            sortable: true,
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails1(item)}>
                    <div style={{ cursor: "pointer" }}>{formatter.format(item.poojaMaterialOrdDto.totalAmount)}</div>
                </div>
            )
        },
        {
            name: "Quantity",
            sortable: true,
            selector: (item: any) => parseInt(item.poojaMaterialOrdDto.quantity, 10),
            cell: (item: any) => (
                <div className="center_col_tab" onClick={() => viewdetails1(item)}>
                    <div style={{ cursor: "pointer" }}>{item.poojaMaterialOrdDto.quantity}</div>
                </div>
            )
        },
        {
            name: "Vendor Name",
            sortable: true,
            selector: (item: any) => item.poojaMaterialOrdDto.poojaMaterialDto.createdBy.firstName,
            cell: (item: any) => (
                <div className="left_col" onClick={() => viewdetails1(item)}>
                    <div style={{ cursor: "pointer" }}>{item.poojaMaterialOrdDto.poojaMaterialDto.createdBy.firstName}</div>
                </div>
            )
        },


        {
            name: "Manage Orders",
            sortable: false,

            cell: (item: any, i: any) => (
                <div className="center_col_tab">
                    <IonButton className="datatable-activate-btn" onClick={() => Ship(item, i)}><span className="button_padding">Shipment</span></IonButton>
                </div>
            )
        },
    ]

    const userdata: any = useState(localStorage.getItem("UserData"));
    const uFlag: any = useState(localStorage.getItem("uFlag"));
    const [usertoken] = useState(localStorage.getItem("token"));
    const history = useHistory();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = React.useState("");
    const [productname, setProductname] = useState();
    const [brandname, setBrandname] = useState();
    const [price, setPrice]: any = useState();
    const [quantity, setQuantity] = useState();
    const [amtpriest, setAmtpriest] = useState();
    const [amttemple, setAmttemple] = useState();
    const [date, setDate]: any = useState();
    const [number, setNumber] = useState();
    const [psize, setPsize] = useState();
    const [punit, setPunit] = useState();
    const [name, setName] = useState();
    const [star, setStar] = useState();
    const [tname, setTname] = useState();
    const [deityname, setDeityname] = useState();
    const [modalopen1, setModalopen1] = useState(false);
    const [statusmodal, setStatusmodal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [totalpaidamount, setTotalpaidamount]: any = useState("")
    const [materialorder, setMaterialorder]: any = useState([]);
    const [materialorderList, setMaterialorderList]: any = useState([]);
    const [alertmsg, setAlertMsg]: any = useState("")
    const [alertModal, setAlertModal]: any = useState(false)
    const alertRef = useRef(null)
    const [presentAlert] = useIonAlert();
    const [addressModal, setAddressModal]: any = useState(false)
    const [templeAdd, setTempleAdd] = useState("")
    const [chooseStatus, setChooseStatus] = useState("OPEN")

    if (materialorder != undefined && materialorder.length > 0) {
        materialorder.forEach((item: any, index: any) => { item.serial = index + 1; });
        materialorder.forEach((item: any, index: any) => {
            var date = item.poojaMaterialOrdDto.orderDate.split("T");
            const od = moment(date[0]).format("DD-MM-YYYY");
            item.localOrderDate = od;
        });
    }

    const [venAdm, setVenAdm] = useState("");
    const [venAdmList, setVenAdmList]: any = useState([]);
    const dataFetchedRef = useRef(false);

    const [ship, setShip]: any = useState([])
    const [shipmodal, setShipmodal] = useState(false);
    const [shipDate, setShipDate] = useState("");
    const [shipTime, setShipTime] = useState("");
    const [statusId, setStatusId] = useState("");
    const [statusArray, setStatusArray]: any = useState([])
    const [listDates, setListdates]: any = useState("")

    const [isSearch, setIsSearch] = useState(false);



    const ref: any = useRef([])
    const dateRef: any = useRef([])


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        if (userdata[0] === null) {
            history.push("/login")
        }
        else if (userdata[0] !== "ROLE_ADMIN") {
            history.push("/home")
        }
        else if (uFlag[0] === "false") {
            history.push("/home")
        }
        else {

            getStates();
            console.log(venAdmList, "mkk")
        }


    }, []);

    const getStates = () => {
        setShowLoading(true)
        Authservice.getAllstates()
            .then((response) => {
                console.log(response)
                setStates(response.data)
                setShowLoading(false)
            })
            .catch((error) => {
                console.log(error)
                presentAlert("Something went wrong, please try again later.");
                setShowLoading(false)
            })
    }

    const showVenAdmList = () => {
        setShowLoading(true);
        Authservice.vendorAdmList(usertoken)
            .then((response) => {
                console.log(response.data);
                var list: any = response.data;
                for (let i = 0; i < list.length; i++) {
                    venAdmList.push(list[i]);
                }
                setShowLoading(false);
            })
            .catch((error) => {
                alert("Something went wrong, please try again later.");
                setShowLoading(false);
            });
    }
    const Ship = (e: any, i: any) => {
        console.log(e, "jnjjj")
        setProductname(e.poojaMaterialOrdDto.poojaMaterialDto.productName)
        setTempleName(e.poojaMaterialOrdDto.temples.name)
        setTempleAdd(e.poojaMaterialOrdDto.temples.villageorTown + "," + e.poojaMaterialOrdDto.temples.district
            + "," + e.poojaMaterialOrdDto.temples.state + "," + e.poojaMaterialOrdDto.temples.country)
        setOrderNo(e.poojaMaterialOrdDto.orderNumber);
        setShip(e.poojaMaterialOrdersStatusList);
        setStatusArray(e.poojaMaterialOrdersStatusList)
        console.log(Object.entries(groupedItems), "filter data");
        setShipmodal(true);
        setOrdStatus("OPEN")
    }
    const getstatusList = () => {
        setShowLoading(true)
        Authservice.materialorderStatusList(usertoken).then((response) => {
            console.log(response);
            setStatusList(response.data)

            setShowLoading(false);

        })
            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }
    const showmaterialoreder = (e: any) => {
        setShowLoading(true)
        loadData.current = [];

        Authservice.materialorderList(usertoken, e).then((response) => {
            console.log(response);
            shipNewlist.length = 0;
            setMaterialorder(response.data);
            loadData.current = response.data;
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].poojaMaterialOrdersStatusList.length > 0) {
                    const newData: any = response.data[i].poojaMaterialOrdersStatusList.map((e: any) => {
                        return { ...e, shipDate: "", shipTime: "", shipDateAlert: "", shipTimeAlert: "", statusAlert: "",awbAlert:"" }
                    })
                    shipNewlist.push(newData)

                }
            }
            setFilterStatus({ value: 0, label: "Incomplete Orders" })
            setIsSearch(true)
            setShowLoading(false);
            getstatusList();
            handleOrderChange({ value: 0, label: "Incomplete Orders" })
        })
            .catch((error) => {
                alert(error)
                setShowLoading(false);
            });
    }
    var tableDataFil: any = useRef([]);
    if (materialorder != undefined && materialorder.length > 0) {
        tableData1 = materialorder.filter(
            (item: any) => JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1

        );

    }

    const handleStatus = (e: any, i: any, items:any) => {
        // ship[i].status = e.target.value
        const status = statusArray.map((item: any, index: any) => {
            if (items.id === item.id) {
                return { ...item, status: e.target.value }
            }
            return item
        })
        setStatusArray(status)
        console.log(statusArray)
    };
    const statusproceed = (j: any,items:any) => {
        
        for(var i=0;i<statusArray.length;i++){
            if(statusArray[i].id=== items.id){
                if (statusArray[i].status == "SCHEDULED") {
                    const alert = ship.map((item: any, index: any) => {
                        if (items.id === item.id) {
                            return { ...item, statusAlert: "Please Select the status" }
                        }
                        return item
                    })
                    setShip(alert)
                }
                else {
                    var data =
                    {
                        "status": statusArray[i].status,
                        "id": statusArray[i].id
                    }
                    UserService.updateOrderStatusForMaterial(usertoken, data).then((response) => {
                        console.log(response.data);
                        setAlertMsg("Status Changed Successfully")
                        setAlertModal(true)
                        setShipmodal(false)
                        setAddressModal(false)
                        showmaterialoreder(venAdm)
                    })
                        .catch((error) => {
                            setAlertMsg(error)
                            setAlertModal(true)
                        });
                    
                }
            }
        }
       
    }
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };

    const Modalclose1 = () => {
        setModalopen1(false)
    }

    const dropdownvalidation = (num: any) => {
        if (num === 1) {
            if (state.length === 0) {
                setFormErrors({ ...formErrors, state: "Please select a state" })
            }
            else {
                setFormErrors({ ...formErrors, state: "" })
            }
        }
        if (num === 2) {
            if (venAdm.length === 0) {
                setFormErrors({ ...formErrors, admin: "Please select a vendor admin" })
            }
            else {
                setFormErrors({ ...formErrors, admin: "" })
            }
        }
    }

    const validation = (num: any, i: any, items:any) => {
        var shTime = items.shipmentTime;
        var shDate = items.shipmentDate;
        var awb = items.shipawbNumber;
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate());
        if (num === "1") {
            if (shDate === null) {
                const datealert = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipDateAlert: "Please Choose the Date" }
                    }
                    return item;
                })
                setShip(datealert)
            }
            else if (tomorrow.toISOString().split('T')[0] > new Date(shDate).toISOString().split('T')[0]) {
                const datealert = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipDateAlert: "Please select future date" }
                    }
                    return item;
                })
                setShip(datealert)
            }
            else {
                const datealert1 = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipDateAlert: "" }
                    }
                    return item;
                })
                setShip(datealert1)
            }
        }
        else if (num === "2") {
            if (shTime === null) {
                const datealert = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipTimeAlert: "Please Choose the Time" }
                    }
                    return item;
                })
                setShip(datealert)
            }
            else {
                const datealert1 = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, shipTimeAlert: "" }
                    }
                    return item;
                })
                setShip(datealert1)
            }
        }
        else if(num === "3"){
            if(!awb){
                const datealert = ship.map((item: any, index: any) => {
                    if (items.id === item.id) {
                        return { ...item, awbAlert: "Invalid AWB number" }
                    }
                    return item;
                })
                setShip(datealert)
            }

        }
    }

    const initShip = (i: any,items:any) => {
        console.log(ship, "kmkk")
        var shiplist = groupedItems[orderStatus];
        var shipCurent = shiplist[i];
        console.log(shipCurent,"currwnt ship data")
       
        var shId = shipCurent.id;
        var ordId = shipCurent.poojaMaterialOrders.id;
        var shTime = shipCurent.shipmentTime;
        var shDate = shipCurent.shipmentDate;
        var awb = shipCurent.shipawbNumber;
        console.log(awb,"shipawb")
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate());
        if (!shDate || shTime === null || !awb ) {
            const datealert = ship.map((item: any, index: any) => {
                if (items.id === item.id) {
                    if(!shDate && shTime === null && !awb){
                        return { ...item, shipDateAlert: "Please Choose the Date",shipTimeAlert: "Please Choose the Time",awbAlert: "Invalid AWB number" }

                    }
                    if(!shDate && shTime === null){
                        return { ...item, shipDateAlert: "Please Choose the Date",shipTimeAlert: "Please Choose the Time"}

                    }
                    if(!shDate){
                        return { ...item, shipDateAlert: "Please Choose the Date"}

                    }
                    if(shTime === null){
                        return { ...item, shipTimeAlert: "Please Choose the Time" }

                    }
                    if(!awb){
                        return { ...item, awbAlert: "Invalid AWB number" }
                    }
                }
                return item;
            })
            setShip(datealert)
        }
        else if (tomorrow.toISOString().split('T')[0] > new Date(shDate).toISOString().split('T')[0]) {
            const datealert = ship.map((item: any, index: any) => {
                if (items.id === item.id) {
                    return { ...item, shipDateAlert: "Please select future date" }
                }
                return item;
            })
            setShip(datealert)
        }
       
        else {
            console.log(shDate, "date", shTime, "time")
            UserService.initShipForMaterial(usertoken, ordId, shDate, shTime, shId,awb).then((response) => {
                console.log(response.data);
                if (response.data.errorMessage == null) {
                    setAlertMsg("Shipment Initiated Successfully");
                    setAlertModal(true)
                    // window.location.reload();
                } else {
                    setAlertMsg(response.data.errorMessage);
                    setAlertModal(true)
                    // window.location.reload();
                }
                setShipmodal(false)
                setAddressModal(false)
                showmaterialoreder(venAdm)

            })
                .catch((error) => {
                    setAlertMsg("Problem in Initiating Shipment");
                    setAlertModal(true)
                    setShipmodal(false)
                    setAddressModal(false)
                    showmaterialoreder(venAdm)
                });

        }
    }
    const dateChange = (event: any, i: any, itemShip: any) => {
        var val = event.target.value;
        if (val !== "" && val != null) {
            const date = moment(val).format("YYYY-MM-DD")
            const datealert1 = ship.map((item: any, index: any) => {
                if (itemShip.id === item.id) {
                    return { ...item, shipDateAlert: "", shipmentDate: date }
                }
                return item;
            })
            setShip(datealert1)
        }
        // ship[i].shipmentDate = date
        console.log(ship)
    };
    
    
   
    const setTime = (e: any, item: any, i: any) => {

        if (e.detail.value !== "") {
            var ii = e.detail.value.split(":");
            var t: any;
            var a: any;

            if (ii[0] > 12) {
                t = ii[0] - 12;
                a = "PM";

            } else if (ii[0] < 12) {
                t = ii[0]
                a = 'AM';
            }
            else if (ii[0] == 12) {
                t = ii[0]
                a = 'PM';
            }

            var str = t + ":" + ii[1] + " " + a;
           

          
            const ti = ship.map((items: any, index: any) => {
                if (item.id === items.id) {

                   
                    
                    return { ...items, shipmentTime: str }
                    
                }
                return items;
            })
            console.log(ti, "itemssss")
            setShip(ti)
        }
        // }

    }
    const setAwb = (e: any, item: any, i: any) => {
        if(e.detail.value.trim() != ""){
            var val=e.detail.value.trim();
            const ti = ship.map((items: any, index: any) => {
                if (item.id === items.id) {
                    console.log(items, "itemssss")
                    return { ...items, shipawbNumber: val , awbAlert :"" }
                }
                return items;
            })
            setShip(ti)

        }else{
            const ti = ship.map((items: any, index: any) => {
                if (item.id === items.id) {
                    console.log(items, "itemssss")
                    return { ...items,  shipawbNumber: "" , awbAlert :"Invalid AWB number" }
                }
                return items;
            })
            setShip(ti)
        }

    }
    const subHeaderComponent1 = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        }
        return (
            <IonRow className="table_btn_container">
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <IonSearchbar
                        value={filterText}
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        placeholder="Search"
                        className="table_search"
                    />
                </div>
            </IonRow>
        );
    }, [filterText, resetPaginationToggle]);
    const [totalamt, setTotalamt] = useState("")
    const [vendorname, setVendorname] = useState("");
    const [listdate, setListdate] = useState("");

    const viewdetails1 = (item: any) => {
        setModalopen1(true);
        var lddate = item.poojaMaterialOrdDto.listOfdates;
        let ldmdate: any;
        var date1 = item.poojaMaterialOrdDto.orderDate.split("T");
        const od1 = moment(date1[0]).format("DD-MM-YYYY");
        if (lddate === null || lddate === "") {
            lddate = "";
        }
        else {
            const ld = lddate.split("||");
            const ld3 = ld.splice(-1);
            console.log(ld, "date")
            ldmdate = ld.join(", ")
        }
        setStar(item.poojaMaterialOrdDto.star);
        setProductname(item.poojaMaterialOrdDto.poojaMaterialDto.productName);
        setBrandname(item.poojaMaterialOrdDto.poojaMaterialDto.brandName);
        setPrice(item.poojaMaterialOrdDto.poojaMaterialDto.price);
        setTotalpaidamount(item.poojaMaterialOrdDto.totalPaidAmount);
        setQuantity(item.poojaMaterialOrdDto.quantity);
        setPsize(item.poojaMaterialOrdDto.poojaMaterialDto.packageSize);
        setPunit(item.poojaMaterialOrdDto.poojaMaterialDto.packageUnit);
        setNumber(item.poojaMaterialOrdDto.orderNumber);
        setDate(od1);
        setAmtpriest(item.poojaMaterialOrdDto.dakshinaAmountForPriest);
        setAmttemple(item.poojaMaterialOrdDto.dakshinaAmountToTemple);
        setName(item.poojaMaterialOrdDto.name);
        setTname(item.poojaMaterialOrdDto.temples.name);
        setDeityname(item.poojaMaterialOrdDto.deity.deityName);
        setTotalamt(item.poojaMaterialOrdDto.totalAmount)
        setListdate(ldmdate)
        setVendorname(item.poojaMaterialOrdDto.poojaMaterialDto.createdBy.firstName)
    };
    const useOutsideAlerter = (refer: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {

                if (refer.current && !refer.current.contains(event.target)) {
                    setModalopen1(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [refer]);
    }
    const status = (i: any, item: any) => {
        setStatusmodal(true)
        setStatusId(i)
    }

    const datelimit = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    const materialmodal = useRef(null);

    useOutsideAlerter(materialmodal);

    const closemodal = () => {
        setShipmodal(false);
        setAddressModal(false)
        setShip([]);
    }

    const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(null);

    const handleChange = (selectedOption: any) => {
        if (selectedOption !== null) {
            setFormErrors({ ...formErrors, admin: "" })
            setVenAdm(selectedOption.value);
        }
        else {
            setFormErrors({ ...formErrors, admin: "Select a Vendor admin" })
            setVenAdm("");
        }
        setSelectedOption(selectedOption);
        showmaterialoreder(selectedOption.value);
        console.log(selectedOption, "kmkkk")
    };

    const handleOrderChange = (select: any) => {
        setFilterArr([])
        setMaterialorder([])


        setFilterStatus(select)
        if (select.value == 2) {
            setMaterialorder(loadData.current);
            return
        }


        if (loadData.current != undefined && loadData.current.length > 0) {

            for (var i = 0; i < loadData.current.length; i++) {
                var compFlag = 1;
                var IncomFlag = 0;
                var subOrder = loadData.current[i].poojaMaterialOrdersStatusList;
                for (var j = 0; j < subOrder.length; j++) {

                    if (subOrder[j].status != "COMPLETED") {
                        compFlag = 0;

                    }


                }
                if (select.value == 0 && compFlag == 0) {
                    filterArr.push(loadData.current[i]);
                }

                if (select.value == 1 && compFlag == 1) {
                    filterArr.push(loadData.current[i]);
                }



            }

            tableData1 = filterArr;
            setMaterialorder(filterArr)
            console.log(filterArr, "filtered data")
        }

    }

    const orderStatusList: any[] = [
        { value: 2, label: "All" },

        { value: 1, label: "Complete Orders" },
        { value: 0, label: "Incomplete Orders" },]

    const vendorListOptions = venAdmList
        .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
        .map((tempAdm: any, index: any) => ({
            value: tempAdm.id,
            label: tempAdm.firstName
        }));

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: "1px solid black",
            boxShadow: 'none',
            fontSize: '15px',
            // height: '44px',
            width: '100%',
            outline: 'none',
            textAlign: "left"
        }),
    };

    const [states, setStates] = useState([]);

    const [stateOption, setStateOption] = useState<{ value: string; label: string } | null>(null);

    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    // const [district, setDistrict] = useState("");
    const [templeName, setTempleName] = useState("");
    const [orderNo, setOrderNo] = useState("")
    const [nameView, setNameView] = useState(false);
    const [formErrors, setFormErrors]: any = useState({});
    var stateLiistOptions: any = [];
    if (states != undefined && states.length > 0) {
        stateLiistOptions = states.map((e: any, index: any) => ({
            value: e,
            label: e
        }));
    }


    const groupedItems = ship.reduce((acc: any, item: any) => {
        const { status } = item;
        if (!acc[status]) {
            acc[status] = [];
        }
        acc[status].push(item);
        return acc;
    }, {} as { [key: string]: any[] });
    const handleStateChange = (e: any) => {
        if (e !== null) {
            setFormErrors({ ...formErrors, state: "" })
            setState(e.value);
        }
        else {
            setState("");
            setFormErrors({ ...formErrors, state: "Select a state" })
        }
        setStateOption(e);
    }

    const handleSearch = () => {
        const errors: any = {};
        if (state.length === 0) {
            errors.state = 'Please Select a State';
        }
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setNameView(true)
            setSelectedOption(null)
            setMaterialorder([])
            loadData.current = [];
            setShowLoading(true)
            setVenAdmList([])
            Authservice.getVendorList(usertoken, state, city)
                .then((response) => {
                    console.log(response, "jnjj")
                    if (response.data.length != 0) {
                        var list: any = response.data;
                        setVenAdmList(list)
                    }
                    else {
                        presentAlert("No Results Found !")
                    }
                    setShowLoading(false);
                })
                .catch((error) => {
                    console.log(error)
                    setShowLoading(false);
                })
        }
    }

    const changeStatus = (status: any) => {
        setOrdStatus(status)

    }
    return (
        <IonPage>
            <AdminHead User={"user"} Role={"Role"} Name={"Material Order Management"} />
            <Sidebar />
            <IonContent>
                <IonLoading message="Loading..." spinner={null}
                    isOpen={showLoading}
                />
                <Modal show={alertModal} centered style={{ width: "40%", left: "30%" }}>
                    <Modal.Body ref={alertRef}>
                        <div style={{ textAlign: "center" }}><div>
                            <IonText> {alertmsg}</IonText>
                        </div><br></br>
                            <div>
                                <IonButton className="admin_add_btn">
                                    <span onClick={() => { setAlertModal(false) }} className="button_padding">ok</span>

                                </IonButton>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={addressModal} centered style={{ width: "40%", left: "30%" }}>
                    <Modal.Header>
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <IonText style={{ color: "black", fontWeight: "bold" }}>Temple Address</IonText></div>

                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: "center" }}><div>
                            <IonText > {templeAdd}</IonText>
                        </div><br></br>
                            <div>
                                <IonButton className="admin_add_btn">
                                    <span onClick={() => { setAddressModal(false) }} className="button_padding">OK</span>

                                </IonButton>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={shipmodal}
                    className="admin_modal material_order_modal">
                    <Modal.Body>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Shipping details</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => closemodal()} />
                            </div>
                        </div>
                        <div>
                            <IonRow>
                                <IonCol sizeLg="2.5" sizeMd="3" size="5"><IonText className="shipMent-headCells">Product Name</IonText></IonCol>
                                <IonCol sizeLg="0.5" sizeMd="1" size="1"><IonText className="shipMent-headCells">:</IonText></IonCol>
                                <IonCol sizeLg="6.5" sizeMd="8" size="6"><IonText className="shipMent-headCells">{productname}</IonText></IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeLg="2.5" sizeMd="3" size="5"><IonText className="shipMent-headCells">Temple Name</IonText></IonCol>
                                <IonCol sizeLg="0.5" sizeMd="1" size="1"><IonText className="shipMent-headCells">:</IonText></IonCol>
                                <IonCol sizeLg="6.5" sizeMd="8" size="6"><IonText
                                    className="shipMent-headCells text-hyper" onClick={() => { setAddressModal(true) }}>{templeName}</IonText></IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeLg="2.5" sizeMd="3" size="5"><IonText className="shipMent-headCells">Order No</IonText></IonCol>
                                <IonCol sizeLg="0.5" sizeMd="1" size="1"><IonText className="shipMent-headCells">:</IonText></IonCol>
                                <IonCol sizeLg="6.5" sizeMd="8" size="6"><IonText className="shipMent-headCells">{orderNo}</IonText></IonCol>
                            </IonRow>
                        </div>
                        <div>

                            <IonList className="horizontal-scroll-list">

                                {statusList.map((item: any, i: any) => (
                                    <div key={item} className={(orderStatus == item) ? "chooseOrderTab center_col" : "horizontal-scroll-item center_col"}
                                        onClick={() => { changeStatus(item) }}>

                                        {item}
                                        {(groupedItems != undefined && groupedItems[item] != undefined) ? <>
                                            ( {groupedItems[item].length} )
                                        </> : <>
                                            ( 0 )</>}</div>

                                ))}





                            </IonList>


                        </div>
                        {!groupedItems.hasOwnProperty(orderStatus) ? <div style={{ width: "100%", height: "200px" }} className="center_col">
                            <IonLabel style={{ fontSize: "20px" }}>
                                Order not available
                            </IonLabel>

                        </div> : <div style={{ height: "500px", overflowY: "auto", marginTop: "5px" }}>
                            <table style={{ width: "100%" }}>
                                <tr style={customTableStyle.headRow.style}>
                                    <th className="shipMent-headCells">
                                        S.No
                                    </th>

                                    <th className="shipMent-headCells">
                                        Order Date
                                    </th>
                                    <th className="shipMent-headCells">
                                        Schedule Date and Time
                                    </th>
                                    <th className="shipMent-headCells">
                                        Status
                                    </th>
                                    <th className="shipMent-headCells">
                                        Action
                                    </th>
                                </tr>

                                {Object.entries(groupedItems).map(([status, items1]) => (
                                    <>
                                        {(status == orderStatus) && <>

                                            {(items1 as any).map((item: any, i: any) => {
                                                 var date = item.orderedDate;
                                                 var val =  new Date(date);
                                                  var formatD=  moment(val).format("DD-MM-YYYY")
                                                const awb = item.awbNumber;
                                                const disable = item.status;
                                                const foundObject = statusArray.find((obj:any) => obj.id === item.id);

                                                const shipformat = moment(item.shipmentDate).format("DD-MM-YYYY")
                                                const shipd = ship[i].shipdate;
                                                var status = foundObject.status

                                                return (
                                                    <>
                                                        {item.awbNumber != null ? <tr className="material-shipment-row shipMent-rowCells">
                                                            <td >
                                                                <IonText>{i + 1}</IonText>
                                                            </td>

                                                            <td >
                                                                <IonText>{formatD}</IonText>
                                                            </td>
                                                            <td >
                                                                <IonText>Date : {shipformat}</IonText><br />
                                                                <IonText>Time : {item.shipmentTime}</IonText>
                                                            </td>
                                                            <td >
                                                                <div style={{ display: "grid" }}>
                                                                    <IonText> {item.status}</IonText>
                                                                    <IonText>AWB Number:{item.awbNumber}</IonText>
                                                                </div>
                                                            </td>
                                                            <td >
                                                                <select style={{ fontSize: "13px" }} className="select-status-admin" value={status} onChange={(e: any) => handleStatus(e, i, item)} placeholder="Select Status">
                                                                    <option value="SCHEDULED" selected disabled>Select Status</option>
                                                                    <option value="SHIPPED">SHIPPED</option>
                                                                    <option value="COMPLETED">COMPLETED</option>
                                                                </select>
                                                                {item.statusAlert === "" ? <></> :
                                                                    <div style={{ display: "flex", marginTop: "2%" }}>
                                                                        <IonText className="alert_input_bottom">
                                                                            {item.statusAlert}
                                                                        </IonText>
                                                                    </div>}
                                                                <IonButton className="datatable-activate-btn" onClick={() => { statusproceed(i,item) }}><span className="button_padding">Update</span></IonButton>
                                                            </td>
                                                        </tr> : <tr className="material-shipment-row shipMent-rowCells">
                                                            <td >
                                                                <IonText>{i + 1}</IonText>
                                                            </td>

                                                            <td >
                                                                <IonText>{formatD}</IonText>
                                                            </td>
                                                            <td className="materialorderdefault" >
                                                                <IonInput onBlur={() => validation("1", i,item)} 
                                                                style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "7px 0px" }} ref={(el: any) => (dateRef.current[i] = el)} 
                                                                onKeyDown={(e: any) => e.preventDefault()} 
                                                                value={item.shipmentDate} type="date" 
                                                                onIonChange={(e: any) => dateChange(e, i, item)} min={datelimit()} className="time_gram" />
                                                                {item.shipDateAlert === "" ? <></> : <div style={{ display: "flex", marginTop: "2%" }}>
                                                                    <IonText className="alert_input_bottom">
                                                                        {item.shipDateAlert}
                                                                    </IonText>
                                                                </div>}
                                                                <IonInput type="time" onBlur={() => validation("2", i,item)}
                                                                style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "7px 0px" }}
                                                                 onKeyDown={(e: any) => e.preventDefault()} 
                                                                 ref={(el: any) => (ref.current[i] = el)} 
                                                                 onIonChange={(e: any) => setTime(e, item, i)} className="time_gram_mat" />
                                                                {item.shipTimeAlert === "" ? <></> : <div style={{ display: "flex", marginTop: "2%" }}>
                                                                    <IonText className="alert_input_bottom">
                                                                        {item.shipTimeAlert}
                                                                    </IonText>
                                                                </div>}
                                                            </td>
                                                            <td className="materialordericon">
                                                                <div>
                                                                    <IonInput style={{}} onBlur={() => validation("1", i,item)} type="date" onKeyDown={(e: any) => e.preventDefault()} ref={(el: any) => (dateRef.current[i] = el)} value={item.shipmentDate} onIonChange={(e: any) => dateChange(e, i, item)} min={datelimit()} />
                                                                </div>
                                                                <div style={{ marginTop: "-18%", textAlignLast: "right", paddingRight: "5px" }}>
                                                                    <IonIcon size="35" className="calaendariconorders" src={calendarOutline} />
                                                                </div>
                                                                {item.shipDateAlert === "" ? <></> : <div style={{ display: "flex", marginTop: "2%" }}>
                                                                    <IonText className="alert_input_bottom">
                                                                        {item.shipDateAlert}
                                                                    </IonText>
                                                                </div>}
                                                                <div style={{ marginTop: "2%" }}>
                                                                    <IonInput type="time" 
                                                                    onBlur={() => validation("2", i,item)} 
                                                                    onKeyDown={(e: any) => e.preventDefault()} 
                                                                    ref={(el: any) => (ref.current[i] = el)} 
                                                                    onIonChange={(e: any) => setTime(e, item, i)} />
                                                                </div>
                                                                <div style={{ marginTop: "-18%", textAlignLast: "right", paddingRight: "5px" }}>
                                                                    <IonIcon size="35" className="calaendariconorders" src={timeOutline} />
                                                                </div>
                                                                {item.shipTimeAlert === "" ? <></> : <div style={{ display: "flex", marginTop: "2%" }}>
                                                                    <IonText className="alert_input_bottom">
                                                                        {item.shipTimeAlert}
                                                                    </IonText>
                                                                </div>}
                                                            </td>
                                                            <td >
                           <IonText> {item.status}</IonText>
                       </td>
                                                            <td >
                                                                <div>
                                                                <IonInput type="text" placeholder="AWB number" style={{textAlign:"left"}}
                                                                onBlur={() => validation("3",i,item)}
                                                                
                                                                onIonChange={(e: any) =>{
                                                                    validation("3",i,item)
                                                                    setAwb(e, item, i) 
                                                                } }></IonInput>
                                                                  <div style={{ display: "flex", marginTop: "3%" }}>
                                                                {item.awbAlert && <span className="alert_input_bottom">{item.awbAlert}</span>}
                                                               
                                                            </div>
                                                                <IonButton className="initiate-shipment-btn" onClick={() => initShip(i,item)}><span className="button_padding">Initiate Shipment</span></IonButton>


                                                                </div>
                                                            </td>
                                                        </tr>}
                                                    </>
                                                )
                                            })}
                                        </>}

                                    </>
                                ))}</table>

                        </div>}

                       
                    </Modal.Body>
                </Modal>
                <Modal show={modalopen1} className="admin_modal">
                    <Modal.Body ref={materialmodal}>
                        <div className="donation-modal-header admin-user-modal-header">
                            <h5 className="donation-modal-title">Order details</h5>
                            <div>
                                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen1(false)} />
                            </div>
                        </div>
                        <div className="donation-admin-container">
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Order Number
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{number}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Order Date
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{date}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{name}</IonText>
                                </IonCol>
                            </IonRow>
                            {star !== "" ? <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align">
                                        Star
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{star}</IonText>
                                </IonCol>
                            </IonRow> : <></>}
                            <IonRow  >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Product Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{productname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Vendor Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{vendorname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Brand Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{brandname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Material Price
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {formatter.format(price)}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Total Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {formatter.format(totalpaidamount)}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        List of Date(s)
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{listdate}</IonText>
                                </IonCol>
                            </IonRow>
                            {totalamt == null ? <></> : <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Total Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {formatter.format(parseInt(totalamt))}</IonText>
                                </IonCol>
                            </IonRow>}
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Quantity
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{quantity}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Package Size
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{psize}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Package Unit
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{punit}</IonText>
                                </IonCol>
                            </IonRow>
                            {amtpriest == null ? <></> : <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Dakshina Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {amtpriest}</IonText>
                                </IonCol>
                            </IonRow>}
                            {amttemple == null ? <></> : <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Donation Amount
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >₹ {amttemple}</IonText>
                                </IonCol>
                            </IonRow>}
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Deity
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{deityname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="5">
                                    <IonLabel className="label_align" >
                                        Temple Name
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1">
                                    <IonText>:</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText
                                    >{tname}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol size="12" style={{ display: "flex", justifyContent: "center" }}>
                                    <IonButton
                                        className="admin_add_btn"
                                        onClick={Modalclose1}
                                    >
                                        <span className="button_padding"> Close</span>
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="container_middle_table page-wrapper admin_background_color" style={{ textAlign: "left" }}>
                    <IonRow >
                        <IonCol size-xs="12" size-lg="3" size-md="3">
                            <IonLabel className="admin_search_label">
                                State <span style={{ color: "red" }}>*</span>
                            </IonLabel>
                            <Select
                                options={stateLiistOptions}
                                value={stateOption}
                                onBlur={() => dropdownvalidation(1)}
                                onChange={handleStateChange}
                                placeholder="Enter the State"
                                isClearable
                                styles={customStyles}
                                className="custom-select"
                            />
                            <div style={{ height: "10px" }}>
                                {formErrors.state && <span className="alert_input_bottom ">{formErrors.state}</span>}
                            </div>
                        </IonCol>
                        <IonCol size-xs="12" size-lg="3" size-md="3" style={{ zIndex: "999", position: "relative" }}>
                            <IonLabel className="admin_search_label">
                                City
                            </IonLabel>
                            <IonInput className="admin_search_input" value={city} placeholder="Enter city name" onIonChange={(e: any) => setCity(e.detail.value)} />
                        </IonCol>
                        <IonCol size-xs="12" size-lg="6" size-md="6" class="search-colum-project-admin">
                            <IonLabel className="admin_search_label">
                            </IonLabel>
                            <IonButton
                                className="admin_add_btn"
                                type="button"
                                onClick={handleSearch}
                            >
                                <span className="button_padding"> Search</span>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ zIndex: "999", position: "relative" }} className="select-admin-container">
                        {venAdmList.length !== 0 ?

                            <IonCol size-xs="12" size-lg="3" size-md="3">
                                <IonLabel className="admin_search_label">
                                    Vendor
                                </IonLabel>
                                <Select
                                    options={vendorListOptions}
                                    value={selectedOption}
                                    onBlur={() => dropdownvalidation(2)}
                                    onChange={handleChange}
                                    placeholder="Select a Vendor"
                                    isClearable
                                    styles={customStyles}
                                    className="custom-select"
                                />
                                {/* {formErrors.admin && <span className="alert_input_bottom ">{formErrors.admin}</span>} */}
                            </IonCol>

                            : <></>}
                        {selectedOption != null &&


                            <IonCol size-xs="12" size-lg="3" size-md="3">
                                <IonLabel className="admin_search_label">
                                    Order Status
                                </IonLabel>
                                <Select
                                    options={orderStatusList}
                                    value={filterStatus}

                                    onChange={handleOrderChange}
                                    placeholder="Select Order Status"
                                    isClearable
                                    styles={customStyles}
                                    className="custom-select"
                                />
                            </IonCol>
                        }</IonRow>



                    {selectedOption != null ?
                        <DataTable
                            columns={data}
                            data={tableData1}
                            striped
                            noDataComponent="Sorry ! No result found"
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                            subHeader
                            subHeaderComponent={isSearch === true && selectedOption != null ? subHeaderComponent1 : ""}
                            style={{ width: "100%" }}
                            onRowClicked={(item: any) => viewdetails1(item)}
                            customStyles={customTableStyle}

                        /> : <></>}
                </div>
            </IonContent>
        </IonPage>


    )
}
export default Admmaterialorder;
