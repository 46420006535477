import {
  IonContent,
  IonPage,
  IonItemDivider,
  useIonRouter,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonTab,
  IonText,
  IonButton,
  IonIcon
} from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import "./Userpage.css"
import "./Home.css";
import Foot from "./Foot";
import HeadFoot from "./Head";
import UserHead from "./UserHead";
import { Capacitor } from "@capacitor/core";
import "./Home.css"
import Authservice from "../Services/user.service";
import UserService from "../Services/auth.service";
import { BudhaImg, home_image, logo, grammercelogo, materialslogo, offeringlogo, projectlogo, temple, donation } from "../theme/imagePath";
import { Link, NavLink } from "react-router-dom";
import { arrowForwardOutline } from "ionicons/icons";

const Userhome: React.FC = () => {
  const params: any = useParams()
  const renderAfterCalled = useRef(false);


  const userdata: any = useState(localStorage.getItem("UserData"));
  const uflag: any = useState(localStorage.getItem("uFlag"))
  const history = useHistory();
  const [usertoken] = useState(localStorage.getItem("token"));
  var token: any = params.token;
  console.log("hometkn" + token);
  const local: any = useLocation()


  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Function to update the screen width when the window is resized
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    if (userdata[0] === null) {
      // history.push("/login")
    } else {
    }
    console.log(token + "tkn")
    console.log(isNaN(uflag[0]), usertoken)


    if (!renderAfterCalled.current) {
      if (token != null && token != undefined && token != "") {
        Authservice.VerifyMobileChange(token, usertoken).then((response) => {
          if (response.data.startsWith("SUCCESS")) {
            alert(response.data.split('SUCCESS:')[1]);
            token = null;

            history.push("/profile")
            return;

          } else {
            alert(response.data.split('ERROR:')[1]);
            token = null;

            history.push("/home")
            return;


          }
          console.log(response + "mobRes")
        })
          .catch((error) => {
            console.log(error + "mobErr")
          });
      }
    }

    renderAfterCalled.current = true;
    // window.addEventListener('beforeunload',(e) => {
    //   e.preventDefault();
    // console.log(localStorage.getItem("token"))
    // localStorage.removeItem("token");
    // localStorage.removeItem("UserData");

    //})
    window.addEventListener('resize', updateScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  /************** Scroll to div ******************/

  const targetRef: any = useRef(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      console.log("Scrolling to target...");
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isHome = local.pathname === '/home' || local.pathname === '/';
  const displayHeadBackgroundImg = isHome && screenWidth <= 1000;

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className={`${displayHeadBackgroundImg ? 'head_background_img' : 'home_head_background_img'}`}>
          <HeadFoot User={"home"} Role={"Role"} Name="Home,Home" />
          <div className="home_head_sub_container1">
          <div className="home_budha_div">
              {/* <IonImg src={BudhaImg} className="home_budha_image" /> */}
            </div>
            <div className="home_text_container">
              <div>
                <IonText className="home_head_text">We, Hindus, believe <br/> in the Vedas.</IonText>
              </div>
              <div>
                <IonText className="home_head_subtext">Who but the Atman is capable of removing the bonds of ignorance, passion and self-interested action?</IonText>
              </div>
              <div className="home_head_button_container">
                <IonButton onClick={scrollToTarget}>
                  <span className="button_padding">Visit Now</span></IonButton>
              </div>
            </div>
           
          </div>
         
        </div>
        {/* <div id="targetElement" className="container_middles_layout_home" ref={targetRef}>
          <h4 style={{ textAlign: "center", padding: "1% 0%" }}>Welcome  To Atmagram</h4>
          <IonImg src={home_image} class="banner" />
        </div> */}
        {usertoken !== null && uflag[0] === "false" ? <div className="container_middles_layout_home" ref={targetRef}>
          {/* <h4 style={{ textAlign: "center", padding: "1% 0%" }}>Welcome  To Atmagram</h4>
          <IonImg src={home_image} class="banner" /> */}
        </div> :
          <IonGrid className="page_content_padding content_style" ref={targetRef}>
            {userdata[0] === null || userdata[0] === "ROLE_USER" ?
              <div className="">
                <IonRow style={{ paddingLeft: "30px" }}>
                  <IonText className="head1_style">
                    Welcome
                  </IonText>
                </IonRow>
                <IonRow style={{ paddingTop: "10px", paddingLeft: '25px' }}>
                  <IonCol size-lg="5.5" size-md="5.5" size-xs="12" size-sm="12">
                    <IonText className="aboutveda_style">
                      Welcome to everyone who aspires to gain happiness and inner peace.
                    </IonText>
                  </IonCol>
                  <IonCol size-lg="1" size-md="1" size-xs="0" size-sm="0" />
                  <IonCol size-lg="5.5" size-md="5.5" size-xs="12" size-sm="12">
                    <IonRow>
                      <IonText className="aboutveda2_style">
                      As gold purified in a furnace loses its impurities and achieves its own true nature, the mind gets rid of the impurities of the attributes of delusion, attachment, and purity through meditation and attains Reality.
                      </IonText>
                    </IonRow>
                    <IonRow style={{ marginTop: "10px" }}>
                      <IonText className="aboutveda2_style">
                      Curb your senses and your mind and see the Lord within your heart.
                      </IonText>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow style={{ margin: "10px", }}>
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonCard className="card_style">
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        {/* <div style={{ borderRadius: "5px" }}> */}
                        <IonImg src={temple} className="card_img_style" />
                        {/* </div> */}
                      </IonRow>
                      <IonRow className="card_head_main">
                        <IonText className="card_head">
                          Temples
                        </IonText>
                      </IonRow>
                      <IonRow style={{ padding: "15px" }}>
                        <IonText className="card_text">
                        View all information related to temples.                        </IonText>
                      </IonRow>
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonButton onClick={() => history.push("/temples")}>

                          <span className="button_padding">
                            View All
                          </span>
                          <IonIcon src={arrowForwardOutline} className="card_btn_icon" />

                        </IonButton>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonCard className="card_style">
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonImg src={offeringlogo} className="card_img_style" />
                      </IonRow>
                      <IonRow className="card_head_main">
                        <IonText className="card_head">
                          Pooja Offerings
                        </IonText>
                      </IonRow>
                      <IonRow style={{ padding: "15px" }}>
                        <IonText className="card_text">
                          Perform all your Poojas and Offerings to your favourite deities based upon temples.                        </IonText>
                      </IonRow>
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonButton onClick={() => history.push("/offerings")}>

                          <span className="button_padding">
                            View All
                          </span>
                          <IonIcon src={arrowForwardOutline} className="card_btn_icon" />

                        </IonButton>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonCard className="card_style">
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonImg src={materialslogo} className="card_img_style" />
                      </IonRow>
                      <IonRow className="card_head_main">
                        <IonText className="card_head">
                          Pooja Materials
                        </IonText>
                      </IonRow>
                      <IonRow style={{ padding: "15px" }}>
                        <IonText className="card_text">
                        Offer pooja materials to temples.                        </IonText>
                      </IonRow>
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonButton onClick={() => history.push("/materials")}>

                          <span className="button_padding">
                            View All
                          </span>
                          <IonIcon src={arrowForwardOutline} className="card_btn_icon" />

                        </IonButton>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow style={{ margin: "10px", }}>
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonCard className="card_style">
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ borderRadius: "5px" }}>
                          <IonImg src={grammercelogo} className="card_img_style" />

                        </div>

                      </IonRow>
                      <IonRow className="card_head_main">
                        <IonText className="card_head">
                          Grammerce
                        </IonText>
                      </IonRow>
                      <IonRow style={{ padding: "15px" }}>
                        <IonText className="card_text">
                        A wide range of products and services produced by Atmagram community.                  </IonText>
                      </IonRow>
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonButton onClick={() => history.push("/grammerce")}>

                          <span className="button_padding">
                            View All
                          </span>
                          <IonIcon src={arrowForwardOutline} className="card_btn_icon" />

                        </IonButton>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonCard className="card_style">
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonImg src={donation} className="card_img_style" />
                      </IonRow>
                      <IonRow className="card_head_main">
                        <IonText className="card_head">
                          Donations
                        </IonText>
                      </IonRow>
                      <IonRow style={{ padding: "15px" }}>
                        <IonText className="card_text">
                        The essence of Atmagram is humanity. Donate for building them.                        </IonText>
                      </IonRow>
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonButton onClick={() => history.push("/donations")}>

                          <span className="button_padding">
                            View All
                          </span>
                          <IonIcon src={arrowForwardOutline} className="card_btn_icon" />

                        </IonButton>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                  <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                    <IonCard className="card_style">
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonImg src={projectlogo} className="card_img_style" />
                      </IonRow>
                      <IonRow className="card_head_main">
                        <IonText className="card_head">
                          Projects
                        </IonText>
                      </IonRow>
                      <IonRow style={{ padding: "15px" }}>
                        <IonText className="card_text">
                        Select a welfare project of Atmagram and donate/contribute for it.                        </IonText>
                      </IonRow>
                      <IonRow style={{ display: "flex", justifyContent: "center" }}>
                        <IonButton onClick={() => history.push("/project")}>

                          <span className="button_padding">
                            View All
                          </span>
                          <IonIcon src={arrowForwardOutline} className="card_btn_icon" />

                        </IonButton>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </div> : <>
                {/* <h4 style={{ textAlign: "center", padding: "1% 0%" }}>Welcome  To Atmagram</h4>
                <IonImg src={home_image} class="banner" /> */}
              </>}
          </IonGrid>}
        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default Userhome;
