import {
  IonContent,
  IonSelect,
  IonItem,
  IonSelectOption,
  IonPage,
  IonButton,
  IonItemDivider,
  IonRow,
  IonCol,
  IonInput,
  IonAlert,
  IonLabel,
  useIonRouter,
  IonLoading,
  IonText,
  IonIcon,
  IonList,
  IonGrid,
  IonImg,
  useIonAlert
} from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Select, { components } from 'react-select';
import { Link, useHistory } from "react-router-dom";
import Head from "./Head";
import Foot from "./Foot";
import "./HeadFoot.css"
import UserService from "../Services/user.service";
import { Capacitor } from "@capacitor/core";
import { Modal, ModalHeader } from "react-bootstrap";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { close, person, personCircleOutline, personOutline } from "ionicons/icons";
import { closeicon, profile_icon } from "../theme/imagePath";
import HeadFoot from "./Head";
import AdminHead from "./admin/adminHead";
import Sidebar from "./admin/sidebar";
interface State {
  id: number;
  name: string;
}

const UserProfile: React.FC = () => {
  const history = useHistory();
  const [firstname, setFirstname] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [pincode, setPincode] = useState("");
  const [userid] = useState(localStorage.getItem("UserId"));
  const [usertoken] = useState(localStorage.getItem("token"));
  const userdata: any = useState(localStorage.getItem("UserData"));
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  const router = useIonRouter();
  const [showLoading, setShowLoading] = useState(false);
  const [phonemodal, setPhonemodal] = useState(false)
  const [oldnumber, setOldnumber]: any = useState("")
  const [newnumber, setNewnumber]: any = useState("")
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [stateOption, setStateOption]: any = useState([]);
  const [countryOption, setCountryOption]: any = useState([]);

  const [states, setStates]: any = useState([]);
  const [countries, setCountries]: any = useState(["India"]);

  var stateLiistOptions: any = [];
  if (states != undefined && states.length > 0) {
    stateLiistOptions = states.map((e: any, index: any) => ({
      value: e,
      label: e
    }));
  }
  var countryLiistOptions: any = [];
  if (countries != undefined && countries.length > 0) {
    countryLiistOptions = countries.map((e: any, index: any) => ({
      value: e,
      label: e
    }));
  }

  const getAllStates = () => {
    setShowLoading(true);
    UserService.getAllstates().then((response) => {
      console.log(response.data);
      setStates(response.data)
      setShowLoading(false);
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "none",
      boxShadow: 'none',
      fontSize: '15px',
      // height: '44px',
      width: '100%',
      outline: 'none',
      textAlign: "left"
    }),
  };


  const [formErrors, setFormErrors] = useState({
    firstname: '',
    phone: '',
    area: '',
    city: '',
    state: '',
    country: '',
    address: '',
    pincode: '',
    aadhar: '',
    email: '',
    password: '',
    oldnumber: '',
    newnumber: '',
  });


  useEffect(() => {
    console.log(usertoken, userdata, "km");
    if (userid === null || usertoken === null) {
      history.push("/login");
    } else {
      own_profile();
      getAllStates()
    }
  }, []);
  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      const target = event.target as HTMLElement;
      const inputElement = document.querySelector('ion-input');
      console.log(inputElement)
      if (inputElement && !inputElement.contains(target)) {
        inputElement.blur(); // Blur the input to dismiss the keyboard
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  const own_profile = () => {
    setShowLoading(true);
    UserService.myProfile(userid, usertoken).then((response: any) => {
      console.log(response);
      setShowLoading(false);
      if (response.data.firstName != null)
        setFirstname(response.data.firstName);
      setFirstName(response.data.firstName);
      if (response.data.lastName != null)
        setLastname(response.data.lastName);
      if (response.data.mobileNumber != null) {
        if (!response.data.mobileNumber.startsWith("+")) {
          setPhone("+91" + response.data.mobileNumber);
        }
        else {
          setPhone(response.data.mobileNumber)
        }
      }

      if (response.data.area != null)
        setArea(response.data.area);

      if (response.data.city != null)
        setCity(response.data.city);


      if (response.data.state != null) {
        setStateOption({ value: response.data.state, label: response.data.state })
        setState(response.data.state);
      }

      if (response.data.country != null) {
        setCountryOption({ value: response.data.country, label: response.data.country })
        setCountry(response.data.country)
      }
      else {
        setCountry("India");
      }

      if (response.data.aadharNumber != null)
        setAadhar(response.data.aadharNumber);

      setEmail(response.data.email);
      localStorage.setItem("Phone", response.data.mobileNumber);
      if (response.data.aadharNumber != null) {
        setOldnumber(response.data.mobileNumber)
      }

      var pin = response.data.address.split(',');
      var arrLlen = pin.length - 1;
      var add: any = "";
      for (var a = 0; a < arrLlen; a++) {
        if (a == 0) {
          add = add + pin[a];

        } else {
          add = add + "," + pin[a];
        }

      }

      setPincode(pin[arrLlen]);
      setAddress(add);

    })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
  };

  const [Alert] = useIonAlert();
  const handleSubmit = () => {
    const errors: any = {};

    if (firstname === "") {
      errors.firstname = "Please fill the first name";
      // setIserror(true);
    }
    if (phone === "" || phone === undefined) {
      errors.phone = "Please fill the phone number"
      // setIserror(true);
    }
    if (area === "") {
      errors.area = "Please fill the area"
      // setIserror(true);
    }
    if (city === "") {
      errors.city = "Please fill the city name"
      // setIserror(true);
    }
    if (state === "") {
      errors.state = "Please choose the state name"
      // setIserror(true);
    }
    if (country === "") {
      errors.country = "Please choose the country name"
      // setIserror(true);
    }
    if (email === "") {
      errors.email = "Please fill the email address"
      // setIserror(true);
    }
    if (address === "") {
      errors.address = "Please fill the address "
      // setIserror(true);
    }
    if (pincode === "") {
      errors.pincode = "Please fill the pincode "
      // setIserror(true);
    }
    else if (pincode.charAt(0) === "0") {
      errors.pincode = "Pincode not start with 0"
      // setIserror(true);
    }
    else if (pincode.length !== 6) {
      errors.pincode = "Please fill a valid pincode"
      // setIserror(true);
    }
    if (aadhar === "") {
      errors.aadhar = "Please fill the aadhaar number "
      // setIserror(true);
    }
    else if (aadhar.length !== 12) {
      errors.aadhar = "Please fill a valid aadhaar number "
      // setIserror(true);
    }
    else if (aadhar.charAt(0) === "0" || aadhar.charAt(0) === "1") {
      errors.aadhar = "Aadhaar not start with 0 and 1"
      // setIserror(true);
    }
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setShowLoading(true)
      UserService.Profileupdate(aadhar, address, pincode, area, city, country, email, firstname, lastname, phone, state, usertoken)
        .then((response) => {
          setShowLoading(false)
          if (response.data.startsWith("SUCCESS")) {
            Alert({
              header: "Atmagram",
              message: "Profile Updated Successfully",
              backdropDismiss: false,
              buttons: [
                {
                  text: "ok",
                  handler: () => {
                    window.location.reload()
                  }
                }
              ]
            })
            localStorage.setItem("Address", address + "," + pincode);
            localStorage.setItem("Phone", response.data.mobileNumber);
          } else {
            Alert({
              header: "Atmagram",
              message: "Profile Not Updated",
              backdropDismiss: false,
              buttons: [
                {
                  text: "ok",
                }
              ]
            })
          }
        })
        .catch((error) => {
          setShowLoading(false)
          alert(error)
        });
    }

  };
  const handleStateChange = (e: any) => {
    console.log(e)
    if (e) {
      setStateOption(e);
      setState(e.value);
    } else {
      setStateOption({})
      setState("")
    }
  }
  const handlecountryChange = (e: any) => {
    console.log(e)
    if (e) {
      setCountryOption(e);
      setCountry(e.value);
    } else {
      setCountryOption({})
      setCountry("")
    }
  }

  const preventdata = (e: any) => {
    e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
    e.target.addEventListener('keydown', function (u: any) {
      if (u.which === 38 || u.which === 40) {
        u.preventDefault();
      }
    })
  }
  const Validation = (label: any) => {
    if (label === "newnumber") {
      if (newnumber === "") {
        setFormErrors((prev: any) => {
          return { ...prev, newnumber: "Enter the New Mobile Number" }
        })
      }
      else if (oldnumber === newnumber) {
        setFormErrors((prev: any) => {
          return { ...prev, newnumber: "New Mobile Number should not be your Old Mobile Number" }
        })
      } else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, newnumber: "Enter the Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, newnumber: "" }
        })
      }
    }

    if (label === "firstname") {
      if (firstname === "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Please fill the first name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "" }
        })
      }
    }

    if (label === "phone") {
      if (phone === "" || phone === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Please fill the phone number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }
    if (label === "area") {
      if (area === "") {
        setFormErrors((prev: any) => {
          return { ...prev, area: "Please fill the area" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, area: "" }
        })
      }
    }

    if (label === "city") {
      if (city === "") {
        setFormErrors((prev: any) => {
          return { ...prev, city: "Please fill the city name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, city: "" }
        })
      }
    }
    if (label === "state") {
      if (state === "") {
        setFormErrors((prev: any) => {
          return { ...prev, state: "Please choose the state name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, state: "" }
        })
      }
    }

    if (label === "country") {
      if (country === "") {
        setFormErrors((prev: any) => {
          return { ...prev, country: "Please choose the country name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, country: "" }
        })
      }
    }

    if (label === "email") {
      if (email === "") {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please fill the email address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }

    if (label === "address") {
      if (address === "") {
        setFormErrors((prev: any) => {
          return { ...prev, address: "Please fill the address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, address: "" }
        })
      }
    }

    if (label === "pincode") {
      if (pincode === "") {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please fill the pincode" }
        })
      }
      else if (pincode.charAt(0) === "0") {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Pincode not start with 0" }
        })
      }
      else if (pincode.length !== 6) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please fill a valid pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }

    if (label === "aadhar") {
      if (aadhar === "") {
        setFormErrors((prev: any) => {
          return { ...prev, aadhar: "Please fill the aadhaar number" }
        })
      }
      else if (aadhar.length !== 12) {
        setFormErrors((prev: any) => {
          return { ...prev, aadhar: "Please fill a valid aadhaar number" }
        })
      }
      else if (aadhar.charAt(0) === "0" || aadhar.charAt(0) === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, aadhar: "Aadhaar not start with 0 and 1" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, aadhar: "" }
        })
      }
    }
  }

  const ChangeValidation = (label: any, e: any) => {
    if (label === "newnumber") {
      const newnumber = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (newnumber === "") {
        setFormErrors((prev: any) => {
          return { ...prev, newnumber: "Enter the New Mobile Number" }
        })
      }
      else if (oldnumber === newnumber) {
        setFormErrors((prev: any) => {
          return { ...prev, newnumber: "New Mobile Number should not be your Old Mobile Number" }
        })
      } else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, newnumber: "Enter the Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, newnumber: "" }
        })
      }
    }

    if (label === "firstname") {
      const firstname = e.target.value;
      if (firstname === "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "Please fill the first name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname: "" }
        })
      }
    }

    if (label === "phone") {
      const phone = e;

      if (phone === "" || phone === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "Please fill the phone number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone: "" }
        })
      }
    }
    if (label === "area") {
      const area = e.target.value;

      if (area === "") {
        setFormErrors((prev: any) => {
          return { ...prev, area: "Please fill the area" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, area: "" }
        })
      }
    }

    if (label === "city") {
      const city = e.target.value;

      if (city === "") {
        setFormErrors((prev: any) => {
          return { ...prev, city: "Please fill the city name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, city: "" }
        })
      }
    }
    if (label === "state") {
      const state = e.target.value;

      if (state === "") {
        setFormErrors((prev: any) => {
          return { ...prev, state: "Please choose the state name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, state: "" }
        })
      }
    }

    if (label === "country") {
      const country = e.target.value;

      if (country === "") {
        setFormErrors((prev: any) => {
          return { ...prev, country: "Please choose the country name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, country: "" }
        })
      }
    }

    if (label === "email") {
      const email = e.target.value;

      if (email === "") {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Please fill the email address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }

    if (label === "address") {
      const address = e.target.value;

      if (address === "") {
        setFormErrors((prev: any) => {
          return { ...prev, address: "Please fill the address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, address: "" }
        })
      }
    }

    if (label === "pincode") {
      const pincode = e.target.value;

      if (pincode === "") {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please fill the pincode" }
        })
      }
      else if (pincode.charAt(0) === "0") {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Pincode not start with 0" }
        })
      }
      else if (pincode.length !== 6) {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "Please fill a valid pincode" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, pincode: "" }
        })
      }
    }

    if (label === "aadhar") {
      const aadhar = e.target.value;

      if (aadhar === "") {
        setFormErrors((prev: any) => {
          return { ...prev, aadhar: "Please fill the aadhaar number" }
        })
      }
      else if (aadhar.length !== 12) {
        setFormErrors((prev: any) => {
          return { ...prev, aadhar: "Please fill a valid aadhaar number" }
        })
      }
      else if (aadhar.charAt(0) === "0" || aadhar.charAt(0) === "1") {
        setFormErrors((prev: any) => {
          return { ...prev, aadhar: "Aadhaar not start with 0 and 1" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, aadhar: "" }
        })
      }
    }
  }

  const changemobileno = () => {
    const errors: any = {};
    if (newnumber === "") {
      errors.newnumber = "Enter the New Mobile Number"
    }
    else if (oldnumber === newnumber) {
      errors.newnumber = "New Mobile Number should not be your Old Mobile Number"
    } else if (!isValidPhoneNumber) {
      errors.newnumber = "Enter the Valid Mobile Number"
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      UserService.ChangeNumber(
        newnumber,
        usertoken
      )
        .then((response) => {
          console.log(response)
          if (response.data === "Mobile Number Already Exist") {
            alert(response.data)
          } else {
            alert("Confirmation Mail sent to your registered mail id, Please verify to proceed with mobile number change request");
            history.push("/home");
          }
        })
        .catch((error) => {
          alert("Something went wrong, please try again later.");
        });
    }
  }

  const phoneInputRef = useRef(null)

  const moveCursorToEnd = (value: any) => {
    const input: any = phoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
      console.log(phoneNumberObject.isValid())
    } catch (error) {
      setIsValidPhoneNumber(false);
      console.log(error)
    }
    setNewnumber(value)
  };



  return (
    <IonPage>
      {userdata[0] != "ROLE_USER" ?
        <AdminHead User={"User"} Role={"User"} Name={"My Profile"} />
        : <></>}
      <IonContent>
        {userdata[0] === "ROLE_USER" ?
          <HeadFoot User={"login"} Role={'Role'} Name={"Home,My Profile"} />
          :
          <></>
        }
        {userdata[0] != "ROLE_USER" ?
          <Sidebar /> : <></>
        }
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        />
        <Modal show={phonemodal} className="profile-modal">
          <ModalHeader className="profilenodal-Header">

            <h5 className="profileModal-title">Mobile Number Change</h5>

            <div>
              <IonIcon src={close} className="close_icon" onClick={() => {
                setPhonemodal(false);
                setFormErrors((prev: any) => {
                  return { ...prev, newnumber: "" }
                })
              }} />
            </div>
          </ModalHeader>
          <Modal.Body>

            <IonRow>
              <IonCol size="12">
                <IonLabel className="form-labels">
                  New Mobile Number
                </IonLabel>
                <PhoneInput
                  international
                  defaultCountry="IN"
                  value={newnumber}
                  className="profile-inputs"
                  // flags=false
                  ref={phoneInputRef}
                  onChange={(value: any) => { moveCursorToEnd(value); ChangeValidation("newnumber", value) }}
                  onBlur={() => Validation("newnumber")}
                />
                {formErrors.newnumber && <span className="profile-errors">{formErrors.newnumber}</span>}
              </IonCol>
            </IonRow>
            <IonButton onClick={() => changemobileno()} className="changeNum-btn">
              Change
            </IonButton>
          </Modal.Body>
        </Modal>
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="my-custom-class"
          header={"Error!"}
          message={message}
          buttons={["Dismiss"]}
        />
        <div className={` page_content_padding ${userdata[0] != "ROLE_USER" ? 'page-wrapper admin-userprofile' : "userprofile-container"}`}>
          <IonGrid>
            <IonRow className={` ${userdata[0] != "ROLE_USER" ? 'admin_profile_content' : ""}`}>
              {userdata[0] != "ROLE_USER" ? <></> :
                <>
                  <IonCol size-lg="3" size-md="0" size-sm="0" size-xs="0">
                    <div className="list-container">
                      <div style={{ paddingTop: "40px" }}>
                        {/* <IonImg src={profile_icon} className="user-profile" /><br /> */}
                        <IonText className="profile-userName">{firstName}</IonText><br />
                        <IonText className="profile-mail">{email}</IonText>
                      </div>
                      <IonList>
                        <div>
                          <Link to="/profile" className="userprofile-links">
                            My Profile
                          </Link>
                        </div>
                        <div>
                          <Link to="/myorders" className="userprofile-links">
                            My Offerings
                          </Link>
                        </div>
                        <div>
                          <Link to="/mymaterial" className="userprofile-links">
                            My Materials
                          </Link>
                        </div>
                        <div>
                          <Link to="/mygrammerce" className="userprofile-links">
                            My Orders
                          </Link>
                        </div>
                        <div>
                          <Link to="/mycart" className="userprofile-links">
                            My Cart
                          </Link>
                        </div>
                        <div>
                          <Link to="/mydonation" className="userprofile-links">
                            My Donation
                          </Link>
                        </div>
                        <div>
                          <Link to="/myprojectdonation" className="userprofile-links">
                            My Project Donation
                          </Link>
                        </div>
                      </IonList>
                    </div>
                  </IonCol>
                </>}
              <IonCol size-lg={userdata[0] != "ROLE_USER" ? "10" : "9"} size-md="12" size-sm="12" size-xs="12">
                <div className="form-container">
                  <IonRow className="gab-between-inputs">
                    <IonCol size="12" size-xl="6"><div>
                      <h2 className="form-title">Personal Information</h2>
                    </div></IonCol></IonRow>
                  <IonRow className="gab-between-inputs">
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">First Name <span style={{ color: 'red' }}>*</span></IonLabel>
                        <IonInput
                          className="profile-inputs"
                          placeholder="Enter text"
                          type="text"
                          value={firstname}
                          onBlur={() => Validation("firstname")}
                          onIonChange={(e) => { setFirstname(e.detail.value!); ChangeValidation("firstname", e) }}
                        ></IonInput>
                        {formErrors.firstname && <span className="profile-errors">{formErrors.firstname}</span>}
                      </div>
                    </IonCol>
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">Last Name <span></span></IonLabel>
                        <IonInput
                          className="profile-inputs"
                          type="text"
                          value={lastname}
                          onIonChange={(e) => setLastname(e.detail.value!)}
                          required
                        ></IonInput>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="gab-between-inputs">
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels" >
                          Mobile Number <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <PhoneInput
                          international
                          className="profile-inputs"
                          defaultCountry="IN"
                          value={phone}
                          readOnly={true}
                          onBlur={() => Validation("phone")}
                          onChange={(value: any) => { setPhone(value); ChangeValidation("phone", value) }}
                        />
                        <IonRow style={{ textAlign: 'left', paddingTop: "5px" }}>
                          <IonText onClick={() => setPhonemodal(true)} tabIndex={0}
                            onKeyDown={(event: any) => {
                              if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
                                setPhonemodal(true)
                              }
                            }}
                            style={{ cursor: "pointer", textDecoration: "underline", color: "blue", fontSize: "13px", textAlign: "left" }}>change the mobile number</IonText>
                        </IonRow>
                        {formErrors.phone && <span className="profile-errors">{formErrors.phone}</span>}
                      </div>
                    </IonCol>
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">Email <span style={{ color: 'red' }}>*</span></IonLabel>
                        <IonInput
                          type="email"
                          className="profile-inputs"
                          value={email}
                          readonly={true}

                          onBlur={() => Validation("email")}
                          onIonChange={(e) => { setEmail(e.detail.value!); ChangeValidation("email", e) }}
                        ></IonInput>
                        {formErrors.email && <span className="profile-errors">{formErrors.email}</span>}
                      </div>
                    </IonCol>

                  </IonRow>
                  <IonRow className="gab-between-inputs">
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">
                          Country <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <Select
                          options={countryLiistOptions}
                          value={countryOption}
                          className="profile-autoSelect"
                          placeholder="Select a Country"
                          isClearable
                          styles={customStyles}
                          onBlur={() => Validation("country")}
                          onChange={(e) => { handlecountryChange(e); ChangeValidation("country", e) }}
                        />
                        {formErrors.country && <span className="profile-errors">{formErrors.country}</span>}
                      </div>
                    </IonCol>
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">
                          State <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <Select
                          options={stateLiistOptions}
                          value={stateOption}
                          className="profile-autoSelect"
                          placeholder="Select a State"
                          isClearable
                          styles={customStyles}
                          onBlur={() => Validation("state")}
                          onChange={(e) => { handleStateChange(e); ChangeValidation("state", e) }}
                        />
                        {formErrors.state && <span className="profile-errors">{formErrors.state}</span>}
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="gab-between-inputs">
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">City <span style={{ color: 'red' }}>*</span></IonLabel>
                        <IonInput
                          type="text"
                          className="profile-inputs"
                          value={city}
                          required
                          onBlur={() => Validation("city")}
                          onIonChange={(e) => { setCity(e.detail.value!); ChangeValidation("city", e) }}
                        ></IonInput>
                        {formErrors.city && <span className="profile-errors">{formErrors.city}</span>}
                      </div>
                    </IonCol>
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">Area <span style={{ color: 'red' }}>*</span></IonLabel>
                        <IonInput
                          type="text"
                          value={area}
                          className="profile-inputs"
                          required
                          onBlur={() => Validation("area")}
                          onIonChange={(e) => { setArea(e.detail.value!); ChangeValidation("area", e) }}
                        ></IonInput>
                        {formErrors.area && <span className="profile-errors">{formErrors.area}</span>}
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="gab-between-inputs">
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">Address <span style={{ color: 'red' }}>*</span></IonLabel>
                        <IonInput
                          className="profile-inputs"
                          type="text"
                          required
                          value={address}
                          onBlur={() => Validation("address")}
                          onIonChange={(e) => { setAddress(e.detail.value!); ChangeValidation("address", e) }}
                        ></IonInput>
                        {formErrors.address && <span className="profile-errors">{formErrors.address}</span>}
                      </div>
                    </IonCol>
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">Pincode <span style={{ color: 'red' }}>*</span></IonLabel>
                        <IonInput
                          type="number"
                          className="profile-inputs"
                          required
                          value={pincode}
                          onFocus={preventdata}
                          onBlur={() => Validation("pincode")}
                          onIonChange={(e) => { setPincode(e.detail.value!); ChangeValidation("pincode", e) }}
                        ></IonInput>
                        {formErrors.pincode && <span className="profile-errors">{formErrors.pincode}</span>}
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="gab-between-inputs">
                    <IonCol size="12" size-xl="6">
                      <div>
                        <IonLabel className="form-labels">Aadhaar Number <span style={{ color: 'red' }}>*</span></IonLabel>
                        <IonInput
                          type="number"
                          className="profile-inputs"
                          required
                          onFocus={preventdata}
                          onBlur={() => Validation("aadhar")}
                          value={aadhar}
                          onIonChange={(e) => { setAadhar(e.detail.value!); ChangeValidation("aadhar", e) }}
                        ></IonInput>
                        {formErrors.aadhar && <span className="profile-errors">{formErrors.aadhar}</span>}
                      </div>
                    </IonCol>
                    <IonCol size="12" size-xl="6" />
                  </IonRow>
                  <IonRow className="gab-between-inputs">
                    <IonCol size="12" size-lg="12" className="centerBtn">
                      <div style={{ width: "fit-content" }} className={firstname.length === 0 ||
                        area.length === 0 ||
                        city.length === 0 ||
                        state.length === 0 ||
                        country.length === 0 ||
                        email.length === 0 ||
                        address.length === 0 ||
                        aadhar.length === 0 ? "disableCursor" : ""}>
                        <IonButton

                          onClick={handleSubmit}
                          disabled={
                            firstname.length === 0 ||
                            area.length === 0 ||
                            city.length === 0 ||
                            state.length === 0 ||
                            country.length === 0 ||
                            email.length === 0 ||
                            address.length === 0 ||
                            aadhar.length === 0
                          }
                        >
                          <span className="button_padding">
                            Update
                          </span>
                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        {userdata[0] != "ROLE_USER" ? <></> :
          <Foot />}
      </IonContent>
    </IonPage >
  );
};
export default UserProfile;
