import { IonText, IonImg, IonInput, IonRow, IonCol, IonLabel, IonButton, IonGrid, IonSelect, IonSelectOption, IonIcon, IonCheckbox, IonItem, IonPage, IonContent, IonItemDivider, IonLoading, useIonAlert, IonSearchbar, IonAlert, IonProgressBar, IonCard, IonBadge } from '@ionic/react'
import React, { useContext, useEffect, useRef, useState } from "react";
import { addCircle, cart, checkmarkCircleSharp, close, codeDownloadSharp, informationCircle, removeCircle } from "ionicons/icons";
import { removeOutline, addOutline, calendarOutline, informationCircleSharp, addCircleOutline, removeCircleOutline, arrowBack, closeOutline, arrowForward } from "ionicons/icons";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import useRazorpay from 'react-razorpay';
import moment from 'moment';
import { Modal, ModalHeader } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ordermaterials.css";
import Foot from "./Foot";
import "./sam-temp.css";
import "jquery/dist/jquery.min.js";
import { useHistory, useLocation } from "react-router";
import Authservice from '../Services/user.service';
import UserService from '../Services/auth.service';
import { useForm } from 'react-hook-form';
import { Capacitor } from '@capacitor/core';
import validator from 'validator'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { AppContext } from '../components/AppContext';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Select, { components } from 'react-select';
import HeadFoot from './Head';
import { closeicon, infoicon, tick } from '../theme/imagePath';
import Lightbox from 'react-image-lightbox';
import { Link } from 'react-router-dom';
import { Icon } from 'ionicons/dist/types/components/icon/icon';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../Services/firebase-config';


interface State {
  id: number;
  name: string;
}
const Poojamaterials: React.FC = () => {
  const [userdata, setUserdata]: any = useState(localStorage.getItem("UserData"));
  const [showLoading, setShowLoading] = useState(false);
  const [deitylist, setDeitylist] = useState([]);
  const [errorText, setErrorText] = useState("")
  const [tabId, setTabId]: any = useState("")
  const history = useHistory()
  const [image, setImage] = useState("");
  const [addshowModal, setAddshowModal] = useState(false)
  // const [name, setName] = useState("");
  const [deityName, setDeityName] = useState("");
  const [description, setDescription] = useState("");
  const [isShow, setIsshow] = useState(false);
  const [islogin, Islogin] = useState(false);
  const [id, setId] = useState("");
  const [deityid, setDeityid] = useState("");
  const token: any = useState(localStorage.getItem("token"));
  const location: any = useLocation()
  const [actionFlag, setActionFlag] = useState(false);
  const [index1, setIndex1] = useState(0);
  var confirmPage = false;
  const buttonClickedRef = useRef(index1);
  const selectedProducts: any = useRef([]);
  const [keyIndex, setKeyIndex] = useState(0)
  const formatter = new Intl.NumberFormat('en-IN')
  const actionClickedRef = useRef(actionFlag);
  const [formErrors, setFormErrors]: any = useState({});
  const { setUFlag } = useContext(AppContext)!;
  const { setToken } = useContext(AppContext)!;
  const { setUserData } = useContext(AppContext)!;
  const [showAlert, setShowAlert] = useState(false);
  const [returnPage, setRtnPage] = useState("");
  const [stateOption, setStateOption]: any = useState([]);
  const [districtOption, setDistrictOption]: any = useState([]);
  const [countryOption, setCountryOption]: any = useState({ value: "India", label: "India" });
  const [states, setStates]: any = useState([])
  const [districts, setDistricts]: any = useState([])
  const [districtMenu, setDistrictMenu]: any = useState(false)
  const [stateMenu, setStateMenu]: any = useState(false)
  const [countries, setCountries] = useState(["India"])
  const [counErrors, setcountErrors]: any = useState({});
  const [headName, setHeadName] = useState('');

  const stateLiistOptions = states.map((e: any, index: any) => ({
    value: e,
    label: e
  }));
  const districtLiistOptions = districts.map((e: any, index: any) => ({
    value: e,
    label: e
  }));
  const countryLiistOptions = countries.map((e: any, index: any) => ({
    value: e,
    label: e
  }));

  const uFlag: any = useState(localStorage.getItem("uFlag"))
  const [hideContent, setHideContent] = useState(false)

  useEffect(() => {
    console.log(userdata, uFlag)
    setHeadName("Home,Select Deity")
    if (userdata !== null && userdata !== "ROLE_USER") {
      history.push("/home")
    }
    else if (userdata !== null && uFlag[0] === "false") {
      history.push("/home")
    }
    else if (userdata === null) {
      Islogin(false)
      getallgods();
    } else {
      Islogin(true)
      getallgods();
    }

    const unlisten = history.block((location, action) => {

      if (!actionClickedRef.current && (buttonClickedRef.current === 7 || buttonClickedRef.current === 5 || buttonClickedRef.current === 4)) {
        setRtnPage(location.pathname)
        setActionFlag(true)
        setShowAlert(true);
        return false;
      }
      console.log('Leaving MyPage');

    });

    return () => {
      unlisten();
    };

  }, [history]);
  const handleLeave = () => {

    setShowAlert(false);


    history.push(returnPage); // Example: Navigate to the new page
  };

  const getallgods = () => {
    setShowLoading(true);
    UserService.Getallgods(islogin, token[0]).then((response: any) => {
      console.log(response.data)
      if (response.data !== undefined || response.data.length !== 0) {
        setDeitylist(response.data);
      } else {
        setDeitylist([]);
        setErrorText("Sorry, No data available")
      }
      setShowLoading(false)

    })
      .catch((error: any) => {
        console.log(error);
        setErrorText("Sorry, No data available")
        setShowLoading(false);
      });
  }
  const Viewinfo = (item: any) => {
    setImage(item.image);
    setDeityName(item.deityName);
    setDescription(item.deityDescription);
    setHeadName("Home,Biography")
    setIndex1(1);
  };
  const [prev, setPrev] = useState(true)
  const Details = (item: any) => {
    if (Capacitor.getPlatform() !== "web" && gods.length === 0) {
      setHideContent(true)
    }
    else {
      setHideContent(false)
    }
    setGods([])
    setId(item.id)
    setDeityid(item.id)
    console.log(item.id)
    setDeityName(item.deityName);
    getAllState()
    setHeadName("Home,Select Area")
    setIndex1(2);
  }


  const inputArr = [
    {

      value: ""
    }
  ];


  const [phone, setPhone]: any = useState(localStorage.getItem("Phone"));
  const [data, setData]: any = useState([])
  const [activeIND, setActiveind]: any = useState(false)
  const [username, setUserName]: any = useState("");
  const [star, setStar]: any = useState("");
  const [tot, setTot]: any = useState("")
  const [Alert] = useIonAlert();
  const [dates, setDates]: any = useState(inputArr)
  const [sortdates, setSortDates]: any = useState([])
  const [onedate, setOnedate]: any = useState([]);
  const [limit, setLimit]: any = useState([]);
  const Razorpay = useRazorpay();
  const [usertoken, setUsertoken] = useState(localStorage.getItem("token"));
  const [state, setState]: any = useState();
  const [country, setCountry] = useState("India");
  const [district, setDistrict]: any = useState()
  const [town, setTown]: any = useState()
  const [gods, setGods] = useState([])
  const [tempname, setTempname] = useState("");
  const [ValidCheck, setValidCheck]: any = useState(false);
  const [confirmdates, setConfirmdates]: any = useState([]);
  const [modalopen, setModalopen] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false)
  const [modalopen1, setModalopen1] = useState(false);
  const [temName, setTemName]: any = useState("")
  const [temId, setTemId]: any = useState("")
  const [donationamt, setDonationamt]: any = useState("")
  const [dakshinaamt, setDakshiamt]: any = useState("")
  const [paydakshinapreist, setPaydakshinapreist]: any = useState(false)
  const [paydakshinatemple, setPaydakshinatemple]: any = useState(false)
  const [delChargeData, setDelChargeData]: any = useState("")
  const [donP, setDonP]: any = useState(0)
  const [dontemp, setDontemp]: any = useState(0)
  let [totalAmount, setTotalAmount]: any = useState(0)
  const [searchQuery, setSearchQuery]: any = useState("");
  const [showgod, setShowgod] = useState(false);
  const [templeDeity, setTempleDeity]: any = useState([])
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const widthSize = window.innerWidth
  const [slidesToShow, setSlidesToShow]: any = useState(widthSize > 500 ? 2 : widthSize < 500 ? 1 : 0);
  const sliderRef: any = useRef()
  const [deityId, setDeityId]: any = useState("")
  const [isHovered, setIsHovered] = useState(false)
  const [hoveredItem, sethoveredItem]: any = useState("")
  const [viewImg, setViewImg]: any = useState()
  const [viewProductName, setViewProductName]: any = useState()
  const [viewBrandName, setViewBrandName]: any = useState()
  const [viewPrice, setViewPrice]: any = useState()
  const [viewPsize, setViewPsize]: any = useState()
  const [viewPunit, setViewPunit]: any = useState()

  const [viewStock, setViewStock]: any = useState()
  const [viewQuantity, setViewQuantity]: any = useState()
  const [viewItem, setViewItem]: any = useState()
  const [viewIndex, setViewIndex]: any = useState()
  const [viewProgressValue, setViewProgressValue]: any = useState()
  var amount = 0;

  var ttt: any = 0
  const { handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const targetRef: any = useRef(null);

  const Submit = () => {
    const errors: any = {};
    if (country.length == 0) {
      errors.country = "Please select the country"
    }
    setcountErrors(errors);
    if (Object.keys(errors).length == 0) {
      setShowLoading(true)
      UserService.SearchTemple(usertoken, country, state, district, town, id, temName)
        .then((response: any) => {
          console.log("Res=>", response)
          setGods(response.data)
          setShowgod(true);
          if (Capacitor.getPlatform() !== "web") {
            setHideContent(false)
            setTimeout(() => {
              if (targetRef.current) {
                targetRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }, 500)
          }
          setShowLoading(false)
        })
        .catch((error: any) => {
          console.log(error);
          setShowLoading(false)
        });
    }
  };
  const clear = () => {
    console.log("clear")
    setState("");
    setDistrict("");
    setTown("");
    setTemName("");
    setStateOption(null)
    setDistrictOption(null)
    setShowgod(false);
    if (Capacitor.getPlatform() !== "web") {
      setHideContent(true)
    }
  };
  const NextArrow = (props: any) => {
    const { className, onClick } = props;
    console.log(props)
    const currentSlide = props.currentSlide
    const slideCount = props.slideCount
    return (
      <div className="slick_nxt" onClick={onClick} style={{ display: currentSlide === slideCount - slidesToShow ? 'none' : 'block' }} >
        <IonIcon icon={arrowForward} />
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { className, onClick } = props;
    const currentSlide = props.currentSlide
    return (
      <div className="slick_prev" onClick={onClick} style={{ display: currentSlide === 0 ? 'none' : 'block' }}>
        <IonIcon icon={arrowBack} />
      </div>
    );
  };
  const [Settings, setSettings] = useState({});

  const [storeId, setStroreId] = useState("");
  const show = (name: any, id: any) => {
    setShowLoading(true)
    setTemId(id)
    setTabId(0);
    setHeadName("Home,Offering Materials")
    setIndex1(3);
    setTempname(name);
    setSearchQuery("")

    if (id != storeId) {
      setTempleDeity([])
      setDeityId("")
      setData([])
      var DeityArr: any = []
      var DeityArr2: any = []
      selectedProducts.current = []
      setTot(0)
      setActiveind(false)
      setStroreId(id)
    } else {
      setShowLoading(false)
      setStroreId(id)
      return
    }
    // setShowLoading(true)

    Authservice.ShowAllPoojaMaterial(id)
      .then((response) => {
        console.log(response);
        const arr = response.data.materialList.map((e: any) => {
          return { ...e, availableStock: e.materialStock }
        })
        setData(arr);
        // setTempleDeity(response.data.deityList)
        for (let i = 0; i < response.data.deityList.length; i++) {
          if (deityid === response.data.deityList[i].id) {
            DeityArr.push(response.data.deityList[i])
            setDeityId(DeityArr[0].id)
            setMaterialdeity(DeityArr[0].deityName)
          } else {
            DeityArr2.push(response.data.deityList[i])
          }
        }
        setTempleDeity([
          ...DeityArr, ...DeityArr2
        ])
        if (response.data.deityList.length === 0) {
          setSettings({
            dots: false,
            horizontal: true,
            speed: 500,
            slidesToShow: 3,
            infinite: false,
            slidesToScroll: 3,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,

                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,

                }
              }
            ]
          })
        }
        else {
          setSettings({
            dots: false,
            horizontal: true,
            speed: 500,
            slidesToShow: 2,
            infinite: false,
            slidesToScroll: 2,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,

                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,

                }
              }
            ]
          })
        }
        // console.log(response.data.deityList,"jnjjjj")
        setShowLoading(false)
      })

      .catch((error) => {
        // alert("Something went wrong, please try again later.");
        setShowLoading(false)
      });

  }

  const [materialdeity, setMaterialdeity]: any = useState("");
  const [dak, setDak]: any = useState(false);
  const [don, setDon] = useState(false);
  const [dakamt1, setDakamt1] = useState(false)
  const inputRef = useRef<any>(null);
  const dakamt = (e: any) => {
    if (e.detail.checked) {
      setDak(true)
      setDakamt1(true)
      setPaydakshinapreist(true)
      setTimeout(() => {
        inputRef.current?.setFocus();
      }, 500);
    }
    else {
      setDak(false)
      setDakamt1(false)
      setPaydakshinapreist(false)
      setDakshiamt("");
    }
    setFormErrors((prev: any) => {
      return { ...prev, dakamt1: "" }
    })
  }
  const [donamt1, setDonamt1] = useState(false)
  const inputRef1 = useRef<any>(null);
  const donamt = (e: any) => {
    if (e.detail.checked) {
      setDon(true)
      setDonamt1(true)
      setPaydakshinatemple(true)
      setTimeout(() => {
        inputRef1.current?.setFocus();
      }, 500);
    }
    else {
      setDon(false)
      setDonamt1(false)
      setPaydakshinatemple(false)
      setDonationamt("")
    }
    setFormErrors((prev: any) => {
      return { ...prev, donamt1: "" }
    })
  }
  const searchItems: any = Object.values(data)
  const [searchParam] = useState(["productName"]);
  const [rerenderKey, setRerenderKey] = useState(0);
  const search = (datas: any) => {

    return datas.filter((item: any) => {
      if (item.productName) {
        return searchParam.some((newItem: any) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchQuery.toLowerCase()) > -1
          );
        });
      }
    });
  }
  const deitySelect = (e: any) => {
    setDeityId(e.target.value)
    for (let i = 0; i < templeDeity.length; i++) {
      if (templeDeity[i].id == e.target.value) {
        setMaterialdeity(templeDeity[i].deityName)
      }
    }
  }
  const preventdata = (e: any) => {
    e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
    e.target.addEventListener('keydown', function (u: any) {
      if (u.which === 38 || u.which === 40) {
        u.preventDefault();
      }
    })
  }
  const quantityIncre = (val: any, e: any, items: any) => {
    var arr = [];
    arr = data;
    const newState = arr.map((item: any, iii: any) => {
      if (items.id === item.id) {
        var max = parseInt(items.materialStock, 10);
        var ii = parseInt(item.quantity, 10) + 1;
        if (ii > 0) {
          setValidCheck(true);
        }
        console.log(item.availableStock)
        if (item.availableStock <= 0) {
          Alert({
            header: 'Alert',
            message: max + ' Stock only  ',
            buttons: ['OK'],
          })
        } else {
          if (selectedProducts.current.length > 0) {
            var isFound: boolean = false;
            for (var i = 0; i < selectedProducts.current.length; i++) {
              if (selectedProducts.current[i].id == items.id && selectedProducts.current[i].deityId === deityId) {
                selectedProducts.current[i].quantity = selectedProducts.current[i].quantity + 1;
                isFound = true;
              }
            }
            if (!isFound) {
              selectedProducts.current = [...selectedProducts.current, { ...items, quantity: 1, deityName: materialdeity, deityId: deityId }]
            }
            calAmt();
          } else {
            selectedProducts.current = [...selectedProducts.current, { ...items, quantity: 1, deityName: materialdeity, deityId: deityId }]
            calAmt();
          }
          setViewQuantity(viewQuantity + 1)
          if (val == "home") {
            Alert({
              header: 'Atmagram',
              message: 'Item added to cart',
              buttons: ['OK'],
            })
          }
        }
        return { ...item, availableStock: item.availableStock > 0 ? item.availableStock - 1 : 0 };
      }
      return item;
    });


    setData(newState);
    e.stopPropagation()

  }
  const calAmt = () => {
    let t: any = 0;
    selectedProducts.current.map((p: any) => {
      if (p.quantity > 0) {
        t = t + (p.quantity * p.price)
        setTot(t)
        setActiveind(true)
        return t
      }
    })

  }
  const removeItem = (index: any) => {
    var arr = [];
    arr = data;
    const newarr = arr.map((item: any, i: any) => {
      if (item.id === delItem.id) {
        console.log(delItem.quantity)
        var val = 0;
        var quant = delItem.quantity
        return { ...item, availableStock: item.availableStock + quant }
      }
      return item
    })
    for (let i = 0; i < selectedProducts.current.length; i++) {
      if (selectedProducts.current[i].id == delItem.id && selectedProducts.current[i].deityId == delItem.deityId) {
        console.log(selectedProducts.current[i].quantity)
        selectedProducts.current[i].quantity = 0
        selectedProducts.current.splice(i, 1)
      }
    }
    setActiveind(true)
    setData(newarr)
    console.log(newarr, "hbh")
    if (selectedProducts.current.every((e: any) => (e.quantity == 0))) {
      setValidCheck(false)
      setTot(0)
      setActiveind(false)
    }
    setdeleteModal(false)
    setDelItem('')
    calAmt();
  }
  const dakshina = (e: any) => {
    setDakshiamt(e.detail.value)
    setDonP(parseInt(e.detail.value, 10))
  }
  const donation = (e: any) => {
    setDonationamt(e.detail.value)
    setDontemp(parseInt(e.detail.value, 10))
  }
  const handleChange = (event: any, items: any) => {
    if (event.target.value.includes('-')) {
      const rem = event.target.value.replace(/-/g, '');
      event.target.value = rem;
    }
    function getMatchingObjectsById(array: any, id: any, deity: any) {
      return array.filter((obj: any) => (obj.id === id && obj.deityId != deity));
    }
    function sumArray(array: any) {
      let sum = 0;
      for (let i = 0; i < array.length; i++) {
        sum += array[i].quantity;
      }
      return sum;
    }
    if (event.target.value.length > 0) {
      const min = 0;
      const max = items.materialStock + 1;
      const value = Math.round(Math.max(min, Math.min(max, Number(event.target.value))))
      var curdeityId: any = ""
      if (items.deityId !== undefined) {
        curdeityId = items.deityId
      } else {
        curdeityId = deityId
      }
      const matchingObjects = getMatchingObjectsById(selectedProducts.current, items.id, curdeityId);


      const result = sumArray(matchingObjects)
      const newState = data.map((item: any, iii: any) => {
        if (item.id === items.id) {
          var ii = value;
          if (ii > 0) {
            setValidCheck(true)
          }
          console.log(result)
          if ((item.materialStock - (result + parseInt(event.target.value))) < 0) {
            Alert({
              header: 'Alert',
              message: items.materialStock + ' Stock only  ',
              buttons: ['OK'],
            })
          } else {
            if (selectedProducts.current.length > 0) {
              var isFound: boolean = false;

              for (var i = 0; i < selectedProducts.current.length; i++) {
                if (selectedProducts.current[i].id == items.id && selectedProducts.current[i].deityId === curdeityId) {
                  selectedProducts.current[i].quantity = parseInt(event.target.value)
                  isFound = true;
                }
              }
              if (!isFound) {
                selectedProducts.current = [...selectedProducts.current, { ...items, quantity: parseInt(event.target.value), deityName: materialdeity, deityId: deityId }]
              }
            } else {
              selectedProducts.current = [...selectedProducts.current, { ...items, quantity: parseInt(event.target.value), deityName: materialdeity, deityId: deityId }]
            }
            setViewQuantity(parseInt(event.target.value))
            return { ...item, availableStock: (item.materialStock - (result + parseInt(event.target.value))) }
          }

        }
        return item;
      });
      setData(newState);

      setActiveind(true)
      let t: any = 0;
      selectedProducts.current.map((p: any) => {
        if (p.quantity > 0) {
          t = t + (p.quantity * p.price)
          setTot(t)
          setActiveind(true)
          return t
        }
      })
      if (selectedProducts.current.every((e: any) => (e.quantity === 0))) {
        setValidCheck(false)
        setActiveind(false)
        setTot(0)
      }
    }

  };
  const quantityIncreChoose = (items: any, i: any) => {
    var arr = [];
    arr = data;

    const newState = arr.map((item: any, iii: any) => {
      if (item.id === items.id) {
        if (item.availableStock > 0) {
          console.log(selectedProducts.current[i].quantity, item.availableStock)
          selectedProducts.current[i].quantity = selectedProducts.current[i].quantity + 1
          return { ...item, availableStock: item.availableStock - 1 };
        } else {
          Alert({
            header: 'Alert',
            message: items.materialStock + ' Stock only  ',
            buttons: ['OK'],
          })
          return item;
        }

      } else {
        return item;
      }

    });

    setData(newState);
    let t: any = 0;
    selectedProducts.current.map((p: any) => {
      if (p.quantity > 0) {
        t = t + (p.quantity * p.price)
        setTot(t)
        setActiveind(true)
        return t
      }
    })
  }

  const quantityDecre = (items: any, ii: any) => {
    console.log(ii)
    var arr = [];
    arr = data;

    const newState = arr.map((item: any, iii: any) => {
      if (item.id === items.id) {
        if (item.availableStock < item.materialStock) {
          return { ...item, availableStock: item.availableStock + 1 }
        }
      }
      return item;
    });
    if (ii === undefined) {
      for (var i = 0; i < selectedProducts.current.length; i++) {
        if (selectedProducts.current[i].id == items.id && selectedProducts.current[i].deityId === deityId) {
          selectedProducts.current[i].quantity = selectedProducts.current[i].quantity - 1;
          setViewQuantity(viewQuantity - 1)
        }
      }
    } else {
      if (selectedProducts.current[ii].quantity > 0) {
        console.log("ln")
        selectedProducts.current[ii].quantity = selectedProducts.current[ii].quantity - 1
        setViewQuantity(viewQuantity - 1)
      }
    }
    setData(newState);
    setActiveind(true)
    console.log(newState, "kj")

    let t: any = 0;
    selectedProducts.current.map((p: any) => {
      if (p.quantity > 0) {
        t = t + (p.quantity * p.price)
        setTot(t)
        setActiveind(true)
        return t
      }
    })
    if (selectedProducts.current.every((e: any) => (e.quantity === 0))) {
      setValidCheck(false)
      setActiveind(false)
      setTot(0)
    }
  }
  const isFound = (val: any) => {
    return dates.some((item: any) => val === item.value);
  }
  const pastDate = (val: any) => {
    return dates.some((item: any) => val > new Date(item.value).toISOString().split('T')[0]);
  }
  const addInput = () => {
    console.log(dates, "dates")
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const val = tomorrow.toISOString().split('T')[0]
    var valSet = isFound('');
    var past = pastDate(val)
    const errors: any = {}

    if (!valSet && !past) {
      setDates((e: any) => {
        return [
          ...e,
          {
            value: ""
          }
        ];
      });
      return;

    } else if (past) {
      errors.date = "Please select future date"
    } else {
      errors.date = "Enter Date before adding a new Date"
    }

    setFormErrors(errors)
  };


  const handleQuantity = (e: any, item: any) => {
    console.log(e.target.value.length)
    if (e.target.value.length == 0) {
      for (let i = 0; i < selectedProducts.current.length; i++) {
        if (selectedProducts.current[i].id == item.id) {
          console.log(selectedProducts.current[i].quantity)
          selectedProducts.current[i].quantity = 0
          setKeyIndex(keyIndex + 1)
        }
      }
    }
  }

  useEffect(() => {
    buttonClickedRef.current = index1;
    actionClickedRef.current = actionFlag;
  }, [index1, actionFlag]);

  const [loginmodal, setLoginmodal] = useState(false)
  const bookpayment = (e: any) => {
    setShowLoading(true)
    var list: any = []
    selectedProducts.current.map((item: any, i: any) => {
      if (item.quantity > 0) {
        list.push({ deityId: item.deityId, materialId: item.id, quantity: item.quantity, price: item.price, deityName: item.deityName })
      }
    })
    console.log(list)

    var dataPay: any = {}
    // const datesList = onedate.map((e: any) => new Date(e))
    //   .sort((a: any, b: any) => a - b)
    // console.log(datesList, "sorting the dates")
    // const listofdates = datesList.map((e: any) => moment(e).format("DD-MM-YYYY"))
    const listofdates = onedate;
    console.log(listofdates, "changing the format of date")
    dataPay = {
      "templeId": temId,
      "name": username,
      "star": star,
      "listOfdates": listofdates,
      "materialDeityDto": list,
      "payDakshinaToPriestFlag": paydakshinapreist,
      "payDakshinaToTempleFlag": paydakshinatemple,
      "totalAmount": tot
    }
    if (paydakshinatemple) {
      dataPay["dakshinaAmountToTemple"] = donationamt;
    }
    if (paydakshinapreist) {
      dataPay["dakshinaAmountForPriest"] = dakshinaamt;
    }
    Authservice.calculateDelMaterial(e, dataPay)
      .then((response) => {
        console.log(response.data);
        console.log(response.data.listOfdates, "get list of dates from del Charge calculate")
        setDelChargeData(response.data)
        const sortedDates = dates
          .map((e: any) => new Date(e.value))
          .sort((a: any, b: any) => a - b);
        console.log(sortdates, "sorting the dates to show")
        setSortDates(sortedDates)
        setActionFlag(false)
        setHeadName("Home,Booking Details")
        setIndex1(5);
        confirmPage = true;

        amount = 0;
        var resData = response.data;

        var listObj = resData.materialDeityDto;
        if (resData.dakshinaAmountToTemple != null) {
          amount = amount + resData.dakshinaAmountToTemple;
        }

        if (resData.dakshinaAmountForPriest != null) {
          amount = amount + resData.dakshinaAmountForPriest;
        }
        var noOfDates = resData.listOfdates.length;
        var totDays = resData.totalAmount * noOfDates;
        amount = amount + totDays;
        for (let obj of listObj) {

          if (obj.pdDelCharge != null) {
            amount = amount + obj.pdDelCharge;
          }
        }
        setTotalAmount(amount)
        setShowLoading(false)
      })
      .catch((error) => {
        setShowLoading(false)
        alert("Something went wrong, please try again later.");
      });
  }


  const { amountLimit }: any = useContext(AppContext)!;


  const Validation = (label: any) => {

    if (label === "flag") {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      var flag2;
      var flag;
      for (let i = 0; i < dates.length; i++) {
        if (dates[i].value == "") {
          flag = true
        }
      }

      var flag1;
      for (let ii = 0; ii < dates.length; ii++) {
        for (let j = ii + 1; j < dates.length; j++) {
          if (dates[ii].value == dates[j].value) {
            flag1 = true
          }
        }
      }
      for (let i = 0; i < dates.length; i++) {
        if (tomorrow.toISOString().split('T')[0] > new Date(dates[i].value).toISOString().split('T')[0]) {
          flag2 = true
        }
      }
      if (flag) {
        setFormErrors((prev: any) => {
          return { ...prev, date: "Please Enter the Date" }
        })
      }
      else if (flag1) {
        setFormErrors((prev: any) => {
          return { ...prev, date: "Please Check the Date you Entered" }
        })
      }
      else if (flag2) {
        setFormErrors((prev: any) => {
          return { ...prev, date: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, date: "" }
        })
      }
    }

    if (label === "dakamt1") {
      if (dakshinaamt == "" && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, dakamt1: "Please Enter the dakshina amount to priest" }
        })
      }
      else if (dakshinaamt == 0 && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, dakamt1: "Please Enter the valid Dakshina amount" }
        })
      }
      else if (dakshinaamt > amountLimit && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, dakamt1: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, dakamt1: "" }
        })
      }
    }

    if (label === "donamt1") {
      if (donationamt == "" && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donamt1: "Please Enter the Donation amount to temple" }
        })
      }
      else if (donationamt == 0 && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donamt1: "Please Enter the valid Donation amount" }
        })
      }
      else if (donationamt > amountLimit && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donamt1: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, donamt1: "" }
        })
      }
    }

    if (label === "login email") {
      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Email is required" }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }

    if (label === "login password") {
      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }

    if (label === "firstname1") {
      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "phone1") {
      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      }
      else if (!isValidPhoneNumberSign) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }
    if (label === "loginphone1") {
      if (loginphone === '' || loginphone === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, loginphone: "Mobile Number is required" }
        })
      }
      else if (!isValidPhoneNumber) {
        setFormErrors((prev: any) => {
          return { ...prev, loginphone: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, loginphone: "" }
        })
      }
    }
    if (label === "email1") {
      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }

    if (label === "password1") {
      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same " }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
  }

  const ChangeValidation = (label: any, e: any) => {
    console.log(amountLimit)

    if (label === "flag") {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      var flag;
      for (let i = 0; i < dates.length; i++) {
        if (dates[i].value == "") {
          flag = true
        }
      }

      var flag1;
      for (let ii = 0; ii < dates.length; ii++) {
        for (let j = ii + 1; j < dates.length; j++) {
          if (dates[ii].value == dates[j].value) {
            flag1 = true
          }
        }
      }
      var flag2;
      for (let i = 0; i < dates.length; i++) {
        if (tomorrow.toISOString().split('T')[0] > new Date(dates[i].value).toISOString().split('T')[0]) {
          flag2 = true
        }
      }
      if (flag) {
        setFormErrors((prev: any) => {
          return { ...prev, date: "Please Enter the Date" }
        })
      }
      else if (flag1) {
        setFormErrors((prev: any) => {
          return { ...prev, date: "Please Check the Date you Entered" }
        })
      }
      else if (flag2) {
        setFormErrors((prev: any) => {
          return { ...prev, date: "Please select future date" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, date: "" }
        })
      }
    }

    if (label === "dakamt1") {
      const dakshinaamt = e.target.value;
      if (dakshinaamt == "" && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, dakamt1: "Please Enter the dakshina amount to priest" }
        })
      }
      else if (dakshinaamt == 0 && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, dakamt1: "Please Enter the valid Dakshina amount" }
        })
      }
      else if (amountLimit < dakshinaamt && dakamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, dakamt1: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, dakamt1: "" }
        })
      }
    }

    if (label === "donamt1") {
      const donationamt = e.target.value;

      if (donationamt == "" && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donamt1: "Please Enter the Donation amount to temple" }
        })
      }
      else if (donationamt == 0 && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donamt1: "Please Enter the valid Donation amount" }
        })
      }
      else if (amountLimit < donationamt && donamt1 === true) {
        setFormErrors((prev: any) => {
          return { ...prev, donamt1: "Maximum Transaction limit is 500000" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, donamt1: "" }
        })
      }
    }

    if (label === "login email") {
      const email = e.target.value;

      if (email.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Email is required" }
        })
      }
      else if (validator.isEmail(email) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email: "" }
        })
      }
    }

    if (label === "login password") {
      const password = e.target.value;

      if (password.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password: "" }
        })
      }
    }

    if (label === "firstname1") {
      const firstname1 = e.target.value;

      if (firstname1 == "") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Enter First Name" }
        })
      }
      else if (namecheck.test(firstname1) == false) {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Alphabets only Allowed in First Name" }
        })
      }
      else if (firstname1.charAt(0) == " ") {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "Empty Space at First Name" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, firstname1: "" }
        })
      }
    }

    if (label === "phone1") {
      const phone1 = e;

      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Mobile Number is required" }
        })
      }
      else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, phone1: "" }
        })
      }
    }
    if (label === "loginphone1") {
      const phone1 = e;
      let valid: any = false
      try {
        const phoneNumberObject: any = parsePhoneNumberFromString(e);
        valid = phoneNumberObject ? phoneNumberObject.isValid() : false;
      } catch (error) {
        valid = false;
        console.log(error)
      }

      if (phone1 === '' || phone1 === undefined) {
        setFormErrors((prev: any) => {
          return { ...prev, loginphone: "Mobile Number is required" }
        })
      }
      else if (!valid) {
        setFormErrors((prev: any) => {
          return { ...prev, loginphone: "Please Enter Valid Mobile Number" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, loginphone: "" }
        })
      }
    }

    if (label === "email1") {
      const email1 = e.target.value;

      if (email1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Email is required" }
        })
      }
      else if (validator.isEmail(email1) === false) {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "Enter Valid Email Address" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, email1: "" }
        })
      }
    }

    if (label === "password1") {
      const password1 = e.target.value;

      if (password1.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "Password is required" }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, password1: "" }
        })
      }
    }

    if (label === "confirmpassword") {
      const confirmpassword = e.target.value;

      if (confirmpassword.trim() === '') {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Confirm Password is required" }
        })
      }
      else if (password1 != confirmpassword) {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "Password and confirm password are not same " }
        })
      }
      else {
        setFormErrors((prev: any) => {
          return { ...prev, confirmpassword: "" }
        })
      }
    }
  }

  const show1 = () => {
    console.log(amountLimit, "ufhjvj", donationamt.length)
    const dontNumber = parseInt(donationamt, 10)
    const dakNumber = parseInt(dakshinaamt, 10)
    var amountCheck = dontNumber + dakNumber
    console.log(amountCheck, "checkkkk")
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var flag;
    var flag2;
    for (let i = 0; i < dates.length; i++) {
      if (dates[i].value == "") {
        flag = true
      } else if (tomorrow.toISOString().split('T')[0] > new Date(dates[i].value).toISOString().split('T')[0]) {
        flag2 = true
      }
    }
    var flag1;
    for (let ii = 0; ii < dates.length; ii++) {
      for (let j = ii + 1; j < dates.length; j++) {
        if (dates[ii].value == dates[j].value) {
          flag1 = true
        }
      }
    }

    // if (username == "") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Enter the name',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (namepat.test(username) == false) {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Alphabets only Allowed in Name',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (username.charAt(0) == " ") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Empty Space at Name',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (star == "") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Enter the star',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (starpat.test(star) == false) {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Alphabets only Allowed in Star',
    //     buttons: ['OK'],
    //   })
    // }
    // else if (star.charAt(0) == " ") {
    //   Alert({
    //     header: 'Alert',
    //     message: 'Empty Space at Star',
    //     buttons: ['OK'],
    //   })
    // }
    const errors: any = {};

    if (flag) {
      errors.date = "Please Enter the Date"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the Date',
      //   buttons: ['OK'],
      // })
    }
    else if (flag1) {
      errors.date = "Please Check the Date you Entered"
      // Alert({
      //   header: 'Alert',
      //   message: 'Same Dates',
      //   buttons: ['OK'],
      // })
    }
    else if (flag2) {
      errors.date = "Please select future date"
    }
    if (dakshinaamt == "" && dakamt1 === true) {
      errors.dakamt1 = "Please Enter the dakshina amount to priest"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the dakshina amount to priest',
      //   buttons: ['OK'],
      // })
    }
    else if (dakshinaamt == 0 && dakamt1 === true) {
      errors.dakamt1 = "Please Enter the valid Dakshina amount"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the valid Dakshina amount',
      //   buttons: ['OK'],
      // })
    }
    if (donationamt == "" && donamt1 === true) {
      errors.donamt1 = "Please Enter the Donation amount to temple"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the Donation amount to temple',
      //   buttons: ['OK'],
      // })
    }
    else if (donationamt == 0 && donamt1 === true) {
      errors.donamt1 = "Please Enter the valid Donation amount"
      // Alert({
      //   header: 'Alert',
      //   message: 'Enter the valid Donation amount',
      //   buttons: ['OK'],
      // })
    }
    setFormErrors(errors);
    if (amountCheck > amountLimit) {
      Alert({
        header: "Atmagram",
        message: "Maximum Transaction limit is 500000",
        backdropDismiss: false,
        buttons: [
          {
            text: "ok",
          }
        ]
      })
    }
    else {
      if (Object.keys(errors).length === 0) {

        // if (usertoken == null) {
        //   setLoginmodal(true)
        // }
        // else {
        setActionFlag(true)
        bookpayment(usertoken)
        // }
      }
    }
  }

  const [android, setAndroid] = useState(false)

  const OrderCheck = () => {
    // if (item.quantity === "."){
    //   Alert({
    //     header: 'Alert',
    //     message: 'Dot is no',
    //     buttons: ['OK'],
    //   })
    // }
    // else
    if (ValidCheck === false) {
      Alert({
        header: 'Alert',
        message: 'Select the Offering Material',
        buttons: ['OK'],
      })
    }
    else {
      if (Capacitor.getPlatform() === "android") {
        setAndroid(true)
      }
      else {
        setAndroid(false)
      }
      setHeadName("Home,Offering Materials")
      setIndex1(3)

    }
  }
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "1px solid black",
      boxShadow: 'none',
      fontSize: '15px',
      // height: '44px',
      width: '90%',
      outline: 'none',
      textAlign: "left"
    }),
  };


  const dates_changes = (e: any, i: any) => {
    console.log(e.detail.value, "onChanges selected value");
    dates[i].value = e.target.value
    var ii = e.detail.value.split("T")
    console.log(ii, "spliting date from time")
    var str = moment(ii[0]).format('YYYY-MM-DDThh:mm:ss')
    console.log(str, "chnaginf the fromat of date")
    onedate[i] = str;
    var confirm = moment(ii).format('DD-MM-YYYY')
    confirmdates[i] = confirm
  }

  const removeInputFields = (index: any) => {
    const rows = [...dates];
    const listss = [...onedate]
    const confirm = [...confirmdates]

    if (rows.length >= 1) {
      rows.splice(index, 1);
    }
    if (listss.length >= 1) {
      listss.splice(index, 1);
    }
    if (confirm.length >= 1) {
      confirm.splice(index, 1);
    }
    setDates(rows);
    setOnedate(listss)
    setConfirmdates(confirm)
    setFormErrors((prev: any) => {
      return { ...prev, date: "" }
    })
  }

  const changeSearchQuery = (query: any) => {
    setSearchQuery(query)
    setRerenderKey((prevKey) => prevKey + 1);
  }

  const handleIconClick = (event: any) => {
    console.log('Icon clicked!');
    event.stopPropagation();  // Stop the event from propagating to the parent div
  };

  const datelimit = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    // return yyyy + "-" + mm + "-" + dd;
    const today1 = new Date();
    const tomorrow = new Date(today1);
    tomorrow.setDate(tomorrow.getDate() + 1);


    return tomorrow.toISOString().split('T')[0];
  };
  const SearchF = (value: any) => {
    if (value == "Enter") {
      Submit()
    }
  }
  const [errormodal, setErrormodal] = useState(false)
  const [errormsg, setErrormsg] = useState([])


  const paymentFailedResponse = (refId:any, e:any) => {
    var resData: any = {

    
      "cartPayRefId": refId,
      

    }
    console.log("resData", resData);
    Authservice.materialPaymentMultiRes(resData, e)
      .then((response) => {
        console.log("payresponse" + response);
       
      })
      .catch((error) => {
        
      });

  }
  const payment = (e: any) => {
    setActionFlag(true)
    setShowLoading(true);
    const list = selectedProducts.current.map((item: any, i: any) => {
      return { deityId: item.deityId, materialId: item.id, quantity: item.quantity, price: item.price }
    })
    console.log(onedate)
    const datesList = onedate.map((e: any) => new Date(e))
      .sort((a: any, b: any) => a - b)
    const listofdates = datesList.map((e: any) => moment(e).format("DD-MM-YYYY"))
    var dataPay: any = delChargeData
    // dataPay = {
    //   "templeId": temId,
    //   "name": username,
    //   "star": star,
    //   "listOfdates": listofdates,
    //   "listOfMaterialsDto": delChargeData.materialDeityDto,
    //   "payDakshinaToPriestFlag": paydakshinapreist,
    //   "payDakshinaToTempleFlag": paydakshinatemple,
    //   // "dakshinaAmountForPriest": amt,
    //   // "dakshinaAmountToTemple": amt1,
    //   "totalAmount": tot
    // }
    if (paydakshinatemple) {
      dataPay["dakshinaAmountToTemple"] = donationamt;
    }
    if (paydakshinapreist) {
      dataPay["dakshinaAmountForPriest"] = dakshinaamt;
    }
    console.log('datapay', dataPay);
    Authservice.materialPayment(dataPay, e)
      .then((response) => {
        console.log("payresponse", response.data);
        // Alert('your order placed successfully, please visit your  My Materials')
        // window.location.replace("/mymaterial")
        if (response.data.errors === null) {
          var options: any = {
            key: response.data.pgOption.key,
            key_secret: response.data.secretKey,
            amount: response.data.pgOption.amount,
            currency: response.data.pgOption.currency,
            name: response.data.pgOption.name,
            order_id: response.data.pgOption.order_id,
            description: response.data.pgOption.description,
            modal: {
              escape: false, ondismiss: function () {
                // code here 
                paymentFailedResponse (response.data.cartPayRefId,e)
                Alert({
                  header: "Atmagram",
                  message: "Payment Failed",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                    }
                  ]
                })
              }
            },
            handler: function (res: any) {
              // Alert(res.razorpay_payment_id+"/"+res.razorpay_order_id+res.razorpay_signature);
              var resData: any = {

                "amount": response.data.pgOption.amount,
                "orderId": res.razorpay_order_id,
                "cartPayRefId": response.data.cartPayRefId,
                "signature": res.razorpay_signature,
                "paymentId": res.razorpay_payment_id

              }
              console.log("resData", resData);
              Authservice.materialPaymentMultiRes(resData, e)
                .then((response) => {
                  console.log("payresponse" + response);
                  Alert({
                    header: "Atmagram",
                    message: "your order placed successfully, please visit your My Materials",
                    backdropDismiss: false,
                    buttons: [
                      {
                        text: "ok",
                        handler: () => {
                          window.location.reload()
                        }
                      }
                    ]
                  })
                })
                .catch((error) => {
                  Alert({
                    header: "Atmagram",
                    message: "Something went wrong, please try again later.",
                    backdropDismiss: false,
                    buttons: [
                      {
                        text: "ok",
                      }
                    ]
                  })
                });

            },
            prefill: {
              name: response.data.name,
              email: response.data.email,
              contact: response.data.contact

            },

            theme: {
              color: response.data.theme
            }
          };
          var pay: any = new Razorpay(options)
          pay.on("payment.failed", function (data: any) {
            // Alert(data.error.code);
            // Alert(data.error.description);
            // Alert(data.error.source);
            // Alert(data.error.step);
            pay.close();
           // paymentFailedResponse (response.data.cartPayRefId,e)
            Alert({
              header: "Atmagram",
              message: data.error.description,
              backdropDismiss: false,
              buttons: [
                {
                  text: "ok",
                }
              ]
            })
            // Alert(data.error.metadata.order_id);
            // Alert(data.error.metadata.payment_id);
          });
          setShowLoading(false);
          pay.open()
        }
        else {
          setShowLoading(false);
          setErrormodal(true)
          console.log(response.data.errors)
          setErrormsg(response.data.errors)
        }
      })
      .catch((error) => {
        setShowLoading(false);
        Alert({
          header: "Atmagram",
          message: "Something went wrong, please try again later.",
          backdropDismiss: false,
          buttons: [
            {
              text: "ok",
            }
          ]
        })
      });
  }
  const openmodal = () => {
    setModalopen(true)
  }
  const openmodal1 = () => {
    setModalopen1(true)
  }
  const numberOnlyValidation = (event: any) => {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  const useOutsideAlerter = (refer: any, tempdonref: any, donref: any, logref: any, signref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (refer.current && !refer.current.contains(event.target)) {
          setIsshow(false)
        }
        if (tempdonref.current && !tempdonref.current.contains(event.target)) {
          setModalopen1(false)
        }
        if (donref.current && !donref.current.contains(event.target)) {
          setModalopen(false)
        }
        if (logref.current && !logref.current.contains(event.target)) {
          setLoginmodal(true)
        }
        if (signref.current && !signref.current.contains(event.target)) {
          setSignupmodal(false)
          setLoginmodal(true)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }, [refer]);
  }
  const deitymodal: any = useRef(null);
  const templedonationmodal = useRef(null);
  const donationmodal = useRef(null);
  const loginmodalref = useRef(null);
  const signupmodalref = useRef(null)

  useOutsideAlerter(deitymodal, templedonationmodal, donationmodal, loginmodalref, signupmodalref);

  const getAllState = () => {
    setStateOption(null)
    setDistrictOption(null)
    setShowLoading(true);
    Authservice.getAllstates().then((response) => {
      console.log(response.data);
      setStates(response.data)
      setShowLoading(false);
    })
      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }

  const getAllDistricts = (item: any) => {
    const arr = [item.value]
    setDistrictOption(null)
    Authservice.getAllDistricts(arr).then((response) => {
      console.log(response);
      setShowLoading(true)
      setDistricts(response.data)
      setShowLoading(false)
    })

      .catch((error) => {
        alert(error)
        setShowLoading(false);
      });
  }
  const handleStateChange = (e: any) => {
    console.log(e)
    if (e) {
      setStateOption(e);
      setState(e.value);
      getAllDistricts(e)
    } else {
      setStateOption(null)
      setState("")
      setDistricts([])
      setDistrictOption(null)
      setDistrict("")
    }
  }
  const handleCountryChange = (e: any) => {
    console.log(e)
    if (e) {
      setCountryOption(e);
      setCountry(e.value);
    } else {
      setCountryOption({})
      setCountry("")
    }
  }
  const handleDistrictChange = (e: any) => {
    console.log(e)
    if (e) {
      setDistrictOption(e);
      setDistrict(e.value);
    } else {
      setDistrictOption(null)
      setDistrict("")
    }
  }

  const [loginIndex, setLoginIndex] = useState(0);
  const [showOtp, setShowOtp]: any = useState(false);
  const [otp, setOtp] = useState("");
  const [confirmationResult, setconfirmationResult]: any = useState();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [loginalertmodal, setLoginalertmodal] = useState(false)
  const [loginalertdata, setLoginalertdata] = useState("")

  const emailLogin = () => {
    setLoginIndex(0)
  }

  const phoneLogin = () => {
    setLoginIndex(1)
  }

  const loginalertmodalaction = (e: any, data: any) => {
    setLoginalertmodal(e)
    setLoginalertdata(data)
  }

  const checkMobile = () => {
    const errors: any = {};
    if (loginphone === undefined || loginphone === "") {
      errors.loginphone = "Mobile Number is required"
    } else if (!isValidPhoneNumber) {
      errors.loginphone = "Enter valid Mobile Number"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      setShowLoading(true);
      UserService.validMob(loginphone).then((res) => {
        setShowLoading(false);
        if (res.data) {
          getOTP();
        } else {
          // alert("Please complete registration. Then only you can login with Atmagram")
          loginalertmodalaction(true, "Please complete registration. Then only you can login with Atmagram")
          // Alert({ header: 'Atmagram', message: "Please complete registration. Then only you can login with Atmagram",buttons: ['OK'], cssClass: 'custom-alert' })
        }

      }).catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
    }
  }

  const getOTP = () => {
    let verify = new RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        // alert("Response expired. Please solve reCAPTCHA again")
        loginalertmodalaction(true, "Response expired. Please solve reCAPTCHA again")
      }
    }, auth);

    signInWithPhoneNumber(auth, loginphone, verify)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log(confirmationResult);
        setShowOtp(true)
        setconfirmationResult(confirmationResult)
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        loginalertmodalaction(true, "Something went wrong, please try again later.")
        // alert("Something went wrong, please try again later.")
        // ...
      });
  }

  const verifyOTP = () => {
    // var credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
    // console.log(credential);
    const errors: any = {};
    if (otp === undefined || otp === "" || otp == null) {
      errors.otp = "Enter OTP"
    }
    setFormErrors(errors);
    console.log(formErrors)
    if (Object.keys(errors).length === 0) {
      confirmationResult.confirm(otp).then((result: any) => {
        // User signed in successfully.
        const user = result.user;
        console.log(result);
        setShowLoading(true);
        UserService.mobileLogin(loginphone)
          .then((response) => {
            if (response.data.responseText.startsWith("SUCCESS")) {
              if (response.data.responseText.startsWith("SUCCESS")) {
                // if (Capacitor.getPlatform() === "web") {
                //   console.log(response, "ressss")
                if (response.data.userModel.role !== "ROLE_USER") {
                  // Alert("This Account is not a user")
                  loginalertmodalaction(true, "This Account is not a user")
                }
                else
                  if (response.data.userModel.uflag === "false") {
                    console.log(response.data.userModel.uflag, "uflag")
                    // Alert("Account Not Approved Yet")
                    loginalertmodalaction(true, "Account Not Approved Yet")
                  }
                  else {
                    localStorage.setItem("token", response.data.userToken);
                    localStorage.setItem("UserId", response.data.userModel.id);
                    localStorage.setItem("UserData", response.data.userModel.role);
                    localStorage.setItem("uFlag", response.data.userModel.uflag);
                    localStorage.setItem("Email", response.data.userModel.email);
                    localStorage.setItem("Phone", response.data.userModel.mobileNumber);
                    setUFlag(response.data.userModel.uflag)
                    setToken(response.data.userToken)
                    setUserData(response.data.userModel.role)
                    if (response.data.userModel.firstName !== null) {
                      localStorage.setItem("Name", response.data.userModel.firstName);
                    }
                    if (response.data.userModel.address !== null) {
                      localStorage.setItem("Address", response.data.userModel.address);
                    }

                    setUsertoken(response.data.userToken)
                    setUserdata(response.data.userModel.role)
                    console.log(response.data.userToken)
                    Alert({
                      header: "Atmagram",
                      message: "LoggedIn Successfully",
                      backdropDismiss: false,
                      buttons: [
                        {
                          text: "ok",
                          handler: () => {
                            payment(response.data.userToken);
                          }
                        }
                      ]
                    })
                    setLoginmodal(false)
                  }
              }
              // }
              else {
                // Alert(response.data.responseText.split("ERROR:")[1]);
                loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
              }
            } else {
              // alert(response.data.responseText.split("ERROR:")[1]);
              loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
            }
            setShowLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setShowLoading(false);
          });

        // ...
      }).catch((err: any) => {
        // User couldn't sign in (bad verification code?)
        console.log(err.error);
        // alert("Not a valid verification code or Code expired, please try again")
        loginalertmodalaction(true, "Not a valid verification code or Code expired, please try again")
        // if(error.error.message=='SESSION_EXPIRED'){
        //   alert("OTP expired");
        // }else{

        // }
        // ...
      });
    }
  }


  const goBack = () => {
    setShowOtp(false)
  }

  const Loginsubmit = () => {
    const errors: any = {};

    if (email.trim() === '') {
      errors.email = 'Email is required';
    }
    else if (validator.isEmail(email) === false) {
      errors.email = "Enter Valid Email Address";
    }

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {

      setShowLoading(true);
      UserService.Login(email, password)
        .then((response) => {
          setShowLoading(false);

          if (response.data.responseText.startsWith("SUCCESS")) {
            // if (Capacitor.getPlatform() === "web") {
            //   console.log(response, "ressss")
            if (response.data.userModel.role !== "ROLE_USER") {
              loginalertmodalaction(true, "This Account is not a user")
            }
            else
              if (response.data.userModel.uflag === "false") {
                console.log(response.data.userModel.uflag, "uflag")
                loginalertmodalaction(true, "Account Not Approved Yet")
              }
              else {
                localStorage.setItem("token", response.data.userToken);
                localStorage.setItem("UserId", response.data.userModel.id);
                localStorage.setItem("UserData", response.data.userModel.role);
                localStorage.setItem("uFlag", response.data.userModel.uflag);
                localStorage.setItem("Email", response.data.userModel.email);
                localStorage.setItem("Phone", response.data.userModel.mobileNumber);
                setUFlag(response.data.userModel.uflag)
                setToken(response.data.userToken)
                setUserData(response.data.userModel.role)
                if (response.data.userModel.firstName !== null) {
                  localStorage.setItem("Name", response.data.userModel.firstName);
                }
                if (response.data.userModel.address !== null) {
                  localStorage.setItem("Address", response.data.userModel.address);
                }

                setUsertoken(response.data.userToken)
                setUserdata(response.data.userModel.role)
                console.log(response.data.userToken)
                Alert({
                  header: "Atmagram",
                  message: "LoggedIn Successfully",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "ok",
                      handler: () => {
                        payment(response.data.userToken);
                      }
                    }
                  ]
                })
                setLoginmodal(false)
              }
          }
          // }
          else {
            loginalertmodalaction(true, response.data.responseText.split("ERROR:")[1])
          }
        })
        .catch((error) => {
          setLoginmodal(true)
          console.log(error);
          setShowLoading(false);
        });
    }
  };
  const mailpat = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;

  const Searchlog = (value: any) => {
    if (value == "Enter") {
      Loginsubmit()
    }
  }
  const [signupmodal, setSignupmodal] = useState(false)
  const [user, setUser]: any = useState("2");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [state1, setState1]: any = useState("");
  const [TName, setTName]: any = useState("");
  const [TAdd, setTAdd]: any = useState("");
  const [aadhar1, setAadhar1] = useState("");
  const [addr, setAddr] = useState("");
  const [area1, setArea1] = useState("")
  const [firstname1, setFirstname1] = useState("");
  const [lastname1, setLastname1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [city1, setCity1] = useState("");
  const [country1, setCountry1] = useState("India");
  const [email1, setEmail1] = useState("");
  const [issuccess, setIssuccess] = useState(false);
  const [pincode1, setPincode1] = useState("");
  const [delItem, setDelItem]: any = useState()
  const [isValidPhoneNumberSign, setIsValidPhoneNumberSign] = useState(false);
  const [loginphone, setloginphone] = useState("")

  const phoneInputRef = useRef(null)
  const loginphoneInputRef = useRef(null)

  const moveCursorToEndMobile = (value: any) => {
    const input: any = loginphoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumber(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumber(false);
      console.log(error)
    }
    setloginphone(value)
  }
  const moveCursorToEnd = (value: any) => {
    const input: any = phoneInputRef.current;
    if (window.innerWidth <= 500) {
      if (input) {
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }
    try {
      const phoneNumberObject: any = parsePhoneNumberFromString(value);
      setIsValidPhoneNumberSign(phoneNumberObject ? phoneNumberObject.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumberSign(false);
      console.log(error)
    }
    setPhone1(value)
  };

  const [message, setMessage] = useState("");
  const [iserror, setIserror] = useState(false);
  const namecheck = /^[a-zA-Z\s]*$/
  const submit = () => {
    const errors: any = {};

    if (firstname1 == "") {
      errors.firstname1 = "Enter First Name"
    }
    else if (namecheck.test(firstname1) == false) {
      errors.firstname1 = 'Alphabets only Allowed in First Name'
    }
    else if (firstname1.charAt(0) == " ") {
      errors.firstname1 = 'Empty Space at First Name'
    }
    if (phone1 === '' || phone1 === undefined) {
      errors.phone1 = "Mobile Number is required"
    }
    else if (!isValidPhoneNumberSign) {
      errors.phone1 = "Please Enter Valid Mobile Number"
    }
    // else if (pincode.length !== 6) {
    //   alert("Enter Valid Pincode")
    // }
    if (email1.trim() === '') {
      errors.email1 = 'Email is required';
    }
    else if (validator.isEmail(email1) === false) {
      errors.email1 = "Enter Valid Email Address";
    }
    // else if (aadhar.length !== 12) {
    //   alert("Enter Valid Aadhar Number")
    // }
    if (password1.trim() === '') {
      errors.password1 = 'Password is required';
    }
    if (confirmpassword.trim() === '') {
      errors.confirmpassword = 'Confirm Password is required';
    }
    else if (password != confirmpassword) {
      errors.confirmpassword = "Password and confirm password are not same ";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {

      console.log(aadhar1,
        email1,
        phone1,
        password1,
        user,
        user,
        firstname1)
      UserService.Registermaterials(
        // aadhar1,
        // addr,
        // pincode1,
        // area1,
        // city1,
        // state1,
        // country1,
        email1,
        firstname1,
        // lastname1,
        phone1,
        password1,
        user,
        user
      )
        .then((response) => {
          console.log("res", response);
          setShowLoading(false);
          if (response.responseText.startsWith("SUCCESS")) {
            setIssuccess(true);
            // setAadhar1("");
            // setAddr("");
            // setArea1("");
            // setCity1("");
            setConfirmpassword("");
            // setCountry1("");
            setEmail1("");
            setFirstname1("");
            // setLastname1("");
            setPassword("");
            setPhone1("");
            setUser("");
            // setPincode1("");
            loginalertmodalaction(true, "Register Successfully")
            setSignupmodal(false)
            console.log(response, "resss =>")
          }
          else if (response.responseText == "ERROR:Email Id already exist") {
            loginalertmodalaction(true, "Email Id Already Exists, Try Any Other Email Id")
          } else if (response.responseText == "ERROR:MobileNumber already exist") {
            loginalertmodalaction(true, "Mobile Number Already Exists, Try Any Other Mobile Number")
          } else {
            loginalertmodalaction(true, "User Not Register Successfully")
          }
        })
        .catch((error) => {
          console.log(error)
          setShowLoading(false);
          loginalertmodalaction(true, "Something went wrong")
        });
    }
  }

  const opendelModal = (item: any) => {
    setDelItem(item)
    setdeleteModal(true)
  }

  const alertmodalclose = () => {
    if (loginalertdata === "Register Successfully") {
      setLoginmodal(true)
      setLoginalertmodal(false)
    }
    else {
      setLoginalertmodal(false)
    }
    setLoginalertdata("")
  }
  const viewMaterial = (item: any) => {
    console.log(item)
    if (selectedProducts.current.length > 0) {
      var isFound: boolean = false;
      for (var i = 0; i < selectedProducts.current.length; i++) {
        if (selectedProducts.current[i].id == item.id && selectedProducts.current[i].deityId === deityId) {
          setViewImg(selectedProducts.current[i].image)
          setViewProductName(selectedProducts.current[i].productName)
          setViewBrandName(selectedProducts.current[i].brandName)
          setViewPrice(selectedProducts.current[i].price)
          setViewStock(selectedProducts.current[i].availableStock)
          setViewQuantity(selectedProducts.current[i].quantity)
          setViewPsize(selectedProducts.current[i].packageSize)
          setViewPunit(selectedProducts.current[i].packageUnit)
          setViewItem(selectedProducts.current[i])
          setViewIndex(i)
          isFound = true;
        }
      }
      if (!isFound) {
        setViewImg(item.image)
        setViewProductName(item.productName)
        setViewPrice(item.price)
        setViewStock(item.availableStock)
        setViewPsize(item.packageSize)
        setViewPunit(item.packageUnit)
        setViewQuantity(item.quantity)
        setViewBrandName(item.brandName)
        setViewItem(item)
        setViewProgressValue((item.availableStock / item.materialStock) * 100)
        setViewIndex(undefined)
      }
    } else {
      setViewImg(item.image)
      setViewProductName(item.productName)
      setViewPrice(item.price)
      setViewStock(item.availableStock)
      setViewQuantity(item.quantity)
      setViewBrandName(item.brandName)
      setViewPsize(item.packageSize)
      setViewPunit(item.packageUnit)
      setViewQuantity(item.quantity)
      setViewItem(item)
      setViewProgressValue((item.availableStock / item.materialStock) * 100)
      setViewIndex(undefined)
    }
    setHeadName("Home,Material View")
    setIndex1(6)
  }
  return (
    <IonPage>
      <IonAlert
        isOpen={showAlert}
        backdropDismiss={false}
        onDidDismiss={() => setShowAlert(false)}
        header="Confirmation"

        message="Are you sure you want to leave? If you proceed, your order details will be lost. Please click Cancel to continue with your order."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',

            handler: () => {
              setActionFlag(false)
              setShowAlert(false); // Update the flag to indicate that the action was clicked
            },
          },
          {
            text: 'Leave',
            handler: handleLeave,
          },
        ]}

      />
      <IonContent>
        <IonLoading message="Loading..." spinner={null}
          isOpen={showLoading}
        // onDidDismiss={() => setShowLoading(false)}

        // duration={5000}
        />
        {/* <UserHead User="materials" Role={userdata} /> */}
        <HeadFoot User={"poojamaterials"} Role={'Role'} Name={headName} />
        <Modal show={signupmodal} className='login-modal' centered>
          <ModalHeader className="profilenodal-Header">
            <h5 className="profileModal-title">Sign Up</h5>
            <div>
              <IonIcon src={close} className="close_icon" onClick={() => { setSignupmodal(false); setLoginmodal(true) }} />
            </div>
          </ModalHeader>
          <Modal.Body >

            <form onSubmit={handleSubmit(submit)}>
              <div>
                <IonRow>
                  <IonCol size="12" size-lg="7" className="email_container">
                    <div>
                      <IonLabel className="label_align">
                        First Name
                        <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="text" className="select_user"
                        value={firstname1}
                        // required
                        onBlur={() => Validation("firstname1")}
                        onIonChange={(e) => { setFirstname1(e.detail.value!); ChangeValidation("firstname1", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.firstname1 && <span className="alert_input_bottom ">{formErrors.firstname1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="12" size-lg="7" >
                    <div>
                      <IonLabel className="label_align">
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <div className="select_user">
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          value={phone1}
                          ref={phoneInputRef}
                          onBlur={() => Validation("phone1")}
                          onChange={(value: any) => { moveCursorToEnd(value); ChangeValidation("phone1", value) }}
                        /></div>
                    </div>
                    {formErrors.phone1 && <span className="alert_input_bottom ">{formErrors.phone1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-lg="7" >
                    <div>
                      <IonLabel className="label_align" >
                        E-mail <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="text"
                        value={email1}
                        className="select_user"
                        onBlur={() => Validation("email1")}
                        onIonChange={(e) => { setEmail1(e.detail.value!); ChangeValidation("email1", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.email1 && <span className="alert_input_bottom ">{formErrors.email1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="12" size-lg="7" className="pass_container">
                    <div>
                      <IonLabel className="label_align">
                        Password <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        value={password1}
                        className="select_user"
                        onBlur={() => Validation("password1")}
                        onIonChange={(e) => { setPassword1(e.detail.value!); ChangeValidation("password1", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.password1 && <span className="alert_input_bottom ">{formErrors.password1}</span>}
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="12" size-lg="7" className="pass_container">
                    <div>
                      <IonLabel className="label_align">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <IonInput
                        type="password"
                        className="select_user"
                        value={confirmpassword}
                        onBlur={() => Validation("confirmpassword")}
                        onIonChange={(e) => { setConfirmpassword(e.detail.value!); ChangeValidation("confirmpassword", e) }}
                      ></IonInput>
                    </div>
                    {formErrors.confirmpassword && <span className="alert_input_bottom ">{formErrors.confirmpassword}</span>}
                  </IonCol>
                </IonRow>
                <div style={{ display: "flex", alignItems: "center" }} className="pass_container">
                  <div>
                    <Link onClick={(event: any) => { setLoginmodal(true); event.preventDefault(); setSignupmodal(false) }} className="forgot_pass_css" to={"#"}>Already have an account?</Link>
                  </div>
                </div>
                <div className={firstname1.length === 0 ||
                  email1.length === 0 ||
                  password1.length === 0 ||
                  confirmpassword.length === 0 ? "disableCursor" : ""} style={{ width: "fit-content" }}>
                  <IonButton
                    type="submit"
                    disabled={
                      firstname1.length === 0 ||
                      email1.length === 0 ||
                      password1.length === 0 ||
                      confirmpassword.length === 0
                    }                  >
                    <span className="button_padding"> Sign Up</span>
                  </IonButton>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={loginmodal} className='login-modal' centered>
          <ModalHeader className="profilenodal-Header">
            <h5 className="profileModal-title">Login</h5>
            <div>
              <IonIcon src={close} className="close_icon" onClick={() => {
                setLoginIndex(0)
                setLoginmodal(false)
                setFormErrors((prev: any) => {
                  return { ...prev, loginphone: "" }
                })
              }} />
            </div>
          </ModalHeader>
          <Modal.Body>

            {loginIndex === 0 ?
              <form onSubmit={handleSubmit(Loginsubmit)} onKeyPress={e => Searchlog(e.key)}>
                <div>
                  <IonRow>
                    <IonCol size="11" size-lg="7" className="email_container">
                      <div>
                        <IonLabel className="label_align">
                          E-mail <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput className="select_user"
                          type="text"
                          placeholder="Enter mail-id"
                          value={email}
                          onBlur={() => Validation("login email")}
                          onIonChange={(e) => { setEmail(e.detail.value!); ChangeValidation("login email", e) }}
                        ></IonInput>
                      </div>
                      {formErrors.email && <span className="alert_input_bottom ">{formErrors.email}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow >
                    <IonCol size="11" size-lg="7" className="pass_container">
                      <div>
                        <IonLabel className="label_align">
                          Password <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput
                          type="password" className="select_user"
                          placeholder="Enter password"
                          onBlur={() => Validation("login password")}
                          onIonChange={(e) => { setPassword(e.detail.value!); ChangeValidation("login password", e) }}
                        ></IonInput>
                      </div>
                      {formErrors.password && <span className="alert_input_bottom ">{formErrors.password}</span>}
                    </IonCol>
                  </IonRow>
                  <div style={{ display: "flex", alignItems: "center" }} className="pass_container">
                    <div>
                      <Link onClick={(event: any) => { setSignupmodal(true); event.preventDefault(); setLoginmodal(false) }} className="forgot_pass_css" to={"#"}>Don't have account?</Link>
                    </div>
                  </div>
                  <div className='login_modal_btn_container'>
                    <div className={email.length === 0 || password.length === 0 ? "disableCursor" : ""} style={{ width: "fit-content" }}>
                      <IonButton
                        type="submit"
                        disabled={email.length === 0 || password.length === 0}
                      >
                        <span className="button_padding"> Login</span>
                      </IonButton>
                    </div>
                    <div className="center or_text_container">
                      <p> or</p>
                    </div>
                    <IonButton onClick={phoneLogin}>
                      <span className="button_padding">  Login With Phone</span>
                    </IonButton>
                  </div>
                </div>
              </form> : <div>

                {!showOtp ? <>
                  <IonRow>
                    <IonCol size="11" size-lg="7" className="email_container">
                      <IonLabel className="label_align">
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </IonLabel>
                      <PhoneInput
                        international
                        defaultCountry="IN"
                        value={loginphone}
                        className="select_user"
                        ref={phoneInputRef}
                        // flags=false
                        onBlur={(e) => Validation("loginphone1")}
                        onChange={(value: any) => { moveCursorToEndMobile(value); ChangeValidation("loginphone1", value) }}
                      />
                      {formErrors.loginphone && <span className="alert_input_bottom ">{formErrors.loginphone}</span>}
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="11" size-lg="7" className="pass_container">
                      <div id="recaptcha-container"></div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" size-lg="12" className="textalign- login_modal_btn_container">
                      <div className={loginphone === undefined || loginphone === "" || !isValidPhoneNumber ? "disableCursor" : ""} style={{ width: "fit-content" }}>
                        <IonButton
                          onClick={checkMobile}
                          disabled={loginphone === undefined || loginphone === "" || !isValidPhoneNumber}
                        >
                          <span className="button_padding"> Get OTP</span>
                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                </> : <></>}
                {showOtp ?
                  <>
                    <IonRow>
                      <IonCol size="11" size-lg="7" className="email_container">

                        <IonLabel className="label_align">
                          Enter OTP <span style={{ color: "red" }}>*</span>
                        </IonLabel>
                        <IonInput className="select_user"
                          onBlur={(e) => Validation("otp")}
                          onIonChange={(e) => { setOtp(e.detail.value!); ChangeValidation("otp", e) }}
                          required
                        ></IonInput>

                        {formErrors.otp && <span className="alert_input_bottom ">{formErrors.otp}</span>}
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size="11" size-lg="7" className="pass_container" >
                        <span className="go_back_style"
                          onClick={goBack}
                        >go back
                        </span>
                      </IonCol>
                    </IonRow>
                    <IonButton
                      onClick={verifyOTP}
                    >
                      <span className="button_padding">Verify OTP</span>

                    </IonButton>
                  </> : <></>
                }
                <div className="center or_text_container"><p>
                  or</p></div>
                <div className='login_modal_btn_container'>
                  <IonButton onClick={emailLogin}>
                    <span className="button_padding"> Login With Email</span></IonButton>
                </div>
              </div>}
          </Modal.Body>
        </Modal>
        <Modal show={isShow} className="custom-modal-wrapper">
          <Modal.Body ref={deitymodal} >
            <div className="modal-header" style={{ padding: "15px 0px" }}>
              <h5 className="modal-title">Atmagram</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsshow(false)}>
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="custom-modal-deity">
              <IonRow style={{ display: "flex", alignItem: "center", justifyContent: "center" }}>
                <IonImg src={image} style={{ width: "250px", height: "300px" }}></IonImg>
              </IonRow>
              <IonRow style={{ justifyContent: "center" }}>
                <IonText style={{ fontWeight: "bold", fontSize: "20px" }}>{deityName}</IonText>
              </IonRow>
              <IonRow style={{ textAlign: "justify" }}>
                <IonText style={{ fontSize: "17px", padding: "30px" }}>{description}</IonText>
              </IonRow>

            </div>
          </Modal.Body>
        </Modal>
        <Modal show={loginalertmodal} className="login_alert_modal" centered>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <h5 className="login_alert_title">Atmagram</h5>
            </div>
            <div style={{ textAlign: "center" }}>
              <IonText style={{ textAlign: "justify" }}>{loginalertdata}</IonText>
            </div>
            <div style={{ textAlign: "right", paddingTop: "15px" }}>
              <IonText style={{ cursor: "pointer" }} onClick={() => alertmodalclose()}>OK</IonText>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={errormodal} className="donation-modal" centered>
          <Modal.Body>
            <div className="donation-modal-header project-modal-header">
              <h5>Atmagram</h5>
              <div>
                <IonIcon src={close} className="close_icon" onClick={() => setErrormodal(false)} />
              </div>
            </div>
            <div className='donation-modal-content'>
              {errormsg.map((item: any, i: any) => (
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <IonText style={{ fontWeight: "bold" }}>{i + 1}.</IonText>
                  <IonText style={{ paddingLeft: "5px" }}>{item}</IonText>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={modalopen} className="donation-modal" centered >
          <Modal.Body ref={donationmodal}>
            <div className="donation-modal-header project-modal-header">
              <div className="donation-modal-title  center_col"><IonLabel>Atmagram</IonLabel></div>
              <div>
                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen(false)} />
              </div>
            </div>
            <div className='donation-modal-content ion-text-center'>
              <IonText className='ion-text-justify'>The Amount will be paid to the priest who performs the pooja</IonText>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={modalopen1} className="donation-modal" centered >
          <Modal.Body ref={templedonationmodal}>
            <div className="donation-modal-header project-modal-header">
              <h5 className="donation-modal-title">Atmagram</h5>
              <div>
                <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => setModalopen1(false)} />
              </div>
            </div>
            <div className='donation-modal-content'>
              <IonText>The Amount donated will be paid to the temple</IonText>
            </div>
          </Modal.Body>
        </Modal>
        <div>
          {index1 === 0 ? <IonRow className='deity_container page_content_padding'>
            {deitylist.length > 0 ? <>
              {deitylist.map((item: any, i) => (
                <IonCol sizeLg='6' sizeXl='4' size-md='6' size-xs='12' size-sm='12'>
                  <IonCard className='deity_cards'>
                    <IonRow>
                      <IonCol style={{ display: "flex" }} size='5'>
                        <IonImg className="deity_god_image" src={item.image + "?v=" + new Date()} />
                      </IonCol>
                      <IonCol size='7'>
                        <IonRow style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <IonCol size='10'>
                            <IonText className='deity_name'>{item.deityName}</IonText>
                          </IonCol>
                          <IonCol size='2'>
                            <IonIcon onClick={() => Viewinfo(item)} className='info_icon' src={informationCircleSharp} />
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonText className='deity_description'>
                            {item.deityDescription}
                          </IonText>
                        </IonRow>
                        <IonRow style={{ marginTop: "5px" }}>
                          <IonButton onClick={(e) => Details(item)}>
                            <span className='button_padding'>
                              Select Area
                            </span>
                          </IonButton>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              ))
              }</> : <IonCol className='no-materials'>
              <IonText>Sorry, No data available</IonText>
            </IonCol>}
          </IonRow> : index1 === 1 ? <IonGrid className='about_deity_container page_content_padding'>
            <IonRow style={{ paddingTop: "20px" }}>
              <IonCol size="1" className='center_col'>
                <div >
                  <IonIcon onClick={() => {
                    setIndex1(0)
                    setHeadName("Home,Select Deity")
                  }} src={arrowBack} className="point back" size="large" />
                </div>
              </IonCol>
              <IonCol size='11'  >
                <IonText className='bio_deity'>
                  Biography
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size-lg="12" size-md="12" size-xs="12">
                <IonImg src={image} className='bio_right_img bio_deity_image' />
                <p className='bio_deity_description'>{description}</p>
              </IonCol>
            </IonRow>
          </IonGrid> : index1 === 2 ? <div className='page_content_padding'>
            <IonGrid>
              <IonRow>
                <IonCol className='sa' size='12' sizeLg='5.5'>
                  <form onSubmit={handleSubmit(Submit)} onKeyPress={e => SearchF(e.key)}>
                    <IonRow>
                      <IonCol className='center_col' size='1' onClick={() => {
                        setIndex1(0)
                        setHeadName("Home,Select Deity")
                        setShowgod(false)
                        if (Capacitor.getPlatform() !== "web") {
                          setHideContent(true)
                        }
                        else {
                          setHideContent(false)
                        }
                      }}>
                        <div >
                          <IonIcon src={arrowBack} className="point back" size="large" />
                        </div></IonCol>
                      <IonCol className='center_col' size='10'>
                        <div >
                          <IonText className='select_text'> Select Area</IonText>
                        </div></IonCol>
                    </IonRow>
                    <IonRow className='justify-ion-text-center'>
                      {/* <IonCol size-xs="12" size-sm="6" size-lg="6" size-md="6">
                                                <IonLabel className='textsize'> Deity</IonLabel>
                                            </IonCol> */}
                      <IonCol className='inputcol' size-xs="12" size-sm="12" size-lg="12" size-md="12">
                        <div className='center_col'>
                          <IonText className='deity_text_search'>{deityName}</IonText>

                        </div>

                      </IonCol>
                    </IonRow>

                    <IonRow className='To'>
                      <IonCol size='12'>
                        <IonLabel className='search_label'> Country</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol size='12'>
                        <Select
                          className="custom-select-dark"
                          options={countryLiistOptions}
                          value={countryOption}
                          placeholder="Select a country"
                          isClearable
                          styles={customStyles}
                          onChange={handleCountryChange}
                        />
                        {counErrors.country && <span className="alert_input_bottom ">{counErrors.country}</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow className='To'>
                      <IonCol size="12">
                        <IonLabel className='search_label'>State</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol size="12">
                        <Select
                          className={stateMenu ? "custom-select" : "custom-select-dark"}
                          options={stateLiistOptions}
                          value={stateOption}
                          placeholder="Select a State"
                          isClearable
                          styles={customStyles}
                          onChange={handleStateChange}
                          menuIsOpen={stateMenu}
                          onMenuClose={() => setStateMenu(false)}
                          onMenuOpen={() => setStateMenu(true)}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow className='To' >
                      <IonCol size="12">
                        <IonLabel className='search_label'>District</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol size="12">
                        <Select
                          className={districtMenu ? "custom-select" : "custom-select-dark"}
                          options={districtLiistOptions}
                          value={districtOption}
                          placeholder="Select a District"
                          isClearable
                          styles={customStyles}
                          onChange={handleDistrictChange}
                          menuIsOpen={districtMenu}
                          onMenuClose={() => setDistrictMenu(false)}
                          onMenuOpen={() => setDistrictMenu(true)}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow className='To'>
                      <IonCol>
                        <IonLabel className='search_label'>Village / Town</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol>
                        <IonInput className='search_text' value={town} onIonChange={(e) => setTown(e.target.value)} />
                      </IonCol>
                    </IonRow>
                    <IonRow style={{ display: "flex", justifyContent: "center" }}>
                      <div className="center"><p>
                        or</p></div>
                    </IonRow>
                    <IonRow className='To'>
                      <IonCol>
                        <IonLabel className='search_label'>Enter Temple Name </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className='Too'>
                      <IonCol >
                        <IonInput className='search_text' value={temName} onIonChange={(e) => setTemName(e.target.value)} />
                      </IonCol>
                    </IonRow>
                    <div className='center_col padding_search'>
                      <div >
                        <IonButton className={Capacitor.getPlatform() === "ios" ? 'clear_all border_change_clear' : 'clear_all'} onClick={clear}>
                          <span className='button_padding'> Clear All</span>

                        </IonButton>
                      </div>
                      <div>
                        <IonButton className='search_btn' type="submit">
                          <span className='button_padding'>Search</span>

                        </IonButton>
                      </div>
                    </div>
                  </form>
                </IonCol>
                {hideContent === true ? <></> : <IonCol className='oo' size='12' sizeLg='6.5' ref={targetRef}>
                  <IonRow>
                    <div style={{ textAlign: "center", width: "100%", marginTop: "1%" }}>
                      <IonText className='deity_text_search'>Temple Name</IonText>
                    </div>
                  </IonRow>
                  <div className='temple_list_container'>
                    <div className='templenamescroll'>
                      {showgod ?
                        <>
                          {gods.length == 0 ?
                            <div className='no_result'>
                              <IonLabel className='before_search_text'>Not a serviceable area</IonLabel>
                            </div> : gods.length != 0 ?
                              <>
                               <div style={{color:"red",textAlign:"center",paddingBottom:"10px",fontSize:"14px"}}>
                              <span >* Please click on one of the temples displayed in the list to proceed</span>

                              </div>
                                {
                                  gods.map((item: any, i: any) => (
                                    <div className='temple_list' onClick={() => show(item.name, item.id)}>
                                      <IonRow >
                                        <IonCol size='2' >
                                          <div className='multi-color-dot'></div>
                                        </IonCol>
                                        <IonCol size='10' >
                                          <IonLabel
                                          >{item.name}
                                          </IonLabel>
                                        </IonCol>
                                      </IonRow></div>
                                  ))
                                }
                              </> : <></>
                          }
                        </> : <div className='temple_bg'>
                          <div className='temple_budha_bg'>
                          </div>
                          <div className='search_display'>
                            <IonLabel className='before_search_text'>Search Your Temple</IonLabel>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </IonCol>}
              </IonRow>
            </IonGrid>
          </div> : index1 === 3 ?
            <div className='order-container page_content_padding'>
              <div className='materials-page-header'>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IonIcon style={{ margin: "0px 10px 0px 0px" }} onClick={() => {
                    setIndex1(2)
                    setHeadName("Home,Select Area")
                  }} src={arrowBack} className='point back' size="large"></IonIcon>
                  <h5 className='page-title order-material-title'>Offering Materials</h5>
                </div>
                {selectedProducts.current.length > 0 && selectedProducts.current.some((e: any) => e.quantity > 0) ?
                  <div className='cart-badge-parent' onClick={() => {
                    setIndex1(7)
                    setHeadName("Home,Material cart")
                  }} >
                    <IonIcon src={cart} className="grammerce_main_cart_btn_mobile" />
                    <IonBadge className='cart-badge'>{selectedProducts.current.filter((item: any) => item.quantity > 0).length}</IonBadge>
                  </div>
                  : <></>}
              </div>
              <div className='center_col page-title' style={{textAlign:'center'}}>
                {tempname}
                </div>
              <div className='orders-select-search-container'>
                <IonSelect interface="popover"
                  placeholder="Select Deity"
                  className='orders-deity-select'
                  value={deityId}
                  onIonChange={(e) => deitySelect(e)}>
                  {templeDeity.map((item: any, i: any) => (
                    <div>
                      <IonSelectOption value={item.id} >
                        {item.deityName}
                      </IonSelectOption>
                    </div>
                  ))}
                </IonSelect>
                <IonSearchbar
                  value={searchQuery}
                  onIonChange={(e: any) => changeSearchQuery(e.detail.value)}
                  placeholder="Search"
                  className='orders-searchBar'
                />
              </div>

              {data.length === 0 ? <div className='no-materials'>
                <IonText>No Materials Available</IonText>
              </div> : search(searchItems).length === 0 ? <div className='no-materials'>
                <IonText>No Materials Available</IonText>
              </div> :
                <IonGrid>
                  <IonRow>
                    {
                      search(searchItems).map((item: any, i: any) => {
                        return (
                          <IonCol sizeLg='3' sizeMd='4' sizeSm='6' sizeXs='6' className='material-cards'>
                            <div onMouseEnter={() => sethoveredItem(item.id)} onMouseLeave={() => sethoveredItem("")} className='material_img_container'>
                              <div className={hoveredItem === item.id ? "material-card" : "material-card-disable"} onClick={() => viewMaterial(item)}>
                                {item.image === null ?
                                  <IonImg src="assets/icon/image.png" className='material_img' /> : <IonImg src={item.image + "?=v1"} className='material_img' />}
                                <div className={["addtocart_btn", item.materialStock === 0 ? "disableCursor" : ""].join(" ")}>
                                  <IonButton size="small"
                                    onClick={(e: any) => quantityIncre("home", e, item)} disabled={item.materialStock <= 0}
                                    className={hoveredItem === item.id ? "addtocartBtn" : "addtocartBtn-disable"}>
                                    <span className='button_padding'> Add to Cart</span>
                                  </IonButton>
                                </div>
                              </div>
                              <div className='material-content'>
                                <div className='material-name-size'>
                                  <span>{item.productName}&nbsp; &nbsp;({item.packageSize} {item.packageUnit})</span>
                                </div>
                                <p className='material-price'>₹ {formatter.format(item.price)}</p>
                                {item.materialStock > 0 &&
                                  <span className='material-stock'>Available Stock : {item.materialStock}</span>}
                                {item.materialStock <= 0 &&
                                  <span className='material-out-stock material-stock'>out of stock</span>
                                }
                              </div>
                            </div>
                          </IonCol>
                        )
                      }
                      )
                    }
                  </IonRow>
                </IonGrid>
              }
            </div > : index1 == 4 ? <div className="order-container page_content_padding">
              <div className='materials-page-header'>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IonIcon className='point back' style={{ margin: "0px 10px 0px 0px" }} onClick={() => {
                    setHeadName("Home,Material cart")
                    setIndex1(7)
                  }} src={arrowBack} size="large"></IonIcon>
                  <h5 className='page-title order-material-title'>Booking Details</h5>
                </div>
              </div>
              <div className='booking-form-container'>
                <IonGrid>
                  <IonRow className='booking-details-header'>
                    <h4>Order Details</h4>
                  </IonRow>
                  <IonRow className='booking-form-elements book-details-forms'>
                    <IonCol size-lg='6.5' size-md='6.5' size-xs='12'>
                      <IonRow>
                        <IonCol sizeMd='1.5' sizeXs='1' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeMd='4.5' sizeXs='11'>
                          <IonLabel>
                            Temple Name
                          </IonLabel>
                        </IonCol>
                        <IonCol sizeMd='0.5' sizeXs='1'>
                          <IonText>:</IonText>
                        </IonCol>
                        <IonCol sizeMd='5.5' sizeXs='11'>
                          <IonText>{tempname}</IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol sizeMd='1.5' sizeXs='1' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeMd='4.5' sizeXs='11'>
                          <IonLabel>
                            Name
                          </IonLabel>
                        </IonCol>
                        <IonCol sizeMd='0.5' sizeXs='1'>
                          <IonText>:</IonText>
                        </IonCol>
                        <IonCol sizeMd='5.5' sizeXs='11'>
                          <IonInput value={username} onIonChange={(e: any) => setUserName(e.detail.value)} />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol sizeMd='1.5' sizeXs='1' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeMd='4.5' sizeXs='11'>
                          <IonLabel>
                            Star
                          </IonLabel>
                        </IonCol>
                        <IonCol sizeMd='0.5' sizeXs='1'>
                          <IonText>:</IonText>
                        </IonCol>
                        <IonCol sizeMd='5.5' sizeXs='11'>
                          <IonInput value={star} onIonChange={(e: any) => setStar(e.detail.value)} />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol sizeMd='1.5' sizeXs='1' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeMd='4.5' sizeXs='11'>
                          <IonLabel className='date-label'>
                            Date(s) <span style={{ color: "red" }}>*</span>
                          </IonLabel>
                        </IonCol>
                        <IonCol sizeMd='0.5' sizeXs='1'>
                          <IonText>:</IonText>
                        </IonCol>
                        <IonCol sizeMd='5.5' sizeXs='11'>
                          {dates.map((item: any, i: any) => {
                            return (
                              <div className='booking-listofdates'>
                                <div className='inputdate-container'>
                                  <IonInput
                                    onIonChange={(e: any) => { dates_changes(e, i); ChangeValidation("flag", e) }}
                                    onKeyDown={(e: any) => e.preventDefault()}
                                    value={item.value}
                                    id={i}
                                    min={datelimit()}
                                    className='listofdatemat'
                                    type="date"
                                    onBlur={() => Validation("flag")}
                                  />
                                  <IonIcon onClick={handleIconClick} className='custom-calender-icon date_icon_style' src={calendarOutline} />
                                </div>
                                <div>
                                  {i == 0 ? <IonIcon className='booking-add-icon' src={addCircle} onClick={() => addInput()} /> : <IonIcon className='booking-add-icon' src={removeCircle} onClick={() => removeInputFields(i)} />}
                                </div>
                              </div>
                            )
                          })}
                          {formErrors.date && <span className="alert_input_bottom ">{formErrors.date}</span>}
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size-lg='5.5' size-md='5.5' size-xs='12'>
                      <IonRow>
                        <IonCol sizeLg="1.5" sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeLg="10.5" sizeMd='10.5' sizeSm='10.5' sizeXs='10.5'>
                          <IonRow style={{ margin: "0px" }}>
                            <IonCol sizeSm='1' sizeXs='1.5' className="booking-paydonation">
                              <IonCheckbox checked={dak} value={paydakshinapreist} onIonChange={(e) => dakamt(e)} />
                            </IonCol>
                            <IonCol sizeSm='11' sizeXs='10.5' className="booking-paydonation">
                              <IonText>Pay Dakshina directly to the priest</IonText><IonIcon onClick={openmodal} src={informationCircleSharp} className='info_icon mat_info_icon' />
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                      {dakamt1 &&
                        <IonRow>
                          <IonCol sizeLg="1.5" sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' />
                          <IonCol sizeLg="10.5" sizeMd='10.5' sizeSm='10.5' sizeXs='10.5'>
                            <div className='donAmt-field'>
                              <IonLabel>Amount (₹) <span style={{ color: "red" }}>*</span></IonLabel>
                              <IonInput value={dakshinaamt} onBlur={() => Validation("dakamt1")} onFocus={preventdata} min={0} type="number" ref={(ref) => inputRef.current = ref} autofocus={true} onKeyPress={(event) => numberOnlyValidation(event)} onIonChange={(e: any) => { dakshina(e); ChangeValidation("dakamt1", e) }} />
                            </div>
                            {formErrors.dakamt1 && <span className="alert_input_bottom ">{formErrors.dakamt1}</span>}
                          </IonCol>
                        </IonRow>
                      }
                      <IonRow>
                        <IonCol sizeLg="1.5" sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeLg="10.5" sizeMd='10.5' sizeSm='10.5' sizeXs='10.5'>
                          <IonRow style={{ margin: "0px" }}>
                            <IonCol sizeSm='1' sizeXs='1.5' className="booking-paydonation">
                              <IonCheckbox checked={don} value={paydakshinatemple} onIonChange={(e) => donamt(e)} />
                            </IonCol>
                            <IonCol sizeSm='11' sizeXs='10.5' className="booking-paydonation">
                              <IonText>Pay Donation to the temple</IonText>
                              <IonIcon onClick={openmodal1} src={informationCircleSharp} className='info_icon mat_info_icon' />                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                      {donamt1 &&
                        <IonRow>
                          <IonCol sizeLg="1.5" sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' />
                          <IonCol sizeLg="10.5" sizeMd='10.5' sizeSm='10.5' sizeXs='10.5'>
                            <div className='donAmt-field'>
                              <IonLabel>Amount (₹) <span style={{ color: "red" }}>*</span></IonLabel>
                              <IonInput value={donationamt} onFocus={preventdata} onBlur={() => Validation("donamt1")} min={0} type="number" ref={(ref) => inputRef1.current = ref} autofocus={true} onKeyPress={(event) => numberOnlyValidation(event)} onIonChange={(e: any) => { donation(e); ChangeValidation("donamt1", e) }} />
                            </div>
                            {formErrors.donamt1 && <span className="alert_input_bottom ">{formErrors.donamt1}</span>}
                          </IonCol>
                        </IonRow>}
                      <IonRow>
                        <IonCol sizeLg='1.5' sizeMd='1.5' sizeSm='0' sizeXs='0'>
                        </IonCol>
                        <IonCol sizeLg='10.5' sizeMd='10.5' sizeSm='12' sizeXs='12'>
                          <div className='confirm-Booking-btn'>
                            <IonButton onClick={() => show1()}><span className='button_padding'>Confirm Booking</span></IonButton>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div > : index1 == 5 ? <div className="order-container page_content_padding">
              <div className='materials-page-header'>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IonIcon onClick={() => {
                    setIndex1(4)
                    setHeadName("Home,Booking Details")
                    setActionFlag(false)
                  }} className='point back' style={{ margin: "0px 10px 0px 0px" }} src={arrowBack} size="large"></IonIcon>
                  <h5 className='page-title order-material-title'>Confirm Booking Details</h5>
                </div>
              </div>
              <div className='booking-form-container'>
                <IonGrid>
                  <IonRow className='booking-details-header'>
                    <h4>Order Details</h4>
                  </IonRow>
                  <IonRow className='booking-form-elements'>
                    <IonCol sizeLg='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                      <IonRow>
                        <IonCol sizeLg='1.5' sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeLg='4.5' sizeMd='4.5' sizeSm='4.5' sizeXs='4.5'>
                          <IonLabel>
                            Temple Name
                          </IonLabel>
                        </IonCol>
                        <IonCol sizeLg='0.5' sizeMd='0.5' sizeSm='0.5' sizeXs='0.5'>
                          <IonText>:</IonText>
                        </IonCol>
                        <IonCol sizeLg='5.5' sizeMd='5.5' sizeSm='5.5' sizeXs='5.5'>
                          <IonText>{tempname}</IonText>
                        </IonCol>
                      </IonRow>
                      {username.length > 0 && <IonRow>
                        <IonCol sizeLg='1.5' sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeLg='4.5' sizeMd='4.5' sizeSm='4.5' sizeXs='4.5'>
                          <IonLabel>
                            Name
                          </IonLabel>
                        </IonCol>
                        <IonCol sizeLg='0.5' sizeMd='0.5' sizeSm='0.5' sizeXs='0.5'>
                          <IonText>:</IonText>
                        </IonCol>
                        <IonCol sizeLg='5.5' sizeMd='5.5' sizeSm='5.5' sizeXs='5.5'>
                          <IonText>{username}</IonText>
                        </IonCol>
                      </IonRow>}
                      {star.length > 0 && <IonRow>
                        <IonCol sizeLg='1.5' sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeLg='4.5' sizeMd='4.5' sizeSm='4.5' sizeXs='4.5'>
                          <IonLabel>
                            Star
                          </IonLabel>
                        </IonCol>
                        <IonCol sizeLg='0.5' sizeMd='0.5' sizeSm='0.5' sizeXs='0.5'>
                          <IonText>:</IonText>
                        </IonCol>
                        <IonCol sizeLg='5.5' sizeMd='5.5' sizeSm='5.5' sizeXs='5.5'>
                          <IonText>{star}</IonText>
                        </IonCol>
                      </IonRow>}
                      <IonRow>
                        <IonCol sizeLg='1.5' sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' >
                          <IonIcon src={checkmarkCircleSharp} className='tick' />
                        </IonCol>
                        <IonCol sizeLg='4.5' sizeMd='4.5' sizeSm='4.5' sizeXs='4.5'>
                          <IonLabel className='date-label'>
                            Date(s)
                          </IonLabel>
                        </IonCol>
                        <IonCol sizeLg='0.5' sizeMd='0.5' sizeSm='0.5' sizeXs='0.5'>
                          <IonText>:</IonText>
                        </IonCol>
                        <IonCol sizeLg='5.5' sizeMd='5.5' sizeSm='5.5' sizeXs='5.5'>
                          {sortdates.map((item: any, i: any) => {
                            const dateFor = moment(item).format("DD-MM-YYYY")
                            var date;
                            if (i === sortdates.length - 1) {
                              date = dateFor.concat("")
                            }
                            else {
                              date = dateFor.concat(", ")
                            }
                            return (
                              <>
                                {
                                  item.value === "" ? <></> :
                                    <IonText>{date}</IonText>
                                }
                              </>
                            )
                          })}
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol sizeLg='6' sizeMd='6' sizeSm='12' sizeXs='12'>
                      {dak &&
                        <IonRow>
                          <IonCol sizeLg='1.5' sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' >
                            <IonIcon src={checkmarkCircleSharp} className='tick' />
                          </IonCol>
                          <IonCol sizeLg='4.5' sizeMd='4.5' sizeSm='4.5' sizeXs='4.5'>
                            <IonLabel>
                              Dakshina Amount
                            </IonLabel>
                          </IonCol>
                          <IonCol sizeLg='0.5' sizeMd='0.5' sizeSm='0.5' sizeXs='0.5'>
                            <IonText>:</IonText>
                          </IonCol>
                          <IonCol sizeLg='5.5' sizeMd='5.5' sizeSm='5.5' sizeXs='5.5'>
                            <IonText>₹ {dakshinaamt}</IonText>
                          </IonCol>
                        </IonRow>}
                      {don &&
                        <IonRow>
                          <IonCol sizeLg='1.5' sizeMd='1.5' sizeSm='1.5' sizeXs='1.5' >
                            <IonIcon src={checkmarkCircleSharp} className='tick' />
                          </IonCol>
                          <IonCol sizeLg='4.5' sizeMd='4.5' sizeSm='4.5' sizeXs='4.5'>
                            <IonLabel>
                              Donation Amount
                            </IonLabel>
                          </IonCol>
                          <IonCol sizeLg='0.5' sizeMd='0.5' sizeSm='0.5' sizeXs='0.5'>
                            <IonText>:</IonText>
                          </IonCol>
                          <IonCol sizeLg='5.5' sizeMd='5.5' sizeSm='5.5' sizeXs='5.5'>
                            <IonText>₹ {donationamt}</IonText>
                          </IonCol>
                        </IonRow>}
                    </IonCol>
                  </IonRow>
                  <div className='payment-page-items-container'>
                    <IonRow className='booking-details-header payment-items-head'>
                      <IonCol size='2'>
                        <span>Material Name</span>
                      </IonCol>
                      <IonCol size='2'>
                        <span>Deity</span>
                      </IonCol>
                      <IonCol size='2'>
                        <span>Quantity</span>
                      </IonCol>
                      <IonCol size='2'>
                        <span>Price</span>
                      </IonCol>
                      <IonCol size='2'>
                        <span>Delivery Charge</span>
                      </IonCol>
                      {confirmdates.length > 1 && <IonCol sizeLg='2'>
                        <span>Delivery Charge<br />Per Day</span>
                      </IonCol>}
                    </IonRow>
                    <div className='booking-form-elements mobile-items'>
                      <IonRow className='payment-page-items'>
                        <IonCol size='12' >
                          {delChargeData.materialDeityDto.map((item: any, i: any) => {
                            ttt = (item.price * item.quantity) + item.pdDelCharge + donP + dontemp + ttt
                            return (
                              item.quantity > 0 && <>
                                <IonRow className='items-row'>
                                  <IonCol size='2'>
                                    <IonText>{item.productName}</IonText>
                                  </IonCol>
                                  <IonCol size='2'>
                                    <IonText>{item.deityName}</IonText>
                                  </IonCol>
                                  <IonCol size='2' className='payment-items-centerTxt'>
                                    <IonText>{item.quantity}</IonText>
                                  </IonCol>
                                  <IonCol size='2' className='payment-items-centerTxt'>
                                    <IonText>₹ {formatter.format(item.price * item.quantity)}</IonText>
                                  </IonCol>
                                  <IonCol size='2' className='payment-items-centerTxt'>
                                    <IonText>₹ {formatter.format(item.pdDelCharge)}</IonText>
                                  </IonCol>
                                  {
                                    confirmdates.length > 1 && <IonCol sizeLg='2' className='payment-items-centerTxt'>
                                      <IonText>₹ {formatter.format(item.delChargePerDay)}</IonText>
                                    </IonCol>
                                  }
                                </IonRow>
                                <IonRow className="items-row-mobile">
                                  <IonCol size='12'>
                                    <IonRow>
                                      <IonCol size='1.5'><IonLabel>{i + 1}.</IonLabel></IonCol>
                                      <IonCol size='5'><IonLabel>Deity Name</IonLabel></IonCol>
                                      <IonCol size='0.5'>:</IonCol>
                                      <IonCol size='5'><IonText>{item.deityName}</IonText></IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size='1.5'></IonCol>
                                      <IonCol size='5'><IonLabel>Material Name</IonLabel></IonCol>
                                      <IonCol size='0.5'>:</IonCol>
                                      <IonCol size='5'><IonText>{item.productName}</IonText></IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size='1.5'></IonCol>
                                      <IonCol size='5'><IonLabel>Quantity</IonLabel></IonCol>
                                      <IonCol size='0.5'>:</IonCol>
                                      <IonCol size='5'><IonText>{item.quantity}</IonText></IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size='1.5'></IonCol>
                                      <IonCol size='5'><IonLabel>Price</IonLabel></IonCol>
                                      <IonCol size='0.5'>:</IonCol>
                                      <IonCol size='5'><IonText>₹ {formatter.format(item.price * item.quantity)}</IonText></IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size='1.5'></IonCol>
                                      <IonCol size='5'><IonLabel>Delivery Charge</IonLabel></IonCol>
                                      <IonCol size='0.5'>:</IonCol>
                                      <IonCol size='5'><IonText>₹ {formatter.format(item.pdDelCharge)}</IonText></IonCol>
                                    </IonRow>
                                    {
                                      confirmdates.length > 1 && <IonRow>
                                        <IonCol size='1.5'></IonCol>
                                        <IonCol size='5'><IonLabel>Delivery Charge<br />Per Day</IonLabel></IonCol>
                                        <IonCol size='0.5'>:</IonCol>
                                        <IonCol size='5'> <IonText>₹ {formatter.format(item.delChargePerDay)}</IonText></IonCol>
                                      </IonRow>
                                    }
                                  </IonCol>
                                </IonRow>
                              </>)
                          })
                          }
                        </IonCol>
                      </IonRow>
                      <IonRow className='items-paymentBtn'>
                        <IonCol sizeSm='6' sizeXs='12'>
                          <IonText class='payment-no-items'>
                            No of Items : {delChargeData.materialDeityDto.length}
                          </IonText>
                        </IonCol>
                        <IonCol sizeSm='6' sizeXs='12'>
                          <IonText className='payment-no-items'>Total Price : <span className='payment-cost'>₹ {formatter.format(totalAmount)}</span></IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow className='payment-btn'>
                        <IonButton onClick={() => {
                          if (usertoken == null) {
                            setLoginmodal(true)
                          }
                          else {
                            payment(usertoken)
                          }
                        }}><span className='button_padding'>Proceed to Payment</span></IonButton>
                      </IonRow>
                    </div>
                  </div>
                </IonGrid>
              </div>
            </div> : index1 === 6 ? <div className='order-container page_content_padding'>
              <IonIcon onClick={() => {
                setIndex1(3)
                setHeadName("Home,Offering Materials")
                sethoveredItem("")
              }} style={{ margin: "0px 10px 0px 0px" }} src={arrowBack} className='point back' size="large"></IonIcon>
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg='7' sizeMd='12' sizeSm='12' sizeXs='12'>
                    <div className='materialView-img-container'>
                      <IonImg onClick={() => setAddshowModal(true)} src={viewImg} className='material-image-view' />
                    </div>
                  </IonCol>
                  <IonCol sizeLg='5' sizeMd='12' sizeSm='12' sizeXs='12'>
                    <div className='material-details-container'>
                      <h5 className='page-title order-material-title'>{materialdeity}</h5>
                      <h4 className='details-productName'>{viewProductName}</h4>
                      <div className='details-price-review'>
                        <div>
                          <p className='details-price'>₹ {viewPrice}</p>
                        </div>
                        {/* <div className='stars-reviews'>
                          <IonImg src={stars} className='stars-img' />
                          <p className='reviews-txt'>( 12 reviews )</p>
                        </div> */}
                      </div>
                      <div className='details-description'>
                        <p>{viewBrandName}</p>
                      </div>
                      <div className='details-available-stock'>
                        <p>{viewPsize} {viewPunit}</p>
                      </div>
                      <div className='details-progress-element'>
                        {viewStock > 0 &&
                          <span className='details-available-stock'>Hurry! Only <b>{viewStock}</b> left in stock!</span>}
                        {viewStock <= 0 &&
                          <span className='material-out-stock material-stock'>out of stock</span>
                        }
                      </div>
                      <div className='details-quantity-element'>
                        <p className='page-title order-material-title'>Quantity</p>
                        <div className='quantity-cart'>
                          <div className='increment-decrement-element'>
                            <IonIcon src={removeOutline} style={{ cursor: viewQuantity > 0 ? "pointer" : "not-allowed" }} onClick={() => {
                              if (viewQuantity > 0) {
                                quantityDecre(viewItem, viewIndex)
                              }
                            }} /><span>|</span><IonInput className='details-input' onFocus={preventdata} type="number" onKeyUp={(e: any) => handleChange(e, viewItem)} readonly={viewItem.materialStock <= 0} value={viewQuantity} /><span>|</span>
                            <IonIcon style={{ cursor: viewStock != 0 && viewQuantity < viewStock ? "pointer" : "not-allowed" }} src={addOutline} onClick={(e: any) => {
                              if (viewStock != 0 && viewQuantity < viewStock) {
                                quantityIncre("view", e, viewItem)
                              }
                            }} />
                          </div>
                          <div className={viewStock === 0 ? "disableCursor" : ""}>
                            {viewQuantity === 0 ? <IonButton disabled={viewStock === 0} className='view-cart-btn' onClick={(e) => quantityIncre("view", e, viewItem)}>
                              <span className='button_padding'>Add to cart</span>
                            </IonButton> : <IonButton className='view-cart-btn' onClick={() => {
                              setHeadName("Home,Material cart")
                              setIndex1(7)
                            }}>
                              <span className='button_padding'>View cart</span>
                            </IonButton>}
                          </div>
                        </div>
                      </div>
                      {/* <div className='buyNow-btn-element'>
                        <IonButton className='buyNow-btn'><span className='button_padding'>Buy Now</span></IonButton>
                      </div> */}
                    </div>
                    {addshowModal && (
                      <Lightbox
                        mainSrc={viewImg}
                        onCloseRequest={() => setAddshowModal(false)}
                        onImageLoad={() => {
                          window.dispatchEvent(new Event('resize'));
                        }}
                      />
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div> : index1 == 7 ?
              <div className='shoppingcart-container order-container page_content_padding'>
                <div className='materials-page-header'>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IonIcon onClick={() => {
                      setIndex1(3)
                      setHeadName("Home,Offering Materials")
                      sethoveredItem("")
                    }} style={{ margin: "0px 10px 0px 0px" }} className='point back' src={arrowBack} size="large"></IonIcon>
                    <h5 className='page-title order-material-title'>Shopping Cart</h5>
                  </div>
                </div>
                <IonGrid>
                  <IonRow>
                    <IonCol sizeLg='9' sizeMd='12' sizeSm='12' sizeXs='12'>

                      <IonRow className='cartItems-header cart-hide-for-mobile'>
                        <IonCol sizeLg='3.5' sizeXs='3.3'>
                          <span>Product</span>
                        </IonCol>
                        <IonCol sizeLg='2' sizeXs='3'>
                          <span>Deity</span>
                        </IonCol>
                        <IonCol sizeLg='1.5' sizeXs='2'>
                          <span>Price</span>
                        </IonCol>
                        <IonCol sizeLg='3' sizeXs='3'>
                          <span>Quantity</span>
                        </IonCol>
                        <IonCol sizeLg='2' sizeXs='0.7'>
                        </IonCol>
                      </IonRow>
                      {selectedProducts.current.every((item: any) => item.quantity === 0) ? <div className='no-materials'>
                        <IonText>No items in your shopping cart</IonText>
                      </div> :
                        <div>{selectedProducts.current.map((item: any, i: any) => {
                          console.log(item)
                          return (
                            item.quantity > 0 && <>
                              <IonRow className='cart-item-row cart-hide-for-mobile' >
                                <IonCol sizeLg='3.5' sizeXs='3.3'>
                                  <IonRow className='cart-item-img'>
                                    <IonCol size='6' className='cartImg-view'>
                                      <IonImg src={item.image} className='material-cartImg' />
                                    </IonCol>
                                    <IonCol size='6'>
                                      <IonText className='cart-items-txt cart-item-productName'>{item.productName}</IonText><br />
                                      <IonText className='cart-items-txt cart-item-productName details-price'>₹ {formatter.format(item.price)}</IonText>
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                                <IonCol sizeLg='2' sizeXs='3'>
                                  <IonText className='cart-items-txt'>{item.deityName}</IonText>
                                </IonCol>
                                <IonCol sizeLg='1.5' sizeXs='2'>
                                  <IonText className='cart-items-txt'>₹ {formatter.format(item.price * item.quantity)}</IonText>
                                </IonCol>
                                <IonCol sizeLg='3' sizeXs='3'>
                                  <div className='increment-decrement-element cart-inc-dec'>
                                    <IonIcon src={removeOutline} onClick={() => quantityDecre(item, i)} /><span>|</span><IonInput className='details-input' onFocus={preventdata} type="number" min="0" onKeyUp={(e: any) => handleChange(e, item)} readonly={item.materialStock <= 0} value={item.quantity} /><span>|</span><IonIcon src={addOutline} onClick={() => quantityIncreChoose(item, i)} />
                                  </div>
                                </IonCol>
                                <IonCol sizeLg="2" sizeXs='0.7'>
                                  <div className='cart-remove-element' onClick={() => opendelModal(item)}>
                                    <IonIcon src={closeOutline} className='cart-remove-icon' size='large' /> <IonText className='cartRem-txt'>Remove</IonText>
                                  </div>
                                </IonCol>
                              </IonRow>
                              <IonRow className='cart-show-for-mobile'>
                                <IonCol size='6'>
                                  <div className='cartImg-view'>
                                    <IonImg src={item.image} className='material-cartImg' />
                                  </div>
                                </IonCol>
                                <IonCol size='6' >
                                  <div>
                                    <div className='deity-close-Icon'>
                                      <p>{item.deityName}</p>
                                      <IonIcon onClick={() => opendelModal(item)} src={closeOutline} className='cart-remove-icon' size='large' />
                                    </div>
                                    <p className='mobile-cart-name'>{item.productName}</p>
                                    <p><span className='details-price'>Price - ₹ {formatter.format(item.price)}</span></p><p>Total - ₹ {formatter.format(item.price * item.quantity)}</p>
                                  </div>
                                  <div className='increment-decrement-element cart-inc-dec'>
                                    <IonIcon src={removeOutline} onClick={() => quantityDecre(item, i)} /><span>|</span><IonInput className='details-input' onFocus={preventdata} type="number" min="0" onKeyUp={(e: any) => handleChange(e, item)} readonly={item.materialStock <= 0} value={item.quantity} /><span>|</span><IonIcon src={addOutline} onClick={() => quantityIncreChoose(item, i)} />
                                  </div>
                                </IonCol>
                              </IonRow>
                              <Modal show={deleteModal} className="modal_dialog_width1 logout-modal">
                                <Modal.Body>
                                  <IonRow>
                                    <IonCol style={{ textAlign: "center" }}>
                                      <h5 className="mt-2">Are You Sure Want To Delete?</h5>
                                    </IonCol>
                                  </IonRow>
                                  <IonRow className="mt-12">
                                    <IonCol size="6" style={{ textAlign: "end" }}>
                                      <IonButton onClick={() => removeItem(i)}><span className='button_padding'>Yes</span></IonButton>
                                    </IonCol>
                                    <IonCol size="6">
                                      <IonButton onClick={(e) => setdeleteModal(false)}>
                                        <span className='button_padding'>No</span></IonButton>
                                    </IonCol>
                                  </IonRow>
                                </Modal.Body>
                              </Modal>
                            </>
                          )
                        })}
                        </div>
                      }
                    </IonCol>
                    <IonCol sizeLg='3' sizeMd='12' sizeSm='12' sizeXs='12' className='totalAmt_col'>
                      <div className='cart-total-view'>
                        <div className='cart-totalAmt'>
                          <IonText>Total</IonText>
                          <IonText>₹ {formatter.format(tot)}</IonText>
                        </div>
                        <div className='cart-order-btn'>
                          <div className={activeIND === false ? "disableCursor" : ""} style={{ width: "fit-content" }}>
                            <IonButton disabled={activeIND === false} onClick={() => {
                              setHeadName("Home,Booking Details")
                              setIndex1(4)
                            }}>
                              <span className='button_padding'>
                                Order Now
                              </span>
                            </IonButton>
                          </div>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>

              </div> : <></>}
        </div>
        <Foot />
      </IonContent >
    </IonPage >
  )
}
export default Poojamaterials;
