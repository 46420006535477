import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Foot from "./Foot";

import UserHead from "./UserHead";
import axios from "axios";
import Authservice from "../Services/auth.service";
import "./Home.css";
import { useHistory } from "react-router";
import { Capacitor } from "@capacitor/core";
import HeadFoot from "./Head";

const JoinUs: React.FC = () => {

  const userdata: any = useState(localStorage.getItem("UserData"));
  const [token, settoken]: any = useState(localStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <HeadFoot User={"user"} Role={'Role'} Name={"Home,Join Us"} />
        <IonRow className="page_content_padding">
          <IonCol>
            <div style={{ margin: '3%' }}>
              <div>
                <h5 style={{ fontWeight: 'bold', color: "red" }}>Join Our Team</h5>
                <div>
                  <h6 style={{ textAlign: "left", marginTop: '3%' }}>*  We are looking for professionals.</h6>
                  <h6 style={{ textAlign: "left" }}>*  Teach our knowledge and skills.</h6>
                  <h6 style={{ textAlign: "left" }}>*  We are appreciate the donations </h6>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>

        <Foot />
      </IonContent>
    </IonPage>
  );
};

export default JoinUs;